unit SMX.Auth.Shared;

interface

uses
  {$IFNDEF PAS2JS}
  Aurelius.Mapping.Attributes,
  {$ENDIF}
  Sphinx.Consts;

{$SCOPEDENUMS ON}

type
{$IFDEF PAS2JS}
  TUserScope = (None, ReadOnly, User, AdminUser, Administrator, SuperUser);
{$ENDIF}

{$IFNDEF PAS2JS}
[Enumeration(TEnumMappingType.emString, 'Pending,Blocked,Active,System')]
{$ENDIF}
TUserStatus = (Pending, Blocked, Active, System);


const
  TOKEN_TYPE_ACCESS = 'access_token';
  TOKEN_TYPE_ID = 'id_token';

  CLAIM_FIRSTNAME = JwtClaimNames.GivenName; // 'given_name';
  CLAIM_LASTNAME = JwtClaimNames.FamilyName;
  CLAIM_FULLNAME = JwtClaimNames.Name; // 'name';
  
  //This is the value held in the Sphinx SX_USERS table (a lower case GUID)
  CLAIM_USERID = 'sub';
  
  //This is the Internal Id, created by the app (Integer)
  CLAIM_APP_USER_ID = 'userid';
  
  CLAIM_LOCATIONTYPE = 'locationtype';
  CLAIM_SCOPE = 'scope';
  CLAIM_ROLE = 'role';
  CLAIM_TENANT_ID = 'tenant-id';  //DO NOT CHANGE THIS IS USED BY XData!!!//
  CLAIM_ORGANISATION_ID = 'org-id';
  CLAIM_ORGANISATION_NAME = 'org-name';
  CLAIM_ORGANISATION_TENANT_ID = 'org-tenant-id';

  ORGANISATION_NOT_CREATED = 'not-created';
  ORGANISATION_SETUP_PENDING = 'setup-pending';
  ORGANISATION_MULTI_TENANT = 'multi-tenant';

  HEADER_TENANT_ID = 'tenant-id';

  //This is added to the Id Token by Sphinx as "email"
  CLAIM_EMAIL = 'email';

  SCOPE_NONE = 'None';
  SCOPE_READ_ONLY = 'ReadOnly';
  SCOPE_USER = 'User';
  SCOPE_ADMIN_USER = 'AdminUser';
  SCOPE_ADMIN = 'Administrator';
  SCOPE_SUPERUSER = 'SuperUser';

  User_Status: Array[TUserStatus] of string = ('Pending', 'Blocked', 'Active', 'System');

implementation

{$SCOPEDENUMS OFF}

end.
