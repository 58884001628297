unit DashboardForm2;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.DB,
  WEBLib.ExtCtrls,
  WEBLib.WebCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  Vcl.Grids,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Data.DB, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCChart, DashboardItem, App.Helper, DB,
  BaseDashboardItem, DashboardItemSummaryTop5, DashboardItemTableOnly,
  DashboardItemSingleSummary,DashboardItemSummaryIncluded,
  Vcl.Imaging.GIFImg, WEBLib.TreeNodes, Vcl.ComCtrls, WEBLib.ComCtrls,
  WEBLib.CheckLst, System.Generics.Collections, SMX.XData.Utils, Sys.Return.Types,
  VCL.TMSFNCCustomControl, VCL.TMSFNCTreeViewBase, VCL.TMSFNCTreeViewData,
  VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView;

type
  TDashboard2 = class(TForm)
    WaitMessage: TWaitMessage;
    ChartTimer: TTimer;
    BranchTreeView: TTreeView;
    StartDatePicker: TDateTimePicker;
    EndDatePicker: TDateTimePicker;
    RefreshButton: TButton;
    ConfigurationButton: TButton;

    procedure WebFormShow(Sender: TObject);
    procedure ChartTimerTimer(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure DatePickerChange(Sender: TObject);
    procedure RefreshButtonClick(Sender: TObject);
    procedure BranchTreeViewChange(Sender: TObject; Node: TTreeNode);
    procedure ConfigurationButtonClick(Sender: TObject);
  private
    { Private declarations }
    FDashboardParamsStr: string;
    FDashboardList: TStringList;

    FDashboardItemTemplates: TList<TDashboardItemTemplate>;

    FBranchStructureJSON: string;
    FGroupList: TStringList;
    FBranchList: TStringList;
    FBranchListForQuery: TStringList;

    procedure CreateDashboardItems;
    procedure CreateDashboardItem(ADashBoardItemTemplate: TDashboardItemTemplate);
    procedure InitialiseDashboard;

    [async]
    procedure RunDashboard(ADashboardId: integer); async;
    procedure RefreshDashboard(ADashboardId: integer);
    procedure UpdateDashboardItemsParameters;

    procedure GetDashboardItemList;
    procedure GetBranchIdsList;
    procedure UpdateBranchIdsList;
    procedure ImplementParameters;
    [async]
    procedure GetBranches; async;
    procedure GetSelectedBranches;
    procedure BranchUpdate;
    function SortBranchIdsParameter:string;
    [async]
    procedure GetUserDashboardItems; async;

    [async]
    procedure GetBranchStructure; async;
    [async]
    procedure ConfigurationPopUp; async;

  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Dashboard2: TDashboard2;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  MainForm,
  MainDataModule,
  SMX.Web.Service.Consts,
  SharedDataModule,
  Job.DetailForm.Manager,
  JobDetailBaseForm,
  SMX.Web.Utils,
  SMX.Web.Document.Utils,
  Simple.Dashboard.Return.Types,
  SMX.Web.Table.Simple,
  DateUtils,
  Auth.Service,
  Configuration;


procedure TDashboard2.InitialiseDashboard;
begin
  FDashboardList := TStringList.Create;
  FDashboardItemTemplates := TList<TDashboardItemTemplate>.Create;

  FBranchListForQuery := TStringList.Create;
  GetBranchStructure;
//  GetBranches;
  GetUserDashboardItems;

//  ImplementParameters;
//  CreateDashboardItems;
end;

procedure TDashboard2.CreateDashboardItems;
var
  lIdx: integer;
  lDashboardItemTemplate: TDashboardItemTemplate;
//  lCount: integer;
begin
//  lCount := FDashboardItemTemplates.Count-1;

//  if lCount = 0 then
//  sleep(9);

  for lIdx := 0 to FDashboardItemTemplates.Count-1 do
  begin
    lDashboardItemTemplate := FDashboardItemTemplates[lIdx];
    CreateDashboardItem(lDashboardItemTemplate);
    (*
    //  CreateDashboardItem('SQLSummaryIncluded', 1);
    CreateDashboardItem('SQLSingleSummary',1);
    CreateDashboardItem('SQLSummaryIncluded',5);
    //  CreateDashboardItem('SQLSummaryIncluded',8);
    CreateDashboardItem('SQLSummaryTop5', 7);
    //  CreateDashboardItem('SQLSummaryIncluded',5);
    CreateDashboardItem('SQLSingleSummary',6);
    CreateDashboardItem('SQLTable', 3);
    CreateDashboardItem('SQLTable', 4);*)
  end;
end;


//procedure TDashboard2.CreateDashboardItem(ADashBoardItemType: string; ADashboardId: integer);
procedure TDashboard2.CreateDashboardItem(ADashBoardItemTemplate: TDashboardItemTemplate);
var
  lDashboardItem: TBaseDashboardItem;
  lIndex: Integer;
begin
//  lDashboardItem := TDashboardItem.Create(Self);
  if ADashboardItemTemplate.DashboardItemType = 'SQLSummaryTop5' then
    lDashboardItem := TDashboardItemSummaryTop5.Create(Self)
  else if ADashboardItemTemplate.DashboardItemType = 'SQLTable' then
    lDashboardItem := TDashboardItemTableOnly.Create(Self)
  else if ADashboardItemTemplate.DashboardItemType = 'SQLSummaryIncluded' then
    lDashboardItem := TDashboardItemSummaryIncluded.Create(Self)
  else if ADashboardItemTemplate.DashboardItemType = 'SQLSingleSummary' then
    lDashboardItem := TDashboardItemSingleSummary.Create(Self)
  else if ADashboardItemTemplate.DashboardItemType = '' then
    lDashboardItem := TDashboardItemSummaryTop5.Create(Self);

  lDashboardItem.DashboardId := ADashboardItemTemplate.Id;
  lDashboardItem.Parent := self;
  lDashboardItem.DashboardParameters := FDashboardParamsStr;
  lDashboardItem.Template := ADashBoardItemTemplate;
  lDashboardItem.Run;

  FDashboardList.AddObject(lDashboardItem.Title, lDashboardItem);
end;

procedure TDashboard2.RefreshButtonClick(Sender: TObject);
begin
  GetSelectedBranches;

  UpdateBranchIdsList;
  ImplementParameters;
  RefreshDashboard(0);
end;

procedure TDashboard2.RefreshDashboard(ADashboardId: integer);
var
  lDashboardItem: TDashboardItem;
  lIndex: Integer;
begin
//  for lIndex := 0 to FDashboardItemsTemplates.Count-1 do
  for lIndex := 0 to FDashboardList.Count-1 do
  begin
    lDashboardItem := TDashboardItem(FDashboardList.Objects[lIndex]);

    lDashboardItem.DashboardParameters := FDashboardParamsStr;
    lDashboardItem.Refresh;
  end;
end;

procedure TDashboard2.RunDashboard(ADashboardId: integer);
begin
end;

procedure TDashboard2.WebFormCreate(Sender: TObject);
begin
//  ChartList := TStringList.Create;
end;

procedure TDashboard2.WebFormShow(Sender: TObject);
begin
  InitialiseDashboard;
end;

procedure TDashboard2.BranchTreeViewChange(Sender: TObject; Node: TTreeNode);
begin
//  UpdateBranchIdsList;
end;

procedure TDashboard2.ChartTimerTimer(Sender: TObject);
var
  lIdx: integer;
  MyChart: TDashboardItem;
begin
  ChartTimer.Enabled := False;

//  for lIdx := 0 to ChartList.Count-1 do
//  begin
//    MyChart := TDashboardChart(ChartList.Objects[lIdx]);
//    MyChart.Parent := Self;
//  end;

//  WaitMessage.Hide;
end;

procedure TDashboard2.ConfigurationButtonClick(Sender: TObject);
begin
  ConfigurationPopUp;
end;

procedure TDashboard2.ConfigurationPopUp;
var
  ConfigForm: TConfigurationForm;
begin
  ConfigForm := TConfigurationForm.Create(Self);
  try
    ConfigForm.Popup := True;
    ConfigForm.PopupOpacity := 1;
    await(TConfigurationForm, ConfigForm.Load());
    ConfigForm.BranchStructure := FBranchStructureJSON;
    await(TModalResult, ConfigForm.Execute);
  finally
    ConfigForm.Free;
    ConfigForm := nil;
  end;

end;

procedure TDashboard2.GetBranchIdsList;
begin

end;

procedure TDashboard2.GetBranchStructure;
begin
  FBranchStructureJSON := Await(SharedData.Dashboard.GetBranchStructure());
  //lRegionArray := JS.toArray(TJSJSON.parse(lBranchStructure));
end;

procedure TDashboard2.GetDashboardItemList;
begin

end;

procedure TDashboard2.GetSelectedBranches;
var
  lCount: integer;
  lIdx: integer;
  lNode : TTreeNode;
begin
 // lCount := BranchTreeView.SelectionCount;

//  lNode := BranchTreeView.Selections[0];

//  BranchTreeView.Subselect()

//  for lIdx := 0 to lCount-1 do
//  begin

//    ListBox.Items.Add(lNode.Text);
//  end;
end;

procedure TDashboard2.ImplementParameters;
var
  lDashboardParams: JS.TJSObject;
  lDashboardParamsStr: string;

  lDate: TDate;
  lDateStr: string;
  lBranchIdList: string;
begin
//  lParams := '{"StartDate":"26.10.2023","EndDate":"31.10.2023","BranchId":"1", "Today":"31.10.2023", "CurrentYear":"2023"}';//lJobParams.toString;
//  lDate := StartDatePicker.Date;

  //function FormatDateTime(const Format: string; DateTime: TDateTime): string;

//  lDateStr := FormatDateTime(lDate);
  lBranchIdList := SortBranchIdsParameter;

  lDashboardParams := JS.TJSObject.new;
  lDate := StartDatePicker.Date;
//  lDateStr := FormatDateTime('dd.mm.yyyy', lDate-365);
  lDateStr := FormatDateTime('dd.mm.yyyy', lDate);
  lDashboardParams.Properties['StartDate'] := lDateStr;
  lDate := EndDatePicker.Date;
//  lDateStr := FormatDateTime('dd.mm.yyyy', lDate-360);
  lDateStr := FormatDateTime('dd.mm.yyyy', lDate+5);
  lDashboardParams.Properties['EndDate'] := lDateStr;
  //lDashboardParams.Properties['BranchId'] := '1';
  lDashboardParams.Properties['BranchId'] := lBranchIdList;
//  '21000000000000002,21000000000000003,21000000000000004';
  lDashboardParams.Properties['Today'] := FormatDateTime('dd.mm.yyyy', Now-1);
  lDashboardParams.Properties['CurrentYear'] := FormatDateTime('YYYY', Now-365);

  lDashboardParamsStr := JS.TJSJSON.stringify(lDashboardParams);

  FDashboardParamsStr := lDashboardParamsStr;
//  UpdateDashboardItemsParameters;
end;

procedure TDashboard2.DatePickerChange(Sender: TObject);
begin
//  ImplementParameters;
end;

procedure TDashboard2.GetBranches;
var
  lBranchStructure: string;
  BranchNode, SN, AreaNode: TTreeNode;
  lStream: TStringStream;

  lBranchStructureObj: JS.TJSObject;
  lIdx: integer;
  lIdx2: integer;
  lCount: integer;

  lBranchStructureArray: JS.TJSArray;
  lBranchObj: JS.TJSObject;

  lRegionArray: JS.TJSArray;
  lRegionName: string;
  lRegionObj: JS.TJSObject;
  lBranchesObj: JS.TJSArray;
  //lBranchesObj: JS.TJSValue;
  lBranchArray: JS.TJSArray;

  lBranchName: string;
  lBranchId: string;
  lSubBranch: TStringList;
begin
  FGroupList := TStringList.Create;
  FBranchList := TStringList.Create;

  lBranchStructure := Await(SharedData.Dashboard.GetBranchStructure());
  lRegionArray := JS.toArray(TJSJSON.parse(lBranchStructure));

  BranchTreeView.BeginUpdate;

  for lIdx := 0 to lRegionArray.Length-1 do
  begin
    lSubBranch := TStringList.Create;
    lRegionObj := JS.toObject(lRegionArray[lIdx]);
    lBranchArray := JS.toArray(lRegionObj);

    lRegionName := String(lRegionObj.toString);
    lRegionName := Copy(lRegionName, 1, Pos(',', lRegionName)-1);
    BranchNode := BranchTreeView.Items.Add(lRegionName);

    FGroupList.AddObject(lRegionName, BranchNode);
    FBranchList.AddObject(lRegionName, lSubBranch);

    //BranchNode.NodeType := ntCheckBox;
    //BranchNode.Selected := True;
    //BranchNode.Checked := True;


    for lIdx2 := 1 to lBranchArray.Length-1 do
    begin
      lBranchObj := JS.toObject(lBranchArray.Elements[lIdx2]);

      lBranchName := String(lBranchObj['BranchName']);
      lBranchId   := String(lBranchObj['BranchId']);

      SN := BranchTreeView.Items.AddChild(BranchNode, lBranchName);
      SN.NodeType := ntCheckBox;
      //SN.Selected := True;
      SN.Checked := False;// True;
      lSubBranch.AddObject(lBranchId, SN);
    end;
  end;

  BranchTreeView.EndUpdate;
end;

procedure TDashboard2.UpdateBranchIdsList;
var
  lGroupIdx: integer;
  lBranchIdx: integer;
  lGroupNode: TTreeNode;
  lBranchNode: TTreeNode;
  lChecked: boolean;
  lBranchList: TStringList;
begin
//  BranchTreeView.BeginUpdate;
  FBranchListForQuery.Clear;
  for lGroupIdx := 0 to FGroupList.Count-1 do
  begin
    lBranchList := TStringList(FBranchList.Objects[lGroupIdx]);
    lGroupNode := TTreeNode(FGroupList.Objects[lGroupIdx]);
    //lChecked := lGroupNode.Checked;

    for lBranchIdx := 0 to lBranchList.Count-1 do
    begin
      lBranchNode := TTreeNode(lBranchList.Objects[lBranchIdx]);
      //lBranchNode.Checked := lChecked;

      if lBranchNode.Checked then
      begin
        //ListBox.Items.Add(lBranchList[lBranchIdx]);
        FBranchListForQuery.Add(lBranchList[lBranchIdx]);
      end;
    end;
  end;
//  BranchTreeView.EndUpdate;
end;

procedure TDashboard2.UpdateDashboardItemsParameters;
var
  lDashboardItem: TDashboardItem;
  lIndex: Integer;
begin
  for lIndex := 0 to FDashboardList.Count-1 do
  begin
    lDashboardItem := TDashboardItem(FDashboardList.Objects[lIndex]);
    lDashboardItem.DashboardParameters := FDashboardParamsStr;
    //lDashboardItem.Refresh;
    (*  MyChart := TDashboardChart.Create(Self);
      MyChart.DashboardIndex := AIndex;
      MyChart.DashboardId := AId;
      MyChart.Parent := self;
      MyChart.DashboardParameters := FDashboardParamsStr;
      MyChart.Run;*)
    //  ChartList.AddObject(AId.ToString, MyChart)
  end;
end;

procedure TDashboard2.BranchUpdate;
begin

end;

function TDashboard2.SortBranchIdsParameter:string;
var
  lIdx: integer;
  lBranchId: string;
  lBranchList: string;
  lLength: integer;
begin
  lBranchList := '';
  for lIdx := 0 to FBranchListForQuery.Count-1 do
  begin
    lBranchId := FBranchListForQuery[lIdx];
    lBranchList := lBranchList + lBranchId + ',';
  end;

  lLength := Length(lBranchList);

  if lLength > 3 then
    lBranchList := Copy(lBranchList, 1, Length(lBranchList)-1)
  else
    lBranchList := '0';

  result := lBranchList;
end;

procedure TDashboard2.GetUserDashboardItems;
//const
  //svc_name = 'IDashboardManagementService.GetUserDashboardItems';
//  svc_name1 = 'IDashboardManagementService.GetDashboardItemsForUser';

//  svc_name = 'ISimpleDashboardService.GetBranchStructure';
//  svc_dashboarditem_tenant = 'IDashboardManagementService.GetTenantDashboardItems';

var
  lRetval: TXDataClientResponse;
//  lResponse: TJSObject;
  lList: TJSArray;
  lIdx: integer;
//  lCount: integer;
  lUserId: integer;

begin
  lUserId := MainData.UserId;

  if lUserId <> 0 then
  begin
    FDashboardItemTemplates := Await(SharedData.Dashboard.GetDashboardItemTemplates(lUserId));
  end;
  ImplementParameters;
  CreateDashboardItems;
end;

(*
Procedure TUserEditForm.LoadBranchList;
Const
  branch_svc = 'IBranchService.GetAllBranches';
Var
  lRetval: TXDataClientResponse;
  lList: TJSArray;
  I: Integer;
  lBranch: TWebBranch;
Begin
  if not Assigned(FBranches) then
  begin
     FBranches := TList<TWebBranch>.Create;
     lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(branch_svc, []));
     lList := TsmxXDataUtils.AsArray(lRetval);
     for I := 0 to lList.Length - 1  do
        FBranches.Add(TWebBranch.Create(JS.toObject(lList[I])));
  end;
*)





procedure TDashboard2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WaitMessage := TWaitMessage.Create(Self);
  BranchTreeView := TTreeView.Create('BranchTreeView');
  StartDatePicker := TDateTimePicker.Create('StartDate');
  EndDatePicker := TDateTimePicker.Create('EndDate');
  RefreshButton := TButton.Create('Refresh');
  ConfigurationButton := TButton.Create('Configuration');
  ChartTimer := TTimer.Create(Self);

  WaitMessage.BeforeLoadDFMValues;
  BranchTreeView.BeforeLoadDFMValues;
  StartDatePicker.BeforeLoadDFMValues;
  EndDatePicker.BeforeLoadDFMValues;
  RefreshButton.BeforeLoadDFMValues;
  ConfigurationButton.BeforeLoadDFMValues;
  ChartTimer.BeforeLoadDFMValues;
  try
    Name := 'Dashboard2';
    Width := 941;
    Height := 677;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WaitMessage.SetParentComponent(Self);
    WaitMessage.Name := 'WaitMessage';
    WaitMessage.Left := 228;
    WaitMessage.Top := 576;
    WaitMessage.Width := 24;
    WaitMessage.Height := 24;
    WaitMessage.Margins.Left := 5;
    WaitMessage.Margins.Top := 5;
    WaitMessage.Margins.Right := 5;
    WaitMessage.Margins.Bottom := 5;
    WaitMessage.HeightStyle := ssAuto;
    WaitMessage.WidthStyle := ssAuto;
    WaitMessage.Opacity := 0.200000000000000000;
    WaitMessage.Picture.LoadFromFile('DashboardForm2.WaitMessage.Picture.gif');
    BranchTreeView.SetParentComponent(Self);
    BranchTreeView.Name := 'BranchTreeView';
    BranchTreeView.Left := 8;
    BranchTreeView.Top := 24;
    BranchTreeView.Width := 244;
    BranchTreeView.Height := 385;
    BranchTreeView.ChildOrder := 1;
    BranchTreeView.ElementPosition := epIgnore;
    BranchTreeView.HeightStyle := ssAuto;
    BranchTreeView.WidthStyle := ssAuto;
    BranchTreeView.HeightPercent := 100.000000000000000000;
    BranchTreeView.WidthPercent := 100.000000000000000000;
    BranchTreeView.TabOrder := 1;
    BranchTreeView.TabStop := True;
    StartDatePicker.SetParentComponent(Self);
    StartDatePicker.Name := 'StartDatePicker';
    StartDatePicker.Left := 16;
    StartDatePicker.Top := 480;
    StartDatePicker.Width := 170;
    StartDatePicker.Height := 22;
    StartDatePicker.HeightStyle := ssAuto;
    StartDatePicker.WidthStyle := ssAuto;
    StartDatePicker.BorderStyle := bsSingle;
    StartDatePicker.ChildOrder := 3;
    StartDatePicker.Color := clWhite;
    StartDatePicker.Date := 45575.658078703700000000;
    StartDatePicker.ElementPosition := epIgnore;
    StartDatePicker.Role := '';
    StartDatePicker.Text := '';
    SetEvent(StartDatePicker, Self, 'OnChange', 'DatePickerChange');
    EndDatePicker.SetParentComponent(Self);
    EndDatePicker.Name := 'EndDatePicker';
    EndDatePicker.Left := 16;
    EndDatePicker.Top := 508;
    EndDatePicker.Width := 170;
    EndDatePicker.Height := 22;
    EndDatePicker.HeightStyle := ssAuto;
    EndDatePicker.WidthStyle := ssAuto;
    EndDatePicker.BorderStyle := bsSingle;
    EndDatePicker.ChildOrder := 3;
    EndDatePicker.Color := clWhite;
    EndDatePicker.Date := 45579.658086701390000000;
    EndDatePicker.ElementPosition := epIgnore;
    EndDatePicker.Role := '';
    EndDatePicker.Text := '';
    SetEvent(EndDatePicker, Self, 'OnChange', 'DatePickerChange');
    RefreshButton.SetParentComponent(Self);
    RefreshButton.Name := 'RefreshButton';
    RefreshButton.Left := 16;
    RefreshButton.Top := 536;
    RefreshButton.Width := 96;
    RefreshButton.Height := 25;
    RefreshButton.Caption := 'Refresh';
    RefreshButton.ChildOrder := 4;
    RefreshButton.ElementClassName := 'btn btn-secondary';
    RefreshButton.HeightPercent := 100.000000000000000000;
    RefreshButton.WidthPercent := 100.000000000000000000;
    SetEvent(RefreshButton, Self, 'OnClick', 'RefreshButtonClick');
    ConfigurationButton.SetParentComponent(Self);
    ConfigurationButton.Name := 'ConfigurationButton';
    ConfigurationButton.Left := 16;
    ConfigurationButton.Top := 624;
    ConfigurationButton.Width := 96;
    ConfigurationButton.Height := 25;
    ConfigurationButton.Caption := 'Configuration';
    ConfigurationButton.ChildOrder := 6;
    ConfigurationButton.HeightPercent := 100.000000000000000000;
    ConfigurationButton.WidthPercent := 100.000000000000000000;
    SetEvent(ConfigurationButton, Self, 'OnClick', 'ConfigurationButtonClick');
    ChartTimer.SetParentComponent(Self);
    ChartTimer.Name := 'ChartTimer';
    ChartTimer.Enabled := False;
    ChartTimer.Interval := 100;
    SetEvent(ChartTimer, Self, 'OnTimer', 'ChartTimerTimer');
    ChartTimer.Left := 832;
    ChartTimer.Top := 464;
  finally
    WaitMessage.AfterLoadDFMValues;
    BranchTreeView.AfterLoadDFMValues;
    StartDatePicker.AfterLoadDFMValues;
    EndDatePicker.AfterLoadDFMValues;
    RefreshButton.AfterLoadDFMValues;
    ConfigurationButton.AfterLoadDFMValues;
    ChartTimer.AfterLoadDFMValues;
  end;
end;

end.

