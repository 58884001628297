unit MainForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Sphinx.WebLogin,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Toast,
  App.Types,
  WEBLib.WebCtrls, WEBLib.ExtCtrls, Data.DB, XData.Web.JsonDataset,
  WEBLib.Grids, WEBLib.DBCtrls, WEBLib.DB, XData.Web.Dataset, Types, Vcl.Grids,
  DB;

type
  TMainPage = class(TForm)
    DashboardLabel: TLabel;
    ExceptionTestLink: TLabel;
    SupportLink: TLabel;
    LogoutLabel: TLabel;
    LoggedInUserLabel: TLabel;
    MainToast: TToast;
    MainMessageDlg: TMessageDlg;
    SysConfigLink: TLabel;
    OrganisationLink: TLabel;
    TenantNameLabel: TLabel;
    userInitials: THTMLSpan;
    UsersAdminLink: TLabel;
    DashboardTimer: TTimer;
    WebButton1: TButton;
    WebEdit1: TEdit;
    MainDataSource: TDataSource;
    DataTable: TDBTableControl;
    XDataWebDataSet1: TXDataWebDataSet;
    WebLabel1: TLabel;
    XDataWebDataSet1Id: TStringField;
    XDataWebDataSet1SphinxId: TStringField;
    WebTableControl1: TTableControl;
    BranchAdminLink: TLabel;
    procedure BranchAdminLinkClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure SysConfigLinkClick(Sender: TObject);
    procedure DashboardLabelClick(Sender: TObject);
    procedure DashboardTimerTimer(Sender: TObject);
    procedure ExceptionTestLinkClick(Sender: TObject);
    procedure LogoutLabelClick(Sender: TObject);
    procedure OrganisationLinkClick(Sender: TObject);
    procedure UsersAdminLinkClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private

    FDisplayedForm: TForm;


    procedure OnFormSelfClose(Sender: TObject);
    // procedure ShowForm(AFormClass: TWebFormClass; AProc: TFormCreatedProc = nil);
    procedure ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc = nil; AlwaysClose: boolean = false);
    function CloseDisplayedForm: boolean;
    [async]
    procedure ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc = nil); async;
    procedure ShowDashboard;
    procedure ErrorHandler(AMessage: string);

    [async]
    procedure SetLabels; async;

  public
    { Public declarations }
    class procedure DisplayPage(const AElementId: string);

    /// <param name="AIcon">
    /// A FontAwesome icon such as 'fad fa-bug'
    /// </param>
    procedure ShowAToast(const ATitle, AMessage, AIcon: string);
    procedure DisplayMessage(const AMessage: string; DlgType: TMsgDlgType = mtInformation);
    function ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType;
        Buttons: TMsgDlgButtons; const AClassName: string = 'SMXDialogBox'):
        TModalResult;
  protected procedure LoadDFMValues; override; end;

var
  MainPage: TMainPage;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  MainDataModule,
  SysConfigForm,
  SMX.Auth.Shared,
  SMX.Web.Document.Utils,
  Auth.Service,
  SharedDataModule,
  DashboardForm,
  DashboardForm2,
  WebForm.Core,
  UserManager, BranchGroupManager;

procedure TMainPage.BranchAdminLinkClick(Sender: TObject);
begin
  ShowForm(TBranchGroupManagerForm);
end;

procedure TMainPage.WebButton1Click(Sender: TObject);
begin
//
  WebEdit1.Text := MainData.TenantId;

  WebLabel1.Caption := XDataWebDataSet1.Connection.URL;

//  XDataWebDataSet1.EntitySetName := 'AppUser';
//  XDataWebDataSet1.Active := true;
//  XDataWebDataSet1.Load;

//  XDataWebDataSet1.Load;
end;

procedure TMainPage.WebFormCreate(Sender: TObject);
begin
  MainData.ShowMessageProc := DisplayMessage;
end;

procedure TMainPage.WebFormShow(Sender: TObject);
begin
  ShowDashboard;
end;

{ TMainAppForm }

procedure TMainPage.DisplayMessage(const AMessage: string; DlgType: TMsgDlgType);
begin
  MainMessageDlg.ShowDialog(AMessage, DlgType, [mbOK]);
end;

class procedure TMainPage.DisplayPage(const AElementId: string);
  procedure AfterCreate(AForm: TObject);
  begin
    TMainPage(AForm).SetLabels;
    // TMainAppForm(AForm).SetLinksAuth;
  end;

begin
  if not Assigned(MainPage) then
  begin
    MainPage := TMainPage.CreateNew(AElementId, @AfterCreate);
  end;
end;

procedure TMainPage.ErrorHandler(AMessage: string);
begin
  ShowAToast('An Error Occurred', AMessage, 'fad fa-bug');
end;

procedure TMainPage.SetLabels;
var
  lPath: string;
begin

  LoggedInUserLabel.Caption := MainData.FirstName;
  { TODO : Add GetOrganisation to UserManager }
//  TenantNameLabel.Caption := MainData.AppUser.OrganisationName;

  lPath := 'tenants/' + AuthService.GetClaimValue(CLAIM_TENANT_ID) + '/tenant-logo.png';

  TDocUtils.writeImageSrc('tenant-logo', lPath);

  UserInitials.HTML.Text := MainData.UserInitials;
  UsersAdminLink.Enabled := True; // MainData.AppUser.IsAdmin; // AuthService.IsAdministrator;
//  OrganisationLink.Enabled := UsersAdminLink.Enabled;

end;

procedure TMainPage.ShowAToast(const ATitle, AMessage, AIcon: string);
const
  MESSAGE_TEMPLATE = '<span class="ToastMessage">%s</span>';
  MESSAGE_TITLE = '<i class="%s fa-fw fa-lg"></i> %s';
var
  lToast: TToastItem;
begin
  lToast := MainToast.Items.Add(Format(MESSAGE_TITLE, [AIcon, ATitle]), Format(MESSAGE_TEMPLATE, [AMessage]));
  lToast.Show;
end;

procedure TMainPage.ShowDashboard;

  procedure DashboardCreated(AForm: TObject);
  begin
    //TDashboard(AForm).JobsDataset := SharedData.JobsDataset;
    //TDashboard2(AForm).Caption := 'XXXXX';
  end;

begin
  //ShowForm(TDashboard, @DashboardCreated);
  ShowForm(TDashboard2, @DashboardCreated, True);
end;

procedure TMainPage.ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc; AlwaysClose: boolean);
  procedure AfterFormCreated(AForm: TObject);
  begin

    if AFormClass.InheritsFrom(TCoreWebForm) then
      TCoreWebForm(AForm).OnSelfClose := OnFormSelfClose;

    if Assigned(AProc) then
      AProc(AForm);

  end;

begin
  // Note that MainPanel is no longer a component. This now just uses a div on the
  // template with that id.
  if Assigned(FDisplayedForm) and (FDisplayedForm.ClassType = AFormClass) and (AlwaysClose = false) then
    Exit;

  if not CloseDisplayedForm then
    Exit;

  Application.CreateForm(AFormClass, 'MainPanel', FDisplayedForm, @AfterFormCreated)

end;

procedure TMainPage.ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc);
var
  AForm: TForm;
  lRetval: TModalResult;
begin
  AForm := AFormClass.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TForm, AForm.Load());
    lRetval := await(TModalResult, AForm.Execute);
    if (lRetval = mrOK) and (OnSuccessProc <> nil) then
      OnSuccessProc();
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TMainPage.SysConfigLinkClick(Sender: TObject);
begin
//  ShowPopUp(TSysConfig);
end;

function TMainPage.CloseDisplayedForm: boolean;
begin
  Result := True;
  if Assigned(FDisplayedForm) then
  begin
    if FDisplayedForm.ClassType.InheritsFrom(TCoreWebForm) then
    begin
      Result := TCoreWebForm(FDisplayedForm).CanClose;
      if Result then
        TCoreWebForm(FDisplayedForm).TearDown;
    end;
    if not Result then
      Exit;
    FDisplayedForm.Free;
    FDisplayedForm := nil;
  end;
end;

function TMainPage.ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType;
  Buttons: TMsgDlgButtons; const AClassName: string = 'SMXDialogBox'): TModalResult;
begin
//  MainMessageDlg.ElementDialogClassName := AClassName;

  Result := MainMessageDlg.ShowDialog(AMessage, DlgType, Buttons, nil);
end;

procedure TMainPage.DashboardLabelClick(Sender: TObject);
begin
  ShowDashboard;
end;

procedure TMainPage.DashboardTimerTimer(Sender: TObject);
begin
  DashboardTimer.Enabled := false;
  //ShowDashboard;
end;

procedure TMainPage.ExceptionTestLinkClick(Sender: TObject);
begin
  raise Exception.Create('Test Exception Raised');
end;

procedure TMainPage.LogoutLabelClick(Sender: TObject);
begin
  MainData.Logout;
end;

procedure TMainPage.OnFormSelfClose(Sender: TObject);
begin
//  ShowDashboard;
end;

procedure TMainPage.OrganisationLinkClick(Sender: TObject);
begin
//  ShowForm(TCharityEditForm);
end;

procedure TMainPage.UsersAdminLinkClick(Sender: TObject);
begin
  ShowForm(TUserManagerList);
end;

procedure TMainPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DashboardLabel := TLabel.Create('DashboardLink');
  ExceptionTestLink := TLabel.Create('ExceptionTestLink');
  SupportLink := TLabel.Create('SupportLink');
  LogoutLabel := TLabel.Create('LogoutLink');
  LoggedInUserLabel := TLabel.Create('UserNameLabel');
  SysConfigLink := TLabel.Create('SysConfigLink');
  OrganisationLink := TLabel.Create('OrganisationLink');
  TenantNameLabel := TLabel.Create('TenantNameLabel');
  UsersAdminLink := TLabel.Create('UsersAdminLink');
  WebLabel1 := TLabel.Create(Self);
  BranchAdminLink := TLabel.Create('BranchAdminLink');
  MainMessageDlg := TMessageDlg.Create(Self);
  userInitials := THTMLSpan.Create('userInitials');
  WebButton1 := TButton.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  DataTable := TDBTableControl.Create('DataTable');
  WebTableControl1 := TTableControl.Create(Self);
  MainToast := TToast.Create(Self);
  DashboardTimer := TTimer.Create(Self);
  MainDataSource := TDataSource.Create(Self);
  XDataWebDataSet1 := TXDataWebDataSet.Create(Self);
  XDataWebDataSet1Id := TStringField.Create(Self);
  XDataWebDataSet1SphinxId := TStringField.Create(Self);

  DashboardLabel.BeforeLoadDFMValues;
  ExceptionTestLink.BeforeLoadDFMValues;
  SupportLink.BeforeLoadDFMValues;
  LogoutLabel.BeforeLoadDFMValues;
  LoggedInUserLabel.BeforeLoadDFMValues;
  SysConfigLink.BeforeLoadDFMValues;
  OrganisationLink.BeforeLoadDFMValues;
  TenantNameLabel.BeforeLoadDFMValues;
  UsersAdminLink.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  BranchAdminLink.BeforeLoadDFMValues;
  MainMessageDlg.BeforeLoadDFMValues;
  userInitials.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  WebTableControl1.BeforeLoadDFMValues;
  MainToast.BeforeLoadDFMValues;
  DashboardTimer.BeforeLoadDFMValues;
  MainDataSource.BeforeLoadDFMValues;
  XDataWebDataSet1.BeforeLoadDFMValues;
  XDataWebDataSet1Id.BeforeLoadDFMValues;
  XDataWebDataSet1SphinxId.BeforeLoadDFMValues;
  try
    Name := 'MainPage';
    Width := 802;
    Height := 718;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    DashboardLabel.SetParentComponent(Self);
    DashboardLabel.Name := 'DashboardLabel';
    DashboardLabel.Left := 28;
    DashboardLabel.Top := 8;
    DashboardLabel.Width := 57;
    DashboardLabel.Height := 15;
    DashboardLabel.Cursor := crHandPoint;
    DashboardLabel.Caption := 'Dashboard';
    DashboardLabel.ElementPosition := epIgnore;
    DashboardLabel.HeightStyle := ssAuto;
    DashboardLabel.HeightPercent := 100.000000000000000000;
    DashboardLabel.WidthStyle := ssAuto;
    DashboardLabel.WidthPercent := 100.000000000000000000;
    SetEvent(DashboardLabel, Self, 'OnClick', 'DashboardLabelClick');
    ExceptionTestLink.SetParentComponent(Self);
    ExceptionTestLink.Name := 'ExceptionTestLink';
    ExceptionTestLink.Left := 28;
    ExceptionTestLink.Top := 40;
    ExceptionTestLink.Width := 75;
    ExceptionTestLink.Height := 15;
    ExceptionTestLink.Cursor := crHandPoint;
    ExceptionTestLink.Caption := 'Exception Test';
    ExceptionTestLink.ElementFont := efCSS;
    ExceptionTestLink.HeightStyle := ssAuto;
    ExceptionTestLink.HeightPercent := 100.000000000000000000;
    ExceptionTestLink.Visible := False;
    ExceptionTestLink.WidthPercent := 100.000000000000000000;
    SetEvent(ExceptionTestLink, Self, 'OnClick', 'ExceptionTestLinkClick');
    SupportLink.SetParentComponent(Self);
    SupportLink.Name := 'SupportLink';
    SupportLink.Left := 28;
    SupportLink.Top := 80;
    SupportLink.Width := 42;
    SupportLink.Height := 15;
    SupportLink.Caption := 'Support';
    SupportLink.ElementFont := efCSS;
    SupportLink.ElementPosition := epIgnore;
    SupportLink.HeightStyle := ssAuto;
    SupportLink.HeightPercent := 100.000000000000000000;
    SupportLink.Visible := False;
    SupportLink.WidthStyle := ssAuto;
    SupportLink.WidthPercent := 100.000000000000000000;
    LogoutLabel.SetParentComponent(Self);
    LogoutLabel.Name := 'LogoutLabel';
    LogoutLabel.Left := 292;
    LogoutLabel.Top := 69;
    LogoutLabel.Width := 38;
    LogoutLabel.Height := 15;
    LogoutLabel.Cursor := crHandPoint;
    LogoutLabel.Caption := 'Logout';
    LogoutLabel.ElementPosition := epIgnore;
    LogoutLabel.HeightStyle := ssAuto;
    LogoutLabel.HeightPercent := 100.000000000000000000;
    LogoutLabel.HTMLType := tDIV;
    LogoutLabel.Visible := False;
    LogoutLabel.WidthStyle := ssAuto;
    LogoutLabel.WidthPercent := 100.000000000000000000;
    SetEvent(LogoutLabel, Self, 'OnClick', 'LogoutLabelClick');
    LoggedInUserLabel.SetParentComponent(Self);
    LoggedInUserLabel.Name := 'LoggedInUserLabel';
    LoggedInUserLabel.Left := 292;
    LoggedInUserLabel.Top := 24;
    LoggedInUserLabel.Width := 101;
    LoggedInUserLabel.Height := 15;
    LoggedInUserLabel.Caption := 'LoggedInUserLabel';
    LoggedInUserLabel.ElementPosition := epIgnore;
    LoggedInUserLabel.HeightStyle := ssAuto;
    LoggedInUserLabel.HeightPercent := 100.000000000000000000;
    LoggedInUserLabel.HTMLType := tDIV;
    LoggedInUserLabel.Visible := False;
    LoggedInUserLabel.WidthStyle := ssAuto;
    LoggedInUserLabel.WidthPercent := 100.000000000000000000;
    SysConfigLink.SetParentComponent(Self);
    SysConfigLink.Name := 'SysConfigLink';
    SysConfigLink.Left := 21;
    SysConfigLink.Top := 536;
    SysConfigLink.Width := 77;
    SysConfigLink.Height := 15;
    SysConfigLink.Cursor := crHandPoint;
    SysConfigLink.Caption := 'System Config';
    SysConfigLink.ElementFont := efCSS;
    SysConfigLink.HeightStyle := ssAuto;
    SysConfigLink.HeightPercent := 100.000000000000000000;
    SysConfigLink.Visible := False;
    SysConfigLink.WidthPercent := 100.000000000000000000;
    SetEvent(SysConfigLink, Self, 'OnClick', 'SysConfigLinkClick');
    OrganisationLink.SetParentComponent(Self);
    OrganisationLink.Name := 'OrganisationLink';
    OrganisationLink.Left := 21;
    OrganisationLink.Top := 443;
    OrganisationLink.Width := 68;
    OrganisationLink.Height := 15;
    OrganisationLink.Caption := 'Organisation';
    OrganisationLink.HeightPercent := 100.000000000000000000;
    OrganisationLink.Visible := False;
    OrganisationLink.WidthPercent := 100.000000000000000000;
    SetEvent(OrganisationLink, Self, 'OnClick', 'OrganisationLinkClick');
    TenantNameLabel.SetParentComponent(Self);
    TenantNameLabel.Name := 'TenantNameLabel';
    TenantNameLabel.Left := 292;
    TenantNameLabel.Top := 45;
    TenantNameLabel.Width := 96;
    TenantNameLabel.Height := 15;
    TenantNameLabel.Caption := 'TenantNameLabel';
    TenantNameLabel.ElementPosition := epIgnore;
    TenantNameLabel.HeightStyle := ssAuto;
    TenantNameLabel.HeightPercent := 100.000000000000000000;
    TenantNameLabel.HTMLType := tDIV;
    TenantNameLabel.Visible := False;
    TenantNameLabel.WidthStyle := ssAuto;
    TenantNameLabel.WidthPercent := 100.000000000000000000;
    UsersAdminLink.SetParentComponent(Self);
    UsersAdminLink.Name := 'UsersAdminLink';
    UsersAdminLink.Left := 21;
    UsersAdminLink.Top := 464;
    UsersAdminLink.Width := 97;
    UsersAdminLink.Height := 15;
    UsersAdminLink.Cursor := crHandPoint;
    UsersAdminLink.Caption := 'User Management';
    UsersAdminLink.HeightPercent := 100.000000000000000000;
    UsersAdminLink.WidthPercent := 100.000000000000000000;
    SetEvent(UsersAdminLink, Self, 'OnClick', 'UsersAdminLinkClick');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 456;
    WebLabel1.Top := 176;
    WebLabel1.Width := 58;
    WebLabel1.Height := 15;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.Visible := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    BranchAdminLink.SetParentComponent(Self);
    BranchAdminLink.Name := 'BranchAdminLink';
    BranchAdminLink.Left := 21;
    BranchAdminLink.Top := 496;
    BranchAdminLink.Width := 147;
    BranchAdminLink.Height := 15;
    BranchAdminLink.Cursor := crHandPoint;
    BranchAdminLink.Caption := 'Branch Group Management';
    BranchAdminLink.HeightPercent := 100.000000000000000000;
    BranchAdminLink.WidthPercent := 100.000000000000000000;
    SetEvent(BranchAdminLink, Self, 'OnClick', 'BranchAdminLinkClick');
    MainMessageDlg.SetParentComponent(Self);
    MainMessageDlg.Name := 'MainMessageDlg';
    MainMessageDlg.Left := 609;
    MainMessageDlg.Top := 8;
    MainMessageDlg.Width := 24;
    MainMessageDlg.Height := 24;
    MainMessageDlg.Buttons := [];
    MainMessageDlg.Opacity := 0.200000000000000000;
    MainMessageDlg.ElementButtonClassName := 'btn';
    MainMessageDlg.ElementDialogClassName := 'SMXDialogBox shadow-lg mb-5 bg-white rounded';
    MainMessageDlg.ElementTitleClassName := 'text-body';
    MainMessageDlg.ElementContentClassName := 'text-body';
    userInitials.SetParentComponent(Self);
    userInitials.Name := 'userInitials';
    userInitials.Left := 456;
    userInitials.Top := 20;
    userInitials.Width := 100;
    userInitials.Height := 40;
    userInitials.HeightStyle := ssAuto;
    userInitials.WidthStyle := ssAuto;
    userInitials.ChildOrder := 28;
    userInitials.ElementPosition := epIgnore;
    userInitials.Role := '';
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 456;
    WebButton1.Top := 112;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Tenant Id';
    WebButton1.ChildOrder := 11;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.Visible := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 392;
    WebEdit1.Top := 143;
    WebEdit1.Width := 241;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 12;
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.Visible := False;
    WebEdit1.WidthPercent := 100.000000000000000000;
    DataTable.SetParentComponent(Self);
    DataTable.Name := 'DataTable';
    DataTable.Left := 208;
    DataTable.Top := 278;
    DataTable.Width := 513;
    DataTable.Height := 249;
    DataTable.HeightStyle := ssAuto;
    DataTable.WidthStyle := ssAuto;
    DataTable.BorderColor := clSilver;
    DataTable.ChildOrder := 4;
    DataTable.ElementFont := efCSS;
    DataTable.ElementHeaderClassName := 'thead-light';
    DataTable.ElementPosition := epIgnore;
    DataTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    DataTable.Visible := False;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'SphinxId';
      Title := 'Id';
    end;
    DataTable.DataSource := MainDataSource;
    WebTableControl1.SetParentComponent(Self);
    WebTableControl1.Name := 'WebTableControl1';
    WebTableControl1.Left := 272;
    WebTableControl1.Top := 443;
    WebTableControl1.Width := 300;
    WebTableControl1.Height := 200;
    WebTableControl1.BorderColor := clSilver;
    WebTableControl1.ChildOrder := 16;
    WebTableControl1.Visible := False;
    MainToast.SetParentComponent(Self);
    MainToast.Name := 'MainToast';
    MainToast.AutoHideDelay := 3000;
    MainToast.Position := tpBottomRight;
    MainToast.Left := 748;
    MainToast.Top := 8;
    DashboardTimer.SetParentComponent(Self);
    DashboardTimer.Name := 'DashboardTimer';
    DashboardTimer.Enabled := False;
    SetEvent(DashboardTimer, Self, 'OnTimer', 'DashboardTimerTimer');
    DashboardTimer.Left := 688;
    DashboardTimer.Top := 8;
    MainDataSource.SetParentComponent(Self);
    MainDataSource.Name := 'MainDataSource';
    MainDataSource.DataSet := XDataWebDataSet1;
    MainDataSource.Left := 344;
    MainDataSource.Top := 176;
    XDataWebDataSet1.SetParentComponent(Self);
    XDataWebDataSet1.Name := 'XDataWebDataSet1';
    XDataWebDataSet1.EntitySetName := 'User';
    XDataWebDataSet1.Connection := MainData.DataConnection;
    XDataWebDataSet1.Left := 224;
    XDataWebDataSet1.Top := 176;
    XDataWebDataSet1Id.SetParentComponent(XDataWebDataSet1);
    XDataWebDataSet1Id.Name := 'XDataWebDataSet1Id';
    XDataWebDataSet1Id.FieldName := 'Id';
    XDataWebDataSet1SphinxId.SetParentComponent(XDataWebDataSet1);
    XDataWebDataSet1SphinxId.Name := 'XDataWebDataSet1SphinxId';
    XDataWebDataSet1SphinxId.FieldName := 'SphinxId';
    XDataWebDataSet1SphinxId.Size := 30;
  finally
    DashboardLabel.AfterLoadDFMValues;
    ExceptionTestLink.AfterLoadDFMValues;
    SupportLink.AfterLoadDFMValues;
    LogoutLabel.AfterLoadDFMValues;
    LoggedInUserLabel.AfterLoadDFMValues;
    SysConfigLink.AfterLoadDFMValues;
    OrganisationLink.AfterLoadDFMValues;
    TenantNameLabel.AfterLoadDFMValues;
    UsersAdminLink.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    BranchAdminLink.AfterLoadDFMValues;
    MainMessageDlg.AfterLoadDFMValues;
    userInitials.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    WebTableControl1.AfterLoadDFMValues;
    MainToast.AfterLoadDFMValues;
    DashboardTimer.AfterLoadDFMValues;
    MainDataSource.AfterLoadDFMValues;
    XDataWebDataSet1.AfterLoadDFMValues;
    XDataWebDataSet1Id.AfterLoadDFMValues;
    XDataWebDataSet1SphinxId.AfterLoadDFMValues;
  end;
end;

end.
