Unit SMX.XData.Utils;

Interface

Uses
  XData.Web.Client,
  JS;

Type
  TsmxXDataUtils = Class

  Public
    Class Function AsString(Response: TXDataClientResponse): String;
    Class Function AsInteger(Response: TXDataClientResponse): integer;
    Class Function AsFloat(Response: TXDataClientResponse): double;
    Class Function AsBoolean(Response: TXDataClientResponse): boolean;
    Class Function AsObject(Response: TXDataClientResponse): TJSObject;
    Class Function AsArray(Response: TXDataClientResponse): TJSArray;
  End;

Implementation

{ TsmxXDataUtils }

Class Function TsmxXDataUtils.AsArray(Response: TXDataClientResponse): TJSArray;
Begin
  Result := JS.ToArray(Response.ResultAsObject['value']);
End;

class function TsmxXDataUtils.AsBoolean(Response: TXDataClientResponse): boolean;
begin
    Result := JS.toBoolean(Response.ResultAsObject['value']);
end;

Class Function TsmxXDataUtils.AsFloat(Response: TXDataClientResponse): double;
Begin
  Result := JS.toNumber(Response.ResultAsObject['value']);
End;

Class Function TsmxXDataUtils.AsInteger(Response: TXDataClientResponse): integer;
Begin
  Result := JS.ToInteger(Response.ResultAsObject['value']);
End;

Class Function TsmxXDataUtils.AsObject(Response: TXDataClientResponse): TJSObject;
Begin
  Result := JS.ToObject(Response.ResultAsObject['value']);
End;

Class Function TsmxXDataUtils.AsString(Response: TXDataClientResponse): String;
Begin
  Result := JS.ToString(Response.ResultAsObject['value']);
End;

End.
