Unit BranchGroupManager;

Interface

Uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.WebCtrls,
  Data.DB,
  WEBLib.DB,
  WEBLib.CDS;

Type
  TBranchGroupManagerForm = Class(TCoreWebForm)
    BranchGroupDataset: TClientDataSet;
    BranchGroupSource: TDataSource;
    BranchGroupTable: TDBTableControl;
    BranchGroupDatasetId: TFloatField;
    BranchGroupDatasetStatus: TStringField;
    BranchGroupDatasetName: TStringField;
    Procedure BranchGroupTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: String;
      AElement: TJSHTMLElementRecord);
    Procedure WebFormCreate(Sender: TObject);
  Private
    { Private declarations }
    [Async]
    Procedure LoadData; async;
    Procedure AddRowActions(Const ARecordId: String; AParentElement: TJSHTMLElement);
    [Async]
    Procedure ToggleStatus(Const RecordId: String); async;
  Public
    { Public declarations }
  protected procedure LoadDFMValues; override; End;

Var
  BranchGroupManagerForm: TBranchGroupManagerForm;

Implementation

{$R *.dfm}

Uses
  XData.Web.Client,
  SMX.Web.Layout.Utils,
  Sys.Return.Types,
  MainDataModule,
  CSY.Enumerations, SMX.XData.Utils;

Procedure TBranchGroupManagerForm.WebFormCreate(Sender: TObject);
Begin
  Inherited;
  LoadData;
End;

{ TBranchGroupManagerForm }

Procedure TBranchGroupManagerForm.AddRowActions(Const ARecordId: String; AParentElement: TJSHTMLElement);

  Procedure ToggleClick(Sender: TObject);
  Begin
    ToggleStatus(ARecordId);
  End;

Var
  Span: THTMLSpan;
Begin

  If BranchGroupDatasetStatus.Value = Branch_Group_Status[TBranchGroupStatus.NotSelectable] Then
  Begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-circle-check', 'enable selection');
    Span.OnClick := @ToggleClick;
  End
  Else
  Begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-ban', 'disable selection');
    Span.OnClick := @ToggleClick;
  End;

End;

Procedure TBranchGroupManagerForm.BranchGroupTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField;
  AValue: String; AElement: TJSHTMLElementRecord);
Var
  RecordId: String;
Begin
  If ARow = 0 Then
    Exit;

  If BranchGroupTable.Columns[ACol].Title = 'Actions' Then
  Begin
    RecordId := BranchGroupDataset.FieldByName('Id').AsString;
    AddRowActions(RecordId, AElement.Element);
  End;
End;

Procedure TBranchGroupManagerForm.LoadData;
Const
  svc_name = 'IBranchService.GetAllBranchGroups';
Var
  lRetval: TXDataClientResponse;
  lResponse: TJSObject;
  lData: TJSArray;
Begin

  BranchGroupDataset.Active := False;
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(svc_name, []));
  lResponse := lRetval.ResultAsObject;
  lData := TJSArray(lResponse['value']);

  BranchGroupDataset.Rows := lData;
  BranchGroupDataset.Active := True;

End;

Procedure TBranchGroupManagerForm.ToggleStatus(Const RecordId: String);
Const
  svc_name = 'IBranchService.ToggleBranchGroupStatus';
Var
  Id: Double;
  lRetval: TXDataClientResponse;
  lStatus: string;
Begin
  Id := RecordId.ToDouble;
  If Not BranchGroupDataset.Locate('Id', Id, []) Then
    Raise Exception.Create('cannot locate Id ' + RecordId);

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(svc_name, [Id]));
  lStatus :=TsmxXDataUtils.AsString(lRetval);
  BranchGroupDataset.Edit;
  BranchGroupDatasetStatus.Value := lStatus;
  BranchGroupDataset.Post;
End;

procedure TBranchGroupManagerForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BranchGroupTable := TDBTableControl.Create('UserDataTable');
  BranchGroupDataset := TClientDataSet.Create(Self);
  BranchGroupDatasetId := TFloatField.Create(Self);
  BranchGroupDatasetName := TStringField.Create(Self);
  BranchGroupDatasetStatus := TStringField.Create(Self);
  BranchGroupSource := TDataSource.Create(Self);

  BranchGroupTable.BeforeLoadDFMValues;
  BranchGroupDataset.BeforeLoadDFMValues;
  BranchGroupDatasetId.BeforeLoadDFMValues;
  BranchGroupDatasetName.BeforeLoadDFMValues;
  BranchGroupDatasetStatus.BeforeLoadDFMValues;
  BranchGroupSource.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    BranchGroupTable.SetParentComponent(Self);
    BranchGroupTable.Name := 'BranchGroupTable';
    BranchGroupTable.Left := 32;
    BranchGroupTable.Top := 136;
    BranchGroupTable.Width := 513;
    BranchGroupTable.Height := 249;
    BranchGroupTable.HeightStyle := ssAuto;
    BranchGroupTable.WidthStyle := ssAuto;
    BranchGroupTable.BorderColor := clSilver;
    BranchGroupTable.ChildOrder := 4;
    BranchGroupTable.ElementFont := efCSS;
    BranchGroupTable.ElementHeaderClassName := 'thead-light';
    BranchGroupTable.ElementPosition := epIgnore;
    BranchGroupTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    SetEvent(BranchGroupTable, Self, 'OnGetCellChildren', 'BranchGroupTableGetCellChildren');
    BranchGroupTable.Columns.Clear;
    with BranchGroupTable.Columns.Add do
    begin
      ElementClassName := 'bgIdCol';
      DataField := 'Id';
      Title := 'Id';
      TitleElementClassName := 'bgIdColTitle';
    end;
    with BranchGroupTable.Columns.Add do
    begin
      ElementClassName := 'bgNameCol';
      DataField := 'Name';
      Title := 'Name';
      TitleElementClassName := 'bgNameColTitle';
    end;
    with BranchGroupTable.Columns.Add do
    begin
      ElementClassName := 'bgStatusCol';
      DataField := 'Status';
      Title := 'Status';
      TitleElementClassName := 'bgStatusColTitle';
    end;
    with BranchGroupTable.Columns.Add do
    begin
      ElementClassName := 'bgActionsCol';
      Title := 'Actions';
      TitleElementClassName := 'bgActionsColTitle';
    end;
    BranchGroupTable.DataSource := BranchGroupSource;
    BranchGroupDataset.SetParentComponent(Self);
    BranchGroupDataset.Name := 'BranchGroupDataset';
    BranchGroupDataset.Left := 176;
    BranchGroupDataset.Top := 16;
    BranchGroupDatasetId.SetParentComponent(BranchGroupDataset);
    BranchGroupDatasetId.Name := 'BranchGroupDatasetId';
    BranchGroupDatasetId.FieldName := 'Id';
    BranchGroupDatasetName.SetParentComponent(BranchGroupDataset);
    BranchGroupDatasetName.Name := 'BranchGroupDatasetName';
    BranchGroupDatasetName.FieldName := 'Name';
    BranchGroupDatasetName.Size := 64;
    BranchGroupDatasetStatus.SetParentComponent(BranchGroupDataset);
    BranchGroupDatasetStatus.Name := 'BranchGroupDatasetStatus';
    BranchGroupDatasetStatus.FieldName := 'Status';
    BranchGroupSource.SetParentComponent(Self);
    BranchGroupSource.Name := 'BranchGroupSource';
    BranchGroupSource.DataSet := BranchGroupDataset;
    BranchGroupSource.Left := 280;
    BranchGroupSource.Top := 24;
  finally
    BranchGroupTable.AfterLoadDFMValues;
    BranchGroupDataset.AfterLoadDFMValues;
    BranchGroupDatasetId.AfterLoadDFMValues;
    BranchGroupDatasetName.AfterLoadDFMValues;
    BranchGroupDatasetStatus.AfterLoadDFMValues;
    BranchGroupSource.AfterLoadDFMValues;
  end;
end;

End.
