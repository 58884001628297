Unit CSY.Enumerations;

Interface

{$IFNDEF PAS2JS}
Uses
  Aurelius.Mapping.Attributes;
{$ENDIF}

{$SCOPEDENUMS ON}

Type

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'None,Branch,BranchGroup')]
{$ENDIF}
  TBranchIdType = (NotSpecified, Branch, BranchGroup);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Selectable,NotSelectable')]
{$ENDIF}
  TBranchGroupStatus = (Selectable, NotSelectable);


  [Enumeration(TEnumMappingType.emString, 'Pending,Trial,Active,Suspended,Deleted,Admin')]
  TOrganisationStatus = (
    /// <summary>
    /// Initial setup in process
    /// </summary>
    Pending, Trial,
    /// <summary>
    /// Available
    /// </summary>
    Active, Suspended,
    /// <summary>
    /// No longer exists
    /// </summary>
    Deleted, Admin);

  [Enumeration(TEnumMappingType.emString, 'Standard,Parent')]
  TOrganisationLevel = (Standard, Parent);



const

Branch_Group_Status: array[TBranchGroupStatus] of string  = ('Selectable', 'NotSelectable');

Implementation

{$SCOPEDENUMS OFF}

End.
