unit WebForm.Core;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TCoreWebForm = class(TForm)
  private
    { Private declarations }
    FOnSelfClose: TNotifyEvent;
  protected
    procedure ButtonValid(const AButton: TButton);
    procedure ButtonInvalid(const AButton: TButton);
    procedure ButtonValidity(const AButton: TButton; const AIsValid: boolean);

    procedure SelfClose;
    /// <summary>
    ///   This calls the routine in MainForm
    /// </summary>
    /// <param name="AIcon">
    ///   A FontAwesome icon such as 'fad fa-bug'
    /// </param>
    procedure ShowAToast(const ATitle, AMessage, AIcon: string);
    /// <summary>
    ///   Calls the DisplayMessage method on the MainForm
    /// </summary>
    procedure DisplayMessage(const AMessage: string; DlgType: TMsgDlgType = mtInformation);
    /// <summary>
    ///   Calls the ConfirmationRequest method on the MainForm
    /// </summary>
    function ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType; Buttons: TMsgDlgButtons): TModalResult;
    [async]
    procedure ShowAPopUp(AFormClass: TFormClass); async;
  public
    { Public declarations }
    function CanClose: boolean; virtual;
    procedure TearDown; virtual;
    procedure AfterCreated; virtual;
    property OnSelfClose: TNotifyEvent write FOnSelfClose;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses MainForm;

{ TCoreWebForm }

procedure TCoreWebForm.AfterCreated;
begin

end;

procedure TCoreWebForm.ButtonInvalid(const AButton: TButton);
begin


end;

procedure TCoreWebForm.ButtonValid(const AButton: TButton);
begin

end;

procedure TCoreWebForm.ButtonValidity(const AButton: TButton; const AIsValid: boolean);
begin

end;

function TCoreWebForm.CanClose: boolean;
begin
  Result := True;
end;

function TCoreWebForm.ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType;
  Buttons: TMsgDlgButtons): TModalResult;
begin
  Result := MainPage.ConfirmationRequest(AMessage, DlgType, Buttons);
end;

procedure TCoreWebForm.DisplayMessage(const AMessage: string; DlgType: TMsgDlgType);
begin
  MainPage.DisplayMessage(AMessage, DlgType);
end;

procedure TCoreWebForm.SelfClose;
begin
  if Assigned(FOnSelfClose) then
    FOnSelfClose(Self);
end;

procedure TCoreWebForm.ShowAPopUp(AFormClass: TFormClass);
var
  AForm: TForm;
begin
  AForm := AFormClass.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm PopUp';
    await(TForm, AForm.Load());
    await(TModalResult, AForm.Execute);
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TCoreWebForm.ShowAToast(const ATitle, AMessage, AIcon: string);
begin
  MainPage.ShowAToast(ATitle, AMessage, AIcon);
end;

procedure TCoreWebForm.TearDown;
begin
  // for descendants
end;

procedure TCoreWebForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'CoreWebForm';
    Width := 640;
    Height := 480;
  finally
  end;
end;

end.
