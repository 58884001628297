unit UserManager.Utils;

interface

uses
  Data.DB,
  Sys.Return.Types;

type

  TUserManagerUtils = class
  public
    class function DatasetToUser(Dataset: TDataset): TAppUser;
    class procedure UserToDataset(AUser: TAppUser; Dataset: TDataset);
  end;

implementation

{ TUserManagerUtils }

class function TUserManagerUtils.DatasetToUser(Dataset: TDataset): TAppUser;
begin
  Result := TAppUser.Create;
  Result.InternalId := Dataset.FieldByName('InternalId').AsInteger;
  Result.Id := Dataset.FieldByName('Id').AsString;
  Result.OrganisationId := Dataset.FieldByName('OrganisationId').AsInteger;
  Result.TenantId := Dataset.FieldByName('TenantId').AsString;
  Result.JobTitle := Dataset.FieldByName('JobTitle').AsString;
  Result.JobPosition := Dataset.FieldByName('JobPosition').AsString;
  Result.Title := Dataset.FieldByName('Title').AsString;
  Result.GivenName := Dataset.FieldByName('GivenName').AsString;
  Result.LastName := Dataset.FieldByName('LastName').AsString;
  Result.Email := Dataset.FieldByName('Email').AsString;
  Result.EmailConfirmed := Dataset.FieldByName('EmailConfirmed').AsBoolean;
  Result.UserStatus := Dataset.FieldByName('UserStatus').AsString;
  Result.UserScope := Dataset.FieldByName('UserScope').AsString;
  Result.PhoneNumber := Dataset.FieldByName('PhoneNumber').AsString;
  Result.BranchIdType := Dataset.FieldByName('BranchIdType').AsString;
  Result.BranchId := Dataset.FieldByName('BranchId').AsFloat;
  Result.UserName := Dataset.FieldByName('UserName').AsString;
  Result.UserPassword := Dataset.FieldByName('UserPassword').AsString;
end;

class procedure TUserManagerUtils.UserToDataset(AUser: TAppUser; Dataset: TDataset);
begin
  Dataset.FieldByName('Id').AsString := AUser.Id;
  Dataset.FieldByName('InternalId').AsInteger := AUser.InternalId;
  Dataset.FieldByName('OrganisationId').AsInteger := AUser.OrganisationId;
  Dataset.FieldByName('TenantId').AsString := AUser.TenantId;
  Dataset.FieldByName('JobTitle').AsString := AUser.JobTitle;
  Dataset.FieldByName('JobPosition').AsString := AUser.JobPosition;
  Dataset.FieldByName('Title').AsString := AUser.Title;
  Dataset.FieldByName('GivenName').AsString := AUser.GivenName;
  Dataset.FieldByName('LastName').AsString := AUser.LastName;
  Dataset.FieldByName('Email').AsString := AUser.Email;
  Dataset.FieldByName('EmailConfirmed').AsBoolean := AUser.EmailConfirmed;
  Dataset.FieldByName('UserStatus').AsString := AUser.UserStatus;
  Dataset.FieldByName('UserScope').AsString := AUser.UserScope;
  Dataset.FieldByName('PhoneNumber').AsString := AUser.PhoneNumber;
  Dataset.FieldByName('BranchIdType').AsString := AUser.BranchIdType;
  Dataset.FieldByName('BranchId').AsFloat := AUser.BranchId;
  Dataset.FieldByName('UserName').AsString := AUser.UserName;
end;

end.
