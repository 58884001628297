unit UserManager;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  Data.DB,
  WEBLib.CDS,
  WEBLib.DB,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.REST,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Sys.Return.Types;

type
  TUserManagerList = class(TCoreWebForm)
    UserDataTable: TDBTableControl;
    UsersDataSource: TDataSource;
    UsersDataset: TClientDataSet;
    UsersDatasetOrganisationId: TIntegerField;
    UsersDatasetTenantId: TStringField;
    UsersDatasetJobTitle: TStringField;
    UsersDatasetJobPosition: TStringField;
    UsersDatasetTitle: TStringField;
    UsersDatasetLastName: TStringField;
    UsersDatasetEmail: TStringField;
    UsersDatasetPhoneNumber: TStringField;
    UsersDatasetUserStatus: TStringField;
    UsersDatasetUserScope: TStringField;
    UsersDatasetInternalId: TIntegerField;
    NewUserButton: TButton;
    UsersDatasetGivenName: TStringField;
    UsersDatasetUserName: TStringField;
    UsersDatasetId: TStringField;
    UsersDatasetBranchId: TFloatField;
    UsersDatasetBranchIdType: TStringField;
    UsersDatasetEmailConfirmed: TBooleanField;
    UsersDatasetUserPassword: TStringField;
    procedure NewUserButtonClick(Sender: TObject);
    procedure UserDataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
      AElement: TJSHTMLElementRecord);
    procedure UsersDatasetCanSubmitGetText(Sender: TField; var Text: string;
        DisplayText: Boolean);
    procedure UsersDatasetHMRCRegisteredGetText(Sender: TField; var Text: string;
        DisplayText: Boolean);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    [Async]
    procedure LoadData; async;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);
    [Async]
    procedure EditRecord(const ARecordId: string); async;
    [Async]
    function UpdateUser: Boolean; async;

  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  UserManagerList: TUserManagerList;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  WEBLib.WebCtrls,
  MainDataModule,
  UserEdit,
  SMX.Web.Layout.Utils,
  UserManager.Utils,
  SMX.Web.Utils;

procedure TUserManagerList.WebFormCreate(Sender: TObject);
begin
  inherited;
  LoadData;

end;

{ TUserManagerForm }

procedure TUserManagerList.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure EditClick(Sender: TObject);
  begin
    EditRecord(ARecordId);
  end;

//const
//  Editable_Statii = 'Active,Inactive';
var
  Span: THTMLSpan;
begin
{ TODO : Need to be much cleverer with these. have to look at their status and available seats to see if their status and/or scope can be edited }
//  if Editable_Statii.Contains(UsersDatasetUserStatus.Value) then
//  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-edit', 'Edit');
    Span.OnClick := @EditClick;
//  end;

end;

procedure TUserManagerList.EditRecord(const ARecordId: string);
var
  AForm: TUserEditForm;
  Retval: TModalResult;
  lUpdateResult: Boolean;
begin
  AForm := TUserEditForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm PopUp';
    await(TUserEditForm, AForm.Load());

    if ARecordId = '' then
    begin
      UsersDataset.Insert;
    end
    else
    begin
      UsersDataset.Locate('InternalId', ARecordId, []);
      UsersDataset.Edit;
    end;

    AForm.Dataset := UsersDataset;
    // Aform.AfterCreated;
    Retval := await(TModalResult, AForm.Execute);
    if Retval = mrOk then
    begin
      lUpdateResult := await(UpdateUser);
      UsersDatasetUserPassword.Value := '';
      UsersDataset.Post;
    end
    else
      UsersDataset.Cancel;
  finally
    AForm.Free;
    AForm := nil;
  end;

end;

procedure TUserManagerList.LoadData;
const
  svc_name = 'IUserManagerService.GetUserList';
var
  lRetval: TXDataClientResponse;
  lResponse: TJSObject;
  lData: TJSArray;
begin

  UsersDataset.Active := False;
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(svc_name, []));
  lResponse := lRetval.ResultAsObject;
  lData := TJSArray(lResponse['value']);

  UsersDataset.Rows := lData;
  UsersDataset.Active := True;

end;

procedure TUserManagerList.NewUserButtonClick(Sender: TObject);
begin
  EditRecord('');
end;

function TUserManagerList.UpdateUser: Boolean;
const
  svc_name = 'IUserManagerService.UpdateUser';
var
  AUser, AResponseUser: TAppUser;
  lRetval: TXDataClientResponse;
  lResponse: TJSObject;
begin
  AUser := TUserManagerUtils.DatasetToUser(UsersDataset);
  try
    lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(svc_name, [AUser]));
    lResponse := lRetval.ResultAsObject;
    AResponseUser := TAppUser.Create;
    try
      AResponseUser.Assign(lResponse);
      TUserManagerUtils.UserToDataset(AResponseUser, UsersDataset);
    finally
      AResponseUser.Free;
    end;
  finally
    AUser.Free;
  end;

end;

procedure TUserManagerList.UserDataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField;
  AValue: string; AElement: TJSHTMLElementRecord);
var
  RecordId: string;
begin
  if ARow = 0 then
    Exit;

  if UserDataTable.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := UsersDataset.FieldByName('InternalId').AsString;
    AddRowActions(RecordId, AElement.Element);
  end;
end;

procedure TUserManagerList.UsersDatasetCanSubmitGetText(Sender: TField; var
    Text: string; DisplayText: Boolean);
begin
  if Sender.AsBoolean = true then
    Text := 'Yes'
  else
    Text := 'No';
end;

procedure TUserManagerList.UsersDatasetHMRCRegisteredGetText(Sender: TField;
    var Text: string; DisplayText: Boolean);
begin
  if Sender.AsBoolean = true then
    Text := 'Yes'
  else
    Text := 'No';
end;

procedure TUserManagerList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  UserDataTable := TDBTableControl.Create('UserDataTable');
  NewUserButton := TButton.Create('NewUserButton');
  UsersDataSource := TDataSource.Create(Self);
  UsersDataset := TClientDataSet.Create(Self);
  UsersDatasetId := TStringField.Create(Self);
  UsersDatasetUserName := TStringField.Create(Self);
  UsersDatasetEmail := TStringField.Create(Self);
  UsersDatasetEmailConfirmed := TBooleanField.Create(Self);
  UsersDatasetPhoneNumber := TStringField.Create(Self);
  UsersDatasetTenantId := TStringField.Create(Self);
  UsersDatasetInternalId := TIntegerField.Create(Self);
  UsersDatasetOrganisationId := TIntegerField.Create(Self);
  UsersDatasetJobTitle := TStringField.Create(Self);
  UsersDatasetTitle := TStringField.Create(Self);
  UsersDatasetGivenName := TStringField.Create(Self);
  UsersDatasetLastName := TStringField.Create(Self);
  UsersDatasetUserStatus := TStringField.Create(Self);
  UsersDatasetUserScope := TStringField.Create(Self);
  UsersDatasetJobPosition := TStringField.Create(Self);
  UsersDatasetBranchId := TFloatField.Create(Self);
  UsersDatasetBranchIdType := TStringField.Create(Self);
  UsersDatasetUserPassword := TStringField.Create(Self);

  UserDataTable.BeforeLoadDFMValues;
  NewUserButton.BeforeLoadDFMValues;
  UsersDataSource.BeforeLoadDFMValues;
  UsersDataset.BeforeLoadDFMValues;
  UsersDatasetId.BeforeLoadDFMValues;
  UsersDatasetUserName.BeforeLoadDFMValues;
  UsersDatasetEmail.BeforeLoadDFMValues;
  UsersDatasetEmailConfirmed.BeforeLoadDFMValues;
  UsersDatasetPhoneNumber.BeforeLoadDFMValues;
  UsersDatasetTenantId.BeforeLoadDFMValues;
  UsersDatasetInternalId.BeforeLoadDFMValues;
  UsersDatasetOrganisationId.BeforeLoadDFMValues;
  UsersDatasetJobTitle.BeforeLoadDFMValues;
  UsersDatasetTitle.BeforeLoadDFMValues;
  UsersDatasetGivenName.BeforeLoadDFMValues;
  UsersDatasetLastName.BeforeLoadDFMValues;
  UsersDatasetUserStatus.BeforeLoadDFMValues;
  UsersDatasetUserScope.BeforeLoadDFMValues;
  UsersDatasetJobPosition.BeforeLoadDFMValues;
  UsersDatasetBranchId.BeforeLoadDFMValues;
  UsersDatasetBranchIdType.BeforeLoadDFMValues;
  UsersDatasetUserPassword.BeforeLoadDFMValues;
  try
    Width := 841;
    Height := 594;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    UserDataTable.SetParentComponent(Self);
    UserDataTable.Name := 'UserDataTable';
    UserDataTable.Left := 32;
    UserDataTable.Top := 136;
    UserDataTable.Width := 513;
    UserDataTable.Height := 249;
    UserDataTable.HeightStyle := ssAuto;
    UserDataTable.WidthStyle := ssAuto;
    UserDataTable.BorderColor := clSilver;
    UserDataTable.ChildOrder := 4;
    UserDataTable.ElementFont := efCSS;
    UserDataTable.ElementHeaderClassName := 'thead-light';
    UserDataTable.ElementPosition := epIgnore;
    UserDataTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    SetEvent(UserDataTable, Self, 'OnGetCellChildren', 'UserDataTableGetCellChildren');
    UserDataTable.Columns.Clear;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'InternalId';
      Title := 'User Id';
    end;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'GivenName';
      Title := 'First Name';
    end;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'LastName';
      Title := 'Last Name';
    end;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'UserStatus';
      Title := 'Status';
    end;
    with UserDataTable.Columns.Add do
    begin
      DataField := 'UserScope';
      Title := 'Scope';
    end;
    with UserDataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    UserDataTable.DataSource := UsersDataSource;
    NewUserButton.SetParentComponent(Self);
    NewUserButton.Name := 'NewUserButton';
    NewUserButton.Left := 368;
    NewUserButton.Top := 41;
    NewUserButton.Width := 96;
    NewUserButton.Height := 25;
    NewUserButton.Caption := 'New User';
    NewUserButton.ChildOrder := 3;
    NewUserButton.ElementClassName := 'btn btn-primary';
    NewUserButton.ElementPosition := epIgnore;
    NewUserButton.HeightStyle := ssAuto;
    NewUserButton.HeightPercent := 100.000000000000000000;
    NewUserButton.WidthStyle := ssAuto;
    NewUserButton.WidthPercent := 100.000000000000000000;
    SetEvent(NewUserButton, Self, 'OnClick', 'NewUserButtonClick');
    UsersDataSource.SetParentComponent(Self);
    UsersDataSource.Name := 'UsersDataSource';
    UsersDataSource.DataSet := UsersDataset;
    UsersDataSource.Left := 280;
    UsersDataSource.Top := 24;
    UsersDataset.SetParentComponent(Self);
    UsersDataset.Name := 'UsersDataset';
    UsersDataset.Left := 176;
    UsersDataset.Top := 16;
    UsersDatasetId.SetParentComponent(UsersDataset);
    UsersDatasetId.Name := 'UsersDatasetId';
    UsersDatasetId.FieldName := 'Id';
    UsersDatasetId.Size := 36;
    UsersDatasetUserName.SetParentComponent(UsersDataset);
    UsersDatasetUserName.Name := 'UsersDatasetUserName';
    UsersDatasetUserName.FieldName := 'UserName';
    UsersDatasetUserName.Size := 255;
    UsersDatasetEmail.SetParentComponent(UsersDataset);
    UsersDatasetEmail.Name := 'UsersDatasetEmail';
    UsersDatasetEmail.FieldName := 'Email';
    UsersDatasetEmail.Size := 255;
    UsersDatasetEmailConfirmed.SetParentComponent(UsersDataset);
    UsersDatasetEmailConfirmed.Name := 'UsersDatasetEmailConfirmed';
    UsersDatasetEmailConfirmed.FieldName := 'EmailConfirmed';
    UsersDatasetPhoneNumber.SetParentComponent(UsersDataset);
    UsersDatasetPhoneNumber.Name := 'UsersDatasetPhoneNumber';
    UsersDatasetPhoneNumber.FieldName := 'PhoneNumber';
    UsersDatasetPhoneNumber.Size := 255;
    UsersDatasetTenantId.SetParentComponent(UsersDataset);
    UsersDatasetTenantId.Name := 'UsersDatasetTenantId';
    UsersDatasetTenantId.FieldName := 'TenantId';
    UsersDatasetTenantId.Size := 36;
    UsersDatasetInternalId.SetParentComponent(UsersDataset);
    UsersDatasetInternalId.Name := 'UsersDatasetInternalId';
    UsersDatasetInternalId.FieldName := 'InternalId';
    UsersDatasetOrganisationId.SetParentComponent(UsersDataset);
    UsersDatasetOrganisationId.Name := 'UsersDatasetOrganisationId';
    UsersDatasetOrganisationId.FieldName := 'OrganisationId';
    UsersDatasetJobTitle.SetParentComponent(UsersDataset);
    UsersDatasetJobTitle.Name := 'UsersDatasetJobTitle';
    UsersDatasetJobTitle.FieldName := 'JobTitle';
    UsersDatasetJobTitle.Size := 255;
    UsersDatasetTitle.SetParentComponent(UsersDataset);
    UsersDatasetTitle.Name := 'UsersDatasetTitle';
    UsersDatasetTitle.FieldName := 'Title';
    UsersDatasetTitle.Size := 25;
    UsersDatasetGivenName.SetParentComponent(UsersDataset);
    UsersDatasetGivenName.Name := 'UsersDatasetGivenName';
    UsersDatasetGivenName.FieldName := 'GivenName';
    UsersDatasetGivenName.Size := 50;
    UsersDatasetLastName.SetParentComponent(UsersDataset);
    UsersDatasetLastName.Name := 'UsersDatasetLastName';
    UsersDatasetLastName.FieldName := 'LastName';
    UsersDatasetLastName.Size := 50;
    UsersDatasetUserStatus.SetParentComponent(UsersDataset);
    UsersDatasetUserStatus.Name := 'UsersDatasetUserStatus';
    UsersDatasetUserStatus.DisplayWidth := 15;
    UsersDatasetUserStatus.FieldName := 'UserStatus';
    UsersDatasetUserStatus.Size := 15;
    UsersDatasetUserScope.SetParentComponent(UsersDataset);
    UsersDatasetUserScope.Name := 'UsersDatasetUserScope';
    UsersDatasetUserScope.FieldName := 'UserScope';
    UsersDatasetJobPosition.SetParentComponent(UsersDataset);
    UsersDatasetJobPosition.Name := 'UsersDatasetJobPosition';
    UsersDatasetJobPosition.FieldName := 'JobPosition';
    UsersDatasetJobPosition.Size := 75;
    UsersDatasetBranchId.SetParentComponent(UsersDataset);
    UsersDatasetBranchId.Name := 'UsersDatasetBranchId';
    UsersDatasetBranchId.FieldName := 'BranchId';
    UsersDatasetBranchIdType.SetParentComponent(UsersDataset);
    UsersDatasetBranchIdType.Name := 'UsersDatasetBranchIdType';
    UsersDatasetBranchIdType.FieldName := 'BranchIdType';
    UsersDatasetBranchIdType.Size := 25;
    UsersDatasetUserPassword.SetParentComponent(UsersDataset);
    UsersDatasetUserPassword.Name := 'UsersDatasetUserPassword';
    UsersDatasetUserPassword.FieldName := 'UserPassword';
    UsersDatasetUserPassword.Size := 50;
  finally
    UserDataTable.AfterLoadDFMValues;
    NewUserButton.AfterLoadDFMValues;
    UsersDataSource.AfterLoadDFMValues;
    UsersDataset.AfterLoadDFMValues;
    UsersDatasetId.AfterLoadDFMValues;
    UsersDatasetUserName.AfterLoadDFMValues;
    UsersDatasetEmail.AfterLoadDFMValues;
    UsersDatasetEmailConfirmed.AfterLoadDFMValues;
    UsersDatasetPhoneNumber.AfterLoadDFMValues;
    UsersDatasetTenantId.AfterLoadDFMValues;
    UsersDatasetInternalId.AfterLoadDFMValues;
    UsersDatasetOrganisationId.AfterLoadDFMValues;
    UsersDatasetJobTitle.AfterLoadDFMValues;
    UsersDatasetTitle.AfterLoadDFMValues;
    UsersDatasetGivenName.AfterLoadDFMValues;
    UsersDatasetLastName.AfterLoadDFMValues;
    UsersDatasetUserStatus.AfterLoadDFMValues;
    UsersDatasetUserScope.AfterLoadDFMValues;
    UsersDatasetJobPosition.AfterLoadDFMValues;
    UsersDatasetBranchId.AfterLoadDFMValues;
    UsersDatasetBranchIdType.AfterLoadDFMValues;
    UsersDatasetUserPassword.AfterLoadDFMValues;
  end;
end;

end.
