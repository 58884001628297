unit DashboardItemTableOnly;

interface


uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.ExtCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  SharedDataModule,
  Simple.Dashboard.Return.Types,
  BaseDashboardItem,
  Data.DB, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCChart, Vcl.Forms;


type
  TDashboardItemTableOnly = class(TBaseDashboardItem)
  private
  protected
    procedure CreateComponents; override;
    procedure DisplayData; override;
    procedure CreateSummaryTable(ARowCount: integer; AColumnCount: integer); override;//overload;
    function GetSummaryRowCount: integer; override;
    function GetSummaryColCount: integer; override;

  public
  end;


implementation

{ TDashboardItemDrillDown }

procedure TDashboardItemTableOnly.CreateComponents;
begin
  inherited;
end;


procedure TDashboardItemTableOnly.DisplayData;
begin
  CreateSummaryTable(GetSummaryRowCount, GetSummaryColCount);
  RunSummary(0);
end;

function TDashboardItemTableOnly.GetSummaryColCount: integer;
begin
  result := Length(Configuration.ColumnHeadings);
end;

function TDashboardItemTableOnly.GetSummaryRowCount: integer;
begin
  result := 10;
end;

procedure TDashboardItemTableOnly.CreateSummaryTable(ARowCount,
  AColumnCount: integer);
var
  I, J: integer;
  lColumnName: string;
  lValue: JS.TJSObject;
  lSummaryData: string;
  lSummaryArray: JS.TJSArray;
begin
  lSummaryData := SummaryData;
  lSummaryArray := JS.toArray(TJSJSON.parse(lSummaryData));

  try
    if not Assigned(SummaryGrid) then
    begin
      SummaryGrid := TTableControl.Create(Self);
      SummaryGrid.Parent := Self;
      SummaryGrid.ColCount := GetSummaryColCount;
      SummaryGrid.RowCount := GetSummaryRowCount+1;
      SummaryGrid.Height := (GetSummaryRowCount+1) * 28;
      SummaryGrid.Font.Height := 4;
      SummaryGrid.Align := alTop;
      SummaryGrid.AlignWithMargins := True;
      SummaryGrid.Options.ImageAlign := taCenter;
    end;

    for I := 0 to GetSummaryColCount-1 do
    begin
      lColumnName := Configuration.ColumnHeadings[I];
      SummaryGrid.Cells[I,0] := StringReplace(lColumnName, '_', ' ', [rfReplaceAll]);

      for J := 0 to GetSummaryRowCount-1 do
      begin
        lValue := JS.toObject(lSummaryArray[J]);
        SummaryGrid.Cells[I,J+1] := String(lValue[lColumnName]);
      end;
    end;
  finally
  end;
end;
(*
procedure TDashboardItem.CreateSummaryTable(ASummaryData: string; AFieldNames: TArray<String>);
var
  I, J: integer;
  lColumnName: string;
  lValue: JS.TJSObject;
  lSummaryData: string;
  lSummaryArray: JS.TJSArray;

  lCount: integer;
begin
  lSummaryData := ASummaryData;
  lSummaryArray := JS.toArray(TJSJSON.parse(lSummaryData));

  try
    if not Assigned(FSummaryGrid) then
    begin
      FSummaryGrid := TWebTableControl.Create(Self);
      FSummaryGrid.Parent := Self;
      FSummaryGrid.ColCount := Length(AFieldNames);
      FSummaryGrid.RowCount := lSummaryArray.Length + 1;
      FSummaryGrid.Height := (FSummaryGrid.RowCount+1) * 28;
      FSummaryGrid.Font.Height := 4;
      FSummaryGrid.Align := alTop;
      FSummaryGrid.AlignWithMargins := True;
      FSummaryGrid.Options.ImageAlign := taCenter;
    end;

    for I := 0 to Length(AFieldNames)-1 do
    begin
      lColumnName := AFieldNames[I];
      FSummaryGrid.Cells[I,0] := StringReplace(lColumnName, '_', ' ', [rfReplaceAll]);

      for J := 0 to lSummaryArray.Length-1 do
      begin
        lValue := JS.toObject(lSummaryArray[J]);
        FSummaryGrid.Cells[I,J+1] := String(lValue[lColumnName]);
      end;
    end;
  finally
  end;
end;
*)


end.

