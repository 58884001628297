Unit UserEdit;

Interface

Uses
  System.SysUtils,
  System.Classes,
  System.Generics.Collections,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls,
  CSY.Enumerations,
  Sys.Return.Types;

Type
  TUserEditForm = Class(TCoreWebForm)
    UserDataSource: TDataSource;
    InternalIdLabel: TDBLabel;
    FirstNameEdit: TDBEdit;
    LastNameEdit: TDBEdit;
    TitleEdit: TDBEdit;
    PhoneNumberEdit: TDBEdit;
    EmailEdit: TDBEdit;
    UserScopeEdit: TDBLookupComboBox;
    UserStatusEdit: TDBLookupComboBox;
    UserEditCancelButton: TButton;
    UserEditSaveButton: TButton;
    UserEditMessage: TLabel;
    UserChange: TMessageDlg;
    BranchIdTypeEdit: TDBComboBox;
    BranchIdEdit: TDBLookupComboBox;
    BranchIdLabel: TLabel;
    PasswordPanel: TPanel;
    UserPassword: TDBEdit;
    procedure BranchIdTypeEditChange(Sender: TObject);
    Procedure WebFormDestroy(Sender: TObject);
    Procedure UserEditCancelButtonClick(Sender: TObject);
    Procedure UserEditSaveButtonClick(Sender: TObject);
    Procedure WebFormCreate(Sender: TObject);
  Private
    FUser: TAppUser;
    FAvailableSeats: Integer;
    FBranches: TList<TBranch>;
    FBranchGroups: TList<TBranchGroup>;
    FLastIdsLoaded: TBranchIdType;
    Function GetDataset: TDataset;
    Procedure SetDataset(Const Value: TDataset);
    Procedure LoadScope;
    Procedure LoadStatus;
    Procedure LoadIdType;
    [async]
    Procedure LoadBranchIdLookup; async;
    [async]
    Procedure LoadBranchList; async;
    [async]
    Procedure LoadBranchGroupList; async;
    [async]
    Procedure Validate; async;
    Procedure DisplayUserMessage(Const Value: String);
    Function GetIsNewUser: Boolean;
    Function GetBranchIdType: TBranchIdType;
    { Private declarations }
    Property IsNewUser: Boolean Read GetIsNewUser;
    Property BranchIdType: TBranchIdType Read GetBranchIdType;
  Public
    { Public declarations }
    Property Dataset: TDataset Read GetDataset Write SetDataset;
  protected procedure LoadDFMValues; override; End;

Var
  UserEditForm: TUserEditForm;

Implementation

{$R *.dfm}

Uses
  UserManager,
  XData.Web.Client,
  SMX.Auth.Shared,
  UserManager.Utils,
  SMX.Web.Document.Utils,
  MainDataModule, SMX.XData.Utils;

procedure TUserEditForm.BranchIdTypeEditChange(Sender: TObject);
begin
 LoadBranchIdLookup;
end;

Procedure TUserEditForm.WebFormDestroy(Sender: TObject);
Begin
  If Assigned(FUser) Then
    FUser.Free;
  if Assigned(FBranchGroups) then
     FBranchGroups.Free;
  if Assigned(FBranches) then
     FBranches.Free;
  Inherited;
End;

Procedure TUserEditForm.WebFormCreate(Sender: TObject);
Begin
  Inherited;
  LoadScope;
  LoadStatus;
  LoadIdType;
End;

{ TUserEditForm }

Procedure TUserEditForm.DisplayUserMessage(Const Value: String);
Begin
  UserEditMessage.Caption := Value;
  UserEditMessage.Visible := (Value <> '');
End;

Function TUserEditForm.GetBranchIdType: TBranchIdType;
Begin
  Result := TBranchIdType(BranchIdTypeEdit.ItemIndex);
End;

Function TUserEditForm.GetDataset: TDataset;
Begin
  Result := UserDataSource.Dataset;
End;

Function TUserEditForm.GetIsNewUser: Boolean;
Begin
  Result := UserDataSource.State = TDataSetState.dsInsert;
End;

Procedure TUserEditForm.LoadBranchGroupList;
Const
  branchgroup_svc = 'IBranchService.GetSelectableBranchGroups';
Var
  lRetval: TXDataClientResponse;
  lList: TJSArray;
  I: Integer;
  lBranchGroup: TBranchGroup;
Begin
  if not Assigned(FBranchGroups) then
  begin
     FBranchGroups := TList<TBranchGroup>.Create;
     lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(branchgroup_svc, []));
     lList := TsmxXDataUtils.AsArray(lRetval);
     for I := 0 to lList.Length - 1  do
        FBranchGroups.Add(TBranchGroup.Create(JS.toObject(lList[I])));
  end;

  BranchIdEdit.LookupValues.Clear;

  for lBranchGroup in FBranchGroups do
    BranchIdEdit.LookupValues.AddPair(lBranchGroup.Id.ToString, lBranchGroup.Name);

  FLastIdsLoaded := TBranchIdType.BranchGroup;
  BranchIdLabel.Caption := 'Branch Group';

End;

Procedure TUserEditForm.LoadBranchIdLookup;
var lBranchIdType: TBranchIdType;
Begin

  lBranchIdType := BranchIdType;
  if lBranchIdType = FLastIdsLoaded then
     Exit;

  BranchIdEdit.LookupValues.BeginUpdate;

  Try

    Case lBranchIdType Of
      TBranchIdType.NotSpecified:
        Begin
          BranchIdEdit.LookupValues.Clear;
          FLastIdsLoaded := TBranchIdType.NotSpecified;
            BranchIdLabel.Caption := 'Branch Group/Branch';
        End;
      TBranchIdType.Branch:
        Await(LoadBranchList);
      TBranchIdType.BranchGroup:
        Await(LoadBranchGroupList);
    End;

  Finally
    BranchIdEdit.LookupValues.EndUpdate;
    BranchIdEdit.Enabled := BranchIdEdit.LookupValues.Count > 0;
  End;
End;

Procedure TUserEditForm.LoadBranchList;
Const
  branch_svc = 'IBranchService.GetAllBranches';
Var
  lRetval: TXDataClientResponse;
  lList: TJSArray;
  I: Integer;
  lBranch: TBranch;
Begin
  if not Assigned(FBranches) then
  begin
     FBranches := TList<TBranch>.Create;
     lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(branch_svc, []));
     lList := TsmxXDataUtils.AsArray(lRetval);
     for I := 0 to lList.Length - 1  do
        FBranches.Add(TBranch.Create(JS.toObject(lList[I])));
  end;

  BranchIdEdit.LookupValues.Clear;

  for lBranch in FBranches do
    BranchIdEdit.LookupValues.AddPair(lBranch.Id.ToString, lBranch.Name);

  FLastIdsLoaded := TBranchIdType.Branch;
  BranchIdLabel.Caption := 'Branch';
End;

Procedure TUserEditForm.LoadIdType;
Begin
  BranchIdTypeEdit.Items.Add('None');
  BranchIdTypeEdit.Items.Add('Branch');
  BranchIdTypeEdit.Items.Add('BranchGroup');
End;

Procedure TUserEditForm.LoadScope;
Begin
  UserScopeEdit.LookupValues.AddPair(SCOPE_USER, 'Standard User');
  UserScopeEdit.LookupValues.AddPair(SCOPE_ADMIN, 'Administrator');
End;

Procedure TUserEditForm.LoadStatus;
Begin
  UserStatusEdit.LookupValues.AddPair('Pending', 'Pending');
  UserStatusEdit.LookupValues.AddPair('Blocked', 'Blocked');
  UserStatusEdit.LookupValues.AddPair('Active', 'Active');
  UserStatusEdit.LookupValues.AddPair('System', 'System');
End;

Procedure TUserEditForm.Validate;
Var
  lValid, AllValid: Boolean;
  lMessage: String;

  mr: TModalResult;
Begin
  AllValid := True;

  lValid := (Dataset.FieldByName('GivenName').AsString.Trim <> '');
  AllValid := AllValid And lValid;
  TDocUtils.setControlValidity('FirstNameEdit', Valid_Check[lValid]);

  lValid := (Dataset.FieldByName('LastName').AsString.Trim <> '');
  AllValid := AllValid And lValid;
  TDocUtils.setControlValidity('LastNameEdit', Valid_Check[lValid]);

  lValid := (Dataset.FieldByName('Email').AsString.Trim <> '');
  AllValid := AllValid And lValid;
  TDocUtils.setControlValidity('EmailEdit', Valid_Check[lValid]);

  If AllValid Then
    ModalResult := mrOk;
End;

Procedure TUserEditForm.SetDataset(Const Value: TDataset);
Begin
  UserDataSource.Dataset := Value;
  EmailEdit.Enabled := (Not Dataset.FieldByName('EmailConfirmed').AsBoolean);
  FUser := TUserManagerUtils.DatasetToUser(Value);
  LoadBranchIdLookup;
  PasswordPanel.Visible := IsNewUser;
End;

Procedure TUserEditForm.UserEditCancelButtonClick(Sender: TObject);
Begin
  ModalResult := mrCancel;
End;

Procedure TUserEditForm.UserEditSaveButtonClick(Sender: TObject);
Begin
  Validate;
End;

procedure TUserEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  InternalIdLabel := TDBLabel.Create('InternalIdLabel');
  UserEditMessage := TLabel.Create('UserEditMessage');
  BranchIdLabel := TLabel.Create('BranchIdLabel');
  FirstNameEdit := TDBEdit.Create('FirstNameEdit');
  LastNameEdit := TDBEdit.Create('LastNameEdit');
  TitleEdit := TDBEdit.Create('TitleEdit');
  PhoneNumberEdit := TDBEdit.Create('PhoneNumberEdit');
  EmailEdit := TDBEdit.Create('EmailEdit');
  UserScopeEdit := TDBLookupComboBox.Create('UserScopeEdit');
  UserStatusEdit := TDBLookupComboBox.Create('UserStatusEdit');
  UserEditCancelButton := TButton.Create('UserEditCancelButton');
  UserEditSaveButton := TButton.Create('UserEditSaveButton');
  UserChange := TMessageDlg.Create(Self);
  BranchIdTypeEdit := TDBComboBox.Create('BranchIdType');
  BranchIdEdit := TDBLookupComboBox.Create('BranchIdEdit');
  PasswordPanel := TPanel.Create('PasswordPanel');
  UserPassword := TDBEdit.Create('UserPassword');
  UserDataSource := TDataSource.Create(Self);

  InternalIdLabel.BeforeLoadDFMValues;
  UserEditMessage.BeforeLoadDFMValues;
  BranchIdLabel.BeforeLoadDFMValues;
  FirstNameEdit.BeforeLoadDFMValues;
  LastNameEdit.BeforeLoadDFMValues;
  TitleEdit.BeforeLoadDFMValues;
  PhoneNumberEdit.BeforeLoadDFMValues;
  EmailEdit.BeforeLoadDFMValues;
  UserScopeEdit.BeforeLoadDFMValues;
  UserStatusEdit.BeforeLoadDFMValues;
  UserEditCancelButton.BeforeLoadDFMValues;
  UserEditSaveButton.BeforeLoadDFMValues;
  UserChange.BeforeLoadDFMValues;
  BranchIdTypeEdit.BeforeLoadDFMValues;
  BranchIdEdit.BeforeLoadDFMValues;
  PasswordPanel.BeforeLoadDFMValues;
  UserPassword.BeforeLoadDFMValues;
  UserDataSource.BeforeLoadDFMValues;
  try
    CSSLibrary := cssBootstrap;
    ElementClassName := 'PopUpForm';
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    InternalIdLabel.SetParentComponent(Self);
    InternalIdLabel.Name := 'InternalIdLabel';
    InternalIdLabel.Left := 48;
    InternalIdLabel.Top := 32;
    InternalIdLabel.Width := 50;
    InternalIdLabel.Height := 15;
    InternalIdLabel.Caption := 'InternalId';
    InternalIdLabel.ElementClassName := 'form-control-manual';
    InternalIdLabel.ElementPosition := epIgnore;
    InternalIdLabel.HeightStyle := ssAuto;
    InternalIdLabel.HeightPercent := 100.000000000000000000;
    InternalIdLabel.HTMLType := tSPAN;
    InternalIdLabel.WidthStyle := ssAuto;
    InternalIdLabel.WidthPercent := 100.000000000000000000;
    InternalIdLabel.DataField := 'InternalId';
    InternalIdLabel.DataSource := UserDataSource;
    UserEditMessage.SetParentComponent(Self);
    UserEditMessage.Name := 'UserEditMessage';
    UserEditMessage.Left := 288;
    UserEditMessage.Top := 232;
    UserEditMessage.Width := 89;
    UserEditMessage.Height := 15;
    UserEditMessage.Caption := 'UserEditMessage';
    UserEditMessage.ElementClassName := 'UserEditMessage';
    UserEditMessage.ElementFont := efCSS;
    UserEditMessage.ElementPosition := epIgnore;
    UserEditMessage.HeightStyle := ssAuto;
    UserEditMessage.HeightPercent := 100.000000000000000000;
    UserEditMessage.HTMLType := tSPAN;
    UserEditMessage.Visible := False;
    UserEditMessage.WidthStyle := ssAuto;
    UserEditMessage.WidthPercent := 100.000000000000000000;
    BranchIdLabel.SetParentComponent(Self);
    BranchIdLabel.Name := 'BranchIdLabel';
    BranchIdLabel.Left := 48;
    BranchIdLabel.Top := 328;
    BranchIdLabel.Width := 115;
    BranchIdLabel.Height := 15;
    BranchIdLabel.Caption := 'Branch Group/Branch';
    BranchIdLabel.ElementFont := efCSS;
    BranchIdLabel.HeightStyle := ssAuto;
    BranchIdLabel.HeightPercent := 100.000000000000000000;
    BranchIdLabel.WidthPercent := 100.000000000000000000;
    FirstNameEdit.SetParentComponent(Self);
    FirstNameEdit.Name := 'FirstNameEdit';
    FirstNameEdit.Left := 48;
    FirstNameEdit.Top := 80;
    FirstNameEdit.Width := 121;
    FirstNameEdit.Height := 22;
    FirstNameEdit.ChildOrder := 1;
    FirstNameEdit.ElementClassName := 'form-control';
    FirstNameEdit.ElementFont := efCSS;
    FirstNameEdit.ElementPosition := epIgnore;
    FirstNameEdit.HeightStyle := ssAuto;
    FirstNameEdit.HeightPercent := 100.000000000000000000;
    FirstNameEdit.Text := 'FirstNameEdit';
    FirstNameEdit.WidthStyle := ssAuto;
    FirstNameEdit.WidthPercent := 100.000000000000000000;
    FirstNameEdit.DataField := 'GivenName';
    FirstNameEdit.DataSource := UserDataSource;
    LastNameEdit.SetParentComponent(Self);
    LastNameEdit.Name := 'LastNameEdit';
    LastNameEdit.Left := 48;
    LastNameEdit.Top := 108;
    LastNameEdit.Width := 121;
    LastNameEdit.Height := 22;
    LastNameEdit.ChildOrder := 1;
    LastNameEdit.ElementClassName := 'form-control';
    LastNameEdit.ElementFont := efCSS;
    LastNameEdit.ElementPosition := epIgnore;
    LastNameEdit.HeightStyle := ssAuto;
    LastNameEdit.HeightPercent := 100.000000000000000000;
    LastNameEdit.Text := 'LastNameEdit';
    LastNameEdit.WidthStyle := ssAuto;
    LastNameEdit.WidthPercent := 100.000000000000000000;
    LastNameEdit.DataField := 'LastName';
    LastNameEdit.DataSource := UserDataSource;
    TitleEdit.SetParentComponent(Self);
    TitleEdit.Name := 'TitleEdit';
    TitleEdit.Left := 48;
    TitleEdit.Top := 53;
    TitleEdit.Width := 121;
    TitleEdit.Height := 22;
    TitleEdit.ChildOrder := 1;
    TitleEdit.ElementClassName := 'form-control';
    TitleEdit.ElementFont := efCSS;
    TitleEdit.ElementPosition := epIgnore;
    TitleEdit.HeightStyle := ssAuto;
    TitleEdit.HeightPercent := 100.000000000000000000;
    TitleEdit.Text := 'TitleEdit';
    TitleEdit.WidthStyle := ssAuto;
    TitleEdit.WidthPercent := 100.000000000000000000;
    TitleEdit.DataField := 'Title';
    TitleEdit.DataSource := UserDataSource;
    PhoneNumberEdit.SetParentComponent(Self);
    PhoneNumberEdit.Name := 'PhoneNumberEdit';
    PhoneNumberEdit.Left := 48;
    PhoneNumberEdit.Top := 164;
    PhoneNumberEdit.Width := 121;
    PhoneNumberEdit.Height := 22;
    PhoneNumberEdit.ChildOrder := 1;
    PhoneNumberEdit.ElementClassName := 'form-control';
    PhoneNumberEdit.ElementFont := efCSS;
    PhoneNumberEdit.ElementPosition := epIgnore;
    PhoneNumberEdit.HeightStyle := ssAuto;
    PhoneNumberEdit.HeightPercent := 100.000000000000000000;
    PhoneNumberEdit.Text := 'PhoneNumberEdit';
    PhoneNumberEdit.WidthStyle := ssAuto;
    PhoneNumberEdit.WidthPercent := 100.000000000000000000;
    PhoneNumberEdit.DataField := 'PhoneNumber';
    PhoneNumberEdit.DataSource := UserDataSource;
    EmailEdit.SetParentComponent(Self);
    EmailEdit.Name := 'EmailEdit';
    EmailEdit.Left := 48;
    EmailEdit.Top := 136;
    EmailEdit.Width := 121;
    EmailEdit.Height := 22;
    EmailEdit.ChildOrder := 1;
    EmailEdit.ElementClassName := 'form-control';
    EmailEdit.ElementFont := efCSS;
    EmailEdit.ElementPosition := epIgnore;
    EmailEdit.HeightStyle := ssAuto;
    EmailEdit.HeightPercent := 100.000000000000000000;
    EmailEdit.Text := 'EmailEdit';
    EmailEdit.WidthStyle := ssAuto;
    EmailEdit.WidthPercent := 100.000000000000000000;
    EmailEdit.DataField := 'Email';
    EmailEdit.DataSource := UserDataSource;
    UserScopeEdit.SetParentComponent(Self);
    UserScopeEdit.Name := 'UserScopeEdit';
    UserScopeEdit.Left := 48;
    UserScopeEdit.Top := 196;
    UserScopeEdit.Width := 145;
    UserScopeEdit.Height := 22;
    UserScopeEdit.ElementClassName := 'form-select form-control';
    UserScopeEdit.ElementFont := efCSS;
    UserScopeEdit.ElementPosition := epIgnore;
    UserScopeEdit.HeightStyle := ssAuto;
    UserScopeEdit.HeightPercent := 100.000000000000000000;
    UserScopeEdit.WidthStyle := ssAuto;
    UserScopeEdit.WidthPercent := 100.000000000000000000;
    UserScopeEdit.DataField := 'UserScope';
    UserScopeEdit.DataSource := UserDataSource;
    UserScopeEdit.ListSource := MainData.DummySource;
    UserStatusEdit.SetParentComponent(Self);
    UserStatusEdit.Name := 'UserStatusEdit';
    UserStatusEdit.Left := 48;
    UserStatusEdit.Top := 224;
    UserStatusEdit.Width := 145;
    UserStatusEdit.Height := 22;
    UserStatusEdit.ElementClassName := 'form-select form-control';
    UserStatusEdit.ElementFont := efCSS;
    UserStatusEdit.ElementPosition := epIgnore;
    UserStatusEdit.HeightStyle := ssAuto;
    UserStatusEdit.HeightPercent := 100.000000000000000000;
    UserStatusEdit.WidthStyle := ssAuto;
    UserStatusEdit.WidthPercent := 100.000000000000000000;
    UserStatusEdit.DataField := 'UserStatus';
    UserStatusEdit.DataSource := UserDataSource;
    UserStatusEdit.ListSource := MainData.DummySource;
    UserEditCancelButton.SetParentComponent(Self);
    UserEditCancelButton.Name := 'UserEditCancelButton';
    UserEditCancelButton.Left := 48;
    UserEditCancelButton.Top := 424;
    UserEditCancelButton.Width := 96;
    UserEditCancelButton.Height := 25;
    UserEditCancelButton.Caption := 'Cancel';
    UserEditCancelButton.ChildOrder := 12;
    UserEditCancelButton.ElementClassName := 'btn btn-primary';
    UserEditCancelButton.ElementFont := efCSS;
    UserEditCancelButton.ElementPosition := epIgnore;
    UserEditCancelButton.HeightStyle := ssAuto;
    UserEditCancelButton.HeightPercent := 100.000000000000000000;
    UserEditCancelButton.WidthStyle := ssAuto;
    UserEditCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(UserEditCancelButton, Self, 'OnClick', 'UserEditCancelButtonClick');
    UserEditSaveButton.SetParentComponent(Self);
    UserEditSaveButton.Name := 'UserEditSaveButton';
    UserEditSaveButton.Left := 150;
    UserEditSaveButton.Top := 424;
    UserEditSaveButton.Width := 96;
    UserEditSaveButton.Height := 25;
    UserEditSaveButton.Caption := 'Save';
    UserEditSaveButton.ChildOrder := 12;
    UserEditSaveButton.ElementClassName := 'btn btn-secondary';
    UserEditSaveButton.ElementFont := efCSS;
    UserEditSaveButton.ElementPosition := epIgnore;
    UserEditSaveButton.HeightStyle := ssAuto;
    UserEditSaveButton.HeightPercent := 100.000000000000000000;
    UserEditSaveButton.WidthStyle := ssAuto;
    UserEditSaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(UserEditSaveButton, Self, 'OnClick', 'UserEditSaveButtonClick');
    UserChange.SetParentComponent(Self);
    UserChange.Name := 'UserChange';
    UserChange.Left := 584;
    UserChange.Top := 136;
    UserChange.Width := 24;
    UserChange.Height := 24;
    UserChange.HeightStyle := ssAuto;
    UserChange.WidthStyle := ssAuto;
    UserChange.Buttons := [];
    UserChange.Opacity := 0.200000000000000000;
    UserChange.ElementButtonClassName := 'btn';
    UserChange.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    UserChange.ElementTitleClassName := 'text-body';
    UserChange.ElementContentClassName := 'text-body';
    BranchIdTypeEdit.SetParentComponent(Self);
    BranchIdTypeEdit.Name := 'BranchIdTypeEdit';
    BranchIdTypeEdit.Left := 48;
    BranchIdTypeEdit.Top := 256;
    BranchIdTypeEdit.Width := 145;
    BranchIdTypeEdit.Height := 23;
    BranchIdTypeEdit.ElementClassName := 'form-select';
    BranchIdTypeEdit.ElementFont := efCSS;
    BranchIdTypeEdit.HeightStyle := ssAuto;
    BranchIdTypeEdit.HeightPercent := 100.000000000000000000;
    BranchIdTypeEdit.Text := 'BranchIdTypeEdit';
    BranchIdTypeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(BranchIdTypeEdit, Self, 'OnChange', 'BranchIdTypeEditChange');
    BranchIdTypeEdit.ItemIndex := -1;
    BranchIdTypeEdit.DataField := 'BranchIdType';
    BranchIdTypeEdit.DataSource := UserDataSource;
    BranchIdEdit.SetParentComponent(Self);
    BranchIdEdit.Name := 'BranchIdEdit';
    BranchIdEdit.Left := 48;
    BranchIdEdit.Top := 349;
    BranchIdEdit.Width := 145;
    BranchIdEdit.Height := 22;
    BranchIdEdit.ElementClassName := 'form-select form-control';
    BranchIdEdit.ElementFont := efCSS;
    BranchIdEdit.ElementPosition := epIgnore;
    BranchIdEdit.Enabled := False;
    BranchIdEdit.HeightStyle := ssAuto;
    BranchIdEdit.HeightPercent := 100.000000000000000000;
    BranchIdEdit.WidthStyle := ssAuto;
    BranchIdEdit.WidthPercent := 100.000000000000000000;
    BranchIdEdit.DataField := 'BranchId';
    BranchIdEdit.DataSource := UserDataSource;
    BranchIdEdit.ListSource := MainData.DummySource;
    PasswordPanel.SetParentComponent(Self);
    PasswordPanel.Name := 'PasswordPanel';
    PasswordPanel.Left := 272;
    PasswordPanel.Top := 320;
    PasswordPanel.Width := 225;
    PasswordPanel.Height := 60;
    PasswordPanel.ElementClassName := 'card';
    PasswordPanel.HeightStyle := ssAuto;
    PasswordPanel.WidthStyle := ssAuto;
    PasswordPanel.BorderColor := clNone;
    PasswordPanel.BorderStyle := bsNone;
    PasswordPanel.ChildOrder := 15;
    PasswordPanel.Color := clWhite;
    PasswordPanel.ElementBodyClassName := 'card-body';
    PasswordPanel.ElementFont := efCSS;
    PasswordPanel.ElementPosition := epIgnore;
    PasswordPanel.TabOrder := 12;
    PasswordPanel.Visible := False;
    UserPassword.SetParentComponent(PasswordPanel);
    UserPassword.Name := 'UserPassword';
    UserPassword.Left := 16;
    UserPassword.Top := 16;
    UserPassword.Width := 121;
    UserPassword.Height := 22;
    UserPassword.ElementClassName := 'form-control';
    UserPassword.ElementFont := efCSS;
    UserPassword.ElementPosition := epIgnore;
    UserPassword.HeightStyle := ssAuto;
    UserPassword.HeightPercent := 100.000000000000000000;
    UserPassword.Text := 'UserPassword';
    UserPassword.WidthStyle := ssAuto;
    UserPassword.WidthPercent := 100.000000000000000000;
    UserPassword.DataField := 'UserPassword';
    UserPassword.DataSource := UserDataSource;
    UserDataSource.SetParentComponent(Self);
    UserDataSource.Name := 'UserDataSource';
    UserDataSource.Left := 440;
    UserDataSource.Top := 40;
  finally
    InternalIdLabel.AfterLoadDFMValues;
    UserEditMessage.AfterLoadDFMValues;
    BranchIdLabel.AfterLoadDFMValues;
    FirstNameEdit.AfterLoadDFMValues;
    LastNameEdit.AfterLoadDFMValues;
    TitleEdit.AfterLoadDFMValues;
    PhoneNumberEdit.AfterLoadDFMValues;
    EmailEdit.AfterLoadDFMValues;
    UserScopeEdit.AfterLoadDFMValues;
    UserStatusEdit.AfterLoadDFMValues;
    UserEditCancelButton.AfterLoadDFMValues;
    UserEditSaveButton.AfterLoadDFMValues;
    UserChange.AfterLoadDFMValues;
    BranchIdTypeEdit.AfterLoadDFMValues;
    BranchIdEdit.AfterLoadDFMValues;
    PasswordPanel.AfterLoadDFMValues;
    UserPassword.AfterLoadDFMValues;
    UserDataSource.AfterLoadDFMValues;
  end;
end;

End.
