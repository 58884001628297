{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2022 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.CheckLst;

interface

uses
  Classes, WEBLib.Controls, WEBLib.Menus, WEBLib.StdCtrls, Web, JS,
  WEBLib.Graphics, WEBLib.ExtCtrls;

type
  TCheckListBox = class(TCustomListBox)
  private
    FClickCheckPtr: pointer;
    FHeader: TList;
    FChecked: TList;
    FHeaderBackgroundColor: TColor;
    FHeaderColor: TColor;
    FOnChange: TNotifyEvent;
    FOnClickCheck: TNotifyEvent;
    FColumns: integer;
    FCheckBoxPadding: integer;
    FAllowGrayed: boolean;
    FElementCheckClassName: TELementClassName;
    function GetHeader(i: Integer): boolean;
    procedure SetHeader(i: Integer; const Value: boolean);
    procedure SetHeaderBackgroundColor(const Value: TColor);
    procedure SetHeaderColor(const Value: TColor);
    function GetAsString: string;
    procedure SetAsString(const Value: string);
    procedure IntChecked(AIndex: integer; AValue: boolean);
    procedure SetColums(const Value: integer);
    function GetCheckState(i: integer): TCheckBoxState;
    procedure SetCheckState(i: integer; const Value: TCheckBoxState);
    function GetSelected(i: Integer): boolean;
    procedure SetSelected(i: Integer; const Value: boolean);
    procedure SetElementCheckClassName(const Value: TELementClassName);
  protected
    function HandleDoClickCheck(Event: TJSMouseEvent): Boolean; virtual;
    function SaveState: string; override;
    procedure LoadState(AState: string); override;
    procedure DoUpdateList; override;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;

    function GetChecked(AIndex: integer): boolean;
    procedure SetChecked(AIndex: Integer; AValue: boolean);
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure CheckAll(AState: TCheckBoxState; AllowGrayed: Boolean; AllowDisabled: Boolean);
    property Checked[i: Integer]: boolean read GetChecked write SetChecked;
    property Selected[i: Integer]: boolean read GetSelected write SetSelected;
    property AllowGrayed: boolean read FAllowGrayed write FAllowGrayed;
    property State[i: integer]: TCheckBoxState read GetCheckState write SetCheckState;
    property CheckBoxPadding: integer read FCheckBoxPadding write FCheckBoxPadding;
    property Header[i: Integer]: boolean read GetHeader write SetHeader;
    property AsString: string read GetAsString write SetAsString;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BiDiMode;
    property BorderStyle;
    property ChildOrder;
    property Color;
    property Columns: integer read FColumns write SetColums default 0;
    property DragMode;
    property Font;
    property ElementClassName;
    property ElementItemClassName;
    property ElementCheckClassName: TELementClassName read FElementCheckClassName write SetElementCheckClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property HeaderColor: TColor read FHeaderColor write SetHeaderColor default clInfoText;
    property HeaderBackgroundColor: TColor read FHeaderBackgroundColor write SetHeaderBackgroundColor default clInfoBk;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property ItemHeight;
    property Items;
    property Left;
    property ParentColor;
    property ParentFont;
    property PopupMenu;
    property Role;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnClickCheck: TNotifyEvent read FOnClickCheck write FOnClickCheck;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnMouseWheel;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebCheckListBox = class(TCheckListBox);


implementation

uses
  SysUtils;

{ TCheckListBox }

procedure TCheckListBox.CheckAll(AState: TCheckBoxState; AllowGrayed,
  AllowDisabled: Boolean);
var
  i: integer;
begin
  for i := 0 to Items.Count - 1 do
  begin
    Checked[i] := AState = cbChecked;
  end;
end;

procedure TCheckListBox.ClearMethodPointers;
begin
  inherited;
  FClickCheckPtr := nil;
end;

procedure TCheckListBox.CreateInitialize;
begin
  inherited;
  FHeader := TList.Create;
  FHeaderColor := clInfoText;
  FHeaderBackgroundColor := clInfoBk;
  FChecked := TList.Create;
end;

destructor TCheckListBox.Destroy;
begin
  FHeader.Free;
  FChecked.Free;
  inherited;
end;

function TCheckListBox.SaveState: string;
var
  i: integer;
  s: string;
begin
  for i := 0 to Items.Count - 1 do
  begin
    if Checked[i] then
      s := s + ',1'
    else
      s := s + ',0';
  end;
  Delete(s,1,1);
  Result := s;
end;

function TCheckListBox.GetHeader(i: Integer): boolean;
begin
  if (i < FHeader.Count) then
    Result := boolean(FHeader.Items[i])
  else
    Result := False;
end;

procedure TCheckListBox.GetMethodPointers;
begin
  inherited;
  FClickCheckPtr := @HandleDoClickCheck;
end;

function TCheckListBox.GetSelected(i: Integer): boolean;
begin
  Result := Checked[i];
end;

{$HINTS OFF}
function TCheckListBox.HandleDoClickCheck(Event: TJSMouseEvent): Boolean;
var
  idx: integer;
  el: TJSElement;
begin
  el := ElementHandle;
  asm
   idx = Array.from(el.firstChild.firstChild.children).indexOf(Event.target.parentElement);
  end;
  if (idx >= 0) then
  begin
    IntChecked(idx, Checked[idx]);
  end;

  if Assigned(OnClickCheck) then
    OnClickCheck(Self);
  Result := true;
end;
{$HINTS ON}

procedure TCheckListBox.IntChecked(AIndex: integer; AValue: boolean);
begin
  while FChecked.Count <= AIndex do
    FChecked.Add(nil);

  if AValue then
    FChecked[AIndex] := 1
  else
    FChecked[AIndex] := nil;
end;

function TCheckListBox.GetAsString: string;
var
  res: string;
  i: integer;
begin
  res := '';
  for i := 0 to Items.Count - 1 do
  begin
    if Checked[i] then
    begin
      if res <> '' then
        res := res + ',';
      res := res + Items[i];
    end;
  end;
  Result := res;
end;

function TCheckListBox.GetChecked(AIndex: integer): boolean;
var
  el: TJSHTMLElement;
  chkid: string;
begin
  Result := false;

  chkid := GetID + '_' + AIndex.ToString;

  el := TJSHTMLElement(document.getElementById(chkid));

  if Assigned(el) then
  begin
    Result := TJSHTMLInputElement(el).checked;
  end
  else
    if FChecked.Count > AIndex then
      Result := (FChecked[AIndex] = 1);
end;

function TCheckListBox.GetCheckState(i: integer): TCheckBoxState;
begin
  if Checked[i] then
    Result := cbChecked
  else
    Result := cbUnChecked;
end;

procedure TCheckListBox.LoadState(AState: string);
var
  sl: TStringList;
  i: integer;
begin
  sl := TStringList.Create;
  sl.Delimiter := ',';
  sl.CommaText := AState;

  for i := 0 to sl.Count - 1 do
  begin
    Checked[i] := sl.Strings[i] = '1';
  end;

  sl.Free;
end;

procedure TCheckListBox.SetAsString(const Value: string);
var
  sl: TStringList;
  i: integer;
begin
  sl := TStringList.Create;
  sl.Delimiter := ',';
  sl.CommaText := Value;

  for i := 0 to Items.Count - 1 do
  begin
    Checked[i] := sl.IndexOf(Items[i]) <> -1;
  end;

  sl.Free;
end;

procedure TCheckListBox.SetChecked(AIndex: integer; AValue: boolean);
var
  el: TJSHTMLElement;
  chkid: string;
begin
  IntChecked(AIndex, AValue);

  chkid := GetID + '_' + AIndex.ToString;

  el := TJSHTMLElement(document.getElementById(chkid));

  if Assigned(el) then
  begin
    TJSHTMLInputElement(el).checked := AValue;
  end;
end;

procedure TCheckListBox.SetCheckState(i: integer; const Value: TCheckBoxState);
begin
  Checked[i] := Value = cbChecked;
end;

procedure TCheckListBox.SetColums(const Value: integer);
begin
  if (FColumns <> Value) then
  begin
    FColumns := Value;
    DoUpdateList;
  end;
end;

procedure TCheckListBox.SetElementCheckClassName(
  const Value: TELementClassName);
begin
  if (FElementCheckClassName <> Value) then
  begin
    FElementCheckClassName := Value;
    DoUpdateList;
  end;
end;

procedure TCheckListBox.SetHeader(i: Integer; const Value: boolean);
var
  el: TJSHTMLElement;
begin
  while (i >= FHeader.Count) do
    FHeader.Add(false);

  FHeader.Items[i] := Value;

  el := ItemElement[i];

  if Assigned(el) then
  begin
    el := TJSHTMLElement(el.firstChild); // get the checkbox
    if Assigned(el) then
    begin
      if Value then
      begin
        el.style.setProperty('display','none');
        TJSHTMLElement(el.parentElement).style.setProperty('background-color',ColorToHTML(HeaderBackgroundColor));
        TJSHTMLElement(el.parentElement).style.setProperty('color',ColorToHTML(HeaderColor));
      end
      else
        el.style.removeProperty('display');
    end;
  end;
end;

procedure TCheckListBox.SetHeaderBackgroundColor(const Value: TColor);
begin
  FHeaderBackgroundColor := Value;
end;

procedure TCheckListBox.SetHeaderColor(const Value: TColor);
begin
  FHeaderColor := Value;
end;

procedure TCheckListBox.SetSelected(i: Integer; const Value: boolean);
begin
  Checked[i] := Value;
end;

procedure TCheckListBox.DoUpdateList;
var
  i, j, k, ColCount, RowCount: integer;
  opt, dv, maindiv: TJSHTMLElement;
  chk: TJSHTMLInputElement;
  txt: TJSNode;
  s: string;
begin
  if not Assigned(Container) then
    Exit;

  if IsUpdating then
    Exit;

  if ElementHandle.tagName <> 'DIV' then
    Exit;

  // remove previous content
  while Assigned(Container.firstChild) do
    Container.removeChild(Container.lastChild);

  ColCount := Columns;
  if ColCount <= 1 then
    ColCount := 1;

  RowCount := Items.Count div ColCount;

  k := 0;

  maindiv := TJSHTMLElement(document.createElement('DIV'));
  maindiv.style.setProperty('display','grid');

  s := '1fr';
  for j := 1 to ColCount - 1 do
    s := s + ' 1fr';

  maindiv.style.setProperty('grid-template-columns',s);

  Container.appendChild(maindiv);

  for j := 0 to ColCount - 1 do
  begin
    dv := TJSHTMLElement(document.createElement('DIV'));

    if ElementItemClassName <> '' then
      dv['class'] := ElementItemClassName;

    maindiv.appendChild(dv);

    for i := 0 to RowCount - 1 do
    begin
      if k < Items.Count then
      begin
        opt := TJSHTMLElement(document.createElement('LABEL'));
        chk := TJSHTMLInputElement(document.createElement('INPUT'));
        chk['type'] := 'checkbox';
        chk['id'] := GetID +'_' + k.toString;

        if ELementCheckClassName <> '' then
          chk['class'] := ELementCheckClassName;

        if FChecked.Count > i then
         chk.checked := FChecked[k] <> nil;

        chk.addEventListener('click', FClickCheckPtr);
        chk.style.setProperty('vertical-align','middle');
        opt.style.setProperty('vertical-align','middle');

        opt.appendChild(chk);
        opt.style.setProperty('display','block');
        txt := document.createTextNode(Items[k]);

        opt.appendChild(txt);


        if DragMode = dmAutomatic then
          opt['draggable'] := 'true';

        dv.appendChild(opt);
      end;

      inc(k);
    end;
  end;

  UpdateElementData;
end;


end.
