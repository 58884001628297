unit DashboardItem;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.ExtCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  SharedDataModule,
  Simple.Dashboard.Return.Types,
  Data.DB, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCChart, Vcl.Forms;


type
  TDashboardItem = class(TPanel)
  private
    { Private declarations }
    FDashboardId: integer;
    FTitleLabel: TLabel;
    FDashboardParameters: string;
    FResponse: TDashboardResponse;
    //FNCBarChart: TTMSFNCBarChart;
    //FNCPieChart: TTMSFNCPieChart;
    FChartType: string;
    FChartTypeFixed: boolean;
    FSummaryIndex: integer;

    FNCChart: TTMSFNCChart;

    FChart: TTMSFNCChart;
    FChartData: string;
    FSummaryGrid: TTableControl;
    FConfiguration: TDashboardItemConfig;
    FSummaryPresent: boolean;
    FSummaryGridPresent: boolean;
    FSummaryType: string;
    FFullSummary: boolean;

    FChartTitle: TTMSFNCChartTitle;

    FOwner: TComponent;
    FTitle: string;
    FYValueNames: string;
    FXLabelNames: string;
    FLegendText: string;
    FDashboardIndex: integer;
    FDrillDownPresent: boolean;
    FDrillDownSQL: string;
    FComboBox: TComboBox;
    ChartTimer: TTimer;

    function JSONToNamesArray(AJSON: string):TTMSFNCChartJSONNamesArray;

    procedure TMSFNCBarChart1SerieBarClick(Sender: TObject; APoint: TTMSFNCChartPoint);
    procedure ComboBoxChange(Sender: TObject);
    procedure TMSFNCPieChart1GetSerieLegendText(Sender: TObject;
                ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint;
                var ALegendText: string);
    procedure ChartTimerTimer(Sender: TObject);
    procedure TestClick(Sender: TObject);
  protected
    procedure SetTitle(ATitle: string);

    procedure AddChartAndData(ADataJSON: string);
    procedure CreateChart;
    procedure CreateTitleLabel;
    procedure CreateSummaryTable(ASummaryData: string; AFieldNames: TArray<String>);overload;
    procedure CreateSummaryTable(ARowCount: integer; AColumnCount: integer);overload;
    procedure PopulateSummaryTableData(ASummaryData: string; AColumnCount: integer; ARowCount: integer);
    procedure PopulateSummaryTableRowData(ARowIndex: integer; ARowData: JS.TJSObject);

    procedure CreateSummaryFieldsTableRowIndex(ASummaryData: string; ARowIndex: integer);
    procedure AddLegend(AChart: TTMSFNCChart);
    procedure SetDashboardId(ADashboardItemId: integer);
    procedure RunSummary(AIndex: integer);
    procedure NewSummarySelected(ASummaryParam: string);
    procedure NewSummaryIndividualData(ASummaryParam: string);

    [async]
    procedure RunSummarySQL(ASQL: string); async;

    procedure CreateComboBox;
    procedure PopulateComboBox;

  public
    { Public declarations }
    constructor Create(AOwner: TComponent);override;
    [async]
    function Run: boolean; async;
    procedure Refresh;
    procedure AddData(AText: string; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames: TTMSFNCChartJSONNamesArray);//, AYSecValueNames, AYVarValueNames: TTMSFNCChartJSONNamesArray); //ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
    property Title: string read FTitle write SetTitle;
    property SummaryGridPresent: boolean read FSummaryGridPresent write FSummaryGridPresent;
//    property DashboardIndex: integer read FDashboardIndex write SetDashboardIndex;
    property DashboardId: integer read FDashboardId write SetDashboardId;
    property DashboardParameters: string read FDashboardParameters write FDashboardParameters;
  end;


implementation

{ TMappingsForm }

uses
  System.UITypes;


constructor TDashboardItem.Create(AOwner: TComponent);
begin
  inherited;

  FOwner := AOwner;
  Color := clWhite;
  BorderStyle := bsNone;
  Height := 600;
  Width := 700;
end;

procedure TDashboardItem.CreateChart;
begin
  if FChartType = 'Bar' then
  begin
    FNCChart := TTMSFNCBarChart.Create(FOwner);
  end
  else if FChartType = 'Pie' then
  begin
    FNCChart := TTMSFNCPieChart.Create(FOwner);
    FNCChart.OnGetSerieLegendText := TMSFNCPieChart1GetSerieLegendText;
  end
  else if FChartType = 'Line' then
  begin
    FNCChart := TTMSFNCLineChart.Create(FOwner);
  end;

  FNCChart.Top := 0;
  FNCChart.Left := 0;
  FNCChart.Parent := Self;
  FNCChart.Align := alTop;
  FNCChart.Height := 300;
//  FNCChart.Height := 500;
  FNCChart.Legend.Left := 300;

  FNCChart.Stroke.Kind := gskNone;
  FNCchart.Title.Line := False;
  FNCChart.XAxis.Line := False;
  FNCChart.YAxis.Line := False;

//  FNCChart.Visible := false;
end;

procedure TDashboardItem.CreateTitleLabel;
begin
  if not assigned(FTitleLabel) then
  begin
    FTitleLabel := TLabel.Create(FOwner);
    FTitleLabel.Parent := Self;
    FTitleLabel.Align := alTop;
    FTitleLabel.Height := 30;
    FTitleLabel.Font.Size := 14;
    FTitleLabel.Font.Style := [fsBold];
    FTitleLabel.OnClick := TestClick;
  end;
end;

procedure TDashboardItem.CreateComboBox;
begin
  if not Assigned(FComboBox) then
  begin
    FComboBox := TComboBox.Create(FOwner);
    FComboBox.Parent := Self;
    FComboBox.Align := alTop;
    FComboBox.ElementClassName := 'form-select';
    FComboBox.OnChange := ComboBoxChange;
  end;
end;

procedure TDashboardItem.PopulateComboBox;
var
  lData: string;
  lMainDataArray: JS.TJSArray;
  lColumnData: JS.TJSObject;
  lChartData: string;
  lIdx: integer;
begin
  FComboBox.Clear;
  lChartData := FChartData;
  lMainDataArray := JS.toArray(TJSJSON.parse(lChartData));

  for lIdx := 0 to lMainDataArray.Length-1 do
  begin
    lColumnData := JS.toObject(lMainDataArray.Elements[lIdx]);
    lData := String(lColumnData[FConfiguration.DrillDownFieldName]);
    FComboBox.Items.Add(lData);
  end;

  FComboBox.ItemIndex := 0;
end;

procedure TDashboardItem.AddLegend(AChart: TTMSFNCChart);
var
  lIdx: integer;
begin
  try
    for lIdx := 0 to Length(FConfiguration.LegendData)-1  do
    begin
      AChart.series.Items[lIdx].LegendText := FConfiguration.LegendData[lIdx];
    end;
  except

  end;
end;

function TDashboardItem.Run: boolean;
var
//  lResponse: TDashboardResponse;
  lConfigObj: JS.TJSObject;
  lConfigJSON: string;
begin
  try
    FResponse := Await(SharedData.Dashboard.GetDashboardItem(FDashboardID.ToString, FDashboardParameters));

    //FResponse := lResponse;
    lConfigJSON := FResponse.Configuration;
    lConfigObj := JS.toObject(TJSJSON.parse(lConfigJSON));
    FConfiguration := TDashboardItemConfig(lConfigObj);

    FChartData := FResponse.Value;
    FDrillDownPresent := FResponse.DrillDownPresent;
    FSummaryPresent := FResponse.SummaryPresent;
    FChartType := FResponse.ChartType;
    FChartTypeFixed := FResponse.ChartTypeFixed;
    FSummaryType := FResponse.SummaryType;
    FFullSummary := FResponse.FullSummaryAvailable;

    if FDrillDownPresent then
      FDrillDownSQL := FResponse.DrillDownSQL;

    CreateTitleLabel;
    Title := FResponse.Title;

    if FResponse.ItemType <> 'SQLTable' then
    begin
      AddChartAndData(FChartData);
    end
    else
    begin
      CreateSummaryTable(FResponse.SummaryData, FConfiguration.ColumnHeadings);
    end;

    result := true;
  except
    result := false;
  end;
end;

procedure TDashboardItem.Refresh;
begin
  Title := FTitle + ' . . . (Refreshing)';
  Run;

(*
  FResponse := Await(SharedData.Dashboard.GetDashboardItem(FDashboardID.ToString, FDashboardParameters));

  if FResponse.ItemType <> 'SQLTable' then
  begin
    AddChartAndData(FChartData);
  end
  else
  begin
    CreateSummaryTable(FResponse.SummaryData, FConfiguration.ColumnHeadings);
  end;*)
end;



procedure TDashboardItem.AddChartAndData(ADataJSON: string);
var
  s, si: TTMSFNCChartSerie;
begin
  if not assigned(FNCChart) then
  begin
    CreateChart;
  end;

  AddData(ADataJSON, '', '', JSONToNamesArray(FConfiguration.YValueName),
        nil, JSONToNamesArray(FConfiguration.XValueLabels));

  FNCChart.Series[0].XValues.AutoUnits := False;
  AddLegend(FNCChart);

  FNCChart.Series[0].Stroke.Kind := gskSolid;

  FNCChart.Series[0].XGrid.Visible := true;
  FNCChart.Series[0].YGrid.Visible := true;

  if FChartType = 'Pie' then
  begin
    FNCChart.Series[0].Legend.Font.Size := 12;
    FNCChart.Series[0].Legend.Visible := True;
    FNCChart.Series[0].Legend.Position := lpTopLeft;
  end
  else
  begin
    FNCChart.Series[0].Legend.Visible := False;
  end;

  if FDrillDownPresent then
  begin
    CreateComboBox;
    PopulateComboBox;
    CreateSummaryTable(Length(FConfiguration.ColumnHeadings), 5);   // KSS This
    //CreateSummaryTable(5, Length(FConfiguration.ColumnHeadings));   // KSS This
    RunSummary(0);
  end
  else if FSummaryPresent then
    CreateSummaryTable(FResponse.SummaryData, FConfiguration.ColumnHeadings)
  else if FFullSummary  then
  begin
    CreateComboBox;
    PopulateComboBox;
    CreateSummaryTable(Length(FConfiguration.FieldsForDisplay), 5);
    PopulateSummaryTableData(FResponse.SummaryData, Length(FConfiguration.FieldsForDisplay), 5);
  end;
end;

function TDashboardItem.JSONToNamesArray(AJSON: string):TTMSFNCChartJSONNamesArray;
var
  lValues: JS.TJSArray;
begin
  lValues := JS.toArray(AJSON);
  result := TTMSFNCChartJSONNamesArray(lValues);
end;

procedure TDashboardItem.AddData(AText: string; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames: TTMSFNCChartJSONNamesArray);
begin
  FNCChart.LoadFromJSONTextDataEx(AText, ASeriesName, APointsName, AYValueNames,
  AXValueNames, AXLabelNames);
end;

procedure TDashboardItem.SetDashboardId(ADashboardItemId: integer);
begin
  // Select the DashboardItem from the table
  FDashboardId := ADashboardItemId;
end;

procedure TDashboardItem.SetTitle(ATitle: string);
begin
  FTitle := ATitle;
  FTitleLabel.Caption := FTitle;
end;

procedure TDashboardItem.TMSFNCBarChart1SerieBarClick(Sender: TObject;
  APoint: TTMSFNCChartPoint);
begin
  RunSummary(APoint.ID);
end;

procedure TDashboardItem.ComboBoxChange(Sender: TObject);
var
  lNewSummaryParam: string;
begin
  lNewSummaryParam := FComboBox.Items[FComboBox.ItemIndex];
  NewSummarySelected(lNewSummaryParam);
  CreateSummaryFieldsTableRowIndex(FChartData, FComboBox.ItemIndex);
end;

procedure TDashboardItem.NewSummarySelected(ASummaryParam: string);
var
  lDrillDownSQL: string;
  lDrillDownFieldName: string;

begin
  if (FSummaryType = 'SummaryIncludedIndividual') or (FSummaryType = 'SummaryIncludedList') then
  begin
    NewSummaryIndividualData(ASummaryParam);
  end
  else
  begin
    lDrillDownFieldName := '@' + FConfiguration.DrillDownFieldName;
    lDrillDownSQL := StringReplace(FDrillDownSQL, lDrillDownFieldName, ASummaryParam, [rfReplaceAll, rfIgnoreCase]);

    RunSummarySQL(lDrillDownSQL);
  end;
end;

procedure TDashboardItem.NewSummaryIndividualData(ASummaryParam: string);
var
  lData: string;
  lMainDataArray: JS.TJSArray;
  lColumnData: JS.TJSObject;
  lChartData: string;
  lDrillDownField: string;
  lDrillDownSQL: string;
  lDrillDownName: string;
begin
  Sleep(70);
//  lChartData := FChartData;
//  lMainDataArray := JS.toArray(TJSJSON.parse(lChartData));
//  lColumnData := JS.toObject(lMainDataArray.Elements[AIndex]);

end;

procedure TDashboardItem.RunSummary(AIndex: integer);
var
  lData: string;
  lMainDataArray: JS.TJSArray;
  lColumnData: JS.TJSObject;
  lChartData: string;
  lDrillDownField: string;
  lDrillDownSQL: string;
  lDrillDownName: string;
begin
  lChartData := FChartData;
  lMainDataArray := JS.toArray(TJSJSON.parse(lChartData));
  lColumnData := JS.toObject(lMainDataArray.Elements[AIndex]);

  lDrillDownField := FConfiguration.DrillDownFieldName;
  lDrillDownSQL := FDrillDownSQL;
  lDrillDownName :=  String(lColumnData[lDrillDownField]);
  lDrillDownField := '@' + FConfiguration.DrillDownFieldName;
  lDrillDownSQL := StringReplace(FDrillDownSQL, lDrillDownField, lDrillDownName, [rfReplaceAll, rfIgnoreCase]);

  RunSummarySQL(lDrillDownSQL);
end;

procedure TDashboardItem.RunSummarySQL(ASQL: string);
var
  lSummaryData: string;
begin
  lSummaryData := Await(SharedData.Dashboard.GetDashboardSummaryData(ASQL, FConfiguration.SummaryFields));
  PopulateSummaryTableData(lSummaryData, Length(FConfiguration.FieldsForDisplay), 1);      // KSS This
  //PopulateSummaryTableData(lSummaryData, Length(FConfiguration.FieldsForDisplay), 5);
end;


procedure TDashboardItem.CreateSummaryTable(ASummaryData: string; AFieldNames: TArray<String>);
var
  I, J: integer;
  lColumnName: string;
  lValue: JS.TJSObject;
  lSummaryData: string;
  lSummaryArray: JS.TJSArray;

  lCount: integer;
begin
  lSummaryData := ASummaryData;
  lSummaryArray := JS.toArray(TJSJSON.parse(lSummaryData));

  try
    if not Assigned(FSummaryGrid) then
    begin
      FSummaryGrid := TTableControl.Create(Self);
      FSummaryGrid.Parent := Self;
      FSummaryGrid.ColCount := Length(AFieldNames);
      FSummaryGrid.RowCount := lSummaryArray.Length + 1;
      FSummaryGrid.Height := (FSummaryGrid.RowCount+1) * 28;
      FSummaryGrid.Font.Height := 4;
      FSummaryGrid.Align := alTop;
      FSummaryGrid.AlignWithMargins := True;
      FSummaryGrid.Options.ImageAlign := taCenter;
    end;

    for I := 0 to Length(AFieldNames)-1 do
    begin
      lColumnName := AFieldNames[I];
      FSummaryGrid.Cells[I,0] := StringReplace(lColumnName, '_', ' ', [rfReplaceAll]);

      for J := 0 to lSummaryArray.Length-1 do
      begin
        lValue := JS.toObject(lSummaryArray[J]);
        FSummaryGrid.Cells[I,J+1] := String(lValue[lColumnName]);
      end;
    end;
  finally
  end;
end;

procedure TDashboardItem.CreateSummaryFieldsTableRowIndex(ASummaryData: string; ARowIndex: integer);
var
  I, J: integer;
  lColumnName: string;
  lValue: JS.TJSObject;
  lSummaryData: string;
  lSummaryArray: JS.TJSArray;

  lCount: integer;
  lMA: TSummaryArray;
begin
  lSummaryData := ASummaryData;
  lSummaryArray := JS.toArray(TJSJSON.parse(lSummaryData));

  try
    if not Assigned(FSummaryGrid) then
    begin
      FSummaryGrid := TTableControl.Create(Self);
      FSummaryGrid.Parent := Self;
      FSummaryGrid.ColCount := Length(FConfiguration.SummaryFields);
      FSummaryGrid.RowCount := lSummaryArray.Length + 1;
      FSummaryGrid.Height := (FSummaryGrid.RowCount+1) * 28;
      FSummaryGrid.Font.Height := 4;
      FSummaryGrid.Align := alTop;
      FSummaryGrid.AlignWithMargins := True;
      FSummaryGrid.Options.ImageAlign := taCenter;
    end;

    for I := 0 to Length(FConfiguration.SummaryFields)-1 do
    begin
      lColumnName := FConfiguration.SummaryFields[I].ToField;
      FSummaryGrid.Cells[I,0] := FConfiguration.SummaryFields[I].DisplayName;
      lValue := JS.toObject(lSummaryArray[ARowIndex]);
      FSummaryGrid.Cells[I,1] := String(lValue[lColumnName]);
    end;
  finally
  end;
end;

procedure TDashboardItem.CreateSummaryTable(ARowCount: integer; AColumnCount: integer);
var
  I,J: integer;
  lColumnName: string;
begin
  ARowCount := 1;
  try
    if not Assigned(FSummaryGrid) then
    begin
      FSummaryGrid := TTableControl.Create(Self);
      FSummaryGrid.Parent := Self;
      FSummaryGrid.ColCount := AColumnCount;
      FSummaryGrid.RowCount := ARowCount + 1;
      FSummaryGrid.Height := (ARowCount+1) * 28;
      FSummaryGrid.Font.Height := 4;
      FSummaryGrid.Align := alTop;
      FSummaryGrid.AlignWithMargins := True;
      FSummaryGrid.Options.ImageAlign := taCenter;
    end;

    for I := 0 to AColumnCount-1 do
    begin
      lColumnName := FConfiguration.SummaryFields[I].ToField;
      FSummaryGrid.Cells[I,0] := FConfiguration.SummaryFields[I].DisplayName;
    end;
  finally
  end;
end;

procedure TDashboardItem.PopulateSummaryTableData(ASummaryData: string; AColumnCount: integer; ARowCount: integer);
var
  lRowIndex: integer;
  lValue: JS.TJSObject;
  lSummaryData: string;
  lSummaryArray: JS.TJSArray;
begin
  lSummaryData := ASummaryData;
  lSummaryArray := JS.toArray(TJSJSON.parse(lSummaryData));

  try
    for lRowIndex := 0 to ARowCount-1 do
    begin
      lValue := JS.toObject(lSummaryArray[lRowIndex]);
      PopulateSummaryTableRowData(lRowIndex, lValue);
    end;
  finally
  end;
end;

procedure TDashboardItem.PopulateSummaryTableRowData(ARowIndex: integer; ARowData: JS.TJSObject);
var
  lColumnIndex: integer;
  lColumnName: string;
begin
  for lColumnIndex := 0 to Length(FConfiguration.SummaryFields)-1 do
  begin
    lColumnName := FConfiguration.SummaryFields[lColumnIndex].ToField;
    FSummaryGrid.Cells[lColumnIndex, ARowIndex+1] := String(ARowData[lColumnName]);
  end;
end;

procedure TDashboardItem.TMSFNCPieChart1GetSerieLegendText(Sender: TObject;
  ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; var ALegendText: string);
begin
//
end;

procedure TDashboardItem.ChartTimerTimer(Sender: TObject);
begin
//  ChartTimer.Enabled := False;
//  Visible := true;

//  if assigned(FNCChart) then
//    FNCChart.Visible := True;
end;

procedure TDashboardItem.TestClick(Sender: TObject);
begin
  Refresh;
end;

end.


