{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCChart;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  {$IFDEF MSWINDOWS}
  Windows,
  {$ENDIF}
  Classes, SysUtils, TypInfo, Types
  , DateUtils, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCPersistence, WEBLib.TMSFNCCloudBase, WEBLib.TMSFNCCustomComponent
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  ,Generics.Collections, UITypes
  {$ENDIF}
  {$ENDIF}
  ,WEBLib.TMSFNCGraphics, WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphicsTypes
  {$IFDEF LCLLIB}
  , fgl, LMessages, LCLType
  {$ENDIF}
  , Math, WEBLib.Controls, WEBLib.Graphics
  {$IFDEF FMXLIB}
  , FMX.Types
  {$ENDIF}
  , WEBLib.ExtCtrls
  {$IFDEF VCLLIB}
  , Messages
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,JSON
  {$ELSE}
  ,fpjson
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,Contnrs, Web, WEBLIB.JSON
  {$ENDIF}
  ;

const
  INTERACTIONMARGIN = 5;
  LOGMAX = 100;

  MAJ_VER = 2; // Major version nr.
  MIN_VER = 6; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 3; // Build nr.

  //v1.0.0.0 : First Release
  //v1.0.0.1 : Improved : AddPointArray for quickly adding an array of singles
  //v1.1.0.0 : New : RAD Studio 10.1 Berlin Support
  //v1.5.0.0 : New : Anti-aliasing & native canvas support
  //         : New : OHLC, CandleStick and Boxplot support
  //         : New : Display centered Y-Axis and X-Axis at custom reference value
  //         : Improved : Separation into TMS FNC Core and TMS FNC Chart
  //v1.5.0.1 : Fixed : Issue retrieving correct X-Axis Value Legend Text for a single point
  //v1.5.2.0 : Improved : Changes in TMS FNC Core
  //         : Fixed : Issue retrieving correct X-Axis Value Legend Text for a single point
  //v1.5.3.0 : New : SaveSettingsToFile & LoadSettingsFromFile to persist chart settings
  //         : Fixed : Issue with displaying annotations in line types
  //v1.5.4.0 : New : OnSerieLegendItemClick and OnLegendItemClick events
  //         : Improved : Index property at TTMSFNCChartDrawXYValue and TTMSFNCChartDrawXYGridLine
  //v1.5.5.0 : New : Property Pie.IncludeZeroValues to include zero values
  //v1.5.6.0 : New : DrawOrder property to change the order in which bars are drawn
  //v1.5.6.1 : Fixed : Issue with detecting legend items and pie slices when both are drawn centered
  //v1.5.6.2 : Fixed : Issue with division by zero when having spider or pie chart with no values
  //v1.5.6.3 : Fixed : Issue with line pixel drawing in Pie Legend
  //v1.5.6.4 : Fixed : Issue in Delphi 11 with begin and end scene for CreateBitmapCanvas
  //v1.5.6.5 : Fixed : Issue with one bar calculating width
  //v1.5.6.7 : Fixed : Issue with auto calculating units in combination with date-time format
  //v2.0.0.0 : New : Controls specified by chart type
  //         : New : Appearance initializing colors
  //         : New : Appearance General font to adjust all fonts
  //         : New : LoadFromArray added for single point and multi point series
  //         : New : LoadFromCSV added for single point and multi point series
  //         : New : LoadFromJSON added for single point and multi point series
  //         : New : Database adapter integrated
  //         : New : Grid adapter integrated
  //v2.5.0.0 : New : Logarithmic X and Y Scale
  //         : New : Crosshair support
  //v2.5.0.1 : Improved : Core Improvements
  //v2.6.0.0 : New : Undefined points
  //v2.6.0.1 : Fixed : Issue with rotated text drawing
  //v2.6.0.2 : Fixed : Issue with setting minor formatting in LoadFrom procedures
  //v2.6.0.3 : Fixed : Issue with floating point error in certain conditions

  CSVSeparators: array[1..10] of char = (',',';','#',#9,'|','@','*','-','+','&');

type
  TTMSFNCChart = class;

  TTMSFNCChartSerieType = (ctLine, ctDigitalLine, ctArea, ctStackedArea, ctStackedPercentageArea, ctBar, ctStackedBar, ctStackedPercentageBar,
    ctMarker, ctXYLine, ctXYMarker, ctPie, ctVariableRadiusPie, ctSizedPie, ctSpider, ctBand, ctOHLC, ctCandleStick, ctBoxPlot);

  TTMSFNCChartPointValueType = (cvtNone, cvtYValue, cvtXValue, cvtXLabel, cvtYValueSecond, cvtYValueVariable, cvtYValueHigh, cvtYValueLow, cvtYValueOpen, cvtYValueClose, cvtYValueMedian);

  {$IFDEF CMNWEBLIB}
  TTMSFNCChartSelector = class(TCustomControl)
  {$ENDIF}
  {$IFDEF FMXLIB}
  TTMSFNCChartSelector = class(TControl)
  {$ENDIF}
  private
    FChartType: TTMSFNCChartSerieType;
    FSelected: Boolean;
    FFont: TTMSFNCGraphicsFont;
    FColor: TTMSFNCGraphicsColor;
    FSelectedColor: TTMSFNCGraphicsColor;
    procedure SetChartType(const Value: TTMSFNCChartSerieType);
    procedure SetSelected(const Value: Boolean);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetSelectedColor(const Value: TTMSFNCGraphicsColor);
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure DoFontChanged(Sender: TObject);
  protected
    procedure Paint; override;
    function GetText: String;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
  published
    property ChartType: TTMSFNCChartSerieType read FChartType write SetChartType default ctLine;
    property Selected: Boolean read FSelected write SetSelected default False;
    property Color: TTMSFNCGraphicsColor read FColor write SetColor;
    property SelectedColor: TTMSFNCGraphicsColor read FSelectedColor write SetSelectedColor;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Cursor;
    property Width;
    property Height;
    property OnClick;
    {$IFDEF FMXLIB}
    property Position;
    property ClipChildren;
    property Size;
    {$ENDIF}
  end;

  TTMSFNCChartValuesArray = array of Double;
  TTMSFNCChartLabelsArray = array of string;
  TTMSFNCChartJSONNamesArray = array of string;
  TTMSFNCChartColumnsArray = array of integer;

  TTMSFNCChartSerie = class;

  TTMSFNCChartPoint = class;

  TTMSFNCChartAnnotation = class;

  TTMSFNCChartAnnotationShape = (asRectangle, asCircle);

  TTMSFNCChartAnnotationArrow = (arLine, arStartArrow, arEndArrow, arDoubleArrow);

  TTMSFNCChartAnnotationVirtual = record
    Index: Integer;
    Visible: Boolean;
    Text: String;
    AutoSize: Boolean;
    Annotation: TTMSFNCChartAnnotation;
    Width: Double;
    Height: Double;
    WordWrap: Boolean;
    Shape: TTMSFNCChartAnnotationShape;
    FontColor: TTMSFNCGraphicsColor;
    LineColor: TTMSFNCGraphicsColor;
    LineWidth: Integer;
    ArrowOpacity: Double;
    LineOpacity: Double;
    Arrow: TTMSFNCChartAnnotationArrow;
    TextVerticalAlignment: TTMSFNCGraphicsTextAlign;
    TextHorizontalAlignment: TTMSFNCGraphicsTextAlign;
    OffsetX: Double;
    OffsetY: Double;
    ArrowSize: Double;
    ArrowColor: TTMSFNCGraphicsColor;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCChartAnnotationVirtual) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCChartPointVirtual = record
    Index: Integer;
    Color: TTMSFNCGraphicsColor;
    Tag: Integer;
    XValue: Double;
    XValueText: String;
    YValue, YValueSecond, YValueHigh, YValueClose, YValueLow, YValueOpen, YValueMedian: Double;
    YValueVariable: Double;
    LegendText: String;
    Explode: Double;
    Point: TTMSFNCChartPoint;
    SecondValue: Boolean;
    Undefined: Boolean;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCChartPointVirtual) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCChartDrawLine = record
    StackedValueStart: Double;
    StackedValueEnd: Double;
    StartPoint: TPointF;
    EndPoint: TPointF;
    StartReference: TTMSFNCChartPoint;
    EndReference: TTMSFNCChartPoint;
    StartVirtualReference: TTMSFNCChartPointVirtual;
    EndVirtualReference: TTMSFNCChartPointVirtual;
    Bottom: Boolean;
    DoAnimation: Boolean;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCChartDrawLine) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCChartDrawMultiPoint = record
    PointHigh: TPointF;
    PointLow: TPointF;
    PointOpen: TPointF;
    PointMedian: TPointF;
    PointClose: TPointF;
    Rect: TRectF;
    Reference: TTMSFNCChartPoint;
    VirtualReference: TTMSFNCChartPointVirtual;
    DoAnimation: Boolean;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCChartDrawMultiPoint) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCChartDrawPoint = record
    Point: TPointF;
    Reference: TTMSFNCChartPoint;
    VirtualReference: TTMSFNCChartPointVirtual;
    DoAnimation: Boolean;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCChartDrawPoint) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCChartDrawRect = record
    Rect: TRectF;
    StackedValue: Double;
    Reference: TTMSFNCChartPoint;
    VirtualReference: TTMSFNCChartPointVirtual;
    DoAnimation: Boolean;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCChartDrawRect) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCChartDrawSlice = record
    CenterPoint: TPointF;
    StartPoint: TPointF;
    EndPoint: TPointF;
    Radius: TPointF;
    InnerRadius: TPointF;
    StartAngle, SweepAngle: Double;
    Reference: TTMSFNCChartPoint;
    VirtualReference: TTMSFNCChartPointVirtual;
    DoAnimation: Boolean;
    AnimateEndPoint: Boolean;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCChartDrawSlice) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCChartAnnotation = class(TCollectionItem)
  private
    FTag: Integer;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: Integer;
    FPoint: TTMSFNCChartPoint;
    FAutoSize: Boolean;
    FLineWidth: Integer;
    FTextVerticalAlignment: TTMSFNCGraphicsTextAlign;
    FLineColor: TTMSFNCGraphicsColor;
    FArrowColor: TTMSFNCGraphicsColor;
    FWidth: Double;
    FShape: TTMSFNCChartAnnotationShape;
    FTextHorizontalAlignment: TTMSFNCGraphicsTextAlign;
    FOffsetX: Double;
    FOffsetY: Double;
    FFont: TTMSFNCGraphicsFont;
    FArrowSize: Double;
    FVisible: Boolean;
    FFill: TTMSFNCGraphicsFill;
    FArrow: TTMSFNCChartAnnotationArrow;
    FText: string;
    FWordWrap: Boolean;
    FHeight: Double;
    FStroke: TTMSFNCGraphicsStroke;
    FLineOpacity: Double;
    FArrowOpacity: Double;
    procedure SetArrow(const Value: TTMSFNCChartAnnotationArrow);
    procedure SetArrowColor(const Value: TTMSFNCGraphicsColor);
    procedure SetArrowSize(const Value: Double);
    procedure SetAutoSize(const Value: Boolean);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetHeight(const Value: Double);
    procedure SetLineColor(const Value: TTMSFNCGraphicsColor);
    procedure SetLineWidth(const Value: Integer);
    procedure SetOffSetX(const Value: Double);
    procedure SetOffSetY(const Value: Double);
    procedure SetShape(const Value: TTMSFNCChartAnnotationShape);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetText(const Value: string);
    procedure SetTextHorizontalAlignment(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTextVerticalAlignment(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetVisible(const Value: Boolean);
    procedure SetWidth(const Value: Double);
    procedure SetWordWrap(const Value: Boolean);
    procedure SetLineOpacity(const Value: Double);
    procedure SetArrowOpacity(const Value: Double);
  protected
    procedure FontChanged(Sender: TObject);
    procedure InvalidateChart;
    procedure UpdateChart;
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    function Chart: TTMSFNCChart;
    function Serie: TTMSFNCChartSerie;
    function Point: TTMSFNCChartPoint;
    function GetValue: TTMSFNCChartAnnotationVirtual;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: Integer read FDataInteger write FDataInteger;
  published
    property Tag: Integer read FTag write FTag default 0;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Visible: Boolean read FVisible write SetVisible;
    property Width: Double read FWidth write SetWidth;
    property Height: Double read FHeight write SetHeight;
    property AutoSize: Boolean read FAutoSize write SetAutoSize default True;
    property TextHorizontalAlignment: TTMSFNCGraphicsTextAlign read FTextHorizontalAlignment write SetTextHorizontalAlignment default gtaCenter;
    property TextVerticalAlignment: TTMSFNCGraphicsTextAlign read FTextVerticalAlignment write SetTextVerticalAlignment default gtaCenter;
    property Arrow: TTMSFNCChartAnnotationArrow read FArrow write SetArrow default arLine;
    property ArrowSize: Double read FArrowSize write SetArrowSize;
    property ArrowColor: TTMSFNCGraphicsColor read FArrowColor write SetArrowColor default gcBlack;
    property ArrowOpacity: Double read FArrowOpacity write SetArrowOpacity;
    property LineOpacity: Double read FLineOpacity write SetLineOpacity;
    property LineWidth: Integer read FLineWidth write SetLineWidth;
    property LineColor: TTMSFNCGraphicsColor read FLineColor write SetLineColor default gcBlack;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property OffsetX: Double read FOffsetX write SetOffSetX;
    property OffsetY: Double read FOffsetY write SetOffSetY;
    property Shape: TTMSFNCChartAnnotationShape read FShape write SetShape default asRectangle;
    property Text: string read FText write SetText;
    property WordWrap: Boolean read FWordWrap write SetWordWrap default True;
  end;

  TTMSFNCChartAnnotations = class(TOwnedCollection)
  private
    FPoint: TTMSFNCChartPoint;
    function GetItem(Index: Integer): TTMSFNCChartAnnotation;
    procedure SetItem(Index: Integer; const Value: TTMSFNCChartAnnotation);
  public
    function Point: TTMSFNCChartPoint;
    constructor Create(APoint: TTMSFNCChartPoint);
    function Add: TTMSFNCChartAnnotation;
    function Insert(Index: Integer): TTMSFNCChartAnnotation;
    property Items[Index: Integer]: TTMSFNCChartAnnotation read GetItem write SetItem; default;
  end;

  TTMSFNCChartPoint = class(TCollectionItem)
  private
    FTag: Integer;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: Integer;
    FSerie: TTMSFNCChartSerie;
    FXValue: Double;
    FYValue: Double;
    FXValueText: String;
    FAnnotations: TTMSFNCChartAnnotations;
    FColor: TTMSFNCGraphicsColor;
    FLegendText: String;
    FExplode: Double;
    FYValueVariable: Double;
    FYValueSecond: Double;
    FYValueLow: Double;
    FYValueOpen: Double;
    FYValueClose: Double;
    FYValueMedian: Double;
    FUndefined: Boolean;
    procedure SetUndefined(const Value: Boolean);
    procedure SetXValue(const Value: Double);
    procedure SetYValue(const Value: Double);
    procedure SetXValueText(const Value: String);
    procedure SetAnnotations(const Value: TTMSFNCChartAnnotations);
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure SetLegendText(const Value: String);
    procedure SetExplode(const Value: Double);
    procedure SetYValueVariable(const Value: Double);
    procedure SetYValueSecond(const Value: Double);
    procedure SetYValueClose(const Value: Double);
    procedure SetYValueLow(const Value: Double);
    procedure SetYValueOpen(const Value: Double);
    procedure SetYValueMedian(const Value: Double);
  protected
    procedure BitmapChanged(Sender: TObject);
    procedure UpdateChart;
    procedure UpdateSequential;
    procedure InvalidateChart;
  public
    function Chart: TTMSFNCChart;
    function Serie: TTMSFNCChartSerie;
    function GetValue: TTMSFNCChartPointVirtual;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: Integer read FDataInteger write FDataInteger;
  published
    property Annotations: TTMSFNCChartAnnotations read FAnnotations write SetAnnotations;
    property Color: TTMSFNCGraphicsColor read FColor write SetColor default gcNull;
    property Tag: Integer read FTag write FTag default 0;
    property XValue: Double read FXValue write SetXValue;
    property XValueText: String read FXValueText write SetXValueText;
    property YValue: Double read FYValue write SetYValue;
    property YValueSecond: Double read FYValueSecond write SetYValueSecond;
    property YValueVariable: Double read FYValueVariable write SetYValueVariable;
    property YValueLow: Double read FYValueLow write SetYValueLow;
    property YValueClose: Double read FYValueClose write SetYValueClose;
    property YValueHigh: Double read FYValue write SetYValue;
    property YValueOpen: Double read FYValueOpen write SetYValueOpen;
    property YValueMedian: Double read FYValueMedian write SetYValueMedian;
    property LegendText: String read FLegendText write SetLegendText;
    property Explode: Double read FExplode write SetExplode;
    property Undefined: Boolean read FUndefined write SetUndefined default False;
  end;

  TTMSFNCChartPoints = class(TOwnedCollection)
  private
    FSerie: TTMSFNCChartSerie;
    function GetItem(Index: Integer): TTMSFNCChartPoint;
    procedure SetItem(Index: Integer; const Value: TTMSFNCChartPoint);
  public
    function Serie: TTMSFNCChartSerie;
    constructor Create(ASerie: TTMSFNCChartSerie);
    function Add: TTMSFNCChartPoint;
    function Insert(Index: Integer): TTMSFNCChartPoint;
    property Items[Index: Integer]: TTMSFNCChartPoint read GetItem write SetItem; default;
  end;

  TTMSFNCChartDrawXYValueKind = (vkMajor, vkMinor);

  TTMSFNCChartDrawXYValue = record
    Index: Integer;
    ValueString: String;
    Value: Double;
    Kind: TTMSFNCChartDrawXYValueKind;
    TextWidth: Double;
    TextHeight: Double;
    ValuePositionYLeft, ValuePositionYCenter, ValuePositionYRight: TPointF;
    ValuePositionXTop, ValuePositionXCenter, ValuePositionXBottom: TPointF;
    TextPositionYLeft, TextPositionYCenter, TextPositionYRight: TPointF;
    TextPositionXTop, TextPositionXCenter, TextPositionXBottom: TPointF;
    ValueRadius: TPointF;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCChartDrawXYValue) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCChartDrawXYGridLine = record
    Index: Integer;
    Value: Double;
    Kind: TTMSFNCChartDrawXYValueKind;
    XStartPoint, XEndPoint: TPointF;
    YStartPoint, YEndPoint: TPointF;
    ValueRadius: TPointF;
    ValueGridLine: Boolean;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCChartDrawXYGridLine) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCChartDrawLabel = record
    ValueString: String;
    Value: Double;
    Reference: TTMSFNCChartPoint;
    VirtualReference: TTMSFNCChartPointVirtual;
    Point: TPointF;
    Rect: TRectF;
    TextWidth, TextHeight: Double;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCChartDrawLabel) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCChartDrawAnnotation = record
    AnnotationReference: TTMSFNCChartAnnotation;
    VirtualAnnotationReference: TTMSFNCChartAnnotationVirtual;
    PointReference: TTMSFNCChartPoint;
    VirtualPointReference: TTMSFNCChartPointVirtual;
    StartPoint, AnnotationPoint: TPointF;
    Rect: TRectF;
    TextWidth, TextHeight: Double;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCChartDrawAnnotation) b : boolean;
    {$ENDIF}
  end;

  TTMSFNCChartSerieAutoRange = (arDisabled, arEnabled, arEnabledZeroBased, arCommon, arCommonZeroBased);

  TTMSFNCChartSerieMode = (smMathematical, smStatistical);

  TTMSFNCChartSerieBarWidthType = (bwtPercentage, bwtPixels);

  TTMSFNCChartSerieBar = class(TPersistent)
  private
    FSerie: TTMSFNCChartSerie;
    FWidth: Double;
    FSpacing: Double;
    FWidthType: TTMSFNCChartSerieBarWidthType;
    procedure SetSpacing(const Value: Double);
    procedure SetWidth(const Value: Double);
    procedure SetWidthType(const Value: TTMSFNCChartSerieBarWidthType);
  protected
    procedure UpdateChart;
    procedure InvalidateChart;
  public
    function Chart: TTMSFNCChart;
    function Serie: TTMSFNCChartSerie;
    constructor Create(ASerie: TTMSFNCChartSerie);
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property WidthType: TTMSFNCChartSerieBarWidthType read FWidthType write SetWidthType default bwtPercentage;
    property Width: Double read FWidth write SetWidth;
    property Spacing: Double read FSpacing write SetSpacing;
  end;

  TTMSFNCChartSerieMultiPoint = class(TPersistent)
  private
    FSerie: TTMSFNCChartSerie;
    FDecreaseStrokeColor: TTMSFNCGraphicsColor;
    FIncreaseStrokeColor: TTMSFNCGraphicsColor;
    FWidth: Double;
    FWidthType: TTMSFNCChartSerieBarWidthType;
    FIncreaseFillColor: TTMSFNCGraphicsColor;
    FDecreaseFillColor: TTMSFNCGraphicsColor;
    procedure SetDecreaseStrokeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetIncreaseStrokeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetWidth(const Value: Double);
    procedure SetWidthType(const Value: TTMSFNCChartSerieBarWidthType);
    procedure SetDecreaseFillColor(const Value: TTMSFNCGraphicsColor);
    procedure SetIncreaseFillColor(const Value: TTMSFNCGraphicsColor);
  protected
    procedure UpdateChart;
    procedure InvalidateChart;
  public
    function Chart: TTMSFNCChart;
    function Serie: TTMSFNCChartSerie;
    constructor Create(ASerie: TTMSFNCChartSerie);
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property WidthType: TTMSFNCChartSerieBarWidthType read FWidthType write SetWidthType default bwtPercentage;
    property Width: Double read FWidth write SetWidth;
    property IncreaseStrokeColor: TTMSFNCGraphicsColor read FIncreaseStrokeColor write SetIncreaseStrokeColor default {$IFDEF FMXLIB}$FF089F95{$ENDIF}{$IFNDEF FMXLIB}$959F08{$ENDIF};
    property DecreaseStrokeColor: TTMSFNCGraphicsColor read FDecreaseStrokeColor write SetDecreaseStrokeColor default {$IFDEF FMXLIB}$FFE14A4D{$ENDIF}{$IFNDEF FMXLIB}$4D4AE1{$ENDIF};
    property IncreaseFillColor: TTMSFNCGraphicsColor read FIncreaseFillColor write SetIncreaseFillColor default {$IFDEF FMXLIB}$FF03A69B{$ENDIF}{$IFNDEF FMXLIB}$9BA603{$ENDIF};
    property DecreaseFillColor: TTMSFNCGraphicsColor read FDecreaseFillColor write SetDecreaseFillColor default {$IFDEF FMXLIB}$FFF45154{$ENDIF}{$IFNDEF FMXLIB}$5451F4{$ENDIF};
  end;

  TTMSFNCChartSeriePiePosition = (ppTopLeft, ppTopCenter, ppTopRight, ppCenterLeft,
    ppCenterCenter, ppCenterRight, ppBottomLeft, ppBottomCenter, ppBottomRight);

  TTMSFNCChartMargins = class(TPersistent)
  private
    FRight: integer;
    FBottom: integer;
    FTop: integer;
    FLeft: integer;
    FOnChange: TNotifyEvent;
    procedure SetBottom(const Value: integer);
    procedure SetLeft(const Value: integer);
    procedure SetRight(const Value: integer);
    procedure SetTop(const Value: integer);
  protected
    procedure Changed;
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
  published
    property Left: integer read FLeft write SetLeft;
    property Top: integer read FTop write SetTop;
    property Right: integer read FRight write SetRight;
    property Bottom: integer read FBottom write SetBottom;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCChartSeriePie = class(TPersistent)
  private
    FSerie: TTMSFNCChartSerie;
    FPosition: TTMSFNCChartSeriePiePosition;
    FAutoSize: Boolean;
    FMargins: TTMSFNCChartMargins;
    FSize: Double;
    FStartAngle: Double;
    FSweepAngle: Double;
    FInnerSize: Double;
    FStacked: Boolean;
    FIncludeZeroValues: Boolean;
    procedure SetPosition(const Value: TTMSFNCChartSeriePiePosition);
    procedure SetAutoSize(const Value: Boolean);
    procedure SetMargins(const Value: TTMSFNCChartMargins);
    procedure SetSize(const Value: Double);
    procedure SetStartAngle(const Value: Double);
    procedure SetSweepAngle(const Value: Double);
    procedure SetInnerSize(const Value: Double);
    procedure SetStacked(const Value: Boolean);
    procedure SetIncludeZeroValues(const Value: Boolean);
  protected
    procedure UpdateChart;
    procedure InvalidateChart;
    procedure MarginsChanged(Sender: TObject);
  public
    function Chart: TTMSFNCChart;
    function Serie: TTMSFNCChartSerie;
    constructor Create(ASerie: TTMSFNCChartSerie);
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property Position: TTMSFNCChartSeriePiePosition read FPosition write SetPosition default ppCenterCenter;
    property Size: Double read FSize write SetSize;
    property InnerSize: Double read FInnerSize write SetInnerSize;
    property AutoSize: Boolean read FAutoSize write SetAutoSize default True;
    property Margins: TTMSFNCChartMargins read FMargins write SetMargins;
    property StartAngle: Double read FStartAngle write SetStartAngle;
    property SweepAngle: Double read FSweepAngle write SetSweepAngle;
    property Stacked: Boolean read FStacked write SetStacked default False;
    property IncludeZeroValues: Boolean read FIncludeZeroValues write SetIncludeZeroValues default False;
  end;

  TTMSFNCChartSerieMarkerShape = (msEllipse, msSquare, msDiamond, msTriangle, msBitmap, msCustom);

  TTMSFNCChartSerieMarkers = class(TPersistent)
  private
    FSerie: TTMSFNCChartSerie;
    FWidth: Double;
    FShape: TTMSFNCChartSerieMarkerShape;
    FHeight: Double;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FBitmap: TTMSFNCBitmap;
    FVisible: Boolean;
    procedure SetHeight(const Value: Double);
    procedure SetShape(const Value: TTMSFNCChartSerieMarkerShape);
    procedure SetWidth(const Value: Double);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetBitmap(const Value: TTMSFNCBitmap);
    procedure SetVisible(const Value: Boolean);
  protected
    procedure StrokeChanged(Sender: TObject);
    procedure FillChanged(Sender: TObject);
    procedure UpdateChart;
    procedure InvalidateChart;
  public
    function Chart: TTMSFNCChart;
    function Serie: TTMSFNCChartSerie;
    constructor Create(ASerie: TTMSFNCChartSerie);
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property Bitmap: TTMSFNCBitmap read FBitmap write SetBitmap;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Height: Double read FHeight write SetHeight;
    property Shape: TTMSFNCChartSerieMarkerShape read FShape write SetShape default msEllipse;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Visible: Boolean read FVisible write SetVisible default False;
    property Width: Double read FWidth write SetWidth;
  end;

  TTMSFNCChartXAxisPosition = (xpTop, xpCenter, xpBottom);
  TTMSFNCChartXAxisPositions = set of TTMSFNCChartXAxisPosition;

  TTMSFNCChartYAxisPosition = (ypLeft, ypCenter, ypRight);
  TTMSFNCChartYAxisPositions = set of TTMSFNCChartYAxisPosition;

  {$IFDEF WEBLIB}
  TTMSFNCChartDrawSlices = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCChartDrawSlice;
    procedure SetItem(Index: Integer; Value: TTMSFNCChartDrawSlice);
  public
    property Items[Index: Integer]: TTMSFNCChartDrawSlice read GetItem write SetItem; default;
  end;
  TTMSFNCChartDrawLines = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCChartDrawLine;
    procedure SetItem(Index: Integer; Value: TTMSFNCChartDrawLine);
  public
    property Items[Index: Integer]: TTMSFNCChartDrawLine read GetItem write SetItem; default;
  end;
  TTMSFNCChartDrawMultiPoints = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCChartDrawMultiPoint;
    procedure SetItem(Index: Integer; Value: TTMSFNCChartDrawMultiPoint);
  public
    property Items[Index: Integer]: TTMSFNCChartDrawMultiPoint read GetItem write SetItem; default;
  end;
  TTMSFNCChartDrawRects = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCChartDrawRect;
    procedure SetItem(Index: Integer; Value: TTMSFNCChartDrawRect);
  public
    property Items[Index: Integer]: TTMSFNCChartDrawRect read GetItem write SetItem; default;
  end;
  TTMSFNCChartDrawPoints = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCChartDrawPoint;
    procedure SetItem(Index: Integer; Value: TTMSFNCChartDrawPoint);
  public
    property Items[Index: Integer]: TTMSFNCChartDrawPoint read GetItem write SetItem; default;
  end;
  TTMSFNCChartDrawXYValues = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCChartDrawXYValue;
    procedure SetItem(Index: Integer; Value: TTMSFNCChartDrawXYValue);
  public
    property Items[Index: Integer]: TTMSFNCChartDrawXYValue read GetItem write SetItem; default;
  end;
  TTMSFNCChartDrawXYGridLines = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCChartDrawXYGridLine;
    procedure SetItem(Index: Integer; Value: TTMSFNCChartDrawXYGridLine);
  public
    property Items[Index: Integer]: TTMSFNCChartDrawXYGridLine read GetItem write SetItem; default;
  end;
  TTMSFNCChartDrawLabels = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCChartDrawLabel;
    procedure SetItem(Index: Integer; Value: TTMSFNCChartDrawLabel);
  public
    property Items[Index: Integer]: TTMSFNCChartDrawLabel read GetItem write SetItem; default;
  end;
  TTMSFNCChartDrawAnnotations = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCChartDrawAnnotation;
    procedure SetItem(Index: Integer; Value: TTMSFNCChartDrawAnnotation);
  public
    property Items[Index: Integer]: TTMSFNCChartDrawAnnotation read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCChartDrawSlices = TList<TTMSFNCChartDrawSlice>;
  TTMSFNCChartDrawLines = TList<TTMSFNCChartDrawLine>;
  TTMSFNCChartDrawMultiPoints = TList<TTMSFNCChartDrawMultiPoint>;
  TTMSFNCChartDrawRects = TList<TTMSFNCChartDrawRect>;
  TTMSFNCChartDrawPoints = TList<TTMSFNCChartDrawPoint>;
  TTMSFNCChartDrawXYValues = TList<TTMSFNCChartDrawXYValue>;
  TTMSFNCChartDrawXYGridLines = TList<TTMSFNCChartDrawXYGridLine>;
  TTMSFNCChartDrawLabels = TList<TTMSFNCChartDrawLabel>;
  TTMSFNCChartDrawAnnotations = TList<TTMSFNCChartDrawAnnotation>;
  {$ENDIF}

  TTMSFNCChartElement = class(TPersistent)
  private
    FChart: TTMSFNCChart;
    FVisible: Boolean;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    procedure SetVisible(const Value: Boolean);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
  protected
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure Draw(AGraphics: TTMSFNCGraphics); virtual;
    procedure UpdateChart;
    procedure InvalidateChart;
  public
    function Chart: TTMSFNCChart;
    procedure Assign(Source: TPersistent); override;
    constructor Create(AChart: TTMSFNCChart); virtual;
    destructor Destroy; override;
  published
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Visible: Boolean read FVisible write SetVisible default True;
  end;

  TTMSFNCChartTitlePosition = (tipTop, tipBottom);
  TTMSFNCChartTitlePositions = set of TTMSFNCChartTitlePosition;

  TTMSFNCChartTitle = class(TTMSFNCChartElement)
  private
    FTextMargins: TTMSFNCChartMargins;
    FText: String;
    FPositions: TTMSFNCChartTitlePositions;
    FHeight: Double;
    FTextVerticalAlignment: TTMSFNCGraphicsTextAlign;
    FTextHorizontalAlignment: TTMSFNCGraphicsTextAlign;
    FFont: TTMSFNCGraphicsFont;
    FBorder: Boolean;
    FLine: Boolean;
    procedure SetPositions(const Value: TTMSFNCChartTitlePositions);
    procedure SetText(const Value: String);
    procedure SetTextMargins(const Value: TTMSFNCChartMargins);
    procedure SetHeight(const Value: Double);
    procedure SetTextHorizontalAlignment(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTextVerticalAlignment(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetBorder(const Value: Boolean);
    procedure SetLine(const Value: Boolean);
  protected
    procedure Draw(AGraphics: TTMSFNCGraphics); override;
    procedure DrawPart(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartTitlePosition);
    procedure TextMarginsChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
  public
    function GetRectangle(APosition: TTMSFNCChartTitlePosition): TRectF;
    procedure Assign(Source: TPersistent); override;
    procedure AssignSource(Source: TPersistent); virtual;
    constructor Create(AChart: TTMSFNCChart); override;
    destructor Destroy; override;
  published
    property Border: Boolean read FBorder write SetBorder default False;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Height: Double read FHeight write SetHeight;
    property Line: Boolean read FLine write SetLine default True;
    property Positions: TTMSFNCChartTitlePositions read FPositions write SetPositions default [tipTop];
    property TextHorizontalAlignment: TTMSFNCGraphicsTextAlign read FTextHorizontalAlignment write SetTextHorizontalAlignment default gtaCenter;
    property TextVerticalAlignment: TTMSFNCGraphicsTextAlign read FTextVerticalAlignment write SetTextVerticalAlignment default gtaCenter;
    property TextMargins: TTMSFNCChartMargins read FTextMargins write SetTextMargins;
    property Text: String read FText write SetText;
  end;

  TTMSFNCChartXAxis = class(TTMSFNCChartElement)
  private
    FHeight: Double;
    FTopHeight, FCenterHeight, FBottomHeight: Double;
    FPositions: TTMSFNCChartXAxisPositions;
    FLine: Boolean;
    FBorder: Boolean;
    FAutoSize: Boolean;
    FDisplayAtReferenceValue: Boolean;
    FReferenceValueSeriesIndex: Integer;
    FReferenceValue: Double;
    procedure SetHeight(const Value: Double);
    procedure SetPositions(const Value: TTMSFNCChartXAxisPositions);
    procedure SetLine(const Value: Boolean);
    procedure SetBorder(const Value: Boolean);
    procedure SetAutoSize(const Value: Boolean);
    procedure SetDisplayAtReferenceValue(const Value: Boolean);
    procedure SetReferenceValueSeriesIndex(const Value: Integer);
    procedure SetReferenceValue(const Value: Double);
    function IsReferenceValueStored: Boolean;
  protected
    procedure Draw(AGraphics: TTMSFNCGraphics); override;
    procedure DrawPart(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartXAxisPosition);
    procedure DrawValues(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartXAxisPosition; ASerie: TTMSFNCChartSerie);
    procedure DrawKind(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartXAxisPosition; AKind: TTMSFNCChartDrawXYValueKind; ASerie: TTMSFNCChartSerie);
    procedure DrawTitle(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartXAxisPosition; ASerie: TTMSFNCChartSerie);
  public
    function GetHeight(APosition: TTMSFNCChartXAxisPosition): Double;
    function GetRectangle(APosition: TTMSFNCChartXAxisPosition): TRectF;
    function Chart: TTMSFNCChart;
    constructor Create(AChart: TTMSFNCChart); override;
    procedure Assign(Source: TPersistent); override;
    procedure AssignSource(Source: TPersistent); virtual;
    destructor Destroy; override;
  published
    property DisplayAtReferenceValue: Boolean read FDisplayAtReferenceValue write SetDisplayAtReferenceValue default False;
    property ReferenceValueSeriesIndex: Integer read FReferenceValueSeriesIndex write SetReferenceValueSeriesIndex default 0;
    property ReferenceValue: Double read FReferenceValue write SetReferenceValue stored IsReferenceValueStored nodefault;
    property AutoSize: Boolean read FAutoSize write SetAutoSize default True;
    property Border: Boolean read FBorder write SetBorder default False;
    property Height: Double read FHeight write SetHeight;
    property Line: Boolean read FLine write SetLine default True;
    property Positions: TTMSFNCChartXAxisPositions read FPositions write SetPositions default [xpBottom];
  end;

  TTMSFNCChartYAxis = class(TTMSFNCChartElement)
  private
    FWidth, FLeftWidth, FCenterWidth, FRightWidth: Double;
    FPositions: TTMSFNCChartYAxisPositions;
    FLine: Boolean;
    FBorder: Boolean;
    FAutoSize: Boolean;
    FDisplayAtReferenceValue: Boolean;
    FReferenceValueSeriesIndex: Integer;
    FReferenceValue: Double;
    procedure SetWidth(const Value: Double);
    procedure SetPositions(const Value: TTMSFNCChartYAxisPositions);
    procedure SetLine(const Value: Boolean);
    procedure SetBorder(const Value: Boolean);
    procedure SetAutoSize(const Value: Boolean);
    procedure SetDisplayAtReferenceValue(const Value: Boolean);
    procedure SetReferenceValueSeriesIndex(const Value: Integer);
    procedure SetReferenceValue(const Value: Double);
    function IsReferenceValueStored: Boolean;
  protected
    procedure Draw(AGraphics: TTMSFNCGraphics); override;
    procedure DrawPart(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartYAxisPosition);
    procedure DrawValues(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartYAxisPosition; ASerie: TTMSFNCChartSerie);
    procedure DrawKind(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartYAxisPosition; AKind: TTMSFNCChartDrawXYValueKind; ASerie: TTMSFNCChartSerie);
    procedure DrawTitle(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartYAxisPosition; ASerie: TTMSFNCChartSerie);
  public
    function GetWidth(APosition: TTMSFNCChartYAxisPosition): Double;
    function GetRectangle(APosition: TTMSFNCChartYAxisPosition): TRectF;
    constructor Create(AChart: TTMSFNCChart); override;
    procedure Assign(Source: TPersistent); override;
    procedure AssignSource(Source: TPersistent); virtual;
    destructor Destroy; override;
  published
    property DisplayAtReferenceValue: Boolean read FDisplayAtReferenceValue write SetDisplayAtReferenceValue default False;
    property ReferenceValueSeriesIndex: Integer read FReferenceValueSeriesIndex write SetReferenceValueSeriesIndex default 0;
    property ReferenceValue: Double read FReferenceValue write SetReferenceValue stored IsReferenceValueStored nodefault;
    property AutoSize: Boolean read FAutoSize write SetAutoSize default True;
    property Border: Boolean read FBorder write SetBorder default False;
    property Line: Boolean read FLine write SetLine default True;
    property Positions: TTMSFNCChartYAxisPositions read FPositions write SetPositions default [ypLeft, ypRight];
    property Width: Double read FWidth write SetWidth;
  end;

  TTMSFNCChartLegendPosition = (lpTopLeft, lpTopCenter, lpTopRight, lpCenterLeft, lpCenterCenter,
    lpCenterRight, lpBottomLeft, lpBottomCenter, lpBottomRight);

  TTMSFNCChartLegend = class(TTMSFNCChartElement)
  private
    FTop: Double;
    FLeft: Double;
    FPosition: TTMSFNCChartLegendPosition;
    FFont: TTMSFNCGraphicsFont;
    procedure SetLeft(const Value: Double);
    procedure SetPosition(const Value: TTMSFNCChartLegendPosition);
    procedure SetTop(const Value: Double);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
  protected
    procedure Draw(AGraphics: TTMSFNCGraphics); override;
    procedure FontChanged(Sender: TObject);
  public
    function GetRectangle: TRectF; virtual;
    constructor Create(AChart: TTMSFNCChart); override;
    procedure Assign(Source: TPersistent); override;
    procedure AssignSource(Source: TPersistent); virtual;
    destructor Destroy; override;
    function XYToItem(X, Y: Double): Integer; virtual;
  published
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Left: Double read FLeft write SetLeft;
    property Position: TTMSFNCChartLegendPosition read FPosition write SetPosition default lpTopLeft;
    property Top: Double read FTop write SetTop;
  end;

  TTMSFNCChartSerieLegend = class(TPersistent)
  private
    FSerie: TTMSFNCChartSerie;
    FVisible: Boolean;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FFont: TTMSFNCGraphicsFont;
    FTop: Double;
    FLeft: Double;
    FPosition: TTMSFNCChartLegendPosition;
    procedure SetVisible(const Value: Boolean);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetLeft(const Value: Double);
    procedure SetPosition(const Value: TTMSFNCChartLegendPosition);
    procedure SetTop(const Value: Double);
  protected
    function GetRectangle: TRectF; virtual;
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure InvalidateChart;
    procedure UpdateChart;
    procedure FontChanged(Sender: TObject);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ASerie: TTMSFNCChartSerie);
    destructor Destroy; override;
    function Chart: TTMSFNCChart;
    function Serie: TTMSFNCChartSerie;
  published
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Left: Double read FLeft write SetLeft;
    property Position: TTMSFNCChartLegendPosition read FPosition write SetPosition default lpCenterRight;
    property Top: Double read FTop write SetTop;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Visible: Boolean read FVisible write SetVisible default False;
  end;

  TTMSFNCChartSerieValueFormatType = (vftNormal, vftFloat, vftDateTime);

  TTMSFNCChartSerieXYValues = class;

  TTMSFNCChartSerieXYTitle = class(TPersistent)
  private
    FSerie: TTMSFNCChartSerie;
    FTextMargins: TTMSFNCChartMargins;
    FTextHorizontalAlignment: TTMSFNCGraphicsTextAlign;
    FTextVerticalAlignment: TTMSFNCGraphicsTextAlign;
    FText: String;
    FFont: TTMSFNCGraphicsFont;
    procedure SetText(const Value: String);
    procedure SetTextMargins(const Value: TTMSFNCChartMargins);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTextHorizontalAlignment(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTextVerticalAlignment(const Value: TTMSFNCGraphicsTextAlign);
  protected
    procedure UpdateChart;
    procedure InvalidateChart;
    procedure TextMarginsChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
  public
    function Serie: TTMSFNCChartSerie;
    function Chart: TTMSFNCChart;
    procedure Assign(Source: TPersistent); override;
    constructor Create(ASerie: TTMSFNCChartSerie); virtual;
    destructor Destroy; override;
  published
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property TextHorizontalAlignment: TTMSFNCGraphicsTextAlign read FTextHorizontalAlignment write SetTextHorizontalAlignment default gtaCenter;
    property TextVerticalAlignment: TTMSFNCGraphicsTextAlign read FTextVerticalAlignment write SetTextVerticalAlignment default gtaCenter;
    property TextMargins: TTMSFNCChartMargins read FTextMargins write SetTextMargins;
    property Text: String read FText write SetText;
  end;

  TTMSFNCChartSerieXYValues = class(TPersistent)
  private
    FSerie: TTMSFNCChartSerie;
    FMinorUnit: Double;
    FMajorUnit: Double;
    FMajorUnitTickMarkSize: Double;
    FMinorUnitFormat: String;
    FMinorUnitSpacing: Double;
    FMajorUnitFormat: String;
    FMajorUnitSpacing: Double;
    FMinorUnitFont: TTMSFNCGraphicsFont;
    FMajorUnitFont: TTMSFNCGraphicsFont;
    FMinorUnitTickMarkSize: Double;
    FMinorUnitFormatType: TTMSFNCChartSerieValueFormatType;
    FMajorUnitFormatType: TTMSFNCChartSerieValueFormatType;
    FMinorUnitTickMarkColor: TTMSFNCGraphicsColor;
    FMajorUnitTickMarkColor: TTMSFNCGraphicsColor;
    FAutoUnits: Boolean;
    FTitle: TTMSFNCChartSerieXYTitle;
    FSpiderAngle: Single;
    FSpiderValues: Boolean;
    procedure SetMajorUnit(const Value: Double);
    procedure SetMajorUnitFormat(const Value: String);
    procedure SetMajorUnitSpacing(const Value: Double);
    procedure SetMajorUnitTickMarkSize(const Value: Double);
    procedure SetMinorUnit(const Value: Double);
    procedure SetMinorUnitFormat(const Value: String);
    procedure SetMinorUnitSpacing(const Value: Double);
    procedure SetMajorUnitFont(const Value: TTMSFNCGraphicsFont);
    procedure SetMinorUnitFont(const Value: TTMSFNCGraphicsFont);
    procedure SetMinorUnitTickMarkSize(const Value: Double);
    procedure SetMajorUnitFormatType(
      const Value: TTMSFNCChartSerieValueFormatType);
    procedure SetMinorUnitFormatType(
      const Value: TTMSFNCChartSerieValueFormatType);
    procedure SetMajorUnitTickMarkColor(const Value: TTMSFNCGraphicsColor);
    procedure SetMinorUnitTickMarkColor(const Value: TTMSFNCGraphicsColor);
    procedure SetAutoUnits(const Value: Boolean);
    procedure SetTitle(const Value: TTMSFNCChartSerieXYTitle);
    procedure SetSpiderValues(const Value: Boolean);
  protected
    procedure UpdateChart;
    procedure InvalidateChart;
    procedure FontChanged(Sender: TObject);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ASerie: TTMSFNCChartSerie); virtual;
    destructor Destroy; override;
    function Chart: TTMSFNCChart;
    function Serie: TTMSFNCChartSerie;
  published
    property AutoUnits: Boolean read FAutoUnits write SetAutoUnits default True;
    property MajorUnit: Double read FMajorUnit write SetMajorUnit;
    property MajorUnitFont: TTMSFNCGraphicsFont read FMajorUnitFont write SetMajorUnitFont;
    property MajorUnitFormat: String read FMajorUnitFormat write SetMajorUnitFormat;
    property MajorUnitFormatType: TTMSFNCChartSerieValueFormatType read FMajorUnitFormatType write SetMajorUnitFormatType default vftNormal;
    property MajorUnitSpacing: Double read FMajorUnitSpacing write SetMajorUnitSpacing;
    property MajorUnitTickMarkSize: Double read FMajorUnitTickMarkSize write SetMajorUnitTickMarkSize;
    property MajorUnitTickMarkColor: TTMSFNCGraphicsColor read FMajorUnitTickMarkColor write SetMajorUnitTickMarkColor default gcGray;
    property MinorUnit: Double read FMinorUnit write SetMinorUnit;
    property MinorUnitFont: TTMSFNCGraphicsFont read FMinorUnitFont write SetMinorUnitFont;
    property MinorUnitFormat: String read FMinorUnitFormat write SetMinorUnitFormat;
    property MinorUnitFormatType: TTMSFNCChartSerieValueFormatType read FMinorUnitFormatType write SetMinorUnitFormatType default vftNormal;
    property MinorUnitSpacing: Double read FMinorUnitSpacing write SetMinorUnitSpacing;
    property MinorUnitTickMarkSize: Double read FMinorUnitTickMarkSize write SetMinorUnitTickMarkSize;
    property MinorUnitTickMarkColor: TTMSFNCGraphicsColor read FMinorUnitTickMarkColor write SetMinorUnitTickMarkColor default gcGray;
    property Title: TTMSFNCChartSerieXYTitle read FTitle write SetTitle;
    property SpiderValues: Boolean read FSpiderValues write SetSpiderValues default True;
  end;

  TTMSFNCChartSerieXValues = class(TTMSFNCChartSerieXYValues)
  private
    FPositions: TTMSFNCChartXAxisPositions;
    FAngle: Double;
    procedure SetPositions(const Value: TTMSFNCChartXAxisPositions);
    procedure SetAngle(const Value: Double);
  public
    constructor Create(ASerie: TTMSFNCChartSerie); override;
    procedure Assign(Source: TPersistent); override;
    procedure AssignSource(Source: TPersistent); virtual;
    destructor Destroy; override;
  published
    property Angle: Double read FAngle write SetAngle;
    property AutoUnits default False;
    property Positions: TTMSFNCChartXAxisPositions read FPositions write SetPositions default [xpBottom];
  end;

  TTMSFNCChartSerieYValues = class(TTMSFNCChartSerieXYValues)
  private
    FPositions: TTMSFNCChartYAxisPositions;
    procedure SetPositions(const Value: TTMSFNCChartYAxisPositions);
  public
    constructor Create(ASerie: TTMSFNCChartSerie); override;
    procedure Assign(Source: TPersistent); override;
    procedure AssignSource(Source: TPersistent); virtual;
    destructor Destroy; override;
  published
    property Positions: TTMSFNCChartYAxisPositions read FPositions write SetPositions default [ypLeft];
  end;

  TTMSFNCChartSerieSpiderGridKind = (sgkCircles, sgkPolygon);

  TTMSFNCChartSerieXYGrid = class(TPersistent)
  private
    FSerie: TTMSFNCChartSerie;
    FMajorUnitStroke: TTMSFNCGraphicsStroke;
    FMinorUnitStroke: TTMSFNCGraphicsStroke;
    FVisible: Boolean;
    FExtended: Boolean;
    FSpiderKind: TTMSFNCChartSerieSpiderGridKind;
    FSpiderVisible: Boolean;
    FSpiderLegend: Boolean;
    procedure SetMajorUnitStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetMinorUnitStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetVisible(const Value: Boolean);
    procedure SetExtended(const Value: Boolean);
    procedure SetSpiderKind(const Value: TTMSFNCChartSerieSpiderGridKind);
    procedure SetSpiderVisible(const Value: Boolean);
    procedure SetSpiderLegend(const Value: Boolean);
  protected
    procedure StrokeChanged(Sender: TObject);
    procedure UpdateChart;
    procedure InvalidateChart;
    procedure Draw(AGraphics: TTMSFNCGraphics; AXGrid: Boolean = True);
    procedure DrawKind(AGraphics: TTMSFNCGraphics; AKind: TTMSFNCChartDrawXYValueKind; AXGrid: Boolean = True);
  public
    procedure Assign(Source: TPersistent); override;
    procedure AssignSource(Source: TPersistent); virtual;
    constructor Create(ASerie: TTMSFNCChartSerie); virtual;
    destructor Destroy; override;
    function Chart: TTMSFNCChart;
    function Serie: TTMSFNCChartSerie;
  published
    property Extended: Boolean read FExtended write SetExtended default True;
    property MajorUnitStroke: TTMSFNCGraphicsStroke read FMajorUnitStroke write SetMajorUnitStroke;
    property MinorUnitStroke: TTMSFNCGraphicsStroke read FMinorUnitStroke write SetMinorUnitStroke;
    property Visible: Boolean read FVisible write SetVisible default False;
    property SpiderVisible: Boolean read FSpiderVisible write SetSpiderVisible default True;
    property SpiderKind: TTMSFNCChartSerieSpiderGridKind read FSpiderKind write SetSpiderKind default sgkCircles;
    property SpiderLegend: Boolean read FSpiderLegend write SetSpiderLegend default False;
  end;

  TTMSFNCChartSerieLabelsPosition = (lpInside, lpOutside);

  TTMSFNCChartSerieLabelsMode = (lmNormal, lmStacked);

  TTMSFNCChartSerieLabels = class(TPersistent)
  private
    FSerie: TTMSFNCChartSerie;
    FVisible: Boolean;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FFormatType: TTMSFNCChartSerieValueFormatType;
    FFont: TTMSFNCGraphicsFont;
    FFormat: String;
    FOffsetX: Double;
    FOffsetY: Double;
    FMode: TTMSFNCChartSerieLabelsMode;
    procedure SetVisible(const Value: Boolean);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetFormat(const Value: String);
    procedure SetFormatType(const Value: TTMSFNCChartSerieValueFormatType);
    procedure SetOffsetX(const Value: Double);
    procedure SetOffsetY(const Value: Double);
    procedure SetMode(const Value: TTMSFNCChartSerieLabelsMode);
  protected
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure InvalidateChart;
    procedure UpdateChart;
    procedure FontChanged(Sender: TObject);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ASerie: TTMSFNCChartSerie);
    destructor Destroy; override;
    function Chart: TTMSFNCChart;
    function Serie: TTMSFNCChartSerie;
  published
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Format: String read FFormat write SetFormat;
    property FormatType: TTMSFNCChartSerieValueFormatType read FFormatType write SetFormatType default vftNormal;
    property OffsetX: Double read FOffsetX write SetOffsetX;
    property OffsetY: Double read FOffsetY write SetOffsetY;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Visible: Boolean read FVisible write SetVisible default False;
    property Mode: TTMSFNCChartSerieLabelsMode read FMode write SetMode default lmNormal;
  end;

  TTMSFNCChartCrosshairMode = (ccmContinuous, ccmPointBased);

  TTMSFNCChartCrosshairModes = set of TTMSFNCChartCrosshairMode;

  TTMSFNCChartCrosshair = class(TPersistent)
  private
    FChart: TTMSFNCChart;
    FVisible: Boolean;
    FContinuousSeriesIndex: Integer;
    FModes: TTMSFNCChartCrosshairModes;
    procedure SetVisible(const Value: Boolean);
    procedure SetModes(const Value: TTMSFNCChartCrosshairModes);
    procedure SetContinuousSeriesIndex(const Value: Integer);
  protected
    procedure Draw(AGraphics: TTMSFNCGraphics); virtual;
    procedure UpdateChart;
    procedure InvalidateChart;
  public
    procedure AssignSource(Source: TPersistent); virtual;
    procedure Assign(Source: TPersistent); override;
    constructor Create(AChart: TTMSFNCChart); virtual;
    destructor Destroy; override;
    function Chart: TTMSFNCChart;
  published
    property Visible: Boolean read FVisible write SetVisible default False;
    property Modes: TTMSFNCChartCrosshairModes read FModes write SetModes default [ccmContinuous];
    property ContinuousSeriesIndex: Integer read FContinuousSeriesIndex write SetContinuousSeriesIndex default 0;
  end;

  TTMSFNCChartLoadOptions = class;

  TTMSFNCChartSerieCrosshair = class(TPersistent)
  private
    FSerie: TTMSFNCChartSerie;
    FYPositions: TTMSFNCChartYAxisPositions;
    FXPositions: TTMSFNCChartXAxisPositions;
    FHorizontalLineStroke: TTMSFNCGraphicsStroke;
    FVerticalLineStroke: TTMSFNCGraphicsStroke;
    FXTextStroke: TTMSFNCGraphicsStroke;
    FXTextFill: TTMSFNCGraphicsFill;
    FXTextFont: TTMSFNCGraphicsFont;
    FYTextStroke: TTMSFNCGraphicsStroke;
    FYTextFill: TTMSFNCGraphicsFill;
    FYTextFont: TTMSFNCGraphicsFont;
    procedure SetYPositions(const Value: TTMSFNCChartYAxisPositions);
    procedure SetXPositions(const Value: TTMSFNCChartXAxisPositions);
    procedure SetHorizontalLineStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetVerticalLineStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetXTextStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetXTextFont(const Value: TTMSFNCGraphicsFont);
    procedure SetXTextFill(const Value: TTMSFNCGraphicsFill);
    procedure SetYTextStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetYTextFont(const Value: TTMSFNCGraphicsFont);
    procedure SetYTextFill(const Value: TTMSFNCGraphicsFill);
  protected
    procedure UpdateChart;
    procedure InvalidateChart;
    procedure StrokeChanged(Sender: TObject);
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
  public
    function Chart: TTMSFNCChart;
    function Serie: TTMSFNCChartSerie;
    constructor Create(ASerie: TTMSFNCChartSerie);
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property XTextStroke: TTMSFNCGraphicsStroke read FXTextStroke write SetXTextStroke;
    property XTextFill: TTMSFNCGraphicsFill read FXTextFill write SetXTextFill;
    property XTextFont: TTMSFNCGraphicsFont read FXTextFont write SetXTextFont;
    property YTextStroke: TTMSFNCGraphicsStroke read FYTextStroke write SetYTextStroke;
    property YTextFill: TTMSFNCGraphicsFill read FYTextFill write SetYTextFill;
    property YTextFont: TTMSFNCGraphicsFont read FYTextFont write SetYTextFont;
    property HorizontalLineStroke: TTMSFNCGraphicsStroke read FHorizontalLineStroke write SetHorizontalLineStroke;
    property VerticalLineStroke: TTMSFNCGraphicsStroke read FVerticalLineStroke write SetVerticalLineStroke;
    property YPositions: TTMSFNCChartYAxisPositions read FYPositions write SetYPositions default [ypLeft, ypCenter, ypRight];
    property XPositions: TTMSFNCChartXAxisPositions read FXPositions write SetXPositions default [xpTop, xpCenter, xpBottom];
  end;

  TTMSFNCChartSerie = class(TCollectionItem)
  private
    FSequential: Boolean;
    FPrevXValue: Double;
    FXAxisTopHeight, FXAxisCenterHeight, FXAxisBottomHeight: Double;
    FYAxisLeftWidth, FYAxisCenterWidth, FYAxisRightWidth: Double;
    FAnimateTimer: TTimer;
    FTag: Integer;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: Integer;
    FPoints: TTMSFNCChartPoints;
    FDrawSlices, FAnimateDrawSlices, FAnimateDrawSlicesReverse: TTMSFNCChartDrawSlices;
    FDrawLines, FAnimateDrawLines, FAnimateDrawLinesReverse: TTMSFNCChartDrawLines;
    FDrawRects, FAnimateDrawRects, FAnimateDrawRectsReverse: TTMSFNCChartDrawRects;
    FDrawPoints, FAnimateDrawPoints, FAnimateDrawPointsReverse: TTMSFNCChartDrawPoints;
    FDrawMultiPoints, FAnimateDrawMultiPoints, FAnimateDrawMultiPointsReverse: TTMSFNCChartDrawMultiPoints;
    FDrawXGridLines: TTMSFNCChartDrawXYGridLines;
    FDrawYGridLines: TTMSFNCChartDrawXYGridLines;
    FDrawXValues: TTMSFNCChartDrawXYValues;
    FDrawYValues: TTMSFNCChartDrawXYValues;
    FDrawLabels: TTMSFNCChartDrawLabels;
    FDrawAnnotations: TTMSFNCChartDrawAnnotations;
    FDrawPath: TTMSFNCGraphicsPath;
    FVisible: Boolean;
    FChart: TTMSFNCChart;
    FStartX, FEndX: Integer;
    FYMax: Double;
    FYMin: Double;
    FXMax: Double;
    FXMin: Double;
    FYScale: Double;
    FXScale: Double;
    FAutoXRange: TTMSFNCChartSerieAutoRange;
    FAutoYRange: TTMSFNCChartSerieAutoRange;
    FMinX: Double;
    FMinY: Double;
    FMaxX: Double;
    FMaxY: Double;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FChartType: TTMSFNCChartSerieType;
    FZeroReferenceValue: Double;
    FMode: TTMSFNCChartSerieMode;
    FBar: TTMSFNCChartSerieBar;
    FMultiPoint: TTMSFNCChartSerieMultiPoint;
    FAnimationFactor: Double;
    FAnimationFlow: Boolean;
    FLegendText: String;
    FShowInLegend: Boolean;
    FRevert: Boolean;
    FMarkers: TTMSFNCChartSerieMarkers;
    FYValues: TTMSFNCChartSerieYValues;
    FXGrid: TTMSFNCChartSerieXYGrid;
    FYGrid: TTMSFNCChartSerieXYGrid;
    FXValues: TTMSFNCChartSerieXValues;
    FMinXOffsetPercentage: Double;
    FMinYOffsetPercentage: Double;
    FMaxXOffsetPercentage: Double;
    FMaxYOffsetPercentage: Double;
    FLabels: TTMSFNCChartSerieLabels;
    FOffset3DX, FOffset3DY: Double;
    F3DXOffset, F3DYOffset: Double;
    FEnable3D: Boolean;
    FGroupIndex: Integer;
    FPie: TTMSFNCChartSeriePie;
    FLegend: TTMSFNCChartSerieLegend;
    FLogarithmicX: Boolean;
    FLogarithmicY: Boolean;
    FLogarithmicXBase: Integer;
    FLogarithmicYBase: Integer;
    FCrosshair: TTMSFNCChartSerieCrosshair;
    procedure SetCrosshair(const Value: TTMSFNCChartSerieCrosshair);
    procedure SetPoints(const Value: TTMSFNCChartPoints);
    procedure SetVisible(const Value: Boolean);
    procedure SetAutoXRange(const Value: TTMSFNCChartSerieAutoRange);
    procedure SetAutoYRange(const Value: TTMSFNCChartSerieAutoRange);
    procedure SetMaxX(const Value: Double);
    procedure SetMaxY(const Value: Double);
    procedure SetMinX(const Value: Double);
    procedure SetMinY(const Value: Double);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetChartType(const Value: TTMSFNCChartSerieType);
    procedure SetZeroReferenceValue(const Value: Double);
    procedure SetMode(const Value: TTMSFNCChartSerieMode);
    procedure SetBar(const Value: TTMSFNCChartSerieBar);
    procedure SetLegendText(const Value: String);
    procedure SetShowInLegend(const Value: Boolean);
    procedure SetMarkers(const Value: TTMSFNCChartSerieMarkers);
    procedure SetXGrid(const Value: TTMSFNCChartSerieXYGrid);
    procedure SetXValues(const Value: TTMSFNCChartSerieXValues);
    procedure SetYGrid(const Value: TTMSFNCChartSerieXYGrid);
    procedure SetYValues(const Value: TTMSFNCChartSerieYValues);
    procedure SetMaxXOffsetPercentage(const Value: Double);
    procedure SetMaxYOffsetPercentage(const Value: Double);
    procedure SetMinXOffsetPercentage(const Value: Double);
    procedure SetMinYOffsetPercentage(const Value: Double);
    procedure SetLabels(const Value: TTMSFNCChartSerieLabels);
    procedure SetOffset3DX(const Value: Double);
    procedure SetOffset3DY(const Value: Double);
    procedure SetEnable3D(const Value: Boolean);
    procedure SetGroupIndex(const Value: Integer);
    procedure SetPie(const Value: TTMSFNCChartSeriePie);
    procedure SetLegend(const Value: TTMSFNCChartSerieLegend);
    procedure SetMultiPoint(const Value: TTMSFNCChartSerieMultiPoint);
    procedure SetLogarithmicX(const Value: Boolean);
    procedure SetLogarithmicY(const Value: Boolean);
    procedure SetLogarithmicXBase(const Value: Integer);
    procedure SetLogarithmicYBase(const Value: Integer);
    function IsChartTypeStored: Boolean; virtual;
    function GetMaxX: Double;
    function GetMaxY: Double;
    function GetMinX: Double;
    function GetMinY: Double;
  protected
    function IsStacked: Boolean;
    function IsBar: Boolean;
    function IsArea: Boolean;
    function IsPie: Boolean;
    function IsSpider: Boolean;
    function Is3DEnabled: Boolean;
    function Get3DOffsetX: Double;
    function Get3DOffsetY: Double;
    function FindXAxisTextForValue(AValue: Double): String;
    function GetStartCountPoint: Integer;
    function GetEndCountPoint: Integer;
    function GetStartPoint: Integer;
    function GetEndPoint: Integer;
    function GetBarCount: Integer;
    function GetBarOffset: Double;
    function GetBarWidth: Double;
    function GetMultiPointWidth: Double;
    function GetTotalBarWidth: Double;
    function GetPieRect(ASerie: Boolean = False): TRectF;
    procedure CalculateMinMaxX;
    procedure CalculateMinMaxY;
    procedure CalculateDrawPoints;
    procedure CalculateAnimation;
    procedure CalculateArea;
    procedure CalculateLabels;
    procedure CalculateAnnotations;
    procedure UpdateChart;
    procedure InvalidateChart;
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure Draw(AGraphics: TTMSFNCGraphics; ADrawPoint: Integer = -1);
    procedure DrawMarkers(AGraphics: TTMSFNCGraphics);
    procedure DrawLabels(AGraphics: TTMSFNCGraphics);
    procedure DrawLegend(AGraphics: TTMSFNCGraphics);
    function GetLegendText(APoint: TTMSFNCChartPointVirtual): String;
    function GetSpiderLegendText(APoint: TTMSFNCChartPointVirtual): String;
    procedure DrawSpiderGrid(AGraphics: TTMSFNCGraphics);
    procedure DrawSpiderValues(AGraphics: TTMSFNCGraphics);
    procedure DrawAnnotations(AGraphics: TTMSFNCGraphics);
    procedure DrawArrow(AGraphics: TTMSFNCGraphics; ArrowColor: TTMSFNCGraphicsColor; ArrowSize: Double; ArrowOpacity: Double; origin, target : TPointF);
    procedure AnimateProcess(Sender: TObject);
    function CalculateOffset: Boolean;
    function Offset: Boolean;
    function GetPointsTotal: Double;
    function GetPointsMax: Double;
    function GetStackedDrawRect(ADrawIndex: Integer; var ADrawRect: TTMSFNCChartDrawRect): Boolean;
    function GetStackedDrawLine(ADrawIndex: Integer; var ADrawLine: TTMSFNCChartDrawLine): Boolean;
    function GetStackedMax(APointIndex: Integer): Double;
    procedure InternalLoadFromDataArray(YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil; YOpenValuesArray: TTMSFNCChartValuesArray = nil;
      YCloseValuesArray: TTMSFNCChartValuesArray = nil; YLowValuesArray: TTMSFNCChartValuesArray = nil;
      YMedianValuesArray: TTMSFNCChartValuesArray = nil); overload; virtual;
    procedure InternalAppendFromDataArray(YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil; YOpenValuesArray: TTMSFNCChartValuesArray = nil;
      YCloseValuesArray: TTMSFNCChartValuesArray = nil; YLowValuesArray: TTMSFNCChartValuesArray = nil;
      YMedianValuesArray: TTMSFNCChartValuesArray = nil); overload; virtual;
    procedure InternalLoadFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil; YOpenValuesArray: TTMSFNCChartValuesArray = nil;
      YCloseValuesArray: TTMSFNCChartValuesArray = nil; YLowValuesArray: TTMSFNCChartValuesArray = nil;
      YMedianValuesArray: TTMSFNCChartValuesArray = nil); overload; virtual;
    procedure InternalAppendFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil; YOpenValuesArray: TTMSFNCChartValuesArray = nil;
      YCloseValuesArray: TTMSFNCChartValuesArray = nil; YLowValuesArray: TTMSFNCChartValuesArray = nil;
      YMedianValuesArray: TTMSFNCChartValuesArray = nil); overload; virtual;
  public
    function XYToLegendItem(X, Y: Double): Integer;
    function XYToClosestDrawPoint(X, Y: Double; var ADrawPoint: TTMSFNCChartDrawPoint): Boolean;
    function XYToPointVirtual(X, Y: Double; var APoint: TTMSFNCChartPointVirtual): Boolean;
    function XYToBarVirtual(X, Y: Double; var APoint: TTMSFNCChartPointVirtual): Boolean;
    function XYToSliceVirtual(X, Y: Double; var APoint: TTMSFNCChartPointVirtual): Boolean;
    function XYToPoint(X, Y: Double): TTMSFNCChartPoint;
    function XYToBar(X, Y: Double): TTMSFNCChartPoint;
    function XYToSlice(X, Y: Double): TTMSFNCChartPoint;
    property Revert: Boolean read FRevert write FRevert;
    function DrawMultiPoints: TTMSFNCChartDrawMultiPoints;
    function DrawPoints: TTMSFNCChartDrawPoints;
    function DrawRects: TTMSFNCChartDrawRects;
    function DrawLines: TTMSFNCChartDrawLines;
    function DrawSlices: TTMSFNCChartDrawSlices;
    procedure Animate;
    function YToValue(AY: Double; APosition: Boolean = True): Double;
    function ValueToY(AValue: Double; APosition: Boolean = True): Double;
    function XToValue(AX: Double; APosition: Boolean = True): Double;
    function ValueToX(AValue: Double; APosition: Boolean = True): Double;
    function GetXOffset: Double;
    function Chart: TTMSFNCChart;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: Integer read FDataInteger write FDataInteger;
    function GetPointsCount: Integer;
    function GetPoint(AIndex: Integer): TTMSFNCChartPointVirtual;
    function XScale: Double;
    function YScale: Double;
    function YMax: Double;
    function YMin: Double;
    function XMax: Double;
    function XMin: Double;
    function AddPoint(AYValue: Double; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    function AddPoint(AYValue: Double; AXValueText: String; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    function AddSecondPoint(AYValue, AYValueSecond: Double; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    function AddSecondPoint(AYValue, AYValueSecond: Double; AXValueText: String; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    function AddVariablePoint(AYValue: Double; AYValueVariable: Double; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    function AddVariablePoint(AYValue: Double; AYValueVariable: Double; AXValueText: String; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    function AddXYPoint(AXValue, AYValue: Double; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    function AddXYPoint(AXValue, AYValue: Double; AXValueText: String; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    function AddMultiPoint(AYValueOpen, AYValueHigh, AYValueLow, AYValueClose: Double; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    function AddMultiPoint(AYValueOpen, AYValueHigh, AYValueLow, AYValueClose: Double; AXValueText: String; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    function AddMultiPoint(AYValueMinimum, AYValueQ1, AYValueMedian, AYValueQ3, AYValueMaximum: Double; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    function AddMultiPoint(AYValueMinimum, AYValueQ1, AYValueMedian, AYValueQ3, AYValueMaximum: Double; AXValueText: String; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint; overload;
    procedure LoadFromDataArray(YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil); overload; virtual;
    procedure LoadFromDataArrayEx(YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil); overload; virtual;
    procedure AppendFromDataArray(YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil); overload; virtual;
    procedure AppendFromDataArrayEx(YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil); overload; virtual;
    procedure LoadFromMultiPointDataArray(YHighValuesArray: TTMSFNCChartValuesArray; YLowValuesArray: TTMSFNCChartValuesArray; YOpenValuesArray: TTMSFNCChartValuesArray;
      YCloseValuesArray: TTMSFNCChartValuesArray; YMedianValuesArray: TTMSFNCChartValuesArray = nil; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil); overload; virtual;
    procedure AppendFromMultiPointDataArray(YHighValuesArray: TTMSFNCChartValuesArray; YLowValuesArray: TTMSFNCChartValuesArray; YOpenValuesArray: TTMSFNCChartValuesArray;
      YCloseValuesArray: TTMSFNCChartValuesArray; YMedianValuesArray: TTMSFNCChartValuesArray = nil; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil); overload; virtual;
      procedure LoadFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil); overload; virtual;
    procedure LoadFromDataArrayEx(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil); overload; virtual;
    procedure AppendFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil); overload; virtual;
    procedure AppendFromDataArrayEx(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil); overload; virtual;
    procedure LoadFromMultiPointDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YHighValuesArray: TTMSFNCChartValuesArray; YLowValuesArray: TTMSFNCChartValuesArray; YOpenValuesArray: TTMSFNCChartValuesArray;
      YCloseValuesArray: TTMSFNCChartValuesArray; YMedianValuesArray: TTMSFNCChartValuesArray = nil; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil); overload; virtual;
    procedure AppendFromMultiPointDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YHighValuesArray: TTMSFNCChartValuesArray; YLowValuesArray: TTMSFNCChartValuesArray; YOpenValuesArray: TTMSFNCChartValuesArray;
      YCloseValuesArray: TTMSFNCChartValuesArray; YMedianValuesArray: TTMSFNCChartValuesArray = nil; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil); overload; virtual;
  published
    property AnimationFactor: Double read FAnimationFactor write FAnimationFactor;
    property AnimationFlow: Boolean read FAnimationFlow write FAnimationFlow default True;
    property AutoXRange: TTMSFNCChartSerieAutoRange read FAutoXRange write SetAutoXRange default arDisabled;
    property AutoYRange: TTMSFNCChartSerieAutoRange read FAutoYRange write SetAutoYRange default arEnabled;
    property Bar: TTMSFNCChartSerieBar read FBar write SetBar;
    property MultiPoint: TTMSFNCChartSerieMultiPoint read FMultiPoint write SetMultiPoint;
    property Pie: TTMSFNCChartSeriePie read FPie write SetPie;
    property ChartType: TTMSFNCChartSerieType read FChartType write SetChartType stored IsChartTypeStored nodefault ;
    property Enable3D: Boolean read FEnable3D write SetEnable3D default False;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property GroupIndex: Integer read FGroupIndex write SetGroupIndex default 0;
    property LegendText: String read FLegendText write SetLegendText;
    property Labels: TTMSFNCChartSerieLabels read FLabels write SetLabels;
    property Markers: TTMSFNCChartSerieMarkers read FMarkers write SetMarkers;
    property MaxX: Double read GetMaxX write SetMaxX;
    property MaxY: Double read GetMaxY write SetMaxY;
    property MaxXOffsetPercentage: Double read FMaxXOffsetPercentage write SetMaxXOffsetPercentage;
    property MaxYOffsetPercentage: Double read FMaxYOffsetPercentage write SetMaxYOffsetPercentage;
    property MinX: Double read GetMinX write SetMinX;
    property MinY: Double read GetMinY write SetMinY;
    property MinXOffsetPercentage: Double read FMinXOffsetPercentage write SetMinXOffsetPercentage;
    property MinYOffsetPercentage: Double read FMinYOffsetPercentage write SetMinYOffsetPercentage;
    property Mode: TTMSFNCChartSerieMode read FMode write SetMode default smMathematical;
    property Offset3DX: Double read FOffset3DX write SetOffset3DX;
    property Offset3DY: Double read FOffset3DY write SetOffset3DY;
    property Points: TTMSFNCChartPoints read FPoints write SetPoints;
    property Legend: TTMSFNCChartSerieLegend read FLegend write SetLegend;
    property ShowInLegend: Boolean read FShowInLegend write SetShowInLegend default True;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Tag: Integer read FTag write FTag default 0;
    property Visible: Boolean read FVisible write SetVisible default True;
    property XGrid: TTMSFNCChartSerieXYGrid read FXGrid write SetXGrid;
    property XValues: TTMSFNCChartSerieXValues read FXValues write SetXValues;
    property YGrid: TTMSFNCChartSerieXYGrid read FYGrid write SetYGrid;
    property YValues: TTMSFNCChartSerieYValues read FYValues write SetYValues;
    property ZeroReferenceValue: Double read FZeroReferenceValue write SetZeroReferenceValue;
    property LogarithmicX: Boolean read FLogarithmicX write SetLogarithmicX default False;
    property LogarithmicXBase: Integer read FLogarithmicXBase write SetLogarithmicXBase default 10;
    property LogarithmicY: Boolean read FLogarithmicY write SetLogarithmicY default False;
    property LogarithmicYBase: Integer read FLogarithmicYBase write SetLogarithmicYBase default 10;
    property Crosshair: TTMSFNCChartSerieCrosshair read FCrosshair write SetCrosshair;
  end;

  TTMSFNCBarChartSerie = class(TTMSFNCChartSerie)
  end;

  TTMSFNCChartSeries = class(TOwnedCollection)
  private
    FChart: TTMSFNCChart;
    function GetItem(Index: Integer): TTMSFNCChartSerie;
    procedure SetItem(Index: Integer; const Value: TTMSFNCChartSerie);
  protected
    function GetBarStop: Integer;
    procedure Draw(AGraphics: TTMSFNCGraphics);
  public
    procedure AssignSource(Source: TPersistent); virtual;
    function Chart: TTMSFNCChart;
    constructor Create(AChart: TTMSFNCChart);
    function Add: TTMSFNCChartSerie;
    function Insert(Index: Integer): TTMSFNCChartSerie;
    property Items[Index: Integer]: TTMSFNCChartSerie read GetItem write SetItem; default;
  end;

  TTMSFNCChartBeforeDrawSerieLabel = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawLabel: TTMSFNCChartDrawLabel; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieLabel = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawLabel: TTMSFNCChartDrawLabel) of object;
  TTMSFNCChartBeforeDrawSerieGridLegendText = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawGridLegendText: TTMSFNCChartDrawLabel; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieGridLegendText = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawGridLegendText: TTMSFNCChartDrawLabel) of object;
  TTMSFNCChartBeforeDrawSerieAnnotation = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawAnnotation: TTMSFNCChartDrawAnnotation; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieAnnotation = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawAnnotation: TTMSFNCChartDrawAnnotation) of object;
  TTMSFNCChartGetSerieLabelVirtual = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; var AValueString: String) of object;
  TTMSFNCChartGetSerieLegendTextVirtual = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; var ALegendText: String) of object;
  TTMSFNCChartGetSerieLabel = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; var AValueString: String) of object;
  TTMSFNCChartGetSerieLegendText = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; var ALegendText: String) of object;
  TTMSFNCChartBeforeDrawChart = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawChart = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;
  TTMSFNCChartBeforeDrawBackground = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawBackground = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;
  TTMSFNCChartBeforeDrawTitle = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartTitlePosition; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartBeforeDrawXValuesTitle = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartXAxisPosition; ASerie: TTMSFNCChartSerie; ATextRect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawXValuesTitle = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartXAxisPosition; ASerie: TTMSFNCChartSerie; ATextRect: TRectF) of object;
  TTMSFNCChartBeforeDrawYValuesTitle = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartYAxisPosition; ASerie: TTMSFNCChartSerie; ATextRect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawYValuesTitle = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartYAxisPosition; ASerie: TTMSFNCChartSerie; ATextRect: TRectF) of object;
  TTMSFNCChartDrawTitleText = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartTitlePosition; var AText: String; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartGetSerie3DOffset = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; var A3DOffset: Double) of object;
  TTMSFNCChartAfterDrawTitle = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartTitlePosition) of object;
  TTMSFNCChartBeforeDrawLegend = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawLegend = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;
  TTMSFNCChartBeforeDrawLegendIcon = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawLegendIcon = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF) of object;
  TTMSFNCChartBeforeDrawSerieYAxisCrosshairText = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartYAxisPosition; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieYAxisCrosshairText = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartYAxisPosition; AMode: TTMSFNCChartCrosshairMode) of object;
  TTMSFNCChartBeforeDrawSerieXAxisCrosshairText = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartXAxisPosition; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieXAxisCrosshairText = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartXAxisPosition; AMode: TTMSFNCChartCrosshairMode) of object;
  TTMSFNCChartBeforeDrawSerieCrosshairHorizontalLine = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieCrosshairHorizontalLine = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode) of object;
  TTMSFNCChartBeforeDrawSerieCrosshairVerticalLine = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieCrosshairVerticalLine = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode) of object;
  TTMSFNCChartGetSerieXAxisCrosshairText = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; AValue: Double; APosition: TTMSFNCChartXAxisPosition; AMode: TTMSFNCChartCrosshairMode; var AText: String) of object;
  TTMSFNCChartGetSerieYAxisCrosshairText = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; AValue: Double; APosition: TTMSFNCChartYAxisPosition; AMode: TTMSFNCChartCrosshairMode; var AText: String) of object;

  TTMSFNCChartBeforeDrawSerieLegend = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieLegend = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF) of object;
  TTMSFNCChartBeforeDrawSerieLegendIconVirtual = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieLegendIconVirtual = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; ARect: TRectF) of object;
  TTMSFNCChartBeforeDrawSerieLegendIcon = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieLegendIcon = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; ARect: TRectF) of object;
  TTMSFNCChartBeforeDrawXAxis = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartXAxisPosition; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawXAxis = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartXAxisPosition) of object;
  TTMSFNCChartBeforeDrawSeries = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSeries = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;
  TTMSFNCChartAnimateSerieStarted = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie) of object;
  TTMSFNCChartAnimateSerieFinished = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie) of object;
  TTMSFNCChartBeforeDrawSerieXValue = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartXAxisPosition; var ADrawValue: TTMSFNCChartDrawXYValue; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieXValue = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartXAxisPosition; ADrawValue: TTMSFNCChartDrawXYValue) of object;
  TTMSFNCChartBeforeDrawSerieYValue = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartYAxisPosition; var ADrawValue: TTMSFNCChartDrawXYValue; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieYValue = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartYAxisPosition; ADrawValue: TTMSFNCChartDrawXYValue) of object;
  TTMSFNCChartBeforeDrawSerieXGridLine = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawGridLine: TTMSFNCChartDrawXYGridLine; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieXGridLine = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawGridLine: TTMSFNCChartDrawXYGridLine) of object;
  TTMSFNCChartBeforeDrawSerieYGridLine = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawGridLine: TTMSFNCChartDrawXYGridLine; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieYGridLine = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawGridLine: TTMSFNCChartDrawXYGridLine) of object;
  TTMSFNCChartGetSerieValue = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; AIndex: Integer; AKind: TTMSFNCChartDrawXYValueKind; AValue: Double; var AValueString: String) of object;
  TTMSFNCChartBeforeDrawYAxis = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartYAxisPosition; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawYAxis = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartYAxisPosition) of object;
  TTMSFNCChartBeforeDrawSerieMarker = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawPoint: TTMSFNCChartDrawPoint; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieMarker = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawPoint: TTMSFNCChartDrawPoint) of object;
  TTMSFNCChartBeforeDrawSerieBar = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawRect: TTMSFNCChartDrawRect; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieBar = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawRect: TTMSFNCChartDrawRect) of object;
  TTMSFNCChartBeforeDrawSerieLine = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawLine: TTMSFNCChartDrawLine; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartBeforeDrawSerieMultiPoint = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawMultiPoint: TTMSFNCChartDrawMultiPoint; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartBeforeDrawSerieSlice = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawSlice: TTMSFNCChartDrawSlice; var ADefaultDraw: Boolean) of object;
  TTMSFNCChartAfterDrawSerieLine = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawLine: TTMSFNCChartDrawLine) of object;
  TTMSFNCChartAfterDrawSerieMultiPoint = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawMultiPoint: TTMSFNCChartDrawMultiPoint) of object;
  TTMSFNCChartAfterDrawSerieSlice = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawSlice: TTMSFNCChartDrawSlice) of object;
  TTMSFNCChartSerieClick = procedure(Sender: TObject; APoint: TTMSFNCChartPoint) of object;
  TTMSFNCChartSerieClickVirtual = procedure(Sender: TObject; APoint: TTMSFNCChartPointVirtual) of object;
  TTMSFNCChartGetNumberOfPoints = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; var ANumberOfPoints: Integer) of object;
  TTMSFNCChartGetPoint = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; AIndex: Integer; var APoint: TTMSFNCChartPointVirtual) of object;
  TTMSFNCChartSerieLegendItemClick = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; AIndex: Integer) of object;
  TTMSFNCChartLegendItemClick = procedure(Sender: TObject; AIndex: Integer) of object;

  TTMSFNCChartCustomizeAnnotationFont = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; AAnnotation: TTMSFNCChartAnnotationVirtual; AFont: TTMSFNCGraphicsFont) of object;
  TTMSFNCChartCustomizeAnnotationStroke = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; AAnnotation: TTMSFNCChartAnnotationVirtual; AStroke: TTMSFNCGraphicsStroke) of object;
  TTMSFNCChartCustomizeAnnotationFill = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; AAnnotation: TTMSFNCChartAnnotationVirtual; AFill: TTMSFNCGraphicsFill) of object;
  TTMSFNCChartGetNumberOfAnnotations = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; var ANumberOfAnnotations: Integer) of object;
  TTMSFNCChartGetAnnotation = procedure(Sender: TObject; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; AIndex: Integer; var AAnnotation: TTMSFNCChartAnnotationVirtual) of object;

  TTMSFNCChartInteractionScaleMode = (smNone, smHorizontal, smVertical);

  TTMSFNCChartInteractionOptions = class(TPersistent)
  private
    FChart: TTMSFNCChart;
    FScaleMode: TTMSFNCChartInteractionScaleMode;
    FPanning: Boolean;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(AChart: TTMSFNCChart);
    destructor Destroy; override;
    function Chart: TTMSFNCChart;
  published
    property ScaleMode: TTMSFNCChartInteractionScaleMode read FScaleMode write FScaleMode default smHorizontal;
    property Panning: Boolean read FPanning write FPanning default True;
  end;

  TTMSFNCChartPieQuadrant = (cpq1, cpq2, cpq3, cpq4);
  TTMSFNCChartSeriesDrawOrder = (csdoNormal, csdoBarsFirst, csdoBarsLast);

  TTMSFNCChartAdapter = class(TTMSFNCCustomComponent)
  private
    FChart: TTMSFNCChart;
    FActive: boolean;
    FAutoCreateSeries: Boolean;
    procedure SetActive(const Value: boolean);
    procedure SetAutoCreateSeries(const Value: Boolean);
  protected
    function GetInstance: NativeUInt; override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
  public
    property Chart: TTMSFNCChart read FChart write FChart;
    procedure LoadItems; virtual;
    procedure GetItems; virtual; abstract;
    procedure UpdateItems; virtual;
    constructor Create(AOwner: TComponent); override;
  published
    property Active: Boolean read FActive write SetActive default False;
    property AutoCreateSeries: Boolean read FAutoCreateSeries write SetAutoCreateSeries default True;
  end;

  TTMSFNCChartAppearance = class;

  TTMSFNCChartColorSelection = class(TCollectionItem)
  private
    FTag: Integer;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: Integer;
    FChart: TTMSFNCChart;
    FColor: TTMSFNCGraphicsColor;
    FOnColorChanged: TNotifyEvent;
  protected
    procedure DoColorChanged;
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
  public
    function Chart: TTMSFNCChart;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: Integer read FDataInteger write FDataInteger;
  published
    property Tag: Integer read FTag write FTag default 0;
    property Color: TTMSFNCGraphicsColor read FColor write SetColor default gcWhite;
    property OnColorChanged: TNotifyEvent read FOnColorChanged write FOnColorChanged;
  end;

  TTMSFNCChartColorSelections = class(TOwnedCollection)
  private
    FChart: TTMSFNCChart;
    function GetItem(Index: Integer): TTMSFNCChartColorSelection;
    procedure SetItem(Index: Integer; const Value: TTMSFNCChartColorSelection);
  public
    function Chart: TTMSFNCChart;
    constructor Create(AChartAppearance: TTMSFNCChartAppearance);
    function Add: TTMSFNCChartColorSelection;
    function AddColor(AColor: TTMSFNCGraphicsColor): TTMSFNCChartColorSelection;
    function Insert(Index: Integer): TTMSFNCChartColorSelection;
    property Items[Index: Integer]: TTMSFNCChartColorSelection read GetItem write SetItem; default;
  end;

  TTMSFNCChartAppearanceFontType = (aftNone, aftColor, aftSize, aftName, aftScale, aftStyle);

  TTMSFNCChartBeforeSetAllFontsEvent = procedure(Sender: TObject; ASetType: TTMSFNCChartAppearanceFontType; var ASetChartFonts: Boolean; var ASetSeriesFonts: Boolean; var ASetAnnotationFonts: Boolean) of object;

  TTMSFNCChartAppearanceFont = class(TPersistent)
  private
    FOwner: TTMSFNCChart;
    FColor: TTMSFNCGraphicsColor;
    FSize: Single;
    FName: String;
    FScale: Double;
    FOldScale: Double;
    FStyle: TFontStyles;
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSize(const Value: Single);
    procedure SetName(const Value: String);
    procedure SetScale(const Value: Double);
    procedure SetStyle(const Value: TFontStyles);
  protected
    procedure ApplyChange(AFont: TTMSFNCGraphicsFont; ASetType: TTMSFNCChartAppearanceFontType);
    procedure SetFonts(ASetType: TTMSFNCChartAppearanceFontType);
  public
    constructor Create(AOwner: TTMSFNCChart);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property Color: TTMSFNCGraphicsColor read FColor write SetColor default gcBlack;
    property Size: Single read FSize write SetSize;
    property Name: String read FName write SetName;
    property Scale: Double read FScale write SetScale;
    property Style: TFontStyles read FStyle write SetStyle;
  end;

  TTMSFNCChartColorScheme = (ccsColorList, ccsExcel, ccsMonochromatic);

  TTMSFNCChartAppearance = class(TPersistent)
  private
    FOwner: TTMSFNCChart;
    FColorList: TTMSFNCChartColorSelections;
    FGlobalFont: TTMSFNCChartAppearanceFont;
    FColorScheme: TTMSFNCChartColorScheme;
    FMonochromeColor: TTMSFNCGraphicsColor;
    FOnChange: TNotifyEvent;
    procedure SetColorList(const Value: TTMSFNCChartColorSelections);
    procedure SetGlobalFont(const Value: TTMSFNCChartAppearanceFont);
    procedure SetColorScheme(const Value: TTMSFNCChartColorScheme);
    procedure SetMonochromeColor(const Value: TTMSFNCGraphicsColor);
  protected
    procedure Changed;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCChart);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    function GetColorSchemeName(AColorScheme: TTMSFNCChartColorScheme): string;
  published
    property ColorList: TTMSFNCChartColorSelections read FColorList write SetColorList;
    property GlobalFont: TTMSFNCChartAppearanceFont read FGlobalFont write SetGlobalFont;
    property ColorScheme: TTMSFNCChartColorScheme read FColorScheme write SetColorScheme;
    property MonochromeColor: TTMSFNCGraphicsColor read FMonochromeColor write SetMonochromeColor;
  end;

  TTMSFNCChartFirstLine = (cflFirstLineValue, cflFirstLineSkip, cflFirstLineLabel);

  TTMSFNCChartLoadOptions = class(TPersistent)
  private
    FClearSeries: Boolean;
    FXValuesFormatType: TTMSFNCChartSerieValueFormatType;
    FYValuesFormatType: TTMSFNCChartSerieValueFormatType;
    FXValuesFormatString: string;
    FXRange: TTMSFNCChartSerieAutoRange;
    FYValuesFormatString: string;
    FYRange: TTMSFNCChartSerieAutoRange;
    FOnChange: TNotifyEvent;
    FDelimiter: Char;
    FCSVFirstLine: TTMSFNCChartFirstLine;
    FXGrid: Boolean;
    FYGrid: Boolean;
    FMaxYOffsetPercentage: Double;
    procedure SetXValuesFormatType(const Value : TTMSFNCChartSerieValueFormatType);
    procedure SetYValuesFormatType(const Value : TTMSFNCChartSerieValueFormatType);
  protected
    procedure Changed;
//    function GetOwner: TPersistent; override;
  public
    constructor Create;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property ClearSeries: Boolean read FClearSeries write FCLearSeries default True;
    property XRange: TTMSFNCChartSerieAutoRange read FXRange write FXRange default arCommon;
    property YRange: TTMSFNCChartSerieAutoRange read FYRange write FYRange default arCommonZeroBased;
    property XValuesFormatType: TTMSFNCChartSerieValueFormatType read FXValuesFormatType write SetXValuesFormatType default vftNormal;
    property XValuesFormatString: string read FXValuesFormatString write FXValuesFormatString;
    property YValuesFormatType: TTMSFNCChartSerieValueFormatType read FYValuesFormatType write SetYValuesFormatType default vftNormal;
    property YValuesFormatString: string read FYValuesFormatString write FYValuesFormatString;
    property CSVDelimiter: Char read FDelimiter write FDelimiter default #0;
    property CSVFirstLine: TTMSFNCChartFirstLine read FCSVFirstLine write FCSVFirstLine default cflFirstLineSkip;
    property XGrid: Boolean read FXGrid write FXGrid default False;
    property YGrid: Boolean read FYGrid write FYGrid default True;
    property MaxYOffsetPercentage: Double read FMaxYOffsetPercentage write FMaxYOffsetPercentage;
  end;

  TTMSFNCChartParseStringToDateTimeEvent = procedure (ASender: TObject; ADateTimeString: string; var ADate: TDateTime) of object;
  TTMSFNCChartAddJSONSerieEvent = procedure(ASender: TObject; ASerie: TTMSFNCChartSerie; ASerieJSONValue: TJSONValue) of object;
  TTMSFNCChartAddJSONPointEvent = procedure(ASender: TObject; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; APointJSONValue: TJSONValue) of object;

  TTMSFNCChartAddJSONSerieCallBackEvent = {$IFNDEF LCLWEBLIB}reference to {$ENDIF}procedure(ASerie: TTMSFNCChartSerie; ASerieJSONValue: TJSONValue){$IFDEF LCLWEBLIB} of object{$ENDIF};
  TTMSFNCChartAddJSONPointCallBackEvent = {$IFNDEF LCLWEBLIB}reference to {$ENDIF}procedure(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; APointJSONValue: TJSONValue){$IFDEF LCLWEBLIB} of object{$ENDIF};

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  TTMSFNCChart = class(TCustomControl, ITMSFNCGraphicsExport, ITMSFNCPersistence)
  {$ENDIF}
  {$IFDEF FMXLIB}
  TTMSFNCChart = class(TControl, ITMSFNCGraphicsExport, ITMSFNCPersistence)
  {$ENDIF}
  private
    FAppearancePersisting: Boolean;
    FExport: Boolean;
    FExportRect: TRectF;
    FVirtualMode: Boolean;
    FMouseDown, FMouseMoved: Boolean;
    FDownPt, FMovePt: TPointF;
    FCrosshairPt: TPointF;
    {$IFDEF FMXLIB}
    FTouchDistance: Integer;
    {$IFDEF MSWINDOWS}
    FTouchPoint: TPointF;
    {$ENDIF}
    {$ENDIF}
    FTotalOffset3DX: Double;
    FTotalOffset3DY: Double;
    FUpdateCount: Integer;
    FSeries: TTMSFNCChartSeries;
    FTitle: TTMSFNCChartTitle;
    FOnBeforeDrawTitle: TTMSFNCChartBeforeDrawTitle;
    FOnAfterDrawTitle: TTMSFNCChartAfterDrawTitle;
    FOnDrawTitleText: TTMSFNCChartDrawTitleText;
    FXAxis: TTMSFNCChartXAxis;
    FOnBeforeDrawXAxis: TTMSFNCChartBeforeDrawXAxis;
    FOnAfterDrawXAxis: TTMSFNCChartAfterDrawXAxis;
    FOnBeforeDrawYAxis: TTMSFNCChartBeforeDrawYAxis;
    FOnAfterDrawYAxis: TTMSFNCChartAfterDrawYAxis;
    FYAxis: TTMSFNCChartYAxis;
    FSeriesMargins: TTMSFNCChartMargins;
    FLegend: TTMSFNCChartLegend;
    FFill: TTMSFNCGraphicsFill;
    FCrosshair: TTMSFNCChartCrosshair;
    FStroke: TTMSFNCGraphicsStroke;
    FOnBeforeDrawSeries: TTMSFNCChartBeforeDrawSeries;
    FOnAfterDrawSeries: TTMSFNCChartAfterDrawSeries;
    FOnAnimateSerieFinished: TTMSFNCChartAnimateSerieFinished;
    FOnAnimateSerieStarted: TTMSFNCChartAnimateSerieStarted;
    FOnBeforeDrawSerieXValue: TTMSFNCChartBeforeDrawSerieXValue;
    FOnBeforeDrawSerieYValue: TTMSFNCChartBeforeDrawSerieYValue;
    FOnAfterDrawSerieXValue: TTMSFNCChartAfterDrawSerieXValue;
    FOnAfterDrawSerieYValue: TTMSFNCChartAfterDrawSerieYValue;
    FOnGetSerieXValue: TTMSFNCChartGetSerieValue;
    FOnGetSerieYValue: TTMSFNCChartGetSerieValue;
    FOnBeforeDrawSerieMarker: TTMSFNCChartBeforeDrawSerieMarker;
    FOnAfterDrawSerieMarker: TTMSFNCChartAfterDrawSerieMarker;
    FOnBeforeDrawSerieXGridLine: TTMSFNCChartBeforeDrawSerieXGridLine;
    FOnBeforeDrawSerieYGridLine: TTMSFNCChartBeforeDrawSerieYGridLine;
    FOnAfterDrawSerieXGridLine: TTMSFNCChartAfterDrawSerieXGridLine;
    FOnAfterDrawSerieYGridLine: TTMSFNCChartAfterDrawSerieYGridLine;
    FOnAfterDrawLegend: TTMSFNCChartAfterDrawLegend;
    FOnBeforeDrawLegend: TTMSFNCChartBeforeDrawLegend;
    FOnBeforeDrawBackground: TTMSFNCChartBeforeDrawBackground;
    FOnAfterDrawBackground: TTMSFNCChartAfterDrawBackground;
    FOnBeforeDrawChart: TTMSFNCChartBeforeDrawChart;
    FOnAfterDrawChart: TTMSFNCChartAfterDrawChart;
    FOnBeforeDrawXValuesTitle: TTMSFNCChartBeforeDrawXValuesTitle;
    FOnBeforeDrawYValuesTitle: TTMSFNCChartBeforeDrawYValuesTitle;
    FOnAfterDrawXValuesTitle: TTMSFNCChartAfterDrawXValuesTitle;
    FOnAfterDrawYValuesTitle: TTMSFNCChartAfterDrawYValuesTitle;
    FOnAfterDrawSerieLabel: TTMSFNCChartAfterDrawSerieLabel;
    FOnGetSerieLabel: TTMSFNCChartGetSerieLabel;
    FOnBeforeDrawSerieLabel: TTMSFNCChartBeforeDrawSerieLabel;
    FOnAfterDrawSerieLine: TTMSFNCChartAfterDrawSerieLine;
    FOnBeforeDrawSerieBar: TTMSFNCChartBeforeDrawSerieBar;
    FOnAfterDrawSerieBar: TTMSFNCChartAfterDrawSerieBar;
    FOnBeforeDrawSerieLine: TTMSFNCChartBeforeDrawSerieLine;
    FInteraction: Boolean;
    FOnSeriePointClick: TTMSFNCChartSerieClick;
    FOnSerieBarClick: TTMSFNCChartSerieClick;
    FClickMargin: Double;
    FOnBeforeDrawSerieAnnotation: TTMSFNCChartBeforeDrawSerieAnnotation;
    FOnAfterDrawSerieAnnotation: TTMSFNCChartAfterDrawSerieAnnotation;
    FOnAfterDrawSerieSlice: TTMSFNCChartAfterDrawSerieSlice;
    FOnBeforeDrawSerieSlice: TTMSFNCChartBeforeDrawSerieSlice;
    FOnSerieSliceClick: TTMSFNCChartSerieClick;
    FOnBeforeDrawSerieLegend: TTMSFNCChartBeforeDrawSerieLegend;
    FOnAfterDrawSerieLegend: TTMSFNCChartAfterDrawSerieLegend;
    FOnGetSerieLegendText: TTMSFNCChartGetSerieLegendText;
    FOnAfterDrawSerieLegendIcon: TTMSFNCChartAfterDrawSerieLegendIcon;
    FOnBeforeDrawSerieLegendIcon: TTMSFNCChartBeforeDrawSerieLegendIcon;
    FOnBeforeDrawLegendIcon: TTMSFNCChartBeforeDrawLegendIcon;
    FOnAfterDrawLegendIcon: TTMSFNCChartAfterDrawLegendIcon;
    FInteractionOptions: TTMSFNCChartInteractionOptions;
    FOnBeforeDrawSerieGridLegendText: TTMSFNCChartBeforeDrawSerieGridLegendText;
    FOnAfterDrawSerieGridLegendText: TTMSFNCChartAfterDrawSerieGridLegendText;
    FOnGetSerieSpiderLegendText: TTMSFNCChartGetSerieLegendText;
    FOnGetNumberOfPoints: TTMSFNCChartGetNumberOfPoints;
    FOnGetPoint: TTMSFNCChartGetPoint;
    FOnGetNumberOfAnnotations: TTMSFNCChartGetNumberOfAnnotations;
    FOnGetAnnotation: TTMSFNCChartGetAnnotation;
    FOnCustomizeAnnotationFill: TTMSFNCChartCustomizeAnnotationFill;
    FOnCustomizeAnnotationStroke: TTMSFNCChartCustomizeAnnotationStroke;
    FOnCustomizeAnnotationFont: TTMSFNCChartCustomizeAnnotationFont;
    FOnSeriePointClickVirtual: TTMSFNCChartSerieClickVirtual;
    FOnSerieBarClickVirtual: TTMSFNCChartSerieClickVirtual;
    FOnSerieSliceClickVirtual: TTMSFNCChartSerieClickVirtual;
    FOnGetSerieSpiderLegendTextVirtual: TTMSFNCChartGetSerieLegendTextVirtual;
    FOnGetSerieLabelVirtual: TTMSFNCChartGetSerieLabelVirtual;
    FOnGetSerieLegendTextVirtual: TTMSFNCChartGetSerieLegendTextVirtual;
    FOnAfterDrawSerieLegendIconVirtual: TTMSFNCChartAfterDrawSerieLegendIconVirtual;
    FOnBeforeDrawSerieLegendIconVirtual: TTMSFNCChartBeforeDrawSerieLegendIconVirtual;
    FNativeCanvas: Boolean;
    FAntiAliasing: Boolean;
    FOnBeforeDrawSerieMultiPoint: TTMSFNCChartBeforeDrawSerieMultiPoint;
    FOnAfterDrawSerieMultiPoint: TTMSFNCChartAfterDrawSerieMultiPoint;
    FTextQuality: TTMSFNCGraphicsTextQuality;
    FOnCanSaveProperty: TTMSFNCCustomControlCanSavePropertyEvent;
    FOnCanLoadProperty: TTMSFNCCustomControlCanLoadPropertyEvent;
    FOnLegendItemClick: TTMSFNCChartLegendItemClick;
    FOnSerieLegendItemClick: TTMSFNCChartSerieLegendItemClick;
    FDrawOrder: TTMSFNCChartSeriesDrawOrder;
    FAppearance: TTMSFNCChartAppearance;
    FOnBeforeSetAllFonts: TTMSFNCChartBeforeSetAllFontsEvent;
    FOnJSONAddSeries: TTMSFNCChartAddJSONSerieEvent;
    FOnJSONAddPoint: TTMSFNCChartAddJSONPointEvent;
    FOnParseDateTimeStringToDateTime: TTMSFNCChartParseStringToDateTimeEvent;
    FAdapter: TTMSFNCChartAdapter;
    FDefaultLoadOptions: TTMSFNCChartLoadOptions;
    FOnBeforeDrawSerieYAxisCrosshairText: TTMSFNCChartBeforeDrawSerieYAxisCrosshairText;
    FOnAfterDrawSerieYAxisCrosshairText: TTMSFNCChartAfterDrawSerieYAxisCrosshairText;
    FOnBeforeDrawSerieXAxisCrosshairText: TTMSFNCChartBeforeDrawSerieXAxisCrosshairText;
    FOnAfterDrawSerieXAxisCrosshairText: TTMSFNCChartAfterDrawSerieXAxisCrosshairText;
    FOnBeforeDrawSerieCrosshairHorizontalLine: TTMSFNCChartBeforeDrawSerieCrosshairHorizontalLine;
    FOnAfterDrawSerieCrosshairHorizontalLine: TTMSFNCChartAfterDrawSerieCrosshairHorizontalLine;
    FOnBeforeDrawSerieCrosshairVerticalLine: TTMSFNCChartBeforeDrawSerieCrosshairVerticalLine;
    FOnAfterDrawSerieCrosshairVerticalLine: TTMSFNCChartAfterDrawSerieCrosshairVerticalLine;
    FOnGetSerieXAxisCrosshairText: TTMSFNCChartGetSerieXAxisCrosshairText;
    FOnGetSerieYAxisCrosshairText: TTMSFNCChartGetSerieYAxisCrosshairText;

    procedure SetSeries(const Value: TTMSFNCChartSeries);
    function GetVersion: String;
    function GetVersionNr: Integer;
    procedure SetTitle(const Value: TTMSFNCChartTitle);
    procedure SetXAxis(const Value: TTMSFNCChartXAxis);
    procedure SetYAxis(const Value: TTMSFNCChartYAxis);
    procedure SetSeriesMargins(const Value: TTMSFNCChartMargins);
    procedure SetLegend(const Value: TTMSFNCChartLegend);
    procedure SetCrosshair(const Value: TTMSFNCChartCrosshair);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    function GetSerieByName(AName: string): TTMSFNCChartSerie;
    procedure SetInteractionOptions(const Value: TTMSFNCChartInteractionOptions);
    procedure SetAntiAliasing(const Value: Boolean);
    procedure SetNativeCanvas(const Value: Boolean);
    procedure SetTextQuality(const Value: TTMSFNCGraphicsTextQuality);
    procedure DoCanLoadProperty(AObject: TObject; APropertyName: string;
      APropertyType: TTypeKind; var ACanLoad: Boolean);
    procedure DoCanSaveProperty(AObject: TObject; APropertyName: string;
      APropertyType: TTypeKind; var ACanSave: Boolean);
    procedure SetDrawOrder(const Value: TTMSFNCChartSeriesDrawOrder);
    procedure SetAppearance(const Value: TTMSFNCChartAppearance);
    procedure UpdateSeriesColors;
    function GetColorForIndex(AIndex: Integer): TTMSFNCGraphicsColor;
    procedure SetAdapter(const Value: TTMSFNCChartAdapter);
    procedure SetDefaultLoadOptions(const Value: TTMSFNCChartLoadOptions);
  protected
    {$IFDEF VCLLIB}
    {$HINTS OFF}
    {$IF COMPILERVERSION > 30}
    procedure ChangeScale(M, D: Integer; isDpiChange: Boolean); override;
    {$ELSE}
    procedure ChangeScale(M, D: Integer); override;
    {$IFEND}
    {$HINTS ON}
    {$ENDIF}
    procedure RegisterRuntimeClasses; virtual;
    procedure Loaded; override;
    procedure HandleMouseLeave; virtual;
    procedure ApplyExportScale(AScale: Single); virtual;
    procedure SetExportRect(ARect: TRectF); virtual;
    procedure ResetExportScale; virtual;
    procedure &Export({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF);
    procedure UpdateRange(AXRange, AYRange: Double; AScale: Boolean);
    {$IFDEF FMXLIB}
    procedure MouseDown(Button: TMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure MouseMove(Shift: TShiftState; X, Y: Single); override;
    procedure MouseUp(Button: TMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure MouseWheel(Shift: TShiftState; WheelDelta: Integer; var Handled: Boolean); override;
    procedure DoMouseLeave; override;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    {$IFDEF VCLLIB}
    procedure WMGetDlgCode(var Message: TWMGetDlgCode); message WM_GETDLGCODE;
    procedure WMMouseWheel(var Message: TWMMouseWheel); message WM_MOUSEWHEEL;
    procedure CMMouseLeave(var Message: TMessage); message CM_MOUSELEAVE;
    {$ENDIF}
    {$IFDEF LCLLIB}
    procedure WMGetDlgCode(var Message: TLMNoParams); message LM_GETDLGCODE;
    procedure WMMouseWheel(var Message: TLMMouseEvent); message LM_MOUSEWHEEL;
    procedure CMMouseLeave(var {%H-}Message: TLMNoParams); message CM_MOUSELEAVE;
    {$ENDIF}
    procedure MouseDown(Button: TMouseButton; Shift: TShiftState; X, Y: Integer); override;
    procedure MouseMove(Shift: TShiftState; X, Y: Integer); override;
    procedure MouseUp(Button: TMouseButton; Shift: TShiftState; X, Y: Integer); override;
    {$ENDIF}
    procedure InternalInitSample(ANumOfSeries: Integer = 3; AShowMarkers: Boolean = True; AMaxValue: Integer = 130);
    function IsAppearanceProperty({%H-}AObject: TObject; {%H-}APropertyName: string; {%H-}APropertyType: TTypeKind): Boolean; virtual;
    function CanSaveProperty({%H-}AObject: TObject; {%H-}APropertyName: string; {%H-}APropertyType: TTypeKind): Boolean; virtual;
    function CanLoadProperty({%H-}AObject: TObject; {%H-}APropertyName: string; {%H-}APropertyType: TTypeKind): Boolean; virtual;
    function GetTotal3DOffsetX: Double;
    function GetTotal3DOffsetY: Double;
    function HasPieChart: Boolean;
    function HasSpiderChart: Boolean;
    function HasStackedPieChart: Boolean;
    function IsDesignerForm: Boolean;
    function InternalAddBandSerie(ASerieIndex: Integer; AStartX, AEndX: integer; AStartValue1, AStartValue2, AEndValue1, AEndValue2: Double; AUseCommonYRange: Boolean = true; ASerieLineColor: TTMSFNCGraphicsColor = gcBlack; ASerieLineWidth: integer = 1; ASerieBandColor: TTMSFNCGraphicsColor = gcGray): TTMSFNCChartSerie;
    function InternalAddLineSerie(ASerieIndex: Integer; AStartX, AEndX: Integer; AStartValue, AEndValue: Double; AUseCommonYRange: Boolean = True; ASerieLineColor: TTMSFNCGraphicsColor = gcBlack; ASerieLineWidth: Integer = 1): TTMSFNCChartSerie;
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure CalculateXAxis;
    procedure CalculateXAxisHeight;
    procedure CalculateYAxis;
    procedure CalculateXScale;
    procedure CalculateYScale;
    procedure CalculateSeries;
    procedure CalculateMinMaxX;
    procedure CalculateMinMaxY;
    procedure Calculate3DOffset;
    procedure CalculateCommonRangeX;
    procedure CalculateCommonRangeY;
    procedure SeriesMarginsChanged(Sender: TObject);
    procedure UpdateChart;
    procedure InvalidateChart;
    procedure DrawBackGround(AGraphics: TTMSFNCGraphics); virtual;
    procedure DrawXYGrid(AGraphics: TTMSFNCGraphics); virtual;
    procedure DoBeforeDrawYAxis(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartYAxisPosition; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawYAxis(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartYAxisPosition); virtual;
    procedure DoBeforeDrawXAxis(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartXAxisPosition; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawXAxis(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartXAxisPosition); virtual;
    procedure DoBeforeDrawTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartTitlePosition; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartTitlePosition); virtual;
    procedure DoBeforeDrawXValuesTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; ATextRect: TRectF; APosition: TTMSFNCChartXAxisPosition; ASerie: TTMSFNCChartSerie; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawXValuesTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; ATextRect: TRectF; APosition: TTMSFNCChartXAxisPosition; ASerie: TTMSFNCChartSerie); virtual;
    procedure DoBeforeDrawYValuesTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; ATextRect: TRectF; APosition: TTMSFNCChartYAxisPosition; ASerie: TTMSFNCChartSerie; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawYValuesTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; ATextRect: TRectF; APosition: TTMSFNCChartYAxisPosition; ASerie: TTMSFNCChartSerie); virtual;
    procedure DoBeforeDrawLegend(AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawLegend(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawLegendIcon(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawLegendIcon(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF); virtual;
    procedure DoBeforeDrawSerieLegend(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieLegend(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF); virtual;
    procedure DoBeforeDrawSerieYAxisCrosshairText(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartYAxisPosition; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieYAxisCrosshairText(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartYAxisPosition; AMode: TTMSFNCChartCrosshairMode); virtual;
    procedure DoBeforeDrawSerieXAxisCrosshairText(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartXAxisPosition; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieXAxisCrosshairText(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartXAxisPosition; AMode: TTMSFNCChartCrosshairMode); virtual;
    procedure DoBeforeDrawSerieCrosshairHorizontalLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieCrosshairHorizontalLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode); virtual;
    procedure DoBeforeDrawSerieCrosshairVerticalLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieCrosshairVerticalLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode); virtual;
    procedure DoGetSerieXAxisCrosshairText(ASerie: TTMSFNCChartSerie; AValue: Double; APosition: TTMSFNCChartXAxisPosition; AMode: TTMSFNCChartCrosshairMode; var AText: String); virtual;
    procedure DoGetSerieYAxisCrosshairText(ASerie: TTMSFNCChartSerie; AValue: Double; APosition: TTMSFNCChartYAxisPosition; AMode: TTMSFNCChartCrosshairMode; var AText: String); virtual;
    procedure DoBeforeDrawSerieLegendIcon(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; ARect: TRectF; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieLegendIcon(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; ARect: TRectF); virtual;
    procedure DoGetSerieLegendText(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; var ALegendText: String); virtual;
    procedure DoGetSerieSpiderLegendText(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; var ALegendText: String); virtual;
    procedure DoBeforeDrawSerieLegendIconVirtual(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; ARect: TRectF; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieLegendIconVirtual(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; ARect: TRectF); virtual;
    procedure DoGetSerieLegendTextVirtual(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; var ALegendText: String); virtual;
    procedure DoGetSerieSpiderLegendTextVirtual(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; var ALegendText: String); virtual;
    procedure DoBeforeDrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawChart(AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawChart(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawSeries(AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSeries(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawSerieMarker(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawPoint: TTMSFNCChartDrawPoint; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieMarker(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawPoint: TTMSFNCChartDrawPoint); virtual;
    procedure DoBeforeDrawSerieSlice(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawSlice: TTMSFNCChartDrawSlice; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieSlice(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawSlice: TTMSFNCChartDrawSlice); virtual;
    procedure DoBeforeDrawSerieLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawLine: TTMSFNCChartDrawLine; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawLine: TTMSFNCChartDrawLine); virtual;
    procedure DoBeforeDrawSerieMultiPoint(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawMultiPoint: TTMSFNCChartDrawMultiPoint; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieMultiPoint(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawMultiPoint: TTMSFNCChartDrawMultiPoint); virtual;
    procedure DoBeforeDrawSerieGridLegendText(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawGridLegendText: TTMSFNCChartDrawLabel; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieGridLegendText(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawGridLegendText: TTMSFNCChartDrawLabel); virtual;
    procedure DoBeforeDrawSerieLabel(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawLabel: TTMSFNCChartDrawLabel; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieLabel(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawLabel: TTMSFNCChartDrawLabel); virtual;
    procedure DoBeforeDrawSerieAnnotation(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawAnnotation: TTMSFNCChartDrawAnnotation; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieAnnotation(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawAnnotation: TTMSFNCChartDrawAnnotation); virtual;
    procedure DoBeforeDrawSerieBar(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawRect: TTMSFNCChartDrawRect; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieBar(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawRect: TTMSFNCChartDrawRect); virtual;
    procedure DoDrawTitleText(AGraphics: TTMSFNCGraphics; ARect: TRectF; APosition: TTMSFNCChartTitlePosition; var AText: String; var ADefaultDraw: Boolean); virtual;
    procedure DoAnimateSerieFinished(ASerie: TTMSFNCChartSerie); virtual;
    procedure DoAnimateSerieStarted(ASerie: TTMSFNCChartSerie); virtual;
    procedure DoGetSerieYValue(ASerie: TTMSFNCChartSerie; AIndex: Integer; AKind: TTMSFNCChartDrawXYValueKind; AValue: Double; var AValueString: String); virtual;
    procedure DoGetSerieLabel(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; var AValueString: String); virtual;
    procedure DoGetSerieLabelVirtual(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; var AValueString: String); virtual;
    procedure DoGetSerieXValue(ASerie: TTMSFNCChartSerie; AIndex: Integer; AKind: TTMSFNCChartDrawXYValueKind; AValue: Double; var AValueString: String); virtual;
    procedure DoBeforeDrawSerieYValue(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartYAxisPosition; var ADrawValue: TTMSFNCChartDrawXYValue; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieYValue(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartYAxisPosition; ADrawValue: TTMSFNCChartDrawXYValue); virtual;
    procedure DoBeforeDrawSerieXValue(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartXAxisPosition; var ADrawValue: TTMSFNCChartDrawXYValue; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieXValue(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartXAxisPosition; ADrawValue: TTMSFNCChartDrawXYValue); virtual;
    procedure DoBeforeDrawSerieYGridLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawGridLine: TTMSFNCChartDrawXYGridLine; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieYGridLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawGridLine: TTMSFNCChartDrawXYGridLine); virtual;
    procedure DoBeforeDrawSerieXGridLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; var ADrawGridLine: TTMSFNCChartDrawXYGridLine; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawSerieXGridLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ADrawGridLine: TTMSFNCChartDrawXYGridLine); virtual;
    procedure DoSeriePointClick(APoint: TTMSFNCChartPoint); virtual;
    procedure DoSerieBarClick(APoint: TTMSFNCChartPoint); virtual;
    procedure DoSerieSliceClick(APoint: TTMSFNCChartPoint); virtual;
    procedure DoSerieLegendItemClick(ASerie: TTMSFNCChartSerie; AIndex: Integer); virtual;
    procedure DoLegendItemClick(AIndex: Integer); virtual;
    procedure DoSeriePointClickVirtual(APoint: TTMSFNCChartPointVirtual); virtual;
    procedure DoSerieBarClickVirtual(APoint: TTMSFNCChartPointVirtual); virtual;
    procedure DoSerieSliceClickVirtual(APoint: TTMSFNCChartPointVirtual); virtual;
    procedure DoCustomizeAnnotationFont(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; AAnnotation: TTMSFNCChartAnnotationVirtual; AFont: TTMSFNCGraphicsFont); virtual;
    procedure DoCustomizeAnnotationStroke(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; AAnnotation: TTMSFNCChartAnnotationVirtual; AStroke: TTMSFNCGraphicsStroke); virtual;
    procedure DoCustomizeAnnotationFill(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; AAnnotation: TTMSFNCChartAnnotationVirtual; AFill: TTMSFNCGraphicsFill); virtual;

    procedure DoGetNumberOfPoints(ASerie: TTMSFNCChartSerie; var ANumberOfPoints: Integer); virtual;
    procedure DoGetPoint(ASerie: TTMSFNCChartSerie; AIndex: Integer; var APoint: TTMSFNCChartPointVirtual); virtual;
    procedure DoGetNumberOfAnnotations(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; var ANumberOfAnnotations: Integer); virtual;
    procedure DoGetAnnotation(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; AIndex: Integer; var AAnnotation: TTMSFNCChartAnnotationVirtual); virtual;
    procedure DoBeforeSetAllFonts(ASetType: TTMSFNCChartAppearanceFontType; var ASetChartFonts: Boolean; var ASetSeriesFonts: Boolean; var ASetAnnotationFonts: Boolean); virtual;
    procedure DoJSONAddSerie(ASerie: TTMSFNCChartSerie; ASerieJSONValue: TJSONValue); virtual;
    procedure DoJSONAddPoint(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; APointJSONValue: TJSONValue); virtual;
    procedure DoParseDateTimeStringToDateTime(ADateTimeString: string; var ADateTime: TDateTime); virtual;
    function GetDefaultChartType: TTMSFNCChartSerieType; virtual;
    procedure InternalLoadFromCSV(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueLowColumnIndex: Integer = -1; YValueOpenColumnIndex: Integer = -1; YValueCloseColumnIndex: Integer = -1; YValueMedianColumnIndex: Integer = -1); virtual;
    procedure InternalLoadFromCSVStream(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueLowColumnIndex: Integer = -1; YValueOpenColumnIndex: Integer = -1; YValueCloseColumnIndex: Integer = -1; YValueMedianColumnIndex: Integer = -1); virtual;
    procedure InternalLoadFromCSVText(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueLowColumnIndex: Integer = -1; YValueOpenColumnIndex: Integer = -1; YValueCloseColumnIndex: Integer = -1; YValueMedianColumnIndex: Integer = -1); virtual;
    procedure InternalLoadFromJSON(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string = ''; APointsName: string = ''; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; AYSecValueNames: TTMSFNCChartJSONNamesArray = nil; AYVarValueNames: TTMSFNCChartJSONNamesArray = nil; AYLowValueName: string = ''; AYOpenValueName: string = ''; AYCloseValueName: string = ''; AYMedianValueName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); virtual;
    procedure InternalLoadFromJSONStream(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string = ''; APointsName: string = ''; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; AYSecValueNames: TTMSFNCChartJSONNamesArray = nil; AYVarValueNames: TTMSFNCChartJSONNamesArray = nil; AYLowValueName: string = ''; AYOpenValueName: string = ''; AYCloseValueName: string = ''; AYMedianValueName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); virtual;
    procedure InternalLoadFromJSONText(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string = ''; APointsName: string = ''; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; AYSecValueNames: TTMSFNCChartJSONNamesArray = nil; AYVarValueNames: TTMSFNCChartJSONNamesArray = nil; AYLowValueName: string = ''; AYOpenValueName: string = ''; AYCloseValueName: string = ''; AYMedianValueName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); virtual;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
  public

    function XYToSerieLegendItem(X, Y: Double; var ASerie: TTMSFNCChartSerie): Integer;
    function XYToLegendItem(X, Y: Double): Integer;
    function XYToPoint(X, Y: Double): TTMSFNCChartPoint;
    function XYToBar(X, Y: Double): TTMSFNCChartPoint;
    function XYToSlice(X, Y: Double): TTMSFNCChartPoint;
    function XYToClosestDrawPoint(X, Y: Double; var ADrawPoint: TTMSFNCChartDrawPoint): Boolean;
    function XYToPointVirtual(X, Y: Double; var APoint: TTMSFNCChartPointVirtual): Boolean;
    function XYToBarVirtual(X, Y: Double; var APoint: TTMSFNCChartPointVirtual): Boolean;
    function XYToSliceVirtual(X, Y: Double; var APoint: TTMSFNCChartPointVirtual): Boolean;

    function AddLineSerie(AStartX, AEndX: Integer; AStartValue, AEndValue: Double; AUseCommonYRange: Boolean = True; ASerieLineColor: TTMSFNCGraphicsColor = gcBlack; ASerieLineWidth: Integer = 1): TTMSFNCChartSerie;
    function AddParetoLine(ASerieIndex, AStartx, AEndx: Integer; AUseCommonYRange: Boolean = True; ASerieLineColor: TTMSFNCGraphicsColor = gcBlack; ASerieLineWidth: Integer = 1): TTMSFNCChartSerie;
    function AddTrendLine(ASerieIndex, AStartX, AEndX: Integer; AUseCommonYRange: Boolean = True; ASerieLineColor: TTMSFNCGraphicsColor = gcBlack; ASerieLineWidth: Integer = 1): TTMSFNCChartSerie;
    function AddBandSerie(AStartX, AEndX: integer; AStartValue1, AStartValue2, AEndValue1, AEndValue2: Double; AUseCommonYRange: Boolean = true; ASerieLineColor: TTMSFNCGraphicsColor = gcBlack; ASerieLineWidth: integer = 1; ASerieBandColor: TTMSFNCGraphicsColor = gcGray): TTMSFNCChartSerie;
    function AddTrendChannelBand(ASerieIndex, AStartX, AEndX: integer; AUseCommonYRange: Boolean = True; ASerieLineColor: TTMSFNCGraphicsColor = gcBlack; ASerieLineWidth: integer = 1; ASerieBandColor: TTMSFNCGraphicsColor = gcGray): TTMSFNCChartSerie;
    function AddMovingAverage(ASerieIndex, AStartX, AEndX, AWindow: Integer; AUseCommonYRange: Boolean = true; ASerieLineColor: TTMSFNCGraphicsColor = gcBlack; ASerieLineWidth: Integer = 1): TTMSFNCChartSerie;
    function AddPointArray(APoints: array of Double; ASerieType: TTMSFNCChartSerieType = ctLine): TTMSFNCChartSerie;
    function GetSeriesRectangle: TRectF;
    function GetChartRectangle: TRectF; virtual;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure AddTrendChannel(ASerieIndex, AStartX, AEndX: Integer; AUseCommonYRange: Boolean = True; ASerieLineColor: TTMSFNCGraphicsColor = gcBlack; ASerieLineWidth: Integer = 1);
    procedure Assign(Source: TPersistent); override;
    {$IF DEFINED(FMXLIB) AND NOT (COMPILERVERSION = 28)}
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    {$ELSE}
    {$IFDEF WEBLIB}
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    {$ENDIF}
    {$IFNDEF WEBLIB}
    procedure BeginUpdate; virtual;
    procedure EndUpdate; virtual;
    {$ENDIF}
    {$ENDIF}
    procedure Paint; override;
    procedure DrawChart(AGraphics: TTMSFNCGraphics); virtual;
    procedure Resize; override;
    procedure InitSample; virtual;
    procedure Clear;
    property SerieByName[AName: string]: TTMSFNCChartSerie read GetSerieByName;
    procedure SaveToImage(AFileName: String);
    {$IFDEF WEBLIB}
    function SaveToBase64: string;
    {$ENDIF}
    {$IFDEF FMXLIB}
    procedure CMGesture(var EventInfo: TGestureEventInfo); override;
    {$ENDIF}
    procedure SaveSettingsToFile(AFileName: string; AAppearanceOnly: Boolean = False); virtual;
    procedure LoadSettingsFromFile(AFileName: string); virtual;
    procedure SaveSettingsToStream(AStream: TStream; AAppearanceOnly: Boolean = False); virtual;
    procedure LoadSettingsFromStream(AStream: TStream); virtual;
    function AddSeriesFromDataArray(YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;
    function AddSeriesFromDataArrayEx(YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil): TTMSFNCChartSerie; overload; virtual;
    function AddSeriesFromMultiPointDataArray(YHighValuesArray: TTMSFNCChartValuesArray; YLowValuesArray: TTMSFNCChartValuesArray; YOpenValuesArray: TTMSFNCChartValuesArray;
      YCloseValuesArray: TTMSFNCChartValuesArray; YMedianValuesArray: TTMSFNCChartValuesArray = nil; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;
    function LoadFromDataArray(ASerieIndex: Integer; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;
    function LoadFromDataArrayEx(ASerieIndex: Integer; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil): TTMSFNCChartSerie; overload; virtual;
    function AppendFromDataArray(ASerieIndex: Integer; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;
    function AppendFromDataArrayEx(ASerieIndex: Integer; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil): TTMSFNCChartSerie; overload; virtual;
    function LoadFromMultiPointDataArray(ASerieIndex: Integer; YHighValuesArray: TTMSFNCChartValuesArray; YLowValuesArray: TTMSFNCChartValuesArray; YOpenValuesArray: TTMSFNCChartValuesArray;
      YCloseValuesArray: TTMSFNCChartValuesArray; YMedianValuesArray: TTMSFNCChartValuesArray = nil; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;
    function AppendFromMultiPointDataArray(ASerieIndex: Integer; YHighValuesArray: TTMSFNCChartValuesArray; YLowValuesArray: TTMSFNCChartValuesArray; YOpenValuesArray: TTMSFNCChartValuesArray;
      YCloseValuesArray: TTMSFNCChartValuesArray; YMedianValuesArray: TTMSFNCChartValuesArray = nil; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;

    function AddSeriesFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;
    function AddSeriesFromDataArrayEx(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil): TTMSFNCChartSerie; overload; virtual;
    function AddSeriesFromMultiPointDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YHighValuesArray: TTMSFNCChartValuesArray; YLowValuesArray: TTMSFNCChartValuesArray; YOpenValuesArray: TTMSFNCChartValuesArray;
      YCloseValuesArray: TTMSFNCChartValuesArray; YMedianValuesArray: TTMSFNCChartValuesArray = nil; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;
    function LoadFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;
    function LoadFromDataArrayEx(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil): TTMSFNCChartSerie; overload; virtual;
    function AppendFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;
    function AppendFromDataArrayEx(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YValuesArray: TTMSFNCChartValuesArray; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil;
      YSecondValuesArray: TTMSFNCChartValuesArray = nil; YVariableValuesArray: TTMSFNCChartValuesArray = nil): TTMSFNCChartSerie; overload; virtual;
    function LoadFromMultiPointDataArray(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YHighValuesArray: TTMSFNCChartValuesArray; YLowValuesArray: TTMSFNCChartValuesArray; YOpenValuesArray: TTMSFNCChartValuesArray;
      YCloseValuesArray: TTMSFNCChartValuesArray; YMedianValuesArray: TTMSFNCChartValuesArray = nil; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;
    function AppendFromMultiPointDataArray(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YHighValuesArray: TTMSFNCChartValuesArray; YLowValuesArray: TTMSFNCChartValuesArray; YOpenValuesArray: TTMSFNCChartValuesArray;
      YCloseValuesArray: TTMSFNCChartValuesArray; YMedianValuesArray: TTMSFNCChartValuesArray = nil; XValuesArray: TTMSFNCChartValuesArray = nil; XLabelsArray: TTMSFNCChartLabelsArray = nil): TTMSFNCChartSerie; overload; virtual;

    procedure LoadFromCSVData(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;
    procedure LoadFromCSVData(AFileName: string; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;
    procedure LoadFromCSVDataEx(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray = nil); overload; virtual;
    procedure LoadFromCSVDataEx(AFileName: string; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray = nil); overload; virtual;
    procedure LoadFromCSVMultiPointData(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer = -1; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;
    procedure LoadFromCSVMultiPointData(AFileName: string; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer = -1; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;
    procedure LoadFromCSVStreamData(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;
    procedure LoadFromCSVStreamData(AStream: TStream; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;
    procedure LoadFromCSVStreamDataEx(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray = nil); overload; virtual;
    procedure LoadFromCSVStreamDataEx(AStream: TStream; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray = nil); overload; virtual;
    procedure LoadFromCSVStreamMultiPointData(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer = -1; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;
    procedure LoadFromCSVStreamMultiPointData(AStream: TStream; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer = -1; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;
    procedure LoadFromCSVTextData(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;
    procedure LoadFromCSVTextData(AText: string; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;
    procedure LoadFromCSVTextDataEx(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray = nil); overload; virtual;
    procedure LoadFromCSVTextDataEx(AText: string; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray = nil; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray = nil); overload; virtual;
    procedure LoadFromCSVTextMultiPointData(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer = -1; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;
    procedure LoadFromCSVTextMultiPointData(AText: string; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer = -1; XValueColumnIndex: Integer = -1; XLabelColumnIndex: Integer = -1); overload; virtual;

    procedure LoadFromJSONData(AFileName: string; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONDataEx(AFileName: string; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; AYSecValueName: string = ''; AYVarValueName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONMultiPointData(AFileName: string; ASeriesName: string; APointsName: string; AYHighValueName: string; AYLowValueName: string; AYOpenValueName: string; AYCloseValueName: string; AYMedianValueName: string = ''; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONStreamData(AStream: TStream; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONStreamDataEx(AStream: TStream; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; AYSecValueName: string = ''; AYVarValueName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONStreamMultiPointData(AStream: TStream; ASeriesName: string; APointsName: string; AYHighValueName: string; AYLowValueName: string; AYOpenValueName: string; AYCloseValueName: string; AYMedianValueName: string = ''; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONTextData(AText: string; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONTextDataEx(AText: string; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; AYSecValueName: string = ''; AYVarValueName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONTextMultiPointData(AText: string; ASeriesName: string; APointsName: string; AYHighValueName: string; AYLowValueName: string; AYOpenValueName: string; AYCloseValueName: string; AYMedianValueName: string = ''; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;

    procedure LoadFromJSONData(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONDataEx(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; AYSecValueName: string = ''; AYVarValueName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONMultiPointData(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYHighValueName: string; AYLowValueName: string; AYOpenValueName: string; AYCloseValueName: string; AYMedianValueName: string = ''; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONStreamData(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONStreamDataEx(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; AYSecValueName: string = ''; AYVarValueName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONStreamMultiPointData(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYHighValueName: string; AYLowValueName: string; AYOpenValueName: string; AYCloseValueName: string; AYMedianValueName: string = ''; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONTextData(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONTextDataEx(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueName: string; AXValueName: string = ''; AXLabelName: string = ''; AYSecValueName: string = ''; AYVarValueName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONTextMultiPointData(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYHighValueName: string; AYLowValueName: string; AYOpenValueName: string; AYCloseValueName: string; AYMedianValueName: string = ''; AXValueName: string = ''; AXLabelName: string = ''; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;

    procedure LoadFromJSONData(AFileName: string; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONDataEx(AFileName: string; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; AYSecValueNames: TTMSFNCChartJSONNamesArray = nil; AYVarValueNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONStreamData(AStream: TStream; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONStreamDataEx(AStream: TStream; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; AYSecValueNames: TTMSFNCChartJSONNamesArray = nil; AYVarValueNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONTextData(AText: string; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONTextDataEx(AText: string; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; AYSecValueNames: TTMSFNCChartJSONNamesArray = nil; AYVarValueNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONData(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONDataEx(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; AYSecValueNames: TTMSFNCChartJSONNamesArray = nil; AYVarValueNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONStreamData(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONStreamDataEx(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; AYSecValueNames: TTMSFNCChartJSONNamesArray = nil; AYVarValueNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONTextData(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure LoadFromJSONTextDataEx(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName: string; APointsName: string; AYValueNames: TTMSFNCChartJSONNamesArray = nil; AXValueNames: TTMSFNCChartJSONNamesArray = nil; AXLabelNames: TTMSFNCChartJSONNamesArray = nil; AYSecValueNames: TTMSFNCChartJSONNamesArray = nil; AYVarValueNames: TTMSFNCChartJSONNamesArray = nil; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent = nil; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent = nil ); overload; virtual;
    procedure SetXValuesPosition(AIndex: integer; APositions: TTMSFNCChartXAxisPositions = [xpBottom]; AClearOther: Boolean = True);
    procedure SetYValuesPosition(AIndex: integer; APositions: TTMSFNCChartYAxisPositions = [ypLeft]; AClearOther: Boolean = True);
    procedure SetAutoXRange(AXRange: TTMSFNCChartSerieAutoRange; ASerieIndex: Integer = -1);
    procedure SetAutoYRange(AYRange: TTMSFNCChartSerieAutoRange; ASerieIndex: Integer = -1);
  published
    property Appearance: TTMSFNCChartAppearance read FAppearance write SetAppearance;
    property AntiAliasing: Boolean read FAntiAliasing write SetAntiAliasing default True;
    property NativeCanvas: Boolean read FNativeCanvas write SetNativeCanvas default False;
    property TextQuality: TTMSFNCGraphicsTextQuality read FTextQuality write SetTextQuality default gtqAntiAliasing;
    property ClickMargin: Double read FClickMargin write FClickMargin;
    property InteractionOptions: TTMSFNCChartInteractionOptions read FInteractionOptions write SetInteractionOptions;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Crosshair: TTMSFNCChartCrosshair read FCrosshair write SetCrosshair;
    property Legend: TTMSFNCChartLegend read FLegend write SetLegend;
    property Interaction: Boolean read FInteraction write FInteraction default True;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property SeriesMargins: TTMSFNCChartMargins read FSeriesMargins write SetSeriesMargins;
    property DrawOrder: TTMSFNCChartSeriesDrawOrder read FDrawOrder write SetDrawOrder default csdoBarsFirst;
    property Series: TTMSFNCChartSeries read FSeries write SetSeries;
    property Title: TTMSFNCChartTitle read FTitle write SetTitle;
    property Version: String read GetVersion;
    property XAxis: TTMSFNCChartXAxis read FXAxis write SetXAxis;
    property YAxis: TTMSFNCChartYAxis read FYAxis write SetYAxis;
    property Adapter: TTMSFNCChartAdapter read FAdapter write SetAdapter;
    property DefaultLoadOptions: TTMSFNCChartLoadOptions read FDefaultLoadOptions write SetDefaultLoadOptions;
    property OnAfterDrawSerieBar: TTMSFNCChartAfterDrawSerieBar read FOnAfterDrawSerieBar write FOnAfterDrawSerieBar;
    property OnAfterDrawSerieLine: TTMSFNCChartAfterDrawSerieLine read FOnAfterDrawSerieLine write FOnAfterDrawSerieLine;
    property OnAfterDrawSerieMultiPoint: TTMSFNCChartAfterDrawSerieMultiPoint read FOnAfterDrawSerieMultiPoint write FOnAfterDrawSerieMultiPoint;
    property OnAfterDrawSerieSlice: TTMSFNCChartAfterDrawSerieSlice read FOnAfterDrawSerieSlice write FOnAfterDrawSerieSlice;
    property OnAfterDrawSerieLabel: TTMSFNCChartAfterDrawSerieLabel read FOnAfterDrawSerieLabel write FOnAfterDrawSerieLabel;
    property OnAfterDrawSerieAnnotation: TTMSFNCChartAfterDrawSerieAnnotation read FOnAfterDrawSerieAnnotation write FOnAfterDrawSerieAnnotation;
    property OnBeforeDrawSerieBar: TTMSFNCChartBeforeDrawSerieBar read FOnBeforeDrawSerieBar write FOnBeforeDrawSerieBar;
    property OnBeforeDrawSerieLine: TTMSFNCChartBeforeDrawSerieLine read FOnBeforeDrawSerieLine write FOnBeforeDrawSerieLine;
    property OnBeforeDrawSerieMultiPoint: TTMSFNCChartBeforeDrawSerieMultiPoint read FOnBeforeDrawSerieMultiPoint write FOnBeforeDrawSerieMultiPoint;
    property OnBeforeDrawSerieSlice: TTMSFNCChartBeforeDrawSerieSlice read FOnBeforeDrawSerieSlice write FOnBeforeDrawSerieSlice;
    property OnBeforeDrawSerieLabel: TTMSFNCChartBeforeDrawSerieLabel read FOnBeforeDrawSerieLabel write FOnBeforeDrawSerieLabel;
    property OnBeforeDrawSerieGridLegendText: TTMSFNCChartBeforeDrawSerieGridLegendText read FOnBeforeDrawSerieGridLegendText write FOnBeforeDrawSerieGridLegendText;
    property OnAfterDrawSerieGridLegendText: TTMSFNCChartAfterDrawSerieGridLegendText read FOnAfterDrawSerieGridLegendText write FOnAfterDrawSerieGridLegendText;
    property OnBeforeDrawSerieAnnotation: TTMSFNCChartBeforeDrawSerieAnnotation read FOnBeforeDrawSerieAnnotation write FOnBeforeDrawSerieAnnotation;
    property OnBeforeDrawXValuesTitle: TTMSFNCChartBeforeDrawXValuesTitle read FOnBeforeDrawXValuesTitle write FOnBeforeDrawXValuesTitle;
    property OnBeforeDrawYValuesTitle: TTMSFNCChartBeforeDrawYValuesTitle read FOnBeforeDrawYValuesTitle write FOnBeforeDrawYValuesTitle;
    property OnAfterDrawXValuesTitle: TTMSFNCChartAfterDrawXValuesTitle read FOnAfterDrawXValuesTitle write FOnAfterDrawXValuesTitle;
    property OnAfterDrawYValuesTitle: TTMSFNCChartAfterDrawYValuesTitle read FOnAfterDrawYValuesTitle write FOnAfterDrawYValuesTitle;
    property OnBeforeDrawXAxis: TTMSFNCChartBeforeDrawXAxis read FOnBeforeDrawXAxis write FOnBeforeDrawXAxis;
    property OnAfterDrawXAxis: TTMSFNCChartAfterDrawXAxis read FOnAfterDrawXAxis write FOnAfterDrawXAxis;
    property OnBeforeDrawYAxis: TTMSFNCChartBeforeDrawYAxis read FOnBeforeDrawYAxis write FOnBeforeDrawYAxis;
    property OnAfterDrawYAxis: TTMSFNCChartAfterDrawYAxis read FOnAfterDrawYAxis write FOnAfterDrawYAxis;
    property OnBeforeDrawTitle: TTMSFNCChartBeforeDrawTitle read FOnBeforeDrawTitle write FOnBeforeDrawTitle;
    property OnAfterDrawTitle: TTMSFNCChartAfterDrawTitle read FOnAfterDrawTitle write FOnAfterDrawTitle;
    property OnBeforeDrawBackground: TTMSFNCChartBeforeDrawBackground read FOnBeforeDrawBackground write FOnBeforeDrawBackground;
    property OnAfterDrawBackground: TTMSFNCChartAfterDrawBackground read FOnAfterDrawBackground write FOnAfterDrawBackground;
    property OnBeforeDrawChart: TTMSFNCChartBeforeDrawChart read FOnBeforeDrawChart write FOnBeforeDrawChart;
    property OnAfterDrawChart: TTMSFNCChartAfterDrawChart read FOnAfterDrawChart write FOnAfterDrawChart;
    property OnBeforeDrawLegend: TTMSFNCChartBeforeDrawLegend read FOnBeforeDrawLegend write FOnBeforeDrawLegend;
    property OnAfterDrawLegend: TTMSFNCChartAfterDrawLegend read FOnAfterDrawLegend write FOnAfterDrawLegend;
    property OnBeforeDrawLegendIcon: TTMSFNCChartBeforeDrawLegendIcon read FOnBeforeDrawLegendIcon write FOnBeforeDrawLegendIcon;
    property OnAfterDrawLegendIcon: TTMSFNCChartAfterDrawLegendIcon read FOnAfterDrawLegendIcon write FOnAfterDrawLegendIcon;
    property OnBeforeDrawSerieLegend: TTMSFNCChartBeforeDrawSerieLegend read FOnBeforeDrawSerieLegend write FOnBeforeDrawSerieLegend;
    property OnAfterDrawSerieLegend: TTMSFNCChartAfterDrawSerieLegend read FOnAfterDrawSerieLegend write FOnAfterDrawSerieLegend;
    property OnBeforeDrawSerieLegendIcon: TTMSFNCChartBeforeDrawSerieLegendIcon read FOnBeforeDrawSerieLegendIcon write FOnBeforeDrawSerieLegendIcon;
    property OnAfterDrawSerieLegendIcon: TTMSFNCChartAfterDrawSerieLegendIcon read FOnAfterDrawSerieLegendIcon write FOnAfterDrawSerieLegendIcon;
    property OnBeforeDrawSerieLegendIconVirtual: TTMSFNCChartBeforeDrawSerieLegendIconVirtual read FOnBeforeDrawSerieLegendIconVirtual write FOnBeforeDrawSerieLegendIconVirtual;
    property OnAfterDrawSerieLegendIconVirtual: TTMSFNCChartAfterDrawSerieLegendIconVirtual read FOnAfterDrawSerieLegendIconVirtual write FOnAfterDrawSerieLegendIconVirtual;
    property OnCustomizeAnnotationFont: TTMSFNCChartCustomizeAnnotationFont read FOnCustomizeAnnotationFont write FOnCustomizeAnnotationFont;
    property OnCustomizeAnnotationFill: TTMSFNCChartCustomizeAnnotationFill read FOnCustomizeAnnotationFill write FOnCustomizeAnnotationFill;
    property OnCustomizeAnnotationStroke: TTMSFNCChartCustomizeAnnotationStroke read FOnCustomizeAnnotationStroke write FOnCustomizeAnnotationStroke;
    property OnGetNumberOfPoints: TTMSFNCChartGetNumberOfPoints read FOnGetNumberOfPoints write FOnGetNumberOfPoints;
    property OnGetPoint: TTMSFNCChartGetPoint read FOnGetPoint write FOnGetPoint;
    property OnGetNumberOfAnnotations: TTMSFNCChartGetNumberOfAnnotations read FOnGetNumberOfAnnotations write FOnGetNumberOfAnnotations;
    property OnGetAnnotation: TTMSFNCChartGetAnnotation read FOnGetAnnotation write FOnGetAnnotation;
    property OnDrawTitleText: TTMSFNCChartDrawTitleText read FOnDrawTitleText write FOnDrawTitleText;
    property OnGetSerieLabel: TTMSFNCChartGetSerieLabel read FOnGetSerieLabel write FOnGetSerieLabel;
    property OnGetSerieLegendText: TTMSFNCChartGetSerieLegendText read FOnGetSerieLegendText write FOnGetSerieLegendText;
    property OnGetSerieSpiderLegendText: TTMSFNCChartGetSerieLegendText read FOnGetSerieSpiderLegendText write FOnGetSerieSpiderLegendText;
    property OnGetSerieLabelVirtual: TTMSFNCChartGetSerieLabelVirtual read FOnGetSerieLabelVirtual write FOnGetSerieLabelVirtual;
    property OnGetSerieLegendTextVirtual: TTMSFNCChartGetSerieLegendTextVirtual read FOnGetSerieLegendTextVirtual write FOnGetSerieLegendTextVirtual;
    property OnGetSerieSpiderLegendTextVirtual: TTMSFNCChartGetSerieLegendTextVirtual read FOnGetSerieSpiderLegendTextVirtual write FOnGetSerieSpiderLegendTextVirtual;
    property OnBeforeDrawSerieMarker: TTMSFNCChartBeforeDrawSerieMarker read FOnBeforeDrawSerieMarker write FOnBeforeDrawSerieMarker;
    property OnAfterDrawSerieMarker: TTMSFNCChartAfterDrawSerieMarker read FOnAfterDrawSerieMarker write FOnAfterDrawSerieMarker;
    property OnBeforeDrawSeries: TTMSFNCChartBeforeDrawSeries read FOnBeforeDrawSeries write FOnBeforeDrawSeries;
    property OnAfterDrawSeries: TTMSFNCChartAfterDrawSeries read FOnAfterDrawSeries write FOnAfterDrawSeries;
    property OnAnimateSerieFinished: TTMSFNCChartAnimateSerieFinished read FOnAnimateSerieFinished write FOnAnimateSerieFinished;
    property OnAnimateSerieStarted: TTMSFNCChartAnimateSerieStarted read FOnAnimateSerieStarted write FOnAnimateSerieStarted;
    property OnBeforeDrawSerieXValue: TTMSFNCChartBeforeDrawSerieXValue read FOnBeforeDrawSerieXValue write FOnBeforeDrawSerieXValue;
    property OnBeforeDrawSerieYValue: TTMSFNCChartBeforeDrawSerieYValue read FOnBeforeDrawSerieYValue write FOnBeforeDrawSerieYValue;
    property OnAfterDrawSerieXValue: TTMSFNCChartAfterDrawSerieXValue read FOnAfterDrawSerieXValue write FOnAfterDrawSerieXValue;
    property OnAfterDrawSerieYValue: TTMSFNCChartAfterDrawSerieYValue read FOnAfterDrawSerieYValue write FOnAfterDrawSerieYValue;
    property OnBeforeDrawSerieXGridLine: TTMSFNCChartBeforeDrawSerieXGridLine read FOnBeforeDrawSerieXGridLine write FOnBeforeDrawSerieXGridLine;
    property OnBeforeDrawSerieYGridLine: TTMSFNCChartBeforeDrawSerieYGridLine read FOnBeforeDrawSerieYGridLine write FOnBeforeDrawSerieYGridLine;
    property OnAfterDrawSerieXGridLine: TTMSFNCChartAfterDrawSerieXGridLine read FOnAfterDrawSerieXGridLine write FOnAfterDrawSerieXGridLine;
    property OnAfterDrawSerieYGridLine: TTMSFNCChartAfterDrawSerieYGridLine read FOnAfterDrawSerieYGridLine write FOnAfterDrawSerieYGridLine;
    property OnGetSerieXValue: TTMSFNCChartGetSerieValue read FOnGetSerieXValue write FOnGetSerieXValue;
    property OnGetSerieYValue: TTMSFNCChartGetSerieValue read FOnGetSerieYValue write FOnGetSerieYValue;
    property OnSerieBarClick: TTMSFNCChartSerieClick read FOnSerieBarClick write FOnSerieBarClick;
    property OnSeriePointClick: TTMSFNCChartSerieClick read FOnSeriePointClick write FOnSeriePointClick;
    property OnSerieSliceClick: TTMSFNCChartSerieClick read FOnSerieSliceClick write FOnSerieSliceClick;
    property OnSerieBarClickVirtual: TTMSFNCChartSerieClickVirtual read FOnSerieBarClickVirtual write FOnSerieBarClickVirtual;
    property OnSerieLegendItemClick: TTMSFNCChartSerieLegendItemClick read FOnSerieLegendItemClick write FOnSerieLegendItemClick;
    property OnLegendItemClick: TTMSFNCChartLegendItemClick read FOnLegendItemClick write FOnLegendItemClick;
    property OnSeriePointClickVirtual: TTMSFNCChartSerieClickVirtual read FOnSeriePointClickVirtual write FOnSeriePointClickVirtual;
    property OnSerieSliceClickVirtual: TTMSFNCChartSerieClickVirtual read FOnSerieSliceClickVirtual write FOnSerieSliceClickVirtual;
    property OnCanSaveProperty: TTMSFNCCustomControlCanSavePropertyEvent read FOnCanSaveProperty write FOnCanSaveProperty;
    property OnCanLoadProperty: TTMSFNCCustomControlCanLoadPropertyEvent read FOnCanLoadProperty write FOnCanLoadProperty;
    property OnBeforeSetAllFonts: TTMSFNCChartBeforeSetAllFontsEvent read FOnBeforeSetAllFonts write FOnBeforeSetAllFonts;
    property OnJSONAddSeries: TTMSFNCChartAddJSONSerieEvent read FOnJSONAddSeries write FOnJSONAddSeries;
    property OnJSONAddPoint: TTMSFNCChartAddJSONPointEvent read FOnJSONAddPoint write FOnJSONAddPoint;
    property OnParseDateTimeStringToDateTime: TTMSFNCChartParseStringToDateTimeEvent read FOnParseDateTimeStringToDateTime write FOnParseDateTimeStringToDateTime;

    property OnBeforeDrawSerieYAxisCrosshairText: TTMSFNCChartBeforeDrawSerieYAxisCrosshairText read FOnBeforeDrawSerieYAxisCrosshairText write FOnBeforeDrawSerieYAxisCrosshairText;
    property OnAfterDrawSerieYAxisCrosshairText: TTMSFNCChartAfterDrawSerieYAxisCrosshairText read FOnAfterDrawSerieYAxisCrosshairText write FOnAfterDrawSerieYAxisCrosshairText;
    property OnBeforeDrawSerieXAxisCrosshairText: TTMSFNCChartBeforeDrawSerieXAxisCrosshairText read FOnBeforeDrawSerieXAxisCrosshairText write FOnBeforeDrawSerieXAxisCrosshairText;
    property OnAfterDrawSerieXAxisCrosshairText: TTMSFNCChartAfterDrawSerieXAxisCrosshairText read FOnAfterDrawSerieXAxisCrosshairText write FOnAfterDrawSerieXAxisCrosshairText;
    property OnBeforeDrawSerieCrosshairHorizontalLine: TTMSFNCChartBeforeDrawSerieCrosshairHorizontalLine read FOnBeforeDrawSerieCrosshairHorizontalLine write FOnBeforeDrawSerieCrosshairHorizontalLine;
    property OnAfterDrawSerieCrosshairHorizontalLine: TTMSFNCChartAfterDrawSerieCrosshairHorizontalLine read FOnAfterDrawSerieCrosshairHorizontalLine write FOnAfterDrawSerieCrosshairHorizontalLine;
    property OnBeforeDrawSerieCrosshairVerticalLine: TTMSFNCChartBeforeDrawSerieCrosshairVerticalLine read FOnBeforeDrawSerieCrosshairVerticalLine write FOnBeforeDrawSerieCrosshairVerticalLine;
    property OnAfterDrawSerieCrosshairVerticalLine: TTMSFNCChartAfterDrawSerieCrosshairVerticalLine read FOnAfterDrawSerieCrosshairVerticalLine write FOnAfterDrawSerieCrosshairVerticalLine;
    property OnGetSerieXAxisCrosshairText: TTMSFNCChartGetSerieXAxisCrosshairText read FOnGetSerieXAxisCrosshairText write FOnGetSerieXAxisCrosshairText;
    property OnGetSerieYAxisCrosshairText: TTMSFNCChartGetSerieYAxisCrosshairText read FOnGetSerieYAxisCrosshairText write FOnGetSerieYAxisCrosshairText;

    property Align;
    {$IFNDEF WEBLIB}
    property Anchors;
    property OnResize;
    {$ENDIF}
    property Cursor default crDefault;
    {$IFDEF FMXLIB}
    property Size;
    property OnPainting;
    property OnPaint;
    property CanParentFocus;
    property ClipChildren default False;
    property ClipParent default False;
    property DisableFocusEffect default True;
    property EnableDragHighlight default True;
    property Locked default False;
    property HitTest default True;
    property Opacity;
    property Position;
    property RotationAngle;
    property RotationCenter;
    property Scale;
    property OnApplyStyleLookup;
    property OnDragEnter;
    property OnDragLeave;
    property OnDragEnd;
    property OnCanFocus;
    {$ENDIF}
    {$IFNDEF WEBLIB}
    property DragMode default TDragMode.dmManual;
    {$ENDIF}
    property Enabled default True;
    property Height;
    {$IFDEF LCLLIB}
    property BorderSpacing;
    {$ENDIF}
    {$IFDEF VCLLIB}
    property Margins;
    property Padding;
    {$ENDIF}
    property TabOrder;
    property Visible default True;
    property Width;
    {$IFNDEF WEBLIB}
    property PopupMenu;
    property OnDragOver;
    property OnDragDrop;
    {$ENDIF}
    property OnKeyDown;
    property OnKeyUp;
    property OnClick;
    property OnDblClick;
    property OnEnter;
    property OnExit;
    property OnMouseDown;
    property OnMouseMove;
    property OnMouseUp;
    property OnMouseWheel;
    property OnMouseEnter;
    property OnMouseLeave;
  end;

  {$IFDEF CMNWEBLIB}
  TTMSFNCChartBaseComponent = class(TCustomControl)
  {$ENDIF}
  {$IFDEF FMXLIB}
  TTMSFNCChartBaseComponent = class(TControl)
  {$ENDIF}
  private
  protected
  public
    constructor Create(AOwner: TComponent); override;
    {$IFNDEF WEBLIB}
    procedure Paint; override;
    {$ENDIF}
  published
    {$IFDEF FMXLIB}
    property Size;
    property Position;
    {$ENDIF}
    property Visible default False;
    property Width;
    property Height;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCLineChart = class(TTMSFNCChart)
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCDigitalLineChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCAreaChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCStackedAreaChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCStackedPercentageAreaChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCBarChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCStackedBarChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCStackedPercentageBarChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCMarkerChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCXYLineChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCXYMarkerChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCPieChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCDonutChart = class(TTMSFNCPieChart)
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCVariableRadiusPieChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCSizedPieChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCSpiderChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCBandChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCOHLCChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCCandleStickChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCBoxPlotChart = class(TTMSFNCChart)
  protected
    function GetDefaultChartType: TTMSFNCChartSerieType; override;
  public
    procedure InitSample; override;
  end;

procedure DrawChartIcon(AGraphics: TTMSFNCGraphics; AChartType: TTMSFNCChartSerieType; r: TRectF);
function GetDefaultVirtualPoint: TTMSFNCChartPointVirtual;
function GetDefaultVirtualAnnotation: TTMSFNCChartAnnotationVirtual;
function ConvertToLog(ABase: Integer; Value: Double): Double;

implementation

uses
  WEBLib.TMSFNCUtils, WEBLib.Forms
{$IFDEF VCLLIB}
  ,PNGImage
{$ENDIF}
{$IFDEF LCLLIB}
  ,LCLIntf
{$ENDIF}
  ;

//{$R 'TMSFNCChart.res'}

{$IFDEF LCLLIB}
class operator TTMSFNCChartAnnotationVirtual.=(z1, z2: TTMSFNCChartAnnotationVirtual)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCChartPointVirtual.=(z1, z2: TTMSFNCChartPointVirtual)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCChartDrawLine.=(z1, z2: TTMSFNCChartDrawLine)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCChartDrawPoint.=(z1, z2: TTMSFNCChartDrawPoint)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCChartDrawMultiPoint.=(z1, z2: TTMSFNCChartDrawMultiPoint)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCChartDrawRect.=(z1, z2: TTMSFNCChartDrawRect)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCChartDrawSlice.=(z1, z2: TTMSFNCChartDrawSlice)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCChartDrawXYValue.=(z1, z2: TTMSFNCChartDrawXYValue)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCChartDrawXYGridLine.=(z1, z2: TTMSFNCChartDrawXYGridLine)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCChartDrawLabel.=(z1, z2: TTMSFNCChartDrawLabel)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCChartDrawAnnotation.=(z1, z2: TTMSFNCChartDrawAnnotation)b: boolean;
begin
  Result := z1 = z2;
end;
{$ENDIF}

function GetDefaultDrawPoint: TTMSFNCChartDrawPoint;
begin
  Result.Point := PointF(0, 0);
  Result.Reference := nil;
  Result.VirtualReference := GetDefaultVirtualPoint;
  Result.DoAnimation := False;
end;

function GetDefaultDrawRect: TTMSFNCChartDrawRect;
begin
  Result.Rect := RectF(0, 0, 0, 0);
  Result.StackedValue := 0;
  Result.Reference := nil;
  Result.VirtualReference := GetDefaultVirtualPoint;
  Result.DoAnimation := False;
end;

function GetDefaultDrawLine: TTMSFNCChartDrawLine;
begin
  Result.StackedValueStart := 0;
  Result.StackedValueEnd := 0;
  Result.StartPoint := PointF(0, 0);
  Result.EndPoint := PointF(0, 0);
  Result.StartReference := nil;
  Result.EndReference := nil;
  Result.StartVirtualReference := GetDefaultVirtualPoint;
  Result.EndVirtualReference := GetDefaultVirtualPoint;
  Result.Bottom := False;
  Result.DoAnimation := False;
end;

function GetDefaultVirtualAnnotation: TTMSFNCChartAnnotationVirtual;
begin
  Result.Index := -1;
  Result.AutoSize := True;
  Result.LineOpacity := 1;
  Result.ArrowOpacity := 1;
  Result.Width := 75;
  Result.Height := 35;
  Result.LineWidth := 1;
  Result.LineColor := gcBlack;
  Result.ArrowColor := gcBlack;
  Result.ArrowSize := 10;
  Result.Visible := True;
  Result.OffsetX := 0;
  Result.OffsetY := -10;
  Result.TextVerticalAlignment := gtaCenter;
  Result.TextHorizontalAlignment := gtaCenter;
  Result.Arrow := arLine;
  Result.FontColor := gcBlack;
  Result.Shape := asRectangle;
  Result.WordWrap := True;
end;

function GetDefaultVirtualPoint: TTMSFNCChartPointVirtual;
begin
  Result.Index := -1;
  Result.Undefined := False;
  Result.Color := gcNull;
  Result.Tag := 0;
  Result.XValue := 0;
  Result.YValue := 0;
  Result.YValueHigh := 0;
  Result.YValueClose := 0;
  Result.YValueOpen := 0;
  Result.YValueMedian := 0;
  Result.YValueLow := 0;
  Result.YValueSecond := 0;
  Result.XValueText := '';
  Result.YValueVariable := 0;
  Result.LegendText := '';
  Result.Explode := 0;
  Result.Point := nil;
end;

function GetPieQuadrant(ACenter: TPointF; APoint: TPointF): TTMSFNCChartPieQuadrant;
begin
  if (ACenter.X <= APoint.X) and (ACenter.Y >= APoint.Y) then
    Result := cpq1
  else if (ACenter.X < APoint.X) and (ACenter.Y < APoint.Y) then
    Result := cpq4
  else if (ACenter.X >= APoint.X) and (ACenter.Y >= APoint.Y) then
    Result := cpq2
  else
    Result := cpq3;
end;

function StrokeDarker(AColor: TTMSFNCGraphicsColor; APercent: Byte): TTMSFNCGraphicsColor;
var
  r, g, b: Byte;
begin
  {$IFDEF CMNWEBLIB}
  AColor := ColorToRGB(AColor);
  r := GetRValue(AColor);
  g := GetGValue(AColor);
  b := GetBValue(AColor);
  {$ENDIF}
  {$IFDEF FMXLIB}
  r := TAlphaColorRec(AColor).R;
  g := TAlphaColorRec(AColor).G;
  b := TAlphaColorRec(AColor).B;
  {$ENDIF}
  r := Max(0, Round(r / 100 * APercent));
  g := Max(0, Round(g / 100 * APercent));
  b := Max(0, Round(b / 100 * APercent));

  {$IFDEF FMXLIB}
  Result := MakeGraphicsColor(r, g, b, TAlphaColorRec(AColor).A);
  {$ENDIF}

  {$IFDEF CMNWEBLIB}
  Result := MakeGraphicsColor(r, g, b);
  {$ENDIF}
end;

procedure DrawChartIcon(AGraphics: TTMSFNCGraphics; AChartType: TTMSFNCChartSerieType; r: TRectF);
var
  rm, rb: TRectF;
  mw, mh: Double;
  pth: TTMSFNCGraphicsPath;
begin
  rm := r;
  case AChartType of
    ctXYLine:
    begin
      AGraphics.DrawLine(PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top) / 4*2), PointF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 3));
      AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 3), PointF(rm.Left + (rm.Right - rm.Left) / 2, rm.Top));
      AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 2, rm.Top), PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top + (rm.Bottom - rm.Top) / 5));
      AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top + (rm.Bottom - rm.Top) / 5), PointF(rm.Right, rm.Bottom));
    end;
    ctLine:
    begin
      AGraphics.DrawLine(PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top) / 3*2), PointF(rm.Left + (rm.Right - rm.Left) / 5, rm.Top + (rm.Bottom - rm.Top) / 3));
      AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 5, rm.Top + (rm.Bottom - rm.Top) / 3), PointF(rm.Left + (rm.Right - rm.Left) / 5 * 2, rm.Bottom));
      AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 2, rm.Bottom), PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top + (rm.Bottom - rm.Top) / 5));
      AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top + (rm.Bottom - rm.Top) / 5), PointF(rm.Right, rm.Bottom));
    end;
    ctDigitalLine:
    begin
      AGraphics.DrawLine(PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top)), PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top) / 4));
      AGraphics.DrawLine(PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top) / 4), PointF(rm.Left + (rm.Right - rm.Left) / 2, rm.Top + (rm.Bottom - rm.Top) / 4));
      AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 2, rm.Top + (rm.Bottom - rm.Top) / 4), PointF(rm.Left + (rm.Right - rm.Left) / 2, rm.Top + (rm.Bottom - rm.Top) / 3 * 2));
      AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 2, rm.Top + (rm.Bottom - rm.Top) / 3 * 2), PointF(rm.Left + (rm.Right - rm.Left), rm.Top + (rm.Bottom - rm.Top) / 3 * 2));
      AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left), rm.Top + (rm.Bottom - rm.Top) / 3 * 2), PointF(rm.Right, rm.Bottom));
    end;
    ctXYMarker:
    begin
      mw := (rm.Right - rm.Left) / 4;
      mh := (rm.Bottom - rm.Top) / 4;
      AGraphics.DrawEllipse(RectF(rm.Left, rm.Top, rm.Left + mw, rm.Top + mh));
      AGraphics.DrawEllipse(RectF(CenterPointEx(rm).X, CenterPointEx(rm).Y, CenterPointEx(rm).X - mw, CenterPointEx(rm).Y + mh));
      AGraphics.DrawEllipse(RectF(CenterPointEx(rm).X, CenterPointEx(rm).Y, CenterPointEx(rm).X + mw, CenterPointEx(rm).Y + mh));
      AGraphics.DrawEllipse(RectF(rm.Right - mw, rm.Top + (rm.Bottom - rm.Top), rm.Right, rm.Top + (rm.Bottom - rm.Top) - mh));
    end;
    ctMarker:
    begin
      mw := (rm.Right - rm.Left) / 4;
      mh := (rm.Bottom - rm.Top) / 4;
      AGraphics.DrawEllipse(RectF(rm.Left, rm.Top, rm.Left + mw, rm.Top + mh));
      AGraphics.DrawEllipse(RectF(CenterPointEx(rm).X - mw * 1.5, CenterPointEx(rm).Y, CenterPointEx(rm).X - mw * 0.5, CenterPointEx(rm).Y + mh));
      AGraphics.DrawEllipse(RectF(CenterPointEx(rm).X, CenterPointEx(rm).Y + mh, CenterPointEx(rm).X + mw, CenterPointEx(rm).Y + mh * 2));
      AGraphics.DrawEllipse(RectF(rm.Right - mw, rm.Top + mh / 2, rm.Right, rm.Top + mh * 1.5));
    end;
    ctStackedPercentageArea:
    begin
      pth := TTMSFNCGraphicsPath.Create;
      pth.MoveTo(PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top)));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 2));
      pth.LineTo(CenterPointEx(rm));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top + (rm.Bottom - rm.Top) / 6));
      pth.LineTo(PointF(rm.Right, rm.Bottom));
      pth.ClosePath;
      AGraphics.DrawPath(pth);
      pth.Clear;
      pth.MoveTo(PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top)));
      pth.LineTo(PointF(rm.Left, rm.Top));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top));
      pth.LineTo(PointF(CenterPointEx(rm).X, rm.Top));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top));
      pth.LineTo(PointF(PointF(rm.Right, rm.Bottom).X, rm.Top));
      pth.LineTo(PointF(rm.Right, rm.Bottom));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top + (rm.Bottom - rm.Top) / 6));
      pth.LineTo(CenterPointEx(rm));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 2));
      pth.ClosePath;
      AGraphics.Fill.Opacity := 0.5;
      AGraphics.DrawPath(pth);
      pth.Free;
    end;
    ctStackedArea:
    begin
      pth := TTMSFNCGraphicsPath.Create;
      pth.MoveTo(PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top)));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 2));
      pth.LineTo(CenterPointEx(rm));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top + (rm.Bottom - rm.Top) / 6));
      pth.LineTo(PointF(rm.Right, rm.Bottom));
      pth.ClosePath;
      AGraphics.DrawPath(pth);
      pth.Clear;
      pth.MoveTo(PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top)));
      pth.LineTo(PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top) / 4));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 5));
      pth.LineTo(PointF(CenterPointEx(rm).X, rm.Top + (rm.Bottom - rm.Top) / 3));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top));
      pth.LineTo(PointF(PointF(rm.Right, rm.Bottom).X, rm.Top + (rm.Bottom - rm.Top) / 3));
      pth.LineTo(PointF(rm.Right, rm.Bottom));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top + (rm.Bottom - rm.Top) / 6));
      pth.LineTo(CenterPointEx(rm));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 2));
      pth.ClosePath;
      AGraphics.Fill.Opacity := 0.5;
      AGraphics.DrawPath(pth);
      pth.Free;
    end;
    ctBand:
    begin
      pth := TTMSFNCGraphicsPath.Create;
      pth.MoveTo(PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top)));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 5, rm.Top + (rm.Bottom - rm.Top) / 3));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 2, rm.Top + (rm.Bottom - rm.Top) / 3));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top + (rm.Bottom - rm.Top) / 3));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top + (rm.Bottom - rm.Top) / 5 * 4));
      pth.ClosePath;
      AGraphics.DrawPath(pth);
      pth.Free;
    end;
    ctArea:
    begin
      pth := TTMSFNCGraphicsPath.Create;
      pth.MoveTo(PointF(rm.Left, rm.Top + (rm.Bottom - rm.Top)));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top));
      pth.LineTo(CenterPointEx(rm));
      pth.LineTo(PointF(rm.Left + (rm.Right - rm.Left) / 5 * 4, rm.Top + (rm.Bottom - rm.Top) / 3));
      pth.LineTo(PointF(rm.Right, rm.Bottom));
      pth.ClosePath;
      AGraphics.DrawPath(pth);
      pth.Free;
    end;
    ctStackedPercentageBar:
    begin
      rb := RectF(rm.Left, rm.Top + (rm.Bottom - rm.Top) / 3 * 2, rm.Left + (rm.Right - rm.Left) / 4, rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 6 * 5, rm.Left + (rm.Right - rm.Left) / 4 * 2, rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4 * 2, rm.Top + (rm.Bottom - rm.Top) / 4 * 3, rm.Left + (rm.Right - rm.Left) / 4 * 3, rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4 * 3, rm.Top + (rm.Bottom - rm.Top) / 2, rm.Left + (rm.Right - rm.Left), rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      AGraphics.Fill.Opacity := 0.5;
      rb := RectF(rm.Left, rm.Top, rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 3 * 2);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top, rm.Left + (rm.Right - rm.Left) / 4 * 2, rm.Top + (rm.Bottom - rm.Top) / 6 * 5);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4 * 2, rm.Top, rm.Left + (rm.Right - rm.Left) / 4 * 3, rm.Top + (rm.Bottom - rm.Top) / 4 * 3);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4 * 3, rm.Top, rm.Left + (rm.Right - rm.Left), rm.Top + (rm.Bottom - rm.Top) / 2);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
    end;
    ctVariableRadiusPie, ctSizedPie:
    begin
      pth := TTMSFNCGraphicsPath.Create;
      pth.MoveTo(CenterPointEx(rm));
      pth.AddArc(CenterPointEx(rm), PointF((rm.Bottom - rm.Top) / 2, (rm.Bottom - rm.Top) / 2), 0, 75);
      pth.LineTo(CenterPointEx(rm));
      pth.ClosePath;
      AGraphics.DrawPath(pth);

      pth.Clear;
      pth.MoveTo(CenterPointEx(rm));
      pth.AddArc(CenterPointEx(rm), PointF((rm.Bottom - rm.Top) / 3, (rm.Bottom - rm.Top) / 3), 75, 90);
      pth.LineTo(CenterPointEx(rm));
      pth.ClosePath;
      AGraphics.DrawPath(pth);

      pth.Clear;
      pth.MoveTo(CenterPointEx(rm));
      pth.AddArc(CenterPointEx(rm), PointF((rm.Bottom - rm.Top) / 2, (rm.Bottom - rm.Top) / 2), 165, 90);
      pth.LineTo(CenterPointEx(rm));
      pth.ClosePath;
      AGraphics.DrawPath(pth);
      pth.Free;
    end;
    ctSpider:
    begin
      pth := TTMSFNCGraphicsPath.Create;
      pth.MoveTo(PointF(CenterPointEx(rm).X + (rm.Bottom - rm.Top) / 3, CenterPointEx(rm).Y));
      pth.LineTo(PointF(CenterPointEx(rm).X + (rm.Bottom - rm.Top) / 4, CenterPointEx(rm).Y - (rm.Bottom - rm.Top) / 2));
      pth.LineTo(PointF(CenterPointEx(rm).X, CenterPointEx(rm).Y - (rm.Bottom - rm.Top) / 3));
      pth.LineTo(PointF(CenterPointEx(rm).X - (rm.Bottom - rm.Top) / 2, CenterPointEx(rm).Y + (rm.Bottom - rm.Top) / 2));
      pth.LineTo(PointF(CenterPointEx(rm).X, CenterPointEx(rm).Y + (rm.Bottom - rm.Top) / 3));
      pth.LineTo(PointF(CenterPointEx(rm).X + (rm.Bottom - rm.Top) / 2, CenterPointEx(rm).Y + (rm.Bottom - rm.Top) / 3));
      pth.ClosePath;
      AGraphics.DrawPath(pth);
      pth.Free;
    end;
    ctPie:
    begin
      pth := TTMSFNCGraphicsPath.Create;
      pth.MoveTo(CenterPointEx(rm));
      pth.AddArc(CenterPointEx(rm), PointF((rm.Bottom - rm.Top) / 2, (rm.Bottom - rm.Top) / 2), 0, 235);
      pth.LineTo(CenterPointEx(rm));
      pth.ClosePath;
      AGraphics.DrawPath(pth);
      pth.Free;
    end;
    ctStackedBar:
    begin
      rb := RectF(rm.Left, rm.Top + (rm.Bottom - rm.Top) / 3 * 2, rm.Left + (rm.Right - rm.Left) / 4, rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 6 * 5, rm.Left + (rm.Right - rm.Left) / 4 * 2, rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4 * 2, rm.Top + (rm.Bottom - rm.Top) / 4 * 3, rm.Left + (rm.Right - rm.Left) / 4 * 3, rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4 * 3, rm.Top + (rm.Bottom - rm.Top) / 2, rm.Left + (rm.Right - rm.Left), rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      AGraphics.Fill.Opacity := 0.5;
      rb := RectF(rm.Left, rm.Top + (rm.Bottom - rm.Top) / 3, rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 3 * 2);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 5, rm.Left + (rm.Right - rm.Left) / 4 * 2, rm.Top + (rm.Bottom - rm.Top) / 6 * 5);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4 * 2, rm.Top + (rm.Bottom - rm.Top) / 4, rm.Left + (rm.Right - rm.Left) / 4 * 3, rm.Top + (rm.Bottom - rm.Top) / 4 * 3);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4 * 3, rm.Top + (rm.Bottom - rm.Top) / 5, rm.Left + (rm.Right - rm.Left), rm.Top + (rm.Bottom - rm.Top) / 2);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
    end;
    ctOHLC, ctCandleStick, ctBoxPlot:
    begin
      AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 2, rm.Top + (rm.Bottom - rm.Top) / 6), PointF(rm.Left + (rm.Right - rm.Left) / 2, rm.Top + (rm.Bottom - rm.Top) / 6 * 5));
      case AChartType of
        ctOHLC:
        begin
          AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 2 - (rm.Right - rm.Left) / 3, rm.Top + (rm.Bottom - rm.Top) / 3), PointF(rm.Left + (rm.Right - rm.Left) / 2, rm.Top + (rm.Bottom - rm.Top) / 3));
          AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 2 + (rm.Right - rm.Left) / 3, rm.Top + (rm.Bottom - rm.Top) / 5 * 3), PointF(rm.Left + (rm.Right - rm.Left) / 2, rm.Top + (rm.Bottom - rm.Top) / 5 * 3));
        end;
        ctCandleStick, ctBoxPlot:
        begin
          AGraphics.DrawRectangle(RectF(rm.Left + (rm.Right - rm.Left) / 2 - (rm.Right - rm.Left) / 3, rm.Top + (rm.Bottom - rm.Top) / 3, rm.Left + (rm.Right - rm.Left) / 2 + (rm.Right - rm.Left) / 3, rm.Top + (rm.Bottom - rm.Top) / 5 * 3), gcrmExpandAll);
          case AChartType of
            ctBoxPlot:
            begin
              AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 2 - (rm.Right - rm.Left) / 3, rm.Top + (rm.Bottom - rm.Top) / 3), PointF(rm.Left + (rm.Right - rm.Left) / 2 + (rm.Right - rm.Left) / 3, rm.Top + (rm.Bottom - rm.Top) / 3));
              AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 2 - (rm.Right - rm.Left) / 3, rm.Top + (rm.Bottom - rm.Top) / 2), PointF(rm.Left + (rm.Right - rm.Left) / 2 + (rm.Right - rm.Left) / 3, rm.Top + (rm.Bottom - rm.Top) / 2));
              AGraphics.DrawLine(PointF(rm.Left + (rm.Right - rm.Left) / 2 - (rm.Right - rm.Left) / 3, rm.Top + (rm.Bottom - rm.Top) / 5 * 3), PointF(rm.Left + (rm.Right - rm.Left) / 2 + (rm.Right - rm.Left) / 3, rm.Top + (rm.Bottom - rm.Top) / 5 * 3));
            end;
          end;
        end;
      end;
    end;
    ctBar:
    begin
      rb := RectF(rm.Left, rm.Top + (rm.Bottom - rm.Top) / 4, rm.Left + (rm.Right - rm.Left) / 4, rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4, rm.Top + (rm.Bottom - rm.Top) / 5 * 4, rm.Left + (rm.Right - rm.Left) / 4 * 2, rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4 * 2, rm.Top + (rm.Bottom - rm.Top) / 2, rm.Left + (rm.Right - rm.Left) / 4 * 3, rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
      rb := RectF(rm.Left + (rm.Right - rm.Left) / 4 * 3, rm.Top + (rm.Bottom - rm.Top) / 4, rm.Left + (rm.Right - rm.Left), rm.Bottom);
      AGraphics.DrawRectangle(rb, gcrmExpandAll);
    end;
  end;
end;

{$IFDEF FREEWARE}
function Scramble(s:string): string;
var
  r:string;
  i: integer;
  c: char;
  b: byte;
begin
  r := '';
  {$IFDEF DELPHI_LLVM}
  for i := 0 to length(s) - 1 do
  {$ELSE}
  for i := 1 to length(s) do
  {$ENDIF}
  begin
    b := ord(s[i]);
    b := (b and $E0) + ((b and $1F) xor 5);
    c := chr(b);
    r := r + c;
  end;
  Result := r;
end;
{$ENDIF}

function MinMaxToUnit(min, max, maxlabels: Double): Double;
var
  i: integer;
  delta, d, r: Double;
begin
  i := 0;
  r := 0;

  delta := max - min;

  if (delta > 0) and (maxlabels > 0) then
  begin

    while abs(delta) < 1 do
    begin
      delta := delta * 10;
      inc(i);
    end;

    while abs(delta) > 10 do
    begin
      delta := delta / 10;
      dec(i);
    end;

    d := (delta) / maxlabels;

    if (d <= 0.1) then
      r := 0.1;

    if (d > 0.1) and (d <= 0.2) then
      r := 0.2;

    if (d > 0.2) and (d <= 0.5) then
      r := 0.5;

    if (d > 0.5) then
      r := 1;

    // denormalize unit
    if i < 0 then
    begin
      while i < 0 do
      begin
        r := r * 10;
        inc(i);
      end;
    end
    else
      while i > 0 do
      begin
        r := r / 10;
        dec(i);
      end;

    Result := r;
  end
  else
  begin
    r := 0;
    result := r;
  end;
end;

function AnimateDouble(var Start, Stop: Double; Delta: Double; Margin: Double): Boolean;
begin
  Result := true;
  if (Start > Stop - Margin) and (Start < Stop + Margin) then
  begin
    Start := Stop;
    Result := false;
  end
  else
  begin
    Delta := Max(Margin, Delta);
    if Start < Stop then
      Start := Start + Delta
    else
      Start := Start - Delta;
  end;
end;

{ TTMSFNCChartMargins }

procedure TTMSFNCChartMargins.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartMargins then
  begin
    FLeft := (Source as TTMSFNCChartMargins).Left;
    FTop := (Source as TTMSFNCChartMargins).Top;
    FRight := (Source as TTMSFNCChartMargins).Right;
    FBottom := (Source as TTMSFNCChartMargins).Bottom;
  end;
end;

procedure TTMSFNCChartMargins.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCChartMargins.Create;
begin
  FLeft := 0;
  FBottom := 0;
  FRight := 0;
  FTop := 0;
end;

procedure TTMSFNCChartMargins.SetBottom(const Value: integer);
begin
  if FBottom <> Value then
  begin
    FBottom := Value;
    Changed;
  end;
end;

procedure TTMSFNCChartMargins.SetLeft(const Value: integer);
begin
  if FLeft <> Value then
  begin
    FLeft := Value;
    Changed;
  end;
end;

procedure TTMSFNCChartMargins.SetRight(const Value: integer);
begin
  if FRight <> Value then
  begin
    FRight := Value;
    Changed;
  end;
end;

procedure TTMSFNCChartMargins.SetTop(const Value: integer);
begin
  if FTop <> Value then
  begin
    FTop := Value;
    Changed;
  end;
end;

{ TTMSFNCChart }

function TTMSFNCChart.AddBandSerie(AStartX, AEndX: integer; AStartValue1,
  AStartValue2, AEndValue1, AEndValue2: Double; AUseCommonYRange: Boolean;
  ASerieLineColor: TTMSFNCGraphicsColor; ASerieLineWidth: integer;
  ASerieBandColor: TTMSFNCGraphicsColor): TTMSFNCChartSerie;
begin
  Result := InternalAddBandSerie(-1, AStartX, AEndX, AStartValue1, AStartValue2,
    AEndValue1, AEndValue2, AUseCommonYRange, ASerieLineColor, ASerieLineWidth, ASerieBandColor);
end;

function TTMSFNCChart.InternalAddBandSerie(ASerieIndex: Integer; AStartX, AEndX: integer; AStartValue1,
  AStartValue2, AEndValue1, AEndValue2: Double; AUseCommonYRange: Boolean;
  ASerieLineColor: TTMSFNCGraphicsColor; ASerieLineWidth: integer;
  ASerieBandColor: TTMSFNCGraphicsColor): TTMSFNCChartSerie;
var
  d1,d2: Double;
  f, t: integer;
  I, K: integer;
begin
  Result := nil;
  if AStartX > AEndX then
  begin
    t := AStartX;
    f := AEndX;
  end
  else
  begin
    t := AEndX;
    f := AStartX;
  end;

  if t - f > 0 then
  begin
    BeginUpdate;
    d1 := (AEndValue1 - AStartValue1) / (t - f);
    d2 := (AEndValue2 - AStartValue2) / (t - f);

    Result := Series.Add;
    Result.YValues.Positions := [];
    Result.XValues.Positions := [];
    Result.ChartType := ctBand;
    Result.Stroke.Color := ASerieLineColor;
    Result.Stroke.Width := ASerieLineWidth;
    Result.Fill.Color := ASerieBandColor;
    Result.ShowInLegend := False;

    K := 0;
    for I := f to t - 1 do
    begin
      if (ASerieIndex >= 0) and (ASerieIndex <= Series.Count - 1) then
        Result.AddSecondPoint(AStartValue1 + (K * d1), AStartValue2 + (K * d2)).XValue := Series[ASerieIndex].GetPoint(I).XValue
      else
        Result.AddSecondPoint(AStartValue1 + (K * d1), AStartValue2 + (K * d2)).XValue := I;
      Inc(K);
    end;

    for I := 0 to Series.Count - 1 do
    begin
      if AUseCommonYRange then
        Series[I].AutoYRange := arCommon;

      Series[I].AutoXRange := arCommon;
    end;

    EndUpdate;
  end;
end;

function TTMSFNCChart.AddLineSerie(AStartX, AEndX: Integer; AStartValue,
  AEndValue: Double; AUseCommonYRange: Boolean; ASerieLineColor: TTMSFNCGraphicsColor;
  ASerieLineWidth: Integer): TTMSFNCChartSerie;
begin
  Result := InternalAddLineSerie(-1, AStartX, AEndX, AStartValue, AEndValue, AUseCommonYRange, ASerieLineColor,
    ASerieLineWidth);
end;

function TTMSFNCChart.InternalAddLineSerie(ASerieIndex: Integer; AStartX, AEndX: Integer; AStartValue,
  AEndValue: Double; AUseCommonYRange: Boolean; ASerieLineColor: TTMSFNCGraphicsColor;
  ASerieLineWidth: Integer): TTMSFNCChartSerie;
var
  d: Double;
  f, t: integer;
  I, K: integer;
begin
  Result := nil;
  BeginUpdate;

  if AStartX > AEndX then
  begin
    t := AStartX;
    f := AEndX;
  end
  else
  begin
    t := AEndX;
    f := AStartX;
  end;

  if t - f > 0 then
  begin
    d := (AEndValue - AStartValue) / (t - f);
    Result := Series.Add;

    Result.YValues.Positions := [];
    Result.XValues.Positions := [];
    Result.ChartType := ctXYLine;
    Result.Stroke.Color := ASerieLineColor;
    Result.Stroke.Width := ASerieLineWidth;
    Result.ShowInLegend := False;

    K := 0;
    for I := f to t do
    begin
      if (ASerieIndex >= 0) and (ASerieIndex <= Series.Count - 1) then
        Result.AddXYPoint(Series[0].GetPoint(I).XValue, AStartValue + (k * d))
      else
        Result.AddXYPoint(I, AStartValue + (k * d));

      Inc(K);
    end;

    for I := 0 to Series.Count - 1 do
    begin
      if AUseCommonYRange then
        Series[I].AutoYRange := arCommon;

      Series[I].AutoXRange := arCommon;
    end;
  end;

  EndUpdate;
end;

function TTMSFNCChart.AddMovingAverage(ASerieIndex, AStartX, AEndX,
  AWindow: Integer; AUseCommonYRange: Boolean; ASerieLineColor: TTMSFNCGraphicsColor;
  ASerieLineWidth: Integer): TTMSFNCChartSerie;
var
  i: integer;
  startsum: double;
  res: double;
begin
  if AWindow <= 0 then
    raise SysUtils.Exception.Create('Moving average window size must be 1 or higher');

  BeginUpdate;

  startsum := 0;
  for i := AStartX to AStartX + AWindow - 1 do
  begin
    if not Series[ASerieIndex].GetPoint(i).Undefined then
      startsum := startsum + Series[ASerieIndex].GetPoint(i).YValue;
  end;

  Result := Series.Add;
  Result.YValues.Positions := [];
  Result.XValues.Positions := [];
  Result.ChartType := ctXYLine;
  Result.Stroke.Color := ASerieLineColor;
  Result.Stroke.Width := ASerieLineWidth;
  Result.ShowInLegend := False;

  for i := AStartX + AWindow to AEndX do
  begin
    res := startsum / Awindow;
    Result.AddXYPoint(Series[ASerieIndex].GetPoint(I).XValue, res);
    if i < AEndX then
    begin
      if not Series[ASerieIndex].GetPoint(i - AWindow).Undefined then
        startsum := startsum - Series[ASerieIndex].GetPoint(i - AWindow).YValue;

      if not Series[ASerieIndex].GetPoint(i).Undefined then
        startsum := startsum + Series[ASerieIndex].GetPoint(i).YValue;
    end;
  end;

  for I := 0 to Series.Count - 1 do
  begin
    if AUseCommonYRange then
      Series[I].AutoYRange := arCommon;

    Series[I].AutoXRange := arCommon;
  end;

  EndUpdate;
end;

function TTMSFNCChart.AddParetoLine(ASerieIndex, AStartx, AEndx: Integer;
  AUseCommonYRange: Boolean; ASerieLineColor: TTMSFNCGraphicsColor;
  ASerieLineWidth: Integer): TTMSFNCChartSerie;
var
  i: integer;
  sum, sumtotal: Double;
  f, t: integer;
begin
  if AStartx >= AEndx then
    raise SysUtils.Exception.Create('End point should be larger than start point');

  BeginUpdate;

  if AStartx > AEndx then
  begin
    t := AStartx;
    f := AEndx;
  end
  else
  begin
    t := AEndx;
    f := AStartx;
  end;

  Result := Series.Add;
  Result.YValues.Positions := [];
  Result.XValues.Positions := [];
  Result.ChartType := ctLine;
  Result.Stroke.Color := ASerieLineColor;
  Result.Stroke.Width := ASerieLineWidth;
  Result.ShowInLegend := False;
  Result.Labels.Visible := True;
  Result.Labels.Format := '%.1f%%';

  sumtotal := 0;
  for I := f to t do
  begin
    if not Series[ASerieIndex].GetPoint(I).Undefined then
      sumtotal := sumtotal + Series[ASerieIndex].GetPoint(I).YValue;
  end;

  if sumtotal > 0 then
  begin
    sum := 0;
    for I := f to t do
    begin
      if not Series[ASerieIndex].GetPoint(I).Undefined then
        sum := sum + (Series[ASerieIndex].GetPoint(I).YValue / sumtotal * 100);

      Result.AddXYPoint(Series[ASerieIndex].GetPoint(I).XValue, sum)
    end;
  end;

  for I := 0 to Series.Count - 1 do
  begin
    if AUseCommonYRange then
      Series[I].AutoYRange := arCommon;

    Series[I].AutoXRange := arCommon;
  end;

  EndUpdate;
end;

function TTMSFNCChart.AddPointArray(APoints: array of Double; ASerieType: TTMSFNCChartSerieType = ctLine): TTMSFNCChartSerie;
var
  I: Integer;
begin
  BeginUpdate;
  Result := Series.Add;
  Result.ChartType := ASerieType;
  for I := 0 to Length(APoints) - 1 do
    Result.AddPoint(APoints[I]);
  EndUpdate;
end;

procedure TTMSFNCChart.AddTrendChannel(ASerieIndex, AStartX, AEndX: Integer;
  AUseCommonYRange: Boolean; ASerieLineColor: TTMSFNCGraphicsColor;
  ASerieLineWidth: Integer);
var
  i: integer;
  count: integer;
  yAxisValuesSum : double;
  xAxisValuesSum : double;
  xxSum : double;
  xySum : double;
  slope: double;
  intercept: double;
  mean: double;
  dev: double;
begin
  if AStartX >= AEndX then
    raise SysUtils.Exception.Create('End point should be larger than start point');

  count := AEndX - AStartX;
  if count > 0 then
  begin
    BeginUpdate;
    yAxisValuesSum := 0;
    xAxisValuesSum := 0;
    xxSum := 0;
    xySum := 0;

    for i := AStartX to AEndX do
    begin
      if not Series[ASerieIndex].GetPoint(I).Undefined then
        yAxisValuesSum := yAxisValuesSum + Series[ASerieIndex].GetPoint(i).YValue;
    end;

    mean := yAxisValuesSum / count;
    dev := 0;

    for i := AStartX to AEndX do
    begin
      xAxisValuesSum := xAxisValuesSum + i;
      if not Series[ASerieIndex].GetPoint(I).Undefined then
        xySum := xysum + i * Series[ASerieIndex].GetPoint(i).YValue;

      xxSum := xxsum + i * i;

      if not Series[ASerieIndex].GetPoint(I).Undefined then
        dev := dev + sqr((mean - Series[ASerieIndex].GetPoint(i).YValue));
    end;

    dev := sqrt(dev / count);

    slope := (count * xySum - xAxisValuesSum * yAxisValuesSum) / ( (count * xxSum) - (xAxisValuesSum * yAxisValuesSum));
    intercept := (yAxisValuesSum - slope * xAxisValuesSum) / count;

    InternalAddLineSerie(ASerieIndex, AStartX, AEndX, intercept + dev,count * slope + (intercept + dev), AUseCommonYRange, ASerieLineColor, ASerieLineWidth);
    InternalAddLineSerie(ASerieIndex, AStartX, AEndX, intercept - dev,count * slope + (intercept - dev), AUseCommonYRange, ASerieLineColor, ASerieLineWidth);
    EndUpdate;
  end;
end;

function TTMSFNCChart.AddTrendChannelBand(ASerieIndex, AStartX,
  AEndX: integer; AUseCommonYRange: Boolean; ASerieLineColor: TTMSFNCGraphicsColor;
  ASerieLineWidth: integer; ASerieBandColor: TTMSFNCGraphicsColor): TTMSFNCChartSerie;
var
  i: integer;
  count: integer;
  yAxisValuesSum : double;
  xAxisValuesSum : double;
  xxSum : double;
  xySum : double;
  slope: double;
  intercept: double;
  mean: double;
  dev: double;
begin
  Result := nil;
  if Astartx >= Aendx then
    raise SysUtils.Exception.Create('End point should be larger than start point');

  count := AEndX - AStartX;
  if count > 0 then
  begin
    BeginUpdate;

    yAxisValuesSum := 0;
    xAxisValuesSum := 0;
    xxSum := 0;
    xySum := 0;

    for i := AStartX to AEndX do
    begin
      if not Series[ASerieIndex].GetPoint(I).Undefined then
        yAxisValuesSum := yAxisValuesSum + Series[ASerieIndex].GetPoint(i).YValue;
    end;

    mean := yAxisValuesSum / count;
    dev := 0;

    for i := AStartX to AEndX do
    begin
      xAxisValuesSum := xAxisValuesSum + i;
      if not Series[ASerieIndex].GetPoint(I).Undefined then
        xySum := xysum + i * Series[ASerieIndex].GetPoint(i).YValue;

      xxSum := xxsum + i * i;

      if not Series[ASerieIndex].GetPoint(I).Undefined then
        dev := dev + sqr((mean - Series[ASerieIndex].GetPoint(i).YValue));
    end;

    dev := sqrt(dev / count);

    slope := (count * xySum - xAxisValuesSum * yAxisValuesSum) / ( (count * xxSum) - (xAxisValuesSum * yAxisValuesSum));
    intercept := (yAxisValuesSum - slope * xAxisValuesSum) / count;

    Result := InternalAddBandSerie(ASerieIndex, AStartX, AEndX, intercept - dev,intercept + dev,count * slope + (intercept - dev),count * slope + (intercept + dev), AUseCommonYRange, ASerieLineColor, ASerieLineWidth, ASerieBandColor);
    EndUpdate;
  end;
end;

function TTMSFNCChart.AddTrendLine(ASerieIndex, AStartX, AEndX: Integer;
  AUseCommonYRange: Boolean; ASerieLineColor: TTMSFNCGraphicsColor;
  ASerieLineWidth: Integer): TTMSFNCChartSerie;
var
  i: integer;
  count: integer;
  yAxisValuesSum : double;
  xAxisValuesSum : double;
  xxSum : double;
  xySum : double;
  slope: double;
  intercept: double;
begin
  Result := nil;
  if AStartX >= AEndX then
    raise SysUtils.Exception.Create('End point should be larger than start point');

  count := AEndX - AStartX + 1;
  if count > 0 then
  begin
    BeginUpdate;
    yAxisValuesSum := 0;
    xAxisValuesSum := 0;
    xxSum := 0;
    xySum := 0;

    for i := AStartX to AEndX do
    begin
      if not Series[ASerieIndex].GetPoint(I).Undefined then
        yAxisValuesSum := yAxisValuesSum + Series[ASerieIndex].GetPoint(i).YValue;

      xAxisValuesSum := xAxisValuesSum + I;

      if not Series[ASerieIndex].GetPoint(I).Undefined then
        xySum := xysum + i * Series[ASerieIndex].GetPoint(i).YValue;
      xxSum := xxsum + i * i;
    end;

    slope := (count * xySum - xAxisValuesSum * yAxisValuesSum) / ( (count *  xxSum) - (xAxisValuesSum * xAxisValuesSum));
    intercept := (yAxisValuesSum - slope * xAxisValuesSum) / count;

    Result := InternalAddLineSerie(ASerieIndex, AStartX, AEndX, AStartX * slope + intercept, AEndX * slope + intercept, AUseCommonYRange, ASerieLineColor, ASerieLineWidth);
    EndUpdate;
  end;
end;

procedure TTMSFNCChart.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChart then
  begin
    FInteractionOptions.Assign((Source as TTMSFNCChart).InteractionOptions);
    FSeries.AssignSource((Source as TTMSFNCChart).Series);
    FTitle.AssignSource((Source as TTMSFNCChart).Title);
    FXAxis.AssignSource((Source as TTMSFNCChart).XAxis);
    FYAxis.AssignSource((Source as TTMSFNCChart).YAxis);
    FLegend.AssignSource((Source as TTMSFNCChart).Legend);
    FCrosshair.AssignSource((Source as TTMSFNCChart).Crosshair);
    FSeriesMargins.Assign((Source as TTMSFNCChart).SeriesMargins);
    FFill.Assign((Source as TTMSFNCChart).Fill);
    FStroke.Assign((Source as TTMSFNCChart).Stroke);
    FInteraction := (Source as TTMSFNCChart).Interaction;
  end;
end;

procedure TTMSFNCChart.BeginUpdate;
var
  I: Integer;
begin
  {$IF DEFINED(FMXLIB) AND NOT (COMPILERVERSION = 28)}
  inherited BeginUpdate;
  {$ELSE}
  inherited;
  {$ENDIF}
  Inc(FUpdateCount);

  for I := 0 to Series.Count - 1 do
  begin
    Series[I].FSequential := True;
    Series[I].FPrevXValue := -MaxDouble;
  end;
end;

procedure TTMSFNCChart.Calculate3DOffset;
var
  I: Integer;
  s: TTMSFNCChartSerie;
begin
  if HasPieChart then
    Exit;

  FTotalOffset3DX := GetTotal3DOffsetX;
  FTotalOffset3DY := GetTotal3DOffsetY;
  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    s.F3DXOffset := s.Get3DOffsetX;
    s.F3DYOffset := s.Get3DOffsetY;
  end;
end;

procedure TTMSFNCChart.CalculateCommonRangeX;
var
  s: TTMSFNCChartSerie;
  I: Integer;
  pt: TTMSFNCChartPointVirtual;
  totminx, totmaxx: Double;
  J: Integer;
  l, e, m, cnt: Integer;
  fnd: Boolean;
begin
  if HasPieChart then
    Exit;

  totmaxx := -MaxDouble;
  totminx := MaxDouble;

  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      case Series[I].AutoXRange of
        arCommon:
        begin
          totmaxx := Max(totmaxx, s.FXMax);
          totminx := Min(totminx, s.FXMin);
        end;
        arCommonZeroBased:
        begin
          totmaxx := Max(totmaxx, s.FXMax);
        end;
      end;
    end;
  end;

  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      case s.AutoXRange of
        arCommon:
        begin
          s.FXMax := totmaxx;
          s.FXMin := totminx;
        end;
        arCommonZeroBased:
        begin
          s.FXMax := totmaxx;
        end;
      end;
    end;
  end;

  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    s.FStartX := 0;
    s.FEndX := s.GetPointsCount - 1;

    if s.Visible then
    begin
      if s.FSequential then
      begin
        l  := s.FStartX;
        e := s.FEndX;
        fnd  := False;

        while (l < e) do
        begin
          m := (l + e) div 2;

          if s.GetPoint(m).XValue = s.FXMin then
          begin
            fnd := True;
            l := e;
            s.FStartX := m;
          end
          else if (s.GetPoint(m).XValue > s.FXMin) or (s.GetPoint(m + 1).XValue > s.FXMin) then
            e := m - 1
          else
            l := m + 1;
        end;

        if not fnd then
          s.FStartX := l;

        s.FStartX := s.FStartX - 1;

        l  := s.FStartX;
        e := s.FEndX;
        fnd  := False;

        while (l < e) do
        begin
          m := (l + e) div 2;

          if s.GetPoint(m).XValue = s.FXMax then
          begin
            fnd  := True;
            l := e;
            s.FEndX := m;
          end
          else if (s.GetPoint(m).XValue > s.FXMax) and (s.GetPoint(m - 1).XValue > s.FXMax) then
            e := m - 1
          else
            l := m + 1;
        end;

        if not fnd then
          s.FEndX := l;

        s.FEndX := s.FEndX + 1;
      end
      else
      begin
        cnt := s.GetPointsCount;
        for J := 0 to cnt - 1 do
        begin
          pt := s.GetPoint(J);
          if s.FXMin >= pt.XValue then
            s.FStartX := J;
        end;
        for J := cnt - 1 downto 0 do
        begin
          pt := s.GetPoint(J);
          if s.FXMax <= pt.XValue then
            s.FEndX := J;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCChart.CalculateCommonRangeY;
var
  s, st: TTMSFNCChartSerie;
  I, J, K: Integer;
  totminy, totmaxy: Double;
  cmax, stmax: Double;
  sta, stp: Integer;
  offsymin, offsymax: Double;
begin
  if HasPieChart and not HasSpiderChart then
    Exit;

  totmaxy := -MaxDouble;
  totminy := MaxDouble;

  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      case s.AutoYRange of
        arCommonZeroBased, arCommon:
        begin
          if not s.IsStacked then
          begin
            totmaxy := Max(totmaxy, s.FYMax);
            if s.AutoYRange <> arCommonZeroBased then
              totminy := Min(totminy, s.FYMin);
          end;
        end;
      end;
    end;
  end;

  cmax := -MaxDouble;
  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible and s.IsStacked then
    begin
      case s.AutoYRange of
        arCommonZeroBased, arCommon:
        begin
          sta := s.GetStartCountPoint;
          stp := s.GetEndCountPoint;
          for J := sta to stp do
          begin
            stmax := 0;
            for K := 0 to Series.Count - 1 do
            begin
              st := Series[K];
              if st.Visible and st.IsStacked then
              begin
                if (st.AutoYRange in [arCommon, arCommonZeroBased]) then
                begin
                  if (st.GroupIndex = s.GroupIndex) and (J >= 0) and (J <= st.GetPointsCount - 1) then
                  begin
                    if not st.GetPoint(J).Undefined then
                      stMax := stMax + st.GetPoint(J).YValue;
                  end;
                end;
              end;
            end;

            if stmax > cmax then
              cmax := stmax;
          end;
        end;
      end;
    end;
  end;

  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible and s.IsStacked then
    begin
      case s.AutoYRange of
        arCommonZeroBased, arCommon:
        begin
          if (s.ChartType = ctStackedPercentageArea) or (s.ChartType = ctStackedPercentageBar) then
            s.FYMax := 100
          else
          begin
            if cmax = -MaxDouble then
              s.FYMax := 0
            else
              s.FYMax := cmax;
          end;

          s.FYMin := 0;

          offsymin := (s.FMinYOffsetPercentage * Abs(s.FYMax - s.FYMin)) / 100;
          offsymax := (s.FMaxYOffsetPercentage * Abs(s.FYMax - s.FYMin)) / 100;

          s.FYMin := s.FYMin - offsymin;
          s.FYMax := s.FYMax + offsymax;

          totmaxy := Max(totmaxy, s.FYMax);
          totminy := Min(totminy, s.FYMin);
        end;
      end;
    end;
  end;

  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      case s.AutoYRange of
        arCommon, arCommonZeroBased:
        begin
          s.FYMax := totmaxy;
          if s.AutoYRange <> arCommonZeroBased then
            s.FYMin := totminy;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCChart.CalculateSeries;
var
  I: Integer;
begin
  for I := 0 to Series.Count - 1 do
  begin
    if Series[I].Visible then
      Series[I].CalculateDrawPoints;
  end;

  for I := 0 to Series.Count - 1 do
  begin
    if Series[I].Visible then
      Series[I].CalculateAnimation;
  end;

  for I := 0 to Series.Count - 1 do
  begin
    if Series[I].Visible then
    begin
      Series[I].CalculateArea;
      Series[I].CalculateAnnotations;
      Series[I].CalculateLabels;
    end;
  end;
end;

procedure TTMSFNCChart.CalculateMinMaxY;
var
  I: Integer;
begin
  if HasPieChart and not HasSpiderChart then
    Exit;

  for I := 0 to Series.Count - 1 do
  begin
    if Series[I].Visible then
      Series[I].CalculateMinMaxY;
  end;
end;

procedure TTMSFNCChart.CalculateMinMaxX;
var
  I: Integer;
begin
  if HasPieChart then
    Exit;

  for I := 0 to Series.Count - 1 do
  begin
    if Series[I].Visible then
      Series[I].CalculateMinMaxX;
  end;
end;

procedure TTMSFNCChart.CalculateXAxisHeight;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  majth, minth, maxh: Double;
  str: String;
  mn, mx: Double;
  mu, mi: Extended;
  JVMax, JMax, JMin: Extended;
  domaj, domin: Boolean;
  domajv, dominv: Boolean;
  JMaxStr, JMinStr: String;
  J: Extended;
  th: Double;
  g: TTMSFNCGraphics;
  compareval: Boolean;
begin
  if HasPieChart then
    Exit;

  XAxis.FTopHeight := 0;
  XAxis.FCenterHeight := 0;
  XAxis.FBottomHeight := 0;

  g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, NativeCanvas);
  g.BeginScene;
  try
    for I := 0 to Series.Count - 1 do
    begin
      s := Series[I];
      if s.Visible and (s.XValues.Positions <> []) then
      begin
        maxh := 0;
        if s.XValues.Angle <> 0 then
        begin
          mn := s.FXMin;
          mx := s.FXMax;

          mu := s.XValues.MajorUnit;
          mi := s.XValues.MinorUnit;

          if s.LogarithmicX then
            mu := 1;

          JMax := mn;
          JMin := mn;

          domaj := (mu > 0);
          domin := (mi > 0);

          if domin then
            Jmin := Round(mn / mi)  * mi;

          if domaj then
            Jmax := Round(mn / mu)  * mu;

          if (domaj) then
          begin
            while (JMin <= mx + mu) do
            begin
              domajv := JMax <= mx + mu;
              if domajv then
              begin
                g.Font.AssignSource(s.XValues.MajorUnitFont);

                JVMax := Jmax;
                if s.LogarithmicX then
                  JVMax := Power(s.LogarithmicXBase, JMax);

                str := s.FindXAxisTextForValue(JVMax);
                if str = '' then
                begin
                  if s.XValues.MajorUnitFormat <> '' then
                  begin
                    case s.XValues.MajorUnitFormatType of
                      vftNormal: str := Format(s.XValues.MajorUnitFormat,[JVMax]);
                      vftFloat: str := FormatFloat(s.XValues.MajorUnitFormat,JVMax);
                      vftDateTime: str := FormatDateTime(s.XValues.MajorUnitFormat, JVMax);
                    end;
                  end
                  else
                    str := FloatToStr(JVMax);
                end;

                JMaxStr := str;
                DoGetSerieXValue(s, -1, vkMajor, JVMax, JMaxStr);
                th := g.CalculateTextWidth(JMaxStr);
                th := th + s.XValues.MajorUnitTickMarkSize + s.XValues.MajorUnitSpacing + 4;
                if th > maxh then
                  maxh := th;
              end;

              dominv := JMin <= mx + mu;
              if dominv and domin then
              begin
                while (CompareValueEx(JMin, JMax) <> EqualsValue) and (JMax > jmin) do
                begin
                  j := JMin;
                  if s.LogarithmicX then
                  begin
                    j := 1 - (JMax - JMin);
                    j := j * Power(s.LogarithmicXBase, JMax);
                  end;

                  g.Font.AssignSource(s.XValues.MinorUnitFont);
                  str := s.FindXAxisTextForValue(j);
                  if str = '' then
                  begin
                    if s.XValues.MinorUnitFormat <> '' then
                    begin
                      case s.XValues.MinorUnitFormatType of
                        vftNormal: str := Format(s.XValues.MinorUnitFormat,[j]);
                        vftFloat: str := FormatFloat(s.XValues.MinorUnitFormat, j);
                        vftDateTime: str := FormatDateTime(s.XValues.MinorUnitFormat, j);
                      end;
                    end
                    else
                      str := FloatToStr(j);
                  end;

                  compareval := false;
                  if s.LogarithmicX then
                    compareval := (CompareValueEx(ConvertToLog(s.LogarithmicXBase, j), JMax - 1, 0.1) = EqualsValue);

                  if (not s.LogarithmicX or (s.LogarithmicX and not compareval)) and (CompareValueEx(Jmin, JMax) <> EqualsValue) then
                  begin
                    JMinstr := str;
                    DoGetSerieXValue(s, -1, vkMinor, j, JMinStr);
                    th := g.CalculateTextWidth(JMinStr);
                    th := th + s.XValues.MinorUnitTickMarkSize + s.XValues.MinorUnitSpacing + 4;
                    if th > maxh then
                      maxh := th;
                  end;

                  JMin := JMin + mi;

                  if CompareValueEx(Jmin, Jmax) = 0 then
                    JMin := JMin + mi;
                end;
              end;

              if not domin then
              begin
                JMin := JMin + mu;
              end
              else
              begin
                if CompareValueEx(Jmin, Jmax) = 0 then
                begin
                  JMin := JMin + mi;
                end;
              end;

              Jmax := JMax + mu;
              if (JMax > mx + mu) then
                Break;
            end;
          end;
        end
        else
        begin
          g.Font.AssignSource(s.XValues.MajorUnitFont);
          majth := g.CalculateTextHeight('gh');
          majth := majth + s.XValues.MajorUnitTickMarkSize + s.XValues.MajorUnitSpacing + 4;
          minth := 0;
          if s.XValues.MinorUnit > 0 then
          begin
            g.Font.AssignSource(s.XValues.MinorUnitFont);
            minth := g.CalculateTextHeight('gh');
            minth := minth + s.XValues.MinorUnitTickMarkSize + s.XValues.MinorUnitSpacing + 4;
          end;
          maxh := max(majth, minth);
        end;

        if s.XValues.Title.Text <> '' then
        begin
          g.Font.AssignSource(s.XValues.Title.Font);
          maxh := maxh + g.CalculateTextHeight(s.XValues.Title.Text) + 4 + s.XValues.Title.TextMargins.Top + s.XValues.Title.TextMargins.Bottom
        end;

        if (xpTop in XAxis.Positions) and (xpTop in s.XValues.Positions) then
        begin
          XAxis.FTopHeight := XAxis.FTopHeight + maxh;
          s.FXAxisTopHeight := XAxis.FTopHeight;
        end;

        if (xpCenter in XAxis.Positions) and (xpCenter in s.XValues.Positions) then
        begin
          XAxis.FCenterHeight := XAxis.FCenterHeight + maxh;
          s.FXAxisCenterHeight := XAxis.FCenterHeight;
        end;

        if (xpBottom in XAxis.Positions) and (xpBottom in s.XValues.Positions) then
        begin
          XAxis.FBottomHeight := XAxis.FBottomHeight + maxh;
          s.FXAxisBottomHeight := XAxis.FBottomHeight;
        end;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

procedure TTMSFNCChart.CalculateXScale;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  c, w: Double;
  r: TRectF;
begin
  if HasPieChart then
    Exit;

  r := GetSeriesRectangle;
  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      w := r.Right - r.Left;
      s.FXScale := w;
      c := Abs(s.FXMax - s.FXMin);
      if c > 0 then
      begin
        if s.CalculateOffset then
        begin
          if c > 1 then
            s.FXScale := w / (c + 1)
          else
          begin
            if s.GetPointsCount > 1 then
              s.FXScale := w / (c + (s.GetPoint(1).XValue - s.GetPoint(0).XValue))
          end;
        end
        else
          s.FXScale := w / c;
      end;
    end;
  end;
end;

procedure TTMSFNCChart.CalculateXAxis;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  va: Double;
  r: TRectF;
  str: string;
  mn, mx: Double;
  htpane: Double;
  httext: Double;
  mu, mi: Extended;
  JVMax, JMax, JMin: Extended;
  domaj, domin, domajv, dominv: Boolean;
  JMaxStr, JMinStr: String;
  j: Extended;
  dv: TTMSFNCChartDrawXYValue;
  dgl: TTMSFNCChartDrawXYGridLine;
  maxw, tw: Double;
  offsxt, offsxc, offsxb: Double;
  vl: Double;
  xrt, xrc, xrb: TRectF;
  K: Integer;
  strmax, strmin: String;
  a: Double;
  cy, ypos: Double;
  thMax, thMin: Double;
  g: TTMSFNCGraphics;
  compareval: Boolean;
begin
  if HasPieChart then
    Exit;

  offsxt := 0;
  offsxc := 0;
  offsxb := 0;

  g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, NativeCanvas);
  g.BeginScene;
  try
    r := GetSeriesRectangle;
    for I := 0 to Series.Count - 1 do
    begin
      s := Series[I];
      s.FDrawXValues.Clear;
      s.FDrawXGridLines.Clear;
      if s.Visible and (s.XValues.Positions <> []) then
      begin
        mn := s.FXMin;
        mx := s.FXMax;

        g.Font.AssignSource(s.XValues.MajorUnitFont);
        thMax := g.CalculateTextHeight('gh');
        g.Font.AssignSource(s.XValues.MinorUnitFont);
        thMin := g.CalculateTextHeight('gh');

        if s.XValues.AutoUnits then
        begin
          htpane := r.Right - r.Left;

          if s.XValues.MajorUnitFormat <> '' then
          begin
            case s.XValues.MajorUnitFormatType of
              vftNormal: strmin := Format(s.XValues.MajorUnitFormat,[mn]);
              vftFloat: strmin := FormatFloat(s.XValues.MajorUnitFormat,mn);
              vftDateTime: strmin := FormatDateTime(s.XValues.MajorUnitFormat, mn);
            end;
          end
          else
            strmin := FloatToStr(mn);

          if s.XValues.MajorUnitFormat <> '' then
          begin
            case s.XValues.MajorUnitFormatType of
              vftNormal: strmax := Format(s.XValues.MajorUnitFormat,[mx]);
              vftFloat: strmax := FormatFloat(s.XValues.MajorUnitFormat,mx);
              vftDateTime: strmax := FormatDateTime(s.XValues.MajorUnitFormat, mx);
            end;
          end
          else
            strmax := FloatToStr(mx);

          g.Font.AssignSource(s.XValues.MajorUnitFont);
          httext := Max(g.CalculateTextWidth(strmax), g.CalculateTextWidth(strmin));
          mu := 0;
          if httext > 0 then
            mu := MinMaxToUnit(mn, mx, htpane / httext);

          g.Font.AssignSource(s.XValues.MinorUnitFont);
          httext := Max(g.CalculateTextWidth(strmax), g.CalculateTextWidth(strmin));
          mi := 0;
          if httext > 0 then
            mi := MinMaxToUnit(mn, mx, htpane / httext);
        end
        else
        begin
          mu := s.XValues.MajorUnit;
          mi := s.XValues.MinorUnit;
        end;

        if s.LogarithmicX then
          mu := 1;

        JMax := mn;
        JMin := mn;
        maxw := 0;

        domaj := (mu > 0);
        domin := (mi > 0);

        if domin then
          Jmin := Round(mn / mi)  * mi;

        if domaj then
          Jmax := Round(mn / mu)  * mu;

        if (domaj) then
        begin
          while (JMin <= mx + mu) do
          begin
            domajv := JMax <= mx + mu;
            if domajv then
            begin
              g.Font.AssignSource(s.XValues.MajorUnitFont);

              JVMax := JMax;
              if s.LogarithmicX then
                JVMax := Power(s.LogarithmicXBase, JMax);

              str := s.FindXAxisTextForValue(JVMax);
              if str = '' then
              begin
                if s.XValues.MajorUnitFormat <> '' then
                begin
                  case s.XValues.MajorUnitFormatType of
                    vftNormal: str := Format(s.XValues.MajorUnitFormat,[JVMax]);
                    vftFloat: str := FormatFloat(s.XValues.MajorUnitFormat,JVMax);
                    vftDateTime: str := FormatDateTime(s.XValues.MajorUnitFormat, JVMax);
                  end;
                end
                else
                  str := FloatToStr(JVMax);
              end;

              JMaxStr := str;
              DoGetSerieXValue(s, s.FDrawXValues.Count, vkMajor, JVMax, JMaxStr);
              dv.ValueString := JMaxStr;
              dv.Value := JVMax;
              dv.Kind := vkMajor;
              vl := s.ValueToX(JVMax) - s.F3DXOffset;
              dv.ValuePositionXTop.X := vl + FTotalOffset3DX;
              dv.ValuePositionXCenter.X := vl;
              dv.ValuePositionXBottom.X := vl;
              dv.ValuePositionXTop.Y := -offsxt;
              dv.ValuePositionXCenter.Y := offsxc;
              dv.ValuePositionXBottom.Y := offsxb;
              dgl.ValueGridLine := False;
              dgl.Value := JVMax;
              dgl.Kind := vkMajor;
              dgl.XStartPoint.X := vl;
              dgl.XEndPoint.X := vl;

              dv.TextWidth := g.CalculateTextWidth(JMaxStr) + 1;
              dv.TextHeight := thmax;

              if s.XValues.Angle <> 0 then
                tw := dv.TextWidth + s.XValues.MajorUnitTickMarkSize + s.XValues.MajorUnitSpacing + 3
              else
                tw := dv.TextHeight + s.XValues.MajorUnitTickMarkSize + s.XValues.MajorUnitSpacing + 3;

              if tw > maxw then
                maxw := tw;
              if (vl >= r.Left - 1) and (vl <= r.Right + 1) then
              begin
                dv.Index := s.FDrawXValues.Count;
                s.FDrawXValues.Add(dv);
                dgl.Index := s.FDrawXGridLines.Count;
                s.FDrawXGridLines.Add(dgl);
              end;
            end;

            dominv := JMin <= mx + mu;
            if dominv and domin then
            begin
              while (CompareValueEx(Jmin, JMax) <> EqualsValue) and (JMax > jmin) do
              begin
                j := JMin;
                if s.LogarithmicX then
                begin
                  j := 1 - (JMax - JMin);
                  j := j * Power(s.LogarithmicXBase, JMax);
                end;

                g.Font.AssignSource(s.XValues.MinorUnitFont);
                str := s.FindXAxisTextForValue(j);
                if str = '' then
                begin
                  if s.XValues.MinorUnitFormat <> '' then
                  begin
                    case s.XValues.MinorUnitFormatType of
                      vftNormal: str := Format(s.XValues.MinorUnitFormat,[j]);
                      vftFloat: str := FormatFloat(s.XValues.MinorUnitFormat, j);
                      vftDateTime: str := FormatDateTime(s.XValues.MinorUnitFormat, j);
                    end;
                  end
                  else
                    str := FloatToStr(j);
                end;

                compareval := false;
                if s.LogarithmicX then
                  compareval := (CompareValueEx(ConvertToLog(s.LogarithmicXBase, j), JMax - 1, 0.1) = EqualsValue);

                if (not s.LogarithmicX or (s.LogarithmicX and not compareval)) and (CompareValueEx(Jmin, JMax) <> EqualsValue) then
                begin
                  JMinstr := str;
                  DoGetSerieXValue(s, s.FDrawXValues.Count, vkMinor, j, JMinStr);
                  dv.ValueString := JMinStr;
                  dv.Value := j;
                  dv.Kind := vkMinor;
                  vl := S.ValueToX(j) - s.F3DXOffset;
                  dv.ValuePositionXTop.X := vl + FTotalOffset3DX;
                  dv.ValuePositionXCenter.X := vl;
                  dv.ValuePositionXBottom.X := vl;
                  dv.ValuePositionXTop.Y := -offsxt;
                  dv.ValuePositionXCenter.Y := offsxc;
                  dv.ValuePositionXBottom.Y := offsxb;
                  dgl.ValueGridLine := False;
                  dgl.Value := j;
                  dgl.Kind := vkMinor;
                  dgl.XStartPoint.X := vl;
                  dgl.XEndPoint.X := vl;
                  dv.TextWidth := g.CalculateTextWidth(JMinStr) + 1;
                  dv.TextHeight := thMin;
                  if s.XValues.Angle <> 0 then
                    tw := dv.TextWidth + s.XValues.MinorUnitTickMarkSize + s.XValues.MinorUnitSpacing + 3
                  else
                    tw := dv.TextHeight + s.XValues.MinorUnitTickMarkSize + s.XValues.MinorUnitSpacing + 3;

                  if tw > maxw then
                    maxw := tw;

                  if (vl >= R.Left - 1) and (vl <= r.Right + 1) then
                  begin
                    dv.Index := s.FDrawXValues.Count;
                    s.FDrawXValues.Add(dv);
                    dgl.Index := s.FDrawXGridLines.Count;
                    s.FDrawXGridLines.Add(dgl);
                  end;
                end;

                JMin := JMin + mi;

                if CompareValueEx(Jmin, Jmax) = 0 then
                  JMin := JMin + mi;
              end;
            end;

            if not domin then
            begin
              JMin := JMin + mu;
            end
            else
            begin
              if CompareValueEx(Jmin, Jmax) = 0 then
              begin
                JMin := JMin + mi;
              end;
            end;

            Jmax := JMax + mu;
            if (JMax > mx + mu) then
              Break;
          end;
        end;

        if s.XValues.Title.Text <> '' then
        begin
          g.Font.AssignSource(s.XValues.Title.Font);
          maxw := maxw + g.CalculateTextHeight(s.XValues.Title.Text) + 4 + s.XValues.Title.TextMargins.Top + s.XValues.Title.TextMargins.Bottom
        end;

        if (xpTop in XAxis.Positions) and (xpTop in s.XValues.Positions) then
          offsxt := offsxt + maxw;

        if (xpCenter in XAxis.Positions) and (xpCenter in s.XValues.Positions) then
          offsxc := offsxc + maxw;

        if (xpBottom in XAxis.Positions) and (xpBottom in s.XValues.Positions) then
          offsxb := offsxb + maxw;
      end;
    end;

    xrt := XAxis.GetRectangle(xpTop);
    xrc := XAxis.GetRectangle(xpCenter);
    xrb := XAxis.GetRectangle(xpBottom);
    r := GetSeriesRectangle;

    for I := 0 to Series.Count - 1 do
    begin
      s := Series[I];
      if s.Visible then
      begin
        a := DegToRad(s.XValues.Angle);
        va := Sin(a);
        for K := 0 to s.FDrawXValues.Count - 1 do
        begin
          dv := s.FDrawXValues[K];
          dv.ValuePositionXTop.Y := dv.ValuePositionXTop.Y + xrt.Bottom;
          dv.ValuePositionXCenter.Y := dv.ValuePositionXCenter.Y + CenterPointEx(xrc).Y;
          dv.ValuePositionXBottom.Y := dv.ValuePositionXBottom.Y + xrb.Top;

          dv.TextPositionXTop := dv.ValuePositionXTop;
          dv.TextPositionXCenter := dv.ValuePositionXCenter;
          dv.TextPositionXBottom := dv.ValuePositionXBottom;

          cy := dv.TextPositionXBottom.Y + dv.TextHeight / 2;
          ypos := cy + (dv.TextWidth / 2) * va;
          dv.TextPositionXBottom.Y := dv.TextPositionXBottom.Y + Abs(ypos - cy) - Abs((dv.TextHeight / 2) * va);

          cy := dv.TextPositionXCenter.Y + dv.TextHeight / 2;
          ypos := cy + (dv.TextWidth / 2) * sin(a);
          dv.TextPositionXCenter.Y := dv.TextPositionXCenter.Y + Abs(ypos - cy) - Abs((dv.TextHeight / 2) * va);

          cy := dv.TextPositionXTop.Y + dv.TextHeight / 2;
          ypos := cy + (dv.TextWidth / 2) * va;
          dv.TextPositionXTop.Y := dv.TextPositionXTop.Y - Abs(ypos - cy) + Abs((dv.TextHeight / 2) * va);

          s.FDrawXValues[K] := dv;
        end;

        for K := 0 to s.FDrawXGridLines.Count - 1 do
        begin
          dgl := s.FDrawXGridLines[K];
          if s.XGrid.Extended then
          begin
            dgl.XStartPoint.Y := xrt.Bottom;
            dgl.XEndPoint.Y := xrb.Top;
          end
          else
          begin
            dgl.XStartPoint.Y := r.Top;
            dgl.XEndPoint.Y := r.Bottom;
          end;

          dgl.XStartPoint.X := dgl.XStartPoint.X + FTotalOffset3DX;
          dgl.XEndPoint.X := dgl.XEndPoint.X + FTotalOffset3DX;
          dgl.XEndPoint.Y := dgl.XEndPoint.Y - FTotalOffset3DY;
          s.FDrawXGridLines[K] := dgl;
        end;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

procedure TTMSFNCChart.CalculateYAxis;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  r: TRectF;
  str: string;
  min, max: Double;
  htpane: Double;
  mu, mi: Extended;
  JVMax, JMax, JVMin, JMin: Extended;
  compareval: Boolean;
  domaj, domin, domajv, dominv: Boolean;
  JMaxStr, JMinStr: String;
  j: Extended;
  dv: TTMSFNCChartDrawXYValue;
  dgl: TTMSFNCChartDrawXYGridLine;
  maxw, tw: Double;
  offsyl, offsyc, offsyr: Double;
  vl: Double;
  yrl, yrc, yrr, pr: TRectF;
  K: Integer;
  thMax, thMin: Double;
  g: TTMSFNCGraphics;
begin
  if HasPieChart and not HasSpiderChart then
    Exit;

  YAxis.FLeftWidth := 0;
  YAxis.FCenterWidth := 0;
  YAxis.FRightWidth := 0;
  offsyl := 0;
  offsyc := 0;
  offsyr := 0;

  g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, NativeCanvas);
  g.BeginScene;
  try
    r := GetSeriesRectangle;
    for I := 0 to Series.Count - 1 do
    begin
      s := Series[I];
      s.FDrawYValues.Clear;
      s.FDrawYGridLines.Clear;
      if s.Visible and ((s.YValues.Positions <> []) or s.IsSpider) then
      begin
        if s.IsSpider then
          min := 0
        else
          min := s.FYMin;

        max := s.FYMax;

        g.Font.AssignSource(s.YValues.MajorUnitFont);
        thMax := g.CalculateTextHeight('gh');
        g.Font.AssignSource(s.YValues.MinorUnitFont);
        thMin := g.CalculateTextHeight('gh');
        pr := s.GetPieRect(False);

        if s.YValues.AutoUnits then
        begin
          if s.IsSpider then
            htpane := pr.Bottom - pr.Top
          else
            htpane := r.Bottom - r.Top;

          mu := 0;
          if thMax > 0 then
            mu := MinMaxToUnit(min, max, htpane / thMax);

          mi := 0;
          if thMin > 0 then
            mi := MinMaxToUnit(min, max, htpane / thMin);
        end
        else
        begin
          mu := s.YValues.MajorUnit;
          mi := s.YValues.MinorUnit;
        end;

        if s.LogarithmicY then
          mu := 1;

        JMax := min;
        JMin := min;
        maxw := 0;

        domaj := (mu > 0);
        domin := (mi > 0);

        if not s.IsSpider then
        begin
          if domin then
            Jmin := Round(min / mi)  * mi;

          if domaj then
            Jmax := Round(min / mu)  * mu;
        end;

        if (domaj) then
        begin
          while (JMin <= max + mu) do
          begin
            domajv := (JMax <= max + mu) or s.IsSpider;
            if domajv then
            begin
              g.Font.AssignSource(s.YValues.MajorUnitFont);
              JVMax := Jmax;
              if s.LogarithmicY then
                JVMax := Power(s.LogarithmicYBase, JMax);

              if s.YValues.MajorUnitFormat <> '' then
              begin
                case s.YValues.MajorUnitFormatType of
                  vftNormal: str := Format(s.YValues.MajorUnitFormat,[JVMax]);
                  vftFloat: str := FormatFloat(s.YValues.MajorUnitFormat, JVMax);
                  vftDateTime: str := FormatDateTime(s.YValues.MajorUnitFormat, JVMax);
                end;
              end
              else
                str := FloatToStr(JVMax);
         
              JMaxStr := str;
              DoGetSerieYValue(s, s.FDrawYValues.Count, vkMajor, JVMax, JMaxStr);
              dv.ValueString := JMaxStr;
              dv.Value := JVMax;
              dv.Kind := vkMajor;
              vl := s.ValueToY(JVMax) + s.F3DYOffset;
              dv.ValuePositionYLeft.Y := vl;
              dv.ValuePositionYCenter.Y := vl;
              dv.ValuePositionYRight.Y := vl - FTotalOffset3DY;
              dv.ValuePositionYLeft.X := -offsyl;
              dv.ValuePositionYCenter.X := offsyc;
              dv.ValuePositionYRight.X := offsyr;
              if max > 0 then
                dv.ValueRadius := PointF((pr.Bottom - pr.Top) / max * JVMax / 2, (pr.Bottom - pr.Top) / max * JVMax / 2);
              dgl.ValueGridLine := False;
              dgl.Value := JVMax;
              dgl.Kind := vkMajor;
              dgl.YStartPoint.Y := vl;
              dgl.YEndPoint.Y := vl;
              dgl.ValueRadius := dv.ValueRadius;

              dv.TextWidth := g.CalculateTextWidth(JMaxStr) + 1;
              dv.TextHeight := thMax;
              tw := dv.TextWidth + s.YValues.MajorUnitTickMarkSize + s.YValues.MajorUnitSpacing + 3;
              if tw > maxw then
                maxw := tw;

              if ((vl >= r.Top - 1) and (vl <= r.Bottom + 1)) or s.IsSpider then
              begin
                dv.Index := s.FDrawYValues.Count;
                s.FDrawYValues.Add(dv);
                dgl.Index := s.FDrawYGridLines.Count;
                s.FDrawYGridLines.Add(dgl);
              end;
            end;

            dominv := (JMin <= max + mu) or s.IsSpider;
            if dominv and domin then
            begin
              while (CompareValueEx(JMin, JMax) <> EqualsValue) and (JMax > jmin) do
              begin
                j := JMin;
                if s.LogarithmicY then
                begin
                  j := 1 - (JMax - JMin);
                  j := j * Power(s.LogarithmicYBase, JMax);
                end;

                g.Font.AssignSource(s.YValues.MinorUnitFont);
                if s.YValues.MinorUnitFormat <> '' then
                begin
                  case s.YValues.MinorUnitFormatType of
                    vftNormal: str := Format(s.YValues.MinorUnitFormat,[j]);
                    vftFloat: str := FormatFloat(s.YValues.MinorUnitFormat, j);
                    vftDateTime: str := FormatDateTime(s.YValues.MinorUnitFormat, j);
                  end;
                end
                else
                  str := FloatToStr(j);

                compareval := false;
                if s.LogarithmicY then
                  compareval := (CompareValueEx(ConvertToLog(s.LogarithmicYBase, j), JMax - 1, 0.1) = EqualsValue);

                if (not s.LogarithmicY or (s.LogarithmicY and not compareval)) and (CompareValueEx(Jmin, JMax) <> EqualsValue) then
                begin
                  JMinstr := str;
                  DoGetSerieYValue(s, s.FDrawYValues.Count, vkMinor, j, JMinStr);
                  dv.ValueString := JMinStr;
                  dv.Value := j;
                  dv.Kind := vkMinor;
                  vl := S.ValueToY(j) + s.F3DYOffset;
                  dv.ValuePositionYLeft.Y := vl;
                  dv.ValuePositionYCenter.Y := vl;
                  dv.ValuePositionYRight.Y := vl - FTotalOffset3DY;
                  dv.ValuePositionYLeft.X := -offsyl;
                  dv.ValuePositionYCenter.X := offsyc;
                  dv.ValuePositionYRight.X := offsyr;
                  if max > 0 then
                    dv.ValueRadius := PointF((pr.Bottom - pr.Top) / max * j / 2, (pr.Bottom - pr.Top) / max * j / 2);

                  dgl.ValueGridLine := False;
                  dgl.Value := j;
                  dgl.Kind := vkMinor;
                  dgl.YStartPoint.Y := vl;
                  dgl.YEndPoint.Y := vl;
                  dgl.ValueRadius := dv.ValueRadius;

                  dv.TextWidth := g.CalculateTextWidth(JMinStr) + 1;
                  dv.TextHeight := thMin;
                  tw := dv.TextWidth + s.YValues.MinorUnitTickMarkSize + s.YValues.MinorUnitSpacing + 3;
                  if tw > maxw then
                    maxw := tw;

                  if ((vl >= R.Top - 1) and (vl <= r.Bottom + 1)) or s.IsSpider then
                  begin
                    dv.Index := s.FDrawYValues.Count;
                    s.FDrawYValues.Add(dv);
                    dgl.Index := s.FDrawYGridLines.Count;
                    s.FDrawYGridLines.Add(dgl);
                  end;
                end;

                JMin := JMin + mi;

                if CompareValueEx(Jmin, Jmax) = EqualsValue then
                  JMin := JMin + mi;
              end;
            end;

            if not domin then
            begin
              JMin := JMin + mu;
            end
            else
            begin
              if CompareValueEx(Jmin, Jmax) = 0 then
                JMin := JMin + mi;
            end;

            Jmax := JMax + mu;

            if (JMax > max + mu) then
              Break;
          end;
        end;

        if s.YValues.Title.Text <> '' then
        begin
          g.Font.AssignSource(s.YValues.Title.Font);
          maxw := maxw + g.CalculateTextHeight(s.YValues.Title.Text) + 4 + s.YValues.Title.TextMargins.Left + s.YValues.Title.TextMargins.Right
        end;

        if (ypLeft in YAxis.Positions) and (ypLeft in s.YValues.Positions) then
        begin
          YAxis.FLeftWidth := YAxis.FLeftWidth + maxw;
          offsyl := offsyl + maxw;
          s.FYAxisLeftWidth := YAxis.FLeftWidth;
        end;

        if (ypCenter in YAxis.Positions) and (ypCenter in s.YValues.Positions) then
        begin
          YAxis.FCenterWidth := YAxis.FCenterWidth + maxw;
          offsyc := offsyc + maxw;
          s.FYAxisCenterWidth := YAxis.FCenterWidth;
        end;

        if (ypRight in YAxis.Positions) and (ypRight in s.YValues.Positions) then
        begin
          YAxis.FRightWidth := YAxis.FRightWidth + maxw;
          offsyr := offsyr + maxw;
          s.FYAxisRightWidth := YAxis.FRightWidth;
        end;
      end;
    end;

    yrl := YAxis.GetRectangle(ypLeft);
    yrc := YAxis.GetRectangle(ypCenter);
    yrr := YAxis.GetRectangle(ypRight);
    r := GetSeriesRectangle;

    for I := 0 to Series.Count - 1 do
    begin
      s := Series[I];
      if s.Visible then
      begin
        for K := 0 to s.FDrawYValues.Count - 1 do
        begin
          dv := s.FDrawYValues[K];
          dv.ValuePositionYLeft.X := dv.ValuePositionYLeft.X + yrl.Right;
          dv.ValuePositionYCenter.X := dv.ValuePositionYCenter.X + CenterPointEx(yrc).X;
          dv.ValuePositionYRight.X := dv.ValuePositionYRight.X + yrr.Left;
          dv.TextPositionYLeft := dv.ValuePositionYLeft;
          dv.TextPositionYCenter := dv.ValuePositionYCenter;
          dv.TextPositionYRight := dv.ValuePositionYRight;
          s.FDrawYValues[K] := dv;
        end;

        for K := 0 to s.FDrawYGridLines.Count - 1 do
        begin
          dgl := s.FDrawYGridLines[K];
          if s.YGrid.Extended then
          begin
            dgl.YStartPoint.X := yrl.Right;
            dgl.YEndPoint.X := yrr.Left;
          end
          else
          begin
            dgl.YStartPoint.X := r.Left;
            dgl.YEndPoint.X := r.Right;
          end;

          dgl.YStartPoint.Y := dgl.YStartPoint.Y - FTotalOffset3DY;
          dgl.YEndPoint.Y := dgl.YEndPoint.Y - FTotalOffset3DY;
          dgl.YStartPoint.X := dgl.YStartPoint.X + FTotalOffset3DX;

          s.FDrawYGridLines[K] := dgl;
        end;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

procedure TTMSFNCChart.CalculateYScale;
var
  s: TTMSFNCChartSerie;
  r: TRectF;
  I: Integer;
  c: Double;
begin
  if HasPieChart then
    Exit;

  r := GetSeriesRectangle;

  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      c := (s.FYMax - s.FYMin);
      if c > 0 then
        s.FYScale := (r.Bottom - r.Top) / c
      else
        s.FYScale := (r.Bottom - r.Top);
    end;
  end;
end;

procedure TTMSFNCChart.DoCanSaveProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind; var ACanSave: Boolean);
begin
  if Assigned(OnCanSaveProperty) then
    OnCanSaveProperty(Self, AObject, APropertyName, APropertyType, ACanSave);
end;

procedure TTMSFNCChart.DoCanLoadProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind; var ACanLoad: Boolean);
begin
  if Assigned(OnCanLoadProperty) then
    OnCanLoadProperty(Self, AObject, APropertyName, APropertyType, ACanLoad);
end;

procedure TTMSFNCChart.Clear;
begin
  Series.Clear;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCChart.CMGesture(var EventInfo: TGestureEventInfo);
var
  p: Double;
  {$IFDEF MSWINDOWS}
  px, py: Double;
  {$ENDIF}
begin
  if not Interaction then
    Exit;

  if EventInfo.GestureID = igiZoom then
  begin
    if TInteractiveGestureFlag.gfBegin in EventInfo.Flags then
    begin
      FTouchDistance := EventInfo.Distance;
    end
    else
    begin
      if EventInfo.Flags = [] then
      begin
        p := (EventInfo.Distance - FTouchDistance);
        UpdateRange(p, p, True);
      end;
      FTouchDistance := EventInfo.Distance;
    end;
  end
  {$IFDEF MSWINDOWS}
  else if EventInfo.GestureID = igiPan then
  begin
    if TInteractiveGestureFlag.gfBegin in EventInfo.Flags then
    begin
      FTouchPoint := EventInfo.Location;
    end
    else
    begin
      if EventInfo.Flags = [] then
      begin
        px := FTouchPoint.X - EventInfo.Location.X;
        py := FTouchPoint.Y - EventInfo.Location.Y;
        UpdateRange(px, py, False);
      end;
      FTouchPoint := EventInfo.Location;
    end;
  end
  {$ENDIF}
  else
    inherited;
end;
{$ENDIF}

constructor TTMSFNCChart.Create(AOwner: TComponent);
begin
  inherited;
  FAntiAliasing := True;
  FNativeCanvas := False;
  FTextQuality := gtqAntiAliasing;
  {$IFDEF FMXLIB}
  ClipChildren := True;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  DoubleBuffered := True;
  ControlStyle := ControlStyle + [csAcceptsControls];
  {$ENDIF}
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcGray);
  FStroke.OnChanged := @StrokeChanged;
  FInteraction := True;
  FClickMargin := 10;

  FAppearance := TTMSFNCChartAppearance.Create(Self);

  FInteractionOptions := TTMSFNCChartInteractionOptions.Create(Self);

  FSeries := TTMSFNCChartSeries.Create(Self);
  FTitle := TTMSFNCChartTitle.Create(Self);
  FXAxis := TTMSFNCChartXAxis.Create(Self);
  FYAxis := TTMSFNCChartYAxis.Create(Self);
  FLegend := TTMSFNCChartLegend.Create(Self);
  FCrosshair := TTMSFNCChartCrosshair.Create(Self);
  FSeriesMargins := TTMSFNCChartMargins.Create;
  FSeriesMargins.OnChange := @SeriesMarginsChanged;

  FDrawOrder := csdoBarsFirst;

  FDefaultLoadOptions := TTMSFNCChartLoadOptions.Create;

  Width := 550;
  Height := 350;
  if (csDesigning in ComponentState) and not ((csReading in Owner.ComponentState) or (csLoading in Owner.ComponentState)) then
    InitSample;

  {$IFDEF FMXLIB}
  Touch.DefaultInteractiveGestures := Touch.DefaultInteractiveGestures + [TInteractiveGesture.Pan, TInteractiveGesture.Zoom];
  Touch.InteractiveGestures := Touch.InteractiveGestures + [TInteractiveGesture.Pan, TInteractiveGesture.Zoom];
  {$ENDIF}

  if not IsDesignerForm then
    RegisterRuntimeClasses;
end;

destructor TTMSFNCChart.Destroy;
begin
  FDefaultLoadOptions.Free;
  FInteractionOptions.Free;
  FSeriesMargins.Free;
  FLegend.Free;
  FCrosshair.Free;
  FYAxis.Free;
  FXAxis.Free;
  FTitle.Free;
  FSeries.Free;
  FAppearance.Free;
  FStroke.Free;
  FFill.Free;
  inherited;
end;

procedure TTMSFNCChart.DoAfterDrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawBackground) then
    OnAfterDrawBackground(Self, AGraphics, ARect);
end;

procedure TTMSFNCChart.DoAfterDrawChart(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawChart) then
    OnAfterDrawChart(Self, AGraphics, ARect);
end;

procedure TTMSFNCChart.DoAfterDrawLegend(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawLegend) then
    OnAfterDrawLegend(Self, AGraphics, ARect);
end;

procedure TTMSFNCChart.DoAfterDrawLegendIcon(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; ARect: TRectF);
begin
  if Assigned(OnAfterDrawLegendIcon) then
    OnAfterDrawLegendIcon(Self, AGraphics, ASerie, ARect);
end;

procedure TTMSFNCChart.DoAfterDrawSerieMarker(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; ADrawPoint: TTMSFNCChartDrawPoint);
begin
  if Assigned(OnAfterDrawSerieMarker) then
    OnAfterDrawSerieMarker(Self, AGraphics, ASerie, ADrawPoint);
end;

procedure TTMSFNCChart.DoAfterDrawSerieMultiPoint(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; ADrawMultiPoint: TTMSFNCChartDrawMultiPoint);
begin
  if Assigned(OnAfterDrawSerieMultiPoint) then
    OnAfterDrawSerieMultiPoint(Self, AGraphics, ASerie, ADrawMultiPoint);
end;

procedure TTMSFNCChart.DoAfterDrawSeries(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawSeries) then
    OnAfterDrawSeries(Self, AGraphics, ARect);
end;

procedure TTMSFNCChart.DoAfterDrawSerieSlice(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; ADrawSlice: TTMSFNCChartDrawSlice);
begin
  if Assigned(OnAfterDrawSerieSlice) then
    OnAfterDrawSerieSlice(Self, AGraphics, ASerie, ADrawSlice);
end;

procedure TTMSFNCChart.DoAfterDrawSerieAnnotation(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; ADrawAnnotation: TTMSFNCChartDrawAnnotation);
begin
  if Assigned(OnAfterDrawSerieAnnotation) then
    OnAfterDrawSerieAnnotation(Self, AGraphics, ASerie, ADrawAnnotation);
end;

procedure TTMSFNCChart.DoAfterDrawSerieBar(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; ADrawRect: TTMSFNCChartDrawRect);
begin
  if Assigned(OnAfterDrawSerieBar) then
    OnAfterDrawSerieBar(Self, AGraphics, ASerie, ADrawRect);
end;

procedure TTMSFNCChart.DoAfterDrawSerieGridLegendText(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; ADrawGridLegendText: TTMSFNCChartDrawLabel);
begin
  if Assigned(OnAfterDrawSerieGridLegendText) then
    OnAfterDrawSerieGridLegendText(Self, AGraphics, ASerie, ADrawGridLegendText);
end;

procedure TTMSFNCChart.DoAfterDrawSerieLabel(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; ADrawLabel: TTMSFNCChartDrawLabel);
begin
  if Assigned(OnAfterDrawSerieLabel) then
    OnAfterDrawSerieLabel(Self, AGraphics, ASerie, ADrawLabel);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieYAxisCrosshairText(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartYAxisPosition; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieYAxisCrosshairText) then
    OnBeforeDrawSerieYAxisCrosshairText(Self, AGraphics, ASerie, ARect, AText, APosition, AMode, ADefaultDraw);
end;

procedure TTMSFNCChart.DoAfterDrawSerieYAxisCrosshairText(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartYAxisPosition; AMode: TTMSFNCChartCrosshairMode);
begin
  if Assigned(OnAfterDrawSerieYAxisCrosshairText) then
    OnAfterDrawSerieYAxisCrosshairText(Self, AGraphics, ASerie, ARect, AText, APosition, AMode);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieXAxisCrosshairText(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartXAxisPosition; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieXAxisCrosshairText) then
    OnBeforeDrawSerieXAxisCrosshairText(Self, AGraphics, ASerie, ARect, AText, APosition, AMode, ADefaultDraw);
end;

procedure TTMSFNCChart.DoAfterDrawSerieXAxisCrosshairText(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; AText: string; APosition: TTMSFNCChartXAxisPosition; AMode: TTMSFNCChartCrosshairMode);
begin
  if Assigned(OnAfterDrawSerieXAxisCrosshairText) then
    OnAfterDrawSerieXAxisCrosshairText(Self, AGraphics, ASerie, ARect, AText, APosition, AMode);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieCrosshairHorizontalLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieCrosshairHorizontalLine) then
    OnBeforeDrawSerieCrosshairHorizontalLine(Self, AGraphics, ASerie, AStartPoint, AValuePoint, AEndPoint, AMode, ADefaultDraw);
end;

procedure TTMSFNCChart.DoAfterDrawSerieCrosshairHorizontalLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode);
begin
  if Assigned(OnAfterDrawSerieCrosshairHorizontalLine) then
    OnAfterDrawSerieCrosshairHorizontalLine(Self, AGraphics, ASerie, AStartPoint, AValuePoint, AEndPoint, AMode);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieCrosshairVerticalLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieCrosshairVerticalLine) then
    OnBeforeDrawSerieCrosshairVerticalLine(Self, AGraphics, ASerie, AStartPoint, AValuePoint, AEndPoint, AMode, ADefaultDraw);
end;

procedure TTMSFNCChart.DoAfterDrawSerieCrosshairVerticalLine(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; AStartPoint, AValuePoint, AEndPoint: TPointF; AMode: TTMSFNCChartCrosshairMode);
begin
  if Assigned(OnAfterDrawSerieCrosshairVerticalLine) then
    OnAfterDrawSerieCrosshairVerticalLine(Self, AGraphics, ASerie, AStartPoint, AValuePoint, AEndPoint, AMode);
end;

procedure TTMSFNCChart.DoGetSerieXAxisCrosshairText(ASerie: TTMSFNCChartSerie; AValue: Double; APosition: TTMSFNCChartXAxisPosition; AMode: TTMSFNCChartCrosshairMode; var AText: String);
begin
  if Assigned(OnGetSerieXAxisCrosshairText) then
    OnGetSerieXAxisCrosshairText(Self, ASerie, AValue, APosition, AMode, AText);
end;

procedure TTMSFNCChart.DoGetSerieYAxisCrosshairText(ASerie: TTMSFNCChartSerie; AValue: Double; APosition: TTMSFNCChartYAxisPosition; AMode: TTMSFNCChartCrosshairMode; var AText: String);
begin
  if Assigned(OnGetSerieYAxisCrosshairText) then
    OnGetSerieYAxisCrosshairText(Self, ASerie, AValue, APosition, AMode, AText);
end;

procedure TTMSFNCChart.DoAfterDrawSerieLegend(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF);
begin
  if Assigned(OnAfterDrawSerieLegend) then
    OnAfterDrawSerieLegend(Self, AGraphics, ASerie, ARect);
end;

procedure TTMSFNCChart.DoAfterDrawSerieLegendIcon(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; ARect: TRectF);
begin
  if Assigned(OnAfterDrawSerieLegendIcon) then
    OnAfterDrawSerieLegendIcon(Self, AGraphics, ASerie, APoint, ARect);
end;

procedure TTMSFNCChart.DoAfterDrawSerieLegendIconVirtual(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; ARect: TRectF);
begin
  if Assigned(OnAfterDrawSerieLegendIconVirtual) then
    OnAfterDrawSerieLegendIconVirtual(Self, AGraphics, ASerie, APoint, ARect);
end;

procedure TTMSFNCChart.DoAfterDrawSerieLine(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; ADrawLine: TTMSFNCChartDrawLine);
begin
  if Assigned(OnAfterDrawSerieLine) then
    OnAfterDrawSerieLine(Self, AGraphics, ASerie, ADrawLine);
end;

procedure TTMSFNCChart.DoAfterDrawSerieXGridLine(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; ADrawGridLine: TTMSFNCChartDrawXYGridLine);
begin
  if Assigned(OnAfterDrawSerieXGridLine) then
    OnAfterDrawSerieXGridLine(Self, AGraphics, ASerie, ADrawGridLine);
end;

procedure TTMSFNCChart.DoAfterDrawSerieXValue(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartXAxisPosition; ADrawValue: TTMSFNCChartDrawXYValue);
begin
  if Assigned(OnAfterDrawSerieXValue) then
    OnAfterDrawSerieXValue(Self, AGraphics, ASerie, APosition, ADrawValue);
end;

procedure TTMSFNCChart.DoAfterDrawSerieYGridLine(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; ADrawGridLine: TTMSFNCChartDrawXYGridLine);
begin
  if Assigned(OnAfterDrawSerieYGridLine) then
    OnAfterDrawSerieYGridLine(Self, AGraphics, ASerie, ADrawGridLine);
end;

procedure TTMSFNCChart.DoAfterDrawSerieYValue(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartYAxisPosition; ADrawValue: TTMSFNCChartDrawXYValue);
begin
  if Assigned(OnAfterDrawSerieYValue) then
    OnAfterDrawSerieYValue(Self, AGraphics, ASerie, APosition, ADrawValue);
end;

procedure TTMSFNCChart.DoAfterDrawTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  APosition: TTMSFNCChartTitlePosition);
begin
  if Assigned(OnAfterDrawTitle) then
    OnAfterDrawTitle(Self, AGraphics, ARect, APosition);
end;

procedure TTMSFNCChart.DoAfterDrawXAxis(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; APosition: TTMSFNCChartXAxisPosition);
begin
  if Assigned(OnAfterDrawXAxis) then
    OnAfterDrawXAxis(Self, AGraphics, ARect, APosition);
end;

procedure TTMSFNCChart.DoAfterDrawXValuesTitle(AGraphics: TTMSFNCGraphics; ARect,
  ATextRect: TRectF; APosition: TTMSFNCChartXAxisPosition;
  ASerie: TTMSFNCChartSerie);
begin
  if Assigned(OnAfterDrawXValuesTitle) then
    OnAfterDrawXValuesTitle(Self, AGraphics, ARect, APosition, ASerie, ATextRect);
end;

procedure TTMSFNCChart.DoAfterDrawYAxis(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; APosition: TTMSFNCChartYAxisPosition);
begin
  if Assigned(OnAfterDrawYAxis) then
    OnAfterDrawYAxis(Self, AGraphics, ARect, APosition);
end;

procedure TTMSFNCChart.DoAfterDrawYValuesTitle(AGraphics: TTMSFNCGraphics; ARect,
  ATextRect: TRectF; APosition: TTMSFNCChartYAxisPosition;
  ASerie: TTMSFNCChartSerie);
begin
  if Assigned(OnAfterDrawYValuesTitle) then
    OnAfterDrawYValuesTitle(Self, AGraphics, ARect, APosition, ASerie, ATextRect);
end;

procedure TTMSFNCChart.DoAnimateSerieFinished(ASerie: TTMSFNCChartSerie);
begin
  if Assigned(OnAnimateSerieFinished) then
    OnAnimateSerieFinished(Self, ASerie);
end;

procedure TTMSFNCChart.DoAnimateSerieStarted(ASerie: TTMSFNCChartSerie);
begin
  if Assigned(OnAnimateSerieStarted) then
    OnAnimateSerieStarted(Self, ASerie);
end;

procedure TTMSFNCChart.DoBeforeDrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawBackground) then
    OnBeforeDrawBackground(Self, AGraphics, ARect, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawChart(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawChart) then
    OnBeforeDrawChart(Self, AGraphics, ARect, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawLegend(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawLegend) then
    OnBeforeDrawLegend(Self, AGraphics, ARect, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawLegendIcon(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawLegendIcon) then
    OnBeforeDrawLegendIcon(Self, AGraphics, ASerie, ARect, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieMarker(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; var ADrawPoint: TTMSFNCChartDrawPoint; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieMarker) then
    OnBeforeDrawSerieMarker(Self, AGraphics, ASerie, ADrawPoint, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSeries(AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSeries) then
    OnBeforeDrawSeries(Self, AGraphics, ARect, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieSlice(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; var ADrawSlice: TTMSFNCChartDrawSlice;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieSlice) then
    OnBeforeDrawSerieSlice(Self, AGraphics, ASerie, ADrawSlice, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieAnnotation(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; var ADrawAnnotation: TTMSFNCChartDrawAnnotation;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieAnnotation) then
    OnBeforeDrawSerieAnnotation(Self, AGraphics, ASerie, ADrawAnnotation, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieBar(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; var ADrawRect: TTMSFNCChartDrawRect;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieBar) then
    OnBeforeDrawSerieBar(Self, AGraphics, ASerie, ADrawRect, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieGridLegendText(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; var ADrawGridLegendText: TTMSFNCChartDrawLabel;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieGridLegendText) then
    OnBeforeDrawSerieGridLegendText(Self, AGraphics, ASerie, ADrawGridLegendText, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieLabel(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; var ADrawLabel: TTMSFNCChartDrawLabel;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieLabel) then
    OnBeforeDrawSerieLabel(Self, AGraphics, ASerie, ADrawLabel, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieLegend(AGraphics: TTMSFNCGraphics; ASerie: TTMSFNCChartSerie; ARect: TRectF;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieLegend) then
    OnBeforeDrawSerieLegend(Self, AGraphics, ASerie, ARect, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieLegendIcon(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; ARect: TRectF;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieLegendIcon) then
    OnBeforeDrawSerieLegendIcon(Self, AGraphics, ASerie, APoint, ARect, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieLegendIconVirtual(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual; ARect: TRectF;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieLegendIconVirtual) then
    OnBeforeDrawSerieLegendIconVirtual(Self, AGraphics, ASerie, APoint, ARect, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieLine(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; var ADrawLine: TTMSFNCChartDrawLine;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieLine) then
    OnBeforeDrawSerieLine(Self, AGraphics, ASerie, ADrawLine, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieMultiPoint(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; var ADrawMultiPoint: TTMSFNCChartDrawMultiPoint;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieMultiPoint) then
    OnBeforeDrawSerieMultiPoint(Self, AGraphics, ASerie, ADrawMultiPoint, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieXGridLine(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; var ADrawGridLine: TTMSFNCChartDrawXYGridLine; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieXGridLine) then
    OnBeforeDrawSerieXGridLine(Self, AGraphics, ASerie, ADrawGridLine, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieXValue(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartXAxisPosition; var ADrawValue: TTMSFNCChartDrawXYValue;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieXValue) then
    OnBeforeDrawSerieXValue(Self, AGraphics, ASerie, APosition, ADrawValue, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieYGridLine(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; var ADrawGridLine: TTMSFNCChartDrawXYGridLine; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieYGridLine) then
    OnBeforeDrawSerieYGridLine(Self, AGraphics, ASerie, ADrawGridLine, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawSerieYValue(AGraphics: TTMSFNCGraphics;
  ASerie: TTMSFNCChartSerie; APosition: TTMSFNCChartYAxisPosition; var ADrawValue: TTMSFNCChartDrawXYValue;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawSerieYValue) then
    OnBeforeDrawSerieYValue(Self, AGraphics, ASerie, APosition, ADrawValue, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  APosition: TTMSFNCChartTitlePosition; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawTitle) then
    OnBeforeDrawTitle(Self, AGraphics, ARect, APosition, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawXAxis(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; APosition: TTMSFNCChartXAxisPosition;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawXAxis) then
    OnBeforeDrawXAxis(Self, AGraphics, ARect, APosition, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawXValuesTitle(AGraphics: TTMSFNCGraphics; ARect,
  ATextRect: TRectF; APosition: TTMSFNCChartXAxisPosition;
  ASerie: TTMSFNCChartSerie; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawXValuesTitle) then
    OnBeforeDrawXValuesTitle(Self, AGraphics, ARect, APosition, ASerie, ATextRect, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawYAxis(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; APosition: TTMSFNCChartYAxisPosition;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawYAxis) then
    OnBeforeDrawYAxis(Self, AGraphics, ARect, APosition, ADefaultDraw);
end;

procedure TTMSFNCChart.DoBeforeDrawYValuesTitle(AGraphics: TTMSFNCGraphics; ARect,
  ATextRect: TRectF; APosition: TTMSFNCChartYAxisPosition;
  ASerie: TTMSFNCChartSerie; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawYValuesTitle) then
    OnBeforeDrawYValuesTitle(Self, AGraphics, ARect, APosition, ASerie, ATextRect, ADefaultDraw);
end;

procedure TTMSFNCChart.DoCustomizeAnnotationFill(ASerie: TTMSFNCChartSerie;
  APoint: TTMSFNCChartPointVirtual; AAnnotation: TTMSFNCChartAnnotationVirtual;
  AFill: TTMSFNCGraphicsFill);
begin
  if Assigned(OnCustomizeAnnotationFill) then
    OnCustomizeAnnotationFill(Self, ASerie, APoint, AAnnotation, AFill);
end;

procedure TTMSFNCChart.DoCustomizeAnnotationFont(ASerie: TTMSFNCChartSerie;
  APoint: TTMSFNCChartPointVirtual; AAnnotation: TTMSFNCChartAnnotationVirtual;
  AFont: TTMSFNCGraphicsFont);
begin
  if Assigned(OnCustomizeAnnotationFont) then
    OnCustomizeAnnotationFont(Self, ASerie, APoint, AAnnotation, AFont);
end;

procedure TTMSFNCChart.DoCustomizeAnnotationStroke(ASerie: TTMSFNCChartSerie;
  APoint: TTMSFNCChartPointVirtual; AAnnotation: TTMSFNCChartAnnotationVirtual;
  AStroke: TTMSFNCGraphicsStroke);
begin
  if Assigned(OnCustomizeAnnotationStroke) then
    OnCustomizeAnnotationStroke(Self, ASerie, APoint, AAnnotation, AStroke);
end;

procedure TTMSFNCChart.DoDrawTitleText(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  APosition: TTMSFNCChartTitlePosition; var AText: String;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnDrawTitleText) then
    OnDrawTitleText(Self, AGraphics, ARect, APosition, AText, ADefaultDraw);
end;

procedure TTMSFNCChart.DoGetAnnotation(ASerie: TTMSFNCChartSerie;
  APoint: TTMSFNCChartPointVirtual; AIndex: Integer;
  var AAnnotation: TTMSFNCChartAnnotationVirtual);
begin
  if Assigned(OnGetAnnotation) then
    OnGetAnnotation(Self, ASerie, APoint, AIndex, AAnnotation);
end;

procedure TTMSFNCChart.DoGetNumberOfAnnotations(ASerie: TTMSFNCChartSerie;
  APoint: TTMSFNCChartPointVirtual; var ANumberOfAnnotations: Integer);
begin
  if Assigned(OnGetNumberOfAnnotations) then
    OnGetNumberOfAnnotations(Self, ASerie, APoint, ANumberOfAnnotations);
end;

procedure TTMSFNCChart.DoGetNumberOfPoints(ASerie: TTMSFNCChartSerie;
  var ANumberOfPoints: Integer);
begin
  if Assigned(OnGetNumberOfPoints) then
  begin
    if Assigned(ASerie) then
      ASerie.Points.Clear;

    OnGetNumberOfPoints(Self, ASerie, ANumberOfPoints);
    FVirtualMode := ANumberOfPoints > 0;
  end;
end;

procedure TTMSFNCChart.DoGetPoint(ASerie: TTMSFNCChartSerie; AIndex: Integer; var APoint: TTMSFNCChartPointVirtual);
begin
  if Assigned(OnGetPoint) then
    OnGetPoint(Self, ASerie, AIndex, APoint);
end;

procedure TTMSFNCChart.DoGetSerieLabel(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; var AValueString: String);
begin
  if Assigned(OnGetSerieLabel) then
    OnGetSerieLabel(Self, ASerie, APoint, AValueString);
end;

procedure TTMSFNCChart.DoGetSerieLabelVirtual(ASerie: TTMSFNCChartSerie;
  APoint: TTMSFNCChartPointVirtual; var AValueString: String);
begin
  if Assigned(OnGetSerieLabelVirtual) then
    OnGetSerieLabelVirtual(Self, ASerie, APoint, AValueString);
end;

procedure TTMSFNCChart.DoGetSerieLegendText(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; var ALegendText: String);
begin
  if Assigned(OnGetSerieLegendText) then
    OnGetSerieLegendText(Self, ASerie, APoint, ALegendText);
end;

procedure TTMSFNCChart.DoGetSerieLegendTextVirtual(ASerie: TTMSFNCChartSerie;
  APoint: TTMSFNCChartPointVirtual; var ALegendText: String);
begin
  if Assigned(OnGetSerieLegendTextVirtual) then
    OnGetSerieLegendTextVirtual(Self, ASerie, APoint, ALegendText);
end;

procedure TTMSFNCChart.DoGetSerieSpiderLegendText(ASerie: TTMSFNCChartSerie;
  APoint: TTMSFNCChartPoint; var ALegendText: String);
begin
  if Assigned(OnGetSerieSpiderLegendText) then
    OnGetSerieSpiderLegendText(Self, ASerie, APoint, ALegendText);
end;

procedure TTMSFNCChart.DoGetSerieSpiderLegendTextVirtual(
  ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPointVirtual;
  var ALegendText: String);
begin
  if Assigned(OnGetSerieSpiderLegendTextVirtual) then
    OnGetSerieSpiderLegendTextVirtual(Self, ASerie, APoint, ALegendText);
end;

procedure TTMSFNCChart.DoGetSerieXValue(ASerie: TTMSFNCChartSerie; AIndex: Integer; AKind: TTMSFNCChartDrawXYValueKind;
  AValue: Double; var AValueString: String);
begin
  if Assigned(OnGetSerieXValue) then
    OnGetSerieXValue(Self, ASerie, AIndex, AKind, AValue, AValueString);
end;

procedure TTMSFNCChart.DoGetSerieYValue(ASerie: TTMSFNCChartSerie; AIndex: Integer; AKind: TTMSFNCChartDrawXYValueKind;
  AValue: Double; var AValueString: String);
begin
  if Assigned(OnGetSerieYValue) then
    OnGetSerieYValue(Self, ASerie, AIndex, AKind, AValue, AValueString);
end;

procedure TTMSFNCChart.DoJSONAddSerie(ASerie: TTMSFNCChartSerie; ASerieJSONValue: TJSONValue);
begin
  if Assigned(FOnJSONAddSeries) then
    OnJSONAddSeries(Self, ASerie, ASerieJSONValue);
end;

procedure TTMSFNCChart.DoJSONAddPoint(ASerie: TTMSFNCChartSerie; APoint: TTMSFNCChartPoint; APointJSONValue: TJSONValue);
begin
  if Assigned(FOnJSONAddPoint) then
    OnJSONAddPoint(Self, ASerie, APoint, APointJSONValue);
end;

procedure TTMSFNCChart.DoLegendItemClick(AIndex: Integer);
begin
  if Assigned(OnLegendItemClick) then
    OnLegendItemClick(Self, AIndex);
end;

procedure TTMSFNCChart.DoSerieBarClick(APoint: TTMSFNCChartPoint);
begin
  if Assigned(OnSerieBarClick) then
    OnSerieBarClick(Self, APoint);
end;

procedure TTMSFNCChart.DoSerieBarClickVirtual(APoint: TTMSFNCChartPointVirtual);
begin
  if Assigned(OnSerieBarClickVirtual) then
    OnSerieBarClickVirtual(Self, APoint);
end;

procedure TTMSFNCChart.DoSerieLegendItemClick(ASerie: TTMSFNCChartSerie; AIndex: Integer);
begin
  if Assigned(OnSerieLegendItemClick) then
    OnSerieLegendItemClick(Self, ASerie, AIndex);
end;

procedure TTMSFNCChart.DoBeforeSetAllFonts(ASetType: TTMSFNCChartAppearanceFontType; var ASetChartFonts: Boolean; var ASetSeriesFonts: Boolean; var ASetAnnotationFonts: Boolean);
begin
  if Assigned(OnBeforeSetAllFonts) then
    FOnBeforeSetAllFonts(Self, ASetType, ASetChartFonts, ASetSeriesFonts, ASetAnnotationFonts);
end;

procedure TTMSFNCChart.DoSeriePointClick(APoint: TTMSFNCChartPoint);
begin
  if Assigned(OnSeriePointClick) then
    OnSeriePointClick(Self, APoint);
end;

procedure TTMSFNCChart.DoSeriePointClickVirtual(APoint: TTMSFNCChartPointVirtual);
begin
  if Assigned(OnSeriePointClickVirtual) then
    OnSeriePointClickVirtual(Self, APoint);
end;

procedure TTMSFNCChart.DoSerieSliceClick(APoint: TTMSFNCChartPoint);
begin
  if Assigned(OnSerieSliceClick) then
    OnSerieSliceClick(Self, APoint);
end;

procedure TTMSFNCChart.DoSerieSliceClickVirtual(APoint: TTMSFNCChartPointVirtual);
begin
  if Assigned(OnSerieSliceClickVirtual) then
    OnSerieSliceClickVirtual(Self, APoint);
end;

procedure TTMSFNCChart.DrawBackGround(AGraphics: TTMSFNCGraphics);
var
  dr: Boolean;
  r: TRectF;
  st: TTMSFNCGraphicsSaveState;
begin
  AGraphics.Fill.Assign(Fill);
  AGraphics.Stroke.Assign(Stroke);

  r := GetChartRectangle;
  dr := True;
  st := AGraphics.SaveState;
  DoBeforeDrawBackground(AGraphics, r, dr);
  if dr then
  begin
    AGraphics.DrawRectangle(r);
    DoAfterDrawBackground(AGraphics, r);
  end;
  AGraphics.RestoreState(st);
end;

procedure TTMSFNCChart.DrawChart(AGraphics: TTMSFNCGraphics);
var
  r: TRectF;
  dr: Boolean;
  st: TTMSFNCGraphicsSaveState;
  {$IFDEF FREEWARE}
  str: String;
  {$ENDIF}
begin
  r := GetChartRectangle;
  dr := True;
  st := AGraphics.SaveState;
  DoBeforeDrawChart(AGraphics, r, dr);
  if dr then
  begin
    DrawBackGround(AGraphics);
    Title.Draw(AGraphics);
    DrawXYGrid(AGraphics);
    XAxis.Draw(AGraphics);
    YAxis.Draw(AGraphics);
    Series.Draw(AGraphics);
    Legend.Draw(AGraphics);
    Crosshair.Draw(AGraphics);
    DoAfterDrawChart(AGraphics, r);
  end;
  AGraphics.RestoreState(st);

  {$IFDEF FREEWARE}
  AGraphics.Font.Color := gcRed;
  str := Scramble('Duuilfdqljk%pv`v%qwldi%s`wvljk%jc%QHV%vjcqrdw`%fjhujk`kqv+')+#13#10+
    Scramble('Fjkqdfq%QHV%vjcqrdw`%mqqu?**rrr+qhvvjcqrdw`+fjh%cjw%sdila%ilf`kvlkb+');
  AGraphics.DrawText(GetChartRectangle, str, True, gtaLeading, gtaLeading);
  {$ENDIF}
end;

procedure TTMSFNCChart.DrawXYGrid(AGraphics: TTMSFNCGraphics);
var
  I: Integer;
  s: TTMSFNCChartSerie;
begin
  if HasPieChart then
    Exit;

  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible then
    begin             
      s.XGrid.Draw(AGraphics);
      s.YGrid.Draw(AGraphics, False);
    end;
  end;
end;

procedure TTMSFNCChart.EndUpdate;
begin
  {$IF DEFINED(FMXLIB) AND NOT (COMPILERVERSION = 28)}
  inherited EndUpdate;
  {$ELSE}
  inherited;
  {$ENDIF}
  Dec(FUpdateCount);
  if FUpdateCount = 0 then
    UpdateChart;
end;

{$IFDEF WEBLIB}
function TTMSFNCChart.SaveToBase64: string;
var
  g: TTMSFNCGraphics;
begin
  g := TTMSFNCGraphics.CreateBitmapCanvas(Round(Width), Round(Height), NativeCanvas);
  g.BeginScene;
  try
    g.Context.SetSize(Width, Height);
    g.Context.SetAntiAliasing(AntiAliasing);
    g.Context.SetTextQuality(TextQuality);
    DrawChart(g);
    g.Context.Render;
    Result := g.Bitmap.GetBase64Image;
  finally
    g.EndScene;
    g.Free;
  end;
end;
{$ENDIF}

procedure TTMSFNCChart.SaveToImage(AFileName: String);
var
  g: TTMSFNCGraphics;
  {$IFDEF WEBLIB}
  s: string;
  b: TBytes;
  ms: TMemoryStream;
  {$ENDIF}
begin
  g := TTMSFNCGraphics.CreateBitmapCanvas(Round(Width), Round(Height), NativeCanvas);
  g.BeginScene
  ;
  try
    g.Context.SetSize(Width, Height);
    g.Context.SetAntiAliasing(AntiAliasing);
    g.Context.SetTextQuality(TextQuality);
    DrawChart(g);
    g.Context.Render;
    {$IFNDEF WEBLIB}
    if Assigned(g.Bitmap) then
      g.Bitmap.SaveToFile(AFileName);
    {$ENDIF}
    {$IFDEF WEBLIB}
    s := g.Bitmap.GetBase64Image;

    if Pos(';base64,', s) > 0 then
      Delete(s, 1, Pos(';base64,', s) + 7);

    b := TTMSFNCUtils.Decode64ToBytes(s);

    ms := TMemoryStream.Create;
    try
      ms.Write(b, Length(b));
      ms.SaveToFile(AFileName);
    finally
      ms.Free;
    end;
    {$ENDIF}
  finally
    g.EndScene;
    g.Free;
  end;
end;

procedure TTMSFNCChart.FillChanged(Sender: TObject);
begin
  InvalidateChart;
end;

function TTMSFNCChart.GetChartRectangle: TRectF;
begin
  if FExport then
    Result := FExportRect
  else
    Result := RectF(0, 0, Width, Height);
end;

function TTMSFNCChart.GetColorForIndex(AIndex: Integer): TTMSFNCGraphicsColor;
var
  I: Integer;
  {$IFDEF FMXLIB}
  col: TAlphaColorRec;
  {$ENDIF}
begin
  Result := gcNull;

  case Appearance.ColorScheme of
    ccsColorList:
    begin
      if (AIndex <= Appearance.ColorList.Count - 1) then
        Result := Appearance.ColorList.Items[AIndex].Color
      else
      begin
        {$IFDEF FMXLIB}
        col.R := Random(255);
        col.G := Random(255);
        col.B := Random(255);
        col.A := 255;
        Result := col.Color;
        {$ELSE}
        Result := RGB(Random(255), Random(255), Random(255));
        {$ENDIF}
      end;

    end;
    ccsExcel:
    begin
      case AIndex of
        {$IFDEF FMXLIB}
        0: Result := $FF4472C4;
        1: Result := $FFED7D31;
        2: Result := $FFA5A5A5;
        3: Result := $FFFFC000;
        4: Result := $FF5B9BD5;
        5: Result := $FF70AD47;
        6: Result := $FF264478;
        7: Result := $FF9E480E;
        8: Result := $FF636363;
        9: Result := $FF997300;
        {$ENDIF}
        {$IFNDEF FMXLIB}
        0: Result := $C47244;
        1: Result := $317DED;
        2: Result := $A5A5A5;
        3: Result := $00C0FF;
        4: Result := $D59B5B;
        5: Result := $47AD70;
        6: Result := $784426;
        7: Result := $0E489E;
        8: Result := $636363;
        9: Result := $007399;
        {$ENDIF}
        else
        begin
          {$IFDEF FMXLIB}
          col.R := Random(255);
          col.G := Random(255);
          col.B := Random(255);
          col.A := 255;
          Result := col.Color;
          {$ELSE}
          Result := RGB(Random(255), Random(255), Random(255));
          {$ENDIF}
        end;
      end;
    end;
    ccsMonochromatic:
    begin
      Result := Appearance.MonochromeColor;
      for I := 1 to AIndex do
        Result := Lighter(Result, 25);
    end;
  end;
end;

function TTMSFNCChart.GetSerieByName(AName: string): TTMSFNCChartSerie;
var
  I: Integer;
begin
  Result := nil;
  for I := 0 to Series.Count - 1 do
  begin
    if Series[I].LegendText = AName then
    begin
      Result := Series[I];
      Exit;
    end;
  end;
end;

function TTMSFNCChart.GetSeriesRectangle: TRectF;
var
  r: TRectF;
  offx, offy: Double;
begin
  r := GetChartRectangle;
  if XAxis.Visible then
  begin
    if xpTop in XAxis.Positions then
      R.Top := R.Top + XAxis.GetHeight(xpTop);

    if xpBottom in XAxis.Positions then
      R.Bottom := R.Bottom - XAxis.GetHeight(xpBottom);
  end;

  if Title.Visible then
  begin
    if tipTop in Title.Positions then
      R.Top := R.Top + Title.Height;
    if tipBottom in Title.Positions then
      R.Bottom := R.Bottom - Title.Height;
  end;

  if YAxis.Visible then
  begin
    if ypLeft in YAxis.Positions then
      R.Left := R.Left + YAxis.GetWidth(ypLeft);

    if ypRight in YAxis.Positions then
      R.Right := R.Right - YAxis.GetWidth(ypRight);
  end;

  Result := RectF(R.Left + SeriesMargins.Left, R.Top + SeriesMargins.Top, R.Right - SeriesMargins.Right,
    R.Bottom - SeriesMargins.Bottom);

  offx := FTotalOffset3DX;
  offy := FTotalOffset3DY;

  Result.Right := Result.Right - offx;
  Result.Top := Result.Top + offy;
end;

function TTMSFNCChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctLine;
end;

function TTMSFNCChart.GetTotal3DOffsetX: Double;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  gp, l: Integer;
  lgp: array of Integer;
  J: Integer;
  maxgp: Double;
begin
  Result := 0;
  SetLength(lgp, 0);
  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible and s.Is3DEnabled and not s.IsBar and not s.IsStacked then
      Result := Result + s.Offset3DX;
  end;

  gp := -1;
  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible and s.Is3DEnabled and (s.GroupIndex > gp) and (s.IsBar or s.IsArea) then
    begin
      gp := s.GroupIndex;
      l := Length(lgp);
      SetLength(lgp, l + 1);
      lgp[l] := gp;
    end;
  end;

  maxgp := 0;
  for I := 0 to Length(lgp) - 1 do
  begin
    for J := 0 to Series.Count - 1 do
    begin
      s := Series[J];
      if s.Visible and s.Is3DEnabled and (s.GroupIndex = lgp[I]) and ((s.IsBar and not s.IsStacked) or (s.IsArea and s.IsStacked)) then
        maxgp := Max(maxgp, s.Offset3DX);
    end;
    Result := Result + maxgp;
  end;

  maxgp := 0;
  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible and s.Is3DEnabled and s.IsBar and s.IsStacked then
      maxgp := Max(maxgp, s.Offset3DX);
  end;
  Result := Result + maxgp;
end;

function TTMSFNCChart.GetTotal3DOffsetY: Double;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  gp, l: Integer;
  lgp: array of Integer;
  J: Integer;
  maxgp: Double;
begin
  Result := 0;
  SetLength(lgp, 0);
  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible and s.Is3DEnabled and not s.IsBar and not s.IsStacked then
      Result := Result + s.Offset3DY;
  end;

  gp := -1;
  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible and s.Is3DEnabled and (s.GroupIndex > gp) and (s.IsBar or s.IsArea) then
    begin
      gp := s.GroupIndex;
      l := Length(lgp);
      SetLength(lgp, l + 1);
      lgp[l] := gp;
    end;
  end;

  maxgp := 0;
  for I := 0 to Length(lgp) - 1 do
  begin
    for J := 0 to Series.Count - 1 do
    begin
      s := Series[J];
      if s.Visible and s.Is3DEnabled and (s.GroupIndex = lgp[I]) and ((s.IsBar and not s.IsStacked) or (s.IsArea and s.IsStacked)) then
        maxgp := Max(maxgp, s.Offset3DY);
    end;
    Result := Result + maxgp;
  end;

  maxgp := 0;
  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Visible and s.Is3DEnabled and s.IsBar and s.IsStacked then
      maxgp := Max(maxgp, s.Offset3DY);
  end;
  Result := Result + maxgp;
end;

function TTMSFNCChart.GetVersion: String;
var
  vn: Integer;
begin
  vn := GetVersionNr;
  {$IFNDEF WEBLIB}
  Result := IntToStr(Hi(Hiword(vn)))+'.'+IntToStr(Lo(Hiword(vn)))+'.'+IntToStr(Hi(Loword(vn)))+'.'+IntToStr(Lo(Loword(vn)));
  {$ENDIF}
end;

function TTMSFNCChart.GetVersionNr: integer;
begin
  {$IFNDEF WEBLIB}
  Result := MakeLong(MakeWord(BLD_VER,REL_VER),MakeWord(MIN_VER,MAJ_VER));
  {$ENDIF}
end;

function TTMSFNCChart.HasPieChart: Boolean;
var
  I: Integer;
begin
  Result := False;
  for I := 0 to Series.Count - 1 do
  begin
    if Series[I].IsPie then
    begin
      Result := True;
      Break;
    end;
  end;
end;

function TTMSFNCChart.HasSpiderChart: Boolean;
var
  I: Integer;
begin
  Result := False;
  for I := 0 to Series.Count - 1 do
  begin
    if Series[I].IsSpider then
    begin
      Result := True;
      Break;
    end;
  end;
end;

function TTMSFNCChart.IsDesignerForm: Boolean;
var
  frm: TCustomForm;
begin
  frm := TTMSFNCUtils.GetOwnerForm(Self);
  Result := (csDesigning in ComponentState);
  if Assigned(frm) then
    Result := Result or (frm is TTMSFNCCustomDesignerForm);
end;

function TTMSFNCChart.HasStackedPieChart: Boolean;
var
  I: Integer;
begin
  Result := False;
  for I := 0 to Series.Count - 1 do
  begin
    if Series[I].Pie.Stacked then
    begin
      Result := True;
      Break;
    end;
  end;
end;

procedure TTMSFNCChart.InitSample;
begin
  InternalInitSample;
end;

procedure TTMSFNCChart.InternalInitSample(ANumOfSeries: Integer; AShowMarkers: Boolean; AMaxValue: Integer);
var
  s: TTMSFNCChartSerie;
  I, yChange: Integer;
  NumOfPoints: Integer;
  J: Integer;
  pt: TTMSFNCChartPoint;
  isPie, isStick: Boolean;
  t: TTMSFNCChartSerieType;
begin
  t := GetDefaultChartType;
  isPie := t in [ctPie, ctVariableRadiusPie, ctSizedPie, ctSpider];
  isStick := t in [ctOHLC, ctCandleStick, ctBoxPlot];

  if t = ctBand then
    yChange := AMaxValue div 4
  else
    yChange := 20;

  BeginUpdate;
  Series.Clear;

  for I := 0 to ANumOfSeries - 1 do
  begin
    s := Series.Add;

    if I = 0 then
    begin
      s.YValues.Positions := [ypLeft];
      s.XValues.Positions := [xpBottom];
      s.XGrid.Visible := True;
      s.YGrid.Visible := True;
    end
    else
    begin
      s.YValues.Positions := [];
      s.XValues.Positions := [];
    end;

    if isPie then
    begin
      {$IFDEF FMXLIB}
      s.Stroke.Color := $7FFFFFFF;
      {$ENDIF}
      {$IFNDEF FMXLIB}
      s.Stroke.Color := $DFDFDF;
      {$ENDIF}
    end;
  end;

  Title.Text := 'TMS FNC Chart';

  if Series.Count > 0 then
  begin
    Series[0].XValues.Positions := [xpTop, xpCenter, xpBottom];
    Series[0].YValues.Positions := [ypLeft, ypCenter, ypRight];
  end;

  if isPie then
  begin
    Legend.Visible := False;
  end;

  for I := 0 to Series.Count - 1 do
  begin
    Series[I].YValues.Title.Text := 'Y-Axis Series ' + inttostr(I + 1);
    Series[I].XValues.Title.Text := 'X-Axis Series ' + inttostr(I + 1);
    Series[I].AutoYRange := arCommonZeroBased;
    Series[I].MaxYOffsetPercentage := 10;
    Series[I].Markers.Visible := AShowMarkers;

    if isPie then
    begin
      NumOfPoints := 5;
      Series[I].Legend.Visible := True;
    end
    else if isStick then
    begin
      NumOfPoints := 20;
      Series[I].MaxX := 20;
      Series[I].Stroke.Width := 2;
    end
    else
      NumOfPoints := 10;

    if (t = ctArea) and (I > 0) then  //Make sure first series is visible
      AMaxValue := Round (AMaxValue * 0.6);

    for J := 0 to NumOfPoints do
    begin
      pt := Series[I].Points.Add;

      pt.YValue := RandomRange(0, AMaxValue - yChange) + yChange;
      pt.YValueSecond := pt.YValue - RandomRange(yChange div 2, yChange);
      pt.YValueVariable := pt.YValue;
      pt.YValueLow :=  pt.YValue - RandomRange(Round(pt.YValueVariable * 0.3) ,Round(pt.YValueVariable * 0.6)) - (yChange div 2);
      pt.YValueOpen := pt.YValueLow + Random(Round(pt.YValue - pt.YValueLow));
      pt.YValueClose := pt.YValueLow + Random(Round(pt.YValue - pt.YValueLow));
      pt.YValueMedian := pt.YValueVariable + RandomRange(-5, 5);
    end;
  end;

  EndUpdate;
end;

procedure TTMSFNCChart.HandleMouseLeave;
begin
  if Crosshair.Visible then
  begin
    FCrosshairPt := PointF(-1, -1);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChart.Loaded;
begin
  inherited;
  UpdateChart;
end;

function TTMSFNCChart.AddSeriesFromDataArray(YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  BeginUpdate;

  Result := FSeries.Add;
  Result.LoadFromDataArray(YValuesArray, XValuesArray, XLabelsArray);

  EndUpdate;
end;

function TTMSFNCChart.AddSeriesFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  BeginUpdate;

  Result := FSeries.Add;
  Result.LoadFromDataArray(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray);

  EndUpdate;
end;

function TTMSFNCChart.AddSeriesFromDataArrayEx(YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray, YVariableValuesArray: TTMSFNCChartValuesArray): TTMSFNCChartSerie;
begin
  BeginUpdate;

  Result := FSeries.Add;
  Result.LoadFromDataArrayEx(YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray);

  EndUpdate;
end;

function TTMSFNCChart.AddSeriesFromDataArrayEx(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray, YVariableValuesArray: TTMSFNCChartValuesArray): TTMSFNCChartSerie;
begin
  BeginUpdate;

  Result := FSeries.Add;
  Result.LoadFromDataArrayEx(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray);

  EndUpdate;
end;

function TTMSFNCChart.AddSeriesFromMultiPointDataArray(YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray,
  YMedianValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  BeginUpdate;

  Result := FSeries.Add;
  Result.LoadFromMultiPointDataArray(YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray, YMedianValuesArray, XValuesArray, XLabelsArray);

  EndUpdate;
end;

function TTMSFNCChart.AddSeriesFromMultiPointDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray,
  YMedianValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  BeginUpdate;

  Result := FSeries.Add;
  Result.LoadFromMultiPointDataArray(ALoadOptions, YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray, YMedianValuesArray, XValuesArray, XLabelsArray);

  EndUpdate;
end;

function TTMSFNCChart.LoadFromDataArray(ASerieIndex: Integer; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  ASerieIndex := LoadFromDataArray(DefaultLoadOptions, ASerieIndex, YValuesArray, XValuesArray, XLabelsArray).Index;
  Result := FSeries[ASerieIndex];
end;

function TTMSFNCChart.LoadFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  if (ASerieIndex = 0) and (ALoadOptions.FClearSeries) then
    Series.Clear;

  if (ASerieIndex >= 0) and (ASerieIndex < FSeries.Count) then
  begin
    FSeries[ASerieIndex].LoadFromDataArray(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray);
  end
  else
    ASerieIndex := AddSeriesFromDataArray(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray).Index;

  Result := FSeries[ASerieIndex];
end;

function TTMSFNCChart.LoadFromDataArrayEx(ASerieIndex: Integer; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray: TTMSFNCChartValuesArray): TTMSFNCChartSerie;
begin
  ASerieIndex := LoadFromDataArrayEx(DefaultLoadOptions, ASerieIndex, YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray).Index;
  Result := FSeries[ASerieIndex];
end;

function TTMSFNCChart.LoadFromDataArrayEx(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray: TTMSFNCChartValuesArray): TTMSFNCChartSerie;
begin
  if (ASerieIndex = 0) and (ALoadOptions.FClearSeries) then
    Series.Clear;

  if (ASerieIndex >= 0) and (ASerieIndex < FSeries.Count) then
  begin
    FSeries[ASerieIndex].LoadFromDataArrayEx(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray);
  end
  else
    ASerieIndex := AddSeriesFromDataArrayEx(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray).Index;

  Result := FSeries[ASerieIndex];
end;

function TTMSFNCChart.AppendFromDataArray(ASerieIndex: Integer; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  if (ASerieIndex >= 0) and (ASerieIndex < FSeries.Count) then
  begin
    FSeries[ASerieIndex].AppendFromDataArray(YValuesArray, XValuesArray, XLabelsArray);
  end
  else
    ASerieIndex := AddSeriesFromDataArray(YValuesArray, XValuesArray, XLabelsArray).Index;

  Result := FSeries[ASerieIndex];
end;

function TTMSFNCChart.AppendFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  if (ASerieIndex >= 0) and (ASerieIndex < FSeries.Count) then
  begin
    FSeries[ASerieIndex].AppendFromDataArray(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray);
  end
  else
    ASerieIndex := AddSeriesFromDataArray(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray).Index;

  Result := FSeries[ASerieIndex];
end;

function TTMSFNCChart.AppendFromDataArrayEx(ASerieIndex: Integer; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray: TTMSFNCChartValuesArray): TTMSFNCChartSerie;
begin
  if (ASerieIndex >= 0) and (ASerieIndex < FSeries.Count) then
  begin
    FSeries[ASerieIndex].AppendFromDataArrayEx(YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray);
  end
  else
    ASerieIndex := AddSeriesFromDataArrayEx(YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray).Index;

  Result := FSeries[ASerieIndex];
end;

function TTMSFNCChart.AppendFromDataArrayEx(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray: TTMSFNCChartValuesArray): TTMSFNCChartSerie;
begin
  if (ASerieIndex >= 0) and (ASerieIndex < FSeries.Count) then
  begin
    FSeries[ASerieIndex].AppendFromDataArrayEx(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray);
  end
  else
    ASerieIndex := AddSeriesFromDataArrayEx(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray).Index;

  Result := FSeries[ASerieIndex];
end;

function TTMSFNCChart.LoadFromMultiPointDataArray(ASerieIndex: Integer; YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray,
  YMedianValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  ASerieIndex := LoadFromMultiPointDataArray(DefaultLoadOptions, ASerieIndex, YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray, YMedianValuesArray, XValuesArray, XLabelsArray).Index;
  Result := FSeries[ASerieIndex];
end;

function TTMSFNCChart.LoadFromMultiPointDataArray(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray,
  YMedianValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  if ALoadOptions.FClearSeries then
    Series.Clear;

  if (ASerieIndex >= 0) and (ASerieIndex < FSeries.Count) then
  begin
    FSeries[ASerieIndex].LoadFromMultiPointDataArray(ALoadOptions, YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray, YMedianValuesArray, XValuesArray, XLabelsArray);
  end
  else
    ASerieIndex := AddSeriesFromMultiPointDataArray(ALoadOptions, YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray, YMedianValuesArray, XValuesArray, XLabelsArray).Index;

  Result := FSeries[ASerieIndex];
end;

function TTMSFNCChart.AppendFromMultiPointDataArray(ASerieIndex: Integer; YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray,
  YMedianValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  if (ASerieIndex >= 0) and (ASerieIndex < FSeries.Count) then
  begin
    FSeries[ASerieIndex].AppendFromMultiPointDataArray(YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray, YMedianValuesArray, XValuesArray, XLabelsArray);
  end
  else
    ASerieIndex := AddSeriesFromMultiPointDataArray(YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray, YMedianValuesArray, XValuesArray, XLabelsArray).Index;

  Result := FSeries[ASerieIndex];
end;

function TTMSFNCChart.AppendFromMultiPointDataArray(ALoadOptions: TTMSFNCChartLoadOptions; ASerieIndex: Integer; YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray,
  YMedianValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray): TTMSFNCChartSerie;
begin
  if (ASerieIndex >= 0) and (ASerieIndex < FSeries.Count) then
  begin
    FSeries[ASerieIndex].AppendFromMultiPointDataArray(ALoadOptions, YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray, YMedianValuesArray, XValuesArray, XLabelsArray);
  end
  else
    ASerieIndex := AddSeriesFromMultiPointDataArray(ALoadOptions, YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray, YMedianValuesArray, XValuesArray, XLabelsArray).Index;

  Result := FSeries[ASerieIndex];
end;

procedure TTMSFNCChart.LoadFromCSVData(AFileName: string; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
begin
  LoadFromCSVDataEx(AFileName, DefaultLoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, nil, nil);
end;

procedure TTMSFNCChart.LoadFromCSVData(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
begin
  LoadFromCSVDataEx(AFileName, ALoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, nil, nil);
end;

procedure TTMSFNCChart.LoadFromCSVDataEx(AFileName: string; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray);
begin
  LoadFromCSVDataEx(AFileName, DefaultLoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, YValueSecColumnIndexesArray, YValueVarColumnIndexesArray);
end;

procedure TTMSFNCChart.LoadFromCSVDataEx(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray);
begin
  InternalLoadFromCSV(AFileName, ALoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, YValueSecColumnIndexesArray, YValueVarColumnIndexesArray);
end;

procedure TTMSFNCChart.LoadFromCSVMultiPointData(AFileName: string; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
begin
  LoadFromCSVMultiPointData(AFileName, DefaultLoadOptions, YValueHighColumn, YValueLowColumnIndex, YValueOpenColumnIndex, YValueCloseColumnIndex, YValueMedianColumnIndex, XValueColumnIndex, XLabelColumnIndex)
end;

procedure TTMSFNCChart.LoadFromCSVMultiPointData(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
var
  a: TTMSFNCChartColumnsArray;
begin
  SetLength(a,1);
  a[0] := YValueHighColumn;
  InternalLoadFromCSV(AFileName, ALoadOptions, a, XValueColumnIndex, XLabelColumnIndex, nil, nil, YValueLowColumnIndex, YValueOpenColumnIndex, YValueCloseColumnIndex, YValueMedianColumnIndex);
end;

procedure TTMSFNCChart.LoadFromCSVStreamData(AStream: TStream; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
begin
  LoadFromCSVStreamDataEx(AStream, DefaultLoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, nil, nil);
end;

procedure TTMSFNCChart.LoadFromCSVStreamData(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
begin
  LoadFromCSVStreamDataEx(AStream, ALoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, nil, nil);
end;

procedure TTMSFNCChart.LoadFromCSVStreamDataEx(AStream: TStream; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray);
begin
  LoadFromCSVStreamDataEx(AStream, DefaultLoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, YValueSecColumnIndexesArray, YValueVarColumnIndexesArray);
end;

procedure TTMSFNCChart.LoadFromCSVStreamDataEx(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray);
begin
  InternalLoadFromCSVStream(AStream, ALoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, YValueSecColumnIndexesArray, YValueVarColumnIndexesArray);
end;

procedure TTMSFNCChart.LoadFromCSVStreamMultiPointData(AStream: TStream; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
begin
  LoadFromCSVStreamMultiPointData(AStream, DefaultLoadOptions, YValueHighColumn, YValueLowColumnIndex, YValueOpenColumnIndex, YValueCloseColumnIndex, YValueMedianColumnIndex, XValueColumnIndex, XLabelColumnIndex)
end;

procedure TTMSFNCChart.LoadFromCSVStreamMultiPointData(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
var
  a: TTMSFNCChartColumnsArray;
begin
  SetLength(a,1);
  a[0] := YValueHighColumn;
  InternalLoadFromCSVStream(AStream, ALoadOptions, a, XValueColumnIndex, XLabelColumnIndex, nil, nil, YValueLowColumnIndex, YValueOpenColumnIndex, YValueCloseColumnIndex, YValueMedianColumnIndex);
end;

procedure TTMSFNCChart.LoadFromCSVTextData(AText: string; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
begin
  LoadFromCSVTextDataEx(AText, DefaultLoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, nil, nil);
end;

procedure TTMSFNCChart.LoadFromCSVTextData(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
begin
  LoadFromCSVTextDataEx(AText, ALoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, nil, nil);
end;

procedure TTMSFNCChart.LoadFromCSVTextDataEx(AText: string; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray);
begin
  LoadFromCSVTextDataEx(AText, DefaultLoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, YValueSecColumnIndexesArray, YValueVarColumnIndexesArray);
end;

procedure TTMSFNCChart.LoadFromCSVTextDataEx(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray);
begin
  InternalLoadFromCSVText(AText, ALoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, YValueSecColumnIndexesArray, YValueVarColumnIndexesArray);
end;

procedure TTMSFNCChart.LoadFromCSVTextMultiPointData(AText: string; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
begin
  LoadFromCSVTextMultiPointData(AText, DefaultLoadOptions, YValueHighColumn, YValueLowColumnIndex, YValueOpenColumnIndex, YValueCloseColumnIndex, YValueMedianColumnIndex, XValueColumnIndex, XLabelColumnIndex)
end;

procedure TTMSFNCChart.LoadFromCSVTextMultiPointData(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueHighColumn: Integer; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer; XValueColumnIndex: Integer; XLabelColumnIndex: Integer);
var
  a: TTMSFNCChartColumnsArray;
begin
  SetLength(a,1);
  a[0] := YValueHighColumn;
  InternalLoadFromCSVText(AText, ALoadOptions, a, XValueColumnIndex, XLabelColumnIndex, nil, nil, YValueLowColumnIndex, YValueOpenColumnIndex, YValueCloseColumnIndex, YValueMedianColumnIndex);
end;

procedure TTMSFNCChart.InternalLoadFromCSV(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer);
var
  ms: TMemoryStream;
  procedure InternalInputFromCSV;
  begin
    ms.Position := 0;
    InternalLoadFromCSVStream(ms, ALoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, YValueSecColumnIndexesArray, YValueVarColumnIndexesArray, YValueLowColumnIndex, YValueOpenColumnIndex, YValueCloseColumnIndex, YValueMedianColumnIndex);
  end;
begin
  ms := TMemoryStream.Create;
  try
    {$IFDEF WEBLIB}
    ms.LoadFromFile(AFileName,
    procedure
    begin
      InternalInputFromCSV;
      ms.Free;
    end
    );
    {$ENDIF}
    {$IFNDEF WEBLIB}
    ms.LoadFromFile(AFileName);
    InternalInputFromCSV;
    {$ENDIF}
  finally
    {$IFNDEF WEBLIB}
    ms.Free;
    {$ENDIF}
  end;
end;

procedure TTMSFNCChart.InternalLoadFromCSVStream(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer);
var
  SL: TStringList;
begin
  SL := TStringList.Create;
  try
    AStream.Position := 0;
//    SL.LoadFromStream(AStream{$IFNDEF LCLWEBLIB}, TEncoding.UTF8{$ENDIF});    //GVH Error with Encoding
    SL.LoadFromStream(AStream);
    InternalLoadFromCSVText(SL.Text, ALoadOptions, YValueColumnIndexesArray, XValueColumnIndex, XLabelColumnIndex, YValueSecColumnIndexesArray, YValueVarColumnIndexesArray, YValueLowColumnIndex, YValueOpenColumnIndex, YValueCloseColumnIndex, YValueMedianColumnIndex);
  finally
    SL.Free;
  end;
end;

procedure TTMSFNCChart.InternalLoadFromCSVText(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; YValueColumnIndexesArray: TTMSFNCChartColumnsArray; XValueColumnIndex: Integer; XLabelColumnIndex: Integer; YValueSecColumnIndexesArray: TTMSFNCChartColumnsArray; YValueVarColumnIndexesArray: TTMSFNCChartColumnsArray; YValueLowColumnIndex: Integer; YValueOpenColumnIndex: Integer; YValueCloseColumnIndex: Integer; YValueMedianColumnIndex: Integer);
var
  sl,buffer: TStringList;
  YValues, YValuesSec, YValuesVar: Array of TTMSFNCChartValuesArray;
  XValues, YValuesOpen, YValuesClose, YValuesLow, YValuesMedian: TTMSFNCChartValuesArray;
  XLabels: TTMSFNCChartLabelsArray;
  XLabelName: string;
  I, J, Line: Integer;
  v: Double;
  startIdx: integer;
  Delim: Char;
begin
  BeginUpdate;

  if ALoadOptions.ClearSeries then
    Series.Clear;

  sl := TStringList.Create;
  buffer := TStringList.Create;
  try
    sl.Text := AText;

    if ALoadOptions.CSVFirstLine in [cflFirstLineSkip, cflFirstLineLabel] then
      Line := 1
    else
      Line := 0;

    // Initialize all series arrays
    SetLength(YValues, Length(YValueColumnIndexesArray));
    SetLength(YValuesSec, Length(YValueColumnIndexesArray));
    SetLength(YValuesVar, Length(YValueColumnIndexesArray));

    startIdx := Series.Count;

    Delim := ALoadOptions.CSVDelimiter;

    if Delim = #0 then
    begin
      if sl.Count > 1 then
        Delim := TTMSFNCUtils.GetBestDelimiter(sl[0], sl[1])
      else
        Delim := TTMSFNCUtils.GetBestDelimiter(sl[0], '');
    end;

    for I := 0 to Length(YValueColumnIndexesArray) - 1 do
    begin
      SetLength(YValues[I], sl.Count - Line);
      Series.Add;
      Series[startIdx + I].XValues.MajorUnitFormatType := ALoadOptions.XValuesFormatType;
      Series[startIdx + I].XValues.MajorUnitFormat := ALoadOptions.XValuesFormatString;
      Series[startIdx + I].XValues.MinorUnitFormatType := ALoadOptions.XValuesFormatType;
      Series[startIdx + I].XValues.MinorUnitFormat := ALoadOptions.XValuesFormatString;
      Series[startIdx + I].YValues.MajorUnitFormatType := ALoadOptions.YValuesFormatType;
      Series[startIdx + I].YValues.MajorUnitFormat := ALoadOptions.YValuesFormatString;
      Series[startIdx + I].YValues.MinorUnitFormatType := ALoadOptions.YValuesFormatType;
      Series[startIdx + I].YValues.MinorUnitFormat := ALoadOptions.YValuesFormatString;
      Series[startIdx + I].AutoXRange := ALoadOptions.XRange;
      Series[startIdx + I].AutoYRange := ALoadOptions.YRange;
      Series[startIdx + I].MaxYOffsetPercentage := ALoadOptions.MaxYOffsetPercentage;
      if (startIdx + I) > 0 then
      begin
        Series[startIdx + I].XValues.Positions := [];
        Series[startIdx + I].YValues.Positions := [];
      end
      else
      begin
        Series[startIdx + I].XGrid.Visible := ALoadOptions.XGrid;
        Series[startIdx + I].YGrid.Visible := ALoadOptions.YGrid;
      end;

      if XLabelColumnIndex < 0 then
        Series[startIdx + I].XValues.AutoUnits := True;
    end;

    for I := 0 to Length(YValueSecColumnIndexesArray) - 1 do
    begin
      SetLength(YValuesSec[I], sl.Count - Line);
    end;

    for I := 0 to Length(YValueSecColumnIndexesArray) - 1 do
    begin
      SetLength(YValuesSec[I], sl.Count - Line);
    end;

    if XValueColumnIndex >= 0 then
      SetLength(XValues, sl.Count - Line);

    if XLabelColumnIndex >= 0 then
      SetLength(XLabels, sl.Count - Line);

    if YValueLowColumnIndex >= 0 then
      SetLength(YValuesLow, sl.Count - Line);

    if YValueOpenColumnIndex >= 0 then
      SetLength(YValuesOpen, sl.Count - Line);

    if YValueCloseColumnIndex >= 0 then
      SetLength(YValuesClose, sl.Count - Line);

    if YValueMedianColumnIndex >= 0 then
      SetLength(YValuesMedian, sl.Count - Line);

    //Hanle if first line is label
    if ALoadOptions.CSVFirstLine = cflFirstLineLabel then
    begin
      TTMSFNCUtils.Split(Delim, sl[0], buffer);

      if XLabelColumnIndex >= 0 then
        XLabelName := buffer[XLabelColumnIndex];

      if XValueColumnIndex >= 0 then
      begin
        v := -1;
        TryStrToFloat(buffer[XValueColumnIndex], v);
        if v >=0 then
          XValues[0] := v;
      end;

      for J := 0 to Length(YValueColumnIndexesArray) - 1 do
      begin
        Series[startIdx + J].LegendText := buffer[YValueColumnIndexesArray[J]];
        Series[startIdx + J].XValues.Title.Text := XLabelName;
      end;
    end;

    //Handle CSV Values
    for I := Line to sl.Count - 1 do
    begin
      TTMSFNCUtils.Split(Delim,sl[I], buffer);

      if XLabelColumnIndex >= 0 then
          XLabels[I - Line] := buffer[XLabelColumnIndex];

      if XValueColumnIndex >= 0 then
      begin
        begin
          case ALoadOptions.XValuesFormatType of
            vftDateTime:
            begin
              v := StrToDateTime(buffer[XValueColumnIndex]);
              DoParseDateTimeStringToDateTime(buffer[XValueColumnIndex], TDateTime(v));
              XValues[I - Line] := v;
            end;
            else
            begin
              v := -1;
              TryStrToFloat(buffer[XValueColumnIndex], v);
              if v >=0 then
                XValues[I - Line] := v;
            end;
          end;
        end;
      end;

      for J := 0 to Length(YValueColumnIndexesArray) - 1 do
      begin
        v := 0;
        TryStrToFloat(buffer[YValueColumnIndexesArray[J]], v);
        YValues[J][I - Line] := v;
      end;

      for J := 0 to Length(YValueSecColumnIndexesArray) - 1 do
      begin
        v := 0;
        TryStrToFloat(buffer[YValueSecColumnIndexesArray[J]], v);
        YValuesSec[J][I - Line] := v;
      end;

      for J := 0 to Length(YValueVarColumnIndexesArray) - 1 do
      begin
        v := 0;
        TryStrToFloat(buffer[YValueVarColumnIndexesArray[J]], v);
        YValuesVar[J][I - Line] := v;
      end;

      if YValueLowColumnIndex >= 0 then
      begin
        v := 0;
        TryStrToFloat(buffer[YValueLowColumnIndex], v);
        YValuesLow[I - Line] := v;
      end;

      if YValueOpenColumnIndex >= 0 then
      begin
        v := 0;
        TryStrToFloat(buffer[YValueOpenColumnIndex], v);
        YValuesOpen[I - Line] := v;
      end;

      if YValueCloseColumnIndex >= 0 then
      begin
        v := 0;
        TryStrToFloat(buffer[YValueCloseColumnIndex], v);
        YValuesClose[I - Line] := v;
      end;

      if YValueMedianColumnIndex >= 0 then
      begin
        v := 0;
        TryStrToFloat(buffer[YValueMedianColumnIndex], v);
        YValuesMedian[I - Line] := v;
      end;
    end;

    for I := 0 to Length(YValues) - 1 do
    begin
      Series[startIdx + I].InternalLoadFromDataArray(YValues[I], XValues, XLabels, YValuesSec[I], YValuesVar[I], YValuesOpen, YValuesClose, YValuesLow, YValuesMedian);
    end;
  finally
    sl.Free;
    buffer.Free;
    EndUpdate;
  end;
end;

procedure TTMSFNCChart.LoadFromJSONData(AFileName, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONDataEx(AFileName, DefaultLoadOptions, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONDataEx(AFileName, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, AYSecValueName, AYVarValueName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONDataEx(AFileName, DefaultLoadOptions, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, AYSecValueName , AYVarValueName, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONData(AFileName, ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONDataEx(AFileName, DefaultLoadOptions, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, nil, nil, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONDataEx(AFileName, ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONDataEx(AFileName, DefaultLoadOptions, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONMultiPointData(AFileName, ASeriesName, APointsName, AYHighValueName, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, AXValueName, AXLabelName : string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONMultiPointData(AFileName, DefaultLoadOptions, ASeriesName, APointsName, AYHighValueName, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, AXValueName, AXLabelName, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONStreamData(AStream: TStream; ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONStreamDataEx(AStream, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONStreamDataEx(AStream: TStream; ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, AYSecValueName, AYVarValueName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONStreamDataEx(AStream, DefaultLoadOptions, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, AYSecValueName , AYVarValueName, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONStreamData(AStream: TStream; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONStreamDataEx(AStream, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, nil, nil, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONStreamDataEx(AStream: TStream; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONStreamDataEx(AStream, DefaultLoadOptions, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONStreamMultiPointData(AStream: TStream; ASeriesName, APointsName, AYHighValueName, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, AXValueName, AXLabelName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONStreamMultiPointData(AStream, DefaultLoadOptions, ASeriesName, APointsName, AYHighValueName, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, AXValueName, AXLabelName, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONTextData(AText, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONTextDataEx(AText, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONTextDataEx(AText, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, AYSecValueName, AYVarValueName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONTextDataEx(AText, DefaultLoadOptions, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, AYSecValueName , AYVarValueName, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONTextData(AText: string; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONTextDataEx(AText, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, nil, nil, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONTextDataEx(AText: string; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONTextDataEx(AText, DefaultLoadOptions, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames , AYVarValueNames, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONTextMultiPointData(AText, ASeriesName, APointsName, AYHighValueName, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, AXValueName, AXLabelName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONTextMultiPointData(AText, DefaultLoadOptions, ASeriesName, APointsName, AYHighValueName, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, AXValueName, AXLabelName, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONData(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONDataEx(AFileName, ALoadOptions, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONDataEx(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, AYSecValueName, AYVarValueName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
var
  ayval, axval, axlab, aysec, ayvar: TTMSFNCChartJSONNamesArray;
begin
  SetLength(ayval, 1);
  ayval[0] := AYValueName;
  SetLength(axval, 1);
  axval[0] := AXValueName;
  SetLength(axlab, 1);
  axlab[0] := AXLabelName;
  SetLength(aysec, 1);
  aysec[0] := AYSecValueName;
  SetLength(ayvar, 1);
  ayvar[0] := AYVarValueName;
  InternalLoadFromJSON(AFileName, ALoadOptions, ASeriesName, APointsName, ayval, axval, axlab, aysec, ayvar, '', '', '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONData(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONDataEx(AFileName, ALoadOptions, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, nil, nil, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONDataEx(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  InternalLoadFromJSON(AFileName, ALoadOptions, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames, '', '', '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONMultiPointData(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName, AYHighValueName, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, AXValueName, AXLabelName : string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
var
  ayval, axval, axlab, aysec, ayvar: TTMSFNCChartJSONNamesArray;
begin
  SetLength(ayval, 1);
  ayval[0] := AYHighValueName;
  SetLength(axval, 1);
  axval[0] := AXValueName;
  SetLength(axlab, 1);
  axlab[0] := AXLabelName;
  SetLength(aysec, 0);
  SetLength(ayvar, 0);
  InternalLoadFromJSON(AFileName, ALoadOptions, ASeriesName, APointsName, ayval, axval, axlab, aysec, ayvar, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONStreamData(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONStreamDataEx(AStream, ALoadOptions, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONStreamDataEx(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, AYSecValueName, AYVarValueName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
var
  ayval, axval, axlab, aysec, ayvar: TTMSFNCChartJSONNamesArray;
begin
  SetLength(ayval, 1);
  ayval[0] := AYValueName;
  SetLength(axval, 1);
  axval[0] := AXValueName;
  SetLength(axlab, 1);
  axlab[0] := AXLabelName;
  SetLength(aysec, 1);
  aysec[0] := AYSecValueName;
  SetLength(ayvar, 1);
  ayvar[0] := AYVarValueName;
  InternalLoadFromJSONStream(AStream, ALoadOptions, ASeriesName, APointsName, ayval, axval, axlab, aysec , ayvar, '', '', '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONStreamData(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONStreamDataEx(AStream, ALoadOptions, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, nil, nil, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONStreamDataEx(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  InternalLoadFromJSONStream(AStream, ALoadOptions, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames , AYVarValueNames, '', '', '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONStreamMultiPointData(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName, AYHighValueName, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, AXValueName, AXLabelName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
var
  ayval, axval, axlab, aysec, ayvar: TTMSFNCChartJSONNamesArray;
begin
  SetLength(ayval, 1);
  ayval[0] := AYHighValueName;
  SetLength(axval, 1);
  axval[0] := AXValueName;
  SetLength(axlab, 1);
  axlab[0] := AXLabelName;
  SetLength(aysec, 0);
  SetLength(ayvar, 0);
  InternalLoadFromJSONStream(AStream, ALoadOptions, ASeriesName, APointsName, ayval, axval, axlab, aysec , ayvar, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONTextData(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONTextDataEx(AText, ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONTextDataEx(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName, AYValueName, AXValueName, AXLabelName, AYSecValueName, AYVarValueName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
var
  ayval, axval, axlab, aysec, ayvar: TTMSFNCChartJSONNamesArray;
begin
  SetLength(ayval, 1);
  ayval[0] := AYValueName;
  SetLength(axval, 1);
  axval[0] := AXValueName;
  SetLength(axlab, 1);
  axlab[0] := AXLabelName;
  SetLength(aysec, 1);
  aysec[0] := AYSecValueName;
  SetLength(ayvar, 1);
  ayvar[0] := AYVarValueName;
  InternalLoadFromJSONText(AText, ALoadOptions, ASeriesName, APointsName, ayval, axval, axlab, aysec , ayvar, '', '', '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONTextData(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  LoadFromJSONTextDataEx(AText, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, nil, nil, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONTextDataEx(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames: TTMSFNCChartJSONNamesArray; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
begin
  InternalLoadFromJSONText(AText, ALoadOptions, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames, '', '', '', '', ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.LoadFromJSONTextMultiPointData(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName, AYHighValueName, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, AXValueName, AXLabelName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
var
  ayval, axval, axlab, aysec, ayvar: TTMSFNCChartJSONNamesArray;
begin
  SetLength(ayval, 1);
  ayval[0] := AYHighValueName;
  SetLength(axval, 1);
  axval[0] := AXValueName;
  SetLength(axlab, 1);
  axlab[0] := AXLabelName;
  SetLength(aysec, 0);
  SetLength(ayvar, 0);
  InternalLoadFromJSONText(AText, ALoadOptions, ASeriesName, APointsName, ayval, axval, axlab, aysec , ayvar, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, ASerieCallBack, APointCallBack);
end;

procedure TTMSFNCChart.InternalLoadFromJSON(AFileName: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames: TTMSFNCChartJSONNamesArray; AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
var
  ms: TMemoryStream;
  procedure InternalInputFromJSON;
  begin
    ms.Position := 0;
    InternalLoadFromJSONStream(ms, ALoadOptions, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, ASerieCallBack, APointCallBack);
  end;
begin
  ms := TMemoryStream.Create;
  try
    {$IFDEF WEBLIB}
    ms.LoadFromFile(AFileName,
    procedure
    begin
      InternalInputFromJSON;
      ms.Free;
    end
    );
    {$ENDIF}
    {$IFNDEF WEBLIB}
    ms.LoadFromFile(AFileName);
    InternalInputFromJSON;
    {$ENDIF}
  finally
    {$IFNDEF WEBLIB}
    ms.Free;
    {$ENDIF}
  end;
end;

procedure TTMSFNCChart.InternalLoadFromJSONStream(AStream: TStream; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames: TTMSFNCChartJSONNamesArray; AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
var
  SL: TStringList;
begin
  SL := TStringList.Create;
  try
    AStream.Position := 0;
//    SL.LoadFromStream(AStream{$IFNDEF LCLWEBLIB}, TEncoding.UTF8{$ENDIF});    //GVH Error with Encoding
    SL.LoadFromStream(AStream);
    InternalLoadFromJSONText(SL.Text, ALoadOptions, ASeriesName, APointsName, AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames, AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName, ASerieCallBack, APointCallBack);
  finally
    SL.Free;
  end;
end;

procedure TTMSFNCChart.InternalLoadFromJSONText(AText: string; ALoadOptions: TTMSFNCChartLoadOptions; ASeriesName, APointsName: string; AYValueNames, AXValueNames, AXLabelNames, AYSecValueNames, AYVarValueNames: TTMSFNCChartJSONNamesArray; AYLowValueName, AYOpenValueName, AYCloseValueName, AYMedianValueName: string; ASerieCallBack: TTMSFNCChartAddJSONSerieCallBackEvent; APointCallBack: TTMSFNCChartAddJSONPointCallBackEvent);
var
  j, jc, jo : TJSONValue;
  ja: TJSONArray;
  I: Integer;
  d: Double;
  dateStr: string;
  s: TTMSFNCChartSerie;
  p: TTMSFNCChartPoint;
  startIdx: Integer;

  procedure ParsePoint(ASerie: TTMSFNCChartSerie; pj: TJSONValue);
  var
    pjc: TJSONValue;
    K: Integer;
  begin
    if (Length(AYValueNames) = 0) then
    begin
      d := -123456789;
      TTMSFNCUtils.TryStrToFloatDot(pj.AsString, d);
      if d <> -123456789 then
      begin
        if Series.Count = startIdx then
        begin
          s := Series.Add;
          s.AutoXRange := ALoadOptions.XRange;
          s.AutoYRange := ALoadOptions.YRange;
          s.XValues.MajorUnitFormatType := ALoadOptions.XValuesFormatType;
          s.XValues.MajorUnitFormat := ALoadOptions.XValuesFormatString;
          s.XValues.MinorUnitFormatType := ALoadOptions.XValuesFormatType;
          s.XValues.MinorUnitFormat := ALoadOptions.XValuesFormatString;
          s.YValues.MajorUnitFormatType := ALoadOptions.YValuesFormatType;
          s.YValues.MajorUnitFormat := ALoadOptions.YValuesFormatString;
          s.YValues.MinorUnitFormatType := ALoadOptions.YValuesFormatType;
          s.YValues.MinorUnitFormat := ALoadOptions.YValuesFormatString;
          s.MaxYOffsetPercentage := ALoadOptions.MaxYOffsetPercentage;
          s.XValues.AutoUnits := True;

          if (s.Index) > 0 then
          begin
            s.XValues.Positions := [];
            s.YValues.Positions := [];
          end
          else
          begin
            s.XGrid.Visible := ALoadOptions.XGrid;
            s.YGrid.Visible := ALoadOptions.YGrid;
          end;
        end;

        p := s.Points.Add;
        p.YValue := d;
      end;
    end
    else
    begin
      for K := 0 to Length(AYvalueNames) - 1 do
      begin

        p := Series[startIdx + K].Points.Add;

        pjc := TTMSFNCUtils.GetJSONValue(pj, AYValueNames[K]);
        if Assigned(pjc) then
        begin
          d := 0;
          TTMSFNCUtils.TryStrToFloatDot(TTMSFNCUtils.GetJSONProp(pj, AYValueNames[K]), d);
          p.YValue := d;
        end
        else
          p.YValue := pj.AsDouble;

        if K < Length(AYSecValueNames) then
        begin
          pjc := TTMSFNCUtils.GetJSONValue(pj, AYSecValueNames[K]);
          if Assigned(pjc) then
          begin
            d := 0;
            TTMSFNCUtils.TryStrToFloatDot(TTMSFNCUtils.GetJSONProp(pj, AYSecValueNames[K]), d);
            p.YValueSecond := d;
          end;
        end;

        if K < Length(AYVarValueNames) then
        begin
          pjc := TTMSFNCUtils.GetJSONValue(pj, AYVarValueNames[K]);
          if Assigned(pjc) then
          begin
            d := 0;
            TTMSFNCUtils.TryStrToFloatDot(TTMSFNCUtils.GetJSONProp(pj, AYVarValueNames[K]), d);
            p.YValueVariable := d;
          end;
        end;

        if K < Length(AXValueNames) then
        begin
          pjc := TTMSFNCUtils.GetJSONValue(pj, AXValueNames[K]);
          if Assigned(pjc) then
          begin
            d := -1;
            TTMSFNCUtils.TryStrToFloatDot(TTMSFNCUtils.GetJSONProp(pj, AXValueNames[K]), d);
            if d >= 0 then
              p.XValue := d
            else
            begin
              dateStr := TTMSFNCUtils.GetJSONProp(pj, AXValueNames[K]);
              d := TTMSFNCUtils.ISOToDateTime(dateStr);
              DoParseDateTimeStringToDateTime(dateStr, TDateTime(d));
              if d > 0 then
                p.XValue := d;
            end;
          end;
        end
        else if (p.Serie.XValues.MajorUnitFormatType = vftDateTime) and (Length(AXValueNames) > 0) then
        begin
          pjc := TTMSFNCUtils.GetJSONValue(pj, AXValueNames[Length(AXValueNames) - 1]);
          if Assigned(pjc) then
          begin
            d := -1;
            TTMSFNCUtils.TryStrToFloatDot(TTMSFNCUtils.GetJSONProp(pj, AXValueNames[Length(AXValueNames) - 1]), d);
            if d >= 0 then
              p.XValue := d
            else
            begin
              dateStr := TTMSFNCUtils.GetJSONProp(pj, AXValueNames[Length(AXValueNames) - 1]);
              d := TTMSFNCUtils.ISOToDateTime(dateStr);
              DoParseDateTimeStringToDateTime(dateStr, TDateTime(d));
              if d > 0 then
                p.XValue := d;
            end;
          end;
        end;

        if K < Length(AXLabelNames) then
        begin
          pjc := TTMSFNCUtils.GetJSONValue(pj, AXLabelNames[K]);
          if Assigned(pjc) then
          begin
            p.XValueText := TTMSFNCUtils.GetJSONProp(pj, AXLabelNames[K]);
          end;
        end;

        pjc := TTMSFNCUtils.GetJSONValue(pj, AYLowValueName);
        if Assigned(pjc) then
        begin
          d := 0;
          TTMSFNCUtils.TryStrToFloatDot(TTMSFNCUtils.GetJSONProp(pj, AYLowValueName), d);
          p.YValueLow := d;
        end;

        pjc := TTMSFNCUtils.GetJSONValue(pj, AYOpenValueName);
        if Assigned(pjc) then
        begin
          d := 0;
          TTMSFNCUtils.TryStrToFloatDot(TTMSFNCUtils.GetJSONProp(pj, AYOpenValueName), d);
          p.YValueOpen := d;
        end;

        pjc := TTMSFNCUtils.GetJSONValue(pj, AYCloseValueName);
        if Assigned(pjc) then
        begin
          d := 0;
          TTMSFNCUtils.TryStrToFloatDot(TTMSFNCUtils.GetJSONProp(pj, AYCloseValueName), d);
          p.YValueClose := d;
        end;

        pjc := TTMSFNCUtils.GetJSONValue(pj, AYMedianValueName);
        if Assigned(pjc) then
        begin
          d := 0;
          TTMSFNCUtils.TryStrToFloatDot(TTMSFNCUtils.GetJSONProp(pj, AYMedianValueName), d);
          p.YValueMedian := d;
        end;

        DoJSONAddPoint(Series[startIdx + K], p, pj);
        if Assigned(APointCallBack) then
          APointCallBack(Series[startIdx + K], p, pj);
      end;
    end;
  end;

  procedure ParseSerie(sj: TJSONValue);
  var
    sjc, sjo: TJSONValue;
    sja: TJSONArray;
    K: Integer;
  begin
    startIdx := Series.Count;

    for K := 0 to Length(AYvalueNames) - 1 do
    begin
      s := Series.Add;
      s.AutoXRange := ALoadOptions.XRange;
      s.AutoYRange := ALoadOptions.YRange;
      s.XValues.MajorUnitFormatType := ALoadOptions.XValuesFormatType;
      s.XValues.MajorUnitFormat := ALoadOptions.XValuesFormatString;
      s.XValues.MinorUnitFormatType := ALoadOptions.XValuesFormatType;
      s.XValues.MinorUnitFormat := ALoadOptions.XValuesFormatString;
      s.YValues.MajorUnitFormatType := ALoadOptions.YValuesFormatType;
      s.YValues.MajorUnitFormat := ALoadOptions.YValuesFormatString;
      s.YValues.MinorUnitFormatType := ALoadOptions.YValuesFormatType;
      s.YValues.MinorUnitFormat := ALoadOptions.YValuesFormatString;
      s.MaxYOffsetPercentage := ALoadOptions.MaxYOffsetPercentage;
      if (s.Index) > 0 then
      begin
        s.XValues.Positions := [];
        s.YValues.Positions := [];
      end
      else
      begin
        s.XGrid.Visible := ALoadOptions.XGrid;
        s.YGrid.Visible := ALoadOptions.YGrid;
      end;

      if Length(AXValueNames) <= 0 then
        s.XValues.AutoUnits := True;
    end;

    sjc := TTMSFNCUtils.GetJSONValue(sj, APointsName);
    if Assigned(sjc) then
    begin
      if sjc is TJSONArray then
      begin
        sja := (sjc as TJSONArray);
        for K := 0 to TTMSFNCUtils.GetJSONArraySize(sja) - 1 do
        begin
          sjo := TTMSFNCUtils.GetJSONArrayItem(sja, K);
          ParsePoint(s, sjo);
        end
      end
      else
        ParsePoint(s, sjc);
    end
    else
    begin
      if sj is TJSONArray then
      begin
        sja := (sj as TJSONArray);
        for K := 0 to TTMSFNCUtils.GetJSONArraySize(sja) - 1 do
        begin
          sjo := TTMSFNCUtils.GetJSONArrayItem(sja, K);
          ParsePoint(s, sjo);
        end
      end
      else
        ParsePoint(s, sjc);
    end;

    for K := 0 to Length(AYvalueNames) - 1 do
    begin
      DoJSONAddSerie(Series[startIdx + k], sj);
      if Assigned(ASerieCallBack) then
        ASerieCallBack(Series[startIdx + k], sj);
    end;
  end;
begin
  BeginUpdate;

  if ALoadOptions.ClearSeries then
    Series.Clear;

  j := TTMSFNCUtils.ParseJSON(AText);
  if Assigned(j) then
  try
    jc := TTMSFNCUtils.GetJSONValue(j, ASeriesName);

    if Assigned(jc) then
    begin
      if jc is TJSONArray then
      begin
        ja := (jc as TJSONArray);
        for I := 0 to TTMSFNCUtils.GetJSONArraySize(ja) - 1 do
        begin
          jo := TTMSFNCUtils.GetJSONArrayItem(ja, i);
          ParseSerie(jo);
        end
      end
      else
        ParseSerie(jc);
    end
    else
    begin
      ParseSerie(j);
    end;
  finally
    j.Free;
  end;

  EndUpdate;
end;

function TTMSFNCChart.IsAppearanceProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind): Boolean;
var
  Prop: TTMSFNCPropertyInfo;
  pName, cn: string;
begin
  Result := False;
  Prop := GetPropInfo(AObject, APropertyName);
  if Assigned(Prop) then
  begin
    pName := TTMSFNCPersistence.GetPropInfoName(Prop);

    cn := TTMSFNCPersistence.GetPropInfoTypeName(Prop);
    Result := (cn = 'TAlphaColor') or (cn = 'TColor') or (cn = 'TGraphicsColor') or
      (cn = 'TTMSFNCGraphicsFill') or (cn = 'TTMSFNCGraphicsStroke') or (Pos('Appearance', pName) > 0);
  end;
end;

function TTMSFNCChart.CanSaveProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind): Boolean;
begin
  if (AObject = Self) then
    Result := (TTMSFNCUtils.IndexOfTextInArray(APropertyName, ExcludePropertyList) = -1)
  else
    Result := True;

  if FAppearancePersisting then
    Result := Result and IsAppearanceProperty(AObject, APropertyName, APropertyType);

  DoCanSaveProperty(AObject, APropertyName, APropertyType, Result);
end;

function TTMSFNCChart.CanLoadProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind): Boolean;
begin
  if (AObject = Self) then
    Result := (TTMSFNCUtils.IndexOfTextInArray(APropertyName, ExcludePropertyList) = -1)
  else
    Result := True;

  DoCanLoadProperty(AObject, APropertyName, APropertyType, Result);
end;

procedure TTMSFNCChart.SaveSettingsToFile(AFileName: string; AAppearanceOnly: Boolean = False);
begin
  FAppearancePersisting := AAppearanceOnly;
  TTMSFNCPersistence.SaveSettingsToFile(Self, AFileName);
  FAppearancePersisting := False;
end;

procedure TTMSFNCChart.LoadSettingsFromFile(AFileName: string);
begin
  TTMSFNCPersistence.LoadSettingsFromFile(Self, AFileName);
end;

procedure TTMSFNCChart.SaveSettingsToStream(AStream: TStream; AAppearanceOnly: Boolean = False);
begin
  FAppearancePersisting := AAppearanceOnly;
  TTMSFNCPersistence.SaveSettingsToStream(Self, AStream);
  FAppearancePersisting := False;
end;

procedure TTMSFNCChart.LoadSettingsFromStream(AStream: TStream);
begin
  TTMSFNCPersistence.LoadSettingsFromStream(Self, AStream);
end;

{ TTMSFNCChartBaseComponent }

constructor TTMSFNCChartBaseComponent.Create(AOwner: TComponent);
begin
  inherited;
  Width := 30;
  Height := 30;
  {$IFDEF FMXLIB}
  if not (csDesigning in ComponentState) then
    Visible := False;
  {$ELSE}
  Visible := False;
  {$ENDIF}
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCChartBaseComponent.Paint;
var
  {$IFDEF VCLLIB}
  png: TPngImage;
  {$ENDIF}
  pic: TTMSFNCBitmap;
  r: TResourceStream;
  g: TTMSFNCGraphics;

  function FindRCData(ModuleHandle: HMODULE; Name:string): boolean;
  begin
    Result := FindResource(ModuleHandle, PChar(Name), PChar(RT_RCDATA)) <> 0;
  end;
  function GetResourceStream(AResourceName: String): TResourceStream;
  var
    hst: NativeUInt;
  begin
    Result := nil;
    hst := HInstance;
    if FindRCData(hst, AResourceName) then
      Result := TResourceStream.Create(hst, AResourceName, RT_RCDATA);
  end;
begin
  inherited;
  r := nil;
  pic := TTMSFNCBitmap.Create;
  {$IFDEF VCLLIB}
  png := TPNGImage.Create;
  {$ENDIF}
  g := TTMSFNCGraphics.Create(Canvas);
  try
    r := GetResourceStream(UpperCase(ClassName));

    if Assigned(r) then
    begin
      {$IFDEF VCLLIB}
      png.LoadFromStream(r);
      pic.Assign(png);
      {$ELSE}
      pic.LoadFromStream(r);
      {$ENDIF}
    end;

    g.DrawBitmap(RectF(0, 0, Width, Height), pic);
  finally
    if Assigned(r) then
      r.Free;

    pic.Free;
    {$IFDEF VCLLIB}
    png.Free;
    {$ENDIF}
    g.Free;
  end;
end;
{$ENDIF}

{$IFDEF FMXLIB}
procedure TTMSFNCChart.MouseDown(Button: TMouseButton; Shift: TShiftState; X,
  Y: Single);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCChart.MouseDown(Button: TMouseButton; Shift: TShiftState; X,
  Y: Integer);
{$ENDIF}
begin
  inherited;
  if not Interaction then
    Exit;

  {$IFDEF FMXLIB}
  Capture;
  {$ENDIF}
  {$IFDEF VCLLIB}
  SetCapture(Self.Handle);
  {$ENDIF}
  FMouseDown := True;
  FDownPt := PointF(X, Y);
  FMovePt := FDownPt;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCChart.MouseMove(Shift: TShiftState; X, Y: Single);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCChart.MouseMove(Shift: TShiftState; X, Y: Integer);
{$ENDIF}
begin
  inherited;
  if not Interaction then
    Exit;

  FCrosshairPt := PointF(X, Y);
  InvalidateChart;

  if (InteractionOptions.ScaleMode = smNone) and (InteractionOptions.Panning = False) then
    Exit;

  if FMouseDown then
  begin
    FMouseMoved := (Abs(FDownPt.X - X) > INTERACTIONMARGIN) or (Abs(FDownPt.Y - Y) > INTERACTIONMARGIN);
    UpdateRange(FMovePt.X - X, FMovePt.Y - Y, ssCtrl in Shift);
    FMovePt := PointF(X, Y);
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCChart.MouseUp(Button: TMouseButton; Shift: TShiftState; X,
  Y: Single);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCChart.MouseUp(Button: TMouseButton; Shift: TShiftState; X,
  Y: Integer);
{$ENDIF}
var
  ptPoint, ptBar, ptSlice: TTMSFNCChartPoint;
  ptPointv, ptBarv, ptSlicev: TTMSFNCChartPointVirtual;
  b: Boolean;
  i: Integer;
  s: TTMSFNCChartSerie;
begin
  inherited;
  if not Interaction then
    Exit;

  {$IFNDEF LCLLIB}
  ReleaseCapture;
  {$ENDIF}

  FMouseDown := False;
  if FMouseMoved then
  begin
    FMouseMoved := False;
    Exit;
  end;

  ptPointv := GetDefaultVirtualPoint;
  ptBarv := GetDefaultVirtualPoint;
  ptSlicev := GetDefaultVirtualPoint;

  b := False;

  if FVirtualMode then
  begin
    if XYToPointVirtual(X, Y, ptPointv) then
    begin
      DoSeriePointClickVirtual(ptPointv);
      b := True;
    end
    else
    begin
      if XYToBarVirtual(X, Y, ptBarv) then
      begin
        DoSerieBarClickVirtual(ptBarv);
        b := True;
      end;
    end;

    if XYToSliceVirtual(X, Y, ptSlicev) then
    begin
      DoSerieSliceClickVirtual(ptSlicev);
      b := True;
    end;
  end
  else
  begin
    ptPoint := XYToPoint(X, Y);
    if Assigned(ptPoint) then
    begin
      DoSeriePointClick(ptPoint);
      b := True;
    end
    else
    begin
      ptBar := XYToBar(X, Y);
      if Assigned(ptBar) then
      begin
        DoSerieBarClick(ptBar);
        b := True;
      end;
    end;

    ptSlice := XYToSlice(X, Y);
    if Assigned(ptSlice) then
    begin
      DoSerieSliceClick(ptSlice);
      b := True;
    end;
  end;

  if not b then
  begin
    s := nil;
    i := XYToSerieLegendItem(X, Y, s);
    if (i <> -1) and Assigned(s) then
    begin
      DoSerieLegendItemClick(s, i);
      b := True;
    end;

    if not b then
    begin
      i := XYToLegendItem(X, Y);
      if (i <> -1) then
        DoLegendItemClick(i);
    end;
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCChart.DoMouseLeave;
begin
  inherited;
  HandleMouseLeave;
end;

procedure TTMSFNCChart.MouseWheel(Shift: TShiftState; WheelDelta: Integer;
  var Handled: Boolean);
begin
  inherited;
  if not Interaction then
    Exit;

  UpdateRange(0, WheelDelta, ssCtrl in Shift)
end;
{$ENDIF}

procedure TTMSFNCChart.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;

  if (AComponent = FAdapter) and (Operation = opRemove) then
    FAdapter := nil;
end;

{$IFDEF CMNLIB}
{$IFDEF VCLLIB}
procedure TTMSFNCChart.WMGetDlgCode(var Message: TWMGetDlgCode);
begin
  if TabStop then
    Message.Result := DLGC_WANTALLKEYS or DLGC_WANTARROWS
  else
    Message.Result := 0;
end;

procedure TTMSFNCChart.CMMouseLeave(var Message: TMessage);
begin
  inherited;
  HandleMouseLeave;
end;

procedure TTMSFNCChart.WMMouseWheel(var Message: TWMMouseWheel);
var
  State: TKeyboardState;
begin
  inherited;
  if not Interaction then
    Exit;

  GetKeyboardState(State);
  UpdateRange(0, Message.WheelDelta, (State[VK_CONTROL] and 128) <> 0)
end;
{$ENDIF}

{$IFDEF LCLLIB}
procedure TTMSFNCChart.WMGetDlgCode(var Message: TLMNoParams);
begin
  if TabStop then
    Message.Result := DLGC_WANTALLKEYS or DLGC_WANTARROWS
  else
    Message.Result := 0;
end;

procedure TTMSFNCChart.CMMouseLeave(var Message: TLMNoParams);
begin
  HandleMouseLeave;
end;

procedure TTMSFNCChart.WMMouseWheel(var Message: TLMMouseEvent);
begin
  inherited;
  if not Interaction then
    Exit;

  UpdateRange(0, Message.WheelDelta, ssCtrl in Message.State);
end;
{$ENDIF}
{$ENDIF}

procedure TTMSFNCChart.Paint;
var
  g: TTMSFNCGraphics;
begin
  inherited;
  g := TTMSFNCGraphics.Create(Canvas, NativeCanvas);
  try
    g.Context.SetSize(Width, Height);
    g.Context.SetAntiAliasing(AntiAliasing);
    g.Context.SetTextQuality(TextQuality);
    DrawChart(g);
    g.Context.Render;
  finally
    g.Free;
  end;
end;

procedure TTMSFNCChart.DoParseDateTimeStringToDateTime(ADateTimeString: string; var ADateTime: TDateTime);
begin
  if Assigned(OnParseDateTimeStringToDateTime) then
    OnParseDateTimeStringToDateTime(Self, ADateTimeString, ADateTime)
end;

procedure TTMSFNCChart.SetExportRect(ARect: TRectF);
begin

end;

procedure TTMSFNCChart.ApplyExportScale(AScale: Single);
begin

end;

procedure TTMSFNCChart.ResetExportScale;
begin

end;

procedure TTMSFNCChart.&Export({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF);
var
  st: TTMSFNCGraphicsSaveState;
begin
  FExport := True;
  FExportRect := ARect;
  UpdateChart;
  st := AGraphics.SaveState;
  AGraphics.ClipRect(ARect);
  DrawChart(AGraphics);
  AGraphics.RestoreState(st);
  FExport := False;
  UpdateChart;
end;

{$IFDEF VCLLIB}
{$HINTS OFF}
{$IF COMPILERVERSION > 30}
procedure TTMSFNCChart.ChangeScale(M, D: Integer; isDpiChange: Boolean);
{$ELSE}
procedure TTMSFNCChart.ChangeScale(M, D: Integer);
{$IFEND}
var
  I: Integer;
  s: TTMSFNCChartSerie;
begin
  inherited;
  BeginUpdate;
  ClickMargin := TTMSFNCUtils.MulDivSingle(ClickMargin, M, D);
  Legend.Font.Height := TTMSFNCUtils.MulDivInt(Legend.Font.Height, M, D);
  Legend.Left := TTMSFNCUtils.MulDivSingle(Legend.Left, M, D);
  Legend.Top := TTMSFNCUtils.MulDivSingle(Legend.Top, M, D);
  SeriesMargins.Left := TTMSFNCUtils.MulDivInt(SeriesMargins.Left, M, D);
  SeriesMargins.Top := TTMSFNCUtils.MulDivInt(SeriesMargins.Top, M, D);
  SeriesMargins.Right := TTMSFNCUtils.MulDivInt(SeriesMargins.Right, M, D);
  SeriesMargins.Bottom := TTMSFNCUtils.MulDivInt(SeriesMargins.Bottom, M, D);

  for I := 0 to Series.Count - 1 do
  begin
    s := Series[I];
    if s.Bar.WidthType = bwtPixels then
      s.Bar.Width := TTMSFNCUtils.MulDivSingle(s.Bar.Width, M, D);

    s.Bar.Spacing := TTMSFNCUtils.MulDivSingle(s.Bar.Spacing, M, D);

    if s.MultiPoint.WidthType = bwtPixels then
      s.MultiPoint.Width := TTMSFNCUtils.MulDivSingle(s.MultiPoint.Width, M, D);

    s.Pie.Size := TTMSFNCUtils.MulDivSingle(s.Pie.Size, M, D);
    s.Pie.InnerSize := TTMSFNCUtils.MulDivSingle(s.Pie.InnerSize, M, D);
    s.Pie.Margins.Left := TTMSFNCUtils.MulDivInt(s.Pie.Margins.Left, M, D);
    s.Pie.Margins.Top := TTMSFNCUtils.MulDivInt(s.Pie.Margins.Top, M, D);
    s.Pie.Margins.Right := TTMSFNCUtils.MulDivInt(s.Pie.Margins.Right, M, D);
    s.Pie.Margins.Bottom := TTMSFNCUtils.MulDivInt(s.Pie.Margins.Bottom, M, D);

    s.Labels.Font.Height := TTMSFNCUtils.MulDivInt(s.Labels.Font.Height, M, D);
    s.Labels.OffsetX := TTMSFNCUtils.MulDivSingle(s.Labels.OffsetX, M, D);
    s.Labels.OffsetY := TTMSFNCUtils.MulDivSingle(s.Labels.OffsetY, M, D);

    s.Markers.Height := TTMSFNCUtils.MulDivSingle(s.Markers.Height, M, D);
    s.Markers.Width := TTMSFNCUtils.MulDivSingle(s.Markers.Width, M, D);

    s.Offset3DX := TTMSFNCUtils.MulDivSingle(s.Offset3DX, M, D);
    s.Offset3DY := TTMSFNCUtils.MulDivSingle(s.Offset3DY, M, D);

    s.Legend.Font.Height := TTMSFNCUtils.MulDivInt(s.Legend.Font.Height, M, D);
    s.Legend.Left := TTMSFNCUtils.MulDivSingle(s.Legend.Left, M, D);
    s.Legend.Top := TTMSFNCUtils.MulDivSingle(s.Legend.Top, M, D);

    s.XValues.MajorUnitFont.Height := TTMSFNCUtils.MulDivInt(s.XValues.MajorUnitFont.Height, M, D);
    s.XValues.MajorUnitSpacing := TTMSFNCUtils.MulDivSingle(s.XValues.MajorUnitSpacing, M, D);
    s.XValues.MajorUnitTickMarkSize := TTMSFNCUtils.MulDivSingle(s.XValues.MajorUnitTickMarkSize, M, D);
    s.XValues.MinorUnitFont.Height := TTMSFNCUtils.MulDivInt(s.XValues.MinorUnitFont.Height, M, D);
    s.XValues.MinorUnitSpacing := TTMSFNCUtils.MulDivSingle(s.XValues.MinorUnitSpacing, M, D);
    s.XValues.MinorUnitTickMarkSize := TTMSFNCUtils.MulDivSingle(s.XValues.MinorUnitTickMarkSize, M, D);
    s.XValues.Title.Font.Height := TTMSFNCUtils.MulDivInt(s.XValues.Title.Font.Height, M, D);
    s.XValues.Title.TextMargins.Left := TTMSFNCUtils.MulDivInt(s.XValues.Title.TextMargins.Left, M, D);
    s.XValues.Title.TextMargins.Top := TTMSFNCUtils.MulDivInt(s.XValues.Title.TextMargins.Top, M, D);
    s.XValues.Title.TextMargins.Right := TTMSFNCUtils.MulDivInt(s.XValues.Title.TextMargins.Right, M, D);
    s.XValues.Title.TextMargins.Bottom := TTMSFNCUtils.MulDivInt(s.XValues.Title.TextMargins.Bottom, M, D);

    s.CrossHair.XTextFont.Height := TTMSFNCUtils.MulDivInt(s.CrossHair.XTextFont.Height, M, D);
    s.CrossHair.YTextFont.Height := TTMSFNCUtils.MulDivInt(s.CrossHair.YTextFont.Height, M, D);

    s.YValues.MajorUnitFont.Height := TTMSFNCUtils.MulDivInt(s.YValues.MajorUnitFont.Height, M, D);
    s.YValues.MajorUnitSpacing := TTMSFNCUtils.MulDivSingle(s.YValues.MajorUnitSpacing, M, D);
    s.YValues.MajorUnitTickMarkSize := TTMSFNCUtils.MulDivSingle(s.YValues.MajorUnitTickMarkSize, M, D);
    s.YValues.MinorUnitFont.Height := TTMSFNCUtils.MulDivInt(s.YValues.MinorUnitFont.Height, M, D);
    s.YValues.MinorUnitSpacing := TTMSFNCUtils.MulDivSingle(s.YValues.MinorUnitSpacing, M, D);
    s.YValues.MinorUnitTickMarkSize := TTMSFNCUtils.MulDivSingle(s.YValues.MinorUnitTickMarkSize, M, D);
    s.YValues.Title.Font.Height := TTMSFNCUtils.MulDivInt(s.YValues.Title.Font.Height, M, D);
    s.YValues.Title.TextMargins.Left := TTMSFNCUtils.MulDivInt(s.YValues.Title.TextMargins.Left, M, D);
    s.YValues.Title.TextMargins.Top := TTMSFNCUtils.MulDivInt(s.YValues.Title.TextMargins.Top, M, D);
    s.YValues.Title.TextMargins.Right := TTMSFNCUtils.MulDivInt(s.YValues.Title.TextMargins.Right, M, D);
    s.YValues.Title.TextMargins.Bottom := TTMSFNCUtils.MulDivInt(s.YValues.Title.TextMargins.Bottom, M, D);

  end;

  Title.Font.Height := TTMSFNCUtils.MulDivInt(Title.Font.Height, M, D);
  Title.Height := TTMSFNCUtils.MulDivSingle(Title.Height, M, D);
  Title.TextMargins.Left := TTMSFNCUtils.MulDivInt(Title.TextMargins.Left, M, D);
  Title.TextMargins.Top := TTMSFNCUtils.MulDivInt(Title.TextMargins.Top, M, D);
  Title.TextMargins.Right := TTMSFNCUtils.MulDivInt(Title.TextMargins.Right, M, D);
  Title.TextMargins.Bottom := TTMSFNCUtils.MulDivInt(Title.TextMargins.Bottom, M, D);

  XAxis.Height := TTMSFNCUtils.MulDivSingle(XAxis.Height, M, D);
  YAxis.Width := TTMSFNCUtils.MulDivSingle(YAxis.Width, M, D);

  EndUpdate;
end;
{$HINTS ON}
{$ENDIF}

procedure TTMSFNCChart.RegisterRuntimeClasses;
begin
  RegisterClasses([TTMSFNCChart, TTMSFNCChartSerie, TTMSFNCChartPoint, TTMSFNCChartAnnotation, TTMSFNCChartColorSelection]);
end;

procedure TTMSFNCChart.InvalidateChart;
begin
  if FUpdateCount > 0 then
    Exit;

  Invalidate;
end;

procedure TTMSFNCChart.Resize;
begin
  inherited;
  UpdateChart;
end;

procedure TTMSFNCChart.SeriesMarginsChanged(Sender: TObject);
begin
  UpdateChart;
end;

procedure TTMSFNCChart.SetAdapter(const Value: TTMSFNCChartAdapter);
begin
  if Assigned(Value) then
    Value.Chart := Self;
  FAdapter := Value;
end;

procedure TTMSFNCChart.SetAntiAliasing(const Value: Boolean);
begin
  if FAntiAliasing <> Value then
  begin
    BeginUpdate;
    FAntiAliasing := Value;
    EndUpdate;
  end;
end;

procedure TTMSFNCChart.SetDefaultLoadOptions(const Value: TTMSFNCChartLoadOptions);
begin
  FDefaultLoadOptions.Assign(Value)
end;

procedure TTMSFNCChart.SetDrawOrder(const Value: TTMSFNCChartSeriesDrawOrder);
begin
  if FDrawOrder <> Value then
  begin
    FDrawOrder := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChart.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
  begin
    FFill.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChart.SetCrosshair(const Value: TTMSFNCChartCrosshair);
begin
  if FCrosshair <> Value then
  begin
    FCrosshair.AssignSource(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChart.SetLegend(const Value: TTMSFNCChartLegend);
begin
  if FLegend <> Value then
  begin
    FLegend.AssignSource(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChart.SetNativeCanvas(const Value: Boolean);
begin
  if FNativeCanvas <> Value then
  begin
    BeginUpdate;
    FNativeCanvas := Value;
    EndUpdate;
  end;
end;

procedure TTMSFNCChart.SetSeries(const Value: TTMSFNCChartSeries);
begin
  if FSeries <> Value then
  begin
    FSeries.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChart.SetSeriesMargins(const Value: TTMSFNCChartMargins);
begin
  if FSeriesMargins <> Value then
  begin
    FSeriesMargins.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChart.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
  begin
    FStroke := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChart.SetTextQuality(const Value: TTMSFNCGraphicsTextQuality);
begin
  if FTextQuality <> Value then
  begin
    FTextQuality := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCChart.SetTitle(const Value: TTMSFNCChartTitle);
begin
  if FTitle <> Value then
  begin
    FTitle.AssignSource(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChart.SetInteractionOptions(const Value: TTMSFNCChartInteractionOptions);
begin
  FInteractionOptions.Assign(Value);
end;

procedure TTMSFNCChart.SetAppearance(const Value: TTMSFNCChartAppearance);
begin
  FAppearance.Assign(Value);
end;

procedure TTMSFNCChart.SetXAxis(const Value: TTMSFNCChartXAxis);
begin
  if FXAxis <> Value then
  begin
    FXAxis.AssignSource(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChart.SetAutoXRange(AXRange: TTMSFNCChartSerieAutoRange; ASerieIndex: Integer);
var
  I: Integer;
begin
  if (ASerieIndex >= 0) and (ASerieIndex < FSeries.Count) then
    FSeries[ASerieIndex].AutoXRange := AXRange
  else
  begin
    for I := 0 to FSeries.Count - 1 do
    begin
      FSeries[I].AutoXRange := AXRange;
    end;
  end;
end;

procedure TTMSFNCChart.SetAutoYRange(AYRange: TTMSFNCChartSerieAutoRange; ASerieIndex: Integer);
var
  I: Integer;
begin
  if (ASerieIndex >= 0) and (ASerieIndex < FSeries.Count) then
    FSeries[ASerieIndex].AutoYRange := AYRange
  else
  begin
    for I := 0 to FSeries.Count - 1 do
    begin
      FSeries[I].AutoYRange := AYRange;
    end;
  end;
end;

procedure TTMSFNCChart.SetXValuesPosition(AIndex: integer; APositions: TTMSFNCChartXAxisPositions; AClearOther: Boolean);
var
  I: Integer;
begin
  for I := 0 to FSeries.Count - 1 do
  begin
    if I = AIndex then
    begin
      Series[I].XValues.Positions := APositions;
    end
    else if AClearOther then
      Series[I].XValues.Positions := [];
  end;
end;

procedure TTMSFNCChart.SetYAxis(const Value: TTMSFNCChartYAxis);
begin
  if FYAxis <> Value then
  begin
    FYAxis.AssignSource(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChart.SetYValuesPosition(AIndex: integer; APositions: TTMSFNCChartYAxisPositions; AClearOther: Boolean);
var
  I: Integer;
begin
  for I := 0 to FSeries.Count - 1 do
  begin
    if I = AIndex then
    begin
      Series[I].YValues.Positions := APositions;
    end
    else if AClearOther then
      Series[I].YValues.Positions := [];
  end;
end;

procedure TTMSFNCChart.StrokeChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChart.UpdateChart;
begin
  if (FUpdateCount > 0) or (csDestroying in ComponentState) then
    Exit;

  Calculate3DOffset;
  CalculateMinMaxX;
  CalculateCommonRangeX;
  CalculateMinMaxY;
  CalculateCommonRangeY;
  CalculateXAxisHeight;
  CalculateYScale;
  CalculateYAxis;
  CalculateXScale;
  CalculateXAxis;
  CalculateSeries;
  if YAxis.DisplayAtReferenceValue then
    CalculateYAxis;
  if XAxis.DisplayAtReferenceValue then
    CalculateXAxis;
  InvalidateChart;
end;

procedure TTMSFNCChart.UpdateRange(AXRange, AYRange: Double; AScale: Boolean);
var
  I: Integer;
  vx, vy: Double;
  c: Integer;
begin
  c := 0;
  for I := 0 to Series.Count - 1 do
    if Series[I].AutoXRange = arDisabled then
      Inc(c);

  if c = 0 then
    Exit;

  BeginUpdate;
  for I := 0 to Series.Count - 1 do
  begin
    if Series[I].AutoXRange = arDisabled then
    begin
      vx := Series[I].XToValue(AXRange, False);

      if Series[I].LogarithmicX then
        vx := ConvertToLog(Series[I].LogarithmicXBase, vx);

      if AScale and (InteractionOptions.ScaleMode = smHorizontal) then
      begin
        Series[I].FMinX := Series[I].FMinX + vx;
        Series[I].FMaxX := Series[I].FMaxX - vx;
      end
      else if not AScale and InteractionOptions.Panning then
      begin
        Series[I].FMinX := Series[I].FMinX + vx;
        Series[I].FMaxX := Series[I].FMaxX + vx;
      end;
    end;

    if Series[I].AutoYRange = arDisabled then
    begin
      vy := Series[I].YToValue(AYRange, False);

      if Series[I].LogarithmicY then
        vy := ConvertToLog(Series[I].LogarithmicYBase, vy);

      if AScale and (InteractionOptions.ScaleMode = smVertical) then
      begin
        Series[I].FMinY := Series[I].FMinY + vy;
        Series[I].FMaxY := Series[I].FMaxY - vy;
      end
      else if not AScale and InteractionOptions.Panning then
      begin
        Series[I].FMinY := Series[I].FMinY - vy;
        Series[I].FMaxY := Series[I].FMaxY - vy;
      end;
    end;
  end;
  EndUpdate;
end;

procedure TTMSFNCChart.UpdateSeriesColors;
var
  I: Integer;
  J: Integer;
begin
  if not (csLoading in ComponentState) then
  begin
    for I := 0 to Series.Count - 1 do
    begin
      Series[I].Fill.Color := GetColorForIndex(I);

      if Series[I].ChartType in [ctPie, ctVariableRadiusPie, ctSizedPie] then
      begin
        for J := 0 to Series[I].Points.Count - 1 do
          Series[I].Points[J].Color := GetColorForIndex(J);

        {$IFDEF FMXLIB}
        Series[I].Stroke.Color := $7FFFFFFF;
        {$ENDIF}
        {$IFNDEF FMXLIB}
        Series[I].Stroke.Color := $DFDFDF;
        {$ENDIF}
      end
      else if Series[I].ChartType in [ctLine, ctDigitalLine, ctXYLine] then
        Series[I].Stroke.Color := Series[I].Fill.Color
      else
        Series[I].Stroke.Color := StrokeDarker(Series[I].Fill.Color, 50);

      Series[I].Crosshair.HorizontalLineStroke.Color := Series[I].Fill.Color;
      Series[I].Crosshair.VerticalLineStroke.Color := Series[I].Fill.Color;
      Series[I].Crosshair.XTextStroke.Color := Series[I].Fill.Color;
      Series[I].Crosshair.YTextStroke.Color := Series[I].Fill.Color;
      Series[I].Crosshair.XTextFill.Color := Series[I].Fill.Color;
      Series[I].Crosshair.YTextFill.Color := Series[I].Fill.Color;

      Series[I].Markers.Fill.Color := Series[I].Fill.Color;
      Series[I].Markers.Stroke.Color := StrokeDarker(Series[I].Markers.Fill.Color, 50);
    end;
  end;
end;

function TTMSFNCChart.XYToBar(X, Y: Double): TTMSFNCChartPoint;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  res: TTMSFNCChartPoint;
begin
  Result := nil;
  for I := Series.Count - 1 downto 0 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      res := s.XYToBar(X, Y);
      if Assigned(res) and not res.Undefined then
      begin
        Result := res;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCChart.XYToBarVirtual(X, Y: Double;
  var APoint: TTMSFNCChartPointVirtual): Boolean;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  res: TTMSFNCChartPointVirtual;
begin
  Result := False;
  for I := Series.Count - 1 downto 0 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      res := GetDefaultVirtualPoint;
      if s.XYToBarVirtual(X, Y, res) and not res.Undefined then
      begin
        APoint := res;
        Result := True;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCChart.XYToLegendItem(X, Y: Double): Integer;
begin
  Result := Legend.XYToItem(X, Y);
end;

function TTMSFNCChart.XYToPoint(X, Y: Double): TTMSFNCChartPoint;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  res: TTMSFNCChartPoint;
begin
  Result := nil;
  for I := Series.Count - 1 downto 0 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      res := s.XYToPoint(X, Y);
      if Assigned(res) and not res.Undefined then
      begin
        Result := res;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCChart.XYToClosestDrawPoint(X: Double; Y: Double; var ADrawPoint: TTMSFNCChartDrawPoint): Boolean;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  res: TTMSFNCChartDrawPoint;
begin
  Result := False;
  for I := Series.Count - 1 downto 0 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      res := GetDefaultDrawPoint;
      if s.XYToClosestDrawPoint(X, Y, res) and not res.VirtualReference.Undefined then
      begin
        ADrawPoint := res;
        Result := True;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCChart.XYToPointVirtual(X, Y: Double;
  var APoint: TTMSFNCChartPointVirtual): Boolean;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  res: TTMSFNCChartPointVirtual;
begin
  Result := False;
  for I := Series.Count - 1 downto 0 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      res := GetDefaultVirtualPoint;
      if s.XYToPointVirtual(X, Y, res) and not res.Undefined then
      begin
        APoint := res;
        Result := True;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCChart.XYToSerieLegendItem(X,
  Y: Double; var ASerie: TTMSFNCChartSerie): Integer;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  res: Integer;
begin
  ASerie := nil;
  Result := -1;
  for I := Series.Count - 1 downto 0 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      res := s.XYToLegendItem(X, Y);
      if res <> -1 then
      begin
        Result := res;
        ASerie := s;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCChart.XYToSlice(X, Y: Double): TTMSFNCChartPoint;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  res: TTMSFNCChartPoint;
begin
  Result := nil;
  for I := Series.Count - 1 downto 0 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      res := s.XYToSlice(X, Y);
      if Assigned(res) and not res.Undefined then
      begin
        Result := res;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCChart.XYToSliceVirtual(X, Y: Double;
  var APoint: TTMSFNCChartPointVirtual): Boolean;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  res: TTMSFNCChartPointVirtual;
begin
  Result := False;
  for I := Series.Count - 1 downto 0 do
  begin
    s := Series[I];
    if s.Visible then
    begin
      res := GetDefaultVirtualPoint;
      if s.XYToSliceVirtual(X, Y, res) and not res.Undefined then
      begin
        APoint := res;
        Result := True;
        Break;
      end;
    end;
  end;
end;

{ TTMSFNCChartSerie }

procedure TTMSFNCChartSerie.AnimateProcess(Sender: TObject);
var
  dt, db, post, posb, posh, posl, posc, poso, dmh, dml, dmc, dmo: Double;
  rest, resb, resh, resl, resc, reso: Boolean;
  I, K: Integer;
  iCount: Integer;
  dr, drsave: TTMSFNCChartDrawRect;
  dl, dlsave: TTMSFNCChartDrawLine;
  dp, dpsave: TTMSFNCChartDrawPoint;
  ds, dssave: TTMSFNCChartDrawSlice;
  dm, dmsave: TTMSFNCChartDrawMultiPoint;
  drt, drh, drl, drc, dro: Double;
  c: TTMSFNCChart;
begin
  for I := 0 to FDrawRects.Count - 1 do
  begin
    dr := FDrawRects[I];
    if FRevert then
      drSave := FAnimateDrawRectsReverse[I]
    else
      drSave := FAnimateDrawRects[I];
    if dr.DoAnimation then
    begin
      dt := Abs(drsave.Rect.Top - dr.Rect.Top) / AnimationFactor;
      post := dr.Rect.Top;
      drt := drSave.Rect.Top;
      rest := AnimateDouble(post, drt, dt, 0.1);

      db := Abs(drsave.Rect.Bottom - dr.Rect.Bottom) / AnimationFactor;
      posb := dr.Rect.Bottom;
      drt := drSave.Rect.Bottom;
      resb := AnimateDouble(posb, drt, db, 0.1);
      if rest or resb then
      begin
        dr.Rect.Top := post;
        dr.Rect.Bottom := posb;
        FDrawRects[I] := dr;
        InvalidateChart;
      end
      else
      begin
        dr.DoAnimation := False;
        dr.Rect.Top := drsave.Rect.Top;
        dr.Rect.Bottom := drsave.Rect.Bottom;
        FDrawRects[I] := dr;
        InvalidateChart;
      end;

      if (I < FDrawRects.Count - 1) and ((AnimationFlow and (dt < 7.5)) or not FAnimationFlow) then
      begin
        dr := FDrawRects[I + 1];
        dr.DoAnimation := True;
        FDrawRects[I + 1] := dr;
      end;
    end;
  end;

  for I := 0 to FDrawLines.Count - 1 do
  begin
    dl := FDrawLines[I];
    if FRevert then
      dlSave := FAnimateDrawLinesReverse[I]
    else
      dlSave := FAnimateDrawLines[I];

    if dl.DoAnimation then
    begin
      dt := Abs(dlsave.StartPoint.Y - dl.StartPoint.Y) / AnimationFactor;
      post := dl.StartPoint.Y;
      drt := dlSave.StartPoint.Y;
      rest := AnimateDouble(post, drt, dt, 0.1);

      db := Abs(dlsave.EndPoint.Y - dl.EndPoint.Y) / AnimationFactor;
      posb := dl.EndPoint.Y;
      drt := dlSave.EndPoint.Y;
      resb := AnimateDouble(posb, drt, db, 0.1);
      if rest or resb then
      begin
        dl.StartPoint.Y := post;
        dl.EndPoint.Y := posb;
        FDrawLines[I] := dl;
        InvalidateChart;
      end
      else
      begin
        dl.DoAnimation := False;
        dl.StartPoint.Y := dlsave.StartPoint.Y;
        dl.EndPoint.Y := dlsave.EndPoint.Y;
        FDrawLines[I] := dl;
        InvalidateChart;
      end;

      if IsArea and (FDrawLines.Count > 0) then
      begin
        FDrawPath.Clear;
        if not FDrawLines[0].StartVirtualReference.Undefined then
          FDrawPath.MoveTo(FDrawLines[0].StartPoint)
        else
          FDrawPath.MoveTo(PointF(FDrawLines[0].StartPoint.X, ValueToY(ZeroReferenceValue)));

        for K := 0 to FDrawLines.Count - 1 do
        begin
          if not FDrawLines[K].StartVirtualReference.Undefined and not FDrawLines[K].EndVirtualReference.Undefined then
            FDrawPath.LineTo(FDrawLines[I].EndPoint)
          else if FDrawLines[K].StartVirtualReference.Undefined then
          begin
            FDrawPath.LineTo(PointF(FDrawLines[K].EndPoint.X, ValueToY(ZeroReferenceValue)));
            FDrawPath.LineTo(PointF(FDrawLines[K].EndPoint.X, FDrawLines[K].StartPoint.Y));
          end
          else
            FDrawPath.LineTo(PointF(FDrawLines[K].StartPoint.X, ValueToY(ZeroReferenceValue)));
        end;
        FDrawPath.ClosePath;
      end;

      if (I < FDrawLines.Count - 1) and ((AnimationFlow and (dt < 7.5)) or not FAnimationFlow) then
      begin
        dl := FDrawLines[I + 1];
        dl.DoAnimation := True;
        FDrawLines[I + 1] := dl;
      end;
    end;
  end;

  for I := 0 to FDrawMultiPoints.Count - 1 do
  begin
    dm := FDrawMultiPoints[I];
    if FRevert then
      dmSave := FAnimateDrawMultiPointsReverse[I]
    else
      dmSave := FAnimateDrawMultiPoints[I];

    if dm.DoAnimation then
    begin
      dmh := Abs(dmsave.PointHigh.Y - dm.PointHigh.Y) / AnimationFactor;
      posh := dm.PointHigh.Y;
      drh := dmSave.PointHigh.Y;
      resh := AnimateDouble(posh, drh, dmh, 0.1);

      dmo := Abs(dmsave.PointOpen.Y - dm.PointOpen.Y) / AnimationFactor;
      poso := dm.PointOpen.Y;
      dro := dmSave.PointOpen.Y;
      reso := AnimateDouble(poso, dro, dmo, 0.1);

      dmc := Abs(dmsave.PointClose.Y - dm.PointClose.Y) / AnimationFactor;
      posc := dm.PointClose.Y;
      drc := dmSave.PointClose.Y;
      resc := AnimateDouble(posc, drc, dmc, 0.1);

      dml := Abs(dmsave.PointLow.Y - dm.PointLow.Y) / AnimationFactor;
      posl := dm.PointLow.Y;
      drl := dmSave.PointLow.Y;
      resl := AnimateDouble(posl, drl, dml, 0.1);

      if resh or reso or resc or resl then
      begin
        dm.PointHigh.Y := posh;
        dm.PointLow.Y := posl;
        dm.PointOpen.Y := poso;
        dm.PointClose.Y := posc;
        FDrawMultiPoints[I] := dm;
        InvalidateChart;
      end
      else
      begin
        dm.DoAnimation := False;
        dm.PointHigh.Y := dmsave.PointHigh.Y;
        dm.PointLow.Y := dmsave.PointLow.Y;
        dm.PointClose.Y := dmsave.PointLow.Y;
        dm.PointOpen.Y := dmsave.PointOpen.Y;
        FDrawMultiPoints[I] := dm;
        InvalidateChart;
      end;

      if (I < FDrawMultiPoints.Count - 1) and ((AnimationFlow and (dmh < 7.5)) or not FAnimationFlow) then
      begin
        dm := FDrawMultiPoints[I + 1];
        dm.DoAnimation := True;
        FDrawMultiPoints[I + 1] := dm;
      end;
    end;
  end;


  for I := 0 to FDrawPoints.Count - 1 do
  begin
    dp := FDrawPoints[I];
    if FRevert then
      dpSave := FAnimateDrawPointsReverse[I]
    else
      dpSave := FAnimateDrawPoints[I];

    if dp.DoAnimation then
    begin
      dt := Abs(dpsave.Point.Y - dp.Point.Y) / AnimationFactor;
      post := dp.Point.Y;
      drt := dpSave.Point.Y;
      rest := AnimateDouble(post, drt, dt, 0.1);

      if rest then
      begin
        dp.Point.Y := post;
        FDrawPoints[I] := dp;
        InvalidateChart;
      end
      else
      begin
        dp.DoAnimation := False;
        dp.Point.Y := dpsave.Point.Y;
        FDrawPoints[I] := dp;
        InvalidateChart;
      end;

      if (I < FDrawPoints.Count - 1) and ((AnimationFlow and (dt < 7.5)) or not FAnimationFlow) then
      begin
        dp := FDrawPoints[I + 1];
        dp.DoAnimation := True;
        FDrawPoints[I + 1] := dp;
      end;
    end;
  end;

  for I := 0 to FDrawSlices.Count - 1 do
  begin
    ds := FDrawSlices[I];
    if FRevert then
      dsSave := FAnimateDrawSlicesReverse[I]
    else
      dsSave := FAnimateDrawSlices[I];

    if ds.DoAnimation then
    begin
      if ds.AnimateEndPoint then
      begin
        dt := Abs(dssave.EndPoint.Y - ds.EndPoint.Y) / AnimationFactor;
        post := ds.EndPoint.Y;
        drt := dssave.EndPoint.Y;
        rest := AnimateDouble(post, drt, dt, 0.1);
      end
      else
      begin
        dt := Abs(dssave.SweepAngle - ds.SweepAngle) / AnimationFactor;
        post := ds.SweepAngle;
        drt := dssave.SweepAngle;
        rest := AnimateDouble(post, drt, dt, 0.1);
      end;

      if rest then
      begin
        if ds.AnimateEndPoint then
          ds.EndPoint.Y := post
        else
          ds.SweepAngle := post;

        FDrawSlices[I] := ds;
        InvalidateChart;
      end
      else
      begin
        ds.DoAnimation := False;
        if ds.AnimateEndPoint then
          ds.EndPoint.Y := dssave.EndPoint.Y
        else
          ds.SweepAngle := dssave.SweepAngle;

        FDrawSlices[I] := ds;
        InvalidateChart;
      end;

      if (I < FDrawSlices.Count - 1) and ((AnimationFlow and (dt < 0.1)) or not FAnimationFlow) then
      begin
        ds := FDrawSlices[I + 1];
        ds.DoAnimation := True;
        ds.AnimateEndPoint := ChartType = ctSpider;
        FDrawSlices[I + 1] := ds;
      end;
    end;
  end;

  iCount := 0;
  for I := 0 to FDrawRects.Count - 1 do
  begin
    if FDrawRects[I].DoAnimation then
      Inc(iCount);
  end;

  for I := 0 to FDrawLines.Count - 1 do
  begin
    if FDrawLines[I].DoAnimation then
      Inc(iCount);
  end;

  for I := 0 to FDrawPoints.Count - 1 do
  begin
    if FDrawPoints[I].DoAnimation then
      Inc(iCount);
  end;

  for I := 0 to FDrawSlices.Count - 1 do
  begin
    if FDrawSlices[I].DoAnimation then
      Inc(iCount);
  end;

  for I := 0 to FDrawMultiPoints.Count - 1 do
  begin
    if FDrawMultiPoints[I].DoAnimation then
      Inc(iCount);
  end;

  if iCount = 0 then
  begin
    c := Chart;
    if Assigned(c) then
      c.DoAnimateSerieFinished(Self);

    if Assigned(FAnimateTimer) then
      FAnimateTimer.Enabled := False;
  end;
end;

function TTMSFNCChartSerie.AddPoint(AYValue: Double; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint;
begin
  Result := Points.Add;
  Result.YValue := AYValue;
  Result.YValueVariable := AYValue;
  if AColor <> gcNull then
    Result.Color := AColor;
  Result.LegendText := ALegendText;
end;

function TTMSFNCChartSerie.AddMultiPoint(AYValueOpen, AYValueHigh, AYValueLow,
  AYValueClose: Double; AColor: TTMSFNCGraphicsColor;
  ALegendText: String): TTMSFNCChartPoint;
begin
  Result := Points.Add;
  Result.YValue := AYValueOpen;
  Result.YValueVariable := AYValueOpen;
  Result.YValueOpen := AYValueOpen;
  Result.YValueLow := AYValueLow;
  Result.YValueClose := AYValueClose;
  Result.YValueHigh := AYValueHigh;
  Result.YValueMedian := AYValueOpen;
  Result.Color := AColor;
  Result.LegendText := ALegendText;
end;

function TTMSFNCChartSerie.AddMultiPoint(AYValueOpen, AYValueHigh, AYValueLow,
  AYValueClose: Double; AXValueText: String; AColor: TTMSFNCGraphicsColor;
  ALegendText: String): TTMSFNCChartPoint;
begin
  Result := Points.Add;
  Result.YValue := AYValueOpen;
  Result.YValueVariable := AYValueOpen;
  Result.YValueOpen := AYValueOpen;
  Result.YValueLow := AYValueLow;
  Result.YValueClose := AYValueClose;
  Result.YValueHigh := AYValueHigh;
  Result.XValueText := AXValueText;
  Result.YValueMedian := AYValueOpen;
  Result.Color := AColor;
  Result.LegendText := ALegendText;
end;

function TTMSFNCChartSerie.AddMultiPoint(AYValueMinimum, AYValueQ1,
  AYValueMedian, AYValueQ3, AYValueMaximum: Double;
  AColor: TTMSFNCGraphicsColor; ALegendText: String): TTMSFNCChartPoint;
begin
  Result := Points.Add;
  Result.YValue := AYValueQ3;
  Result.YValueVariable := AYValueQ3;
  Result.YValueOpen := AYValueQ3;
  Result.YValueLow := AYValueMinimum;
  Result.YValueClose := AYValueQ1;
  Result.YValueHigh := AYValueMaximum;
  Result.YValueMedian := AYValueMedian;
  Result.Color := AColor;
  Result.LegendText := ALegendText;
end;

function TTMSFNCChartSerie.AddMultiPoint(AYValueMinimum, AYValueQ1,
  AYValueMedian, AYValueQ3, AYValueMaximum: Double; AXValueText: String;
  AColor: TTMSFNCGraphicsColor; ALegendText: String): TTMSFNCChartPoint;
begin
  Result := Points.Add;
  Result.YValue := AYValueQ3;
  Result.YValueVariable := AYValueQ3;
  Result.YValueOpen := AYValueQ3;
  Result.YValueLow := AYValueMinimum;
  Result.YValueClose := AYValueQ1;
  Result.YValueHigh := AYValueMaximum;
  Result.YValueMedian := AYValueMedian;
  Result.XValueText := AXValueText;
  Result.Color := AColor;
  Result.LegendText := ALegendText;
end;

function TTMSFNCChartSerie.AddPoint(AYValue: Double;
  AXValueText: String; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint;
begin
  Result := Points.Add;
  Result.YValue := AYValue;
  Result.YValueVariable := AYValue;
  Result.XValueText := AXValueText;
  Result.Color := AColor;
  Result.LegendText := ALegendText;
end;

function TTMSFNCChartSerie.AddSecondPoint(AYValue, AYValueSecond: Double;
  AColor: TTMSFNCGraphicsColor; ALegendText: String): TTMSFNCChartPoint;
begin
  Result := AddPoint(AYValue, AColor, ALegendText);
  Result.YValueSecond := AYValueSecond;
end;

function TTMSFNCChartSerie.AddSecondPoint(AYValue, AYValueSecond: Double;
  AXValueText: String; AColor: TTMSFNCGraphicsColor;
  ALegendText: String): TTMSFNCChartPoint;
begin
  Result := AddPoint(AYValue, AXValueText, AColor, ALegendText);
  Result.YValueSecond := AYValueSecond;
end;

function TTMSFNCChartSerie.AddVariablePoint(AYValue, AYValueVariable: Double;
  AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint;
begin
  Result := Points.Add;
  Result.YValue := AYValue;
  Result.YValueVariable := AYValueVariable;
  Result.Color := AColor;
  Result.LegendText := ALegendText;
end;

function TTMSFNCChartSerie.AddVariablePoint(AYValue, AYValueVariable: Double;
  AXValueText: String; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint;
begin
  Result := Points.Add;
  Result.YValue := AYValue;
  Result.YValueVariable := AYValueVariable;
  Result.XValueText := AXValueText;
  Result.Color := AColor;
  Result.LegendText := ALegendText;
end;

function TTMSFNCChartSerie.AddXYPoint(AXValue,
  AYValue: Double; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint;
begin
  Result := Points.Add;
  Result.XValue := AXValue;
  Result.YValue := AYValue;
  Result.YValueVariable := AYValue;
  Result.LegendText := ALegendText;
  Result.Color := AColor;
end;

function TTMSFNCChartSerie.AddXYPoint(AXValue, AYValue: Double;
  AXValueText: String; AColor: TTMSFNCGraphicsColor = gcNull; ALegendText: String = ''): TTMSFNCChartPoint;
begin
  Result := Points.Add;
  Result.XValue := AXValue;
  Result.YValue := AYValue;
  Result.YValueVariable := AYValue;
  Result.XValueText := AXValueText;
  Result.LegendText := ALegendText;
  Result.Color := AColor;
end;

procedure TTMSFNCChartSerie.Animate;
var
  dr: TTMSFNCChartDrawRect;
  dl: TTMSFNCChartDrawLine;
  dp: TTMSFNCChartDrawPoint;
  dm: TTMSFNCChartDrawMultiPoint;
  ds: TTMSFNCChartDrawSlice;
  c: TTMSFNCChart;
begin
  if FDrawRects.Count > 0 then
  begin
    dr := FDrawRects[0];
    dr.DoAnimation := True;
    FDrawRects[0] := dr;
  end;

  if FDrawLines.Count > 0 then
  begin
    dl := FDrawLines[0];
    dl.DoAnimation := True;
    FDrawLines[0] := dl;
  end;

  if FDrawPoints.Count > 0 then
  begin
    dp := FDrawPoints[0];
    dp.DoAnimation := True;
    FDrawPoints[0] := dp;
  end;

  if FDrawSlices.Count > 0 then
  begin
    ds := FDrawSlices[0];
    ds.DoAnimation := True;
    ds.AnimateEndPoint := ChartType = ctSpider;
    FDrawSlices[0] := ds;
  end;

  if FDrawMultiPoints.Count > 0 then
  begin
    dm := FDrawMultiPoints[0];
    dm.DoAnimation := True;
    FDrawMultiPoints[0] := dm;
  end;

  if Assigned(FAnimateTimer) then
    FAnimateTimer.Enabled := True;

  c := Chart;
  if Assigned(c) then
    c.DoAnimateSerieStarted(Self);  
end;

procedure TTMSFNCChartSerie.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCChartSerie) then
  begin
    FGroupIndex := (Source as TTMSFNCChartSerie).GroupIndex;
    FEnable3D := (Source as TTMSFNCChartSerie).Enable3D;
    FOffset3DX := (Source as TTMSFNCChartSerie).Offset3DX;
    FOffset3DY := (Source as TTMSFNCChartSerie).Offset3DY;
    FMinXOffsetPercentage := (Source as TTMSFNCChartSerie).MinXOffsetPercentage;
    FMinYOffsetPercentage := (Source as TTMSFNCChartSerie).MinYOffsetPercentage;
    FMaxXOffsetPercentage := (Source as TTMSFNCChartSerie).MaxXOffsetPercentage;
    FMaxYOffsetPercentage := (Source as TTMSFNCChartSerie).MaxYOffsetPercentage;
    FXGrid.AssignSource((Source as TTMSFNCChartSerie).XGrid);
    FXValues.AssignSource((Source as TTMSFNCChartSerie).XValues);
    FYGrid.AssignSource((Source as TTMSFNCChartSerie).YGrid);
    FYValues.AssignSource((Source as TTMSFNCChartSerie).YValues);
    FPoints.Assign((Source as TTMSFNCChartSerie).Points);
    FBar.Assign((Source as TTMSFNCChartSerie).Bar);
    FMultiPoint.Assign((Source as TTMSFNCChartSerie).MultiPoint);
    FLegend.Assign((Source as TTMSFNCChartSerie).Legend);
    FPie.Assign((Source as TTMSFNCChartSerie).Pie);
    FCrosshair.Assign((Source as TTMSFNCChartSerie).Crosshair);
    FMarkers.Assign((Source as TTMSFNCChartSerie).Markers);
    FLabels.Assign((Source as TTMSFNCChartSerie).Labels);
    FMode := (Source as TTMSFNCChartSerie).Mode;
    FTag := (Source as TTMSFNCChartSerie).Tag;
    FLegendText := (Source as TTMSFNCChartSerie).LegendText;
    FShowInLegend := (Source as TTMSFNCChartSerie).ShowInLegend;
    FVisible := (Source as TTMSFNCChartSerie).Visible;
    FChartType := (Source as TTMSFNCChartSerie).ChartType;
    FAutoXRange := (Source as TTMSFNCChartSerie).AutoXRange;
    FAutoYRange := (Source as TTMSFNCChartSerie).AutoYRange;
    FMinY := (Source as TTMSFNCChartSerie).MinY;
    FMaxY := (Source as TTMSFNCChartSerie).MaxY;
    FMinX := (Source as TTMSFNCChartSerie).MinX;
    FMaxX := (Source as TTMSFNCChartSerie).MaxX;
    FFill.Assign((Source as TTMSFNCChartSerie).Fill);
    FStroke.Assign((Source as TTMSFNCChartSerie).Stroke);
    FLogarithmicX := (Source as TTMSFNCChartSerie).LogarithmicX;
    FLogarithmicY := (Source as TTMSFNCChartSerie).LogarithmicY;
    FLogarithmicXBase := (Source as TTMSFNCChartSerie).LogarithmicXBase;
    FLogarithmicYBase := (Source as TTMSFNCChartSerie).LogarithmicYBase;
  end;
end;

procedure TTMSFNCChartSerie.CalculateAnimation;
var
  I: Integer;
  dr: TTMSFNCChartDrawRect;
  dl: TTMSFNCChartDrawLine;
  dp: TTMSFNCChartDrawPoint;
  dm: TTMSFNCChartDrawMultiPoint;
  ds: TTMSFNCChartDrawSlice;
  zrv: Double;
begin
  zrv := ValueToY(ZeroReferenceValue);

  for I := 0 to FDrawRects.Count - 1 do
  begin
    dr := FDrawRects[I];
    dr.Rect.Top := zrv;
    dr.Rect.Bottom := zrv;
    FAnimateDrawRects.Add(dr);
    FAnimateDrawRectsReverse.Add(FDrawRects[I]);
  end;

  for I := 0 to FDrawLines.Count - 1 do
  begin
    dl := FDrawLines[I];
    dl.StartPoint.Y := zrv;
    dl.EndPoint.Y := zrv;
    FAnimateDrawLines.Add(dl);
    FAnimateDrawLinesReverse.Add(FDrawLines[I]);
  end;

  for I := 0 to FDrawPoints.Count - 1 do
  begin
    dp := FDrawPoints[I];
    dp.Point.Y := zrv;
    FAnimateDrawPoints.Add(dp);
    FAnimateDrawPointsReverse.Add(FDrawPoints[I]);
  end;

  for I := 0 to FDrawMultiPoints.Count - 1 do
  begin
    dm := FDrawMultiPoints[I];
    dm.PointHigh.Y := zrv;
    dm.PointLow.Y := zrv;
    dm.PointOpen.Y := zrv;
    dm.PointClose.Y := zrv;
    FAnimateDrawMultiPoints.Add(dm);
    FAnimateDrawMultiPointsReverse.Add(FDrawMultiPoints[I]);
  end;

  for I := 0 to FDrawSlices.Count - 1 do
  begin
    ds := FDrawSlices[I];
    ds.SweepAngle := 0;
    ds.EndPoint.Y := ds.CenterPoint.Y;
    FAnimateDrawSlices.Add(ds);
    FAnimateDrawSlicesReverse.Add(FDrawSlices[I]);
  end;

  if FRevert then
  begin
    FDrawLines.Clear;
    FDrawRects.Clear;
    FDrawPoints.Clear;
    FDrawSlices.Clear;
    FDrawMultiPoints.Clear;
    for I := 0 to FAnimateDrawLines.Count - 1 do
      FDrawLines.Add(FAnimateDrawLines[I]);

    for I := 0 to FAnimateDrawRects.Count - 1 do
      FDrawRects.Add(FAnimateDrawRects[I]);

    for I := 0 to FAnimateDrawPoints.Count - 1 do
      FDrawPoints.Add(FAnimateDrawPoints[I]);

    for I := 0 to FAnimateDrawMultiPoints.Count - 1 do
      FDrawMultiPoints.Add(FAnimateDrawMultiPoints[I]);

    for I := 0 to FAnimateDrawSlices.Count - 1 do
      FDrawSlices.Add(FAnimateDrawSlices[I]);
  end;
end;

procedure TTMSFNCChartSerie.CalculateAnnotations;
var
  g: TTMSFNCGraphics;
  dann: TTMSFNCChartDrawAnnotation;
  I: Integer;
  ann: TTMSFNCChartAnnotationVirtual;
  th: Double;
  ft: TTMSFNCGraphicsFont;
  c: TTMSFNCChart;
  sz: TSizeF;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  if FDrawAnnotations.Count > 0 then
  begin
    ft := TTMSFNCGraphicsFont.Create;
    g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, c.NativeCanvas);
    g.BeginScene;
    try
      for I := 0 to FDrawAnnotations.Count - 1 do
      begin
        dann := FDrawAnnotations[I];
        ann := dann.VirtualAnnotationReference;

        if ann.AutoSize and (ann.Text <> '') then
        begin
          if Assigned(ann.Annotation) then
            ft.AssignSource(ann.Annotation.Font);

          c.DoCustomizeAnnotationFont(Self, dann.VirtualPointReference, ann, ft);

          g.Font.AssignSource(ft);
          sz := g.CalculateTextSize(ann.Text, RectF(0, 0, 10000, 10000), True);
          th := sz.cy + 1;
          dann.TextHeight := th;
          dann.TextWidth := sz.cx + 4;
        end
        else
        begin
          dann.TextHeight := ann.Height;
          dann.TextWidth := ann.Width;
        end;
        dann.AnnotationPoint.X := dann.AnnotationPoint.X + ann.OffsetX;
        if IsPie then
          dann.AnnotationPoint.Y := dann.AnnotationPoint.Y + ann.OffsetY + dann.TextHeight / 2
        else
          dann.AnnotationPoint.Y := dann.AnnotationPoint.Y + ann.OffsetY;

        dann.Rect := RectF(dann.AnnotationPoint.X - dann.TextWidth / 2, dann.AnnotationPoint.Y - dann.TextHeight, dann.AnnotationPoint.X + dann.TextWidth / 2, dann.AnnotationPoint.Y);
        FDrawAnnotations[I] := dann;
      end;
    finally
      g.EndScene;
      g.Free;
      ft.Free;
    end;
  end;
end;

procedure TTMSFNCChartSerie.CalculateArea;
var
  I: Integer;
begin
  if IsArea and (FDrawLines.Count > 0) then
  begin
    FDrawPath.Clear;

    if IsStacked then
    begin
      FDrawPath.MoveTo(FDrawLines[0].StartPoint);
      for I := 0 to FDrawLines.Count - 1 do
        FDrawPath.LineTo(FDrawLines[I].EndPoint);
    end
    else
    begin
      if not FDrawLines[0].StartVirtualReference.Undefined then
        FDrawPath.MoveTo(FDrawLines[0].StartPoint)
      else
        FDrawPath.MoveTo(PointF(FDrawLines[0].StartPoint.X, ValueToY(ZeroReferenceValue)));

      for I := 0 to FDrawLines.Count - 1 do
      begin
        if not FDrawLines[I].StartVirtualReference.Undefined and not FDrawLines[I].EndVirtualReference.Undefined then
          FDrawPath.LineTo(FDrawLines[I].EndPoint)
        else
        begin
          FDrawPath.LineTo(PointF(FDrawLines[I].StartPoint.X, ValueToY(ZeroReferenceValue)));
          FDrawPath.LineTo(PointF(FDrawLines[I].EndPoint.X, ValueToY(ZeroReferenceValue)));
          if not FDrawLines[I].EndVirtualReference.Undefined then
            FDrawPath.LineTo(FDrawLines[I].EndPoint);
         end;
      end;
    end;
    FDrawPath.ClosePath;
  end;
end;

procedure TTMSFNCChartSerie.CalculateDrawPoints;
var
  I: Integer;
  dr, drPrev: TTMSFNCChartDrawRect;
  dl, dlPrev, dlPrevn: TTMSFNCChartDrawLine;
  ds: TTMSFNCChartDrawSlice;
  dp: TTMSFNCChartDrawPoint;
  dm: TTMSFNCChartDrawMultiPoint;
  dlbl: TTMSFNCChartDrawLabel;
  dann: TTMSFNCChartDrawAnnotation;
  pt, ptNext: TTMSFNCChartPointVirtual;
  xVal, yVal, xValNext, yValNext, yValPrev, yValHigh, yValClose, yValLow, yValOpen, yValMedian: Double;
  ohw: Single;
  c: TTMSFNCChart;
  bwpos, bw, bwtot: Double;
  zrv: Double;
  start, stop: Integer;
  ann: TTMSFNCChartAnnotationVirtual;
  a: Integer;
  vl, vlNext, vlstmax, vlstmaxnext, pttot, ptmax, vlHigh, vlClose, vlLow, vlOpen, vlMedian: Double;
  pir: TRectF;
  st, swp: Double;
  cnt: Integer;
  cnta: Integer;
  {$IFDEF DELPHIXE8_LVL}
  tmp: TTMSFNCChartDrawLines;
  {$ENDIF}
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  FDrawLabels.Clear;
  FDrawAnnotations.Clear;
  FDrawPoints.Clear;
  FAnimateDrawPoints.Clear;
  FAnimateDrawPointsReverse.Clear;
  FDrawMultiPoints.Clear;
  FAnimateDrawMultiPoints.Clear;
  FAnimateDrawMultiPointsReverse.Clear;
  FDrawSlices.Clear;
  FAnimateDrawSlices.Clear;
  FAnimateDrawSlicesReverse.Clear;
  FDrawLines.Clear;
  FAnimateDrawLines.Clear;
  FAnimateDrawLinesReverse.Clear;
  FDrawRects.Clear;
  FAnimateDrawRects.Clear;
  FAnimateDrawRectsReverse.Clear;
  FDrawPath.Clear;

  zrv := ValueToY(ZeroReferenceValue);

  start := GetStartPoint;
  stop := GetEndPoint;

  case ChartType of
    ctPie, ctVariableRadiusPie, ctSizedPie, ctSpider:
    begin
      pttot := GetPointsTotal;
      if IsSpider then
        ptmax := YMax
      else
        ptmax := GetPointsMax;

      if pttot > 0 then
      begin
        pir := GetPieRect;
        st := Pie.StartAngle;
        cnt := GetPointsCount;
        for I := start to stop do
        begin
          pt := GetPoint(i);
          if ((pt.YValue <= 0) and Pie.IncludeZeroValues) or (pt.YValue > 0) then
          begin
            ds.Radius := PointF((pir.Right - pir.Left) / 2, (pir.Bottom - pir.Top) / 2);
            swp := Pie.SweepAngle / pttot * pt.YValue;

            if (ChartType = ctSizedPie) or (ChartType = ctSpider) then
            begin
              swp := Pie.SweepAngle / Max(1, cnt);
              ds.Radius.X := (ds.Radius.X / Max(1, ptmax)) * pt.YValue;
              ds.Radius.Y := (ds.Radius.Y / Max(1, ptmax)) * pt.YValue;
            end
            else if (ChartType = ctVariableRadiusPie) then
            begin
              ds.Radius.X := (ds.Radius.X / Max(1, ptmax)) * pt.YValueVariable;
              ds.Radius.Y := (ds.Radius.Y / Max(1, ptmax)) * pt.YValueVariable;
            end;

            ds.CenterPoint := CenterPointEx(pir);
            ds.CenterPoint.X := ds.CenterPoint.X + pt.Explode * Cos(DegToRad(st + swp / 2));
            ds.CenterPoint.Y := ds.CenterPoint.Y + pt.Explode * Sin(DegToRad(st + swp / 2));
            ds.InnerRadius := PointF(Pie.InnerSize / 2, Pie.InnerSize / 2);
            ds.StartPoint.X := ds.CenterPoint.X + ds.InnerRadius.X * Cos(DegToRad(st));
            ds.StartPoint.Y := ds.CenterPoint.Y + ds.InnerRadius.Y * Sin(DegToRad(st));

            xval := ds.Radius.X * Cos(DegToRad(st + swp / 2));
            yval := ds.Radius.Y * Sin(DegToRad(st + swp / 2));

            ds.EndPoint.X := ds.CenterPoint.X + xval;
            ds.EndPoint.Y := ds.CenterPoint.Y + yval;
            ds.StartAngle := st;
            ds.SweepAngle := swp;
            ds.Reference := pt.Point;
            ds.VirtualReference := pt;
            FDrawSlices.Add(ds);

            dlbl.Reference := pt.Point;
            dlbl.VirtualReference := pt;
            if ChartType = ctSpider then
            begin
              dlbl.Point.X := ds.CenterPoint.X + xval;
              dlbl.Point.Y := ds.CenterPoint.Y + yval;
            end
            else
            begin
              dlbl.Point.X := ds.CenterPoint.X + xval / 4 * 3;
              dlbl.Point.Y := ds.CenterPoint.Y + yval / 4 * 3;
            end;

            dlbl.Value := pt.YValue;
            FDrawLabels.Add(dlbl);

            cnta := 0;
            if Assigned(pt.Point) then
              cnta := pt.Point.Annotations.Count;

            c.DoGetNumberOfAnnotations(Self, pt, cnta);
            for a := 0 to cnta - 1 do
            begin
              if Assigned(pt.Point) and (a >= 0) and (a <= pt.Point.Annotations.Count - 1) then
                ann := pt.Point.Annotations[a].GetValue
              else
                ann := GetDefaultVirtualAnnotation;

              ann.Index := a;
              c.DoGetAnnotation(Self, pt, a, ann);
              if ann.Visible then
              begin
                dann.PointReference := pt.Point;
                dann.VirtualPointReference := pt;
                dann.AnnotationReference := ann.Annotation;
                dann.VirtualAnnotationReference := ann;
                dann.AnnotationPoint := PointF(ds.CenterPoint.X + xval, ds.CenterPoint.Y + yval);
                dann.StartPoint := dann.AnnotationPoint;
                FDrawAnnotations.Add(dann);
              end;
            end;

            st := st + swp;
          end;
        end;
      end;
    end;
    ctLine, ctArea, ctStackedArea, ctStackedPercentageArea, ctDigitalLine, ctXYLine, ctBand, ctOHLC, ctCandleStick, ctBoxPlot:
    begin
      for I := start to stop do
      begin
        pt := GetPoint(i);
        ptNext := GetPoint(i + 1);
        xVal := ValueToX(pt.XValue);
        xValNext := ValueToX(ptNext.XValue);
        vl := pt.YValue;
        vlHigh := pt.YValueHigh;
        vlLow := pt.YValueLow;
        vlClose := pt.YValueClose;
        vlOpen := pt.YValueOpen;
        vlMedian := pt.YValueMedian;
        vlNext := ptNext.YValue;

        vlstmax := GetStackedMax(i);
        vlstmaxnext := GetStackedMax(i + 1);

        dl.Bottom := False;
        dl.StackedValueStart := 0;
        dl.StackedValueEnd := 0;

        if (ChartType = ctStackedArea) or (ChartType = ctStackedPercentageArea) then
        begin
          if ChartType = ctStackedPercentageArea then
          begin
            if vlstmax > 0 then
              vl := vl / vlstmax * 100;

            if vlstmaxnext > 0 then
              vlNext := vlNext / vlstmaxnext * 100;
          end;

          dlPrev := GetDefaultDrawLine;
          if GetStackedDrawLine(i + 1 - start, dlPrev) then
          begin
            yVal := ValueToY(vl + dlPrev.StackedValueStart);
            yValNext := ValueToY(vlNext + dlPrev.StackedValueEnd);
            dl.StackedValueStart := dlPrev.StackedValueStart + vl;
            dl.StackedValueEnd := dlPrev.StackedValueEnd + vlNext;
          end
          else
          begin
            yVal := ValueToY(vl);
            yValNext := ValueToY(vlNext);
            dl.StackedValueStart := dl.StackedValueStart + vl;
            dl.StackedValueEnd := dl.StackedValueEnd + vlNext;
          end;
        end
        else
        begin
          yVal := ValueToY(vl);
          yValNext := ValueToY(vlNext);
        end;

        yValLow := ValueToY(vlLow);
        yValOpen := ValueToY(vlOpen);
        yValMedian := ValueToY(vlMedian);
        yValClose := ValueToY(vlClose);
        yValHigh := ValueToY(vlHigh);

        if ChartType = ctDigitalLine then
        begin
          dl.StartPoint := PointF(xVal, yVal);
          dl.EndPoint := PointF(xValNext, yVal);
          dl.StartReference := pt.Point;
          dl.EndReference := ptNext.Point;
          dl.StartVirtualReference := pt;
          dl.EndVirtualReference := ptNext;
          FDrawLines.Add(dl);

          dl.StartPoint := PointF(xValNext, yVal);
          dl.EndPoint := PointF(xValNext, yValNext);
          dl.StartReference := pt.Point;
          dl.StartVirtualReference := pt;
          dl.EndReference := ptNext.Point;
          dl.EndVirtualReference := ptNext;
          FDrawLines.Add(dl);
        end
        else
        begin
          dl.StartPoint := PointF(xVal, yVal);
          dl.EndPoint := PointF(xValNext, yValNext);
          dl.StartReference := pt.Point;
          dl.StartVirtualReference := pt;
          dl.EndReference := ptNext.Point;
          dl.EndVirtualReference := ptNext;
          FDrawLines.Add(dl);
        end;

        dp.Point := PointF(xVal, yVal);
        dp.Reference := pt.Point;
        dp.VirtualReference := pt;
        FDrawPoints.Add(dp);

        if I = stop then
        begin
          dp.Point := PointF(xValNext, yValNext);
          dp.Reference := ptNext.Point;
          dp.VirtualReference := ptNext;
          FDrawPoints.Add(dp);
        end;

        dlbl.Point := PointF(xVal, yVal);
        dlbl.Reference := pt.Point;
        dlbl.VirtualReference := pt;
        dlbl.Value := pt.YValue;
        if IsStacked and (Labels.Mode = lmStacked) then
          dlbl.Value := dl.StackedValueStart;

        FDrawLabels.Add(dlbl);

        dm.PointHigh := PointF(xVal, yValHigh);
        dm.PointLow := PointF(xVal, yValLow);
        dm.PointClose := PointF(xVal, yValClose);
        dm.PointOpen := PointF(xVal, yValOpen);
        dm.PointMedian := PointF(xVal, yValMedian);
        dm.Reference := pt.Point;
        ohw := GetMultiPointWidth;
        if yValOpen > yValClose then
          dm.Rect := RectF(xVal - ohw / 2, yValClose, xVal + ohw / 2, yValOpen)
        else
          dm.Rect := RectF(xVal - ohw / 2, yValOpen, xVal + ohw / 2, yValClose);
        dm.VirtualReference := pt;

        FDrawMultiPoints.Add(dm);

        cnta := 0;
        if Assigned(pt.Point) then
          cnta := pt.Point.Annotations.Count;

        c.DoGetNumberOfAnnotations(Self, pt, cnta);
        for a := 0 to cnta - 1 do
        begin
          if Assigned(pt.Point) and (a >= 0) and (a <= pt.Point.Annotations.Count - 1) then
            ann := pt.Point.Annotations[a].GetValue
          else
            ann := GetDefaultVirtualAnnotation;

          ann.Index := a;
          c.DoGetAnnotation(Self, pt, a, ann);
          if ann.Visible then
          begin
            dann.PointReference := pt.Point;
            dann.VirtualPointReference := pt;
            dann.AnnotationReference := ann.Annotation;
            dann.VirtualAnnotationReference := ann;
            dann.AnnotationPoint := PointF(xVal, yVal);
            dann.StartPoint := dann.AnnotationPoint;
            FDrawAnnotations.Add(dann);
          end;
        end;

        if I = stop then
        begin
          dlbl.Point := PointF(xValNext, yValNext);
          dlbl.Reference := ptNext.Point;
          dlbl.VirtualReference := ptNext;
          dlbl.Value := ptNext.YValue;
          if IsStacked and (Labels.Mode = lmStacked) then
            dlbl.Value := dl.StackedValueEnd;
          FDrawLabels.Add(dlbl);

          cnta := 0;
          if Assigned(ptNext.Point) then
            cnta := ptNext.Point.Annotations.Count;

          c.DoGetNumberOfAnnotations(Self, ptNext, cnta);
          for a := 0 to cnta - 1 do
          begin
            if Assigned(ptNext.Point) and (a >= 0) and (a <= ptNext.Point.Annotations.Count - 1) then
              ann := ptNext.Point.Annotations[a].GetValue
            else
              ann := GetDefaultVirtualAnnotation;

            ann.Index := a;
            c.DoGetAnnotation(Self, ptNext, a, ann);
            if ann.Visible then
            begin
              dann.PointReference := ptNext.Point;
              dann.VirtualPointReference := ptNext;
              dann.AnnotationReference := ann.Annotation;
              dann.VirtualAnnotationReference := ann;
              dann.AnnotationPoint := PointF(xValNext, yValNext);
              dann.StartPoint := dann.AnnotationPoint;
              FDrawAnnotations.Add(dann);
            end;
          end;
        end;
      end;

      if ChartType = ctBand then
      begin
        pt := GetPoint(stop + 1);
        pt.SecondValue := True;
        xVal := ValueToX(pt.XValue);
        vl := pt.YValue;
        vlNext := pt.YValueSecond;

        yVal := ValueToY(vl);
        yValNext := ValueToY(vlNext);

        dl.StartPoint := PointF(xVal, yVal);
        dl.EndPoint := PointF(xVal, yValNext);
        dl.StartReference := pt.Point;
        dl.StartVirtualReference := pt;
        dl.EndReference := ptNext.Point;
        dl.EndVirtualReference := ptNext;
        FDrawLines.Add(dl);

        dp.Point := PointF(xVal, yVal);
        dp.Reference := pt.Point;
        dp.VirtualReference := pt;
        FDrawPoints.Add(dp);

        for I := stop + 1 downto start + 1 do
        begin
          pt := GetPoint(i);
          pt.SecondValue := True;
          ptNext := GetPoint(i - 1);
          ptNext.SecondValue := True;

          xVal := ValueToX(pt.XValue);
          xValNext := ValueToX(ptNext.XValue);
          vl := pt.YValueSecond;
          vlNext := ptNext.YValueSecond;

          yVal := ValueToY(vl);
          yValNext := ValueToY(vlNext);

          dl.StartPoint := PointF(xVal, yVal);
          dl.EndPoint := PointF(xValNext, yValNext);
          dl.StartReference := pt.Point;
          dl.StartVirtualReference := pt;
          dl.EndReference := ptNext.Point;
          dl.EndVirtualReference := ptNext;
          FDrawLines.Add(dl);

          dp.Point := PointF(xVal, yVal);
          dp.Reference := pt.Point;
          dp.VirtualReference := pt;
          FDrawPoints.Add(dp);

          if I = start + 1 then
          begin
            dp.Point := PointF(xValNext, yValNext);
            dp.Reference := ptNext.Point;
            dp.VirtualReference := ptNext;
            FDrawPoints.Add(dp);
          end;

          dlbl.Point := PointF(xVal, yVal);
          dlbl.Reference := pt.Point;
          dlbl.VirtualReference := pt;
          dlbl.Value := pt.YValueSecond;

          FDrawLabels.Add(dlbl);

          if I = start + 1 then
          begin
            dlbl.Point := PointF(xValNext, yValNext);
            dlbl.Reference := ptNext.Point;
            dlbl.VirtualReference := ptNext;
            dlbl.Value := ptNext.YValueSecond;
            FDrawLabels.Add(dlbl);
          end;
        end;
      end;

      if IsArea and (FDrawLines.Count > 0) and not (ChartType = ctBand) then
      begin
        dlPrev := GetDefaultDrawLine;
        dlPrevn := GetDefaultDrawLine;
        if GetStackedDrawLine(1, dlPrev) and GetStackedDrawLine(FDrawLines.Count, dlPrevn) then
        begin
          dl.StartPoint := FDrawLines[FDrawLines.Count - 1].EndPoint;
          dl.EndPoint := PointF(FDrawLines[FDrawLines.Count - 1].EndPoint.X, dlPrevn.EndPoint.Y);
          dl.StartReference := nil;
          dl.EndReference := nil;
          dl.StartVirtualReference := GetDefaultVirtualPoint;
          dl.EndVirtualReference := GetDefaultVirtualPoint;
          dl.Bottom := False;
          FDrawLines.Add(dl);

          dl.StartPoint := PointF(FDrawLines[0].StartPoint.X, dlPrev.StartPoint.Y);
          dl.EndPoint := FDrawLines[0].StartPoint;
          dl.StartReference := nil;
          dl.EndReference := nil;
          dl.StartVirtualReference := GetDefaultVirtualPoint;
          dl.EndVirtualReference := GetDefaultVirtualPoint;
          dl.Bottom := False;
          {$IFDEF FMXLIB}
          {$IFDEF DELPHIXE8_LVL}
          tmp := TTMSFNCChartDrawLines.Create;
          tmp.AddRange(FDrawLines);
          FDrawLines.Clear;
          FDrawLines.Add(dl);
          FDrawLines.AddRange(tmp);
          tmp.Free;
          {$ELSE}
          FDrawLines.Insert(0, dl);
          {$ENDIF}
          {$ENDIF}
          {$IFDEF CMNWEBLIB}
          FDrawLines.Insert(0, dl);
          {$ENDIF}

          for I := FDrawLines.Count - 3 downto 1 do
          begin
            if GetStackedDrawLine(I, dlPrev) then
            begin
              dl := dlPrev;
              dl.StartReference := nil;
              dl.EndReference := nil;
              dl.StartVirtualReference := GetDefaultVirtualPoint;
              dl.EndVirtualReference := GetDefaultVirtualPoint;
              dl.Bottom := True;
              FDrawLines.Add(dl);
            end;
          end;
        end
        else
        begin
          dl.StartPoint := FDrawLines[FDrawLines.Count - 1].EndPoint;
          dl.EndPoint := PointF(FDrawLines[FDrawLines.Count - 1].EndPoint.X, zrv);
          dl.StartReference := nil;
          dl.EndReference := nil;
          dl.StartVirtualReference := GetDefaultVirtualPoint;
          dl.EndVirtualReference := GetDefaultVirtualPoint;
          FDrawLines.Add(dl);

          dl.StartPoint := PointF(FDrawLines[0].StartPoint.X, zrv);
          dl.EndPoint := FDrawLines[0].StartPoint;
          dl.StartReference := nil;
          dl.EndReference := nil;
          dl.StartVirtualReference := GetDefaultVirtualPoint;
          dl.EndVirtualReference := GetDefaultVirtualPoint;
          {$IFDEF FMXLIB}
          {$IFDEF DELPHIXE8_LVL}
          tmp := TTMSFNCChartDrawLines.Create;
          tmp.AddRange(FDrawLines);
          FDrawLines.Clear;
          FDrawLines.Add(dl);
          FDrawLines.AddRange(tmp);
          tmp.Free;
          {$ELSE}
          FDrawLines.Insert(0, dl);
          {$ENDIF}
          {$ENDIF}
          {$IFDEF CMNWEBLIB}
          FDrawLines.Insert(0, dl);
          {$ENDIF}
        end;
      end;
    end;
    ctMarker, ctXYMarker:
    begin
      for I := start to stop do
      begin
        pt := GetPoint(i);
        xVal := ValueToX(pt.XValue);
        yVal := ValueToY(pt.YValue);
        dp.Reference := pt.Point;
        dp.VirtualReference := pt;
        dp.Point := PointF(xVal, yVal);
        FDrawPoints.Add(dp);
        dlbl.Reference := pt.Point;
        dlbl.VirtualReference := pt;
        dlbl.Point := PointF(xVal, yVal);
        dlbl.Value := pt.YValue;
        FDrawLabels.Add(dlbl);

        cnta := 0;
        if Assigned(pt.Point) then
          cnta := pt.Point.Annotations.Count;

        c.DoGetNumberOfAnnotations(Self, pt, cnta);
        for a := 0 to cnta - 1 do
        begin
          if Assigned(pt.Point) and (a >= 0) and (a <= pt.Point.Annotations.Count - 1) then
            ann := pt.Point.Annotations[a].GetValue
          else
            ann := GetDefaultVirtualAnnotation;

          ann.Index := a;
          c.DoGetAnnotation(Self, pt, a, ann);
          if ann.Visible then
          begin
            dann.PointReference := pt.Point;
            dann.VirtualPointReference := pt;
            dann.AnnotationReference := ann.Annotation;
            dann.VirtualAnnotationReference := ann;
            dann.AnnotationPoint := PointF(xVal, yVal);
            dann.StartPoint := dann.AnnotationPoint;
            FDrawAnnotations.Add(dann);
          end;
        end;
      end;
    end;
    ctBar, ctStackedBar, ctStackedPercentageBar:
    begin
      bwtot := GetTotalBarWidth;
      bwpos := GetBarOffset;
      if Offset then
        bwpos := bwpos + (FXScale - bwtot) / 2 - (FXScale / 2);

      bw := GetBarWidth;
      for I := start to stop do
      begin
        pt := GetPoint(i);
        dr.Reference := pt.Point;
        dr.VirtualReference := pt;
        dr.StackedValue := 0;
        xVal := ValueToX(pt.XValue);
        vlstmax := GetStackedMax(i);
        vl := pt.YValue;

        if (ChartType = ctStackedBar) or (ChartType = ctStackedPercentageBar) then
        begin
          if ChartType = ctStackedPercentageBar then
          begin
            if vlstmax > 0 then
              vl := vl / vlstmax * 100;
          end;

          drPrev := GetDefaultDrawRect;
          if GetStackedDrawRect(i - start, drPrev) then
          begin
            yVal := ValueToY(vl + drPrev.StackedValue);
            yValPrev := ValueToY(drPrev.StackedValue);
            dr.StackedValue := drPrev.StackedValue + vl;
          end
          else
          begin
            yVal := ValueToY(vl);
            yValPrev := zrv;
            dr.StackedValue := dr.StackedValue + vl;
          end;
        end
        else
        begin
          yVal := ValueToY(vl);
          yValPrev := zrv;
        end;

        if yval > yValPrev then
          dr.Rect := RectF(xVal + bwpos, yValPrev, xVal + bwpos + bw, yval)
        else
          dr.Rect := RectF(xVal + bwpos, yVal, xVal + bwpos + bw, yValPrev);

        FDrawRects.Add(dr);
        dp.Point.Y := dr.Rect.Top;
        dp.Point.X := CenterPointEx(dr.Rect).X;
        dp.Reference := pt.Point;
        dp.VirtualReference := pt;
        FDrawPoints.Add(dp);

        dlbl.Point.Y := dr.Rect.Top;
        dlbl.Point.X := CenterPointEx(dr.Rect).X;
        dlbl.Reference := pt.Point;
        dlbl.VirtualReference := pt;
        dlbl.Value := pt.YValue;
        if IsStacked and (Labels.Mode = lmStacked) then
          dlbl.Value := dr.StackedValue;

        FDrawLabels.Add(dlbl);

        cnta := 0;
        if Assigned(pt.Point) then
          cnta := pt.Point.Annotations.Count;

        c.DoGetNumberOfAnnotations(Self, pt, cnta);
        for a := 0 to cnta - 1 do
        begin
          if Assigned(pt.Point) and (a >= 0) and (a <= pt.Point.Annotations.Count - 1) then
            ann := pt.Point.Annotations[a].GetValue
          else
            ann := GetDefaultVirtualAnnotation;

          ann.Index := a;
          c.DoGetAnnotation(Self, pt, a, ann);
          if ann.Visible then
          begin
            dann.PointReference := pt.Point;
            dann.VirtualPointReference := pt;
            dann.AnnotationReference := ann.Annotation;
            dann.VirtualAnnotationReference := ann;
            dann.AnnotationPoint.Y := dr.Rect.Top;
            dann.AnnotationPoint.X := CenterPointEx(dr.Rect).X;
            dann.StartPoint := dann.AnnotationPoint;
            FDrawAnnotations.Add(dann);
          end;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCChartSerie.CalculateLabels;
var
  g: TTMSFNCGraphics;
  th: Double;
  dlbl: TTMSFNCChartDrawLabel;
  I: Integer;
  c: TTMSFNCChart;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  if Labels.Visible then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, c.NativeCanvas);
    g.BeginScene;
    try
      g.Font.AssignSource(Labels.Font);
      th := g.CalculateTextHeight('gh') + 2;
      for I := 0 to FDrawLabels.Count - 1 do
      begin
        dlbl := FDrawLabels[I];
        if Labels.Format <> '' then
        begin
          case Labels.FormatType of
            vftNormal: dlbl.ValueString := Format(Labels.Format,[dlbl.Value]);
            vftFloat: dlbl.ValueString := FormatFloat(Labels.Format,dlbl.Value);
            vftDateTime: dlbl.ValueString := FormatDateTime(Labels.Format, dlbl.Value);
          end;
        end
        else
          dlbl.ValueString := FloatToStr(dlbl.Value);

        if c.FVirtualMode then
          c.DoGetSerieLabelVirtual(Self, dlbl.VirtualReference, dlbl.ValueString)
        else
          c.DoGetSerieLabel(Self, dlbl.Reference, dlbl.ValueString);

        dlbl.TextHeight := th;
        dlbl.TextWidth := g.CalculateTextWidth(dlbl.ValueString) + 4;
        dlbl.Point.X := dlbl.Point.X + Labels.OffsetX;
        if IsPie then
          dlbl.Point.Y := dlbl.Point.Y + Labels.OffsetY + dlbl.TextHeight / 2
        else
          dlbl.Point.Y := dlbl.Point.Y + Labels.OffsetY;

        dlbl.Rect := RectF(dlbl.Point.X - dlbl.TextWidth / 2, dlbl.Point.Y - dlbl.TextHeight, dlbl.Point.X + dlbl.TextWidth / 2, dlbl.Point.Y);

        FDrawLabels[I] := dlbl;
      end;
    finally
      g.EndScene;
      g.Free;
    end;
  end;
end;

function ConvertToLog(ABase: Integer; Value: Double): Double;
begin
  Result := Value;
  if Result <> 0 then
    Result := LogN(ABase, Abs(Value));
end;

procedure TTMSFNCChartSerie.CalculateMinMaxX;
var
  I: Integer;
  pt: TTMSFNCChartPointVirtual;
  c: TTMSFNCChart;
  offsxmin, offsxmax, d: Double;
  sta, ste, cnt, p: Integer;
begin
  cnt := GetPointsCount;
  if cnt = 0 then
  begin
    FXMax := 0;
    FXMin := 0;
    Exit;
  end;

  c := Chart;
  if Assigned(c) then
  begin
    sta := 0;
    ste := cnt - 1;

    p := 0;
    for I := sta to ste do
    begin
      pt := GetPoint(I);
      if not pt.Undefined then
        Inc(p);
    end;

    if p = 0 then
    begin
      FXMax := 0;
      FXMin := 0;
      Exit;
    end;

    case AutoXRange of
      arDisabled:
      begin
        FXMax := FMaxX;
        FXMin := FMinX;
      end;
      arEnabled, arCommon:
      begin
        FXMax := -MaxDouble;
        FXMin := MaxDouble;

        for I := sta to ste do
        begin
          pt := GetPoint(I);
          if not pt.Undefined then
          begin
            FXMax := Max(FXMax, pt.XValue);
            FXMin := Min(FXMin, pt.XValue);
          end;
        end;
      end;
      arEnabledZeroBased, arCommonZeroBased:
      begin
        FXMin := 0;
        FXMax := -MaxDouble;

        for I := sta to ste do
        begin
          pt := GetPoint(I);
          if not pt.Undefined then
            FXMax := Max(FXMax, pt.XValue);
        end;
      end;
    end;
  end;

  if LogarithmicX and not (AutoXRange = arDisabled) then
  begin
    d := ConvertToLog(LogarithmicXBase, FXMax);
    FXMax := d;

    d := ConvertToLog(LogarithmicYBase, FXMin);
    FXMin := d;
  end;

  offsxmin := (FMinXOffsetPercentage * Abs(FXMax - FXMin)) / 100;
  offsxmax := (FMaxXOffsetPercentage * Abs(FXMax - FXMin)) / 100;
  FXMin := FXMin - offsxmin;
  FXMax := FXMax + offsxmax;
end;

procedure TTMSFNCChartSerie.CalculateMinMaxY;
var
  I: Integer;
  pt: TTMSFNCChartPointVirtual;
  c: TTMSFNCChart;
  offsymin, offsymax, d: Double;
  sta, ste, cnt: Integer;
begin
  cnt := GetPointsCount;
  if cnt = 0 then
  begin
    FYMax := 0;
    FYMin := 0;
    Exit;
  end;

  c := Chart;
  if Assigned(c) then
  begin
    sta := GetStartCountPoint;
    ste := GetEndCountPoint;

    case AutoYRange of
      arDisabled:
      begin
        FYMax := FMaxY;
        FYMin := FMinY;
      end;
      arEnabled, arCommon:
      begin
        FYMax := -MaxDouble;
        FYMin := MaxDouble;

        for I := sta to ste do
        begin
          pt := GetPoint(I);
          if not pt.Undefined then
          begin
            if ChartType in [ctOHLC, ctCandleStick, ctBoxPlot] then
              FYMax := Max(FYMax, pt.YValueHigh)
            else
              FYMax := Max(FYMax, pt.YValue);

            if ChartType = ctBand then
              FYMin := Min(FYMin, pt.YValueSecond)
            else if ChartType in [ctCandleStick, ctOHLC, ctBoxPlot] then
              FYMin := Min(FYMin, pt.YValueLow)
            else
              FYMin := Min(FYMin, pt.YValue);
          end;
        end;
      end;
      arEnabledZeroBased, arCommonZeroBased:
      begin
        FYMin := 0;
        FYMax := -MaxDouble;

        for I := sta to ste do
        begin
          pt := GetPoint(I);
          if not pt.Undefined then
          begin
            if ChartType in [ctOHLC, ctCandleStick, ctBoxPlot] then
              FYMax := Max(FYMax, pt.YValueHigh)
            else
              FYMax := Max(FYMax, pt.YValue);
          end;
        end;
      end;
    end;
  end;

  if LogarithmicY and not (AutoYRange = arDisabled) then
  begin
    d := ConvertToLog(LogarithmicYBase, FYMax);
    FYMax := d;

    d := ConvertToLog(LogarithmicYBase, FYMin);
    FYMin := d;
  end;

  if IsSpider and not (AutoYRange = arDisabled) then
  begin
    offsymin := (Max(10, FMinYOffsetPercentage) * Abs(FYMax - FYMin)) / 100;
    offsymax := (Max(10, FMaxYOffsetPercentage) * Abs(FYMax - FYMin)) / 100;
  end
  else
  begin
    offsymin := (FMinYOffsetPercentage * Abs(FYMax - FYMin)) / 100;
    offsymax := (FMaxYOffsetPercentage * Abs(FYMax - FYMin)) / 100;
  end;

  FYMin := FYMin - offsymin;
  FYMax := FYMax + offsymax;
end;

procedure TTMSFNCChartSerie.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

function TTMSFNCChartSerie.Chart: TTMSFNCChart;
begin
  Result := FChart;
end;

constructor TTMSFNCChartSerie.Create(ACollection: TCollection);
{$IFDEF FMXLIB}
var
  col: TAlphaColorRec;
{$ENDIF}
begin
  inherited;
  if Assigned(Collection) then
    FChart := (Collection as TTMSFNCChartSeries).Chart;

  FGroupIndex := 0;
  FSequential := True;
  FPrevXValue := -MaxDouble;
  FPoints := TTMSFNCChartPoints.Create(Self);
  FBar := TTMSFNCChartSerieBar.Create(Self);
  FMultiPoint := TTMSFNCChartSerieMultiPoint.Create(Self);
  FPie := TTMSFNCChartSeriePie.Create(Self);
  FCrosshair := TTMSFNCChartSerieCrosshair.Create(Self);
  FMarkers := TTMSFNCChartSerieMarkers.Create(Self);
  FXGrid := TTMSFNCChartSerieXYGrid.Create(Self);
  FYGrid := TTMSFNCChartSerieXYGrid.Create(Self);
  FXValues := TTMSFNCChartSerieXValues.Create(Self);
  FYValues := TTMSFNCChartSerieYValues.Create(Self);
  FLabels := TTMSFNCChartSerieLabels.Create(Self);
  FLegend := TTMSFNCChartSerieLegend.Create(Self);

  FDrawLines := TTMSFNCChartDrawLines.Create;
  FAnimateDrawLines := TTMSFNCChartDrawLines.Create;
  FAnimateDrawLinesReverse := TTMSFNCChartDrawLines.Create;
  FDrawSlices := TTMSFNCChartDrawSlices.Create;
  FAnimateDrawSlices := TTMSFNCChartDrawSlices.Create;
  FAnimateDrawSlicesReverse := TTMSFNCChartDrawSlices.Create;
  FDrawMultiPoints := TTMSFNCChartDrawMultiPoints.Create;
  FAnimateDrawMultiPoints := TTMSFNCChartDrawMultiPoints.Create;
  FAnimateDrawMultiPointsReverse := TTMSFNCChartDrawMultiPoints.Create;
  FDrawPoints := TTMSFNCChartDrawPoints.Create;
  FAnimateDrawPoints := TTMSFNCChartDrawPoints.Create;
  FAnimateDrawPointsReverse := TTMSFNCChartDrawPoints.Create;
  FDrawXValues := TTMSFNCChartDrawXYValues.Create;
  FDrawYValues := TTMSFNCChartDrawXYValues.Create;
  FDrawXGridLines := TTMSFNCChartDrawXYGridLines.Create;
  FDrawYGridLines := TTMSFNCChartDrawXYGridLines.Create;
  FDrawLabels := TTMSFNCChartDrawLabels.Create;
  FDrawAnnotations := TTMSFNCChartDrawAnnotations.Create;
  
  FDrawRects := TTMSFNCChartDrawRects.Create;
  FAnimateDrawRects := TTMSFNCChartDrawRects.Create;
  FAnimateDrawRectsReverse := TTMSFNCChartDrawRects.Create;
  FDrawPath := TTMSFNCGraphicsPath.Create;
  FAnimationFactor := 4.0;
  FOffset3DX := 15;
  FOffset3DY := 15;
  FEnable3D := False;
  FAnimationFlow := True;
  FVisible := True;
  FShowInLegend := True;
  FLogarithmicX := False;
  FLogarithmicY := False;
  FLogarithmicXBase := 10;
  FLogarithmicYBase := 10;

  if Assigned(FChart) then
    FChartType := FChart.GetDefaultChartType
  else
    FChartType := ctLine;

  if ChartType in [ctBar, ctStackedBar, ctStackedPercentageBar, ctOHLC, ctCandleStick, ctBoxPlot] then
    FMode := smStatistical;

  FAutoYRange := arEnabled;
  FAutoXRange := arDisabled;
  FZeroReferenceValue := 0;
  FMinX := 0;
  FMaxX := 10;
  FMinY := 0;
  FMaxY := 10;
  FTag := 0;

  FFill := TTMSFNCGraphicsFill.Create;
  FStroke := TTMSFNCGraphicsStroke.Create;

  if Assigned(FChart) then
    FFill.Color := FChart.GetColorForIndex(Index)
  else
  begin
    {$IFDEF FMXLIB}
    col.R := Random(255);
    col.G := Random(255);
    col.B := Random(255);
    col.A := 255;
    FFill.Color := col.Color;
    {$ELSE}
    FFill.Color := RGB(Random(255), Random(255), Random(255));
    {$ENDIF}
  end;

  if ChartType in [ctLine, ctDigitalLine, ctXYLine] then
    FStroke.Color := FFill.Color
  else
    FStroke.Color := StrokeDarker(FFill.Color, 50);

  Markers.Fill.Color := FFill.Color;
  Markers.Stroke.Color := StrokeDarker(FFill.Color, 50);

  Crosshair.HorizontalLineStroke.Color := FFill.Color;
  Crosshair.VerticalLineStroke.Color := FFill.Color;
  Crosshair.XTextStroke.Color := FFill.Color;
  Crosshair.YTextStroke.Color := FFill.Color;
  Crosshair.XTextFill.Color := FFill.Color;
  Crosshair.YTextFill.Color := FFill.Color;

  FFill.OnChanged := @FillChanged;
  FStroke.OnChanged := @StrokeChanged;

  FAnimateTimer := TTimer.Create(nil);
  FAnimateTimer.Enabled := False;
  FAnimateTimer.Interval := 10;
  FAnimateTimer.OnTimer := AnimateProcess;

  FLegendText := 'Series ' + inttostr(Index);
  UpdateChart;
end;

destructor TTMSFNCChartSerie.Destroy;
begin
  FAnimateTimer.Free;
  FFill.Free;
  FStroke.Free;
  FPoints.Free;
  FDrawXValues.Free;
  FDrawYValues.Free;
  FDrawXGridLines.Free;
  FDrawYGridLines.Free;
  FAnimateDrawRectsReverse.Free;
  FAnimateDrawRects.Free;
  FDrawRects.Free;
  FDrawLabels.Free;
  FDrawAnnotations.Free;
  FAnimateDrawLines.Free;
  FAnimateDrawLinesReverse.Free;
  FDrawLines.Free;
  FAnimateDrawSlices.Free;
  FAnimateDrawSlicesReverse.Free;
  FDrawslices.Free;
  FAnimateDrawPoints.Free;
  FAnimateDrawPointsReverse.Free;
  FDrawPoints.Free;
  FAnimateDrawMultiPoints.Free;
  FAnimateDrawMultiPointsReverse.Free;
  FDrawMultiPoints.Free;
  FDrawPath.Free;
  FLabels.Free;
  FXValues.Free;
  FYValues.Free;
  FXGrid.Free;
  FYGrid.Free;
  FMarkers.Free;
  FLegend.Free;
  FBar.Free;
  FMultiPoint.Free;
  FPie.Free;
  FCrosshair.Free;
  inherited;
  UpdateChart;
end;

procedure TTMSFNCChartSerie.Draw(AGraphics: TTMSFNCGraphics; ADrawPoint: Integer = -1);
var
  I: Integer;
  c: TTMSFNCChart;
  dl: TTMSFNCChartDrawLine;
  dm: TTMSFNCChartDrawMultiPoint;
  dr: TTMSFNCChartDrawRect;
  ds, dsStart: TTMSFNCChartDrawSlice;
  df: Boolean;
  st: TTMSFNCGraphicsSaveState;
  pth, pp: TTMSFNCGraphicsPath;
  br: TRectF;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  AGraphics.Fill.Assign(Fill);
  AGraphics.Stroke.Assign(Stroke);

  pth := nil;
  if Is3DEnabled then
    pth := TTMSFNCGraphicsPath.Create;

  case ChartType of
    ctSpider:
    begin
      if FDrawSlices.Count > 0 then
      begin
        pp := TTMSFNCGraphicsPath.Create;
        dsStart := FDrawSlices[0];
        pp.MoveTo(dsStart.EndPoint);
        for I := 1 to FDrawSlices.Count - 1 do
        begin
          ds := FDrawSlices[I];
          if not ds.VirtualReference.Undefined then
            pp.LineTo(ds.EndPoint)
          else
            pp.LineTo(ds.CenterPoint);
        end;
        pp.ClosePath;
        st := AGraphics.SaveState;
        AGraphics.DrawPath(pp);
        AGraphics.RestoreState(st);
        pp.Free;
      end;
    end;
    ctPie, ctVariableRadiusPie, ctSizedPie:
    begin
      pp := TTMSFNCGraphicsPath.Create;
      for I := 0 to FDrawSlices.Count - 1 do
      begin
        ds := FDrawSlices[I];
        df := not ds.VirtualReference.Undefined;
        st := AGraphics.SaveState;

        if Assigned(ds.Reference) and (ds.Reference.Color <> gcNull) then
          AGraphics.Fill.Color := ds.Reference.Color
        else if ds.VirtualReference.Color <> gcNull then
          AGraphics.Fill.Color := ds.VirtualReference.Color;

        c.DoBeforeDrawSerieSlice(AGraphics, Self, ds, df);
        if df then
        begin
          pp.Clear;
          pp.MoveTo(ds.StartPoint);
          pp.AddArc(ds.CenterPoint, ds.Radius, ds.StartAngle, ds.SweepAngle);
          pp.AddArc(ds.CenterPoint, ds.InnerRadius, ds.StartAngle + ds.SweepAngle, -ds.SweepAngle);
          pp.ClosePath;
          AGraphics.DrawPath(pp);
          c.DoAfterDrawSerieSlice(AGraphics, Self, ds);
        end;
        AGraphics.RestoreState(st);
      end;
      pp.Free;
    end;
    ctOHLC, ctCandleStick, ctBoxPlot:
    begin
      for I := 0 to FDrawMultiPoints.Count - 1 do
      begin
        dm := FDrawMultiPoints[I];
        df := not dm.VirtualReference.Undefined;
        st := AGraphics.SaveState;
        if Assigned(dm.Reference) then
        begin
          if (dm.Reference.Color <> gcNull) then
          begin
            AGraphics.Fill.Color := dm.Reference.Color;
            AGraphics.Stroke.Color := dm.Reference.Color;
          end
          else if dm.VirtualReference.Color <> gcNull then
          begin
            AGraphics.Fill.Color := dm.VirtualReference.Color;
            AGraphics.Stroke.Color := dm.VirtualReference.Color;
          end
          else
          begin
            if dm.Reference.YValueClose < dm.Reference.YValueOpen then
            begin
              if MultiPoint.DecreaseStrokeColor <> gcNull then
                AGraphics.Stroke.Color := MultiPoint.DecreaseStrokeColor;

              if MultiPoint.DecreaseFillColor <> gcNull then
                AGraphics.Fill.Color := MultiPoint.DecreaseFillColor;
            end
            else
            begin
              if MultiPoint.IncreaseStrokeColor <> gcNull then
                AGraphics.Stroke.Color := MultiPoint.IncreaseStrokeColor;

              if MultiPoint.IncreaseFillColor <> gcNull then
                AGraphics.Fill.Color := MultiPoint.IncreaseFillColor;
            end;
          end;
        end;

        c.DoBeforeDrawSerieMultiPoint(AGraphics, Self, dm, df);
        if df then
        begin
          AGraphics.DrawLine(dm.PointHigh, dm.PointLow);
          case ChartType of
            ctOHLC:
            begin
              AGraphics.DrawLine(PointF(dm.Rect.Left, dm.PointOpen.Y), dm.PointOpen);
              AGraphics.DrawLine(dm.PointClose, PointF(dm.Rect.Right, dm.PointClose.Y));
            end;
            ctCandleStick, ctBoxPlot:
            begin
              AGraphics.DrawRectangle(dm.Rect, gcrmExpandAll);
              case ChartType of
                ctBoxPlot:
                begin
                  AGraphics.DrawLine(PointF(dm.Rect.Left, dm.PointHigh.Y), PointF(dm.Rect.Right, dm.PointHigh.Y));
                  AGraphics.DrawLine(PointF(dm.Rect.Left, dm.PointLow.Y), PointF(dm.Rect.Right, dm.PointLow.Y));
                  AGraphics.DrawLine(PointF(dm.Rect.Left, dm.PointMedian.Y), PointF(dm.Rect.Right, dm.PointMedian.Y));
                end;
              end;
            end;
          end;
          c.DoAfterDrawSerieMultiPoint(AGraphics, Self, dm);
        end;
        AGraphics.RestoreState(st);
      end;
    end;
    ctLine, ctDigitalLine, ctXYLine:
    begin
      for I := 0 to FDrawLines.Count - 1 do
      begin
        dl := FDrawLines[I];
        df := not dl.StartVirtualReference.Undefined and not dl.EndVirtualReference.Undefined;
        st := AGraphics.SaveState;
        c.DoBeforeDrawSerieLine(AGraphics, Self, dl, df);
        if df then
        begin
          if Is3DEnabled then
          begin
            if Assigned(pth) then
            begin
              pth.Clear;
              pth.MoveTo(dl.StartPoint);
              pth.LineTo(dl.EndPoint);
              pth.LineTo(PointF(dl.EndPoint.X + Offset3DX, dl.EndPoint.Y - Offset3DY));
              pth.LineTo(PointF(dl.StartPoint.X + Offset3DX, dl.StartPoint.Y - Offset3DY));
              pth.ClosePath;
              AGraphics.DrawPath(pth);
            end;
          end
          else
            AGraphics.DrawLine(dl.StartPoint, dl.EndPoint, gcpmNone, gcpmNone);

          c.DoAfterDrawSerieLine(AGraphics, Self, dl);
        end;
        AGraphics.RestoreState(st);
      end;
    end;
    ctArea, ctStackedArea, ctStackedPercentageArea, ctBand:
    begin
      if Is3DEnabled and Assigned(pth) then
      begin
        if ChartType = ctBand then
        begin
          for I := FDrawLines.Count - 1 downto FDrawLines.Count div 2 do
          begin
            dl := FDrawLines[I];
            pth.Clear;
            pth.MoveTo(dl.StartPoint);
            pth.LineTo(dl.EndPoint);
            pth.LineTo(PointF(dl.EndPoint.X + Offset3DX, dl.EndPoint.Y - Offset3DY));
            pth.LineTo(PointF(dl.StartPoint.X + Offset3DX, dl.StartPoint.Y - Offset3DY));
            pth.ClosePath;
            AGraphics.DrawPath(pth);
          end;

          for I := 0 to FDrawLines.Count div 2 do
          begin
            dl := FDrawLines[I];
            pth.Clear;
            pth.MoveTo(dl.StartPoint);
            pth.LineTo(dl.EndPoint);
            pth.LineTo(PointF(dl.EndPoint.X + Offset3DX, dl.EndPoint.Y - Offset3DY));
            pth.LineTo(PointF(dl.StartPoint.X + Offset3DX, dl.StartPoint.Y - Offset3DY));
            pth.ClosePath;
            AGraphics.DrawPath(pth);
          end;
        end
        else
        begin
          for I := 0 to FDrawLines.Count - 1 do
          begin
            dl := FDrawLines[I];
            if dl.Bottom then
              Continue;

            pth.Clear;

            pth.MoveTo(dl.StartPoint);
            pth.LineTo(dl.EndPoint);
            pth.LineTo(PointF(dl.EndPoint.X + Offset3DX, dl.EndPoint.Y - Offset3DY));
            pth.LineTo(PointF(dl.StartPoint.X + Offset3DX, dl.StartPoint.Y - Offset3DY));
            pth.ClosePath;
            AGraphics.DrawPath(pth);
          end;
        end;
      end;

      AGraphics.DrawPath(FDrawPath);
    end;
    ctBar, ctStackedBar, ctStackedPercentageBar:
    begin
      dr := FDrawRects[ADrawPoint];
      br := dr.Rect;
      br := ModifyRect(br, gcrmShrinkAll);
      st := AGraphics.SaveState;
      if Assigned(dr.Reference) and (dr.Reference.Color <> gcNull) then
        AGraphics.Fill.Color := dr.Reference.Color
      else if dr.VirtualReference.Color <> gcNull then
        AGraphics.Fill.Color := dr.VirtualReference.Color;

      df := not dr.VirtualReference.Undefined;
      c.DoBeforeDrawSerieBar(AGraphics, Self, dr, df);
      if df then
      begin
        if Is3DEnabled and Assigned(pth) then
        begin
          pth.Clear;
          pth.MoveTo(PointF(br.Left, br.Top));
          pth.LineTo(PointF(br.Right, br.Top));
          pth.LineTo(PointF(br.Right + Offset3DX, br.Top - Offset3DY));
          pth.LineTo(PointF(br.Left + Offset3DX, br.Top - Offset3DY));
          pth.ClosePath;
          AGraphics.DrawPath(pth);
          pth.Clear;
          pth.MoveTo(PointF(br.Right, br.Top));
          pth.LineTo(PointF(br.Right, br.Bottom));
          pth.LineTo(PointF(br.Right + Offset3DX, br.Bottom - Offset3DY));
          pth.LineTo(PointF(br.Right + Offset3DX, br.Top - Offset3DY));
          pth.ClosePath;
          AGraphics.DrawPath(pth);
        end;

        AGraphics.DrawRectangle(br, gcrmExpandAll);
        c.DoAfterDrawSerieBar(AGraphics, Self, dr);
      end;
      AGraphics.RestoreState(st);
    end;
  end;

  if Assigned(pth) then
    pth.Free;
end;

procedure TTMSFNCChartSerie.DrawAnnotations(AGraphics: TTMSFNCGraphics);
var
  c: TTMSFNCChart;
  I: Integer;
  dann: TTMSFNCChartDrawAnnotation;
  dr: Boolean;
  st, sts, strs, ars: TTMSFNCGraphicsSaveState;
  rt: TRectF;
  an: TTMSFNCChartAnnotationVirtual;
  epd: TPointF;
  fl: TTMSFNCGraphicsFill;
  stb: TTMSFNCGraphicsStroke;
  ft: TTMSFNCGraphicsFont;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  if not (FDrawAnnotations.Count > 0) or (Assigned(FAnimateTimer) and FAnimateTimer.Enabled) or Revert then
    Exit;

  fl := TTMSFNCGraphicsFill.Create;
  fl.Color := gcGhostwhite;
  stb := TTMSFNCGraphicsStroke.Create;
  stb.Color := gcGray;

  ft := TTMSFNCGraphicsFont.Create;

  for I := 0 to FDrawAnnotations.Count - 1 do
  begin
    dann := FDrawAnnotations[I];
    an := dann.VirtualAnnotationReference;

    if Assigned(an.Annotation) then
    begin
      fl.Assign(an.Annotation.Fill);
      ft.AssignSource(an.Annotation.Font);
      stb.Assign(an.Annotation.Stroke);
    end;

    c.DoCustomizeAnnotationFill(Self, dann.VirtualPointReference, an, fl);
    c.DoCustomizeAnnotationStroke(Self, dann.VirtualPointReference, an, stb);
    c.DoCustomizeAnnotationFont(Self, dann.VirtualPointReference, an, ft);

    AGraphics.Fill.Assign(fl);
    AGraphics.Font.AssignSource(ft);
    AGraphics.Stroke.Assign(stb);

    st := AGraphics.SaveState;
    dr := not dann.VirtualPointReference.Undefined;
    c.DoBeforeDrawSerieAnnotation(AGraphics, Self, dann, dr);
    if dr then
    begin
      rt := RectF(dann.AnnotationPoint.X - dann.TextWidth / 2, dann.AnnotationPoint.Y - dann.TextHeight,
        dann.AnnotationPoint.X + dann.TextWidth / 2, dann.AnnotationPoint.Y);

      epd := PointF(rt.Left + (rt.Right - rt.Left) / 2, rt.Bottom);

      if an.LineColor <> gcNull then
      begin
        strs := AGraphics.SaveState;
        AGraphics.Stroke.Width := an.LineWidth;
        AGraphics.Stroke.Color := an.LineColor;
        AGraphics.DrawLine(dann.StartPoint, epd);
        AGraphics.RestoreState(strs);
      end;

      if (an.ArrowColor <> gcNull) and (an.Arrow <> arLine) then
      begin
        ars := AGraphics.SaveState;
        AGraphics.Fill.Kind := gfkSolid;
        case an.Arrow of
          arEndArrow: DrawArrow(AGraphics, an.ArrowColor, an.ArrowSize, an.ArrowOpacity, dann.StartPoint, epd);
          arStartArrow: DrawArrow(AGraphics, an.ArrowColor, an.ArrowSize, an.ArrowOpacity, epd, dann.StartPoint);
          arDoubleArrow:
          begin
            DrawArrow(AGraphics, an.ArrowColor, an.ArrowSize, an.ArrowOpacity, dann.StartPoint, epd);
            DrawArrow(AGraphics, an.ArrowColor, an.ArrowSize, an.ArrowOpacity, epd, dann.StartPoint);
          end;
        end;
        AGraphics.RestoreState(ars);
      end;

      case an.Shape of
        asRectangle: AGraphics.DrawRectangle(rt);
        asCircle: AGraphics.DrawEllipse(rt);
      end;
      sts := AGraphics.SaveState;
      AGraphics.Font.Color := an.FontColor;
      AGraphics.DrawText(rt, an.Text, an.WordWrap, an.TextHorizontalAlignment, an.TextVerticalAlignment);
      AGraphics.RestoreState(sts);
      c.DoAfterDrawSerieAnnotation(AGraphics, Self, dann);
      AGraphics.RestoreState(st);
    end;
  end;

  fl.Free;
  stb.Free;
  ft.Free;
end;

procedure TTMSFNCChartSerie.DrawArrow(AGraphics: TTMSFNCGraphics; ArrowColor: TTMSFNCGraphicsColor;
  ArrowSize: Double; ArrowOpacity: Double; origin, target: TPointF);
var
  quarter: Integer;
  fx, px: Double;
  fy, py: Double;
  x, y: Double;
  arrowpts: TTMSFNCGraphicsPathPolygon;
  p: TPointF;
  ar: TPointF;
  h: Double;
  arx, ary: Double;
begin
  SetLength(arrowpts, 4);

  arx := ArrowSize;
  ary := ArrowSize;

  arrowpts[0] := target;

  x := target.x - origin.x;
  y := target.y - origin.y;
  h := sqrt(sqr(x) + sqr(y));

  if h = 0 then
    h := 1;

  if origin.x < target.x then
  begin
    if origin.y < target.y then
      quarter := 1
    else
      quarter := 3;
  end
  else
  begin
    if origin.y < target.y then
      quarter := 2
    else
      quarter := 4;
  end;

  px := x * arx / h;
  py := y * ary / h;
  case quarter of
    1 :
      begin
        p.x := target.x - px;
        p.y := target.y - py;
        ar.x := target.x - (x * arx / h);
        ar.y := target.y - (y * ary / h);
      end;
    2 :
      begin
        p.x := target.x - px;
        p.y := target.y - py;
        ar.x := target.x - (x * arx / h);
        ar.y := target.y - (y * ary / h);
      end;
    3 :
      begin
        p.x := target.x - px;
        p.y := target.y - py;
        ar.x := target.x - (x * arx / h);
        ar.y := target.y - (y * ary / h);
      end;
    4 :
      begin
        p.x := Target.x - px;
        p.y := Target.y - py;
        ar.x := target.x - (x * arx / h);
        ar.y := target.y - (y * ary / h);
      end;
  end;

  fx := y * (arx / 2) / h;
  fy := x * (ary / 2) / h;
  case quarter of
    1 :
      begin
        arrowpts[1].x := p.x - fx;
        arrowpts[1].y := p.y + fy;
        arrowpts[3].x := p.x + fx;
        arrowpts[3].y := p.y - fy;
      end;
    2 :
      begin
        arrowpts[1].x := p.x + fx;
        arrowpts[1].y := p.y - fy;
        arrowpts[3].x := p.x - fx;
        arrowpts[3].y := p.y + fy;
      end;
    3 :
      begin
        arrowpts[1].x := p.x + fx;
        arrowpts[1].y := p.y - fy;
        arrowpts[3].x := p.x - fx;
        arrowpts[3].y := p.y + fy;
      end;
    4 :
      begin
        arrowpts[1].x := p.x + fx;
        arrowpts[1].y := p.y - fy;
        arrowpts[3].x := p.x - fx;
        arrowpts[3].y := p.y + fy;
      end;
  end;

  arrowpts[2] := ar;
  AGraphics.Fill.Color := ArrowColor;
  AGraphics.Fill.Opacity := ArrowOpacity;
  AGraphics.DrawPolygon(arrowpts);
end;

procedure TTMSFNCChartSerie.DrawLabels(AGraphics: TTMSFNCGraphics);
var
  c: TTMSFNCChart;
  I: Integer;
  dlbl: TTMSFNCChartDrawLabel;
  dr: Boolean;
  st, sts: TTMSFNCGraphicsSaveState;
  rt: TRectF;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  if not Labels.Visible or (Assigned(FAnimateTimer) and FAnimateTimer.Enabled) or Revert then
    Exit;

  AGraphics.Fill.Assign(Labels.Fill);
  AGraphics.Font.AssignSource(Labels.Font);
  AGraphics.Stroke.Assign(Labels.Stroke);

  for I := 0 to FDrawLabels.Count - 1 do
  begin
    dlbl := FDrawLabels[I];
    st := AGraphics.SaveState;
    dr := not dlbl.VirtualReference.Undefined;
    c.DoBeforeDrawSerieLabel(AGraphics, Self, dlbl, dr);
    if dr then
    begin
      rt := RectF(dlbl.Point.X - dlbl.TextWidth / 2, dlbl.Point.Y - dlbl.TextHeight, dlbl.Point.X + dlbl.TextWidth / 2, dlbl.Point.Y);
      AGraphics.DrawRectangle(rt);
      sts := AGraphics.SaveState;
      AGraphics.DrawText(rt, dlbl.ValueString, False, gtaCenter);
      AGraphics.RestoreState(sts);
      c.DoAfterDrawSerieLabel(AGraphics, Self, dlbl);
    end;
    AGraphics.RestoreState(st);
  end;
end;

procedure TTMSFNCChartSerie.DrawLegend(AGraphics: TTMSFNCGraphics);
var
  c: TTMSFNCChart;
  r, rm, rt: TRectF;
  I, cnt: Integer;
  pt: TTMSFNCChartPointVirtual;
  th, x, y: Double;
  dr: Boolean;
  st: TTMSFNCGraphicsSaveState;
  str: String;
  li: Boolean;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  cnt := GetPointsCount;
  if cnt = 0 then
    Exit;

  if not Legend.Visible then
    Exit;

  r := Legend.GetRectangle;
  if RectIsEmpty(r) then
    Exit;

  AGraphics.Fill.Assign(Legend.Fill);
  AGraphics.Stroke.Assign(Legend.Stroke);

  dr := True;
  st := AGraphics.SaveState;
  c.DoBeforeDrawSerieLegend(AGraphics, Self, r, dr);
  if dr then
  begin
    AGraphics.DrawRectangle(r);
    AGraphics.Font.AssignSource(Legend.Font);

    x := r.Left + 5;
    y := r.Top + 5;

    if LegendText <> '' then
    begin
      th := AGraphics.CalculateTextHeight(LegendText);
      rt := RectF(r.Left, r.Top, r.Right, r.Top + th + 5);
      InflateRectEx(rt, -2, -2);
      AGraphics.DrawText(rt, LegendText, False, gtaCenter);
      y := y + th;
      AGraphics.DrawLine(PointF(r.Left, Round(y)), PointF(r.Right - 1, Round(y)));
      y := y + 5;
    end;

    for I := 0 to cnt - 1 do
    begin
      pt := GetPoint(I);
      str := GetLegendText(pt);
      if (str <> '') and not pt.Undefined then
      begin
        th := AGraphics.CalculateTextHeight(str);
        rt := RectF(x + th + 5, y, x + (r.Right - r.Left) - 10, y + th);
        rm := RectF(x, y, x + th, y + th);
        y := y + th + 5;

        AGraphics.DrawText(rt, str, False, gtaLeading, gtaLeading);

        AGraphics.Fill.Assign(Fill);
        AGraphics.Stroke.Assign(Stroke);
        if pt.Color <> gcNull then
          AGraphics.Fill.Color := pt.Color;

        InflateRectEx(rm, -(rm.Bottom - rm.Top) / 7.5, -(rm.Bottom - rm.Top) / 7.5);
        li := True;
        if c.FVirtualMode then
          c.DoBeforeDrawSerieLegendIconVirtual(AGraphics, Self, pt, rm, li)
        else
          c.DoBeforeDrawSerieLegendIcon(AGraphics, Self, pt.Point, rm, li);

        if li then
        begin
          DrawChartIcon(AGraphics, ChartType, rm);
          if c.FVirtualMode then
            c.DoAfterDrawSerieLegendIconVirtual(AGraphics, Self, pt, rm)
          else
            c.DoAfterDrawSerieLegendIcon(AGraphics, Self, pt.Point, rm)
        end;
      end;
    end;
    c.DoAfterDrawSerieLegend(AGraphics, Self, r);
  end;
  AGraphics.RestoreState(st);
end;

function TTMSFNCChartSerie.DrawLines: TTMSFNCChartDrawLines;
begin
  Result := FDrawLines;
end;

procedure TTMSFNCChartSerie.DrawMarkers(AGraphics: TTMSFNCGraphics);
var
  msw, msh: Double;
  pth: TTMSFNCGraphicsPath;
  I: Integer;
  pt: TPointF;
  c: TTMSFNCChart;
  dp: TTMSFNCChartDrawPoint;
  dr: Boolean;
  st: TTMSFNCGraphicsSaveState;
begin
  c := Chart;
  if not Assigned(c) or (Assigned(FAnimateTimer) and FAnimateTimer.Enabled) or Revert then
    Exit;

  if not Markers.Visible and (ChartType <> ctXYMarker) and (ChartType <> ctMarker) then
    Exit;

  AGraphics.Fill.Assign(Markers.Fill);
  AGraphics.Stroke.Assign(Markers.Stroke);

  msw := Markers.Width / 2;
  msh := Markers.Height / 2;
  pth := TTMSFNCGraphicsPath.Create;
  for I := 0 to FDrawPoints.Count - 1 do
  begin
    dp := FDrawPoints[I];
    dr := not dp.VirtualReference.Undefined;
    st := AGraphics.SaveState;
    c.DoBeforeDrawSerieMarker(AGraphics, Self, dp, dr);
    if dr then
    begin
      pt := dp.Point;
      case Markers.Shape of
        msEllipse: AGraphics.DrawEllipse(RectF(pt.X - msw, pt.Y - msh, pt.X + msw, pt.Y + msh));
        msSquare:
        begin
          AGraphics.DrawRectangle(RectF(pt.X - msw, pt.Y - msh, pt.X + msw, pt.Y + msh));
        end;
        msDiamond:
        begin
          pth.Clear;
          pth.MoveTo(PointF(pt.X, pt.Y - msh));
          pth.LineTo(PointF(pt.X + msw, pt.Y));
          pth.LineTo(PointF(pt.X, pt.Y + msh));
          pth.LineTo(PointF(pt.X - msw, pt.Y));
          pth.ClosePath;
          AGraphics.DrawPath(pth);
        end;
        msBitmap:
        begin
          if not IsBitmapEmpty(Markers.Bitmap) then
            AGraphics.DrawBitmap(RectF(pt.X - msw, pt.Y - msh, pt.X + msw, pt.Y + msh), Markers.Bitmap);
        end;
        msTriangle:
        begin
          pth.Clear;
          pth.MoveTo(PointF(pt.X, pt.Y - msh));
          pth.LineTo(PointF(pt.X + msw, pt.Y));
          pth.LineTo(PointF(pt.X - msw, pt.Y));
          pth.ClosePath;
          AGraphics.DrawPath(pth);
        end;
      end;
      c.DoAfterDrawSerieMarker(AGraphics, Self, dp);
    end;
    AGraphics.RestoreState(st);
  end;
  pth.Free;
end;

function TTMSFNCChartSerie.DrawMultiPoints: TTMSFNCChartDrawMultiPoints;
begin
  Result := FDrawMultiPoints;
end;

function TTMSFNCChartSerie.DrawPoints: TTMSFNCChartDrawPoints;
begin
  Result := FDrawPoints;
end;

function TTMSFNCChartSerie.DrawRects: TTMSFNCChartDrawRects;
begin
  Result := FDrawRects;
end;

function TTMSFNCChartSerie.DrawSlices: TTMSFNCChartDrawSlices;
begin
  Result := FDrawSlices;
end;

procedure TTMSFNCChartSerie.FillChanged(Sender: TObject);
begin
  InvalidateChart;
end;

function TTMSFNCChartSerie.FindXAxisTextForValue(AValue: Double): String;
var
  l, e, m, cnt: Integer;
  fnd: Boolean;
  dif, dif2: Double;
  pt, ptn: TTMSFNCChartPointVirtual;
begin
  Result := '';
  cnt := GetPointsCount;
  if cnt = 1 then
  begin
    pt := GetPoint(0);
    if (pt.XValue = AValue) and not pt.Undefined then
      Result := pt.XValueText;

    Exit;
  end;

  l  := 0;
  e := cnt - 1;

  fnd := False;
  while (l <= e) and not fnd do
  begin
    m := (l + e) div 2;
    pt := GetPoint(m);
    ptn := GetPoint(m + 1);

    if (AValue >= pt.XValue) and (m >= cnt) and not pt.Undefined then
    begin
      fnd := True;
      Result := pt.XValueText;
    end
    else
    if (AValue >= pt.XValue) and ((m + 1 < cnt) and (AValue <= ptn.XValue)) and (not pt.Undefined) and not (ptn.Undefined) then
    begin
      fnd := True;
      dif := Abs(AValue - pt.XValue);
      dif2 := Abs(AValue - ptn.XValue);
      if dif > dif2 then
        Result := ptn.XValueText
      else
        Result := pt.XValueText;
    end
    else if pt.XValue > AValue then
      e := m - 1
    else
      l := m + 1;
  end;
end;

function TTMSFNCChartSerie.Get3DOffsetX: Double;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  gp, l: Integer;
  lgp: array of Integer;
  J: Integer;
  maxgp: Double;
  c: TTMSFNCChart;
begin
  Result := 0;
  c := Chart;
  if not Assigned(c) then
    Exit;     

  SetLength(lgp, 0);
  Result := 0;
  for I := c.Series.Count - 1 downto 0 do
  begin
    s := c.Series[I];
    if s = Self then
      Break;
    if s.Visible and s.Is3DEnabled and not s.IsBar and not s.IsStacked then
      Result := Result + s.Offset3DX;
  end;

  gp := -1;
  for I := 0 to c.Series.Count - 1 do
  begin
    s := c.Series[I];
    if s.Visible and s.Is3DEnabled and (s.GroupIndex > gp) and (s.IsBar or s.IsArea) then
    begin
      gp := s.GroupIndex;
      l := Length(lgp);
      SetLength(lgp, l + 1);
      lgp[l] := gp;
    end;
  end;

  maxgp := 0;
  for I := 0 to Length(lgp) - 1 do
  begin
    for J := c.Series.Count - 1 downto 0 do
    begin
      s := c.Series[J];
      if Self.GroupIndex = s.GroupIndex then
        Break;

      if s.Visible and s.Is3DEnabled and (s.GroupIndex = lgp[I]) and ((s.IsBar and not s.IsStacked) or (s.IsArea and s.IsStacked)) then
        maxgp := Max(maxgp, s.Offset3DX);
    end;
    Result := Result + maxgp;
  end;
end;

function TTMSFNCChartSerie.Get3DOffsetY: Double;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  gp, l: Integer;
  lgp: array of Integer;
  J: Integer;
  maxgp: Double;
  c: TTMSFNCChart;
begin
  Result := 0;
  c := Chart;
  if not Assigned(c) then
    Exit;

  SetLength(lgp, 0);
  Result := 0;
  for I := c.Series.Count - 1 downto 0 do
  begin
    s := c.Series[I];
    if s = Self then
      Break;
    if s.Visible and s.Is3DEnabled and not s.IsBar and not s.IsStacked then
      Result := Result + s.Offset3DY;
  end;

  gp := -1;
  for I := 0 to c.Series.Count - 1 do
  begin
    s := c.Series[I];
    if s.Visible and s.Is3DEnabled and (s.GroupIndex > gp) and (s.IsBar or s.IsArea) then
    begin
      gp := s.GroupIndex;
      l := Length(lgp);
      SetLength(lgp, l + 1);
      lgp[l] := gp;
    end;
  end;

  maxgp := 0;
  for I := 0 to Length(lgp) - 1 do
  begin
    for J := c.Series.Count - 1 downto 0 do
    begin
      s := c.Series[J];
      if Self.GroupIndex = s.GroupIndex then
        Break;

      if s.Visible and s.Is3DEnabled and (s.GroupIndex = lgp[I]) and ((s.IsBar and not s.IsStacked) or (s.IsArea and s.IsStacked)) then
        maxgp := Max(maxgp, s.Offset3DY);
    end;
    Result := Result + maxgp;
  end;
end;

function TTMSFNCChartSerie.GetBarCount: Integer;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  c: TTMSFNCChart;
  cnt: Integer;
begin
  c := Chart;
  Result := 0;
  if not Assigned(c) then
    Exit;

  if IsStacked then
  begin
    cnt := -1;
    for I := 0 to c.Series.Count - 1 do
    begin
      s := c.Series[I];
      if s.IsBar and (s.Visible) and (s.GroupIndex > cnt) and (s.IsStacked) then
      begin
        Inc(Result);
        Inc(cnt);
      end;
    end;
  end
  else
  begin
    for I := 0 to c.Series.Count - 1 do
    begin
      s := c.Series[I];
      if s.IsBar and (s.Visible) and (s.GroupIndex = GroupIndex) then
        Inc(Result);
    end;
  end;
end;

function TTMSFNCChartSerie.GetBarWidth: Double;
var
  cnt: Integer;
  xs: Double;
begin
  Result := 0;
  case Bar.WidthType of
    bwtPercentage:
    begin
      cnt := GetBarCount;
      if cnt > 0 then
      begin
        xs := ValueToX(GetXOffset, False);
        if xs = 0 then
          xs := XScale;

        Result := xs / cnt;
        Result := Result * Bar.Width / 100;
      end;
    end;
    bwtPixels: Result := Bar.Width;
  end;
end;

function TTMSFNCChartSerie.GetEndCountPoint: Integer;
begin
  Result := Round(Min(GetPointsCount - 1, FEndX));
  case ChartType of
    ctXYLine, ctXYMarker, ctSpider: Result := GetPointsCount - 1;
  end;
end;

function TTMSFNCChartSerie.GetEndPoint: Integer;
begin
  Result := GetPointsCount - 1;
  case ChartType of
    ctBar, ctStackedBar, ctStackedPercentageBar, ctMarker, ctOHLC, ctCandleStick, ctBoxPlot: Result := Round(Min(GetPointsCount - 1, FEndX));
    ctLine, ctDigitalLine, ctArea, ctStackedArea, ctBand, ctStackedPercentageArea: Result := Round(Min(GetPointsCount - 2, FEndX - 1));
    ctXYLine: Result := GetPointsCount - 2;
  end;
end;

function TTMSFNCChartSerie.GetLegendText(APoint: TTMSFNCChartPointVirtual): String;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  Result := FloatToStr(APoint.YValue);
  if APoint.LegendText <> '' then
    Result := APoint.LegendText;

  if c.FVirtualMode then
    c.DoGetSerieLegendTextVirtual(Self, APoint, Result)
  else
    c.DoGetSerieLegendText(Self, APoint.Point, Result);
end;

function TTMSFNCChartSerie.GetMultiPointWidth: Double;
var
  cnt: Integer;
  xs: Double;
begin
  Result := 0;
  case MultiPoint.WidthType of
    bwtPercentage:
    begin
      cnt := 1;
      if cnt > 0 then
      begin
        xs := ValueToX(GetXOffset, False);
        if xs = 0 then
          xs := XScale;

        Result := xs / cnt;
        Result := Result * MultiPoint.Width / 100;
      end;
    end;
    bwtPixels: Result := MultiPoint.Width;
  end;
end;

function TTMSFNCChartSerie.GetPieRect(ASerie: Boolean = False): TRectF;
var
  c: TTMSFNCChart;
  w, wp, hp: Double;
  r: TRectF;
  sr: TRectF;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  r := c.GetSeriesRectangle;
  if c.Series.Count > 0 then
  begin
    w := (r.Right - r.Left);

    if not c.HasStackedPieChart then
    begin
      w := w / c.Series.Count;
      r.Left := r.Left + w * Index;
    end;
    r.Right := r.Left + w;
    r.Left := r.Left + Pie.Margins.Left;
    r.Top := r.Top + Pie.Margins.Top;
    r.Right := r.Right - Pie.Margins.Right;
    r.Bottom := r.Bottom - Pie.Margins.Bottom;

    if ASerie then
    begin
      Result := r;
      Exit;
    end;

    sr := r;

    wp := (r.Right - r.Left);
    hp := (r.Bottom - r.Top);

    if Pie.AutoSize then
    begin
      if hp > wp then
        hp := wp
      else
        wp := hp;
    end
    else
    begin
      wp := Pie.Size;
      hp := Pie.Size;
    end;

    case Pie.Position of
      ppTopLeft, ppCenterLeft, ppBottomLeft: r.Left := sr.Left;
      ppTopCenter, ppCenterCenter, ppBottomCenter: r.Left := sr.Left + ((sr.Right - sr.Left) - wp) / 2;
      ppTopRight, ppCenterRight, ppBottomright: r.Left := sr.Right - wp;
    end;

    case Pie.Position of
      ppTopLeft, ppTopCenter, ppTopRight: r.Top := sr.Top;
      ppCenterLeft, ppCenterCenter, ppCenterRight: r.Top := sr.Top + ((sr.Bottom - sr.Top) - hp) / 2;
      ppBottomLeft, ppBottomCenter, ppBottomRight: r.Top := sr.Bottom - hp;
    end;

    r.Right := r.Left + wp;
    r.Bottom := r.Top + hp;

    Result := r;
  end;
end;

function TTMSFNCChartSerie.GetPoint(AIndex: Integer): TTMSFNCChartPointVirtual;
var
  c: TTMSFNCChart;
begin
  Result := GetDefaultVirtualPoint;
  c := Chart;
  if not Assigned(c) then
    Exit;

  Result.XValue := AIndex;

  if (AIndex >= 0) and (AIndex <= Points.Count - 1) then
    Result := Points[AIndex].GetValue;

  Result.Index := AIndex;
  c.DoGetPoint(Self, AIndex, Result);
end;

function TTMSFNCChartSerie.GetPointsCount: Integer;
var
  c: TTMSFNCChart;
begin
  Result := 0;
  c := Chart;
  if not Assigned(c) then
    Exit;

  Result := Points.Count;
  c.DoGetNumberOfPoints(Self, Result);
end;

function TTMSFNCChartSerie.GetPointsMax: Double;
var
  I: Integer;
  sta, stp: Integer;
  pt: TTMSFNCChartPointVirtual;
begin
  sta := GetStartPoint;
  stp := GetEndPoint;
  Result := 0;
  for I := sta to stp do
  begin
    pt := GetPoint(I);
    if not pt.Undefined then
      Result := Max(Result, pt.YValue);
  end;
end;

function TTMSFNCChartSerie.GetPointsTotal: Double;
var
  I: Integer;
  sta, stp: Integer;
  pt: TTMSFNCChartPointVirtual;
begin
  sta := GetStartPoint;
  stp := GetEndPoint;
  Result := 0;
  for I := sta to stp do
  begin
    pt := GetPoint(I);
    if not pt.Undefined then
      Result := Result + pt.YValue;
  end;
end;

function TTMSFNCChartSerie.GetSpiderLegendText(
  APoint: TTMSFNCChartPointVirtual): String;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  Result := FloatToStr(APoint.YValue);
  if APoint.LegendText <> '' then
    Result := APoint.LegendText;

  if c.FVirtualMode then
    c.DoGetSerieSpiderLegendTextVirtual(Self, APoint, Result)
  else
    c.DoGetSerieSpiderLegendText(Self, APoint.Point, Result);
end;

function TTMSFNCChartSerie.GetStackedDrawLine(ADrawIndex: Integer;
  var ADrawLine: TTMSFNCChartDrawLine): Boolean;
var
  c: TTMSFNCChart;
  s: TTMSFNCChartSerie;
  idx: Integer;
  I: Integer;
begin
  Result := False;
  c := Chart;
  if not Assigned(c) then
    Exit;

  idx := Index;
  for I := idx - 1 downto 0 do
  begin
    if (I >= 0) and (I <= c.Series.Count - 1) then
    begin
      s := c.Series[I];
      if s.Visible and s.IsStacked and (s.GroupIndex = GroupIndex) and s.IsArea then
      begin
        if (ADrawIndex >= 0) and (ADrawIndex <= s.FDrawLines.Count - 1) then
        begin
          ADrawLine := s.FDrawLines[ADrawIndex];
          Result := True;
          Break;
        end;
      end;
    end;
  end;
end;

function TTMSFNCChartSerie.GetStackedDrawRect(ADrawIndex: Integer; var ADrawRect: TTMSFNCChartDrawRect): Boolean;
var
  c: TTMSFNCChart;
  s: TTMSFNCChartSerie;
  idx: Integer;
  I: Integer;
begin
  Result := False;
  c := Chart;
  if not Assigned(c) then
    Exit;

  idx := Index;
  for I := idx - 1 downto 0 do
  begin
    if (I >= 0) and (I <= c.Series.Count - 1) then
    begin
      s := c.Series[I];
      if s.Visible and s.IsStacked and (s.GroupIndex = GroupIndex) and s.IsBar then
      begin
        if (ADrawIndex >= 0) and (ADrawIndex <= s.FDrawRects.Count - 1) then
        begin
          ADrawRect := s.FDrawRects[ADrawIndex];
          Result := True;
          Break;
        end;
      end;
    end;
  end;
end;

function TTMSFNCChartSerie.GetStackedMax(APointIndex: Integer): Double;
var
  c: TTMSFNCChart;
  s: TTMSFNCChartSerie;
  I: Integer;
begin
  Result := 0;
  c := Chart;
  if not Assigned(c) then
    Exit;

  for I := 0 to c.Series.Count - 1 do
  begin
    s := c.Series[I];
    if s.Visible and s.IsStacked and (s.GroupIndex = GroupIndex) then
    begin
      if (APointIndex >= 0) and (APointIndex <= s.GetPointsCount - 1) then
        Result := Result + s.GetPoint(APointIndex).YValue;
    end;
  end;
end;

function TTMSFNCChartSerie.GetStartCountPoint: Integer;
begin
  Result := 0;
  case ChartType of
    ctLine, ctDigitalLine, ctArea, ctBand, ctStackedArea, ctStackedPercentageArea, ctBar, ctStackedBar, ctStackedPercentageBar,
    ctMarker, ctOHLC, ctCandleStick, ctBoxPlot: Result := Round(Max(0, FStartX));
  end;
end;

function TTMSFNCChartSerie.GetStartPoint: Integer;
begin
  Result := 0;
  case ChartType of
    ctLine, ctDigitalLine, ctArea, ctBand, ctStackedArea, ctStackedPercentageArea, ctBar, ctStackedBar, ctStackedPercentageBar,
    ctMarker, ctOHLC, ctCandleStick, ctBoxPlot: Result := Round(Max(0, FStartX));
  end;
end;

function TTMSFNCChartSerie.GetTotalBarWidth: Double;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  c: TTMSFNCChart;
  cnt: Integer;
  bw: Double;
  K: Integer;
  gp: Integer;
  l, j: Integer;
  lgp: array of Integer;
  xs: Double;
begin
  c := Chart;
  Result := 0;
  if not Assigned(c) then
    Exit;

  cnt := GetBarCount;
  SetLength(lgp, 0);

  if IsStacked then
  begin
    gp := -1;
    for I := 0 to c.Series.Count - 1 do
    begin
      s := c.Series[I];
      if s.Visible and s.IsStacked and (s.GroupIndex > gp) and s.IsBar then
      begin
        gp := s.GroupIndex;
        l := Length(lgp);
        SetLength(lgp, l + 1);
        lgp[l] := gp;
      end;
    end;

    K := 0;
    for I := 0 to Length(lgp) - 1 do
    begin
      for J := 0 to c.Series.Count - 1 do
      begin
        s := c.Series[J];
        if s.IsBar and (s.Visible) and (s.GroupIndex = lgp[I]) and s.IsStacked then
        begin
          bw := 0;
          case s.Bar.WidthType of
            bwtPercentage:
            begin
              if cnt > 0 then
              begin
                xs := s.ValueToX(s.GetXOffset, False);
                if xs = 0 then
                  xs := XScale;

                bw := xs / cnt;
                if Length(lgp) - 1 = K then
                  bw := bw * s.Bar.Width / 100
                else
                  bw := bw * (s.Bar.Width + s.Bar.Spacing) / 100
              end;
            end;
            bwtPixels:
            begin
              if Length(lgp) - 1 = K then
                bw := s.Bar.Width
              else
                bw := s.Bar.Width + s.Bar.Spacing
            end;
          end;
          Result := Result + bw;
          Inc(K);
          Break;
        end;
      end;
    end;
  end
  else
  begin
    K := 0;
    for I := 0 to c.Series.Count - 1 do
    begin
      s := c.Series[I];
      if s.IsBar and (s.Visible) and (s.GroupIndex = GroupIndex) then
      begin
        bw := 0;
        case s.Bar.WidthType of
          bwtPercentage:
          begin
            if cnt > 0 then
            begin
              xs := s.ValueToX(s.GetXOffset, False);
              if xs = 0 then
                xs := XScale;

              bw := xs / cnt;
              if cnt - 1 = K then
                bw := bw * s.Bar.Width / 100
              else
                bw := bw * (s.Bar.Width + s.Bar.Spacing) / 100
            end;
          end;
          bwtPixels:
          begin
            if cnt - 1 = K then
              bw := s.Bar.Width
            else
              bw := s.Bar.Width + s.Bar.Spacing
          end;
        end;
        Result := Result + bw;
        Inc(K);
      end;
    end;
  end;
end;

function TTMSFNCChartSerie.GetXOffset: Double;
var
  c: Double;
begin
  Result := 0;
  c := Abs(FXMax - FXMin);
  if c > 0 then
  begin
    if c > 1 then
      Result := 1
    else
    begin
      if GetPointsCount > 1 then
        Result := (GetPoint(1).XValue - GetPoint(0).XValue)
      else
        Result := 1;
    end;
  end;
end;

function TTMSFNCChartSerie.IsChartTypeStored: Boolean;
var
  t: TTMSFNCChartSerieType;
begin
  if Assigned(FChart) then
    t := FChart.GetDefaultChartType
  else
    t := ctLine;
  Result := FChartType <> t;
end;

function TTMSFNCChartSerie.Is3DEnabled: Boolean;
begin
  Result := ((Offset3DX > 0) or (Offset3DY > 0)) and Enable3D;
end;

function TTMSFNCChartSerie.IsArea: Boolean;
begin
  Result := (ChartType = ctArea) or (ChartType = ctBand) or (ChartType = ctStackedArea) or (ChartType = ctStackedPercentageArea);
end;

function TTMSFNCChartSerie.IsBar: Boolean;
begin
  Result := (ChartType = ctBar) or (ChartType = ctStackedBar) or (ChartType = ctStackedPercentageBar);
end;

function TTMSFNCChartSerie.IsPie: Boolean;
begin
  Result := (ChartType = ctPie) or (ChartType = ctVariableRadiusPie) or (ChartType = ctSizedPie) or (ChartType = ctSpider);
end;

function TTMSFNCChartSerie.IsSpider: Boolean;
begin
  Result := (ChartType = ctSpider);
end;

function TTMSFNCChartSerie.IsStacked: Boolean;
begin
  Result := (ChartType = ctStackedBar) or (ChartType = ctStackedPercentageBar) or (ChartType = ctStackedArea) or
    (ChartType = ctStackedPercentageArea);
end;

function TTMSFNCChartSerie.GetBarOffset: Double;
var
  I: Integer;
  s: TTMSFNCChartSerie;
  c: TTMSFNCChart;
  cnt: Integer;
  bw: Double;
  gp: Integer;
  lgp: array of Integer;
  l, J, K: Integer;
  xs: Double;
begin
  c := Chart;
  Result := 0;
  if not Assigned(c) then
    Exit;

  SetLength(lgp, 0);
  cnt := GetBarCount;
  if IsStacked then
  begin
    gp := -1;
    for I := 0 to c.Series.Count - 1 do
    begin
      s := c.Series[I];
      if s.Visible and s.IsStacked and (s.GroupIndex > gp) and s.IsBar then
      begin
        gp := s.GroupIndex;
        l := Length(lgp);
        SetLength(lgp, l + 1);
        lgp[l] := gp;
      end;
    end;

    K := 0;
    for I := 0 to Length(lgp) - 1 do
    begin
      for J := 0 to c.Series.Count - 1 do
      begin
        s := c.Series[J];
        if s.GroupIndex = GroupIndex then
          Exit;
        if s.IsBar and (s.Visible) and (s.GroupIndex = lgp[I]) and s.IsStacked then
        begin
          bw := 0;
          case s.Bar.WidthType of
            bwtPercentage:
            begin
              if cnt > 0 then
              begin
                xs := s.ValueToX(s.GetXOffset, False);
                if xs = 0 then
                  xs := XScale;

                bw := xs / cnt;
                if Length(lgp) - 1 = K then
                  bw := bw * s.Bar.Width / 100
                else
                  bw := bw * (s.Bar.Width + s.Bar.Spacing) / 100
              end;
            end;
            bwtPixels:
            begin
              if Length(lgp) - 1 = K then
                bw := s.Bar.Width
              else
                bw := s.Bar.Width + s.Bar.Spacing
            end;
          end;
          Result := Result + bw;
          Inc(K);
          Break;
        end;
      end;
    end;
  end
  else
  begin
    for I := 0 to c.Series.Count - 1 do
    begin
      s := c.Series[I];
      if (s = Self) then
        Exit;
      if s.IsBar and (s.Visible) and (s.GroupIndex = GroupIndex) then
      begin
        bw := 0;
        case s.Bar.WidthType of
          bwtPercentage:
          begin
            if cnt > 0 then
            begin
              xs := s.ValueToX(s.GetXOffset, False);
              if xs = 0 then
                xs := XScale;

              bw := xs / cnt;
              bw := bw * (s.Bar.Width + s.Bar.Spacing) / 100;
            end;
          end;
          bwtPixels: bw := s.Bar.Width + s.Bar.Spacing;
        end;
        Result := Result + bw
      end;
    end;
  end;
end;

function TTMSFNCChartSerie.Offset: Boolean;
begin
  Result := Mode = smStatistical;
end;

function TTMSFNCChartSerie.CalculateOffset: Boolean;
var
  I: Integer;
  c: TTMSFNCChart;
  s: TTMSFNCChartSerie;
begin
  Result := False;
  c := Chart;
  if not Assigned(c) then
    Exit;

  case Mode of
    smMathematical:
    begin
      if IsBar then
        Result := True
      else
      begin
        for I := 0 to c.Series.Count - 1 do
        begin
          s := c.Series[I];
          if s.Visible and ((s.AutoXRange = arCommon) or (s.AutoXRange = arCommonZeroBased)) and s.IsBar then
          begin
            Result := True;
            Break;
          end;
        end;
      end;
    end;
    smStatistical: Result := True;
  end;
end;

procedure TTMSFNCChartSerie.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

procedure TTMSFNCChartSerie.SetAutoXRange(const Value: TTMSFNCChartSerieAutoRange);
begin
  if FAutoXRange <> Value then
  begin
    FAutoXRange := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetAutoYRange(const Value: TTMSFNCChartSerieAutoRange);
begin
  if FAutoYRange <> Value then
  begin
    FAutoYRange := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetBar(const Value: TTMSFNCChartSerieBar);
begin
  if FBar <> Value then
  begin
    FBar.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetChartType(const Value: TTMSFNCChartSerieType);
begin
  if FChartType <> Value then
  begin
    FChartType := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetEnable3D(const Value: Boolean);
begin
  if FEnable3D <> Value then
  begin
    FEnable3D := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
  begin
    FFill.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetGroupIndex(const Value: Integer);
begin
  if FGroupIndex <> Value then
  begin
    FGroupIndex := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetLabels(const Value: TTMSFNCChartSerieLabels);
begin
  if FLabels <> Value then
  begin
    FLabels.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetLegend(const Value: TTMSFNCChartSerieLegend);
begin
  if FLegend <> Value then
  begin
    FLegend.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetLegendText(const Value: String);
begin
  if FLegendText <> Value then
  begin
    FLegendText := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetMarkers(const Value: TTMSFNCChartSerieMarkers);
begin
  if FMarkers <> Value then
  begin
    FMarkers.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetMaxXOffsetPercentage(const Value: Double);
begin
  if FMaxXOffsetPercentage <> Value then
  begin
    FMaxXOffsetPercentage := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetMaxYOffsetPercentage(const Value: Double);
begin
  if FMaxYOffsetPercentage <> Value then
  begin
    FMaxYOffsetPercentage := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetMaxX(const Value: Double);
begin
  if (FMaxX <> Value) or LogarithmicX then
  begin
    FMaxX := Value;
    if LogarithmicX then
      FMaxX := ConvertToLog(LogarithmicXBase, FMaxX);

    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetMaxY(const Value: Double);
begin
  if (FMaxY <> Value) or LogarithmicY then
  begin
    FMaxY := Value;
    if LogarithmicY then
      FMaxY := ConvertToLog(LogarithmicYBase, FMaxY);

    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetMinXOffsetPercentage(const Value: Double);
begin
  if FMinXOffsetPercentage <> Value then
  begin
    FMinXOffsetPercentage := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetMinYOffsetPercentage(const Value: Double);
begin
  if FMinYOffsetPercentage <> Value then
  begin
    FMinYOffsetPercentage := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetMinX(const Value: Double);
begin
  if (FMinX <> Value) or LogarithmicX then
  begin
    FMinX := Value;
    if LogarithmicX then
      FMinX := ConvertToLog(LogarithmicXBase, FMinX);

    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetMinY(const Value: Double);
begin
  if (FMinY <> Value) or LogarithmicY then
  begin
    FMinY := Value;
    if LogarithmicY then
      FMinY := ConvertToLog(LogarithmicYBase, FMinY);

    UpdateChart;
  end;
end;

function TTMSFNCChartSerie.GetMaxY: Double;
begin
  Result := FMaxY;
  if LogarithmicY then
    Result := Power(LogarithmicYBase, Result);
end;

function TTMSFNCChartSerie.GetMinY: Double;
begin
  Result := FMinY;
  if LogarithmicY then
    Result := Power(LogarithmicYBase, Result);
end;

function TTMSFNCChartSerie.GetMaxX: Double;
begin
  Result := FMaxX;
  if LogarithmicX then
    Result := Power(LogarithmicXBase, Result);
end;

function TTMSFNCChartSerie.GetMinX: Double;
begin
  Result := FMinX;
  if LogarithmicX then
    Result := Power(LogarithmicXBase, Result);
end;

procedure TTMSFNCChartSerie.SetMode(const Value: TTMSFNCChartSerieMode);
begin
  if FMode <> Value then
  begin
    FMode := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetOffset3DX(const Value: Double);
begin
  if FOffset3DX <> Value then
  begin
    FOffset3DX := Max(0, Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetOffset3DY(const Value: Double);
begin
  if FOffset3DY <> Value then
  begin
    FOffset3DY := Max(0, Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetLogarithmicYBase(const Value: Integer);
begin
  if FLogarithmicYBase <> Value then
  begin
    FLogarithmicYBase := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetLogarithmicXBase(const Value: Integer);
begin
  if FLogarithmicXBase <> Value then
  begin
    FLogarithmicXBase := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetLogarithmicY(const Value: Boolean);
begin
  if FLogarithmicY <> Value then
  begin
    FLogarithmicY := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetLogarithmicX(const Value: Boolean);
begin
  if FLogarithmicX <> Value then
  begin
    FLogarithmicX := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetMultiPoint(const Value: TTMSFNCChartSerieMultiPoint);
begin
  if FMultiPoint <> Value then
  begin
    FMultiPoint.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetPie(const Value: TTMSFNCChartSeriePie);
begin
  if FPie <> Value then
  begin
    FPie.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetPoints(const Value: TTMSFNCChartPoints);
begin
  if FPoints <> Value then
  begin
    FPoints.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetCrosshair(const Value: TTMSFNCChartSerieCrosshair);
begin
  if FCrosshair <> Value then
  begin
    FCrosshair.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetShowInLegend(const Value: Boolean);
begin
  if FShowInLegend <> Value then
  begin
    FShowInLegend := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
  begin
    FStroke.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetXGrid(const Value: TTMSFNCChartSerieXYGrid);
begin
  if FXGrid <> Value then
  begin
    FXGrid.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetXValues(const Value: TTMSFNCChartSerieXValues);
begin
  if FXValues <> Value then
  begin
    FXValues.AssignSource(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetYGrid(const Value: TTMSFNCChartSerieXYGrid);
begin
  if FYGrid <> Value then
  begin
    FYGrid.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetYValues(const Value: TTMSFNCChartSerieYValues);
begin
  if FYValues <> Value then
  begin
    FYValues.AssignSource(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.SetZeroReferenceValue(const Value: Double);
begin
  if FZeroReferenceValue <> Value then
  begin
    FZeroReferenceValue := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerie.StrokeChanged(Sender: TObject);
begin
  InvalidateChart;
end;

function TTMSFNCChartSerie.ValueToX(AValue: Double; APosition: Boolean = True): Double;
var
  r: TRectF;
  c: TTMSFNCChart;
  ct: Double;
begin
  c := Chart;
  Result := 0;
  if not Assigned(c) then
    Exit;

  if LogarithmicX then
    AValue := ConvertToLog(LogarithmicXBase, AValue);

  if APosition then
  begin
    r := c.GetSeriesRectangle;

    ct := 0;
    if Offset then
      ct := GetXOffset / 2;

    if FXScale > 0 then
      Result := r.Left + (AValue - FXMin + ct) * FXScale
    else
      Result := r.Left + (AValue - FXMin + ct);

    Result := Result + F3DXOffset;
  end
  else
  begin
    if FXScale > 0 then
      Result := AValue * FXScale
    else
      Result := AValue;
  end;
end;

function TTMSFNCChartSerie.ValueToY(AValue: Double; APosition: Boolean = True): Double;
var
  r: TRectF;
  c: TTMSFNCChart;
begin
  c := Chart;
  Result := 0;
  if not Assigned(c) then
    Exit;

  if LogarithmicY then
    AValue := ConvertToLog(LogarithmicYBase, AValue);

  r := c.GetSeriesRectangle;
  if APosition then
  begin
    if FYScale > 0 then
      Result := r.Top + (r.Bottom - r.Top) - (AValue - FYMin) * FYScale
    else
      Result := r.Top + (r.Bottom - r.Top) - (AValue - FYMin);

    Result := Result - F3DYOffset;
  end
  else
  begin
    if FYScale > 0 then
      Result := AValue * FYScale
    else
      Result := AValue;
  end;
end;

function TTMSFNCChartSerie.XMax: Double;
begin
  Result := FXMax;
end;

function TTMSFNCChartSerie.XMin: Double;
begin
  Result := FXMin;
end;

function TTMSFNCChartSerie.XScale: Double;
begin
  Result := FXScale;
end;

function TTMSFNCChartSerie.XToValue(AX: Double; APosition: Boolean = True): Double;
var
  r: TRectF;
  c: TTMSFNCChart;
  ct: Double;
  off: Double;
begin
  c := Chart;
  Result := 0;
  if not Assigned(c) then
    Exit;

  if APosition then
  begin
    r := c.GetSeriesRectangle;
    ct := 0;
    if Offset then
      ct := GetXOffset / 2;

    off := F3DXOffset;
    if FXScale > 0 then
      Result := ((AX - r.Left - off) / FXScale) + FXMin - ct
    else
      Result := (AX - r.Left - off) + FXMin - ct;
  end
  else
  begin
    if FXScale > 0 then
      Result := AX / FXScale
    else
      Result := AX;
  end;

  if LogarithmicX then
    Result := Power(LogarithmicXBase, Result);
end;

function TTMSFNCChartSerie.XYToBar(X, Y: Double): TTMSFNCChartPoint;
var
  I: Integer;
begin
  Result := nil;
  for I := 0 to FDrawRects.Count - 1 do
  begin
    if PtInRectEx(FDrawRects[I].Rect, PointF(X, Y)) and not FDrawRects[I].VirtualReference.Undefined then
    begin
      Result := FDrawRects[I].Reference;
      Break;
    end;
  end;
end;

function TTMSFNCChartSerie.XYToPoint(X, Y: Double): TTMSFNCChartPoint;
var
  I: Integer;
  r: TRectF;
  dp: TTMSFNCChartDrawPoint;
  c: TTMSFNCChart;
  im: Double;
begin
  Result := nil;
  c := Chart;
  if not Assigned(c) then
    Exit;

  im := c.ClickMargin;
  for I := 0 to FDrawPoints.Count - 1 do
  begin
    dp := FDrawPoints[I];
    r := RectF(dp.Point.X - im / 2, dp.Point.Y - im / 2, dp.point.X + im / 2, dp.Point.Y + im / 2);
    if PtInRectEx(r, PointF(X, Y)) and not FDrawPoints[I].VirtualReference.Undefined then
    begin
      Result := FDrawPoints[I].Reference;
      Break;
    end;
  end;
end;

function TTMSFNCChartSerie.XYToSlice(X, Y: Double): TTMSFNCChartPoint;
var
  I: Integer;
  ds: TTMSFNCChartDrawSlice;
  c: TTMSFNCChart;
  pth: TTMSFNCGraphicsPath;
  g: TTMSFNCGraphics;
begin
  Result := nil;
  c := Chart;
  if not Assigned(c) then
    Exit;

  pth := TTMSFNCGraphicsPath.Create;
  g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, c.NativeCanvas);
  g.BeginScene;
  try
    for I := 0 to FDrawSlices.Count - 1 do
    begin
      ds := FDrawSlices[I];
      if not ds.VirtualReference.Undefined then
      begin
        pth.Clear;
        pth.MoveTo(ds.StartPoint);
        pth.AddArc(ds.CenterPoint, ds.Radius, ds.StartAngle, ds.SweepAngle);
        pth.AddArc(ds.CenterPoint, ds.InnerRadius, ds.StartAngle + ds.SweepAngle, -ds.SweepAngle);
        pth.ClosePath;
        if g.PointInPath(PointF(X, Y), pth) then
        begin
          Result := FDrawSlices[I].Reference;
          Break;
        end;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
    pth.Free;
  end;
end;

function TTMSFNCChartSerie.XYToBarVirtual(X, Y: Double;
  var APoint: TTMSFNCChartPointVirtual): Boolean;
var
  I: Integer;
begin
  Result := False;
  for I := 0 to FDrawRects.Count - 1 do
  begin
    if PtInRectEx(FDrawRects[I].Rect, PointF(X, Y)) and not FDrawRects[I].VirtualReference.Undefined then
    begin
      Result := True;
      APoint := FDrawRects[I].VirtualReference;
      Break;
    end;
  end;
end;

function TTMSFNCChartSerie.XYToLegendItem(X, Y: Double): Integer;
var
  c: TTMSFNCChart;
  r, rt: TRectF;
  I, cnt: Integer;
  pt: TTMSFNCChartPointVirtual;
  th, xx, xy: Double;
  dr: Boolean;
  str: String;
  g: TTMSFNCGraphics;
begin
  Result := -1;
  c := Chart;
  if not Assigned(c) then
    Exit;

  cnt := GetPointsCount;
  if cnt = 0 then
    Exit;

  if not Legend.Visible then
    Exit;

  r := Legend.GetRectangle;
  if RectIsEmpty(r) then
    Exit;

  g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, c.NativeCanvas);
  g.BeginScene;
  try
    dr := True;
    c.DoBeforeDrawSerieLegend(g, Self, r, dr);
    if dr then
    begin
      g.Font.AssignSource(Legend.Font);

      xx := r.Left;
      xy := r.Top + 5;

      if LegendText <> '' then
      begin
        th := g.CalculateTextHeight(LegendText);
        xy := xy + th + 5;
      end;

      for I := 0 to cnt - 1 do
      begin
        pt := GetPoint(I);
        str := GetLegendText(pt);
        if (str <> '') and not pt.Undefined then
        begin
          th := g.CalculateTextHeight(str);
          rt := RectF(xx, xy, xx + (r.Right - r.Left), xy + th);

          if PtInRectEx(rt, PointF(X, Y)) then
          begin
            Result := I;
            Break;
          end;

          xy := xy + th + 5;
        end;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

function TTMSFNCChartSerie.XYToClosestDrawPoint(X: Double; Y: Double; var ADrawPoint: TTMSFNCChartDrawPoint): Boolean;
var
  I: Integer;
  dp: TTMSFNCChartDrawPoint;
  tdif, xdif: Double;
begin
  ADrawPoint := GetDefaultDrawPoint;
  Result := False;

  tdif := MaxDouble;

  for I := FDrawPoints.Count - 1 downto 0 do
  begin
    dp := FDrawPoints[I];

    if not dp.VirtualReference.Undefined then
    begin
      xdif := Abs(ValueToX(dp.VirtualReference.XValue) - x);
      if xdif < tdif then
      begin
        tdif := xdif;
        Result := True;
        ADrawPoint := dp;
      end;
    end;
  end;
end;

function TTMSFNCChartSerie.XYToPointVirtual(X,
  Y: Double; var APoint: TTMSFNCChartPointVirtual): Boolean;
var
  I: Integer;
  r: TRectF;
  dp: TTMSFNCChartDrawPoint;
  c: TTMSFNCChart;
  im: Double;
begin
  Result := False;
  c := Chart;
  if not Assigned(c) then
    Exit;

  im := c.ClickMargin;
  for I := 0 to FDrawPoints.Count - 1 do
  begin
    dp := FDrawPoints[I];
    r := RectF(dp.Point.X - im / 2, dp.Point.Y - im / 2, dp.point.X + im / 2, dp.Point.Y + im / 2);
    if PtInRectEx(r, PointF(X, Y)) and not FDrawPoints[I].VirtualReference.Undefined then
    begin
      Result := True;
      APoint := FDrawPoints[I].VirtualReference;
      Break;
    end;
  end;
end;

function TTMSFNCChartSerie.XYToSliceVirtual(X, Y: Double;
  var APoint: TTMSFNCChartPointVirtual): Boolean;
var
  I: Integer;
  ds: TTMSFNCChartDrawSlice;
  c: TTMSFNCChart;
  pth: TTMSFNCGraphicsPath;
  g: TTMSFNCGraphics;
begin
  Result := False;
  c := Chart;
  if not Assigned(c) then
    Exit;

  pth := TTMSFNCGraphicsPath.Create;
  g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, c.NativeCanvas);
  g.BeginScene;
  try
    for I := 0 to FDrawSlices.Count - 1 do
    begin
      ds := FDrawSlices[I];
      if not ds.VirtualReference.Undefined then
      begin
        pth.Clear;
        pth.MoveTo(ds.CenterPoint);
        pth.AddArc(ds.CenterPoint, ds.Radius, ds.StartAngle, ds.SweepAngle);
        pth.LineTo(ds.CenterPoint);
        pth.ClosePath;
        if g.PointInPath(PointF(X, Y), pth) then
        begin
          Result := True;
          APoint := FDrawSlices[I].VirtualReference;
          Break;
        end;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
    pth.Free;
  end;
end;

function TTMSFNCChartSerie.YMax: Double;
begin
  Result := FYMax;
end;

function TTMSFNCChartSerie.YMin: Double;
begin
  Result := FYMin;
end;

function TTMSFNCChartSerie.YScale: Double;
begin
  Result := FYScale;
end;

function TTMSFNCChartSerie.YToValue(AY: Double; APosition: Boolean = True): Double;
var
  r: TRectF;
  c: TTMSFNCChart;
  off: Double;
begin
  c := Chart;
  Result := 0;
  if not Assigned(c) then
    Exit;

  if APosition then
  begin
    r := c.GetSeriesRectangle;

    off := F3DYOffset;

    if FYScale > 0 then
      Result := (((r.Bottom - r.Top) - AY + r.Top - off) / FYScale) + FYMin
    else
      Result := ((r.Bottom - r.Top) - AY + r.Top - off) + FYMin;
  end
  else
  begin
    if FYScale > 0 then
      Result := AY / FYScale
    else
      Result := AY;
  end;

  if LogarithmicY then
    Result := Power(LogarithmicYBase, Result);
end;

{ TTMSFNCChartSeries }

function TTMSFNCChartSeries.Add: TTMSFNCChartSerie;
begin
  Result := TTMSFNCChartSerie(inherited Add);
end;

procedure TTMSFNCChartSeries.AssignSource(Source: TPersistent);
begin
  inherited Assign(Source);
end;

function TTMSFNCChartSeries.Chart: TTMSFNCChart;
begin
  Result := FChart;
end;

constructor TTMSFNCChartSeries.Create(AChart: TTMSFNCChart);
begin
  inherited Create(AChart, TTMSFNCChartSerie);
  FChart := AChart;
end;

procedure TTMSFNCChartSeries.Draw(AGraphics: TTMSFNCGraphics);
var
  I, J: Integer;
  st: TTMSFNCGraphicsSaveState;
  c: TTMSFNCChart;
  r, rs: TRectF;
  dr: Boolean;
  offx, offy: Double;
  stopb: Integer;
begin
  inherited;
  c := Chart;
  if not Assigned(c) then
    Exit;

  offx := c.FTotalOffset3DX;
  offy := c.FTotalOffset3DY;
  r := c.GetSeriesRectangle;
  st := AGraphics.SaveState;
  rs.Left := r.Left - c.SeriesMargins.Left + 1;
  rs.Top := r.Top - c.SeriesMargins.Top + 1;
  rs.Bottom := r.Bottom + c.SeriesMargins.Bottom;
  rs.Right := r.Right + c.SeriesMargins.Right - 1;
  rs.Top := rs.Top - offy;
  rs.Right := rs.Right + offx;
  AGraphics.ClipRect(rs);

  dr := True;
  c.DoBeforeDrawSeries(AGraphics, r, dr);
  if dr then
  begin
    stopb := GetBarStop;

    if c.DrawOrder = csdoBarsFirst then
    begin
      for I := 0 to stopb - 1 do
      begin
        for J := 0 to Count - 1 do
        begin
          if I <= Items[J].FDrawRects.Count - 1 then
          begin
            if Items[J].Visible and Items[J].IsBar then
              Items[J].Draw(AGraphics, I);
          end;
        end;
      end;
    end;

    for I := 0 to Count - 1 do
    begin
      if Items[I].Visible and Items[I].IsBar then
      begin
        Items[I].DrawMarkers(AGraphics);
        Items[I].DrawLabels(AGraphics);
        Items[I].DrawAnnotations(AGraphics);
      end;
    end;

    for I := 0 to Count - 1 do
    begin
      if Items[I].Visible and Items[I].IsSpider then
        Items[I].DrawSpiderGrid(AGraphics);
    end;
        
    for I := 0 to Count - 1 do
    begin
      if Items[I].Visible and (not Items[I].IsBar or (c.DrawOrder = csdoNormal)) then
      begin
        if Items[I].IsBar then
        begin
          for J := 0 to Items[I].FDrawRects.Count - 1 do
            Items[I].Draw(AGraphics, J);
        end
        else
          Items[I].Draw(AGraphics);

        Items[I].DrawMarkers(AGraphics);
      end;
    end;

    for I := 0 to Count - 1 do
    begin
      if Items[I].Visible and Items[I].IsSpider then
        Items[I].DrawSpiderValues(AGraphics);
    end;    

    for I := 0 to Count - 1 do
    begin
      if Items[I].Visible and (not Items[I].IsBar or (c.DrawOrder = csdoNormal)) then
      begin
        Items[I].DrawLabels(AGraphics);
        Items[I].DrawAnnotations(AGraphics);
      end;
    end;

    if c.DrawOrder = csdoBarsLast then
    begin
      for I := 0 to stopb - 1 do
      begin
        for J := 0 to Count - 1 do
        begin
          if I <= Items[J].FDrawRects.Count - 1 then
          begin
            if Items[J].Visible and Items[J].IsBar then
              Items[J].Draw(AGraphics, I);
          end;
        end;
      end;

      for I := 0 to Count - 1 do
      begin
        if Items[I].Visible and Items[I].IsBar then
        begin
          Items[I].DrawMarkers(AGraphics);
          Items[I].DrawLabels(AGraphics);
          Items[I].DrawAnnotations(AGraphics);
        end;
      end;
    end;

    for I := 0 to Count - 1 do
    begin
      if Items[I].Visible then
        Items[I].DrawLegend(AGraphics);
    end;

    c.DoAfterDrawSeries(AGraphics, r);
  end;

  AGraphics.RestoreState(st);
end;

function TTMSFNCChartSeries.GetBarStop: Integer;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to Count - 1 do
    if Items[I].Visible then
      Result := Max(Result, Items[I].FDrawRects.Count);
end;

function TTMSFNCChartSeries.GetItem(Index: Integer): TTMSFNCChartSerie;
begin
  Result := TTMSFNCChartSerie(inherited Items[Index]);
end;

function TTMSFNCChartSeries.Insert(Index: Integer): TTMSFNCChartSerie;
begin
  Result := TTMSFNCChartSerie(inherited Insert(Index));
end;

procedure TTMSFNCChartSeries.SetItem(Index: Integer;
  const Value: TTMSFNCChartSerie);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCChartPoint }

procedure TTMSFNCChartPoint.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartPoint then
  begin
    FTag := (Source as TTMSFNCChartPoint).Tag;
    FXValue := (Source as TTMSFNCChartPoint).XValue;
    FYValue := (Source as TTMSFNCChartPoint).YValue;
    FYValueSecond := (Source as TTMSFNCChartPoint).YValueSecond;
    FYValueOpen := (Source as TTMSFNCChartPoint).YValueOpen;
    FYValueMedian := (Source as TTMSFNCChartPoint).YValueMedian;
    FYValueLow := (Source as TTMSFNCChartPoint).YValueLow;
    FYValueClose := (Source as TTMSFNCChartPoint).YValueClose;
    FYValueVariable := (Source as TTMSFNCChartPoint).YValueVariable;
    FXValueText := (Source as TTMSFNCChartPoint).XValueText;
    FLegendText := (Source as TTMSFNCChartPoint).LegendText;
    FExplode := (Source as TTMSFNCChartPoint).Explode;
    FUndefined := (Source as TTMSFNCChartPoint).Undefined;
    FColor := (Source as TTMSFNCChartPoint).Color;
    FAnnotations.Assign((Source as TTMSFNCChartPoint).Annotations);
  end;
end;

procedure TTMSFNCChartPoint.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

procedure TTMSFNCChartPoint.UpdateSequential;
var
  s: TTMSFNCChartSerie;
begin
  s := Serie;
  if Assigned(s) and s.FSequential then
  begin
    if XValue < s.FPrevXValue then
      s.FSequential := False
    else
      s.FPrevXValue := XValue;
  end;
end;

procedure TTMSFNCChartPoint.BitmapChanged(Sender: TObject);
begin
  InvalidateChart;
end;

function TTMSFNCChartPoint.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

constructor TTMSFNCChartPoint.Create(ACollection: TCollection);
{$IFDEF FMXLIB}
var
  col: TAlphaColorRec;
{$ENDIF}
begin
  inherited;
  if Assigned(Collection) then
    FSerie := (Collection as TTMSFNCChartPoints).Serie;

  FTag := 0;
  FExplode := 0;
  if Assigned(FSerie) then
    FXValue := FSerie.Points.Count - 1;

  FUndefined := False;
  FYValue := Random(100);
  FYValueSecond := FYValue - 20;
  FYValueVariable := FYValue;
  FYValueLow := FYValue - 20;
  FYValueOpen := FYValue + RandomRange(-10, 10);
  FYValueMedian := FYValueOpen + RandomRange(-5, 5);
  FYValueClose := FYValue - RandomRange(-10, 10);

  if Assigned(FSerie) and (FSerie.ChartType in [ctPie, ctVariableRadiusPie, ctSizedPie]) then
  begin
    if Assigned(FSerie.FChart) then
      Color := FSerie.FChart.GetColorForIndex(Index)
    else
    begin
      {$IFDEF FMXLIB}
      col.R := Random(255);
      col.G := Random(255);
      col.B := Random(255);
      col.A := 255;
      Color := col.Color;
      {$ELSE}
      Color := RGB(Random(255), Random(255), Random(255));
      {$ENDIF}
    end;
  end
  else
    Color := gcNull;

  FAnnotations := TTMSFNCChartAnnotations.Create(Self);
  UpdateChart;
end;

destructor TTMSFNCChartPoint.Destroy;
begin
  FAnnotations.Free;
  inherited;
  UpdateChart;
end;

function TTMSFNCChartPoint.GetValue: TTMSFNCChartPointVirtual;
begin
  Result.Color := Color;
  Result.Tag := Tag;
  Result.XValue := XValue;
  Result.XValueText := XValueText;
  Result.YValue := YValue;
  Result.YValueSecond := YValueSecond;
  Result.YValueVariable := YValueVariable;
  Result.LegendText := LegendText;
  Result.YValueHigh := YValueHigh;
  Result.YValueClose := YValueClose;
  Result.YValueOpen := YValueOpen;
  REsult.YValueMedian := YValueMedian;
  Result.YValueLow := YValueLow;
  Result.Explode := Explode;
  Result.Point := Self;
  Result.Undefined := Undefined;
end;

procedure TTMSFNCChartPoint.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

function TTMSFNCChartPoint.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

procedure TTMSFNCChartPoint.SetAnnotations(
  const Value: TTMSFNCChartAnnotations);
begin
  if FAnnotations <> Value then
  begin
    FAnnotations.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetColor(const Value: TTMSFNCGraphicsColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetExplode(const Value: Double);
begin
  if FExplode <> Value then
  begin
    FExplode := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetLegendText(const Value: String);
begin
  if FLegendText <> value then
  begin
    FLegendText := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetXValue(const Value: Double);
begin
  FXValue := Value;
  UpdateSequential;
  UpdateChart;
end;

procedure TTMSFNCChartPoint.SetXValueText(const Value: String);
begin
  if FXValueText <> Value then
  begin
    FXValueText := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetYValue(const Value: Double);
begin
  if FYValue <> Value then
  begin
    FYValue := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetUndefined(const Value: Boolean);
begin
  if FUndefined <> Value then
  begin
    FUndefined := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetYValueClose(const Value: Double);
begin
  if FYValueClose <> Value then
  begin
    FYValueClose := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetYValueLow(const Value: Double);
begin
  if FYValueLow <> Value then
  begin
    FYValueLow := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetYValueMedian(const Value: Double);
begin
  if FYValueMedian <> Value then
  begin
    FYValueMedian := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetYValueOpen(const Value: Double);
begin
  if FYValueOpen <> Value then
  begin
    FYValueOpen := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetYValueSecond(const Value: Double);
begin
  if FYValueSecond <> Value then
  begin
    FYValueSecond := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartPoint.SetYValueVariable(const Value: Double);
begin
  if FYValueVariable <> Value then
  begin
    FYValueVariable := Value;
    UpdateChart;
  end;
end;

{ TTMSFNCChartPoints }

function TTMSFNCChartPoints.Add: TTMSFNCChartPoint;
begin
  Result := TTMSFNCChartPoint(inherited Add);
end;

constructor TTMSFNCChartPoints.Create(ASerie: TTMSFNCChartSerie);
begin
  inherited Create(ASerie, TTMSFNCChartPoint);
  FSerie := ASerie;
end;

function TTMSFNCChartPoints.GetItem(Index: Integer): TTMSFNCChartPoint;
begin
  Result := TTMSFNCChartPoint(inherited Items[Index]);
end;

function TTMSFNCChartPoints.Insert(Index: Integer): TTMSFNCChartPoint;
begin
  Result := TTMSFNCChartPoint(inherited Insert(Index));
end;

function TTMSFNCChartPoints.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

procedure TTMSFNCChartPoints.SetItem(Index: Integer;
  const Value: TTMSFNCChartPoint);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCChartElement }

procedure TTMSFNCChartElement.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

procedure TTMSFNCChartElement.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartElement then
  begin
    FVisible := (Source as TTMSFNCChartElement).Visible;
    FStroke.Assign((Source as TTMSFNCChartElement).Stroke);
    FFill.Assign((Source as TTMSFNCChartElement).Fill);
  end;
end;

function TTMSFNCChartElement.Chart: TTMSFNCChart;
begin
  Result := FChart;
end;

constructor TTMSFNCChartElement.Create(AChart: TTMSFNCChart);
begin
  FChart := AChart;
  FVisible := True;
  FFill := TTMSFNCGraphicsFill.Create(gfkNone, gcGhostWhite);
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create(gskNone, gcGray);
  FStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCChartElement.Destroy;
begin
  FStroke.Free;
  FFill.Free;
  inherited;
end;

procedure TTMSFNCChartElement.Draw(AGraphics: TTMSFNCGraphics);
begin
  AGraphics.Fill.Assign(Fill);
  AGraphics.Stroke.Assign(Stroke);
end;

procedure TTMSFNCChartElement.FillChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartElement.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

procedure TTMSFNCChartElement.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
  begin
    FFill.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartElement.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
  begin
    FStroke.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartElement.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartElement.StrokeChanged(Sender: TObject);
begin
  InvalidateChart;
end;

{ TTMSFNCChartTitle }

procedure TTMSFNCChartTitle.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCChartTitle then
  begin
    FLine := (Source as TTMSFNCChartTitle).Line;
    FBorder := (Source as TTMSFNCChartTitle).Border;
    FFont.AssignSource((Source as TTMSFNCChartTitle).Font);
    FTextMargins.Assign((Source as TTMSFNCChartTitle).TextMargins);
    FTextVerticalAlignment := (Source as TTMSFNCChartTitle).TextVerticalAlignment;
    FTextHorizontalAlignment := (Source as TTMSFNCChartTitle).TextHorizontalAlignment;
    FText := (Source as TTMSFNCChartTitle).Text;
    FPositions := (Source as TTMSFNCChartTitle).Positions;
    FHeight := (Source as TTMSFNCChartTitle).Height;
  end;
end;

procedure TTMSFNCChartTitle.AssignSource(Source: TPersistent);
begin
  Assign(Source);
end;

constructor TTMSFNCChartTitle.Create(AChart: TTMSFNCChart);
begin
  inherited;
  FChart := AChart;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FTextMargins := TTMSFNCChartMargins.Create;
  FTextMargins.Left := 3;
  FTextMargins.Top := 3;
  FTextMargins.Right := 3;
  FTextMargins.Bottom := 3;
  FTextMargins.OnChange := @TextMarginsChanged;
  FHeight := 35;
  FLine := True;
  FBorder := False;
  FPositions := [tipTop];
  FTextVerticalAlignment := gtaCenter;
  FTextHorizontalAlignment := gtaCenter;
  FStroke.Kind := gskSolid;
  FStroke.OnChanged := @StrokeChanged;
  if Assigned(FChart) then
  begin
    if (csDesigning in FChart.ComponentState) and not ((csReading in FChart.ComponentState) or (csLoading in FChart.ComponentState)) then
      FText := 'Title';
  end;
end;

destructor TTMSFNCChartTitle.Destroy;
begin
  FTextMargins.Free;
  FFont.Free;
  inherited;
end;

procedure TTMSFNCChartTitle.Draw(AGraphics: TTMSFNCGraphics);
var
  pos: TTMSFNCChartTitlePosition;
begin
  inherited;
  if not Visible then
    Exit;

  for pos in Positions do
    DrawPart(AGraphics, pos);
end;

procedure TTMSFNCChartTitle.DrawPart(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartTitlePosition);
var
  dr: Boolean;
  c: TTMSFNCChart;
  txt: String;
  rText: TRectF;
  r: TRectF;
  k: TTMSFNCGraphicsStrokeKind;
  st: TTMSFNCGraphicsSaveState;
  exp: TTMSFNCGraphicsModifyRectMode;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  r := GetRectangle(APosition);

  exp := gcrmExpandAll;

  if not c.HasPieChart then
    exp := gcrmShiftRightAndExpandHeight;

  dr := True;
  st := AGraphics.SaveState;
  c.DoBeforeDrawTitle(AGraphics, r, APosition, dr);

  if dr then
  begin
    k := AGraphics.Stroke.Kind;
    if not Border then
      AGraphics.Stroke.Kind := gskNone;

    AGraphics.DrawRectangle(r, exp);

    AGraphics.Stroke.Kind := k;

    if Line then
    begin
      case APosition of
        tipTop: AGraphics.DrawLine(PointF(R.Left, R.Bottom), PointF(R.Right, R.Bottom));
        tipBottom: AGraphics.DrawLine(PointF(R.Left, R.Top), PointF(R.Right, R.Top));
      end;
    end;

    rText.Left := r.Left + TextMargins.Left;
    rText.Top := r.Top + TextMargins.Top;
    rText.Right := r.Right - TextMargins.Right;
    rText.Bottom := r.Bottom - TextMargins.Bottom;

    AGraphics.Font.AssignSource(Font);
    dr := True;
    txt := Text;
    c.DoDrawTitleText(AGraphics, rText, APosition, txt, dr);
    if dr then
      AGraphics.DrawText(rText, txt, False, TextHorizontalAlignment, TextVerticalAlignment);

    c.DoAfterDrawTitle(AGraphics, r, APosition);
  end;
  AGraphics.RestoreState(st);
end;

procedure TTMSFNCChartTitle.FontChanged(Sender: TObject);
begin
  InvalidateChart;
end;

function TTMSFNCChartTitle.GetRectangle(
  APosition: TTMSFNCChartTitlePosition): TRectF;
var
  c: TTMSFNCChart;
  r: TRectF;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  r := c.GetChartRectangle;
  case APosition of
    tipTop: Result := RectF(R.Left, R.Top, R.Right, R.Top + Height);
    tipBottom: Result := RectF(R.Left, R.Bottom - Height, R.Right, R.Bottom);
  end;

  if (ypLeft in c.YAxis.Positions) and c.YAxis.Visible then
    Result.Left := Result.Left + c.YAxis.GetWidth(ypLeft);

  if (ypRight in c.YAxis.Positions) and c.YAxis.Visible then
    Result.Right := Result.Right - c.YAxis.GetWidth(ypRight);

  Result.Left := Result.Left + c.FTotalOffset3DX;
end;

procedure TTMSFNCChartTitle.SetBorder(const Value: Boolean);
begin
  if FBorder <> Value then
  begin
    FBorder := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartTitle.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  if FFont <> Value then
  begin
    FFont.AssignSource(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartTitle.SetHeight(const Value: Double);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartTitle.SetLine(const Value: Boolean);
begin
  if FLine <> Value then
  begin
    FLine := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartTitle.SetPositions(const Value: TTMSFNCChartTitlePositions);
begin
  if FPositions <> Value then
  begin
    FPositions := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartTitle.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartTitle.SetTextHorizontalAlignment(const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextHorizontalAlignment <> Value then
  begin
    FTextHorizontalAlignment := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartTitle.SetTextMargins(const Value: TTMSFNCChartMargins);
begin
  if FTextMargins <> Value then
  begin
    FTextMargins.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartTitle.SetTextVerticalAlignment(const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextVerticalAlignment <> Value then
  begin
    FTextVerticalAlignment := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartTitle.TextMarginsChanged(Sender: TObject);
begin
  InvalidateChart;
end;

{ TTMSFNCChartXAxis }

procedure TTMSFNCChartXAxis.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCChartXAxis then
  begin
    FPositions := (Source as TTMSFNCChartXAxis).Positions;
    FHeight := (Source as TTMSFNCChartXAxis).Height;
    FLine := (Source as TTMSFNCChartXAxis).Line;
    FBorder := (Source as TTMSFNCChartXAxis).Border;
    FAutoSize := (Source as TTMSFNCChartXAxis).AutoSize;
    FDisplayAtReferenceValue := (Source as TTMSFNCChartXAxis).DisplayAtReferenceValue;
    FReferenceValue := (Source as TTMSFNCChartXAxis).ReferenceValue;
    FReferenceValueSeriesIndex := (Source as TTMSFNCChartXAxis).ReferenceValueSeriesIndex;
  end;
end;

procedure TTMSFNCChartXAxis.AssignSource(Source: TPersistent);
begin
  Assign(Source);
end;

function TTMSFNCChartXAxis.Chart: TTMSFNCChart;
begin
  Result := FChart;
end;

constructor TTMSFNCChartXAxis.Create(AChart: TTMSFNCChart);
begin
  inherited;
  FChart := AChart;
  FAutoSize := True;
  FLine := True;
  FHeight := 35;
  FBorder := False;
  FPositions := [xpBottom];
  FStroke.Kind := gskSolid;
  FStroke.OnChanged := @StrokeChanged;
  FDisplayAtReferenceValue := False;
  FReferenceValueSeriesIndex := 0;
  FReferenceValue := 0;
end;

destructor TTMSFNCChartXAxis.Destroy;
begin
  inherited;
end;

procedure TTMSFNCChartXAxis.Draw(AGraphics: TTMSFNCGraphics);
var
  pos: TTMSFNCChartXAxisPosition;
  I: Integer;
  c: TTMSFNCChart;
  s: TTMSFNCChartSerie;
begin
  inherited;

  if not Visible then
    Exit;

  c := Chart;
  if not Assigned(c) then
    Exit;

  if c.HasPieChart then
    Exit;

  for pos in Positions do
    DrawPart(AGraphics, pos);

  for pos in Positions do
  begin
    for I := 0 to c.Series.Count - 1 do
    begin
      s := c.Series[I];
      if s.Visible and (pos in s.XValues.Positions) then
        DrawValues(AGraphics, pos, s);
    end;
  end;
end;

procedure TTMSFNCChartXAxis.DrawKind(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartXAxisPosition; AKind: TTMSFNCChartDrawXYValueKind;
  ASerie: TTMSFNCChartSerie);
var
  dv: TTMSFNCChartDrawXYValue;
  I: Integer;
  dr: Boolean;
  c: TTMSFNCChart;
  tc, spc: Double;
  rt: TRectF;
  st: TTMSFNCGraphicsSaveState;
  w, h, cw, ch, cs, si: Single;
  ar: Single;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  if AKind = vkMajor then
  begin
    tc := ASerie.XValues.MajorUnitTickMarkSize;
    spc := ASerie.XValues.MajorUnitSpacing;
    AGraphics.Font.AssignSource(ASerie.XValues.MajorUnitFont);
    AGraphics.Stroke.Color := ASerie.XValues.MajorUnitTickMarkColor;
  end
  else
  begin
    tc := ASerie.XValues.MinorUnitTickMarkSize;
    spc := ASerie.XValues.MinorUnitSpacing;
    AGraphics.Font.AssignSource(ASerie.XValues.MinorUnitFont);
    AGraphics.Stroke.Color := ASerie.XValues.MinorUnitTickMarkColor;
  end;

  for I := 0 to ASerie.FDrawXValues.Count - 1 do
  begin
    dv := ASerie.FDrawXValues[I];
    if dv.Kind = AKind then
    begin
      dr := True;
      st := AGraphics.SaveState;
      c.DoBeforeDrawSerieXValue(AGraphics, ASerie, APosition, dv, dr);
      if dr then
      begin
        case APosition of
          xpTop:
          begin
            AGraphics.DrawLine(PointF(dv.ValuePositionXTop.X, dv.ValuePositionXTop.Y - tc), PointF(dv.ValuePositionXTop.X, dv.ValuePositionXTop.Y));
            rt := RectF(dv.TextPositionXTop.X - dv.TextWidth / 2, dv.TextPositionXTop.Y - dv.TextHeight - tc - spc,
              dv.TextPositionXTop.X + dv.TextWidth / 2, dv.TextPositionXTop.Y - tc - spc);
          end;
          xpCenter:
          begin
            AGraphics.DrawLine(PointF(dv.ValuePositionXCenter.X, dv.ValuePositionXCenter.Y - tc / 2), PointF(dv.ValuePositionXCenter.X,
              dv.ValuePositionXCenter.Y + tc / 2));

            rt := RectF(dv.TextPositionXCenter.X - dv.TextWidth / 2, dv.TextPositionXCenter.Y + tc / 2 + spc,
              dv.TextPositionXCenter.X + dv.TextWidth / 2, dv.TextPositionXCenter.Y  + tc / 2 + spc + dv.TextHeight);
          end;
          xpBottom:
          begin
            AGraphics.DrawLine(PointF(dv.ValuePositionXBottom.X, dv.ValuePositionXBottom.Y), PointF(dv.ValuePositionXBottom.X, dv.ValuePositionXBottom.Y + tc));
            rt := RectF(dv.TextPositionXBottom.X - dv.TextWidth / 2, dv.TextPositionXBottom.Y + tc + spc, dv.TextPositionXBottom.X + dv.TextWidth / 2,
              dv.TextPositionXBottom.Y + tc + spc + dv.TextHeight);
          end;
        end;

        ar := ASerie.XValues.Angle;

        if ar <> 0 then
        begin
          w := rt.Right - rt.Left;
          h := rt.Bottom - rt.Top;
          cs := Cos(ar);
          si := Sin(ar);

          cw := (Abs(w * cs) + Abs(h * si));
          ch := (Abs(w * si) + Abs(h * cs));

          rt := RectF(rt.Left + w / 2 - cw / 2, rt.Top + h / 2 - ch / 2, rt.Left + w / 2 + cw / 2, rt.Top + h / 2 + ch / 2);
        end;

        AGraphics.DrawText(rt, dv.ValueString, False, gtaCenter, gtaCenter, gttNone, ASerie.XValues.Angle);

        c.DoAfterDrawSerieXValue(AGraphics, ASerie, APosition, dv);
      end;
      AGraphics.RestoreState(st);
    end;
  end;
end;

procedure TTMSFNCChartXAxis.DrawPart(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartXAxisPosition);
var
  dr: Boolean;
  c: TTMSFNCChart;
  r: TRectF;
  st: TTMSFNCGraphicsSaveState;
  k: TTMSFNCGraphicsStrokeKind;
  ex: TTMSFNCGraphicsModifyRectMode;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  r := GetRectangle(APosition);

  case APosition of
    xpTop:  ex := gcrmShiftRightDown;
    xpCenter, xpBottom: ex := gcrmShiftRightAndShrinkHeight;
    else
      ex := gcrmExpandAll;
  end;

  dr := True;
  st := AGraphics.SaveState;
  c.DoBeforeDrawXAxis(AGraphics, r, APosition, dr);

  if dr then
  begin
    k := AGraphics.Stroke.Kind;
    if not Border then
      AGraphics.Stroke.Kind := gskNone;

    AGraphics.DrawRectangle(r, ex);

    AGraphics.Stroke.Kind := k;

    if Line then
    begin
      case APosition of
        xpTop: AGraphics.DrawLine(PointF(R.Left, R.Bottom), PointF(R.Right, R.Bottom));
        xpCenter: AGraphics.DrawLine(PointF(R.Left, CenterPointEx(R).Y), PointF(R.Right, CenterPointEx(R).Y));
        xpBottom:
        begin
          AGraphics.DrawLine(PointF(R.Left, R.Top), PointF(R.Right, R.Top));
          if (c.FTotalOffset3DX > 0) or (c.FTotalOffset3DY > 0) then
          begin
            AGraphics.DrawLine(PointF(R.Left, R.Top), PointF(R.Left + c.FTotalOffset3DX, R.Top - c.FTotalOffset3DY));
            AGraphics.DrawLine(PointF(R.Right, R.Top), PointF(R.Right + c.FTotalOffset3DX, R.Top - c.FTotalOffset3DY));
            AGraphics.DrawLine(PointF(R.Left + c.FTotalOffset3DX, R.Top - c.FTotalOffset3DY), PointF(R.Right + c.FTotalOffset3DX, R.Top - c.FTotalOffset3DY));
          end;
        end;
      end;
    end;

    c.DoAfterDrawXAxis(AGraphics, r, APosition);
  end;
  AGraphics.RestoreState(st);
end;

procedure TTMSFNCChartXAxis.DrawTitle(AGraphics: TTMSFNCGraphics;
  APosition: TTMSFNCChartXAxisPosition; ASerie: TTMSFNCChartSerie);
var
  r, rt: TRectF;
  ts: Double;
  txt: String;
  txth: Double;
  tm: TTMSFNCChartMargins;
  c: TTMSFNCChart;
  dr: Boolean;
  st: TTMSFNCGraphicsSaveState;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  txt := ASerie.XValues.Title.Text;
  if txt <> '' then
  begin
    AGraphics.Font.AssignSource(ASerie.XValues.Title.Font);
    tm := ASerie.XValues.Title.TextMargins;
    r := GetRectangle(APosition);
    ts := AGraphics.CalculateTextHeight(txt);
    txth := ts + 4;
    case APosition of
      xpTop: rt := RectF(r.Left + tm.Left, r.Bottom - ASerie.FXAxisTopHeight + tm.Top, r.Right - tm.Right, r.Bottom - ASerie.FXAxisTopHeight + txth + tm.Top + tm.Bottom);
      xpCenter: rt := RectF(r.Left + tm.Left, r.Top + (r.Bottom - r.Top) / 2 + ASerie.FXAxisCenterHeight - tm.Top - tm.Bottom - txth, r.Right - tm.Right, R.Top + (r.Bottom - r.Top) / 2 + ASerie.FXAxisCenterHeight - tm.Top);
      xpBottom: rt := RectF(r.Left + tm.Left, r.Top + ASerie.FXAxisBottomHeight - tm.Top - tm.Bottom - txth, r.Right - tm.Right, r.Top + ASerie.FXAxisBottomHeight - tm.Top);
    end;
    dr := True;
    st := AGraphics.SaveState;
    c.DoBeforeDrawXValuesTitle(AGraphics, r, rt, APosition, ASerie, dr);
    if dr then
    begin
      AGraphics.DrawText(rt, txt, False, ASerie.XValues.Title.TextHorizontalAlignment, ASerie.XValues.Title.TextVerticalAlignment);
      c.DoAfterDrawXValuesTitle(AGraphics, r, rt, APosition, ASerie);
    end;
    AGraphics.RestoreState(st);
  end;
end;

procedure TTMSFNCChartXAxis.DrawValues(AGraphics: TTMSFNCGraphics;
  APosition: TTMSFNCChartXAxisPosition; ASerie: TTMSFNCChartSerie);
begin
  if not Assigned(ASerie) then
    Exit;

  DrawKind(AGraphics, APosition, vkMinor, ASerie);
  DrawKind(AGraphics, APosition, vkMajor, ASerie);
  DrawTitle(AGraphics, APosition, ASerie);
end;

function TTMSFNCChartXAxis.GetHeight(APosition: TTMSFNCChartXAxisPosition): Double;
var
  c: TTMSFNCChart;
begin
  Result := 0;
  c := Chart;
  if not Assigned(c) then
    Exit;

  if c.HasPieChart then
    Exit;

  if not Visible or not (APosition in Positions) then
    Exit;

  Result := FHeight;
  if AutoSize then
  begin
    case APosition of
      xpTop: Result := FTopHeight;
      xpCenter: Result := FCenterHeight;
      xpBottom: Result := FBottomHeight;
    end;
  end;
end;

function TTMSFNCChartXAxis.GetRectangle(
  APosition: TTMSFNCChartXAxisPosition): TRectF;
var
  c: TTMSFNCChart;
  r, rc: TRectF;
  pos: Double;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  r := c.GetChartRectangle;
  case APosition of
    xpTop:
    begin
      Result := RectF(R.Left, R.Top, R.Right, R.Top + GetHeight(APosition));
      if (tipTop in c.Title.Positions) and c.Title.Visible then
      begin
        Result.Top := Result.Top + c.Title.Height;
        Result.Bottom := Result.Bottom + c.Title.Height;
      end;
    end;
    xpCenter:
    begin
      rc := r;
      if Visible then
      begin
        if xpTop in Positions then
          rc.Top := rc.Top + GetHeight(xpTop);

        if xpBottom in Positions then
          rc.Bottom := rc.Bottom - GetHeight(xpBottom);
      end;

      if c.Title.Visible then
      begin
        if tipTop in c.Title.Positions then
          rc.Top := rc.Top + c.Title.Height;
        if tipBottom in c.Title.Positions then
          rc.Bottom := rc.Bottom - c.Title.Height;
      end;

      if DisplayAtReferenceValue and (ReferenceValueSeriesIndex >= 0) and (ReferenceValueSeriesIndex <= c.Series.Count - 1) then
        pos := c.Series[ReferenceValueSeriesIndex].ValueToY(ReferenceValue) - GetHeight(APosition) / 2
      else
        pos := rc.Top + ((rc.Bottom - rc.Top) - GetHeight(APosition)) / 2;

      Result := RectF(rc.Left, pos, rc.Right, pos + GetHeight(APosition));
    end;
    xpBottom:
    begin
      Result := RectF(R.Left, R.Bottom - GetHeight(APosition), R.Right, R.Bottom);
      if (tipBottom in c.Title.Positions) and (c.Title.Visible) then
      begin
        Result.Top := Result.Top - c.Title.Height;
        Result.Bottom := Result.Bottom - c.Title.Height;
      end;
    end;
  end;

  if (ypLeft in c.YAxis.Positions) and c.YAxis.Visible then
    Result.Left := Result.Left + c.YAxis.GetWidth(ypLeft);

  if (ypRight in c.YAxis.Positions) and c.YAxis.Visible then
    Result.Right := Result.Right - c.YAxis.GetWidth(ypRight);

  if APosition = xpBottom then
    Result.Right := Result.Right - c.FTotalOffset3DX;

  if APosition = xpTop then
    Result.Left := Result.Left + c.FTotalOffset3DX;
end;

function TTMSFNCChartXAxis.IsReferenceValueStored: Boolean;
begin
  Result := ReferenceValue <> 0;
end;

procedure TTMSFNCChartXAxis.SetAutoSize(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartXAxis.SetBorder(const Value: Boolean);
begin
  if FBorder <> Value then
  begin
    FBorder := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartXAxis.SetDisplayAtReferenceValue(const Value: Boolean);
begin
  if FDisplayAtReferenceValue <> Value then
  begin
    FDisplayAtReferenceValue := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartXAxis.SetHeight(const Value: Double);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartXAxis.SetLine(const Value: Boolean);
begin
  if FLine <> Value then
  begin
    FLine := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartXAxis.SetPositions(const Value: TTMSFNCChartXAxisPositions);
begin
  if FPositions <> Value then
  begin
    FPositions := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartXAxis.SetReferenceValue(const Value: Double);
begin
  if FReferenceValue <> Value then
  begin
    FReferenceValue := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartXAxis.SetReferenceValueSeriesIndex(const Value: Integer);
begin
  if FReferenceValueSeriesIndex <> Value then
  begin
    FReferenceValueSeriesIndex := Value;
    UpdateChart;
  end;
end;

{ TTMSFNCChartYAxis }

procedure TTMSFNCChartYAxis.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCChartYAxis then
  begin
    FPositions := (Source as TTMSFNCChartYAxis).Positions;
    FWidth := (Source as TTMSFNCChartYAxis).Width;
    FLine := (Source as TTMSFNCChartYAxis).Line;
    FBorder := (Source as TTMSFNCChartYAxis).Border;
    FAutoSize := (Source as TTMSFNCChartYAxis).AutoSize;
    FDisplayAtReferenceValue := (Source as TTMSFNCChartYAxis).DisplayAtReferenceValue;
    FReferenceValue := (Source as TTMSFNCChartYAxis).ReferenceValue;
    FReferenceValueSeriesIndex := (Source as TTMSFNCChartYAxis).ReferenceValueSeriesIndex;
  end;
end;

procedure TTMSFNCChartYAxis.AssignSource(Source: TPersistent);
begin
  Assign(Source);
end;

constructor TTMSFNCChartYAxis.Create(AChart: TTMSFNCChart);
begin
  inherited;
  FChart := AChart;
  FAutoSize := True;
  FLine := True;
  FBorder := False;
  FWidth := 35;
  FPositions := [ypLeft, ypRight];
  FStroke.Kind := gskSolid;
  FStroke.OnChanged := @StrokeChanged;
  FDisplayAtReferenceValue := False;
  FReferenceValueSeriesIndex := 0;
  FReferenceValue := 0;
end;

destructor TTMSFNCChartYAxis.Destroy;
begin
  inherited;
end;

procedure TTMSFNCChartYAxis.Draw(AGraphics: TTMSFNCGraphics);
var
  pos: TTMSFNCChartYAxisPosition;
  I: Integer;
  c: TTMSFNCChart;
  s: TTMSFNCChartSerie;
begin
  inherited;
  if not Visible then
    Exit;

  c := Chart;
  if not Assigned(c) then
    Exit;

  if c.HasPieChart then
    Exit;

  for pos in Positions do
    DrawPart(AGraphics, pos);

  for pos in Positions do
  begin
    for I := 0 to c.Series.Count - 1 do
    begin
      s := c.Series[I];
      if s.Visible and (pos in s.YValues.Positions) then
        DrawValues(AGraphics, pos, s);
    end;
  end;
end;

procedure TTMSFNCChartYAxis.DrawKind(AGraphics: TTMSFNCGraphics;
  APosition: TTMSFNCChartYAxisPosition; AKind: TTMSFNCChartDrawXYValueKind; ASerie: TTMSFNCChartSerie);
var
  tc, spc: Double;
  I: Integer;
  dv: TTMSFNCChartDrawXYValue;
  dr: Boolean;
  c: TTMSFNCChart;
  rt: TRectF;
  st: TTMSFNCGraphicsSaveState;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  if AKind = vkMajor then
  begin
    tc := ASerie.YValues.MajorUnitTickMarkSize;
    spc := ASerie.YValues.MajorUnitSpacing;
    AGraphics.Font.AssignSource(ASerie.YValues.MajorUnitFont);
    AGraphics.Stroke.Color := ASerie.YValues.MajorUnitTickMarkColor;
  end
  else
  begin
    tc := ASerie.YValues.MinorUnitTickMarkSize;
    spc := ASerie.YValues.MinorUnitSpacing;
    AGraphics.Font.AssignSource(ASerie.YValues.MinorUnitFont);
    AGraphics.Stroke.Color := ASerie.YValues.MinorUnitTickMarkColor;
  end;

  for I := 0 to ASerie.FDrawYValues.Count - 1 do
  begin
    dv := ASerie.FDrawYValues[I];
    if dv.Kind = AKind then
    begin
      dr := True;
      st := AGraphics.SaveState;
      c.DoBeforeDrawSerieYValue(AGraphics, ASerie, APosition, dv, dr);
      if dr then
      begin
        case APosition of
          ypLeft:
          begin
            AGraphics.DrawLine(PointF(dv.ValuePositionYLeft.X, dv.ValuePositionYLeft.Y), PointF(dv.ValuePositionYLeft.X - tc, dv.ValuePositionYLeft.Y));
            rt := RectF(dv.TextPositionYLeft.X - dv.TextWidth - tc - spc, dv.TextPositionYLeft.Y - dv.TextHeight / 2,
              dv.TextPositionYLeft.X - tc - spc, dv.TextPositionYLeft.Y + dv.TextHeight / 2);
          end;
          ypCenter:
          begin
            AGraphics.DrawLine(PointF(dv.ValuePositionYCenter.X - tc / 2, dv.ValuePositionYCenter.Y), PointF(dv.ValuePositionYCenter.X + tc / 2,
              dv.ValuePositionYCenter.Y));
            rt := RectF(dv.TextPositionYCenter.X + tc / 2 + spc, dv.TextPositionYCenter.Y - dv.TextHeight / 2,
              dv.TextPositionYCenter.X + tc / 2 + spc + dv.TextWidth, dv.TextPositionYCenter.Y + dv.TextHeight / 2);
          end;
          ypRight:
          begin
            AGraphics.DrawLine(PointF(dv.ValuePositionYRight.X, dv.ValuePositionYRight.Y), PointF(dv.ValuePositionYRight.X + tc, dv.ValuePositionYRight.Y));
            rt := RectF(tc + spc + dv.TextPositionYRight.X, dv.TextPositionYRight.Y - dv.TextHeight / 2, tc + spc + dv.TextWidth + dv.TextPositionYRight.X,
              dv.TextPositionYRight.Y + dv.TextHeight / 2);
          end;
        end;

        AGraphics.DrawText(rt, dv.ValueString, False, gtaLeading);
        c.DoAfterDrawSerieYValue(AGraphics, ASerie, APosition, dv);
      end;
      AGraphics.RestoreState(st);
    end;
  end;
end;

procedure TTMSFNCChartYAxis.DrawPart(AGraphics: TTMSFNCGraphics; APosition: TTMSFNCChartYAxisPosition);
var
  dr: Boolean;
  c: TTMSFNCChart;
  r: TRectF;
  st: TTMSFNCGraphicsSaveState;
  k: TTMSFNCGraphicsStrokeKind;
  ex: TTMSFNCGraphicsModifyRectMode;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  r := GetRectangle(APosition);

  case APosition of
    ypLeft, ypCenter: ex := gcrmShiftDownAndExpandWidth;
    ypRight: ex := gcrmShiftRightDown;
    else
      ex := gcrmExpandAll;
  end;

  dr := True;
  st := AGraphics.SaveState;
  c.DoBeforeDrawYAxis(AGraphics, r, APosition, dr);

  if dr then
  begin
    k := AGraphics.Stroke.Kind;
    if not Border then
      AGraphics.Stroke.Kind := gskNone;

    AGraphics.DrawRectangle(r, ex);

    AGraphics.Stroke.Kind := k;

    if Line then
    begin
      case APosition of
        ypLeft:
        begin
          AGraphics.DrawLine(PointF(R.Right, R.Top), PointF(R.Right, R.Bottom));
          if (c.FTotalOffset3DX > 0) or (c.FTotalOffset3DY > 0) then
          begin
            AGraphics.DrawLine(PointF(R.Right, R.Top), PointF(R.Right + c.FTotalOffset3DX, R.Top - c.FTotalOffset3DY));
            AGraphics.DrawLine(PointF(R.Right + c.FTotalOffset3DX, R.Top - c.FTotalOffset3DY), PointF(R.Right + c.FTotalOffset3DX, R.Bottom - c.FTotalOffset3DY));
          end;
        end;
        ypCenter: AGraphics.DrawLine(PointF(CenterPointEx(R).X, R.Top), PointF(CenterPointEx(R).X, R.Bottom));
        ypRight: AGraphics.DrawLine(PointF(R.Left, R.Top), PointF(R.Left, R.Bottom));
      end;
    end;

    c.DoAfterDrawYAxis(AGraphics, r, APosition);
  end;
  AGraphics.RestoreState(st);
end;

procedure TTMSFNCChartYAxis.DrawTitle(AGraphics: TTMSFNCGraphics;
  APosition: TTMSFNCChartYAxisPosition; ASerie: TTMSFNCChartSerie);
var
  r, rt, txtr: TRectF;
  th, tw: Double;
  txt: String;
  txth: Double;
  tm: TTMSFNCChartMargins;
  dr: Boolean;
  c: TTMSFNCChart;
  st: TTMSFNCGraphicsSaveState;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  txt := ASerie.YValues.Title.Text;
  if txt <> '' then
  begin
    AGraphics.Font.AssignSource(ASerie.YValues.Title.Font);
    tm := ASerie.YValues.Title.TextMargins;
    r := GetRectangle(APosition);
    th := AGraphics.CalculateTextHeight(txt);
    tw := AGraphics.CalculateTextWidth(txt);
    txth := th + 4;
    case APosition of
      ypLeft: rt := RectF(r.Right - ASerie.FYAxisLeftWidth + tm.Left, r.Top + tm.Top, r.Right - ASerie.FYAxisLeftWidth + txth + tm.Left + tm.Right, r.Bottom - tm.Bottom);
      ypCenter: rt := RectF(r.Left + (r.Right - r.Left) / 2 + ASerie.FYAxisCenterWidth - tm.Left - txth - tm.Right, r.Top + tm.Top, R.Left + (r.Right - r.Left) / 2 + ASerie.FYAxisCenterWidth - tm.Left, r.Bottom - tm.Bottom);
      ypRight: rt := RectF(r.Left + ASerie.FYAxisRightWidth - tm.Left - txth - tm.Right, r.Top + tm.Top, R.Left + ASerie.FYAxisRightWidth - tm.Left, r.Bottom - tm.Bottom);
    end;

    dr := True;
    st := AGraphics.SaveState;
    c.DoBeforeDrawYValuesTitle(AGraphics, r, rt, APosition, ASerie, dr);
    if dr then
    begin
      txtr := rt;

      case ASerie.YValues.Title.TextHorizontalAlignment of
        gtaCenter: txtr.Left := txtr.Left + ((txtr.Right - txtr.Left) - th) / 2;
        gtaTrailing: txtr.Left := txtr.Right - th;
      end;

      case ASerie.YValues.Title.TextVerticalAlignment of
        gtaCenter: txtr.Top := txtr.Top + ((txtr.Bottom - txtr.Top) - tw) / 2;
        gtaTrailing: txtr.Top := txtr.Bottom - tw;
      end;

      txtr.Right := txtr.Left + th;
      txtr.Bottom := txtr.Top + tw;

      case APosition of
        ypLeft: AGraphics.DrawText(txtr, txt, False, gtaCenter, gtaCenter,  gttNone, -90);
        ypCenter, ypRight: AGraphics.DrawText(txtr, txt, False, gtaLeading, gtaCenter, gttNone, 90);
      end;

      c.DoAfterDrawYValuesTitle(AGraphics, r, rt, APosition, ASerie);
    end;
    AGraphics.RestoreState(st);
  end;
end;

procedure TTMSFNCChartYAxis.DrawValues(AGraphics: TTMSFNCGraphics;
  APosition: TTMSFNCChartYAxisPosition; ASerie: TTMSFNCChartSerie);
begin
  if not Assigned(ASerie) then
    Exit;

  DrawKind(AGraphics, APosition, vkMinor, ASerie);
  DrawKind(AGraphics, APosition, vkMajor, ASerie);
  DrawTitle(AGraphics, APosition, ASerie);
end;

function TTMSFNCChartYAxis.GetRectangle(
  APosition: TTMSFNCChartYAxisPosition): TRectF;
var
  c: TTMSFNCChart;
  r, rc: TRectF;
  pos: Double;
begin
  c := Chart;
  if not Assigned(c) then
    Exit;

  r := c.GetChartRectangle;
  case APosition of
    ypLeft: Result := RectF(R.Left, R.Top, R.Left + GetWidth(APosition), R.Bottom);
    ypCenter:
    begin
      rc := r;
      if (ypLeft in Positions) and Visible then
        rc.Left := rc.Left + GetWidth(ypLeft);

      if (ypRight in Positions) and Visible then
        rc.Right := rc.Right - GetWidth(ypRight);

      if DisplayAtReferenceValue and (ReferenceValueSeriesIndex >= 0) and (ReferenceValueSeriesIndex <= c.Series.Count - 1) then
        pos := c.Series[ReferenceValueSeriesIndex].ValueToX(ReferenceValue) - GetWidth(APosition) / 2
      else
        pos := rc.Left + ((rc.Right - rc.Left) - GetWidth(APosition)) / 2;

      Result := RectF(pos, rc.Top, pos + GetWidth(APosition), rc.Bottom);
    end;
    ypright: Result := RectF(R.Right - GetWidth(APosition), R.Top, R.Right, R.Bottom);
  end;

  if (tipTop in c.Title.Positions) and c.Title.Visible then
    Result.Top := Result.Top + c.Title.Height;

  if (xpTop in c.XAxis.Positions) and c.XAxis.Visible then
    Result.Top := Result.Top + c.XAxis.GetHeight(xpTop);

  if (tipBottom in c.Title.Positions) and c.Title.Visible then
    Result.Bottom := Result.Bottom - c.Title.Height;

  if (xpBottom in c.XAxis.Positions) and c.XAxis.Visible then
    Result.Bottom := Result.Bottom - c.XAxis.GetHeight(xpBottom);

  if APosition = ypLeft then
    Result.Top := Result.Top + c.FTotalOffset3DY;

  if APosition = ypRight then
    Result.Bottom := Result.Bottom - c.FTotalOffset3DY;
end;

function TTMSFNCChartYAxis.GetWidth(APosition: TTMSFNCChartYAxisPosition): Double;
var
  c: TTMSFNCChart;
begin
  Result := 0;
  c := Chart;
  if not Assigned(c) then
    Exit;

  if c.HasPieChart then
    Exit;

  if not Visible or not (APosition in Positions) then
    Exit;

  Result := FWidth;
  if AutoSize then
  begin
    case APosition of
      ypLeft: Result := FLeftWidth;
      ypCenter: Result := FCenterWidth;
      ypRight: Result := FRightWidth;
    end;
  end;
end;

function TTMSFNCChartYAxis.IsReferenceValueStored: Boolean;
begin
  Result := ReferenceValue <> 0;
end;

procedure TTMSFNCChartYAxis.SetAutoSize(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartYAxis.SetBorder(const Value: Boolean);
begin
  if FBorder <> Value then
  begin
    FBorder := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartYAxis.SetDisplayAtReferenceValue(const Value: Boolean);
begin
  if FDisplayAtReferenceValue <> Value then
  begin
    FDisplayAtReferenceValue := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartYAxis.SetLine(const Value: Boolean);
begin
  if FLine <> Value then
  begin
    FLine := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartYAxis.SetWidth(const Value: Double);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartYAxis.SetReferenceValue(const Value: Double);
begin
  if FReferenceValue <> Value then
  begin
    FReferenceValue := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartYAxis.SetReferenceValueSeriesIndex(const Value: Integer);
begin
  if FReferenceValueSeriesIndex <> Value then
  begin
    FReferenceValueSeriesIndex := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartYAxis.SetPositions(const Value: TTMSFNCChartYAxisPositions);
begin
  if FPositions <> Value then
  begin
    FPositions := Value;
    UpdateChart;
  end;
end;

{ TTMSFNCChartSerieMultiPoint }

procedure TTMSFNCChartSerieMultiPoint.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartSerieMultiPoint then
  begin
    FWidth := (Source as TTMSFNCChartSerieMultiPoint).Width;
    FWidthType := (Source as TTMSFNCChartSerieMultiPoint).WidthType;
    FIncreaseStrokeColor := (Source as TTMSFNCChartSerieMultiPoint).IncreaseStrokeColor;
    FDecreaseStrokeColor := (Source as TTMSFNCChartSerieMultiPoint).DecreaseStrokeColor;
    FIncreaseFillColor := (Source as TTMSFNCChartSerieMultiPoint).IncreaseFillColor;
    FDecreaseFillColor := (Source as TTMSFNCChartSerieMultiPoint).DecreaseFillColor;
  end;
end;

function TTMSFNCChartSerieMultiPoint.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

constructor TTMSFNCChartSerieMultiPoint.Create(ASerie: TTMSFNCChartSerie);
begin
  FSerie := ASerie;
  {$IFDEF FMXLIB}
  FIncreaseStrokeColor := $FF089F95;
  FDecreaseStrokeColor := $FFE14A4D;
  FIncreaseFillColor := $FF03A69B;
  FDecreaseFillColor := $FFF45154;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  FIncreaseStrokeColor := $959F08;
  FDecreaseStrokeColor := $4D4AE1;
  FIncreaseFillColor := $9BA603;
  FDecreaseFillColor := $5451F4;
  {$ENDIF}
  FWidth := 65;
  FWidthType := bwtPercentage;
end;

destructor TTMSFNCChartSerieMultiPoint.Destroy;
begin

  inherited;
end;

procedure TTMSFNCChartSerieMultiPoint.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

function TTMSFNCChartSerieMultiPoint.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

procedure TTMSFNCChartSerieMultiPoint.SetDecreaseFillColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDecreaseFillColor <> Value then
  begin
    FDecreaseFillColor := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieMultiPoint.SetDecreaseStrokeColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDecreaseStrokeColor <> Value then
  begin
    FDecreaseStrokeColor := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieMultiPoint.SetIncreaseFillColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FIncreaseFillColor <> Value then
  begin
    FIncreaseFillColor := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieMultiPoint.SetIncreaseStrokeColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FIncreaseStrokeColor <> Value then
  begin
    FIncreaseStrokeColor := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieMultiPoint.SetWidth(const Value: Double);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieMultiPoint.SetWidthType(
  const Value: TTMSFNCChartSerieBarWidthType);
begin
  if FWidthType <> Value then
  begin
    FWidthType := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieMultiPoint.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

{ TTMSFNCChartSerieBar }

procedure TTMSFNCChartSerieBar.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartSerieBar then
  begin
    FWidth := (Source as TTMSFNCChartSerieBar).Width;
    FWidthType := (Source as TTMSFNCChartSerieBar).WidthType;
    FSpacing := (Source as TTMSFNCChartSerieBar).Spacing;
  end;
end;

function TTMSFNCChartSerieBar.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

constructor TTMSFNCChartSerieBar.Create(ASerie: TTMSFNCChartSerie);
begin
  FSerie := ASerie;
  FWidth := 65;
  FWidthType := bwtPercentage;
  FSpacing := 20;
end;

destructor TTMSFNCChartSerieBar.Destroy;
begin

  inherited;
end;

procedure TTMSFNCChartSerieBar.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

function TTMSFNCChartSerieBar.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

procedure TTMSFNCChartSerieBar.SetSpacing(const Value: Double);
begin
  if FSpacing <> Value then
  begin
    FSpacing := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieBar.SetWidth(const Value: Double);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieBar.SetWidthType(
  const Value: TTMSFNCChartSerieBarWidthType);
begin
  if FWidthType <> Value then
  begin
    FWidthType := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieBar.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;


{ TTMSFNCChartSeriePie }

procedure TTMSFNCChartSeriePie.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartSeriePie then
  begin
    FPosition := (Source as TTMSFNCChartSeriePie).Position;
    FSize := (Source as TTMSFNCChartSeriePie).Size;
    FAutoSize := (Source as TTMSFNCChartSeriePie).AutoSize;
    FMargins.Assign((Source as TTMSFNCChartSeriePie).Margins);
    FStartAngle := (Source as TTMSFNCChartSeriePie).StartAngle;
    FSweepAngle := (Source as TTMSFNCChartSeriePie).SweepAngle;
    FInnerSize := (Source as TTMSFNCChartSeriePie).InnerSize;
    FStacked := (Source as TTMSFNCChartSeriePie).Stacked;
    FIncludeZeroValues := (Source as TTMSFNCChartSeriePie).IncludeZeroValues;
  end;
end;

function TTMSFNCChartSeriePie.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

constructor TTMSFNCChartSeriePie.Create(ASerie: TTMSFNCChartSerie);
begin
  FSerie := ASerie;
  FPosition := ppCenterCenter;
  FSize := 200;
  FAutoSize := True;
  FStacked := False;
  FStartAngle := 0;
  FSweepAngle := 360;
  FIncludeZeroValues := False;
  FMargins := TTMSFNCChartMargins.Create;
  FMargins.Left := 10;
  FMargins.Top := 10;
  FMargins.Right := 10;
  FMargins.Bottom := 10;
  FMargins.OnChange := @MarginsChanged;
end;

destructor TTMSFNCChartSeriePie.Destroy;
begin
  FMargins.Free;
  inherited;
end;

procedure TTMSFNCChartSeriePie.MarginsChanged(Sender: TObject);
begin
  UpdateChart;
end;

procedure TTMSFNCChartSeriePie.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

function TTMSFNCChartSeriePie.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

procedure TTMSFNCChartSeriePie.SetAutoSize(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSeriePie.SetInnerSize(const Value: Double);
begin
  if FInnerSize <> Value then
  begin
    FInnerSize := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSeriePie.SetMargins(const Value: TTMSFNCChartMargins);
begin
  if FMargins <> Value then
  begin
    FMargins.Assign(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSeriePie.SetPosition(
  const Value: TTMSFNCChartSeriePiePosition);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSeriePie.SetSize(const Value: Double);
begin
  if FSize <> Value then
  begin
    FSize := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSeriePie.SetStacked(const Value: Boolean);
begin
  if FStacked <> value then
  begin
    FStacked := Value;
    Updatechart;
  end;
end;

procedure TTMSFNCChartSeriePie.SetStartAngle(const Value: Double);
begin
  if FStartAngle <> Value then
  begin
    FStartAngle := Value;
    UpdateChart; 
  end;
end;

procedure TTMSFNCChartSeriePie.SetSweepAngle(const Value: Double);
begin
  if FSweepAngle <> Value then
  begin
    FSweepAngle := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSeriePie.SetIncludeZeroValues(const Value: Boolean);
begin
  if FIncludeZeroValues <> Value then
  begin
    FIncludeZeroValues := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSeriePie.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

constructor TTMSFNCChartSerieCrosshair.Create(ASerie: TTMSFNCChartSerie);
begin
  FSerie := ASerie;
  FYPositions := [ypLeft, ypCenter, ypRight];
  FXPositions := [xpTop, xpCenter, xpBottom];
  FHorizontalLineStroke := TTMSFNCGraphicsStroke.Create;
  FHorizontalLineStroke.OnChanged := @StrokeChanged;
  FVerticalLineStroke := TTMSFNCGraphicsStroke.Create;
  FVerticalLineStroke.OnChanged := @StrokeChanged;
  FXTextStroke := TTMSFNCGraphicsStroke.Create;
  FXTextStroke.OnChanged := @StrokeChanged;
  FXTextFill := TTMSFNCGraphicsFill.Create;
  FXTextFill.OnChanged := @FillChanged;
  FXTextFont := TTMSFNCGraphicsFont.Create;
  FXTextFont.Color := gcWhite;
  FXTextFont.OnChanged := @FontChanged;
  FYTextStroke := TTMSFNCGraphicsStroke.Create;
  FYTextStroke.OnChanged := @StrokeChanged;
  FYTextFill := TTMSFNCGraphicsFill.Create;
  FYTextFill.OnChanged := @FillChanged;
  FYTextFont := TTMSFNCGraphicsFont.Create;
  FYTextFont.Color := gcWhite;
  FYTextFont.OnChanged := @FontChanged;
end;

destructor TTMSFNCChartSerieCrosshair.Destroy;
begin
  FVerticalLineStroke.Free;
  FHorizontalLineStroke.Free;
  FXTextStroke.Free;
  FXTextFill.Free;
  FXTextFont.Free;
  FYTextStroke.Free;
  FYTextFill.Free;
  FYTextFont.Free;
  inherited;
end;

procedure TTMSFNCChartSerieCrosshair.StrokeChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartSerieCrosshair.FontChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartSerieCrosshair.FillChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartSerieCrosshair.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

procedure TTMSFNCChartSerieCrosshair.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartSerieCrosshair then
  begin
    FXPositions := (Source as TTMSFNCChartSerieCrosshair).XPositions;
    FYPositions := (Source as TTMSFNCChartSerieCrosshair).YPositions;
    FHorizontalLineStroke.Assign((Source as TTMSFNCChartSerieCrosshair).HorizontalLineStroke);
    FVerticalLineStroke.Assign((Source as TTMSFNCChartSerieCrosshair).VerticalLineStroke);
  end;
end;

function TTMSFNCChartSerieCrosshair.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

function TTMSFNCChartSerieCrosshair.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

procedure TTMSFNCChartSerieCrosshair.SetYTextFont(const Value: TTMSFNCGraphicsFont);
begin
  if FYTextFont <> Value then
    FYTextFont.Assign(Value);
end;

procedure TTMSFNCChartSerieCrosshair.SetYTextFill(const Value: TTMSFNCGraphicsFill);
begin
  if FYTextFill <> Value then
    FYTextFill.Assign(Value);
end;

procedure TTMSFNCChartSerieCrosshair.SetYTextStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FYTextStroke <> Value then
    FYTextStroke.Assign(Value);
end;

procedure TTMSFNCChartSerieCrosshair.SetXTextFont(const Value: TTMSFNCGraphicsFont);
begin
  if FXTextFont <> Value then
    FXTextFont.Assign(Value);
end;

procedure TTMSFNCChartSerieCrosshair.SetXTextFill(const Value: TTMSFNCGraphicsFill);
begin
  if FXTextFill <> Value then
    FXTextFill.Assign(Value);
end;

procedure TTMSFNCChartSerieCrosshair.SetXTextStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FXTextStroke <> Value then
    FXTextStroke.Assign(Value);
end;

procedure TTMSFNCChartSerieCrosshair.SetYPositions(
  const Value: TTMSFNCChartYAxisPositions);
begin
  if FYPositions <> Value then
  begin
    FYPositions := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieCrosshair.SetXPositions(
  const Value: TTMSFNCChartXAxisPositions);
begin
  if FXPositions <> Value then
  begin
    FXPositions := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieCrosShair.SetHorizontalLineStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FHorizontalLineStroke <> Value then
    FHorizontalLineStroke.Assign(Value);
end;

procedure TTMSFNCChartSerieCrosShair.SetVerticalLineStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FVerticalLineStroke <> Value then
    FVerticalLineStroke.Assign(Value);
end;

procedure TTMSFNCChartSerieCrosshair.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

{ TTMSFNCChartLegend }

procedure TTMSFNCChartLegend.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCChartLegend then
  begin
    FTop := (Source as TTMSFNCChartLegend).Top;
    FLeft := (Source as TTMSFNCChartLegend).Left;
    FPosition := (Source as TTMSFNCChartLegend).Position;
    FFont.AssignSource((Source as TTMSFNCChartLegend).Font);
  end;
end;

procedure TTMSFNCChartLegend.AssignSource(Source: TPersistent);
begin
  Assign(Source);
end;

constructor TTMSFNCChartLegend.Create(AChart: TTMSFNCChart);
begin
  inherited;
  FTop := 10;
  FLeft := 10;
  FPosition := lpTopLeft;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FStroke.Kind := gskSolid;
  FFill.Kind := gfkSolid;
  FStroke.OnChanged := @StrokeChanged;
  FFill.OnChanged := @FillChanged;
end;

destructor TTMSFNCChartLegend.Destroy;
begin
  FFont.Free;
  inherited;
end;

procedure TTMSFNCChartLegend.Draw(AGraphics: TTMSFNCGraphics);
var
  c: TTMSFNCChart;
  r, rm, rt: TRectF;
  I: Integer;
  s: TTMSFNCChartSerie;
  th, x, y: Double;
  dr: Boolean;
  st: TTMSFNCGraphicsSaveState;
  li: Boolean;
begin
  inherited;
  if not Visible then
    Exit;

  c := Chart;
  if not Assigned(c) then
    Exit;

  if c.Series.Count = 0 then
    Exit;

  r := GetRectangle;
  if RectIsEmpty(r) then
    Exit;

  dr := True;
  st := AGraphics.SaveState;
  c.DoBeforeDrawLegend(AGraphics, r, dr);
  if dr then
  begin
    AGraphics.DrawRectangle(r);

    x := r.Left + 5;
    y := r.Top + 5;

    for I := 0 to c.Series.Count - 1 do
    begin
      s := c.Series[I];
      if s.Visible and s.ShowInLegend then
      begin
        AGraphics.Font.AssignSource(Font);
        th := AGraphics.CalculateTextHeight(s.LegendText);
        rt := RectF(x + th + 5, y, x + (r.Right - r.Left) - 10, y + th);
        rm := RectF(x, y, x + th, y + th);
        y := y + th + 5;

        AGraphics.DrawText(rt, s.LegendText, False, gtaLeading, gtaLeading);

        AGraphics.Fill.Assign(s.Fill);
        AGraphics.Stroke.Assign(s.Stroke);

        InflateRectEx(rm, -(rm.Bottom - rm.Top) / 7.5, -(rm.Bottom - rm.Top) / 7.5);
        li := True;
        c.DoBeforeDrawLegendIcon(AGraphics, s, rm, li);
        if li then
        begin
          DrawChartIcon(AGraphics, s.ChartType, rm);
          c.DoAfterDrawLegendIcon(AGraphics, s, rm);
        end;        
      end;
    end;
    c.DoAfterDrawLegend(AGraphics, r);
  end;
  AGraphics.RestoreState(st);
end;

procedure TTMSFNCChartLegend.FontChanged(Sender: TObject);
begin
  InvalidateChart;
end;

function TTMSFNCChartLegend.GetRectangle: TRectF;
var
  c: TTMSFNCChart;
  I: Integer;
  s: TTMSFNCChartSerie;
  g: TTMSFNCGraphics;
  tw, th: Double;
  mxw, mxh, mxhr: Double;
  x, y: Double;
  r: TRectF;
begin
  Result := RectF(0, 0, 0, 0);
  c := Chart;
  if not Assigned(c) then
    Exit;
  if c.Series.Count = 0 then
    Exit;

  mxw := 0;
  mxh := 0;
  mxhr := 0;

  g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, c.NativeCanvas);
  g.BeginScene;
  try
    g.Font.AssignSource(Font);
    for I := 0 to c.Series.Count - 1 do
    begin
      s := c.Series[I];
      if s.Visible and s.ShowInLegend then
      begin
        if s.LegendText <> '' then
        begin
          tw := g.CalculateTextWidth(s.LegendText) + 5;
          if tw > mxw then
            mxw := tw;
          th := g.CalculateTextHeight(s.LegendText) + 5;
          if th > mxh then
            mxhr := th;

          mxh := mxh + th;
        end;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
  end;

  if (mxw = 0) and (mxh = 0) then
  begin
    Result := RectF(0, 0, 0, 0);
    Exit;
  end;

  mxw := mxw + mxhr + 5;
  mxh := mxh + 5;

  r := c.GetSeriesRectangle;
  r.Left := r.Left - c.SeriesMargins.Left + c.FTotalOffset3DX;
  r.Top := r.Top - c.SeriesMargins.Top - c.FTotalOffset3DY;
  r.Bottom := r.Bottom + c.SeriesMargins.Bottom - c.FTotalOffset3DY / 2;
  r.Right := r.Right + c.SeriesMargins.Right + c.FTotalOffset3DX / 2;

  x := r.Left;
  y := r.Top;

  case Position of
    lpTopCenter: x := x + ((r.Right - r.Left) - mxw) / 2;
    lpTopRight: x := x + (r.Right - r.Left) - mxw;
    lpCenterLeft: y := y + ((r.Bottom - r.Top) - mxh) / 2;
    lpCenterCenter:
    begin
      y := y + ((r.Bottom - r.Top) - mxh) / 2;
      x := x + ((r.Right - r.Left) - mxw) / 2;
    end;
    lpCenterRight:
    begin
      x := x + (r.Right - r.Left) - mxw;
      y := y + ((r.Bottom - r.Top) - mxh) / 2;
    end;
    lpBottomLeft:
    begin
      y := y + (r.Bottom - r.Top) - mxh;
    end;
    lpBottomCenter:
    begin
      x := x + ((r.Right - r.Left) - mxw) / 2;
      y := y + (r.Bottom - r.Top) - mxh;
    end;
    lpBottomRight:
    begin
      x := x + (r.Right - r.Left) - mxw;
      y := y + (r.Bottom - r.Top) - mxh;
    end;
  end;

  x := x + Left;
  y := y + Top;

  Result := RectF(x, y, x + mxw, y + mxh);
end;

procedure TTMSFNCChartLegend.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  if FFont <> Value then
  begin
    FFont.AssignSource(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartLegend.SetLeft(const Value: Double);
begin
  if FLeft <> Value then
  begin
    FLeft := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartLegend.SetPosition(
  const Value: TTMSFNCChartLegendPosition);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartLegend.SetTop(const Value: Double);
begin
  if FTop <> Value then
  begin
    FTop := Value;
    InvalidateChart;
  end;
end;

function TTMSFNCChartLegend.XYToItem(X, Y: Double): Integer;
var
  c: TTMSFNCChart;
  r, rt: TRectF;
  I: Integer;
  s: TTMSFNCChartSerie;
  th, xx, xy: Double;
  dr: Boolean;
  g: TTMSFNCGraphics;
begin
  inherited;
  Result := -1;
  if not Visible then
    Exit;

  c := Chart;
  if not Assigned(c) then
    Exit;

  if c.Series.Count = 0 then
    Exit;

  r := GetRectangle;
  if RectIsEmpty(r) then
    Exit;

  g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, c.NativeCanvas);
  g.BeginScene;
  try
    dr := True;
    c.DoBeforeDrawLegend(g, r, dr);
    if dr then
    begin
      xx := r.Left;
      xy := r.Top + 5;

      for I := 0 to c.Series.Count - 1 do
      begin
        s := c.Series[I];
        if s.Visible and s.ShowInLegend then
        begin
          g.Font.AssignSource(Font);
          th := g.CalculateTextHeight(s.LegendText);
          rt := RectF(xx, xy, xx + (r.Right - r.Left), xy + th);
          if PtInRectEx(rt, PointF(X, Y)) then
          begin
            Result := I;
            Break;
          end;
          xy := xy + th + 5;
        end;
      end;
    end;
  finally
    g.EndScene;
    g.Free;
  end;
end;

{ TTMSFNCChartSerieMarker }

procedure TTMSFNCChartSerieMarkers.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCChartSerieMarkers) then
  begin
    FWidth := (Source as TTMSFNCChartSerieMarkers).Width;
    FHeight := (Source as TTMSFNCChartSerieMarkers).Height;
    FShape := (Source as TTMSFNCChartSerieMarkers).Shape;
    FFill.Assign((Source as TTMSFNCChartSerieMarkers).Fill);
    FStroke.Assign((Source as TTMSFNCChartSerieMarkers).Stroke);
    FBitmap.Assign((Source as TTMSFNCChartSerieMarkers).Bitmap);
    FVisible := (Source as TTMSFNCChartSerieMarkers).Visible;
  end;
end;

function TTMSFNCChartSerieMarkers.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

constructor TTMSFNCChartSerieMarkers.Create(ASerie: TTMSFNCChartSerie);
begin
  FSerie := ASerie;
  FBitmap := TTMSFNCBitmap.Create;
  FWidth := 10;
  FHeight := 10;
  FShape := msEllipse;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcYellowGreen);
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGreen);
  FStroke.OnChanged := @StrokeChanged;
  FVisible := False;
end;

destructor TTMSFNCChartSerieMarkers.Destroy;
begin
  FBitmap.Free;
  FStroke.Free;
  FFill.Free;
  inherited;
end;

procedure TTMSFNCChartSerieMarkers.FillChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartSerieMarkers.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

function TTMSFNCChartSerieMarkers.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

procedure TTMSFNCChartSerieMarkers.SetBitmap(const Value: TTMSFNCBitmap);
begin
  if FBitmap <> Value then
  begin
    FBitmap.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieMarkers.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
  begin
    FFill.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieMarkers.SetHeight(const Value: Double);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieMarkers.SetShape(
  const Value: TTMSFNCChartSerieMarkerShape);
begin
  if FShape <> Value then
  begin
    FShape := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieMarkers.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
  begin
    FStroke.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieMarkers.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieMarkers.SetWidth(const Value: Double);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieMarkers.StrokeChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartSerieMarkers.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

{ TTMSFNCChartSerieXValues }

procedure TTMSFNCChartSerieXValues.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCChartSerieXValues then
  begin
    FPositions := (Source as TTMSFNCChartSerieXValues).Positions;
    FAngle := (Source as TTMSFNCChartSerieXValues).Angle;
  end;
end;

procedure TTMSFNCChartSerieXValues.AssignSource(Source: TPersistent);
begin
  Assign(Source);
end;

constructor TTMSFNCChartSerieXValues.Create(ASerie: TTMSFNCChartSerie);
var
  c: TTMSFNCChart;
begin
  inherited;
  FPositions := [xpBottom];
  FAutoUnits := False;
  {$IFNDEF WEBLIB}
  FMajorUnitFormat := '%.0f';
  FMinorUnitFormat := '%.0f';
  {$ENDIF}
  FAngle := 0;
  c := Chart;
  if Assigned(c) then
  begin
    if (csDesigning in c.ComponentState) and not ((csReading in c.ComponentState) or (csLoading in c.ComponentState)) then
      FTitle.FText := 'X-Axis';
  end;
end;

destructor TTMSFNCChartSerieXValues.Destroy;
begin

  inherited;
end;

procedure TTMSFNCChartSerieXValues.SetAngle(const Value: Double);
begin
  if FAngle <> Value then
  begin
    FAngle := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXValues.SetPositions(
  const Value: TTMSFNCChartXAxisPositions);
begin
  if FPositions <> Value then
  begin
    FPositions := Value;
    UpdateChart;
  end;
end;

{ TTMSFNCChartSerieYValues }

procedure TTMSFNCChartSerieYValues.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCChartSerieYValues then
  begin
    FPositions := (Source as TTMSFNCChartSerieYValues).Positions;
  end;
end;

procedure TTMSFNCChartSerieYValues.AssignSource(Source: TPersistent);
begin
  Assign(Source);
end;

constructor TTMSFNCChartSerieYValues.Create(ASerie: TTMSFNCChartSerie);
var
  c: TTMSFNCChart;
begin
  inherited;
  FPositions := [ypLeft];
  c := Chart;
  if Assigned(c) then
  begin
    if (csDesigning in c.ComponentState) and not ((csReading in c.ComponentState) or (csLoading in c.ComponentState)) then
      FTitle.FText := 'Y-Axis';
  end;
end;

destructor TTMSFNCChartSerieYValues.Destroy;
begin

  inherited;
end;

procedure TTMSFNCChartSerieYValues.SetPositions(
  const Value: TTMSFNCChartYAxisPositions);
begin
  if FPositions <> Value then
  begin
    FPositions := Value;
    UpdateChart;
  end;
end;

{ TTMSFNCChartSerieXYValues }

procedure TTMSFNCChartSerieXYValues.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartSerieXYValues then
  begin
    FMinorUnitTickMarkSize := (Source as TTMSFNCChartSerieXYValues).MinorUnitTickMarkSize;
    FMinorUnitTickMarkColor := (Source as TTMSFNCChartSerieXYValues).MinorUnitTickMarkColor;
    FMinorUnitFont.AssignSource((Source as TTMSFNCChartSerieXYValues).MinorUnitFont);
    FMinorUnit := (Source as TTMSFNCChartSerieXYValues).MinorUnit;
    FMinorUnitFormat := (Source as TTMSFNCChartSerieXYValues).MinorUnitFormat;
    FMinorUnitSpacing := (Source as TTMSFNCChartSerieXYValues).MinorUnitSpacing;
    FMinorUnitFormatType := (Source as TTMSFNCChartSerieXYValues).MinorUnitFormatType;
    FMajorUnitTickMarkSize := (Source as TTMSFNCChartSerieXYValues).MajorUnitTickMarkSize;
    FMajorUnitTickMarkColor := (Source as TTMSFNCChartSerieXYValues).MajorUnitTickMarkColor;
    FMajorUnitFont.AssignSource((Source as TTMSFNCChartSerieXYValues).MajorUnitFont);
    FMajorUnit := (Source as TTMSFNCChartSerieXYValues).MajorUnit;
    FMajorUnitFormat := (Source as TTMSFNCChartSerieXYValues).MajorUnitFormat;
    FMajorUnitSpacing := (Source as TTMSFNCChartSerieXYValues).MajorUnitSpacing;
    FMajorUnitFormatType := (Source as TTMSFNCChartSerieXYValues).MajorUnitFormatType;
    FAutoUnits := (Source as TTMSFNCChartSerieXYValues).AutoUnits;
    FTitle.Assign((Source as TTMSFNCChartSerieXYValues).Title);
    FSpiderValues := (Source as TTMSFNCChartSerieXYValues).SpiderValues;
  end;
end;

function TTMSFNCChartSerieXYValues.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

constructor TTMSFNCChartSerieXYValues.Create(ASerie: TTMSFNCChartSerie);
begin
  FSerie := ASerie;
  FAutoUnits := True;
  FSpiderValues := True;
  FSpiderAngle := 270;
  FMinorUnitTickMarkSize := 7;
  FMinorUnitFont := TTMSFNCGraphicsFont.Create;
  FMinorUnitFont.OnChanged := @FontChanged;
  FMinorUnit := 0;
  {$IFNDEF WEBLIB}
  FMinorUnitFormat := '%.2f';
  {$ENDIF}
  FMinorUnitSpacing := 5;
  FMinorUnitFormatType := vftNormal;
  FMajorUnitTickMarkSize := 10;
  FMajorUnitFont := TTMSFNCGraphicsFont.Create;
  FMajorUnitFont.OnChanged := @FontChanged;
  FMajorUnit := 1;
  {$IFNDEF WEBLIB}
  FMajorUnitFormat := '%.2f';
  {$ENDIF}
  FMajorUnitSpacing := 5;
  FMajorUnitFormatType := vftNormal;
  FMajorUnitTickMarkColor := gcSilver;
  FMinorUnitTickMarkColor := gcSilver;
  FTitle := TTMSFNCChartSerieXYTitle.Create(FSerie);
end;

destructor TTMSFNCChartSerieXYValues.Destroy;
begin
  FTitle.Free;
  FMajorUnitFont.Free;
  FMinorUnitFont.Free;
  inherited;
end;

procedure TTMSFNCChartSerieXYValues.FontChanged(Sender: TObject);
begin
  UpdateChart;
end;

procedure TTMSFNCChartSerieXYValues.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

function TTMSFNCChartSerieXYValues.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

procedure TTMSFNCChartSerieXYValues.SetAutoUnits(const Value: Boolean);
begin
  if FAutoUnits <> Value then
  begin
    FAutoUnits := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMajorUnit(const Value: Double);
begin
  if FMajorUnit <> Value then
  begin
    FMajorUnit := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMajorUnitFont(const Value: TTMSFNCGraphicsFont);
begin
  if FMajorUnitFont <> Value then
  begin
    FMajorUnitFont.AssignSource(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMajorUnitFormat(const Value: String);
begin
  if FMajorUnitFormat <> Value then
  begin
    FMajorUnitFormat := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMajorUnitFormatType(
  const Value: TTMSFNCChartSerieValueFormatType);
begin
  if FMajorUnitFormatType <> Value then
  begin
    FMajorUnitFormatType := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMajorUnitSpacing(const Value: Double);
begin
  if FMajorUnitSpacing <> Value then
  begin
    FMajorUnitSpacing := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMajorUnitTickMarkColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FMajorUnitTickMarkColor <> Value then
  begin
    FMajorUnitTickMarkColor := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMajorUnitTickMarkSize(
  const Value: Double);
begin
  if FMajorUnitTickMarkSize <> Value then
  begin
    FMajorUnitTickMarkSize := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMinorUnit(const Value: Double);
begin
  if FMinorUnit <> Value then
  begin
    FMinorUnit := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMinorUnitFont(const Value: TTMSFNCGraphicsFont);
begin
  if FMinorUnitFont <> Value then
  begin
    FMinorUnitFont.AssignSource(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMinorUnitFormat(const Value: String);
begin
  if FMinorUnitFormat <> Value then
  begin
    FMinorUnitFormat := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMinorUnitFormatType(
  const Value: TTMSFNCChartSerieValueFormatType);
begin
  if FMinorUnitFormatType <> Value then
  begin
    FMinorUnitFormatType := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMinorUnitSpacing(const Value: Double);
begin
  if FMinorUnitSpacing <> Value then
  begin
    FMinorUnitSpacing := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMinorUnitTickMarkColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FMinorUnitTickMarkColor <> Value then
  begin
    FMinorUnitTickMarkColor := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetMinorUnitTickMarkSize(
  const Value: Double);
begin
  if FMinorUnitTickMarkSize <> Value then
  begin
    FMinorUnitTickMarkSize := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetSpiderValues(const Value: Boolean);
begin
  if FSpiderValues <> Value then
  begin
    FSpiderValues := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.SetTitle(
  const Value: TTMSFNCChartSerieXYTitle);
begin
  if FTitle <> Value then
  begin
    FTitle := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYValues.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

{ TTMSFNCChartSerieXYGrid }

procedure TTMSFNCChartSerieXYGrid.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartSerieXYGrid then
  begin
    FMajorUnitStroke.Assign((Source as TTMSFNCChartSerieXYGrid).MajorUnitStroke);
    FMinorUnitStroke.Assign((Source as TTMSFNCChartSerieXYGrid).MinorUnitStroke);
    FVisible := (Source as TTMSFNCChartSerieXYGrid).Visible;
    FExtended := (Source as TTMSFNCChartSerieXYGrid).Extended;
    FSpiderKind := (Source as TTMSFNCChartSerieXYGrid).SpiderKind;
    FSpiderVisible := (Source as TTMSFNCChartSerieXYGrid).SpiderVisible;
    FSpiderLegend := (Source as TTMSFNCChartSerieXYGrid).SpiderLegend;
  end;
end;

procedure TTMSFNCChartSerieXYGrid.AssignSource(Source: TPersistent);
begin
  Assign(Source);
end;

function TTMSFNCChartSerieXYGrid.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

constructor TTMSFNCChartSerieXYGrid.Create(ASerie: TTMSFNCChartSerie);
begin
  FSerie := ASerie;
  FSpiderVisible := True;
  FSpiderKind := sgkCircles;
  FSpiderLegend := False;
  FMajorUnitStroke := TTMSFNCGraphicsStroke.Create(gskSolid, Lighter(gcLightGray, 20));
  FMajorUnitStroke.OnChanged := @StrokeChanged;

  FMinorUnitStroke := TTMSFNCGraphicsStroke.Create(gskSolid, Lighter(gcLightGray, 20));
  FMinorUnitStroke.OnChanged := @StrokeChanged;
  FVisible := False;
  FExtended := True;
end;

destructor TTMSFNCChartSerieXYGrid.Destroy;
begin
  FMinorUnitStroke.Free;
  FMajorUnitStroke.Free;
  inherited;
end;

procedure TTMSFNCChartSerieXYGrid.Draw(AGraphics: TTMSFNCGraphics; AXGrid: Boolean = True);
begin
  if not Visible then
    Exit;

  DrawKind(AGraphics, vkMinor, AXGrid);
  DrawKind(AGraphics, vkMajor, AXGrid);
end;

procedure TTMSFNCChartSerieXYGrid.DrawKind(AGraphics: TTMSFNCGraphics;
  AKind: TTMSFNCChartDrawXYValueKind; AXGrid: Boolean = True);
var
  i: integer;
  dgl: TTMSFNCChartDrawXYGridLine;
  dgls: TTMSFNCChartDrawXYGridLines;
  s: TTMSFNCChartSerie;
  c: TTMSFNCChart;
  dr: Boolean;
  st: TTMSFNCGraphicsSaveState;
begin
  s := Serie;
  if not Assigned(s) then
    Exit;

  c := Chart;
  if not Assigned(c) then
    Exit;

  if AXGrid then
    dgls := s.FDrawXGridLines
  else
    dgls := s.FDrawYGridLines;

  if AKind = vkMajor then
    AGraphics.Stroke.Assign(MajorUnitStroke)
  else
    AGraphics.Stroke.Assign(MinorUnitStroke);

  for I := 0 to dgls.Count - 1 do
  begin
    dgl := dgls[I];
    if dgl.Kind = AKind then
    begin
      if AXGrid then
      begin
        dr := True;
        st := AGraphics.SaveState;
        c.DoBeforeDrawSerieXGridLine(AGraphics, s, dgl, dr);
        if dr then
        begin
          AGraphics.DrawLine(PointF(dgl.XStartPoint.X, dgl.XStartPoint.Y), PointF(dgl.XEndPoint.X, dgl.XEndPoint.Y));
          if (c.FTotalOffset3DY > 0) then
            AGraphics.DrawLine(PointF(dgl.XEndPoint.X, dgl.XEndPoint.Y), PointF(dgl.XEndPoint.X - c.FTotalOffset3DX, dgl.XEndPoint.Y + c.FTotalOffset3DY));

          c.DoAfterDrawSerieXGridLine(AGraphics, s, dgl);
        end;
        AGraphics.RestoreState(st);
      end
      else
      begin
        dr := True;
        st := AGraphics.SaveState;
        c.DoBeforeDrawSerieYGridLine(AGraphics, s, dgl, dr);
        if dr then
        begin
          AGraphics.DrawLine(PointF(dgl.YStartPoint.X, dgl.YStartPoint.Y), PointF(dgl.YEndPoint.X, dgl.YEndPoint.Y));
          if (c.FTotalOffset3DX > 0) then
            AGraphics.DrawLine(PointF(dgl.YStartPoint.X, dgl.YStartPoint.Y), PointF(dgl.YStartPoint.X - c.FTotalOffset3DX, dgl.YStartPoint.Y + c.FTotalOffset3DY));

          c.DoAfterDrawSerieYGridLine(AGraphics, s, dgl);
        end;
        AGraphics.RestoreState(st);
      end;
    end;
  end;
end;

procedure TTMSFNCChartSerieXYGrid.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

function TTMSFNCChartSerieXYGrid.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

procedure TTMSFNCChartSerieXYGrid.SetExtended(const Value: Boolean);
begin
  if FExtended <> Value then
  begin
    FExtended := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYGrid.SetMajorUnitStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FMajorUnitStroke <> Value then
  begin
    FMajorUnitStroke.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYGrid.SetMinorUnitStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FMinorUnitStroke <> Value then
  begin
    FMinorUnitStroke.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYGrid.SetSpiderKind(
  const Value: TTMSFNCChartSerieSpiderGridKind);
begin
  if FSpiderKind <> Value then
  begin
    FSpiderKind := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYGrid.SetSpiderLegend(const Value: Boolean);
begin
  if FSpiderLegend <> Value then
  begin
    FSpiderLegend := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYGrid.SetSpiderVisible(const Value: Boolean);
begin
  if FSpiderVisible <> Value then
  begin
    FSpiderVisible := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYGrid.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYGrid.StrokeChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartSerieXYGrid.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

{ TTMSFNCChartSerieXYTitle }

procedure TTMSFNCChartSerieXYTitle.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartSerieXYTitle then
  begin
    FFont.AssignSource((Source as TTMSFNCChartSerieXYTitle).Font);
    FTextMargins.Assign((Source as TTMSFNCChartSerieXYTitle).TextMargins);
    FText := (Source as TTMSFNCChartSerieXYTitle).Text;
    FTextHorizontalAlignment := (Source as TTMSFNCChartSerieXYTitle).TextHorizontalAlignment;
    FTextVerticalAlignment := (Source as TTMSFNCChartSerieXYTitle).TextVerticalAlignment;
  end;
end;

function TTMSFNCChartSerieXYTitle.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

constructor TTMSFNCChartSerieXYTitle.Create(ASerie: TTMSFNCChartSerie);
begin
  FSerie := ASerie;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FTextMargins := TTMSFNCChartMargins.Create;
  FTextMargins.OnChange := @TextMarginsChanged;
  FTextHorizontalAlignment := gtaCenter;
  FTextVerticalAlignment := gtaCenter;
end;

destructor TTMSFNCChartSerieXYTitle.Destroy;
begin
  FTextMargins.Free;
  FFont.Free;
  inherited;
end;

procedure TTMSFNCChartSerieXYTitle.FontChanged(Sender: TObject);
begin
  UpdateChart;
end;

procedure TTMSFNCChartSerieXYTitle.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

function TTMSFNCChartSerieXYTitle.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

procedure TTMSFNCChartSerieXYTitle.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  if FFont <> Value then
  begin
    FFont.AssignSource(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYTitle.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieXYTitle.SetTextHorizontalAlignment(const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextHorizontalAlignment <> Value then
  begin
    FTextHorizontalAlignment := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYTitle.SetTextVerticalAlignment(const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextVerticalAlignment <> Value then
  begin
    FTextVerticalAlignment := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYTitle.SetTextMargins(const Value: TTMSFNCChartMargins);
begin
  if FTextMargins <> Value then
  begin
    FTextMargins.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieXYTitle.TextMarginsChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartSerieXYTitle.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

{ TTMSFNCChartCrosshair }

procedure TTMSFNCChartCrosshair.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartCrosshair then
  begin
    FModes := (Source as TTMSFNCChartCrosshair).Modes;
    FVisible := (Source as TTMSFNCChartCrosshair).Visible;
    FContinuousSeriesIndex := (Source as TTMSFNCChartCrosshair).ContinuousSeriesIndex;
  end;
end;

procedure TTMSFNCChartCrosshair.AssignSource(Source: TPersistent);
begin
  Assign(Source);
end;

constructor TTMSFNCChartCrosshair.Create(AChart: TTMSFNCChart);
begin
  FChart := AChart;
  FModes := [ccmContinuous];
  FVisible := False;
  FContinuousSeriesIndex := 0;
end;

destructor TTMSFNCChartCrosshair.Destroy;
begin
  inherited;
end;

function TTMSFNCChartCrosshair.Chart: TTMSFNCChart;
begin
  Result := FChart;
end;

procedure TTMSFNCChartCrosshair.Draw(AGraphics: TTMSFNCGraphics);
var
  c: TTMSFNCChart;
  r: TRectF;
  nx, ny, x, y: Double;
  st: TTMSFNCGraphicsSaveState;
  xv, yv: Extended;
  I: Integer;
  s, sbase: TTMSFNCChartSerie;
  spc: Single;
  pt: TTMSFNCChartDrawPoint;
  m: TTMSFNCChartCrosshairMode;

  procedure DrawXValues;
  var
    posx: TTMSFNCChartXAxisPosition;
    xsv: string;
    xa, xvr, xrc: TRectF;
    lw: Double;
    sw: Integer;
    bf: Boolean;
  begin
    if s.Visible then
    begin
      AGraphics.Stroke.Assign(s.Crosshair.HorizontalLineStroke);

      bf := True;
      c.DoBeforeDrawSerieCrosshairHorizontalLine(AGraphics, s, PointF(r.Left, y), PointF(x, y), PointF(r.Right, y), m, bf);
      if bf then
      begin
        AGraphics.DrawLine(PointF(r.Left, y), PointF(r.Right, y));
        c.DoAfterDrawSerieCrosshairHorizontalLine(AGraphics, s, PointF(r.Left, y), PointF(x, y), PointF(r.Right, y), m);
      end;
    end;

    for posx in c.XAxis.Positions do
    begin
      if (posx in s.Crosshair.XPositions) and s.Visible then
      begin
        xsv := s.FindXAxisTextForValue(xv);
        if xsv = '' then
        begin
          if s.XValues.MajorUnitFormat <> '' then
          begin
            case s.XValues.MajorUnitFormatType of
              vftNormal: xsv := Format(s.XValues.MajorUnitFormat,[xv]);
              vftFloat: xsv := FormatFloat(s.XValues.MajorUnitFormat,xv);
              vftDateTime: xsv := FormatDateTime(s.XValues.MajorUnitFormat, xv);
            end;
          end
          else
            xsv := FloatToStr(xv);
        end;

        c.DoGetSerieXAxisCrosshairText(s, xv, posx, m, xsv);

        AGraphics.Font.Assign(s.Crosshair.XTextFont);
        xrc := AGraphics.CalculateText(xsv);

        lw := 0;

        sw := s.Index;
        while sw > 0 do
        begin
          if c.Series[sw - 1].Visible and (posx in c.Series[sw - 1].XValues.Positions) and (posx in s.XValues.Positions) then
          begin
            case posx of
              xpTop: lw := c.Series[sw - 1].FXAxisTopHeight;
              xpCenter: lw := c.Series[sw - 1].FXAxisCenterHeight;
              xpBottom: lw := c.Series[sw - 1].FXAxisBottomHeight;
            end;
            Break;
          end;

          Dec(sw);
        end;

        xa := c.xAxis.GetRectangle(posx);
        case posx of
          xpTop:
          begin
            xvr := RectF(x - (xrc.Right - xrc.Left) / 2, xa.Bottom - lw - s.XValues.MajorUnitTickMarkSize - s.XValues.MajorUnitSpacing - (xrc.Bottom - xrc.Top), x + (xrc.Right - xrc.Left) / 2,
              Min(xa.Bottom, xa.Bottom - s.XValues.MajorUnitTickMarkSize - s.XValues.MajorUnitSpacing - lw));
          end;
          xpCenter:
          begin
            xvr := RectF(x - (xrc.Right - xrc.Left) / 2, CenterPointEx(xa).Y + lw + s.XValues.MajorUnitTickMarkSize / 2 + s.XValues.MajorUnitSpacing, x + (xrc.Right - xrc.Left) / 2,
              CenterPointEx(xa).Y + s.XValues.MajorUnitTickMarkSize / 2 + s.XValues.MajorUnitSpacing + lw + (xrc.Bottom - xrc.Top));
          end;
          xpBottom:
          begin
            xvr := RectF(x - (xrc.Right - xrc.Left) / 2, xa.Top + lw + s.XValues.MajorUnitTickMarkSize + s.XValues.MajorUnitSpacing, x + (xrc.Right - xrc.Left) / 2,
              Min(xa.Bottom, xa.Top + xrc.Bottom - xrc.Top + lw + s.XValues.MajorUnitTickMarkSize + s.XValues.MajorUnitSpacing));
          end;
        end;

        AGraphics.Stroke.Assign(s.Crosshair.XTextStroke);
        AGraphics.Fill.Assign(s.Crosshair.XTextFill);

        InflateRectEx(xvr, spc/2, spc/2);

        bf := True;
        c.DoBeforeDrawSerieXAxisCrosshairText(AGraphics, s, xvr, xsv, posx, m, bf);
        if bf then
        begin
          AGraphics.DrawRectangle(xvr);
          AGraphics.DrawText(xvr, xsv, False, gtaCenter);
          c.DoAfterDrawSerieXAxisCrosshairText(AGraphics, s, xvr, xsv, posx, m);
        end;
      end;
    end;
  end;

  procedure DrawYValues;
  var
    posy: TTMSFNCChartYAxisPosition;
    ysv: string;
    ya, yvr, yrc: TRectF;
    lw: Double;
    sw: Integer;
    bf: Boolean;
  begin
    if s.Visible then
    begin
      AGraphics.Stroke.Assign(s.Crosshair.VerticalLineStroke);
      bf := True;
      c.DoBeforeDrawSerieCrosshairVerticalLine(AGraphics, s, PointF(x, r.Top), PointF(x, y), PointF(x, r.Bottom), m, bf);
      if bf then
      begin
        AGraphics.DrawLine(PointF(x, r.Top), PointF(x, r.Bottom));
        c.DoAfterDrawSerieCrosshairVerticalLine(AGraphics, s, PointF(x, r.Top), PointF(x, y), PointF(x, r.Bottom), m);
      end;
    end;

    for posy in c.YAxis.Positions do
    begin
      if (posy in s.Crosshair.YPositions) and s.Visible then
      begin
        if s.YValues.MajorUnitFormat <> '' then
        begin
          case s.YValues.MajorUnitFormatType of
            vftNormal: ysv := Format(s.YValues.MajorUnitFormat,[yv]);
            vftFloat: ysv := FormatFloat(s.YValues.MajorUnitFormat,yv);
            vftDateTime: ysv := FormatDateTime(s.YValues.MajorUnitFormat, yv);
          end;
        end
        else
          ysv := FloatToStr(yv);

        c.DoGetSerieYAxisCrosshairText(s, yv, posy, m, ysv);
        AGraphics.Font.Assign(s.Crosshair.YTextFont);
        yrc := AGraphics.CalculateText(ysv);

        ya := c.YAxis.GetRectangle(posy);

        lw := 0;

        sw := s.Index;
        while sw > 0 do
        begin
          if c.Series[sw - 1].Visible and (posy in c.Series[sw - 1].YValues.Positions) and (posy in s.YValues.Positions) then
          begin
            case posy of
              ypLeft: lw := c.Series[sw - 1].FYAxisLeftWidth;
              ypCenter: lw := c.Series[sw - 1].FYAxisCenterWidth;
              ypRight: lw := c.Series[sw - 1].FYAxisRightWidth;
            end;
            Break;
          end;

          Dec(sw);
        end;

        case posy of
          ypLeft:
          begin
            yvr := RectF(Max(ya.Left, ya.Right - lw - yrc.Right - yrc.Left - s.YValues.MajorUnitTickMarkSize - s.YValues.MajorUnitSpacing),
              y - (yrc.Bottom - yrc.Top) / 2, ya.Right - s.YValues.MajorUnitTickMarkSize - s.YValues.MajorUnitSpacing - lw, y + (yrc.Bottom - yrc.Top) / 2);
          end;
          ypCenter:
          begin
            yvr := RectF(CenterPointEx(ya).X + s.YValues.MajorUnitTickMarkSize / 2 + s.YValues.MajorUnitSpacing + lw,
              y - (yrc.Bottom - yrc.Top) / 2, CenterPointEx(ya).X + lw + s.YValues.MajorUnitTickMarkSize / 2 + s.YValues.MajorUnitSpacing + (yrc.Right - yrc.Left), y + (yrc.Bottom - yrc.Top) / 2);
          end;
          ypRight:
          begin
            yvr := RectF(ya.Left + s.YValues.MajorUnitTickMarkSize + s.YValues.MajorUnitSpacing + lw,
              y - (yrc.Bottom - yrc.Top) / 2, ya.Left + lw + s.YValues.MajorUnitTickMarkSize + s.YValues.MajorUnitSpacing + (yrc.Right - yrc.Left), y + (yrc.Bottom - yrc.Top) / 2);
          end;
        end;

        AGraphics.Stroke.Assign(s.Crosshair.YTextStroke);
        AGraphics.Fill.Assign(s.Crosshair.YTextFill);

        InflateRectEx(yvr, spc/2, spc/2);
        bf := True;
        c.DoBeforeDrawSerieYAxisCrosshairText(AGraphics, s, yvr, ysv, posy, m, bf);
        if bf then
        begin
          AGraphics.DrawRectangle(yvr);
          AGraphics.DrawText(yvr, ysv, False, gtaCenter);
          c.DoAfterDrawSerieYAxisCrosshairText(AGraphics, s, yvr, ysv, posy, m);
        end;
      end;
    end;
  end;
begin
  inherited;

  if not Visible then
    Exit;

  c := Chart;
  if not Assigned(c) then
    Exit;

  if c.HasPieChart or c.HasSpiderChart then
    Exit;

  x := c.FCrosshairPt.X;
  y := c.FCrosshairPt.Y;
  r := c.GetSeriesRectangle;
  if PtInRectEx(r, c.FCrosshairPt) then
  begin
    st := AGraphics.SaveState;
    spc := 4;
    sbase := nil;
    if (FContinuousSeriesIndex >= 0) and (FContinuousSeriesIndex <= c.Series.Count - 1) then
      sbase := c.Series[FContinuousSeriesIndex];

    for m in Modes do
    begin
      case m of
        ccmContinuous:
        begin
          s := sbase;
          if Assigned(s) then
          begin
            yv := s.YToValue(y);
            xv := s.XToValue(x);
            DrawXValues;
            DrawYValues;
          end;
        end;
        ccmPointBased:
        begin
          nx := x;
          ny := y;
          for I := 0 to c.Series.Count - 1 do
          begin
            s := c.Series[I];
            if s.Visible and s.XYToClosestDrawPoint(nx, ny, pt) then
            begin
              yv := s.YToValue(pt.Point.Y);
              y := pt.Point.Y;
              xv := s.XToValue(pt.Point.X);
              x := pt.Point.X;

              InflateRectEx(r, 1, 1);

              if PtInRectEx(r, PointF(x, y)) then
              begin
                DrawXValues;
                DrawYValues;
              end;
            end;
          end;
        end;
      end;
    end;
    AGraphics.RestoreState(st);
  end;
end;

procedure TTMSFNCChartCrosshair.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

procedure TTMSFNCChartCrosshair.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

procedure TTMSFNCChartCrosshair.SetContinuousSeriesIndex(const Value: Integer);
begin
  if FContinuousSeriesIndex <> Value then
  begin
    FContinuousSeriesIndex := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartCrosshair.SetModes(const Value: TTMSFNCChartCrosshairModes);
begin
  if FModes <> Value then
  begin
    FModes := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartCrosshair.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    InvalidateChart;
  end;
end;

{ TTMSFNCChartSerieLabels }

procedure TTMSFNCChartSerieLabels.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartSerieLabels then
  begin
    FFill.Assign((Source as TTMSFNCChartSerieLabels).Fill);
    FStroke.Assign((Source as TTMSFNCChartSerieLabels).Stroke);
    FVisible := (Source as TTMSFNCChartSerieLabels).Visible;
    FFormat := (Source as TTMSFNCChartSerieLabels).Format;
    FFormatType := (Source as TTMSFNCChartSerieLabels).FormatType;
    FOffsetX := (Source as TTMSFNCChartSerieLabels).OffsetX;
    FOffsetY := (Source as TTMSFNCChartSerieLabels).OffsetY;
    FFont.AssignSource((Source as TTMSFNCChartSerieLabels).Font);
    FMode := (Source as TTMSFNCChartSerieLabels).Mode;
  end;
end;

function TTMSFNCChartSerieLabels.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

constructor TTMSFNCChartSerieLabels.Create(ASerie: TTMSFNCChartSerie);
begin
  FSerie := ASerie;
  FVisible := False;
  FOffsetX := 0;
  FOffsetY := -10;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcGhostWhite);
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcGray);
  FStroke.OnChanged := @StrokeChanged;
  {$IFNDEF WEBLIB}
  FFormat := '%.2f';
  {$ENDIF}
  FFormatType := vftNormal;
  FMode := lmNormal;
end;

destructor TTMSFNCChartSerieLabels.Destroy;
begin
  FStroke.Free;
  FFill.Free;
  Ffont.Free;
  inherited;
end;

procedure TTMSFNCChartSerieLabels.FillChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartSerieLabels.FontChanged(Sender: TObject);
begin
  UpdateChart;
end;

procedure TTMSFNCChartSerieLabels.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

function TTMSFNCChartSerieLabels.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

procedure TTMSFNCChartSerieLabels.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
  begin
    FFill.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieLabels.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  if FFont <> Value then
  begin
    FFont.AssignSource(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieLabels.SetFormat(const Value: String);
begin
  if FFormat <> Value then
  begin
    FFormat := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieLabels.SetFormatType(
  const Value: TTMSFNCChartSerieValueFormatType);
begin
  if FFormatType <> Value then
  begin
    FFormatType := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieLabels.SetMode(
  const Value: TTMSFNCChartSerieLabelsMode);
begin
  if FMode <> Value then
  begin
    FMode := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieLabels.SetOffsetX(const Value: Double);
begin
  if FOffsetX <> Value then
  begin
    FOffsetX := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieLabels.SetOffsetY(const Value: Double);
begin
  if FOffsetY <> Value then
  begin
    FOffsetY := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieLabels.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
  begin
    FStroke.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieLabels.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieLabels.StrokeChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartSerieLabels.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

{ TTMSFNCChartSerieLegend }

procedure TTMSFNCChartSerieLegend.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartSerieLegend then
  begin
    FFill.Assign((Source as TTMSFNCChartSerieLegend).Fill);
    FStroke.Assign((Source as TTMSFNCChartSerieLegend).Stroke);
    FVisible := (Source as TTMSFNCChartSerieLegend).Visible;
    FFont.AssignSource((Source as TTMSFNCChartSerieLegend).Font);
    FPosition := (Source as TTMSFNCChartSerieLegend).Position;
    FLeft := (Source as TTMSFNCChartSerieLegend).Left;
    FTop := (Source as TTMSFNCChartSerieLegend).Top;
  end;
end;

function TTMSFNCChartSerieLegend.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

constructor TTMSFNCChartSerieLegend.Create(ASerie: TTMSFNCChartSerie);
begin
  FSerie := ASerie;
  FVisible := False;
  FPosition := lpCenterRight;
  FLeft := -10;
  FTop := 0;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcGhostwhite);
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcGray);
  FStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCChartSerieLegend.Destroy;
begin
  FStroke.Free;
  FFill.Free;
  Ffont.Free;
  inherited;
end;

procedure TTMSFNCChartSerieLegend.FillChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartSerieLegend.FontChanged(Sender: TObject);
begin
  UpdateChart;
end;

function TTMSFNCChartSerieLegend.GetRectangle: TRectF;
var
  I: Integer;
  c: TTMSFNCChart;
  s: TTMSFNCChartSerie;
  pt: TTMSFNCChartPointVirtual;
  tw, th: Double;
  mxw, mxh, mxhr: Double;
  x, y: Double;
  r: TRectF;
  str: String;
  cnt: Integer;
  g: TTMSFNCGraphics;
begin
  Result := RectF(0, 0, 0, 0);

  c := Chart;
  if not Assigned(c) then
    Exit;

  s := Serie;
  if not Assigned(s) then
    Exit;

  cnt := s.GetPointsCount;
  if cnt = 0 then
    Exit;

  mxw := 0;
  mxh := 0;
  mxhr := 0;
  g := TTMSFNCGraphics.CreateBitmapCanvas(1, 1, c.NativeCanvas);
  g.BeginScene;
  try
    g.Font.AssignSource(Font);
    for I := 0 to cnt - 1 do
    begin
      pt := s.GetPoint(I);
      str := s.GetLegendText(pt);
      if str <> '' then
      begin
        tw := g.CalculateTextWidth(str) + 5;
        if tw > mxw then
          mxw := tw;
        th := g.CalculateTextHeight(str) + 5;
        if th > mxh then
          mxhr := th;

        mxh := mxh + th;
      end;
    end;

    if s.LegendText <> '' then
    begin
      mxw := Max(mxw, g.CalculateTextWidth(s.LegendText) + 5);
      mxh := mxh + g.CalculateTextHeight(s.LegendText) + 5;
    end;
  finally
    g.EndScene;
    g.Free;
  end;

  if (mxw = 0) and (mxh = 0) then
  begin
    Result := RectF(0, 0, 0, 0);
    Exit;
  end;

  mxw := mxw + mxhr + 5;
  mxh := mxh + 5;

  if s.IsPie then
  begin
    r := s.GetPieRect(True);
    r.Left := r.Left - c.SeriesMargins.Left;
    r.Top := r.Top - c.SeriesMargins.Top;
    r.Bottom := r.Bottom + c.SeriesMargins.Bottom;
    r.Right := r.Right + c.SeriesMargins.Right;
  end
  else
  begin
    r := c.GetSeriesRectangle;
    r.Left := r.Left - c.SeriesMargins.Left + c.FTotalOffset3DX;
    r.Top := r.Top - c.SeriesMargins.Top - c.FTotalOffset3DY;
    r.Bottom := r.Bottom + c.SeriesMargins.Bottom - c.FTotalOffset3DY / 2;
    r.Right := r.Right + c.SeriesMargins.Right + c.FTotalOffset3DX / 2;
  end;

  x := r.Left;
  y := r.Top;

  case Position of
    lpTopCenter: x := x + ((r.Right - r.Left) - mxw) / 2;
    lpTopRight: x := x + (r.Right - r.Left) - mxw;
    lpCenterLeft: y := y + ((r.Bottom - r.Top) - mxh) / 2;
    lpCenterCenter:
    begin
      y := y + ((r.Bottom - r.Top) - mxh) / 2;
      x := x + ((r.Right - r.Left) - mxw) / 2;
    end;
    lpCenterRight:
    begin
      x := x + (r.Right - r.Left) - mxw;
      y := y + ((r.Bottom - r.Top) - mxh) / 2;
    end;
    lpBottomLeft:
    begin
      y := y + (r.Bottom - r.Top) - mxh;
    end;
    lpBottomCenter:
    begin
      x := x + ((r.Right - r.Left) - mxw) / 2;
      y := y + (r.Bottom - r.Top) - mxh;
    end;
    lpBottomRight:
    begin
      x := x + (r.Right - r.Left) - mxw;
      y := y + (r.Bottom - r.Top) - mxh;
    end;
  end;

  x := x + Left;
  y := y + Top;

  Result := RectF(x, y, x + mxw, y + mxh);
end;

procedure TTMSFNCChartSerieLegend.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

function TTMSFNCChartSerieLegend.Serie: TTMSFNCChartSerie;
begin
  Result := FSerie;
end;

procedure TTMSFNCChartSerieLegend.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
  begin
    FFill.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieLegend.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  if FFont <> Value then
  begin
    FFont.AssignSource(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieLegend.SetLeft(const Value: Double);
begin
  if FLeft <> Value then
  begin
    FLeft := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieLegend.SetPosition(
  const Value: TTMSFNCChartLegendPosition);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieLegend.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
  begin
    FStroke.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieLegend.SetTop(const Value: Double);
begin
  if FTop <> Value then
  begin
    FTop := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartSerieLegend.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartSerieLegend.StrokeChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartSerieLegend.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;


{ TTMSFNCChartAnnotation }

procedure TTMSFNCChartAnnotation.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartAnnotation then
  begin
    FText := (Source as TTMSFNCChartAnnotation).Text;
    FAutoSize := (Source as TTMSFNCChartAnnotation).AutoSize;
    FWidth := (Source as TTMSFNCChartAnnotation).Width;
    FHeight := (Source as TTMSFNCChartAnnotation).Height;
    FLineWidth := (Source as TTMSFNCChartAnnotation).LineWidth;
    FLineColor := (Source as TTMSFNCChartAnnotation).LineColor;
    FFill.Assign((Source as TTMSFNCChartAnnotation).Fill);
    FStroke.Assign((Source as TTMSFNCChartAnnotation).Stroke);
    FArrowColor := (Source as TTMSFNCChartAnnotation).ArrowColor;
    FArrowSize := (Source as TTMSFNCChartAnnotation).ArrowSize;
    FVisible := (Source as TTMSFNCChartAnnotation).Visible;
    FOffsetX := (Source as TTMSFNCChartAnnotation).OffsetX;
    FOffsetY := (Source as TTMSFNCChartAnnotation).OffsetY;
    FTextVerticalAlignment := (Source as TTMSFNCChartAnnotation).TextVerticalAlignment;
    FTextHorizontalAlignment := (Source as TTMSFNCChartAnnotation).TextHorizontalAlignment;
    FArrow := (Source as TTMSFNCChartAnnotation).Arrow;
    FFont.AssignSource((Source as TTMSFNCChartAnnotation).Font);
    FShape := (Source as TTMSFNCChartAnnotation).Shape;
    FWordWrap := (Source as TTMSFNCChartAnnotation).WordWrap;
    FLineOpacity := (Source as TTMSFNCChartAnnotation).LineOpacity;
    FArrowOpacity := (Source as TTMSFNCChartAnnotation).ArrowOpacity;
  end;
end;

function TTMSFNCChartAnnotation.Chart: TTMSFNCChart;
var
  s: TTMSFNCChartSerie;
begin
  Result := nil;
  s := Serie;
  if Assigned(s) then
    Result := s.Chart;
end;

constructor TTMSFNCChartAnnotation.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FPoint := (Collection as TTMSFNCChartAnnotations).Point;

  FAutoSize := True;
  FLineOpacity := 1;
  FArrowOpacity := 1;
  FWidth := 75;
  FHeight := 35;
  FLineWidth := 1;
  FLineColor := gcBlack;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcGhostwhite);
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcGray);
  FStroke.OnChanged := @StrokeChanged;
  FArrowColor := gcBlack;
  FArrowSize := 10;
  FVisible := True;
  FOffsetX := 0;
  FOffsetY := -10;
  FTextVerticalAlignment := gtaCenter;
  FTextHorizontalAlignment := gtaCenter;
  FArrow := arLine;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FShape := asRectangle;
  FWordWrap := True;
  UpdateChart;
end;

destructor TTMSFNCChartAnnotation.Destroy;
begin
  FFont.Free;
  FFill.Free;
  FStroke.Free;
  inherited;
  UpdateChart;
end;

procedure TTMSFNCChartAnnotation.FillChanged(Sender: TObject);
begin
  InvalidateChart;
end;

procedure TTMSFNCChartAnnotation.FontChanged(Sender: TObject);
begin
  UpdateChart;
end;

function TTMSFNCChartAnnotation.Point: TTMSFNCChartPoint;
begin
  Result := FPoint;
end;

procedure TTMSFNCChartAnnotation.UpdateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.UpdateChart;
end;

function TTMSFNCChartAnnotation.GetValue: TTMSFNCChartAnnotationVirtual;
begin
  Result.Text := Text;
  Result.AutoSize := AutoSize;
  Result.LineOpacity := LineOpacity;
  Result.ArrowOpacity := ArrowOpacity;
  Result.Width := Width;
  Result.Height := Height;
  Result.LineWidth := LineWidth;
  Result.LineColor := LineColor;
  Result.ArrowColor := ArrowColor;
  Result.ArrowSize := ArrowSize;
  Result.Visible := Visible;
  Result.OffsetX := OffsetX;
  Result.OffsetY := OffsetY;
  Result.TextVerticalAlignment := TextVerticalAlignment;
  Result.TextHorizontalAlignment := TextHorizontalAlignment;
  Result.Arrow := Arrow;
  Result.FontColor := Font.Color;
  Result.Shape := Shape;
  Result.WordWrap := WordWrap;
  Result.Annotation := Self;
end;

procedure TTMSFNCChartAnnotation.InvalidateChart;
var
  c: TTMSFNCChart;
begin
  c := Chart;
  if Assigned(c) then
    c.InvalidateChart;
end;

function TTMSFNCChartAnnotation.Serie: TTMSFNCChartSerie;
var
  p: TTMSFNCChartPoint;
begin
  Result := nil;
  p := Point;
  if Assigned(p) then
    Result := p.Serie;
end;

procedure TTMSFNCChartAnnotation.SetArrow(
  const Value: TTMSFNCChartAnnotationArrow);
begin
  if FArrow <> Value then
  begin
    FArrow := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetArrowColor(const Value: TTMSFNCGraphicsColor);
begin
  if FArrowColor <> Value then
  begin
    FArrowColor := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetArrowOpacity(const Value: Double);
begin
  if FArrowOpacity <> Value then
  begin
    FArrowOpacity := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetLineOpacity(const Value: Double);
begin
  if FLineOpacity <> Value then
  begin
    FLineOpacity := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetArrowSize(const Value: Double);
begin
  if FArrowSize <> Value then
  begin
    FArrowSize := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetAutoSize(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
  begin
    FFill.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  if FFont <> Value then
  begin
    FFont.AssignSource(Value);
    UpdateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetHeight(const Value: Double);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetLineColor(const Value: TTMSFNCGraphicsColor);
begin
  if FLineColor <> Value then
  begin
    FLineColor := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetLineWidth(const Value: Integer);
begin
  if FLineWidth <> Value then
  begin
    FLineWidth := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetOffSetX(const Value: Double);
begin
  if FOffsetX <> Value then
  begin
    FOffsetX := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetOffSetY(const Value: Double);
begin
  if FOffsetY <> Value then
  begin
    FOffsetY := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetShape(
  const Value: TTMSFNCChartAnnotationShape);
begin
  if FShape <> Value then
  begin
    FShape := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
  begin
    FStroke.Assign(Value);
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetText(const Value: string);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetTextHorizontalAlignment(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextHorizontalAlignment <> Value then
  begin
    FTextHorizontalAlignment := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetTextVerticalAlignment(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTextVerticalAlignment <> Value then
  begin
    FTextVerticalAlignment := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetVisible(const Value: Boolean);
begin
  if FVisible <> value then
  begin
    FVisible := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetWidth(const Value: Double);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
    UpdateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.SetWordWrap(const Value: Boolean);
begin
  if FWordWrap <> Value then
  begin
    FWordWrap := Value;
    InvalidateChart;
  end;
end;

procedure TTMSFNCChartAnnotation.StrokeChanged(Sender: TObject);
begin
  InvalidateChart;
end;

{ TTMSFNCChartAnnotations }

function TTMSFNCChartAnnotations.Add: TTMSFNCChartAnnotation;
begin
  Result := TTMSFNCChartAnnotation(inherited Add);
end;

constructor TTMSFNCChartAnnotations.Create(APoint: TTMSFNCChartPoint);
begin
  inherited Create(APoint, TTMSFNCChartAnnotation);
  FPoint := APoint;
end;

function TTMSFNCChartAnnotations.GetItem(Index: Integer): TTMSFNCChartAnnotation;
begin
  Result := TTMSFNCChartAnnotation(inherited Items[Index]);
end;

function TTMSFNCChartAnnotations.Insert(Index: Integer): TTMSFNCChartAnnotation;
begin
  Result := TTMSFNCChartAnnotation(inherited Insert(Index));
end;

function TTMSFNCChartAnnotations.Point: TTMSFNCChartPoint;
begin
  Result := FPoint;
end;

procedure TTMSFNCChartAnnotations.SetItem(Index: Integer;
  const Value: TTMSFNCChartAnnotation);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCChartSelector }

constructor TTMSFNCChartSelector.Create(AOwner: TComponent);
begin
  inherited;
  {$IFDEF FMXLIB}
  ClipChildren := False;
  {$ENDIF}
  FSelected := False;
  FChartType := ctLine;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.Color := gcWhite;
  {$IFDEF FMXLIB}
  FFont.Size := 9;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  FFont.Size := 7;
  {$ENDIF}
  FFont.OnChanged := @DoFontChanged;
  FColor := gcSlategray;
  FSelectedColor := gcDarkorange;
end;

destructor TTMSFNCChartSelector.Destroy;
begin
  FFont.Free;

  inherited;
end;

procedure TTMSFNCChartSelector.DoFontChanged(Sender: TObject);
begin
  Invalidate;
end;

function TTMSFNCChartSelector.GetText: String;
begin
  case ChartType of
    ctSpider: Result := 'Spider';
    ctPie: Result := 'Pie';
    ctSizedPie: Result := 'Sized Pie';
    ctVariableRadiusPie: Result := 'Var Radius Pie';
    ctLine: Result := 'Line';
    ctDigitalLine: Result := 'Digital Line';
    ctArea: Result := 'Area';
    ctBand: Result := 'Band';
    ctStackedArea: Result := 'Stacked Area';
    ctStackedPercentageArea: Result := 'Stacked Area (%)';
    ctBar: Result := 'Bar';
    ctStackedBar: Result := 'Stacked Bar';
    ctStackedPercentageBar: Result := 'Stacked Bar (%)';
    ctMarker: Result := 'Marker';
    ctXYLine: Result := 'X-Y Line';
    ctXYMarker: Result := 'X-Y Marker';
    ctOHLC: Result := 'OHLC';
    ctCandleStick: Result := 'Candlestick';
    ctBoxPlot: Result := 'Boxplot';
  end;
end;

procedure TTMSFNCChartSelector.Paint;
var
  rm, rt: TRectF;
  str: String;
  st: TTMSFNCGraphicsSaveState;
  g: TTMSFNCGraphics;
begin
  rm := RectF(0, 0, Width, Height);
  InflateRectEx(rm, -0.5, -0.5);

  g := TTMSFNCGraphics.Create(Canvas {$IFDEF VCLLIB}, True{$ENDIF});
  try
    g.Fill.Kind := gfkSolid;
    g.Stroke.Kind := gskSolid;

    if Selected then
    begin
      g.Fill.Color := gcWhite;
      g.Stroke.Color := FSelectedColor;
      g.DrawRoundRectangle(rm, 4);
    end
    else
    begin
      g.Fill.Color := gcWhite;
      g.Stroke.Color := FColor;
      g.DrawRoundRectangle(rm, 4);
    end;

    g.Fill.Color := g.Stroke.Color;

    rt := RectF(rm.Left, rm.Top, rm.Right, rm.Top + 20);

    InflateRectEx(rm, -5, -5);
    g.DrawRoundRectangle(rt, 4,[gcTopLeft, gcTopRight]);
    str := GetText;
    st := g.SaveState;
    g.Font.Assign(FFont);
    g.DrawText(rt, str, False, gtaCenter, gtaCenter);
    g.RestoreState(st);

    rm.Top := rm.Top + 20;

    if Selected then
      g.Fill.Color := FSelectedColor
    else
      g.Fill.Color := FColor;
    g.Stroke.Color := g.Fill.Color;

    DrawChartIcon(g, ChartType, rm);
  finally
    g.Free;
  end;
end;

procedure TTMSFNCChartSelector.SetChartType(const Value: TTMSFNCChartSerieType);
begin
  if FChartType <> Value then
  begin
    FChartType := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCChartSelector.SetSelectedColor(const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedColor <> Value then
  begin
    FSelectedColor := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCChartSelector.SetColor(const Value: TTMSFNCGraphicsColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCChartSelector.SetSelected(const Value: Boolean);
begin
  if FSelected <> Value then
  begin
    FSelected := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCChartSelector.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
  Invalidate;
end;

{ TTMSFNCChartInteractionOptions }

procedure TTMSFNCChartInteractionOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartInteractionOptions then
  begin
    FScaleMode := (Source as TTMSFNCChartInteractionOptions).ScaleMode;
    FPanning := (Source as TTMSFNCChartInteractionOptions).Panning;
  end;
end;

function TTMSFNCChartInteractionOptions.Chart: TTMSFNCChart;
begin
  Result := FChart;
end;

constructor TTMSFNCChartInteractionOptions.Create(AChart: TTMSFNCChart);
begin
  FChart := AChart;
  FScaleMode := smHorizontal;
  FPanning := True;
end;

destructor TTMSFNCChartInteractionOptions.Destroy;
begin
  inherited;
end;

procedure TTMSFNCChartSerie.DrawSpiderGrid(AGraphics: TTMSFNCGraphics);
var
  r: TRectF;
  st: TTMSFNCGraphicsSaveState;
  I, K: Integer;
  ds: TTMSFNCChartDrawSlice;
  yg: TTMSFNCChartDrawXYGridLine;
  lastRadius: TPointF;
  rd: TPointF;
  pth: TTMSFNCGraphicsPath;
  c: TTMSFNCChart;
  df: Boolean;
  lgt: TTMSFNCChartDrawLabel;
  pt: TTMSFNCChartPointVirtual;
begin
  if not YGrid.SpiderVisible or (FDrawSlices.Count = 0) or (FDrawYGridLines.Count = 0) then
    Exit;

  c := Chart;
  if not Assigned(c) or not IsSpider then
    Exit;

  r := GetPieRect(False);
  pth := TTMSFNCGraphicsPath.Create;

  for I := 0 to FDrawYGridLines.Count - 1 do
  begin
    yg := FDrawYGridLines[I];

    if yg.Kind = vkMajor then
      AGraphics.Stroke.Assign(YGrid.MajorUnitStroke)
    else
      AGraphics.Stroke.Assign(YGrid.MinorUnitStroke);

    st := AGraphics.SaveState;
    df := True;
    c.DoBeforeDrawSerieYGridLine(AGraphics, Self, yg, df);
    if yg.Kind = vkMajor then
      lastRadius := yg.ValueRadius;    
    
    if df then
    begin  
      case YGrid.SpiderKind of
        sgkCircles: AGraphics.DrawArc(CenterPointEx(r), yg.ValueRadius, Pie.StartAngle, Pie.SweepAngle);
        sgkPolygon:
        begin
          pth.Clear;
          ds := FDrawSlices[0];
          rd.X := ds.CenterPoint.X + yg.ValueRadius.X * Cos(DegToRad(ds.StartAngle + ds.SweepAngle / 2));
          rd.Y := ds.CenterPoint.Y + yg.ValueRadius.Y * Sin(DegToRad(ds.StartAngle + ds.SweepAngle / 2));
          pth.MoveTo(rd);
          for K := 1 to FDrawSlices.Count - 1 do
          begin
            ds := FDrawSlices[K];
            rd.X := ds.CenterPoint.X + yg.ValueRadius.X * Cos(DegToRad(ds.StartAngle + ds.SweepAngle / 2));
            rd.Y := ds.CenterPoint.Y + yg.ValueRadius.Y * Sin(DegToRad(ds.StartAngle + ds.SweepAngle / 2));
            pth.LineTo(rd);
          end;

          pth.ClosePath;

          AGraphics.DrawPath(pth);
        end;
      end;
      c.DoAfterDrawSerieYGridLine(AGraphics, Self, yg);
    end;

    AGraphics.RestoreState(st);
  end;

  for I := 0 to FDrawSlices.Count - 1 do
  begin
    ds := FDrawSlices[I];
    yg.Kind := vkMajor;
    yg.ValueGridLine := True;

    pt := ds.VirtualReference;

    yg.Value := pt.YValue;

    AGraphics.Stroke.Assign(YGrid.MajorUnitStroke);

    rd.X := ds.CenterPoint.X + lastRadius.X * Cos(DegToRad(ds.StartAngle + ds.SweepAngle / 2));
    rd.Y := ds.CenterPoint.Y + lastRadius.Y * Sin(DegToRad(ds.StartAngle + ds.SweepAngle / 2));

    st := AGraphics.SaveState;
    df := True;
    c.DoBeforeDrawSerieYGridLine(AGraphics, Self, yg, df);
    if df then
    begin
      AGraphics.DrawLine(ds.CenterPoint, rd);
      c.DoAfterDrawSerieYGridLine(AGraphics, Self, yg);
    end;
    AGraphics.RestoreState(st);

    if YGrid.SpiderLegend then
    begin
      st := AGraphics.SaveState;
      lgt.ValueString := GetSpiderLegendText(pt);
      lgt.Value := pt.YValue;
      lgt.Reference := pt.Point;
      lgt.VirtualReference := pt;
      lgt.Point := rd;

      AGraphics.Font.AssignSource(Legend.Font);

      lgt.TextWidth := AGraphics.CalculateTextWidth(lgt.ValueString);
      lgt.TextHeight := AGraphics.CalculateTextHeight(lgt.ValueString);
      case GetPieQuadrant(ds.CenterPoint, rd) of
        cpq1: lgt.Rect := RectF(lgt.Point.X + 2, lgt.Point.Y - lgt.TextHeight - 2, lgt.Point.X + 2 + lgt.TextWidth, lgt.Point.Y - 2);
        cpq2: lgt.Rect := RectF(lgt.Point.X - lgt.TextWidth - 2, lgt.Point.Y - 2 - lgt.TextHeight, lgt.Point.X - 2, lgt.Point.Y - 2);
        cpq3: lgt.Rect := RectF(lgt.Point.X - lgt.TextWidth - 2, lgt.Point.Y + 2, lgt.Point.X - 2, lgt.Point.Y + lgt.TextHeight + 2);
        cpq4: lgt.Rect := RectF(lgt.Point.X + 2, lgt.Point.Y + 2, lgt.Point.X + lgt.TextWidth + 2, lgt.Point.Y + lgt.TextHeight + 2);
      end;

      df := True;
      c.DoBeforeDrawSerieGridLegendText(AGraphics, Self, lgt, df);
      if df then
      begin
        AGraphics.DrawText(lgt.Rect, lgt.ValueString, False, gtaLeading, gtaLeading);
        c.DoAfterDrawSerieGridLegendText(AGraphics, Self, lgt);
      end;

      AGraphics.RestoreState(st);
    end;
  end;

  pth.Free;
end;

procedure TTMSFNCChartSerie.DrawSpiderValues(AGraphics: TTMSFNCGraphics);
var
  r: TRectF;
  c: TTMSFNCChart;
  I: Integer;
  yv: TTMSFNCChartDrawXYValue;
  tw, th: Single;
  an: Single;
  rd: TPointF;
  rt: TRectF;
  st: TTMSFNCGraphicsSaveState;
  df: Boolean;
begin
  if not YGrid.SpiderVisible or not YValues.SpiderValues or (FDrawSlices.Count = 0) or (FDrawYGridLines.Count = 0) then
    Exit;

  c := Chart;
  if not Assigned(c) or not IsSpider then
    Exit;

  r := GetPieRect(False);

  for I := 0 to FDrawYValues.Count - 1 do
  begin
    yv := FDrawYValues[I];
    if yv.Kind = vkMajor then
    begin
      AGraphics.Font.AssignSource(YValues.MajorUnitFont);
      AGraphics.Stroke.Color := YValues.MajorUnitTickMarkColor;
    end
    else
    begin
      AGraphics.Font.AssignSource(YValues.MinorUnitFont);
      AGraphics.Stroke.Color := YValues.MinorUnitTickMarkColor;
    end;

    tw := AGraphics.CalculateTextWidth(yv.ValueString);
    th := AGraphics.CalculateTextHeight(yv.ValueString);
    
    an := DegToRad(-90);

    rd.X := CenterPointEx(r).X + yv.ValueRadius.X * Cos(an);
    rd.Y := CenterPointEx(r).Y + yv.ValueRadius.Y * Sin(an);

    rt := RectF(rd.X - tw / 2, rd.Y - th / 2, rd.X + tw / 2, rd.Y + th / 2);
    st := AGraphics.SaveState;

    df := True;
    c.DoBeforeDrawSerieYValue(AGraphics, Self, ypCenter, yv, df);
    if df then
    begin
      AGraphics.DrawText(rt, yv.ValueString, False, gtaLeading, gtaLeading, gttNone);
      c.DoAfterDrawSerieYValue(AGraphics, Self, ypCenter, yv);
    end;

    AGraphics.RestoreState(st);
  end;
end;

procedure TTMSFNCChartSerie.LoadFromDataArray(YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray);
begin
  InternalLoadFromDataArray(YValuesArray, XValuesArray, XLabelsArray);
end;

procedure TTMSFNCChartSerie.LoadFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray);
begin
  InternalLoadFromDataArray(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray);
end;

procedure TTMSFNCChartSerie.LoadFromDataArrayEx(YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray: TTMSFNCChartValuesArray);
begin
  InternalLoadFromDataArray(YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray);
end;

procedure TTMSFNCChartSerie.LoadFromDataArrayEx(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray: TTMSFNCChartValuesArray);
begin
  InternalLoadFromDataArray(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray);
end;

procedure TTMSFNCChartSerie.AppendFromDataArray(YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray);
begin
  InternalAppendFromDataArray(YValuesArray, XValuesArray, XLabelsArray);
end;

procedure TTMSFNCChartSerie.AppendFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray);
begin
  InternalAppendFromDataArray(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray);
end;

procedure TTMSFNCChartSerie.AppendFromDataArrayEx(YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray: TTMSFNCChartValuesArray);
begin
  InternalAppendFromDataArray(YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray);
end;

procedure TTMSFNCChartSerie.AppendFromDataArrayEx(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray: TTMSFNCChartValuesArray);
begin
  InternalAppendFromDataArray(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray);
end;

procedure TTMSFNCChartSerie.LoadFromMultiPointDataArray(YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray,
  YMedianValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray);
begin
  InternalLoadFromDataArray(YHighValuesArray, XValuesArray, XLabelsArray, nil, nil, YOpenValuesArray, YCloseValuesArray, YLowValuesArray, YMedianValuesArray);
end;

procedure TTMSFNCChartSerie.LoadFromMultiPointDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray,
  YMedianValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray);
begin
  InternalLoadFromDataArray(ALoadOptions, YHighValuesArray, XValuesArray, XLabelsArray, nil, nil, YOpenValuesArray, YCloseValuesArray, YLowValuesArray, YMedianValuesArray);
end;

procedure TTMSFNCChartSerie.AppendFromMultiPointDataArray(YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray,
  YMedianValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray);
begin
  InternalAppendFromDataArray(YHighValuesArray, XValuesArray, XLabelsArray, nil, nil, YOpenValuesArray, YCloseValuesArray, YLowValuesArray, YMedianValuesArray);
end;

procedure TTMSFNCChartSerie.AppendFromMultiPointDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YHighValuesArray, YLowValuesArray, YOpenValuesArray, YCloseValuesArray,
  YMedianValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray);
begin
  InternalAppendFromDataArray(ALoadOptions, YHighValuesArray, XValuesArray, XLabelsArray, nil, nil, YOpenValuesArray, YCloseValuesArray, YLowValuesArray, YMedianValuesArray);
end;

procedure TTMSFNCChartSerie.InternalLoadFromDataArray(YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray, YOpenValuesArray, YCloseValuesArray, YLowValuesArray, YMedianValuesArray: TTMSFNCChartValuesArray);
begin
  FPoints.Clear;
  InternalAppendFromDataArray(YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray, YOpenValuesArray, YCloseValuesArray, YLowValuesArray, YMedianValuesArray);
end;

procedure TTMSFNCChartSerie.InternalLoadFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray, YOpenValuesArray, YCloseValuesArray, YLowValuesArray, YMedianValuesArray: TTMSFNCChartValuesArray);
begin
  FPoints.Clear;
  InternalAppendFromDataArray(ALoadOptions, YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray, YOpenValuesArray, YCloseValuesArray, YLowValuesArray, YMedianValuesArray);
end;

procedure TTMSFNCChartSerie.InternalAppendFromDataArray(YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray, YOpenValuesArray, YCloseValuesArray, YLowValuesArray, YMedianValuesArray: TTMSFNCChartValuesArray);
begin
  if Assigned(FChart) then
    InternalAppendFromDataArray(FChart.DefaultLoadOptions, YValuesArray, XValuesArray, XLabelsArray, YSecondValuesArray, YVariableValuesArray, YOpenValuesArray, YCloseValuesArray, YLowValuesArray, YMedianValuesArray);
end;

procedure TTMSFNCChartSerie.InternalAppendFromDataArray(ALoadOptions: TTMSFNCChartLoadOptions; YValuesArray, XValuesArray: TTMSFNCChartValuesArray; XLabelsArray: TTMSFNCChartLabelsArray; YSecondValuesArray,
  YVariableValuesArray, YOpenValuesArray, YCloseValuesArray, YLowValuesArray, YMedianValuesArray: TTMSFNCChartValuesArray);
var
  I: Integer;
  pt: TTMSFNCChartPoint;
begin
  if Assigned(FChart) then
    FChart.BeginUpdate;

  XValues.MajorUnitFormatType := ALoadOptions.XValuesFormatType;
  XValues.MajorUnitFormat := ALoadOptions.XValuesFormatString;
  XValues.MinorUnitFormatType := ALoadOptions.XValuesFormatType;
  XValues.MinorUnitFormat := ALoadOptions.XValuesFormatString;
  YValues.MajorUnitFormatType := ALoadOptions.YValuesFormatType;
  YValues.MajorUnitFormat := ALoadOptions.YValuesFormatString;
  YValues.MinorUnitFormatType := ALoadOptions.YValuesFormatType;
  YValues.MinorUnitFormat := ALoadOptions.YValuesFormatString;
  AutoXRange := ALoadOptions.XRange;
  AutoYRange := ALoadOptions.YRange;
  MaxYOffsetPercentage := ALoadOptions.MaxYOffsetPercentage;

  if (Index) > 0 then
  begin
    XValues.Positions := [];
    YValues.Positions := [];
  end
  else
  begin
    XGrid.Visible := ALoadOptions.XGrid;
    YGrid.Visible := ALoadOptions.YGrid;
  end;

  if Length(XLabelsArray) = 0 then
    XValues.AutoUnits := True;

  for I := 0 to Length(YValuesArray) - 1 do
  begin
    pt := AddPoint(YValuesArray[I]);

    if Assigned(YSecondValuesArray) and (I <= Length(YSecondValuesArray) - 1) then
    begin
      pt.YValueSecond := YSecondValuesArray[I];
    end;

    if Assigned(YVariableValuesArray) and (I <= Length(YVariableValuesArray) - 1) then
    begin
      pt.YValueVariable := YVariableValuesArray[I];
    end;

    if Assigned(YOpenValuesArray) and (I <= Length(YOpenValuesArray) - 1) then
    begin
      pt.YValueOpen := YOpenValuesArray[I];
    end;

    if Assigned(YCloseValuesArray) and (I <= Length(YCloseValuesArray) - 1) then
    begin
      pt.YValueClose := YCloseValuesArray[I];
    end;

    if Assigned(YLowValuesArray) and (I <= Length(YLowValuesArray) - 1) then
    begin
      pt.YValueLow := YLowValuesArray[I];
    end;

    if Assigned(YMedianValuesArray) and (I <= Length(YMedianValuesArray) - 1) then
    begin
      pt.YValueMedian := YMedianValuesArray[I];
    end;

    if Assigned(XValuesArray) then
    begin
      if I >= Length(XValuesArray) then
      begin
        SetLength(XValuesArray, Length(XValuesArray) + 1);
        if (I - 2) >= 0 then
          XValuesArray[I] := XValuesArray[(I - 1)] + XValuesArray[(I - 1)] - XValuesArray[(I - 2)]
        else
          XValuesArray[I] := XValuesArray[(I - 1)] + 1;
      end;

      pt.XValue := XValuesArray[I];
    end;

    if Assigned(XLabelsArray) then
    begin
      if I <= Length(XLabelsArray) - 1 then
        pt.XValueText := XLabelsArray[I];
    end;
  end;

  if Assigned(FChart) then
    FChart.EndUpdate;
end;

{$IFDEF WEBLIB}
function TTMSFNCChartDrawSlices.GetItem(Index: Integer): TTMSFNCChartDrawSlice;
begin
  Result := TTMSFNCChartDrawSlice(inherited Items[Index]);
end;

procedure TTMSFNCChartDrawSlices.SetItem(Index: Integer; Value: TTMSFNCChartDrawSlice);
var
  v: TTMSFNCChartDrawSlice;
begin
  v := Value;
  inherited Items[Index] := v;
end;

function TTMSFNCChartDrawXYGridLines.GetItem(Index: Integer): TTMSFNCChartDrawXYGridLine;
begin
  Result := TTMSFNCChartDrawXYGridLine(inherited Items[Index]);
end;

procedure TTMSFNCChartDrawXYGridLines.SetItem(Index: Integer; Value: TTMSFNCChartDrawXYGridLine);
var
  v: TTMSFNCChartDrawXYGridLine;
begin
  v := Value;
  inherited Items[Index] := v;
end;

function TTMSFNCChartDrawPoints.GetItem(Index: Integer): TTMSFNCChartDrawPoint;
begin
  Result := TTMSFNCChartDrawPoint(inherited Items[Index]);
end;

procedure TTMSFNCChartDrawPoints.SetItem(Index: Integer; Value: TTMSFNCChartDrawPoint);
var
  v: TTMSFNCChartDrawPoint;
begin
  v := Value;
  inherited Items[Index] := v;
end;

function TTMSFNCChartDrawXYValues.GetItem(Index: Integer): TTMSFNCChartDrawXYValue;
begin
  Result := TTMSFNCChartDrawXYValue(inherited Items[Index]);
end;

procedure TTMSFNCChartDrawXYValues.SetItem(Index: Integer; Value: TTMSFNCChartDrawXYValue);
var
  v: TTMSFNCChartDrawXYValue;
begin
  v := Value;
  inherited Items[Index] := v;
end;

function TTMSFNCChartDrawMultiPoints.GetItem(Index: Integer): TTMSFNCChartDrawMultiPoint;
begin
  Result := TTMSFNCChartDrawMultiPoint(inherited Items[Index]);
end;

procedure TTMSFNCChartDrawMultiPoints.SetItem(Index: Integer; Value: TTMSFNCChartDrawMultiPoint);
var
  v: TTMSFNCChartDrawMultiPoint;
begin
  v := Value;
  inherited Items[Index] := v;
end;

function TTMSFNCChartDrawLines.GetItem(Index: Integer): TTMSFNCChartDrawLine;
begin
  Result := TTMSFNCChartDrawLine(inherited Items[Index]);
end;

procedure TTMSFNCChartDrawLines.SetItem(Index: Integer; Value: TTMSFNCChartDrawLine);
var
  v: TTMSFNCChartDrawLine;
begin
  v := Value;
  inherited Items[Index] := v;
end;

function TTMSFNCChartDrawRects.GetItem(Index: Integer): TTMSFNCChartDrawRect;
begin
  Result := TTMSFNCChartDrawRect(inherited Items[Index]);
end;

procedure TTMSFNCChartDrawRects.SetItem(Index: Integer; Value: TTMSFNCChartDrawRect);
var
  v: TTMSFNCChartDrawRect;
begin
  v := Value;
  inherited Items[Index] := v;
end;

function TTMSFNCChartDrawLabels.GetItem(Index: Integer): TTMSFNCChartDrawLabel;
begin
  Result := TTMSFNCChartDrawLabel(inherited Items[Index]);
end;

procedure TTMSFNCChartDrawLabels.SetItem(Index: Integer; Value: TTMSFNCChartDrawLabel);
var
  v: TTMSFNCChartDrawLabel;
begin
  v := Value;
  inherited Items[Index] := v;
end;

function TTMSFNCChartDrawAnnotations.GetItem(Index: Integer): TTMSFNCChartDrawAnnotation;
begin
  Result := TTMSFNCChartDrawAnnotation(inherited Items[Index]);
end;

procedure TTMSFNCChartDrawAnnotations.SetItem(Index: Integer; Value: TTMSFNCChartDrawAnnotation);
var
  v: TTMSFNCChartDrawAnnotation;
begin
  v := Value;
  inherited Items[Index] := v;
end;
{$ENDIF}

{ TTMSFNCChartAdapter }

constructor TTMSFNCChartAdapter.Create(AOwner: TComponent);
var
  I: Integer;
begin
  inherited;
  FActive := False;
  FAutoCreateSeries := True;

  if IsDesignTime and Assigned(AOwner) and (AOwner is TCustomForm) then
  begin
    for I := 0 to AOwner.ComponentCount - 1 do
    begin
      if (AOwner.Components[i] is TTMSFNCChart) then
      begin
        FChart := AOwner.Components[i] as TTMSFNCChart;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCChartAdapter.GetInstance: NativeUInt;
begin
  Result := HInstance;
end;

procedure TTMSFNCChartAdapter.LoadItems;
begin
  if not Assigned(FChart) or (FChart.FUpdateCount > 0) then
    Exit;

  FChart.BeginUpdate;

  if FChart.DefaultLoadOptions.ClearSeries then
    FChart.Series.Clear;

  if Active then
    GetItems;
  FChart.EndUpdate
end;

procedure TTMSFNCChartAdapter.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FChart) then
    FChart := nil;
end;

procedure TTMSFNCChartAdapter.SetActive(const Value: boolean);
begin
  if (Value <> FActive) then
  begin
    FActive := Value;
    LoadItems;
  end;
end;

procedure TTMSFNCChartAdapter.SetAutoCreateSeries(const Value: Boolean);
begin
  if FAutoCreateSeries <> Value then
  begin
    FAutoCreateSeries := Value;

    if FActive and FAutoCreateSeries then
    begin
      LoadItems;
    end;
  end;
end;

procedure TTMSFNCChartAdapter.UpdateItems;
begin
  Active := false;
  Active := true;
end;

procedure TTMSFNCChartAppearance.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

{ TTMSFNCChartAppearanceFont }

constructor TTMSFNCChartAppearanceFont.Create(AOwner: TTMSFNCChart);
begin
  FOwner := AOwner;
  FColor := gcNull;
  FSize := 0;
  FScale := 1;
  FName := '';
end;

destructor TTMSFNCChartAppearanceFont.Destroy;
begin
  inherited;
end;

procedure TTMSFNCChartAppearanceFont.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCChartAppearanceFont) then
  begin
    Color := (Source as TTMSFNCChartAppearanceFont).Color;
    Size := (Source as TTMSFNCChartAppearanceFont).Size;
    Name := (Source as TTMSFNCChartAppearanceFont).Name;
    Scale := (Source as TTMSFNCChartAppearanceFont).Scale;
    Style := (Source as TTMSFNCChartAppearanceFont).Style;
  end
  else
    inherited;
end;

procedure TTMSFNCChartAppearanceFont.SetColor(const Value: TTMSFNCGraphicsColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;

    SetFonts(aftColor);
  end;
end;

procedure TTMSFNCChartAppearanceFont.SetSize(const Value: Single);
begin
  if FSize <> Value then
  begin
    FSize := Value;

    SetFonts(aftSize);
  end;
end;

procedure TTMSFNCChartAppearanceFont.SetName(const Value: String);
begin
  if FName <> Value then
  begin
    FName := Value;

    SetFonts(aftName);
  end;
end;

procedure TTMSFNCChartAppearanceFont.SetScale(const Value: Double);
begin
  if FScale <> Value then
  begin
    FOldScale := FScale;
    FScale := Value;

    SetFonts(aftScale);
  end;
end;

procedure TTMSFNCChartAppearanceFont.SetStyle(const Value: TFontStyles);
begin
  if FStyle <> Value then
  begin
    FStyle := Value;

    SetFonts(aftStyle);
  end;
end;

procedure TTMSFNCChartAppearanceFont.ApplyChange(AFont: TTMSFNCGraphicsFont; ASetType: TTMSFNCChartAppearanceFontType);
begin
  case ASetType of
    aftColor: AFont.Color := FColor;
    aftSize: AFont.Size := {$IFNDEF FMXLIB}Round({$ENDIF}FSize{$IFNDEF FMXLIB}){$ENDIF};
    aftName: AFont.Name := FName;
    aftScale:
    begin
      {$IFDEF FMXLIB}
      AFont.Size := Round(AFont.Size * FScale / FOldScale);
      {$ENDIF}
      {$IFNDEF FMXLIB}
      AFont.Height := Round(AFont.Height * FScale / FOldScale);
      {$ENDIF}
    end;
    aftStyle: AFont.Style := FStyle;
  end;
end;

procedure TTMSFNCChartAppearanceFont.SetFonts(ASetType: TTMSFNCChartAppearanceFontType);
var
  I, J, K: Integer;
  ASetChartFonts, ASetSeriesFonts, ASetAnnotationFonts: Boolean;
begin
  ASetChartFonts := True;
  ASetSeriesFonts := True;
  ASetAnnotationFonts := True;

  if Assigned(FOwner) then
    FOwner.DoBeforeSetAllFonts(ASetType, ASetChartFonts, ASetSeriesFonts, ASetAnnotationFonts);

  if ASetChartFonts then
  begin
    ApplyChange(FOwner.Title.Font, ASetType);
    ApplyChange(FOwner.Legend.Font, ASetType);
  end;

  for I := 0 to FOwner.Series.Count - 1 do
  begin
    if ASetSeriesFonts then
    begin
      ApplyChange(FOwner.Series[I].Legend.Font, ASetType);
      ApplyChange(FOwner.Series[I].Labels.Font, ASetType);
      ApplyChange(FOwner.Series[I].XValues.Title.Font, ASetType);
      ApplyChange(FOwner.Series[I].XValues.MinorUnitFont, ASetType);
      ApplyChange(FOwner.Series[I].XValues.MajorUnitFont, ASetType);
      ApplyChange(FOwner.Series[I].YValues.Title.Font, ASetType);
      ApplyChange(FOwner.Series[I].YValues.MinorUnitFont, ASetType);
      ApplyChange(FOwner.Series[I].YValues.MajorUnitFont, ASetType);
      ApplyChange(FOwner.Series[I].Crosshair.XTextFont, ASetType);
      ApplyChange(FOwner.Series[I].Crosshair.YTextFont, ASetType);
    end;

    for J := 0 to FOwner.Series[I].Points.Count - 1 do
    begin
      if ASetAnnotationFonts then
      begin
        for K := 0 to FOwner.Series[I].Points[J].Annotations.Count - 1 do
        begin
          ApplyChange(FOwner.Series[I].Points[J].Annotations[K].Font, ASetType);
        end;
      end;
    end;
  end;
end;


{ TTMSFNCChartAppearance }

constructor TTMSFNCChartAppearance.Create(AOwner: TTMSFNCChart);
begin
  FOwner := AOwner;

  FColorList := TTMSFNCChartColorSelections.Create(Self);
  {$IFDEF FMXLIB}
  FColorList.AddColor($FF57C0F5);
  FColorList.AddColor($FFF71F56);
  FColorList.AddColor($FF031B79);
  FColorList.AddColor($FF954EBB);
  FColorList.AddColor($FFEEC713);
  FColorList.AddColor($FFF36042);
  FColorList.AddColor($FF2773FC);
  FColorList.AddColor($FFA1CF58);
  FColorList.AddColor($FF00A9A6);
  FColorList.AddColor($FF85E4F9);
  {$ENDIF}
  {$IFNDEF FMXLIB}
  FColorList.AddColor($F5C057);
  FColorList.AddColor($561FF7);
  FColorList.AddColor($791B03);
  FColorList.AddColor($BB4E95);
  FColorList.AddColor($13C7EE);
  FColorList.AddColor($4260F3);
  FColorList.AddColor($FC7327);
  FColorList.AddColor($58CFA1);
  FColorList.AddColor($A6A900);
  FColorList.AddColor($F9E485);
  {$ENDIF}

  FGlobalFont := TTMSFNCChartAppearanceFont.Create(FOwner);

  FColorScheme := ccsColorList;
  FMonochromeColor := gcSteelblue;
end;

destructor TTMSFNCChartAppearance.Destroy;
begin
  FColorList.Free;
  FGlobalFont.Free;
  inherited;
end;

function TTMSFNCChartAppearance.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCChartAppearance.GetColorSchemeName(AColorScheme: TTMSFNCChartColorScheme): string;
begin
  case AColorScheme of
    ccsColorList: Result := 'Color List';
    ccsExcel: Result := 'Excel Preset';
    ccsMonochromatic: Result := 'Monochromatic';
  end;
end;

procedure TTMSFNCChartAppearance.SetGlobalFont(const Value: TTMSFNCChartAppearanceFont);
begin
  if FGlobalFont <> Value then
  begin
    FGlobalFont.Assign(Value);
    Changed;
  end;
end;

procedure TTMSFNCChartAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartAppearance then
  begin
    FColorList.Assign((Source as TTMSFNCChartAppearance).ColorList);
    FGlobalFont.Assign((Source as TTMSFNCChartAppearance).GlobalFont);
  end
  else
    inherited;
end;

procedure TTMSFNCChartAppearance.SetColorScheme(const Value: TTMSFNCChartColorScheme);
begin
  if Value <> FColorScheme then
  begin
    FColorScheme := Value;
    if Assigned(FOwner) then
      FOwner.UpdateSeriesColors;
  end;
end;

procedure TTMSFNCChartAppearance.SetColorList(const Value: TTMSFNCChartColorSelections);
begin
  if FColorList <> Value then
  begin
    FColorList.Assign(Value);
    Changed;
  end;
end;

procedure TTMSFNCChartAppearance.SetMonochromeColor(const Value: TTMSFNCGraphicsColor);
begin
  if FMonochromeColor <> Value then
  begin
    FMonochromeColor := Value;
    if (ColorScheme = ccsMonochromatic) and Assigned(FOwner) then
      FOwner.UpdateSeriesColors;
  end;
end;

procedure TTMSFNCLineChart.InitSample;
begin
  inherited;
end;

function TTMSFNCDigitalLineChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctDigitalLine;
end;

procedure TTMSFNCDigitalLineChart.InitSample;
begin
  InternalInitSample(1, False);
end;

function TTMSFNCAreaChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctArea;
end;

procedure TTMSFNCAreaChart.InitSample;
begin
  InternalInitSample(2);
end;

function TTMSFNCStackedAreaChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctStackedArea;
end;

procedure TTMSFNCStackedAreaChart.InitSample;
begin
  InternalInitSample(2, False);
end;

function TTMSFNCStackedPercentageAreaChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctStackedPercentageArea;
end;

procedure TTMSFNCStackedPercentageAreaChart.InitSample;
begin
  InternalInitSample(3, False);
end;

function TTMSFNCBarChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctBar;
end;

procedure TTMSFNCBarChart.InitSample;
begin
  InternalInitSample(2, False);
end;

function TTMSFNCStackedBarChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctStackedBar;
end;

procedure TTMSFNCStackedBarChart.InitSample;
begin
  InternalInitSample(2, False);
end;

function TTMSFNCStackedPercentageBarChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctStackedPercentageBar;
end;

procedure TTMSFNCStackedPercentageBarChart.InitSample;
begin
  InternalInitSample(3, False);
end;

function TTMSFNCMarkerChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctMarker;
end;

procedure TTMSFNCMarkerChart.InitSample;
begin
  InternalInitSample;
end;

function TTMSFNCXYLineChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctXYLine;
end;

procedure TTMSFNCXYLineChart.InitSample;
begin
  InternalInitSample;
end;

function TTMSFNCXYMarkerChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctXYMarker;
end;

procedure TTMSFNCXYMarkerChart.InitSample;
begin
  InternalInitSample;
end;

function TTMSFNCPieChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctPie;
end;

procedure TTMSFNCPieChart.InitSample;
begin
  InternalInitSample(1, False);
end;

procedure TTMSFNCDonutChart.InitSample;
begin
  inherited;
  Series[0].Pie.InnerSize := Series[0].Pie.Size / 2;
end;

function TTMSFNCVariableRadiusPieChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctVariableRadiusPie;
end;

procedure TTMSFNCVariableRadiusPieChart.InitSample;
begin
  InternalInitSample(1, False);
end;

function TTMSFNCSizedPieChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctSizedPie;
end;

procedure TTMSFNCSizedPieChart.InitSample;
begin
  InternalInitSample(1, False);
end;

function TTMSFNCSpiderChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctSpider;
end;

procedure TTMSFNCSpiderChart.InitSample;
begin
  InternalInitSample(1, False, 40);
  SeriesMargins.Top := 20;
  SeriesMargins.Bottom := 20;
end;

function TTMSFNCBandChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctBand;
end;

procedure TTMSFNCBandChart.InitSample;
begin
  InternalInitSample(2, False);
end;

function TTMSFNCOHLCChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctOHLC;
end;

procedure TTMSFNCOHLCChart.InitSample;
begin
  InternalInitSample(1, False);
end;

function TTMSFNCCandleStickChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctCandleStick;
end;

procedure TTMSFNCCandleStickChart.InitSample;
begin
  InternalInitSample(1, False);
end;

function TTMSFNCBoxPlotChart.GetDefaultChartType: TTMSFNCChartSerieType;
begin
  Result := ctBoxPlot;
end;

procedure TTMSFNCBoxPlotChart.InitSample;
begin
  InternalInitSample(1, False);
end;


{ TTMSFNCChartColorSelections }

function TTMSFNCChartColorSelections.Add: TTMSFNCChartColorSelection;
begin
  Result := TTMSFNCChartColorSelection(inherited Add);
end;

function TTMSFNCChartColorSelections.AddColor(AColor: TTMSFNCGraphicsColor): TTMSFNCChartColorSelection;
begin
  Result := TTMSFNCChartColorSelection(inherited Add);
  Result.Color := AColor;
end;

constructor TTMSFNCChartColorSelections.Create(AChartAppearance: TTMSFNCChartAppearance);
begin
  inherited Create(AChartAppearance, TTMSFNCChartColorSelection);
  if Assigned(AChartAppearance) then
    FChart := AChartAppearance.FOwner;
end;

function TTMSFNCChartColorSelections.GetItem(Index: Integer): TTMSFNCChartColorSelection;
begin
  Result := TTMSFNCChartColorSelection(inherited Items[Index]);
end;

function TTMSFNCChartColorSelections.Insert(Index: Integer): TTMSFNCChartColorSelection;
begin
  Result := TTMSFNCChartColorSelection(inherited Insert(Index));
end;

function TTMSFNCChartColorSelections.Chart: TTMSFNCChart;
begin
  Result := FChart;
end;

procedure TTMSFNCChartColorSelections.SetItem(Index: Integer; const Value: TTMSFNCChartColorSelection);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCChartColorSelection }

procedure TTMSFNCChartColorSelection.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartColorSelection then
  begin
    FColor := (Source as TTMSFNCChartColorSelection).Color;
  end
  else
    inherited;
end;

function TTMSFNCChartColorSelection.Chart: TTMSFNCChart;
begin
  Result := FChart;
end;

constructor TTMSFNCChartColorSelection.Create(ACollection: TCollection);
begin
  inherited;
  {$IFNDEF LCLLIB}
  if Assigned(Collection) then
  {$ENDIF}
  {$IFDEF LCLLIB}
  if Assigned(ACollection) then
  {$ENDIF}
    FChart := (Collection as TTMSFNCChartColorSelections).Chart;

  FColor := gcWhite;
end;

destructor TTMSFNCChartColorSelection.Destroy;
begin
  inherited;
end;

procedure TTMSFNCChartColorSelection.DoColorChanged;
begin
  if Assigned(FOnColorChanged) then
    OnColorChanged(Self);
end;

procedure TTMSFNCChartColorSelection.SetColor(const Value: TTMSFNCGraphicsColor);
begin
  if Value <> FColor then
  begin
    FColor := Value;
    DoColorChanged;
  end;
end;


{ TTMSFNCChartLoadOptions }

procedure TTMSFNCChartLoadOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCChartLoadOptions then
  begin
    CSVDelimiter := (Source as TTMSFNCChartLoadOptions).CSVDelimiter;
    CSVFirstLine := (Source as TTMSFNCChartLoadOptions).CSVFirstLine;
    XRange := (Source as TTMSFNCChartLoadOptions).XRange;
    YRange := (Source as TTMSFNCChartLoadOptions).YRange;
    ClearSeries := (Source as TTMSFNCChartLoadOptions).ClearSeries;
    XValuesFormatType := (Source as TTMSFNCChartLoadOptions).XValuesFormatType;
    XValuesFormatString := (Source as TTMSFNCChartLoadOptions).XValuesFormatString;
    YValuesFormatType := (Source as TTMSFNCChartLoadOptions).FYValuesFormatType;
    YValuesFormatString := (Source as TTMSFNCChartLoadOptions).FYValuesFormatString;
  end
  else
    inherited;
end;

procedure TTMSFNCChartLoadOptions.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCChartLoadOptions.Create;
begin
  CSVDelimiter := #0;
  CSVFirstLine := cflFirstLineSkip;
  XRange := arCommon;
  YRange := arCommonZeroBased;
  ClearSeries := True;
  XValuesFormatType := vftNormal;
  XValuesFormatString := '%.0f';
  YValuesFormatType := vftNormal;
  YValuesFormatString := '%.2f';
  FXGrid := False;
  FYGrid := True;
  FMaxYOffsetPercentage := 5;
end;

destructor TTMSFNCChartLoadOptions.Destroy;
begin

  inherited;
end;

procedure TTMSFNCChartLoadOptions.SetXValuesFormatType(const Value : TTMSFNCChartSerieValueFormatType);
var
  defaultValue: Boolean;
begin
  defaultValue := False;

  if FXValuesFormatType <> Value then
  begin
    case FXValuesFormatType of
      vftNormal: defaultValue := FXValuesFormatString = '%.0f';
      vftFloat:  defaultValue := FXValuesFormatString = '#.00';
      vftDateTime: defaultValue := FXValuesFormatString = FormatSettings.ShortDateFormat;
    end;

    FXValuesFormatType := Value;

    if defaultValue then
    begin
      case FXValuesFormatType of
        vftNormal: FXValuesFormatString := '%.0f';
        vftFloat:  FXValuesFormatString := '#.00';
        vftDateTime: FXValuesFormatString := FormatSettings.ShortDateFormat;
      end;
    end;
  end;
end;

procedure TTMSFNCChartLoadOptions.SetYValuesFormatType(const Value : TTMSFNCChartSerieValueFormatType);
var
  defaultValue: Boolean;
begin
  defaultValue := False;

  if FYValuesFormatType <> Value then
  begin
    case FYValuesFormatType of
      vftNormal: defaultValue := FYValuesFormatString = '%.2f';
      vftFloat:  defaultValue := FYValuesFormatString = '#.00';
      vftDateTime: defaultValue := FYValuesFormatString = FormatSettings.ShortDateFormat;
    end;

    FYValuesFormatType := Value;

    if defaultValue then
    begin
      case FYValuesFormatType of
        vftNormal: FYValuesFormatString := '%.2f';
        vftFloat:  FYValuesFormatString := '#.00';
        vftDateTime: FYValuesFormatString := FormatSettings.ShortDateFormat;
      end;
    end;
  end;
end;

end.
