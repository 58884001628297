unit Configuration;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, XData.Web.Client, MainDataModule,
  System.Generics.Collections, SMX.XData.Utils, Sys.Return.Types, Vcl.Controls,
  Vcl.StdCtrls, WEBLib.CheckLst, WEBLib.StdCtrls, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCTreeViewBase, VCL.TMSFNCTreeViewData,
  VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView, WEBLib.ExtCtrls;

type
  TConfigurationForm = class(TCoreWebForm)
    CloseButton: TButton;
    SaveButton: TButton;
    WebPanel1: TPanel;
    FNCTreeView: TTMSFNCTreeView;
    procedure WebFormShow(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure FNCTreeViewXXXClick(Sender: TObject);
    //procedure CloseButtonClick(Sender: TObject);
  private
    { Private declarations }
    FGroupList: TStringList;
    FBranchList: TStringList;
    FBranchListForQuery: TStringList;

    procedure SetBranchStructure(ABranchStructure: string);
    procedure AddBranches;
  public
    { Public declarations }

    procedure InitialiseConfig;

    [async]
    procedure GetTenantDashboardItems; async;
    property BranchStructure: string write SetBranchStructure;


  protected procedure LoadDFMValues; override; end;

var
  ConfigurationForm: TConfigurationForm;

implementation

{$R *.dfm}

{ TConfigurationForm }

procedure TConfigurationForm.WebFormShow(Sender: TObject);
begin
  inherited;
  InitialiseConfig
end;

procedure TConfigurationForm.AddBranches;
begin
end;


procedure TConfigurationForm.CloseButtonClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrClose;
end;

procedure TConfigurationForm.FNCTreeViewXXXClick(Sender: TObject);
begin
  inherited;
//  FNCTreeView.ExpandAllVirtual;
end;

procedure TConfigurationForm.GetTenantDashboardItems;
const
  svc_dashboarditem_tenant = 'IDashboardManagementService.GetTenantDashboardItems';

var
  lRetval: TXDataClientResponse;
  lResponse: TJSObject;
  lDashboardItemTitles: TList<TDashboardItemTitle>;
  lList: TJSArray;
  lIdx: integer;
begin
  lDashboardItemTitles := TList<TDashboardItemTitle>.Create;

  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(svc_dashboarditem_tenant, [MainData.TenantId]));
  lList := TsmxXDataUtils.AsArray(lRetval);

  for lIdx := 0 to lList.Length - 1  do
    lDashboardItemTitles.Add(TDashboardItemTitle.Create(JS.toObject(lList[lIdx])));

//  lCount := lDashboardItemTitles.Count;
//  WebButton1.Caption := inttostr(lCount);
end;

procedure TConfigurationForm.InitialiseConfig;
var
  I: integer;
  pn: TTMSFNCTreeViewNode;
  childn: TTMSFNCTreeViewNode;
  C: integer;
  IdxC: integer;
  K: integer;
begin
  FNCTreeView.BeginUpdate;
  FNCTreeView.ClearColumns;
  FNCTreeView.ClearNodes;

  asm debugger; end;  FNCTreeView.Columns.Add.Text := 'Branches XXXX';
  FNCTreeView.Columns.Add.Text := 'Col 2';
  I := 0;

  for I := 0 to 2 do
  begin
    pn := FNCTreeView.AddNode();

    for C := 0 to FNCTreeView.Columns.Count - 1 do
    begin
      //pn := FNCTreeView.AddNode();
      pn.Text[C] := 'Region ';// + inttostr(I) + ' for ' + FNCTreeView.Columns[C].Text;


      if I = 0  then
      begin
        for K := 0 to 4 do
        begin
          childn := FNCTreeView.AddNode(pn);
          childn.Text[K] := 'GOSH';


          for IdxC := 0 to FNCTreeView.Columns.Count-1 do
          begin
            childn.Text[IdxC] := 'Child Node ' + inttostr(K);
          end;
        end;
      end;

      if I = 1  then
      begin
        for K := 0 to 4 do
        begin
          childn := FNCTreeView.AddNode(pn);
          childn.Text[K] := 'GOSH 2';

          for IdxC := 0 to FNCTreeView.Columns.Count-1 do
          begin
            childn.Text[IdxC] := 'Child Node ' + inttostr(K);
          end;
        end;
      end;
    end;
  end;

  FNCTreeView.EndUpdate;
end;

procedure TConfigurationForm.SetBranchStructure(ABranchStructure: string);
begin
end;

procedure TConfigurationForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CloseButton := TButton.Create('CloseButton');
  SaveButton := TButton.Create('SaveButton');
  WebPanel1 := TPanel.Create('ConfigurationPanel');
  FNCTreeView := TTMSFNCTreeView.Create(Self);

  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  FNCTreeView.BeforeLoadDFMValues;
  try
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnShow', 'WebFormShow');
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 520;
    CloseButton.Top := 432;
    CloseButton.Width := 96;
    CloseButton.Height := 25;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 2;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 418;
    SaveButton.Top := 432;
    SaveButton.Width := 96;
    SaveButton.Height := 25;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 2;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 8;
    WebPanel1.Top := 8;
    WebPanel1.Width := 608;
    WebPanel1.Height := 385;
    WebPanel1.ChildOrder := 4;
    WebPanel1.TabOrder := 2;
    FNCTreeView.SetParentComponent(WebPanel1);
    FNCTreeView.Name := 'FNCTreeView';
    FNCTreeView.Left := 3;
    FNCTreeView.Top := 16;
    FNCTreeView.Width := 300;
    FNCTreeView.Height := 276;
    FNCTreeView.ParentDoubleBuffered := False;
    FNCTreeView.DoubleBuffered := True;
    FNCTreeView.TabOrder := 0;
    FNCTreeView.Stroke.Color := clDarkgray;
    FNCTreeView.GroupsAppearance.TopSize := 50.000000000000000000;
    FNCTreeView.GroupsAppearance.BottomSize := 50.000000000000000000;
    FNCTreeView.GroupsAppearance.TopFill.Kind := gfkNone;
    FNCTreeView.GroupsAppearance.BottomFill.Kind := gfkNone;
    FNCTreeView.GroupsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    FNCTreeView.GroupsAppearance.TopFont.Color := clBlack;
    FNCTreeView.GroupsAppearance.TopFont.Height := -11;
    FNCTreeView.GroupsAppearance.TopFont.Name := 'Tahoma';
    FNCTreeView.GroupsAppearance.TopFont.Style := [];
    FNCTreeView.GroupsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    FNCTreeView.GroupsAppearance.BottomFont.Color := clBlack;
    FNCTreeView.GroupsAppearance.BottomFont.Height := -11;
    FNCTreeView.GroupsAppearance.BottomFont.Name := 'Tahoma';
    FNCTreeView.GroupsAppearance.BottomFont.Style := [];
    FNCTreeView.Columns.Clear;
    with FNCTreeView.Columns.Add do
    begin
      Name := 'Column0';
      Text := 'Model';
      Width := 100.000000000000000000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Tahoma';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Tahoma';
      BottomFont.Style := [];
    end;
    with FNCTreeView.Columns.Add do
    begin
      Name := 'Column1';
      Text := 'Year';
      Width := 100.000000000000000000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Tahoma';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Tahoma';
      BottomFont.Style := [];
    end;
    with FNCTreeView.Columns.Add do
    begin
      Name := 'Column2';
      Text := 'Miles';
      Width := 100.000000000000000000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Tahoma';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Tahoma';
      BottomFont.Style := [];
    end;
    FNCTreeView.Nodes.Clear;
    with FNCTreeView.Nodes.Add do
    begin
      Values.Clear;
      with Values.Add do
      begin
        Text := 'Audi';
      end;
      Expanded := True;
      Extended := True;
      Tag := 0;
      Nodes.Clear;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'A3';
        end;
        with Values.Add do
        begin
          Text := '2010';
        end;
        with Values.Add do
        begin
          Text := '32,300';
        end;
        Tag := 0;
      end;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'A5 series';
        end;
        Expanded := True;
        Tag := 0;
        Nodes.Clear;
        with Nodes.Add do
        begin
          Values.Clear;
          with Values.Add do
          begin
            Text := 'S5';
          end;
          with Values.Add do
          begin
            Text := '2016';
          end;
          with Values.Add do
          begin
            Text := '40,000';
          end;
          Tag := 0;
        end;
        with Nodes.Add do
        begin
          Values.Clear;
          with Values.Add do
          begin
            Text := 'RS5';
          end;
          with Values.Add do
          begin
            Text := '2012';
          end;
          with Values.Add do
          begin
            Text := '15,000';
          end;
          Tag := 0;
        end;
      end;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'A8';
        end;
        with Values.Add do
        begin
          Text := '2005';
        end;
        with Values.Add do
        begin
          Text := '80,000';
        end;
        Tag := 0;
      end;
    end;
    with FNCTreeView.Nodes.Add do
    begin
      Values.Clear;
      with Values.Add do
      begin
        Text := 'Mercedes';
      end;
      Expanded := True;
      Extended := True;
      Tag := 0;
      Nodes.Clear;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'SLS';
        end;
        with Values.Add do
        begin
          Text := '2000';
        end;
        with Values.Add do
        begin
          Text := '300,000';
        end;
        Tag := 0;
      end;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'SLK';
        end;
        with Values.Add do
        begin
          Text := '2010';
        end;
        with Values.Add do
        begin
          Text := '20,000';
        end;
        Tag := 0;
      end;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'GLA';
        end;
        with Values.Add do
        begin
          Text := '2012';
        end;
        with Values.Add do
        begin
          Text := '14,500';
        end;
        Tag := 0;
      end;
    end;
    FNCTreeView.ColumnsAppearance.StretchColumn := 0;
    FNCTreeView.ColumnsAppearance.StretchAll := False;
    FNCTreeView.ColumnsAppearance.TopSize := 25.000000000000000000;
    FNCTreeView.ColumnsAppearance.BottomSize := 25.000000000000000000;
    FNCTreeView.ColumnsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    FNCTreeView.ColumnsAppearance.TopFont.Color := clBlack;
    FNCTreeView.ColumnsAppearance.TopFont.Height := -11;
    FNCTreeView.ColumnsAppearance.TopFont.Name := 'Tahoma';
    FNCTreeView.ColumnsAppearance.TopFont.Style := [];
    FNCTreeView.ColumnsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    FNCTreeView.ColumnsAppearance.BottomFont.Color := clBlack;
    FNCTreeView.ColumnsAppearance.BottomFont.Height := -11;
    FNCTreeView.ColumnsAppearance.BottomFont.Name := 'Tahoma';
    FNCTreeView.ColumnsAppearance.BottomFont.Style := [];
    FNCTreeView.ColumnsAppearance.TopFill.Color := 16380654;
    FNCTreeView.ColumnsAppearance.BottomFill.Kind := gfkNone;
    FNCTreeView.ColumnsAppearance.BottomFill.Color := 16380654;
    FNCTreeView.ColumnsAppearance.TopStroke.Kind := gskNone;
    FNCTreeView.NodesAppearance.ShowFocus := False;
    FNCTreeView.NodesAppearance.ExpandWidth := 15.000000000000000000;
    FNCTreeView.NodesAppearance.ExpandHeight := 15.000000000000000000;
    FNCTreeView.NodesAppearance.LevelIndent := 20.000000000000000000;
    FNCTreeView.NodesAppearance.FixedHeight := 25.000000000000000000;
    FNCTreeView.NodesAppearance.VariableMinimumHeight := 25.000000000000000000;
    FNCTreeView.NodesAppearance.Font.Charset := DEFAULT_CHARSET;
    FNCTreeView.NodesAppearance.Font.Color := clBlack;
    FNCTreeView.NodesAppearance.Font.Height := -11;
    FNCTreeView.NodesAppearance.Font.Name := 'Tahoma';
    FNCTreeView.NodesAppearance.Font.Style := [];
    FNCTreeView.NodesAppearance.TitleFont.Charset := DEFAULT_CHARSET;
    FNCTreeView.NodesAppearance.TitleFont.Color := clBlack;
    FNCTreeView.NodesAppearance.TitleFont.Height := -11;
    FNCTreeView.NodesAppearance.TitleFont.Name := 'Tahoma';
    FNCTreeView.NodesAppearance.TitleFont.Style := [];
    FNCTreeView.NodesAppearance.SelectedFontColor := 4539717;
    FNCTreeView.NodesAppearance.ExtendedFontColor := 4539717;
    FNCTreeView.NodesAppearance.SelectedFill.Color := 16578806;
    FNCTreeView.NodesAppearance.SelectedStroke.Color := 15702829;
    FNCTreeView.NodesAppearance.SelectedStroke.Width := 2.000000000000000000;
    FNCTreeView.NodesAppearance.ExtendedFont.Charset := DEFAULT_CHARSET;
    FNCTreeView.NodesAppearance.ExtendedFont.Color := clWindowText;
    FNCTreeView.NodesAppearance.ExtendedFont.Height := -11;
    FNCTreeView.NodesAppearance.ExtendedFont.Name := 'Tahoma';
    FNCTreeView.NodesAppearance.ExtendedFont.Style := [];
    FNCTreeView.NodesAppearance.ExpandNodeIcon.LoadFromFile('Configuration.WebPanel1.FNCTreeView.NodesAppearance.ExpandNodeIcon.png');
    FNCTreeView.NodesAppearance.CollapseNodeIcon.LoadFromFile('Configuration.WebPanel1.FNCTreeView.NodesAppearance.CollapseNodeIcon.png');
    FNCTreeView.NodesAppearance.ExpandNodeIconLarge.LoadFromFile('Configuration.WebPanel1.FNCTreeView.NodesAppearance.ExpandNodeIconLarge.png');
    FNCTreeView.NodesAppearance.CollapseNodeIconLarge.LoadFromFile('Configuration.WebPanel1.FNCTreeView.NodesAppearance.CollapseNodeIconLarge.png');
    FNCTreeView.GlobalFont.Name := 'Segoe UI';
    FNCTreeView.DefaultViewJSONOptions.NameHTMLTemplate := '<#NAME>';
    FNCTreeView.DefaultViewJSONOptions.ValueHTMLTemplate := '<#VALUE>';
  finally
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    FNCTreeView.AfterLoadDFMValues;
  end;
end;

end.