unit MainDataModule;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Modules,
  WEBLib.Forms,
  XData.Web.Connection,
  XData.Web.Client,
  WEBLib.Sentry,
  App.Types,
  App.Config,
  Sphinx.WebLogin,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.ExtCtrls,
  Sphinx.LoginCommon,
  SMX.Auth.Shared,
  WEBLib.Storage,
  CSY.Enumerations,
  Sys.Return.Types, WEBLib.CDS, WEBLib.DB;

type

  TMainData = class(TDataModule)
    WebClient: TXDataWebClient;
    DataConnection: TXDataWebConnection;
    WebSentry1: TSentry;
    SphinxLogin: TSphinxWebLogin;
    MainTimer: TTimer;
    DummySource: TDataSource;
    DummyLookUp: TClientDataSet;
    procedure WebDataModuleDestroy(Sender: TObject);
    procedure DataConnectionRequest(Args: TXDataWebConnectionRequest);
    procedure SphinxLoginUserLoggedIn(Args: TUserLoggedInArgs);
    procedure WebDataModuleCreate(Sender: TObject);
  private
    { Private declarations }
    FErrorProc: TErrorMessageProc;
    FLastError: TApplicationError;
    FLastErrorTime: TDateTime;
    FErrorCount: Integer;
    FShowMessageProc: TShowMessageProc;
    FAppUser: TAppUser;
    FTenantId: string;
    FBranchIdType: TBranchIdType;
    FBranchId: Double;
    FUserId: integer;
    FFirstName: String;


    procedure CheckConnection;
    [Async]
    procedure InitModule; async;
    [Async]
    procedure AfterLogin; async;
    [Async]
    procedure LoadConfig; async;

    function GetConfig: TAppConfig;
    function GetLocalValue(const Key: string): string;
    procedure SetLocalValue(const Key, Value: string);
    function GetUserScope: TUserScope;
    function GetBranchId: Double;
    function GetBranchIdType: TBranchIdType;
    function GetFirstName: string;
    function GetUserInitials: string;
    function GetUserId: Integer;

  protected
    function SentryAppId: string;
    function SentryDSN: string;
    function SphinxClientId: string;
    function SphinxRedirectUri: string;
    function SphinxScopes: string;
  public
    { Public declarations }
    procedure OnApplicationError(Sender: TObject; AError: TApplicationError; var Handled: Boolean);

    [Async]
    procedure ShowMainPage; async;
    procedure Logout(const ShowLogin: Boolean = True);

    procedure SentryErrorProc(E: Exception; const AComment: string; var AHandled: Boolean;
      const CallUIHandler: Boolean = True);

    procedure DeleteLocalValue(const Key: string);
    property Config: TAppConfig read GetConfig;
    property ShowMessageProc: TShowMessageProc write FShowMessageProc;
    property ErrorProc: TErrorMessageProc write FErrorProc;
    property UserScope: TUserScope read GetUserScope;
    property FirstName: string read GetFirstName;
    property UserInitials: string read GetUserInitials;
    property BranchIdType: TBranchIdType read GetBranchIdType;
    property BranchId: Double read GetBranchId;

    property LocalValue[const Key: string]: string read GetLocalValue write SetLocalValue;
    property TenantId: string read FTenantId;
    property UserId: integer read GetUserId;
  protected procedure LoadDFMValues; override; end;

var
  MainData: TMainData;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

uses
  System.DateUtils,
  System.Rtti,
  WEBLib.Rest,
  WEBLib.WebTools,
  XData.Web.Request,
  XData.Web.Response,
  MainForm,
  SharedDataModule,
  Auth.Service,
  SMX.JWT.Web.Helper;

{$R *.dfm}

const
  WEB_SENTRY_DSN = 'https://c17aa4b667554ef29e9954a14f27c8f8@o4504537489473536.ingest.sentry.io/4504854736207872';

procedure TMainData.WebDataModuleDestroy(Sender: TObject);
begin
  if Assigned(FAppUser) then
    FAppUser.Free;
end;

procedure TMainData.AfterLogin;
var
  lURL, lOrgTenant: string;
  lUserId: integer;
begin

  lUrl := Config.BaseUrl;
  DataConnection.URL := Config.BaseUrl;

  lOrgTenant := AuthService.GetClaimValue(CLAIM_ORGANISATION_TENANT_ID);

  if lOrgTenant = ORGANISATION_MULTI_TENANT then
  begin
    //TMultiTenantOption.DisplayPage('content_here');
  end
  else
  begin
    FTenantId := AuthService.GetClaimValue(CLAIM_TENANT_ID);
    ShowMainPage;
  end;

end;

procedure TMainData.CheckConnection;
begin
  if (DataConnection.URL <> '') and (not DataConnection.Connected) and SphinxLogin.IsLoggedIn then
    DataConnection.Connected := True;
  if (not DataConnection.Connected) then
    raise Exception.Create('Could not connect to server');
end;

procedure TMainData.DataConnectionRequest(Args: TXDataWebConnectionRequest);
begin
  Args.Request.Headers.SetValue('Authorization', 'Bearer ' + SphinxLogin.AuthResult.AccessToken);
  if FTenantId <> '' then
     Args.Request.Headers.SetValue(HEADER_TENANT_ID, FTenantId);
end;

procedure TMainData.DeleteLocalValue(const Key: string);
begin
  if LocalValue[Key] <> '' then
     TLocalStorage.RemoveKey(Key);
end;


function TMainData.GetBranchId: Double;
begin
  if FBranchId < 1 then
     FBranchId := AuthService.GetClaimValue(CLAIM_BRANCH_ID).ToDouble;
  Result := FBranchId;
end;

function TMainData.GetBranchIdType: TBranchIdType;
begin
  if FBranchIdType = TBranchIdType.NotSpecified then
    FBranchIdType := TRttiEnumerationType.GetValue<TBranchIdType>(AuthService.GetClaimValue(CLAIM_BRANCH_ID_TYPE));
  Result := FBranchIdType;
end;

function TMainData.GetConfig: TAppConfig;
begin
  Result := AppConfig;
end;

function TMainData.GetFirstName: string;
begin
  if FFirstName = '' then
     FFirstName := AuthService.GetClaimValue(CLAIM_FIRSTNAME, TSphinxTokenType.sttId);
  Result := FFirstName;
end;

function TMainData.GetLocalValue(const Key: string): string;
begin
  Result := TLocalStorage.GetValue(Key);
end;

function TMainData.GetUserInitials: string;
begin
  if FirstName <> '' then
     Result := FirstName.Substring(0, 1)
  else
     Result := '*';
end;

function TMainData.GetUserScope: TUserScope;
begin
  Result := FAppUser.User_Scope;//  TRttiEnumerationType.GetValue<TUserScope>(FAppUser.UserScope);
end;

procedure TMainData.InitModule;
var
  lCharityId, lEmail: string;
begin
  FErrorCount := 0;

{$IFDEF NO_SENTRY}
  // do nothing
{$ELSE}
  WebSentry1.DSN := SentryDSN;
{$IFDEF RELEASE}
  WebSentry1.Release := SentryAppId + '.RELEASE@' + Application.Version;
{$ELSE}
  WebSentry1.Release := SentryAppId + '.DEBUG@' + Application.Version;
{$ENDIF}
  WebSentry1.Init;
{$ENDIF}
  FormatSettings.DateSeparator := '/';
  FormatSettings.ShortDateFormat := 'dd/mm/yyyy';

{$IFDEF DEBUG}
  Application.ErrorType := aeFooter; // aeSilent, aeDialog, aeAlert, aeFooter
{$ELSE}
  Application.ErrorType := aeSilent; // aeSilent, aeDialog, aeAlert, aeFooter
  Application.OnError := self.OnApplicationError;
{$ENDIF}
  if HasQueryParam('charityid', lCharityId) then
    LocalValue['charity-id'] := lCharityId;
  if HasQueryParam('usermail', lEmail) then
    LocalValue['user-email'] := lEmail;

  Await(LoadConfig);

  SharedData := TSharedData.Create(Application, WebClient);
  SharedData.DataConnection := DataConnection;

  SphinxLogin.ClientId := SphinxClientId;
  SphinxLogin.RedirectUri := SphinxRedirectUri;
  SphinxLogin.Scope := SphinxScopes;

  SphinxLogin.Login;

end;

procedure TMainData.LoadConfig;
var
  Conn: TXDataWebConnection;
  Response: IHttpResponse;
begin

  Conn := TXDataWebConnection.Create(nil);
  try

    Response := Await(Conn.SendRequestAsync(THttpRequest.Create('config/config.json')));
    if Response.StatusCode = 200 then
    begin
      Config.Load(Response.ContentAsText);
      DataConnection.URL := Config.BaseUrl;
      SphinxLogin.Authority := Config.AuthURL;
//      Await(DataConnection.OpenAsync);
    end;

  finally
    Conn.Free;
  end;

end;

procedure TMainData.Logout(const ShowLogin: Boolean = True);
begin
  SphinxLogin.Logout;
  { TODO : do some logging? }
  if ShowLogin then
     SphinxLogin.Login;
end;

procedure TMainData.OnApplicationError(Sender: TObject; AError: TApplicationError; var Handled: Boolean);
begin
  if (AError.ALineNumber = FLastError.ALineNumber) and (SecondsBetween(Now, FLastErrorTime) < 60) then
  begin
    Inc(FErrorCount);
  end
  else
  begin
    FLastError.ALineNumber := AError.ALineNumber;
    FErrorCount := 1;
  end;

  SentryErrorProc(EApplicationException.Create(AError, FErrorCount), '', Handled);

end;

function TMainData.SentryAppId: string;
begin
  Result := Application.ExeName
end;

function TMainData.SentryDSN: string;
begin
  Result := WEB_SENTRY_DSN;
end;

procedure TMainData.SentryErrorProc(E: Exception; const AComment: string; var AHandled: Boolean;
  const CallUIHandler: Boolean);
var
  sendException: TJSObject;
begin

  // if Assigned(FErrorProc) then
  // FErrorProc('Error: ' + E.Message + '. NOT Logged at WebSentry');
  // Exit;

  if E.Message.Contains('FServerRecordCount') then
  begin
    // Swallow the excpetion - caused by a page being released before being fully loaded
{$IFDEF DEBUG}
    if CallUIHandler and Assigned(FErrorProc) then
      FErrorProc('Error: ' + E.Message + '. Not logged and Not notified in Release Version');
{$ENDIF}
    Exit;
  end;

{$IFDEF NO_SENTRY}
  if Assigned(FErrorProc) then
    FErrorProc('Error: ' + E.Message + '. NOT Logged at WebSentry');
{$ELSE}
  sendException := TJSObject(E);
  WebSentry1.CaptureException(sendException, AComment);
{$IFDEF RELEASE}
  if CallUIHandler and Assigned(FErrorProc) then
    FErrorProc('There has been an error and the developers have been notified');
  Exit;
{$ENDIF}
{$IFDEF DEBUG}
  if Assigned(FErrorProc) then
    FErrorProc('Error: ' + E.Message + '. Logged at WebSentry');
{$ENDIF}
{$ENDIF}
end;

procedure TMainData.SetLocalValue(const Key, Value: string);
begin
  TLocalStorage.SetValue(Key, Value);
end;

procedure TMainData.ShowMainPage;
begin
  if FTenantId = '' then
     FTenantId := AuthService.GetClaimValue(CLAIM_TENANT_ID);
  DataConnection.Connected := True;
//  Await(GetAppUser);
  TMainPage.DisplayPage('content_here');
end;

function TMainData.SphinxClientId: string;
begin
  Result := Config.SphinxClientId;
  if Result = '' then
    Result := 'GAMTWeb';
end;

procedure TMainData.SphinxLoginUserLoggedIn(Args: TUserLoggedInArgs);
begin
  AfterLogin;
end;

function TMainData.SphinxRedirectUri: string;
var
  I: Integer;
begin
  Result := Application.ExeName;
  I := Result.IndexOf('?');
  if I > 0 then
    Result := Result.Substring(0, I);
  I := Result.IndexOf('#');
  if I > 0 then
    Result := Result.Substring(0, I);
end;

function TMainData.SphinxScopes: string;
begin
  Result := 'openid email';
end;

procedure TMainData.WebDataModuleCreate(Sender: TObject);
begin
  InitModule;
end;

function TMainData.GetUserId: Integer;
var
  lValue: string;
begin
  try
    lValue := AuthService.GetClaimValue(CLAIM_USERID);
    if lValue <> '' then
      Result := lValue.ToInteger
    else
      Result := 0;
  except
    Result := 0;
  end;
end;



procedure TMainData.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebClient := TXDataWebClient.Create(Self);
  DataConnection := TXDataWebConnection.Create(Self);
  WebSentry1 := TSentry.Create(Self);
  SphinxLogin := TSphinxWebLogin.Create(Self);
  MainTimer := TTimer.Create(Self);
  DummySource := TDataSource.Create(Self);
  DummyLookUp := TClientDataSet.Create(Self);

  WebClient.BeforeLoadDFMValues;
  DataConnection.BeforeLoadDFMValues;
  WebSentry1.BeforeLoadDFMValues;
  SphinxLogin.BeforeLoadDFMValues;
  MainTimer.BeforeLoadDFMValues;
  DummySource.BeforeLoadDFMValues;
  DummyLookUp.BeforeLoadDFMValues;
  try
    Name := 'MainData';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    SetEvent(Self, 'OnDestroy', 'WebDataModuleDestroy');
    Height := 270;
    Width := 416;
    WebClient.SetParentComponent(Self);
    WebClient.Name := 'WebClient';
    WebClient.Connection := DataConnection;
    WebClient.Left := 40;
    WebClient.Top := 88;
    DataConnection.SetParentComponent(Self);
    DataConnection.Name := 'DataConnection';
    DataConnection.URL := 'http://localhost:2018/GAOnline';
    SetEvent(DataConnection, Self, 'OnRequest', 'DataConnectionRequest');
    DataConnection.Left := 40;
    DataConnection.Top := 24;
    WebSentry1.SetParentComponent(Self);
    WebSentry1.Name := 'WebSentry1';
    WebSentry1.Enabled := True;
    WebSentry1.Release := '1.0';
    WebSentry1.Left := 176;
    WebSentry1.Top := 88;
    SphinxLogin.SetParentComponent(Self);
    SphinxLogin.Name := 'SphinxLogin';
    SetEvent(SphinxLogin, Self, 'OnUserLoggedIn', 'SphinxLoginUserLoggedIn');
    SphinxLogin.Left := 336;
    SphinxLogin.Top := 24;
    MainTimer.SetParentComponent(Self);
    MainTimer.Name := 'MainTimer';
    MainTimer.Enabled := False;
    MainTimer.Interval := 30000;
    MainTimer.Left := 336;
    MainTimer.Top := 192;
    DummySource.SetParentComponent(Self);
    DummySource.Name := 'DummySource';
    DummySource.DataSet := DummyLookUp;
    DummySource.Left := 176;
    DummySource.Top := 216;
    DummyLookUp.SetParentComponent(Self);
    DummyLookUp.Name := 'DummyLookUp';
    DummyLookUp.Left := 176;
    DummyLookUp.Top := 160;
  finally
    WebClient.AfterLoadDFMValues;
    DataConnection.AfterLoadDFMValues;
    WebSentry1.AfterLoadDFMValues;
    SphinxLogin.AfterLoadDFMValues;
    MainTimer.AfterLoadDFMValues;
    DummySource.AfterLoadDFMValues;
    DummyLookUp.AfterLoadDFMValues;
  end;
end;

end.
