unit SimpleDashboard;

interface

uses
  System.Generics.Collections,
  JS,
  XData.Web.Client,
  Sys.Return.Types,
  SMX.XData.Utils,
  Simple.Dashboard.Return.Types;

type

  TSimpleDashboard = class
  private
    FWebClient: TXDataWebClient;
    FItemList: TDictionary<string, TDashboardResponse>;
    procedure SetWebClient(const Value: TXDataWebClient);
    function JobParams(AParamString: string): JS.TJSObject;

  public
    constructor Create(AWebClient: TXDataWebClient);
    destructor Destroy; override;
    [ASync]
    function GetDashboardItem(const AKey: string; const AParams: string = ''):
        TDashboardResponse; async;
    [ASync]
    function GetDashboardSummaryData(const AKey: string; AFields: TArray<TSummaryArray>): string; async;
    [ASync]
    function GetBranchStructure: string; async;
    [ASync]
    function GetDashboardItemTemplates(const AUserId: integer): TList<TDashboardItemTemplate>; async;
    property WebClient: TXDataWebClient read FWebClient write SetWebClient;

  end;

implementation

uses
  System.SysUtils,
  System.Rtti;

const
  SVC_GET_ITEM = 'ISimpleDashboardService.GetDashboardItem';
  SVC_GET_SUMMARY_DATA = 'ISimpleDashboardService.GetDashboardSummaryData';
  SVC_GET_BRANCH_STRUCTURE = 'ISimpleDashboardService.GetBranchStructure';
  SVC_GET_DASHBOARD_TEMPLATES = 'IDashboardManagementService.GetDashboardItemsForUser';

  { TSimpleDashboard }

constructor TSimpleDashboard.Create(AWebClient: TXDataWebClient);
begin
  FWebClient := AWebClient;
  FItemList := TDictionary<string, TDashboardResponse>.Create;
end;

destructor TSimpleDashboard.Destroy;
begin
  FItemList.Free;
  inherited;
end;

function TSimpleDashboard.GetBranchStructure: string;
var
  lRetval: TXDataClientResponse;
  lObject: JS.TJSObject;
  lData: string;
begin
  lRetval := Await(TXDataClientResponse, FWebClient.RawInvokeAsync(SVC_GET_BRANCH_STRUCTURE, []));
  lObject := lRetval.ResultAsObject;

  lData := String(TJSObject(lRetval.Result)['value']);

  result := lData;
//  FItemList.AddOrSetValue(AKey, Result);
end;

function TSimpleDashboard.GetDashboardItem(const AKey: string; const AParams: string): TDashboardResponse;
var
  lRetval: TXDataClientResponse;
  lObject: JS.TJSObject;
begin
  lRetval := Await(TXDataClientResponse, FWebClient.RawInvokeAsync(SVC_GET_ITEM, [AKey, AParams]));
  lObject := lRetval.ResultAsObject;

  Result := TDashboardResponse.Create;
  Result.Assign(lObject);

  //FItemList.AddOrSetValue(AKey, Result);
end;

function TSimpleDashboard.GetDashboardItemTemplates(const AUserId: integer): TList<TDashboardItemTemplate>;
const
  svc_name = 'IDashboardManagementService.GetDashboardItemsForUser';

var
  lRetval: TXDataClientResponse;
  lObject: TJSObject;
  lData: TJSArray;
  lTemplates: TList<TDashboardItemTemplate>;
  lIdx: integer;
  lList: TJSArray;

begin
  lRetval := Await(TXDataClientResponse, FWebClient.RawInvokeAsync(SVC_GET_DASHBOARD_TEMPLATES, [AUserId]));

  lTemplates := TList<TDashboardItemTemplate>.Create;

  lList := TsmxXDataUtils.AsArray(lRetval);
  for lIdx := 0 to lList.Length - 1  do
     lTemplates.Add(TDashboardItemTemplate.Create(JS.toObject(lList[lIdx])));

  result := lTemplates;
end;

(*
     FBranchGroups := TList<TWebBranchGroup>.Create;
     lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(branchgroup_svc, []));
     lList := TsmxXDataUtils.AsArray(lRetval);
     for I := 0 to lList.Length - 1  do
        FBranchGroups.Add(TWebBranchGroup.Create(JS.toObject(lList[I])));
  end;


(*
Procedure TBranchGroupManagerForm.LoadData;
Const
  svc_name = 'IBranchService.GetAllBranchGroups';
Var
  lRetval: TXDataClientResponse;
  lResponse: TJSObject;
  lData: TJSArray;
Begin

  BranchGroupDataset.Active := False;
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(svc_name, []));
  lResponse := lRetval.ResultAsObject;
  lData := TJSArray(lResponse['value']);

  BranchGroupDataset.Rows := lData;
  BranchGroupDataset.Active := True;

End; *)








function TSimpleDashboard.GetDashboardSummaryData(const AKey: string; AFields: TArray<TSummaryArray>): string;
var
  lRetval: TXDataClientResponse;
  lResult: string;

begin
  lRetval := Await(TXDataClientResponse, FWebClient.RawInvokeAsync(SVC_GET_SUMMARY_DATA, [AKey, AFields]));
  lResult := String(TJSObject(lRetval.Result)['value']);
  result := lResult;
end;

function TSimpleDashboard.JobParams(AParamString: string): JS.TJSObject;
//var
//  lJobParams: JS.TJSObject;

begin
//  lJobParams := JS.TJSObject.new;

//  lJobParams.Properties['StartDate'] := '01.03.2023';
//  lJobParams.Properties['EndDate'] := '01.03.2023';
//  lJobParams.Properties['BranchId'] := '1';

//  result := lJobParams;
end;


procedure TSimpleDashboard.SetWebClient(const Value: TXDataWebClient);
begin
  FWebClient := Value;
end;

end.
